var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// creates a span with mouse over and leave events that
// wraps whatever child elements it is given
// Tremr.Generic.Mouseover = CreateReactClass({
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      onMouseOver: PropTypes.func.isRequired,
      onMouseLeave: PropTypes.func.isRequired
  },

  render: function() {

  	return (

    	<span className="generic-mouseover" onMouseOver={this.props.onMouseOver} onMouseLeave={this.props.onMouseLeave}>
          {this.props.children}
      </span>
    );
  }
});
