require("./scss/related.scss");

var classNames = require('../../utils/classnames');
var TremrStorage = require("../../utils/tremr_storage");
var TremrUtils = require('../../utils/tremr_utils');
var CreateReactClass = require('create-react-class');
var PropTypes = require("prop-types");
var HorizontalScroller = require('../generic/horizontalscroller');

// sideways scrolling list of related posts
module.exports = CreateReactClass({

   // mixins: [PureRenderMixin],

   propTypes: {
      feed: PropTypes.string.isRequired,
      post_id: PropTypes.string.isRequired,
      cache: PropTypes.bool
   },

   getDefaultProps: function() {
      return { cache: false }
   },

   getInitialState: function() {
      return {
           loaded: false,
           empty: true
      };
   },

   loadedCallback: function(data, gridStyle) {

       this.setState({
           loaded: true,
           empty: (data.length == 0)
       });
   },

   loadMore: function() {

       this.refs['relatedComponent'].loadMore();
   },

   handleScroll: function(left, viewport, width) {
       if (left + viewport + 100 > width) {
           this.loadMore();
       }
   },

   // draw cards
   render: function() {

      // work out our top level class
      let classes = classNames({
         'post-related': true,
         empty: this.state.empty,
         loaded: this.state.loaded
      });
      let wall = Tremr.stores.postStore.getRelatedList(this.props.feed, this.props.post_id, this.props.cache, this.loadedCallback);

      return <div className={classes}>
         <div className="title">Read more…</div>
         <HorizontalScroller childSize={240} onScroll={this.handleScroll}>{wall}</HorizontalScroller>
      </div>;

   }
});
