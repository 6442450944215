require("./scss/badge.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");

module.exports = CreateReactClass({
  propTypes: {
    post_id: PropTypes.string.isRequired,
    set: PropTypes.bool.isRequired,
  },

  getInitialState: function () {
    return {
      isSet: this.props.set,
    };
  },

  getDefaultProps: function () {
    return {
      set: false,
    };
  },

  // listen for changes
  componentDidMount: function () {
    _.extend(this, Backbone.Events);
    this.listenTo(
      Tremr.dispatcher,
      "tremr:factcheck:" + this.props.post_id,
      function (data) {
        this.setState({
          isSet: data.set,
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    if (this.stopListening) {
      this.stopListening();
    }
  },

  // display
  render: function () {
    if (this.state.isSet) {
      return (
        <div className="factcheck-badge">
          <div className="badge">
            <SvgIcon icon="factcheck-tick" />
            <label>Fact Checked</label>
          </div>
        </div>
      );
    } else {
      return null;
    }
  },
});
