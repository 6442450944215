require("../layout/scss/staticpage.scss");
require("./scss/signin.scss");

var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');
var Theme = require("theme");

// ui for signing the user in
// Tremr.User.Signin = CreateReactClass({
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  getInitialState: function() {
    return {
      tab: 'signin',
      forgottenEmail: ''
    }
  },

  // register listeners
  componentDidMount: function() {

    _.extend(this, Backbone.Events);

    var self = this;

    this.renderSocialSignin();

    // watch for success of forgotten password
    this.listenTo(Tremr.dispatcher, "tremr:user:forgotpassword:success", function(data) {
      this.setState({
        tab: 'forgotpassword',
        forgottenEmail: data.email
      });
    });

    this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function(user) {

      Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
    }.bind(this));

    // track which tab we are showing
    // TremrTracking.event('signup', 'show', 'signin_tab');

    // watch for validation of forgotten password
    this.listenTo(Tremr.dispatcher, "tremr:user:forgotpassword:failed", function(errors) {

       // clear old errors
      _.each(this.refs, function(ref) {
        if (ref.state.error) {
          ref.setState({
            error: false
          });
        }
      }.bind(this));

      _.each(_.pairs(errors), function(error) {
        if (self.refs[error[0]]) {
          self.refs[error[0]].setState({
            error: true,
            errorMessage: error[1]
          });
        }
      });

      this.setState({
        isLoading: false,
        isForgottenPassword: false
      });
    });

    // watch for success of forgotten password
    this.listenTo(Tremr.dispatcher, "tremr:user:forgotpassword:success", function() {
      if (this.isMounted()) {
        this.setState({
          isLoading: false,
          isForgottenPassword: false
        });
      }
    }.bind(this));

    // watch for validation
    this.listenTo(Tremr.dispatcher, "tremr:user:signin:failed", function(errors) {

      _.each(_.pairs(errors), function(error) {
        if (self.refs[error[0]]) {
          self.refs[error[0]].setState({
            error: true,
            errorMessage: error[1]
          });
        }
      });

      this.setState({
        isLoading: false
      });
    });

    // this.renderSocialSignin();
  },

  getInitialState: function() {
    return {
      name: '',
      email: '',
      password: '',
      username: '',
      isLoading: false,
      isForgottenPassword: false
    }
  },

  renderSocialSignin: function() {

    if (!Tremr.Theme.RenderSocialSignin) {
      return;
    }

    // add the social signup
    var url = Tremr.current_url.protocol + "//" + Tremr.current_url.hostname;
    if (Tremr.current_url.port && Tremr.current_url.port != '80' && Tremr.current_url.port != '0') {
        url = url + ':' + Tremr.current_url.port;
    }
    var social_callback_script = url + '/api/social_callback';
    // var css = url + '/css/oneall.css';
    var css = url + Theme.OneAllCss;
    _oneall.push(['social_login', 'set_providers', ['facebook', 'twitter', 'google']]);
    _oneall.push(['social_login', 'set_grid_sizes', [1,3]]);
    _oneall.push(['social_login', 'set_custom_css_uri', css]);
    _oneall.push(['social_login', 'set_callback_uri', social_callback_script]);
    _oneall.push(['social_login', 'set_event', 'on_login_end_success', function(arg) {
        // console.log("on_login_end_success");
    }]);
    _oneall.push(['social_login', 'set_event', 'on_login_redirect', function(arg) {
        // console.log("on_login_redirect");
        Tremr.dispatcher.message(this, "tremr:social_login", arg);
        this.setState({
          isLoading: true,
        });
        return false;
    }.bind(this)]);

    // seriously?  needs to be run last otherwie the JS doesn't fire!
    _.defer(function() {
        _oneall.push(['social_login', 'do_render_ui', 'social_signup']);
    });


  },

  componentDidUpdate: function(prevProps, prevState) {

    // this.renderSocialSignin(); 
  },

  signin: function(event) {
    if (event) {
      if (typeof ga !== "undefined") {
        ga("send", "event", "button", "sign-in-submit");
      }
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.state.isLoading) {
      return;
    }
    this.setState({
      isLoading: true
    });

    var data = {
      email: this.refs.email.state.value,
      password: this.refs.password.state.value
    };

    Tremr.dispatcher.message(this, "tremr:user:signin", data);
  },

  requestInvite: function(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.state.isLoading) {
        return;
      }

      Tremr.dispatcher.message(this, "tremr:open:requestinvite", this.props.index);
      Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
  },

  forgotPassword: function(event) {
    if (event) {
      if (typeof ga !== "undefined") {
        ga("send", "event", "button", "forgot-password");
      }
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.state.isLoading) {
      return;
    }
    this.setState({
      isLoading: true,
      isForgottenPassword: true
    });

    var data = {
      email: this.refs.email.state.value
    };

    Tremr.dispatcher.message(this, "tremr:user:forgotpassword", data);
  },

  render: function() {

      if (this.state.tab == 'forgotpassword') {
        return <div className="user-signin layout-staticpage forgotpasswordmessage">
            <div className="content">
                <h2 className="center">Forgot Password</h2>
                <p className="center multiline">An email has been sent to {this.state.forgottenEmail}.<br /><br />
                Follow the instructions to reset your password.<br /><br />
                <button onClick={this.props.onClose} className="submit">OK</button>
                </p>
            </div>
        </div>;
      }

    var self = this;

    var buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading
    });

    var buttonLabel = 'Sign In';
    if (this.state.isLoading) {
      buttonLabel = 'Signing-in';
    }
    if (this.state.isForgottenPassword) {
      buttonLabel = 'Password Reset';
    }

    let socialSignin = null;
    if (Tremr.Theme.RenderSocialSignin) {
      //socialSignin = [<p key="info" className="info">Or sign in with your preferred social media:</p>,
        //              <div key="social_signup" id="social_signup"></div>];
    }

    return (

        <div className="user-signin layout-staticpage">

            <div className="toolbar">
                <div className="help" onClick={this.requestInvite}>Don't have an account?</div>
            </div>

            <form className="content tx2">
                <h2>Sign in to Your Tremr Account</h2>

                <Tremr.Generic.Input ref="email" name="email" label="Email" type="email" initialValue={self.state.email} maxLength="50" />
                <Tremr.Generic.Input onEnter={this.signin} ref="password" name="password" label="Password" type="password" initialValue={self.state.password} maxLength="50" />

                <p className="help">
                    <a onClick={this.forgotPassword}>Forgot your password?</a>
                </p>

                <p>
                <button onClick={this.signin} className={buttonClasses}>{buttonLabel}</button>
                </p>

                {socialSignin}
            </form>
        </div>
    );
  }

});
