// var TremrTracking = require('../../utils/tracking');
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  openSignIn: function (event) {
    if (typeof ga !== "undefined") {
      ga("send", "event", "button", "sign-in");
    }
    event.stopPropagation();
    event.preventDefault();
    Tremr.dispatcher.message(this, "tremr:open:signin", event);
  },

  openRequestInvite: function (event) {
    if (typeof ga !== "undefined") {
      ga("send", "event", "button", "request-invite");
    }
    event.stopPropagation();
    event.preventDefault();
    Tremr.dispatcher.message(this, "tremr:open:requestinvite");
    //Tremr.dispatcher.message(this, "tremr:open:pitchpage");
  },

  render: function () {
    if (this.state.userUnchecked || this.state.user) {
      return null;
    } else {
      return (
        <div className="user-signinupmenu">
          <div className="signinup-link" onClick={this.openSignIn}>
            <a href="/signin" onClick={this.openSignIn}>
              Sign in
            </a>
          </div>
          <div
            className="signinup-link get-started"
            onClick={this.openRequestInvite}
          >
            <a href="/request-invite" onClick={this.openRequestInvite}>
              Get&nbsp;Started
            </a>
          </div>
        </div>
      );
    }
  },
});
