require("./scss/profilemenu.scss");

var TremrTracking = require('../../utils/tracking');
var UnseenCount = require('../generic/unseencount');
var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');
var SvgIcon = require('../generic/svgicon');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

    openSidebar: function(event) {
		event.preventDefault();
		event.stopPropagation();
		Tremr.dispatcher.message(this, "tremr:user:sidebar", true);
	},

    openSidebarActivity: function(event) {
		event.preventDefault();
		event.stopPropagation();
		Tremr.dispatcher.message(this, "tremr:user:sidebar:activity", true);
	},

	render: function() {

		if (this.state.user) {

			var avatar = <Tremr.Generic.SvgIcon icon="user" />;
			if (this.state.user.get('avatar_image')) {
				avatar = <Tremr.Generic.Image key={"avatar"+this.state.user.get('avatar_image').public_id} height={26} width={26} initialBackgroundColor="#4c4957" image={this.state.user.get('avatar_image')} />;
			}

			// var nameKey = this.state.user.get('name').replace(/\W/g, '_');
			// var name = <span key={nameKey}>{Tremr.Utils.truncate(this.state.user.get('name'), 18, '')}</span>;

			var profileUrl = Tremr.routes.urlFor({
				primary: {
		         	target: 'feed',
	                scope: 'all',
	                subfeed: 'featured',
	                feed: this.state.user.get('feedname')
		        }
			});

			return (
				<div className="user-profilemenu avatar">
                    <div onClick={this.openSidebarActivity}>
                        <SvgIcon icon="notifications" />
                        <UnseenCount total_path={'notifications/users/{user}/notifications_count'} seen_path={'notifications/users/{user}/seen_count'} />
                    </div>
					<div onClick={this.openSidebar}>
						{avatar}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
});
