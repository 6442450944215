var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// rich text editor block
// Tremr.Editor.TwitterControl
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      identifier: PropTypes.string.isRequired,
      notifyPosition: PropTypes.func.isRequired, // notify parent of position
      removeBlock: PropTypes.func.isRequired, // notify parent of position
      code: PropTypes.string.isRequired,
      readOnly: PropTypes.bool
  },

  lastBounds: false, // keep track of bounds so we only notify if actually changed

  getDefaultProps: function() {

    return {
      readOnly: false
    }
  },

  getInitialState: function() {
    return {
      loading: true
    }
  },

  // set focus to this element (do nothing)
  focus: function() {

  },

  renderTwitterWidgets: function(node) {

    if (window['twttr']) {

      // window.twttr.events.bind('loaded', function (event) {
      //   this.setState({
      //     loading: false
      //   });
      //
      //   this.notifyPosition();
      //
      // }.bind(this));

      window.twttr.widgets.load(node);
    } else {
      // this.setState({
      //   loading: false
      // });
      //
      // _.delay(this.notifyPosition, 200);
    }

    this.setState({
      loading: false
    });

    _.delay(this.notifyPosition, 200);
  },

  componentDidMount: function() {
    var domNode = ReactDOM.findDOMNode(this);
    this.renderTwitterWidgets(domNode);
    this.notifyPosition();
  },

  componentDidUpdate: function() {
    var domNode = ReactDOM.findDOMNode(this);
    this.renderTwitterWidgets(domNode);
    this.notifyPosition();
  },

  // notify parent of it's position
  notifyPosition: function() {

    if (!this.props.readOnly && !this.state.loading) {
      var node = ReactDOM.findDOMNode(this);
      var bounds = node.getBoundingClientRect();
      if (bounds.top != this.lastBounds.top || bounds.height != this.lastBounds.height) {
        if (!this.props.notifyPosition(this.props.identifier, bounds, (bounds.height != this.lastBounds.height), this)) {
          this.lastBounds = bounds;
        }
      }
    } else if (!this.props.readOnly) {
      _.delay(_.throttle(this.notifyPosition, 1000), 200);
    }
  },

  // tell the editor to remove this block
  handleRemove: function(event) {

    event.preventDefault();
    event.stopPropagation();
    this.props.removeBlock(this.props.identifier);
  },

  handleImageLoad: function() {
    _.delay(this.notifyPosition, 200);
  },

  render: function() {

    var classNames = require('../../utils/classnames');
var classes = classNames({
        'block': true,
        'twitter-block': true,
        'loading': this.state.loading
    });

    var embed = <div className="tweet"></div>;
    if (this.props.code) {

      var getEmbedHTML = function() {
          return {
              __html: this.props.code
          };
      }.bind(this);

    //   const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
    // divRef.current.innerHTML = '' // Clear the container
    // divRef.current.appendChild(slotHtml) // Append the new content
    //
      embed = <div className="tweet" dangerouslySetInnerHTML={getEmbedHTML()} />;
    }

    var removeButton = null;
    if (!this.props.readOnly) {
      removeButton = <a onClick={this.handleRemove} className="remove">
        <Tremr.Generic.SvgIcon icon="remove" />
      </a>;
    }

    return (<div className={classes} contentEditable="false" data-id={this.props.identifier}>
      {embed}
      {removeButton}
    </div>);

  }

});
