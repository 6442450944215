require("./scss/cardbreadcrumbs.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var TremrUtils = require('../../utils/tremr_utils');
var CardBreadcrumb = require('./cardbreadcrumb');
var CreateReactClass = require('create-react-class');

// a list of breadcrumbs, created from a list of ancestors from a post
module.exports = CreateReactClass({


	mixins: [PureRenderMixin],

	propTypes: {
        onToggle: PropTypes.func,
        width: PropTypes.number
    },

	getDefaultProps: function() {

		return {
			onToggle: function(state) { }
		}
	},

	getInitialState: function() {
		return {
			expanded: false,
			expandToggle: PropTypes.func
		}
	},

	// open all breadcrumbs
	toggleExpand: function(event) {

		event.preventDefault();
		event.stopPropagation();

		this.setState({
			expanded: !this.state.expanded
		});

		if (this.props.onToggle) {
			this.props.onToggle(!this.state.expanded);
		}
	},

	// draw cards
    render: function() {

    	// work out our top level class
		var classes = classNames({
			'post-cardbreadcrumbs': true,
			'expanded': this.state.expanded
		});

		var toggleButton = null;
		if (!this.state.expanded && this.props.posts && this.props.posts.length > 0) {
			toggleButton = <div title="Show Conversation" className="toggle show">
                <div className="count">{this.props.posts.length}</div>
			</div>;
		} else if (this.state.expanded && this.props.posts && this.props.posts.length > 0) {
			toggleButton = <div title="Hide Conversation" className="toggle">
				<Tremr.Generic.SvgIcon icon="up" />
			</div>;
		}

		var content = null;
		if (!this.state.expanded && this.props.posts && this.props.posts.length > 0) {
			content = <div onClick={this.toggleExpand} className="breadcrumbs-headline">
				{toggleButton}
			</div>;

		} else if (this.state.expanded && this.props.posts && this.props.posts.length > 0) {
			// expanded so content is the list
			content = <div className="breadcrumbs-list">
				<div onClick={this.toggleExpand} className="breadcrumbs-headline">
					{toggleButton}
				</div>
	            {_.map(this.props.posts, function(ancestor, index) { // use GUID !
		          return <CardBreadcrumb ref={"breadcrumb-"+index} key={"breadcrumb-"+index} isFirst={index == 0} expanded={this.state.expanded} expandHandler={this.toggleExpand} post={ancestor} />;
		        }.bind(this))}
	        </div>
		}

		let styles = {};
		if (this.props.width) {
			styles.width = this.props.width;
		}


		if (this.props.posts && this.props.posts.length > 0) {
			return (
	        <div className={classes} style={styles}>
	        	{content}
	        </div>
	        );
		} else {
			return null;
		}
    }
});
