require("./scss/notifications.scss");

var UserAwareMixin = require("../../mixins/userawaremixin");
var ActivityListItem = require("../activity/listitem");
var classNames = require("../../utils/classnames");
var CreateReactClass = require("create-react-class");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  lastScrollTop: 0,
  notificationsRef: null,
  notificationsRefPage1: null,

  getInitialState: function () {
    return {
      limit: 20,
      notifications: [],
      isLoading: true,
      lastLoadedCount: 0,
    };
  },

  componentDidMount: function () {
    this.bindToFirebase();
  },

  componentDidUpdate: function () {
    this.bindToFirebase();
  },

  bindToFirebase: function () {
    if (!this.notificationsRef && this.state.user) {
      // connect to firebase to get first page
      this.notificationsRef = Tremr.firebase
        .database()
        .ref(
          "notifications/users/" + this.state.user.get("_id") + "/notifications"
        );
      this.notificationsRefPage1 = this.notificationsRef
        .orderByChild("timestamp")
        .limitToLast(this.state.limit);
      this.notificationsRefPage1.on(
        "value",
        function (snapshot) {
          let notifications = [];

          // iterate children to preserve order
          snapshot.forEach((child) => {
            // add in reverse order
            let n = child.val();
            n.key = child.key;
            notifications.unshift(n);
          });

          let lastLoadedCount = notifications.length;

          // merge in new ones
          if (this.state.notifications && this.state.notifications.length > 0) {
            notifications = notifications.concat(this.state.notifications);
          }

          // update
          this.setState({
            notifications: notifications,
            isLoading: false,
            lastLoadedCount: lastLoadedCount,
          });
        }.bind(this)
      );
    }
  },

  componentWillUnmount: function () {
    // disconnect from firebase
    if (this.notificationsRef) {
      this.notificationsRef.off();
    }
  },

  // load the next page from firebase
  loadNextPage: function () {
    // only load if we have a full set currently loaded (or there are no more pages)
    if (
      !this.state.isLoading &&
      this.state.lastLoadedCount &&
      this.state.lastLoadedCount >= this.state.limit
    ) {
      // stop multiple calls
      this.setState({
        isLoading: true,
      });

      // use the timestamp of the oldest current item (ie the last one)
      // to offset the query
      let firstKey = this.state.notifications[
        this.state.notifications.length - 1
      ].timestamp;

      // load an extra one (the one we stop on has already been loaded)
      let nextPageLimit = this.state.limit + 1;

      // connect once to the next page of data
      this.notificationsRef = Tremr.firebase
        .database()
        .ref(
          "notifications/users/" + this.state.user.get("_id") + "/notifications"
        );
      this.notificationsRefPage1 = this.notificationsRef
        .orderByChild("timestamp")
        .limitToLast(nextPageLimit)
        .endAt(firstKey);
      this.notificationsRefPage1.once(
        "value",
        function (snapshot) {
          let notifications = [];

          // iterate children to preserve order
          snapshot.forEach((child) => {
            // add in reverse order
            let n = child.val();
            n.key = child.key;
            notifications.unshift(n);
          });

          // first one is always a duplicate because our END includes it
          notifications.shift();
          let keys = _.map(notifications, (n) => {
            return n.key;
          });
          let lastLoadedCount = notifications.length;

          // dedupe versus existing notifications
          notifications = _.reject(notifications, (n) => {
            // reject if present in state
            // (not sure why the dedupe after concat doesn't deal with this)
            return _.findWhere(this.state.notifications, { key: n.key });
          });

          // merge in new ones at the end
          if (this.state.notifications && this.state.notifications.length > 0) {
            notifications = this.state.notifications.concat(notifications);
          }

          // dedupe before sending to react
          // notifications = _.uniq(notifications, false, (n) => {
          // 	return n.key
          // });

          // update
          this.setState({
            notifications: notifications,
            isLoading: false,
            lastLoadedCount: lastLoadedCount,
          });
        }.bind(this)
      );
    }
  },

  seeAll: function (event) {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:activity:seenall");
  },

  openSettings: function (event) {
    event.preventDefault();
    // event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:open:notificationsettings");
  },

  noteFactory: function (data) {
    // console.log(data.notification_type);
    // console.dir(data);

    let key = "n-" + data.key;
    let model = {
      get: function (attr) {
        return data[attr];
      },
    };
    return (
      <ActivityListItem mykey={key} ref={key} key={key} activity={model} />
    );
  },

  render: function () {
    var list = null;
    var header = null;

    // hide the whole view if the user isn't signed-in
    if (this.state.user) {
      // <Tremr.Feed.ViewChart />
      header = (
        <div className="section menu">
          <a onClick={this.seeAll}>Clear All</a>
          <a onClick={this.openSettings}>Settings</a>
        </div>
      );

      let classes = {
        notifications: true,
        section: true,
        loading: this.state.isLoading,
      };
      classes = classNames(classes);

      list = (
        <div className={classes}>
          {this.state.notifications.map((notification) => {
            return this.noteFactory(notification);
          })}
        </div>
      );
    }

    return (
      <nav ref="notificationsContainer" className="layout-notifications">
        {header}
        {list}
      </nav>
    );
  },
});
