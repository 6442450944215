var CreateReactClass = require('create-react-class');

// a label followed by a link
// Tremr.Generic.LabelLink = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	handleClick: function(event) {
		if (this.props.onClick) {
			var $el = $(event.currentTarget);
			event.preventDefault();
			event.stopPropagation();
			this.props.onClick($el.attr('data-value'));
		} else if (this.props.href == undefined) {
			event.preventDefault();
			event.stopPropagation();
		}

	},

	render: function() {

		return (
			<label onClick={this.handleClick} data-value={this.props.value} className={this.props.className}>{this.props.label}
			<a href={this.props.href} onClick={this.handleClick} data-value={this.props.value}>{this.props.link}</a></label>
		);
	}
});
