var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// displays sum of several activities on a post
module.exports = CreateReactClass({

    propTypes: {
        tags: PropTypes.array.isRequired,
        onClick: PropTypes.func
    },

    getEncodedTag: function(tag) {
        return tag.replace(/\s/g, ':');
    },

    onClick: function(event) {
        if (this.props.onClick) {
            event.preventDefault();
            event.stopPropagation();
            this.props.onClick();
        }
    },

    // render
    render: function() {

        // any values (even 0) will set value and sum
        let value = null;
        this.props.tags.forEach((tag) => {
          if (this.props.liveDataPoints[this.getEncodedTag(tag)+'-sum'].data != null) {
            if (value == null) value = 0;
            value = value + this.props.liveDataPoints[this.getEncodedTag(tag)+'-sum'].data;
          }
        });

        // but if all data points are null value remains null and we can return empty
        if (value == null) {
            return null;
        }

        return <div className="activity-sum" onClick={this.onClick}>
            <output>{value}</output>
            {this.props.children}
        </div>
    }
});
