require("./scss/sidebar.scss");

var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var Config = require("config");
var SvgIcon = require("../generic/svgicon");
var WithFBData = require("../generic/withFBData");
var Dashboard = require("./dashboard");
var DashboardWithFBData = WithFBData(Dashboard);

module.exports = CreateReactClass({
  // mixins: [PureRenderMixin, UserAwareMixin],
  mixins: [UserAwareMixin],

  getInitialState: function () {
    return {
      isLoading: true,
      promises: [],
    };
  },

  loadFeed: function (user) {
    // load the feed
    var feed = new Tremr.Models.Feed({
      id: user.get("feedname"),
    });
    var promise = Tremr.stores.feedStore.loadFeed(
      feed,
      user.get("feedname"),
      false
    );
    this.setState({
      feed: feed,
      promises: this.state.promises.slice(0).concat(promise),
    });
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.setState({
          isLoading: false,
          feed: model,
        });

        // set a global highlight colour - read from user!
        if (model.get("highlight_color")) {
          // document.documentElement.style.setProperty('--feed-highlight-color', model.get('highlight_color'));
          Tremr.setFeedHighlightColor(model.get("highlight_color"));
        } else {
          // document.documentElement.style.setProperty('--feed-highlight-color', Config.colors.highlight);
          Tremr.setFeedHighlightColor(Config.colors.highlight);
        }
      }.bind(this)
    );
  },

  // load data from server when first added to DOM
  componentDidMount: function () {
    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // listen for sign-in/out and update user in our session
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signin:success",
      function (user) {
        if (!this.state.feed || !this.state.feed.get("name")) {
          this.loadFeed(user);
        }
      }.bind(this)
    );

    // until we load se the highlight as grey
    // document.documentElement.style.setProperty(
    //   "--feed-highlight-color",
    //   "#cccccc"
    // );
    Tremr.setFeedHighlightColor("#cccccc");

    // if we have a user, load there feed
    if (this.state.user) {
      this.loadFeed(this.state.user);
    }
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.state.promises = _.reject(this.state.promises, function (promise) {
      promise.cancel();
      return true;
    });

    if (this.stopListening) {
      this.stopListening();
    }

    // return hard-coded highlight color
    // document.documentElement.style.setProperty(
    //   "--feed-highlight-color",
    //   Config.colors.highlight
    // );
    Tremr.setFeedHighlightColor(Config.colors.highlight);
  },

  render: function () {
    var classes = {
      "user-sidebar-reputation": true,
      sidebar: true,
      loading: this.state.isLoading,
    };
    classes = classNames(classes);

    if (this.state.isLoading) {
      return <div className={classes}></div>;
    }

    // load the whole node for that user
    let reputationDataPoints = [
      {
        name: "reputation",
        key: "/community/reputation/users/" + this.state.user.get("_id"),
      },
      {
        name: "bars",
        key: "/community/reputation/star_bars",
      },
    ];

    let highlightColor = Config.colors.highlight;
    if (this.state.feed.get("highlight_color")) {
      highlightColor = this.state.feed.get("highlight_color");
    }

    //this.state.feed.get('author_name')
    return (
      <div className={classes}>
        <SvgIcon
          key="close"
          icon="remove"
          classes="close icon"
          onClick={this.props.onClose}
        />
        <div className="controls">
          <DashboardWithFBData
            dataPoints={reputationDataPoints}
            user={this.state.user}
            highlightColor={highlightColor}
          />
        </div>
      </div>
    );
  },
});
