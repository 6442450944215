var CreateReactClass = require('create-react-class');

// SVG based logo (just tr), allowing us to add styles
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultProps: function() {
		return {
			baseColor: '#4a4a4a',
			highlightColor: '#bb9f9f',
			height: 512,
			width: 512
		}
	},

	render: function() {

		var tremrStyle = {
			fill: this.props.baseColor
		};
		var quotesStyle = {
			fill: this.props.highlightColor
		};

		// calculate properties if requested
		var width = this.props.width;
		var height = this.props.height;
		if (height == 'auto' && width == 'auto') {
			height = 512; width = 512;
		} else if (height == 'auto' && 512 != 'auto') {
			height = Math.round((width/512)*530);
		} else if (height != 'auto' && width == 'auto') {
			width = Math.round((height/512)*512);
		}

		return (
			<svg
			 id="tremr-logo"
			 width={width}
			 height={height}
			 viewBox="0 0 512 512">
			  <g>
			  <path
			   id="tremr"
			   style={tremrStyle}
			   d="M441,0 C480.212,0 512,31.788 512,71 L512,441 C512,480.212 480.212,512 441,512 L71,512 C31.788,512 0,480.212 0,441 L0,71 C0,31.788 31.788,0 71,0 L441,0 z M216.714,191.183 L216.714,146 L164.445,156.104 L164.445,191.183 L126,191.183 L126,228.434 L192.686,228.46 L164.445,254.796 L164.445,326.642 C164.445,352.881 182.054,366 217.27,366 L350.26,366 L350.26,328.758 L308.909,328.758 L308.909,240.811 C318.571,233.314 327.075,229.386 334.422,229.026 C337.441,228.878 339.592,230.171 340.874,232.905 L346.303,246.607 L385.997,246.607 C385.856,209.62 385.856,191.064 385.997,190.939 C386.385,190.593 352.398,184.092 329.057,192.721 C321.251,195.607 311.837,201.227 300.815,209.581 L293.724,191.183 L216.714,191.183 z M230.093,228.434 L230.093,228.46 L279.682,228.46 L254.912,251.506 L254.912,328.758 C245.248,328.758 237.161,328.758 230.649,328.758 C219.1,328.758 217.401,321.385 217.27,311.802 C216.9,284.704 216.714,256.924 216.714,228.46 L230.093,228.434 z" />
			  </g>
			</svg>
		);
	}
});
