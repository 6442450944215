var Backbone = require('backbone');
var Config = require('config');

// app state as a model (not backbone though, just useful place to keep it)
// Tremr.Models.State
module.exports = function(){

    // keep reference to self
    var self = this;

    // allow us to throw events
    _.extend(this, Backbone.Events);

    // start with empty state
    this.data = {};

    // get our state
    this.getState = function() {
    	return self.data;
    };

    // remove an element from an array from the index
    this.removeFromArray = function(key, index, suppressEvent) {

		if (suppressEvent == undefined) {
			suppressEvent = false;
		}

		if (self.data[key] && self.data[key][index]) {
			self.data[key].splice(index, 1);
		}

		if (suppressEvent != true) {
			self.trigger("state:changed", self, {});
		}
	};


    // add an element to an array
    this.addToArray = function(key, item, suppressEvent) {

		if (suppressEvent == undefined) {
			suppressEvent = false;
		}

		if (self.data[key] === undefined) {
			self.data[key] = [];
		}

		self.data[key].push(item);

		var newdata = [];
		newdata[key] = [item];

		if (suppressEvent != true) {
			self.trigger("state:changed", self, newdata);
		}
	};


    // add data to our json
	this.add = function(newdata, suppressEvent) {

        if (suppressEvent == undefined) {
			suppressEvent = false;
		}

		self.data = _.extend(self.data, newdata)

		if (suppressEvent != true) {
			self.trigger("state:changed", self, newdata);
		}
	};

	// remove a path of nodes from our json
	this.remove = function(paths, suppressEvent) {

		if (suppressEvent == undefined) {
			suppressEvent = false;
		}

		_.each(paths, function(path) {

			if (!_.isArray(path)) {
				path = [path];
			}

			// remove the node
			var obj = this.data;
			for (var i = 0; i < path.length; i++) {

				if (obj) {
					// last one removed, or we just move down the chain
					if (i+1 == path.length) {
						delete obj[path[i]];
					} else {
						obj = obj[path[i]];
					}
				}
			}
		}.bind(this));

		if (suppressEvent != true) {
			self.trigger("state:changed", self, paths);
		}
	};

	// batch add and remove state state
	this.batch = function(newdata, removepaths) {

		self.remove(removepaths, true);
		self.add(newdata, true);

		self.trigger("state:changed", self, newdata, removepaths);
	};

	// refresh the current state
	this.refresh = function(item) {

		this.trigger("state:refresh", this, item, {});
	}.bind(this)

};
