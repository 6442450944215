require("./scss/postmenu.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var classNames = require('../../utils/classnames');
var SvgIcon = require('../generic/svgicon');

module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      isSaving: PropTypes.bool,
      savingText: PropTypes.string,
      // canDelete: PropTypes.bool,
      // deleteText: PropTypes.string,
      // isHeadline: PropTypes.bool,
      saveText: PropTypes.string,
      save: PropTypes.func,
      // draft: PropTypes.func,
      // delete: PropTypes.func,
      // preview: PropTypes.func,
      // toggleFeatured: PropTypes.func,
      // toggleHeadline: PropTypes.func,
      closeEditor: PropTypes.func
  },

  getDefaultProps: function() {
    return {
      isSaving: false,
      // canDelete: false,
      // isHeadline: false,
      saveText: 'Post',
      deleteText: 'Delete',
      savingText: 'Posting'
    }
  },

  getInitialState: function() {
      return {
          open: false
      };
  },

  // raise event to close editor
  closeEditor: function() {
    Tremr.dispatcher.message(this, "tremr:editor:close");
  },

  // main save button clicked - either when open or closed
  save: function(event) {
      this.props.save(event);
  },

  // open/close this menu
  toggleOpen: function(event) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
          open: !this.state.open
      });
  },

  // discard: function(event) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     // default to discard closing the editor
  //     if (this.props.canDelete) { // post or draft can be deleted
  //       this.props.delete();
  //     } else {
  //       this.closeEditor();
  //     }
  // },

  sidebarClick: function(event) {
      event.preventDefault();
      event.stopPropagation();
  },

  render: function() {

    let classes = classNames({
        "post-menu": true,
        open: this.state.open,
        loading: this.props.isSaving
    });

    let buttonText = "Post";
    if (this.props.isSaving) {
      buttonText = this.props.savingText;
      if (this.props.saveText == 'Update') {
        buttonText = 'Updating';
      }
    }

    // var preview = <div onClick={this.props.preview} className="preview"><Tremr.Generic.SvgIcon icon="view" /></div>;
    // var draft = <div onClick={this.props.draft} className="draft"><Tremr.Generic.SvgIcon icon="save" /></div>;
    // var discard = <div onClick={this.discard} className="discard"><Tremr.Generic.SvgIcon icon="delete" /></div>;
    // {preview}
    // {discard}
    // {draft}
    // <SvgIcon key="close" icon="remove" classes="close icon" onClick={this.props.closeEditor}/>

    if (this.state.open) {
        return <div className={classes}>
            <div className="overlay" onClick={this.toggleOpen}>
                <div className="sidebar" onClick={this.sidebarClick}>
                    <div className="postmenu-toolbar toolbar">
                        <a className="close" onClick={this.toggleOpen}>
                          <Tremr.Generic.SvgIcon icon="remove" />
                        </a>
                        <div className="save-button" onClick={this.save}>{buttonText}</div>
                        <div className="toggle-button" onClick={this.toggleOpen}>
                            <SvgIcon icon="up" />
                        </div>
                    </div>
                    <div className="controls">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>;
    } else {
        return <div className={classes}>
            <div className="postmenu-toolbar">
                <div className="save-button" onClick={this.save}>{buttonText}</div>
                <div className="toggle-button" onClick={this.toggleOpen}>
                    <SvgIcon icon="dropdown" />
                </div>
            </div>
        </div>;
    }
  }
});
