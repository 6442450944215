require("./scss/overlays.scss");

var CreateReactClass = require('create-react-class');

// array of overlays, array order dictates order (numbered classes, lets css deal with actual zindex)
// Tremr.Layout.Overlays = CreateReactClass({
module.exports = CreateReactClass({

  // mixins: [PureRenderMixin],

	render: function() {

  	var self = this;

    // return blank if no overlays
  	if (self.props.overlays && self.props.overlays.length > 0) {

    	return <div className="layout-overlays">
              {self.props.overlays.map(function(overlay, index) {
									if (overlay.class) {
                      return <Tremr.Layout.Overlay handleClose={overlay.handleClose} style={overlay.style} content={overlay.class} key={'overlay-'+index} index={index} props={overlay.props} />
                  }
              })}
		        </div>;
    } else {
      return <div className="layout-overlays empty"></div>;
    }
  }

});
