// simple functions to allow copy using execCommand

// create fake textarea, add the content and copy the text to the clipboard
module.exports = {

    fakeElement: false,

    copy: function(text, window, document) {

        if (document.queryCommandSupported('copy') &&
            document.queryCommandEnabled('copy')) {

            // create or update a fake element offscreen
            if (!this.fakeElement) {
                this.fakeElement = document.createElement('textarea');
                this.fakeElement.style.position = 'absolute';
                this.fakeElement.style.left = '-9999px';
                this.fakeElement.style.zIndex = '99999';
                this.fakeElement.setAttribute('readonly', '');
                document.body.appendChild(this.fakeElement);
            }
            $(this.fakeElement).empty().val(text);
            this.fakeElement.style.top = (window.pageYOffset || document.documentElement.scrollTop) + 'px';

            // select the content
            this.fakeElement.focus();
            this.fakeElement.setSelectionRange(0, text.length + 999);

            // copy it to the clipboard
            try {
                if (document.execCommand('copy')) {
                    return true;
                } else {
                    return false;
                }
            } catch(err) {
                return false;
            }
        } else {
            return false;
        }
    }
}

