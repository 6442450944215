var classNames = require("../../utils/classnames");
// var PropTypes = require('prop-types');
var CreateReactClass = require("create-react-class");

module.exports = CreateReactClass({
  getEncodedTag: function (tag) {
    return tag.replace(/\s/g, ":");
  },

  onClick: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.activity &&
      this.props.liveDataPoints.activity.data &&
      this.props.liveDataPoints.activity.data.recommend
    ) {
      this.props.onOpen();
    }
  },

  render: function () {
    let list = [];
    let tags = Tremr.stores.activityStore.reactions;

    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.activity &&
      this.props.liveDataPoints.activity.data
    ) {
      list = tags.map((tag) => {
        let item = null;
        if (this.props.liveDataPoints.activity.data[this.getEncodedTag(tag)]) {
          item = (
            <div
              onClick={this.onClick}
              key={"activity-" + this.getEncodedTag(tag)}
              className={"activity " + tag}
            >
              {tag.replace(/:/g, " ")}
            </div>
          );
          // delim = ",";
        }
        return item;
      });

      if (
        this.props.liveDataPoints.activity.data.recommend &&
        this.props.liveDataPoints.activity.data.recommend > 0
      ) {
        list.push(
          <div
            onClick={this.onClick}
            key="activity-upvote"
            className="activity upvote"
          >
            +{this.props.liveDataPoints.activity.data.recommend}
          </div>
        );
      } else if (
        this.props.liveDataPoints.activity.data.recommend &&
        this.props.liveDataPoints.activity.data.recommend < 0
      ) {
        list.push(
          <div
            onClick={this.onClick}
            key="activity-downvote"
            className="activity downvote"
          >
            {this.props.liveDataPoints.activity.data.recommend}
          </div>
        );
      }
    }

    return <div className="reaction-summary">{list}</div>;
  },
});
