require("../scss/fieldset/article.scss");

var classNames = require('../../../utils/classnames');
var TremrUtils = require('../../../utils/tremr_utils');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	propTypes: {
        fields: PropTypes.object.isRequired,
        errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
        change: PropTypes.func.isRequired,
	},

    getInitialState: function() {
        return {
            isComment: true
        };
    },

    changeTags: function(data) {
        this.props.change({
            field: "tags",
            data: data
        });
    },

    changeTitle: function(data) {
        this.props.change({
            field: "title",
            data: data
        });
    },

    changeHeader: function(mode, data) {

        let headerData = {
            mode: mode,
            data: data
        }

        this.props.change({
            field: "header",
            data: headerData
        });
    },

    invalid: function(data) {

        var errors = false;

        // check title is present
        if (data.title == '') {
            if (!errors) {
                errors = {};
            }
            errors.title = {
                message: 'Please enter a title'
            };
        }

        // check lengths of title
        if (data.title.length > 280) {
            if (!errors) {
                errors = {};
            }
            errors.title = {
                message: 'Please reduce to 280 characters or less'
            };
        }

        // length of header caption (if we have one)
		if (data.headerData) {
			let headerCaption = this.refs['header'].state.caption;
			if (headerCaption && headerCaption.length > 280) {
				if (!errors) {
					errors = {};
				}
				errors.header = {
					message: 'Please reduce to 280 characters or less'
				};
			}
		}

        return errors;
    },

    getFieldsContent: function(data) {

        let attributes = {};

        attributes.title = data.title;
        attributes.tags = data.tags;
        attributes.summary = data.summary;

        // header, depends on type
		if (data.headerMode == 'image') {

			// add the caption if we have one
			var headerCaption = this.refs['header'].state.caption;
			if (headerCaption && headerCaption.length > 0) {
                attributes.headerData = data.headerData;
				attributes.headerData.caption = headerCaption;
			}
            attributes.image = data.headerData;
            delete attributes.embed;

		} else if (data.headerMode == 'embed') {

			// add the caption if we have one
			var headerCaption = this.refs['header'].state.caption;
			if (headerCaption && headerCaption.length > 0) {
				attributes.headerData = data.headerData;
				attributes.headerData.caption = headerCaption;
			}

			attributes.embed = data.headerData;
            delete attributes.image;

		} else {
			delete attributes.image;
            delete attributes.embed;
		}

		// get the content blocks
        attributes.content_blocks = this.refs['richtext'].getBlocksJson();

        return attributes;
    },

    countWords: function() {
        // dont use actual blocks - just use innerText of richtext element
        let words = "";
        let domNode = ReactDOM.findDOMNode(this);
        let richtextnode = $(domNode).find('.editor-richtext');
        if (richtextnode) {
            words = richtextnode.text();
            if (words == "Say some more…") {
                words = "";
            }
        }
        let wordCount = TremrUtils.countWords(words);
        this.refs['wordcount'].setState({count: wordCount});
        return wordCount;
    },

    contentChanged: function(data) {

        let words = this.countWords();
        let isArticle = (words > 0);

        this.setState({
            isComment: !isArticle
        });

        this.props.change({
            field: "content",
            data: data
        });
    },

    componentDidMount: function() {

        let words = this.countWords();
        let isArticle = (words > 0);
        this.setState({
            isComment: !isArticle
        });

        // focus on the title field
		if (this.refs['title']) {
			this.refs['title'].focus();
		}
    },


    richTextBlur: function() {

    },

    richTextFocus: function() {

    },

	render: function() {

        let titlePercentage = (this.props.fields.title.length / 280) * 100;
        if (titlePercentage > 100) {
            titlePercentage = 100;
        }
        let titleLengthStyles = {
            width: titlePercentage + '%'
        };
        let titleLength = <div className="title-length" style={titleLengthStyles}></div>;

        return <div className="fieldset article">

            <Tremr.Generic.Errorfield fieldName="tagsField" error={this.props.errors.tags} message={this.props.errors.tags
                    ? this.props.errors.tags.message
                    : ''}>
                <Tremr.Generic.TagEditor ref="tageditor" onChange={this.changeTags} initialTags={this.props.fields.tags}/>
            </Tremr.Generic.Errorfield>
            {titleLength}
			<Tremr.Generic.Errorfield error={this.props.errors.title} message={this.props.errors.title
					? this.props.errors.title.message
					: ''}>
				<Tremr.Editor.ContentEditable ref="title" key={"title-"+this.state.isComment} classNames={{
                        comment: true,
						h1: !this.state.isComment
					}} initialValue={this.props.fields.title} onChange={this.changeTitle} maxLength={280} placeholder="Say something…"/>
			</Tremr.Generic.Errorfield>

			<Tremr.Generic.Errorfield fieldName="header" error={this.props.errors.header} message={this.props.errors.header
					? this.props.errors.header.message
					: ''}>
				<Tremr.Editor.Header ref="header" height={430} width={768} initialMode={this.props.fields.headerMode} initialData={this.props.fields.headerData} onChange={this.changeHeader} image={this.props.fields.header_data}/>
			</Tremr.Generic.Errorfield>

			<div className="field">
				<Tremr.Editor.Richtext key="richtext" ref="richtext" onBlur={this.richTextBlur} onFocus={this.richTextFocus} onChange={this.contentChanged} initialBlocks={this.props.fields.content_blocks} readOnly={false}/>
				<Tremr.Editor.Count ref="wordcount" initialLabel="Words" initialCount={0}/>
			</div>
		</div>;
	}

});
