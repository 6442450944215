var CreateReactClass = require('create-react-class');

// app component, renders the page layout component
// and listens directly to the dispatcher for events
// that update state etc. as if it was a store.
// mainly it listens for the context change event and
// updates it's state which passes to Layout->Page as props.
module.exports = CreateReactClass({

	// when we are about to go into page start listening to events
	componentWillMount: function() {

		// handle the contextchanged event to update our components
	    Tremr.dispatcher.on("tremr:contextchanged", function(newState) {

	    	// this.replaceState(newState.getState());

	    	// set missing keys to null, so we can avoid replaceState
	    	let newstateObj = _.clone(newState.getState());

	    	// find keys in our state but not newState
	    	let missing = _.difference(_.keys(this.state), _.keys(newstateObj));

	    	// add them as null
	    	_.each(missing, function(missingKey) {
	    		newstateObj[missingKey] = null;
	    	})

	    	this.setState(newstateObj);
	    }, this);

	},

	render: function() {
		return <Tremr.Layout.Page ref='page' {...this.state} />;
	}

});
