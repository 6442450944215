require("./scss/profile.scss");

var classNames = require("../../utils/classnames");
var alertify = require("alertify");
var UserAwareMixin = require("../../mixins/userawaremixin");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var Config = require("config");
var ColorPicker = require("../generic/colorpicker");
var SocialLink = require("../user/sociallink");

// users profile editor
// Tremr.User.Profile = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  propTypes: {
    user: PropTypes.object.isRequired,
    showTags: PropTypes.bool,
  },

  getDefaultProps: function () {
    return {
      showTags: false,
    };
  },

  getInitialState: function () {
    return {
      tags: this.props.user.get("tags"),
      name: this.props.user.get("name"),
      newemail: this.props.user.get("email"),
      bio: this.props.user.get("bio"),
      blog: this.props.user.get("blog"),
      feedname: this.props.user.get("feedname"),
      twitter: this.props.user.get("twitter"),
      instagram: this.props.user.get("instagram"),
      location: this.props.user.get("location"),
      avatar_image: this.props.user.get("avatar_image"),
      header_image: this.props.user.get("header_image"),
      highlight_color: this.props.user.get("highlight_color"),
      custom_colors: this.props.user.get("custom_colors") || [],
      isLoading: false,
      usernameLocked: true,
      emailLocked: true,
    };
  },

  // register listeners
  componentDidMount: function () {
    if (this.state.highlight_color && !this.state.original_highlight_color) {
      this.onSelectColor(this.state.highlight_color);
      // this.setState({
      // 		original_highlight_color: document.documentElement.style.getPropertyValue('--highlight-color')
      // });
      //
      // // always default to standard color
      // // document.documentElement.style.setProperty('--highlight-color', this.state.highlight_color);
      // document.documentElement.style.setProperty('--highlight-color', Config.colors.highlight);
    } else if (!this.state.original_highlight_color) {
      this.onSelectColor(Config.colors.highlight);
    }

    _.extend(this, Backbone.Events);

    // save success, just close
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:profile:success",
      function (user) {
        Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
        alertify.log(
          "Your profile change(s) may take a moment to be processed"
        );
      }.bind(this)
    );

    // error saving, display errors
    this.listenTo(Tremr.dispatcher, "tremr:user:profile:failed", function (
      errors
    ) {
      _.each(
        _.pairs(errors),
        function (error) {
          if (this.refs[error[0]]) {
            var newState = {
              error: true,
              errorMessage: error[1],
            };
            if (error[0] == "newemail") {
              newState.newemail == false;
            }
            this.refs[error[0]].setState(newState);
          }
        }.bind(this)
      );

      this.setState({
        isLoading: false,
      });
    });
  },

  // unregister listeners
  componentWillUnmount: function () {
    if (this.state.original_highlight_color) {
      // document.documentElement.style.setProperty('--highlight-color', this.state.original_highlight_color);
      Tremr.setHighlightColor(this.state.original_highlight_color);
    }

    this.stopListening();
  },

  save: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    var data = {
      name: this.refs.name.state.value,
      location: this.refs.location.state.value,
      twitter: this.refs.twitter.state.value,
      instagram: this.refs.instagram.state.value,
      blog: this.refs.blog.state.value,
      bio: this.refs.bio.state.value,
      feedname: this.refs.feedname.state.value,
      avatar_image: this.refs.avatar.state.image,
      header_image: this.refs.header.state.image,
      highlight_color: this.state.highlight_color,
      custom_colors: this.state.custom_colors,
      user: this.props.user, // send the user - so admin can edit other users
    };

    if (this.refs.newemail) {
      data.newemail = this.refs.newemail.state.value;
    }

    if (this.props.showTags) {
      data.tags = this.state.tags;
    }

    Tremr.dispatcher.message(this, "tremr:user:profile:save", data);
  },

  cancel: function (event) {
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
  },

  changeTags: function (tags) {
    this.setState({
      tags: tags,
    });
  },

  unlockUsername: function (event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      usernameLocked: false,
    });
  },

  unlockEmail: function (event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      emailLocked: false,
    });
  },

  componentDidUpdate: function (prevProps, prevState) {
    if (
      this.state.usernameLocked === false &&
      prevState.usernameLocked === true
    ) {
      this.refs["feedname"].focus(true);
    } else if (
      this.state.emailLocked === false &&
      prevState.emailLocked === true
    ) {
      this.refs["newemail"].focus(true);
    }
  },

  onAddColor: function (c) {
    let newState = {
      highlight_color: c,
      custom_colors: this.state.custom_colors.concat([c]),
    };

    if (!this.state.original_highlight_color) {
      newState.original_highlight_color = document.documentElement.style.getPropertyValue(
        "--highlight-color"
      );
    }
    // document.documentElement.style.setProperty("--highlight-color", c);
    Tremr.setHighlightColor(c);

    this.setState(newState);
  },

  onSelectColor: function (c) {
    let newState = {
      highlight_color: c,
    };

    if (!this.state.original_highlight_color) {
      newState.original_highlight_color = document.documentElement.style.getPropertyValue(
        "--highlight-color"
      );
    }
    // document.documentElement.style.setProperty("--highlight-color", c);
    Tremr.setHighlightColor(c);

    this.setState(newState);
  },

  render: function () {
    var buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading,
    });

    var buttonLabel = "Save Changes";
    if (this.state.isLoading) {
      buttonLabel = "Saving Changes";
    }

    var upload = <Tremr.Generic.SvgIcon icon="photo" classes="button icon" />;

    var emailField = null;
    if (this.state.emailLocked) {
      emailField = (
        <Tremr.Generic.Input
          readOnly={true}
          ref="newemail"
          name="newemail"
          label="Email"
          placeholder="email@address.com"
          type="email"
          initialValue={this.state.newemail}
          maxLength={100}
        >
          <div className="unlock" onClick={this.unlockEmail}>
            Edit
          </div>
        </Tremr.Generic.Input>
      );
    } else {
      emailField = (
        <Tremr.Generic.Input
          ref="newemail"
          name="newemail"
          placeholder="email@address.com"
          label="Email"
          type="email"
          initialValue={this.state.newemail}
          maxLength={100}
        />
      );
    }

    // if (!this.state.newemail || this.state.newemail.length == 0 || (this.state.user && this.state.user.get('admin') == 'yes')) {
    // }

    var tags = null;
    if (
      this.props.showTags &&
      this.state.user &&
      this.state.user.get("admin") == "yes"
    ) {
      tags = (
        <p>
          <strong>Tags: </strong>
          <Tremr.Generic.TagEditor
            ref="tageditor"
            onChange={this.changeTags}
            initialTags={this.state.tags}
          />
        </p>
      );
    }

    let headerHeight = 320;
    let avatar_size = 200;
    if (Tremr.Utils.isDeviceOrNarrow()) {
      headerHeight = 200;
      avatar_size = 150;
    }

    let highlightColor = Config.colors.highlight;
    if (this.state.highlight_color) {
      highlightColor = this.state.highlight_color;
    }

    let header_image_upload = (
      <Tremr.Generic.Imageupload
        ref="header"
        showRemove={true}
        buttonText={upload}
        initialImage={this.state.header_image}
        previewImage={true}
        previewHeight={headerHeight}
        previewWidth={"auto"}
        previewResizable={true}
      />
    );

    let feedname = null;
    if (this.state.usernameLocked) {
      feedname = (
        <Tremr.Generic.Input
          readOnly={true}
          ref="feedname"
          name="feedname"
          label="Username"
          placeholder="Username"
          type="text"
          initialValue={this.state.feedname}
          maxLength={50}
        >
          <div className="unlock" onClick={this.unlockUsername}>
            Edit
          </div>
        </Tremr.Generic.Input>
      );
    } else {
      feedname = (
        <Tremr.Generic.Input
          ref="feedname"
          name="feedname"
          label="Username"
          placeholder="Username"
          type="text"
          initialValue={this.state.feedname}
          maxLength={50}
        />
      );
    }

    // <Tremr.Generic.Input ref="highlight_color" name="highlight_color" label="Highlight Color" placeholder="#RRGGBB" type="text" initialValue={this.state.highlight_color} maxLength="12" />
    // <Tremr.Generic.Input ref="twitter" name="twitter" label="Twitter Handle" placeholder="@twitter" type="text" initialValue={this.state.twitter} maxLength={50} />

    let twitterConnection = null;
    if (this.state.user && this.state.user.get("twitter_handle")) {
      twitterConnection = (
        <div className="field readonly twitter">
          <label>Twitter Handle</label>
          <div className="input">@{this.state.user.get("twitter_handle")}</div>
        </div>
      );
    } else {
      twitterConnection = (
        <div className="field twitter">
          <label>Twitter Handle</label>
          <div className="input social_link row">
            <span className="input">
              Connect your twitter account, there are some compelling reasons
              for this.
            </span>
            <span className="fixed end">
              <SocialLink user={this.state.user} />
            </span>
          </div>
        </div>
      );
    }
    //{feedname}
    // {twitterConnection}

    return (
      <div className="user-profile">
        <div className="toolbar">
          <button onClick={this.save} className={buttonClasses}>
            {buttonLabel}
          </button>
        </div>

        <div className="content tx2">
          <div className="header-image">{header_image_upload}</div>
          <div className="avatar">
            <Tremr.Generic.Imageupload
              ref="avatar"
              showRemove={false}
              buttonText={upload}
              initialImage={this.state.avatar_image}
              previewImage={true}
              previewHeight={avatar_size}
              previewWidth={avatar_size}
            />
          </div>

          <ColorPicker
            ref="colorpicker"
            colors={this.state.custom_colors}
            selectedColor={highlightColor}
            addColor={this.onAddColor}
            selectColor={this.onSelectColor}
          />

          <Tremr.Generic.Input
            ref="name"
            name="name"
            label="Name"
            placeholder="Your name"
            type="text"
            initialValue={this.state.name}
            maxLength={50}
          />
          {emailField}
          <Tremr.Generic.Input
            ref="bio"
            name="bio"
            multiline={true}
            label="Bio"
            placeholder="Say something about yourself..."
            type="text"
            initialValue={this.state.bio}
            maxLength={250}
          />
          {feedname}
          <Tremr.Generic.Input
            ref="location"
            name="location"
            label="Location"
            placeholder="Where are you?"
            type="text"
            initialValue={this.state.location}
            maxLength={50}
          />

          <Tremr.Generic.Input
            ref="twitter"
            name="twitter"
            label="Twitter Handle"
            placeholder="@twitter"
            type="text"
            initialValue={this.state.twitter}
            maxLength={50}
          />

          <Tremr.Generic.Input
            ref="instagram"
            name="instagram"
            label="Instagram"
            placeholder="@instagram"
            type="text"
            initialValue={this.state.instagram}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="blog"
            name="blog"
            label="Blog/Website"
            placeholder="http://www.xyz.com"
            type="text"
            initialValue={this.state.blog}
            maxLength={250}
          />
          {tags}
        </div>
      </div>
    );
  },
});
