var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.FeedView
module.exports = Backbone.Model.extend({
    urlRoot: Config.apiurl+'/api/views',

    url: function() {
    	var d = new Date();
		var tz = d.getTimezoneOffset();
        return this.urlRoot + '/' + this.get('id') + '?tz='+tz;
    }

});
