require("./scss/header.scss");

var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var Share = require("../generic/share");
var HorizontalScroller = require("../generic/horizontalscroller");

// menu at the top of the primary position
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    term: PropTypes.string.isRequired,
    context: PropTypes.object.isRequired,
  },

  // navigate to the new url allowing the routes to sort out context
  handleClick: function (event, data) {
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(
      this.getContext(data.scope, "relevance"),
      scrollpos,
      undefined,
      false
    );
  },

  handleSubmenuClick: function (event, data) {
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(
      this.getContext(data.scope, data.sort),
      scrollpos,
      undefined,
      false
    );
  },

  getContext: function (tab, sort) {
    let context = {
      target: "search",
      scope: tab,
      term: this.props.term,
      sort: "relevance",
    };
    if (sort) {
      context.sort = sort;
    }
    return context;
  },

  getUrl: function (tab, sort) {
    return Tremr.routes.urlFor({
      primary: this.getContext(tab, sort),
    });
  },

  render: function () {
    var self = this;

    var classes = {
      header: true,
      search: true,
    };
    classes["term-" + this.props.term.replace(/\s+/g, "-")] = true;
    classes = classNames(classes);

    let share = null;
    let shareUrl = this.getUrl("posts");
    share = (
      <Share
        moreIcon={<Tremr.Generic.SvgIcon icon="share-reverse" />}
        icons={["more"]}
        url={shareUrl}
        title={
          "Search " + this.props.term
            ? this.props.term.capitalize().replace(/&[^;]{2,3,4};/g, " ")
            : ""
        }
        description={
          "Search " + this.props.term
            ? this.props.term.replace(/&[^;]{2,3,4};/g + " on Tremr", " ")
            : ""
        }
      />
    );

    // <Tremr.Generic.Tab href={this.getUrl('channels')} handleClick={this.handleClick} data={{scope: 'channels', sort: 'latest'}} text="Channels" active={(this.props.context.scope == 'channels')} />
    let tabs = (
      <nav className="tabs">
        <Tremr.Generic.Tab
          href={this.getUrl("posts")}
          handleClick={this.handleClick}
          data={{ scope: "posts", sort: "latest" }}
          text="Posts"
          active={this.props.context.scope == "posts"}
        />
        <Tremr.Generic.Tab
          href={this.getUrl("users")}
          handleClick={this.handleClick}
          data={{ scope: "users", sort: "latest" }}
          text="Members"
          active={this.props.context.scope == "users"}
        />
        <Tremr.Generic.Tab
          href={this.getUrl("tags")}
          handleClick={this.handleClick}
          data={{ scope: "tags", sort: "latest" }}
          text="Tags"
          active={this.props.context.scope == "tags"}
        />
      </nav>
    );

    let submenu = null;

    if (
      this.props.context.scope == "posts" ||
      this.props.context.scope == "all"
    ) {
      submenu = (
        <HorizontalScroller>
          <nav className="menu">
            <Tremr.Generic.Tab
              key="relevance"
              href={this.getUrl("posts", "relevance")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "posts", sort: "relevance" }}
              text="Relevance"
              active={
                this.props.context.scope == "posts" &&
                this.props.context.sort == "relevance"
              }
            />
            <Tremr.Generic.Tab
              key="trending"
              href={this.getUrl("posts", "trending")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "posts", sort: "trending" }}
              text="Trending"
              active={
                this.props.context.scope == "posts" &&
                this.props.context.sort == "trending"
              }
            />
            <Tremr.Generic.Tab
              key="recommended"
              href={this.getUrl("posts", "recommended")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "posts", sort: "recommended" }}
              text="Top Rated"
              active={
                this.props.context.scope == "posts" &&
                this.props.context.sort == "recommended"
              }
            />
            <Tremr.Generic.Tab
              key="latest"
              href={this.getUrl("posts", "latest")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "posts", sort: "latest" }}
              text="All Posts"
              active={
                this.props.context.scope == "posts" &&
                this.props.context.sort == "latest"
              }
            />
          </nav>
        </HorizontalScroller>
      );
    } else if (this.props.context.scope == "users") {
      submenu = (
        <HorizontalScroller>
          <nav className="menu">
            <Tremr.Generic.Tab
              key="relevance"
              href={this.getUrl("users", "relevance")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "users", sort: "relevance" }}
              text="Relevance"
              active={
                this.props.context.scope == "users" &&
                this.props.context.sort == "relevance"
              }
            />
            <Tremr.Generic.Tab
              key="latest"
              href={this.getUrl("users", "latest")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "users", sort: "latest" }}
              text="Latest"
              active={
                this.props.context.scope == "users" &&
                this.props.context.sort == "latest"
              }
            />
            <Tremr.Generic.Tab
              key="alphabetical"
              href={this.getUrl("users", "alphabetical")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "users", sort: "alphabetical" }}
              text="A-Z"
              active={
                this.props.context.scope == "users" &&
                this.props.context.sort == "alphabetical"
              }
            />
          </nav>
        </HorizontalScroller>
      );
    } else if (this.props.context.scope == "tags") {
      submenu = (
        <HorizontalScroller>
          <nav className="menu">
            <Tremr.Generic.Tab
              key="relevance"
              href={this.getUrl("users", "relevance")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "tags", sort: "relevance" }}
              text="Relevance"
              active={
                this.props.context.scope == "tags" &&
                this.props.context.sort == "relevance"
              }
            />
            <Tremr.Generic.Tab
              key="alphabetical"
              href={this.getUrl("users", "alphabetical")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "tags", sort: "alphabetical" }}
              text="A-Z"
              active={
                this.props.context.scope == "tags" &&
                this.props.context.sort == "alphabetical"
              }
            />
          </nav>
        </HorizontalScroller>
      );
    } else if (this.props.context.scope == "channels") {
      submenu = (
        <HorizontalScroller>
          <nav className="menu">
            <Tremr.Generic.Tab
              key="relevance"
              href={this.getUrl("channels", "relevance")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "channels", sort: "relevance" }}
              text="Relevance"
              active={
                this.props.context.scope == "channels" &&
                this.props.context.sort == "relevance"
              }
            />
            <Tremr.Generic.Tab
              key="latest"
              href={this.getUrl("channels", "latest")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "channels", sort: "latest" }}
              text="Latest"
              active={
                this.props.context.scope == "channels" &&
                this.props.context.sort == "latest"
              }
            />
            <Tremr.Generic.Tab
              key="alphabetical"
              href={this.getUrl("channels", "alphabetical")}
              handleClick={this.handleSubmenuClick}
              data={{ scope: "channels", sort: "alphabetical" }}
              text="A-Z"
              active={
                this.props.context.scope == "channels" &&
                this.props.context.sort == "alphabetical"
              }
            />
          </nav>
        </HorizontalScroller>
      );
    }

    return (
      <div className={classes}>
        <div className="content">
          <div className="info">
            <h1>
              <Tremr.Generic.Abbreviations
                text={this.props.term.capitalize()}
              />
            </h1>
          </div>
          <div className="actions-tabs-container">
            <div className="actions">{share}</div>
            {tabs}
          </div>
          {submenu}
        </div>
      </div>
    );
  },
});
