require("./scss/reactions.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");

// TODO: these??
var WithFBData = require("../generic/withFBData");
var ActivityButton = require("./button");
var ActivityButtonWithFBData = WithFBData(ActivityButton);
var Summary = require("./reaction_summary");
var SummaryWithFBData = WithFBData(Summary);
var Count = require("./count");
var CountWithFBData = WithFBData(Count);

// var Button = require("../activity/button");
// var ActivityButton = require("../activity/button");
// var Count = require("../activity/count");
// var CountBar = require("../activity/countbar");

module.exports = CreateReactClass({

    // mixins: [PureRenderMixin],

    propTypes: {
        post_id: PropTypes.string.isRequired,
        open: PropTypes.bool
    },

    getInitialState: function() {

        return {
            open: false,
            dataPoints: Tremr.stores.activityStore.getActivityDataPoints(this.props.post_id, Tremr.stores.activityStore.reactions, { set: true, sum: true, users: true })
        };
    },

    // unregister listeners
    componentWillUnmount: function() {

        this.state.dataPoints.forEach(function(dataPoint) {

            let dp = this.state.dataPoints[dataPoint.name];
            if (dp && dp.fbRef && dp.fbRef.off) {
                dp.fbRef.off();
            }
        }.bind(this));
    },

    render: function() {

        let classes = {
            reactions: true,
            open: this.props.open
        };
        classes = classNames(classes);

        let content = null;
        let buttons = null;
        let totals = null;

        if (this.props.open && this.state.dataPoints) {

            // show upvote buttons
            buttons = Tremr.stores.activityStore.reactions.map(function(tag) {
                let dataPoints = this.state.dataPoints.filter(function(dp) {
                    return (dp['name'].startsWith(tag) || dp['name'].startsWith('reactors'));
                });
                return <ActivityButtonWithFBData post_id={this.props.post_id} key={'reaction-button-'+tag} dataPoints={dataPoints} tag={tag} />;
            }.bind(this));

            // totals to be aligned with buttons above
           let upvoteDataPoints = Tremr.stores.activityStore.getActivityDataPoints(this.props.post_id, ["upvote"],  { set: false, sum: true });
           let downvoteDataPoints = Tremr.stores.activityStore.getActivityDataPoints(this.props.post_id, ["downvote"],  { set: false, sum: true });
           let repostDataPoints = Tremr.stores.activityStore.getActivityDataPoints(this.props.post_id, ["repost"],  { set: false, sum: true });
           let bookmarkDataPoints = Tremr.stores.activityStore.getActivityDataPoints(this.props.post_id, ["bookmark"],  { set: false, sum: true });

           totals = <div className="totals" key="totals">
               <CountWithFBData key="count-upvote" dataPoints={upvoteDataPoints} tag="upvote" />
               <CountWithFBData key="count-downvote" dataPoints={downvoteDataPoints} tag="downvote" />
               <CountWithFBData key="count-repost" dataPoints={repostDataPoints} tag="repost" />
               <CountWithFBData key="count-bookmark" dataPoints={bookmarkDataPoints} tag="bookmark" />
           </div>

           content = [
               totals,
               <div className="reaction-buttons-wrapper">
                    <div className="reaction-buttons">
                        {buttons}
                    </div>
               </div>
           ];

        } else {
            // summary of current users activity on this post
            let key = "posts/{post}/activity/{user}";
            key = key.replace(/\{post\}/, this.props.post_id);

            let dataPoints = [{
                name: "activity",
                key: key,
                user: true
            }];
            content = <SummaryWithFBData key="summary" dataPoints={dataPoints} onOpen={this.props.onOpen} />;
        }

        return <div className={classes}>
            {content}
        </div>;
    }

});
