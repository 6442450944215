var TremrUtils = require('../../utils/tremr_utils');
var UnseenCount = require('../generic/unseencount');
var CreateReactClass = require('create-react-class');
var classNames = require('../../utils/classnames');

// Tremr.Tag.ListItem = CreateReactClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    // open a card in primary, keeping the scroll position
    openPrimary: function(context) {

        // var scrollpos;
        // var node = ReactDOM.findDOMNode(this);
        // scrollpos = $(node).closest('.current, .primary').scrollTop(); // get scroll pos of container
        let scrollpos = window.scrollY;

        Tremr.navigatePrimary(context, scrollpos);
    },

    // open a card
    openCard: function(event) {

        event.stopPropagation();

        // if meta key, allow normal open - for container use
        // data-href to open in new tab
        if (event.metaKey) {
            if (event.currentTarget && !event.currentTarget.getAttribute('href')) {
                var url = Tremr.routes.urlFor({
                    primary: this.getTagContext()
                });
                window.open(url);
            }
            return;
        }

        event.preventDefault();

        if (this.props.closeSidebarOnClick) {
            Tremr.dispatcher.message(this, "tremr:layout:leftsidebarcloseonmobile");
        }

        this.openPrimary(this.getTagContext());
    },

    // get the context for this users feed
    getTagContext: function() {
        return {
            target: 'tag',
            tag: this.props.tag.get('tag'),
            ui: 'home'
        };
    },

    // display
    render: function() {

        let classes = classNames({
            active: this.props.active
        });

        var url = Tremr.routes.urlFor({
            primary: this.getTagContext()
        });

        var hex = TremrUtils.stringToColor(this.props.tag.get('tag'));

        var style = {};
        style['backgroundColor'] = hex;

        return (
        <div className="tag-listitem">
            <a className={classes} onClick={this.openCard} href={url} title={this.props.tag.get('tag')}>
                <div className="tag-avatar" style={style}>{this.props.tag.get('tag').substring(0, 2)}
                    <UnseenCount total_path={'tag-totals/' + this.props.tag.get('tag')} seen_path={'tag-seen/{user}/' + this.props.tag.get('tag') + '/count'} />
                </div>
            </a>
        </div>);
    }
});

