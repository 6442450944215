require("./scss/alert.scss");

var CreateReactClass = require('create-react-class');
var SvgIcon = require('../generic/svgicon');
// var Cookies = require('js-cookie/src/js.cookie');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultProps: function() {
		return {
			onClose: null,
			index: 0
		}
	},

  close: function(e) {
		this.props.onClose(this.props.index);
  },

  render: function() {

    return (
			<div className="layout-generic-alert layout-alert-wrapper">
				<div className="layout-alert-content">
					{this.props.children}
	        <SvgIcon icon="remove" onClick={this.close} />
				</div>
			</div>
		);
	}
});
