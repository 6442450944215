require("./scss/newposttextbutton.scss");

var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');
var Dropdown = require('../generic/dropdown');
var classNames = require("../../utils/classnames");
var PropTypes = require('prop-types');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

    propTypes: {
        channel: PropTypes.object,
        contributor: PropTypes.bool
    },

	openEditor: function(event) {

		event.preventDefault();
		event.stopPropagation();

        let tab = event.currentTarget.getAttribute('data-tab');
        let data = {
            fieldset: tab
        };
        if (this.props.channel) {
            data.channel = this.props.channel;
        }
		Tremr.dispatcher.message(this, "tremr:editor:open", data);
	},

	render: function() {

		if (!this.state.user) {

			return null;

		} else {
            let classes = {
                "post-newposttextbutton": true,
                channel: this.props.channel,
                contributor: this.props.contributor
            };
            classes = classNames(classes);
            let title = <strong>New Post</strong>;
            if (this.props.channel && this.props.contributor) {
                // title = "Post to " + this.props.channel.get('title');
                // title = <span><strong>New Post</strong> (Member)</span>
                title = <span><strong>New Post</strong></span>
            } else if (this.props.channel) {
                // title = <span><strong>New Post</strong> (Community)</span>                
                return null;
            }

			return <div className={classes}>
                <a href="/editor" data-tab="article" className="new-post" onClick={this.openEditor}>
                    <Tremr.Generic.SvgIcon icon="write" classes="article icon" />
					{title}
                </a>
			</div>;
		}
	}
});
