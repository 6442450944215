require("./scss/button.scss");

var TremrUtils = require("../../utils/tremr_utils");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");
var UserAwareMixin = require("../../mixins/userawaremixin");
var alertify = require("alertify");
var Config = require("config");

module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  propTypes: {
    post_id: PropTypes.string.isRequired,
    isSet: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
  },

  getInitialState: function () {
    return {
      isSet: this.props.isSet,
    };
  },

  getDefaultProps: function () {
    return {
      isSet: false,
    };
  },

  sendData: function (isSet) {
    if (this.props.onClick) {
      this.props.onClick();
    }

    TremrUtils.fetch(Config.apiurl + "/api/factcheck/" + this.props.post_id, {
      method: isSet ? "POST" : "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    }).then(
      function (response) {
        if (response.ok) {
          alertify.success("Updated Fact Checked");
          Tremr.dispatcher.message(
            this,
            "tremr:factcheck:" + this.props.post_id,
            { set: isSet }
          );
        } else {
          console.log("Error:" + response.status + ":" + response.statusText);
          alertify.error("Error updating Fact Checked");
        }
      }.bind(this)
    );
  },

  tick: function (event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isSet: true,
    });
    this.sendData(true);
  },

  untick: function (event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isSet: false,
    });
    this.sendData(false);
  },

  // display
  render: function () {
    if (this.state.user && this.state.user.get("admin") == "yes") {
      let icon = <SvgIcon icon="unticked" />;
      let onClick = this.tick;
      if (this.state.isSet) {
        icon = <SvgIcon icon="ticked" />;
        onClick = this.untick;
      }

      return (
        <div className="section factcheck-button">
          <div className="line" onClick={onClick}>
            {icon}
            <label>Fact Checked</label>
          </div>
        </div>
      );
    } else {
      return null;
    }
  },
});
