require("./scss/inbox_header.scss");

var CreateReactClass = require('create-react-class');
var classNames = require('../../utils/classnames');
var HorizontalScroller = require('../generic/horizontalscroller');

module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  // navigate to the new url allowing the routes to sort out context
  handleClick: function(event, data) {
    Tremr.navigatePrimary(this.getContext(data.sort));
  },

  getContext: function(sort) {
    return {
        target: 'inbox',
        scope: 'posts',
        sort: sort
    };
  },

  getUrl: function(data) {
    return Tremr.routes.urlFor({
        primary: this.getContext(data.sort)
    });
  },

  // when added make sure we update that we have seen these posts
  componentDidMount: function() {

    Tremr.dispatcher.message(this, "tremr:activity:seenallresponses");

  },

  render: function() {

    // return null;
    // <Tremr.Generic.Tab href={this.getUrl('featured')} handleClick={this.handleClick} data="featured" text="Featured" active={(this.props.context.scope == 'featured')} />

    let classes = {
        inbox: true,
        header: true
    };
    classes = classNames(classes);

    return (

      <div className={classes}>
        <HorizontalScroller>
            <nav className="menu">
            <div className="title">Inbox:</div>
            <Tremr.Generic.Tab href={this.getUrl({sort: 'latest'})} handleClick={this.handleClick} data={{ sort: 'latest'}} text="Latest" active={(this.props.context.sort == 'latest')} />
            <Tremr.Generic.Tab href={this.getUrl({sort: 'recommended'})} handleClick={this.handleClick} data={{ sort: 'recommended'}} text="Recommended" active={(this.props.context.sort == 'recommended')} />
            <Tremr.Generic.Tab href={this.getUrl({sort: 'trending'})} handleClick={this.handleClick} data={{ sort: 'trending'}} text="Trending" active={(this.props.context.sort == 'trending')} />
            </nav>
        </HorizontalScroller>
      </div>
    );
  }

});
