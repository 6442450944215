var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");
var OriginRules = require("./originRules");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    post: PropTypes.object.isRequired,
    showRepostComment: PropTypes.bool,
  },

  getDefaultProps: function () {
    return {
      showRepostComment: true,
    };
  },

  openContext: function (event, context) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: context });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    Tremr.navigatePrimary(context);
  },

  openTag: function (event) {
    event.preventDefault();
    event.stopPropagation();

    let tag = event.currentTarget.getAttribute("data-value");

    let tagContext = {
      target: "tag",
      tag: tag,
    };

    this.openContext(event, tagContext);
  },

  openFeed: function (event) {
    event.preventDefault();
    event.stopPropagation();

    let feed = event.currentTarget.getAttribute("data-value");

    let feedContext = {
      target: "feed",
      scope: "posts",
      subfeed: "featured",
      feed: feed,
    };

    this.openContext(event, feedContext);
  },

  render: function () {
    let repostVia = null;
    let repostIn = null;
    let comment = null;

    let originRules = OriginRules(this.props.post);
    // if (originRules.inChannel || originRules.isVia || originRules.inTag) {
    //   console.log("originRules:"+this.props.post.get('title') + ' ('+originRules.isVia+')');
    //   console.dir(this.props.post.attributes);
    //   console.dir(originRules);
    // }
    // console.log("originRules:" + this.props.post.get("title"));
    // console.dir(originRules);

    if (originRules.isVia) {
      repostVia = (
        <div
          data-value={originRules.viaSlug}
          onClick={this.openFeed}
          className="reposted-by-via"
        >
          via <div className="name">{originRules.viaName}</div>
        </div>
      );

      if (originRules.viaComment) {
        comment = (
          <div className="repost-comment">{originRules.viaComment}</div>
        );
      }
    }

    if (originRules.inTag) {
      repostIn = (
        <div
          data-value={originRules.tagSlug}
          onClick={this.openTag}
          className="reposted-by-in"
        >
          in <div className="name">{originRules.tagName}</div>
        </div>
      );
    } else if (originRules.inChannel) {
      repostIn = (
        <div
          data-value={originRules.channelSlug}
          onClick={this.openFeed}
          className="reposted-by-in"
        >
          in <div className="name">{originRules.channelTitle}</div>
        </div>
      );
    }

    if (repostVia || repostIn || comment) {
      return (
        <div className="reposted-by">
          {repostVia}
          {repostIn}
          {comment}
        </div>
      );
    } else {
      return null;
    }

    // if (this.props.post.get("reasons") && this.props.post.get("reasons").length > 0) {
    //
    //     let reason = this.props.post.get("reasons")[0];
    //
    //     // console.log("post origin reason:");
    //     // console.dir(reason);
    //     // console.dir(this.props.post.get("reasons"));
    //
    //     if (reason.reason == "tag" && reason.tag) {
    //
    //         repostIn = <div data-value={reason.tag} onClick={this.openTag} className="reposted-by-in">in <span className="tag">{reason.tag}</span></div>;
    //
    //     } else if (reason.reason == "feed" && reason.author) {
    //
    //         repostVia = <div data-value={this.props.post.get("channel_author_feed") || reason.author_feed} onClick={this.openFeed} className="reposted-by-via xxx1">via <div className="name">{reason.author}</div></div>;
    //         viaFeed = (this.props.post.get("channel_author_feed") || reason.author_feed);
    //
    //         if (reason.channel_title && this.props.post.get("channel_author_feed") && this.props.post.get("channel_author_feed") != reason.channel) {
    //
    //             repostIn = <div data-value={reason.feed} onClick={this.openFeed} className="reposted-by-in">in <div className="name">{reason.channel_title}</div></div>;
    //             inChannel = reason.feed;
    //             repostVia = null;
    //         }
    //     } else if (reason.reason == "repost") {
    //
    //         if (reason.comment && reason.comment.length > 0 ) {
    //             comment = <div className="repost-comment">{reason.comment}</div>;
    //         }
    //
    //         if (reason.author) {
    //             repostVia = <div data-value={reason.author_feed} onClick={this.openFeed} className="reposted-by-via xxx2">via <div className="name">{reason.author}</div></div>;
    //             viaFeed = reason.author_feed;
    //         }
    //         if (this.props.post.get("channel_author_feed") && this.props.post.get("channel_author_feed") != this.props.post.get("author_feed")) {
    //             repostIn = <div data-value={reason.channel} onClick={this.openFeed} className="reposted-by-in">from <div className="name">{reason.channel_title}</div></div>;
    //             inChannel = reason.channel;
    //         }
    //
    //         if (reason.feed_title && reason.author_feed != reason.feed) {
    //             repostVia = <div data-value={reason.feed} onClick={this.openFeed} className="reposted-by-via xxx3">via <div className="name">{reason.feed_title}</div></div>;
    //             viaFeed = reason.feed;
    //         }
    //
    //     }
    //
    //     // special case where in and from the same
    //     if (reason.reason != "tag" && viaFeed == inChannel && reason.channel_title) {
    //         repostVia = null;
    //         repostIn = <div data-value={reason.channel} onClick={this.openFeed} className="reposted-by-in">in <div className="name">{reason.channel_title}</div></div>;
    //     }
    //
    // } else if (this.props.post.get("repost")) {
    //
    //     if (this.props.post.get("repost").comment && this.props.post.get("repost").comment.length > 0 ) {
    //         comment = <div className="repost-comment">{this.props.post.get("repost").comment}</div>;
    //     }
    //
    //     // supress if the author_feed IS THIS feed
    //     if (this.props.post.get("author_feed") &&
    //         this.props.post.get("author_feed") != this.props.post.get("repost").author_feed) {
    //
    //           // if this has been reposted to a feed it was originally posted  in then don't show origin
    //
    //    } else if (this.props.post.get("channel_author_feed") &&
    //         this.props.post.get("channel_author_feed") != this.props.post.get("repost").author_feed) {
    //
    //           // via the reposter unless the reposter is the owner of this feed
    //         repostVia = <div data-value={this.props.post.get("repost").author_feed || this.props.post.get("repost").feed} onClick={this.openFeed} className="reposted-by-via xxx4">via <div className="name">{this.props.post.get("repost").author}</div></div>;
    //         viaFeed = this.props.post.get("repost").author_feed || this.props.post.get("repost").feed;
    //     // } else if (this.props.post.get("author_feed") &&
    //     //     this.props.post.get("author_feed") != this.props.post.get("repost").author_feed) {
    //     //     repostVia = <div data-value={this.props.post.get("repost").author_feed || this.props.post.get("repost").feed} onClick={this.openFeed} className="reposted-by-via xxx5">via <div className="name">{this.props.post.get("repost").author}</div></div>;
    //     //     viaFeed = this.props.post.get("repost").author_feed || this.props.post.get("repost").feed;
    //     }
    //
    //     // looking at feed is different to author of post AND author post is not channel author feed (ie. a channel)
    //     if (this.props.post.get("channel_author_feed") &&
    //         this.props.post.get("channel_title") &&
    //        (this.props.post.get("channel_author_feed") != this.props.post.get("author_feed"))) {
    //
    //             repostIn = <div data-value={this.props.post.get("author_feed")} onClick={this.openFeed} className="reposted-by-in">in <div className="name">{this.props.post.get("channel_title")}</div></div>;
    //             inChannel = this.props.post.get("author_feed");
    //
    //     } else if (this.props.post.get("repost").channeltitle &&
    //         this.props.post.get("repost").feed != this.props.post.get("author_feed")) {
    //
    //         if (this.props.post.get("channel_author_feed") &&
    //             this.props.post.get("repost").feed != this.props.post.get("channel_author_feed")) {
    //             repostIn = <span data-value={this.props.post.get("author_feed")} onClick={this.openFeed} className="reposted-by-in">in <div className="name">{this.props.post.get("channel_title")}</div></span>;
    //             inChannel = this.props.post.get("author_feed");
    //         }
    //     }
    //
    //     // special case where auhor is reposter
    //     if (this.props.post.get("channel_author_feed") && viaFeed == this.props.post.get("channel_author_feed")) {
    //         repostVia = null;
    //     }
    //
    //     if (this.props.post.get("repost").feed == inChannel) {
    //         repostIn = null;
    //     }
    //
    //
    //
    //     // repos    tIn = <span className="reposted-by-in">
    //     // DEBUG: {JSON.stringify(this.props.post.get("repost"))}<br />&nbsp;<br />
    //     // channel_author_feed: {JSON.stringify(this.props.post.get("channel_author_feed"))}<br />&nbsp;<br />
    //     // author_feed: {JSON.stringify(this.props.post.get("author_feed"))}<br />&nbsp;<br />
    //     // </span>;
    //
    // } else if (this.props.post.get("posted")) {
    //
    //     // if we're seeing this post somewhere other than an originator
    //     // if ((this.props.post.get("posted").feed != this.props.post.get("author_feed"))) {
    //     //
    //     //     if ((!this.props.post.get("channel_author_feed")) ||
    //     //        (this.props.post.get("posted").feed != this.props.post.get("channel_author_feed"))) {
    //     //
    //     //            repostVia = <span data-value={this.props.post.get("channel_author_feed")} onClick={this.openRepostFeed} className="reposted-by-via">via {this.props.post.get("author_name")}</span>;
    //     //     }
    //     // }
    //
    //     // looking at feed is different to author of post AND author post is not channel author feed (ie. a channel)
    //     if (this.props.post.get("posted").feed != this.props.post.get("author_feed") &&
    //         this.props.post.get("posted").channeltitle) {
    //         repostIn = <span data-value={this.props.post.get("author_feed")}  onClick={this.openFeed} className="reposted-by-in">in <div className="name">{this.props.post.get("posted").channeltitle}</div></span>;
    //     }
    //
    // } else {
    //
    //     if (this.props.post.get("channel_author_feed") &&
    //         this.props.post.get("channel_title") &&
    //        (this.props.post.get("channel_author_feed") != this.props.post.get("author_feed"))) {
    //
    //             repostIn = <div onClick={this.openFeed} className="reposted-by-in">in <div className="name">{this.props.post.get("channel_title")}</div></div>;
    //     }
    // }
    //
    // // always hide via if it matches the post author
    // if (viaFeed == Tremr.Theme.HomepageFeed || viaFeed == ( this.props.post.get('channel_author_feed') ||  this.props.post.get('author_feed') )) {
    //     repostVia = null;
    // }
    //
    // // last minute adjustment for comment
    // if (this.props.showRepostComment === false) {
    //     if (comment) {
    //         comment = <Tremr.Generic.SvgIcon icon="repost-comment" />;
    //     }
    // }
    //
    // if (repostVia || repostIn || comment) {
    //     return <div className="reposted-by">
    //       {repostVia}
    //       {repostIn}
    //       {comment}
    //     </div>;
    // } else {
    //     return null;
    // }
  },
});
