// var svg4everybody = require('svg4everybody');
var classNames = require("../../utils/classnames");
var icons = require("../../utils/icons.svg");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// svg icon from a sprite file
// Tremr.Generic.SvgIcon = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    icon: PropTypes.string.isRequired,
    file: PropTypes.string,
    classes: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    fill: PropTypes.bool,
  },

  getDefaultProps: function () {
    var image_path = "/images/icons.svg";

    return {
      icon: "icon-tremr",
      file: image_path,
      classes: "icon",
      onClick: _.noop,
      fill: true,
    };
  },

  render: function () {
    var classes = {};
    classes[this.props.classes] = true;
    classes = classNames(classes);

    // convert some icon names to alternatives - bad idea but I can't keep up with the icon changes
    var iconname = this.props.icon;
    if (iconname == "latest") {
      iconname = "timeline";
    } else if (iconname == "top-rated") {
      iconname = "recommend";
    } else if (iconname == "a-z") {
      iconname = "list";
    } else if (iconname == "relevance") {
      iconname = "list";
    } else if (iconname == "best-of-tremr") {
      iconname = "star";
    }

    iconname = iconname.replace(/\s/gi, "-");

    var svgClasses = {
      svgWrapper: true,
      fill: this.props.fill,
    };
    svgClasses["icon-" + iconname] = true;
    svgClasses = classNames(svgClasses);

    // var useTag = '<use xlink:href="'+this.props.file+'#icon-'+iconname+'" />';
    var content = icons("icon-" + iconname);
    if (this.props.onClick) {
      return (
        <div
          title={this.props.title || ""}
          onClick={this.props.onClick}
          className={classes}
        >
          <div
            className={svgClasses}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      );
    } else {
      return (
        <div className={classes}>
          <div
            className={svgClasses}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      );
    }
  },
});
