require("./scss/card.scss");

let classNames = require("../../utils/classnames");
let CardMixin = require("../../mixins/cardmixin");
let CreateReactClass = require("create-react-class");
let ChannelAvatar = require("./channelavatar");
let Config = require("config");
let WithFBData = require("../generic/withFBData");
let Stars = require("../reputation/stars");
let StarsWithFBData = WithFBData(Stars);
var ReputationUserOverview = require("../reputation/useroverview");
var ReputationUserOverviewWithFBData = WithFBData(ReputationUserOverview);
let ColorChangesMixin = require("../../mixins/colorchangesmixin");
let ReputationBadge = require("../reputation/badge");
let ReputationBadgeWithFBData = WithFBData(ReputationBadge);
let ReputationHelper = require("../reputation/helper");

// Tremr.Feed.Card
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, CardMixin, ColorChangesMixin],

  // open a card
  openCard: function (event) {
    event.stopPropagation();

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({
          primary: this.getUserContext(),
        });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    this.openPrimary(this.getUserContext());
  },

  // get the context for this users feed
  getUserContext: function () {
    return {
      target: "feed",
      feed: this.props.feed.get("name"),
      // ,
      // subfeed: 'featured'
    };
  },

  componentDidMount: function () {
    if (this.props.loadedCallback) {
      _.defer(() => {
        // must be delayed
        this.props.loadedCallback();
      });
    }
  },

  // display
  render: function () {
    var divStyle = this.getCardStyle();

    // work out our top level class
    var classes = classNames({
      "feed-card": true,
      card: true,
      image: false,
      embed: false,
      repost: false,
      channel: this.props.feed.get("channel") == "yes",
    });

    // var header_image = '';
    // if (this.props.feed.get('header_image')) {
    //     header_image = <Tremr.Generic.Image image={this.props.feed.get('header_image')} height="232" width="232" />;
    // }

    var avatar_image = null;
    if (this.props.feed.get("author_avatar_image")) {
      avatar_image = (
        <Tremr.Generic.Image
          image={this.props.feed.get("author_avatar_image")}
          height={52}
          width={52}
        />
      );
    } else {
      let color = Config.colors.highlight;
      if (this.props.feed.get("highlight_color")) {
        color = this.props.feed.get("highlight_color");
      }
      avatar_image = (
        <ChannelAvatar
          title={this.props.feed.get("author_name")}
          color={color}
        />
      );
    }

    var url = Tremr.routes.urlFor({
      primary: this.getUserContext(),
    });

    var bio = null;
    if (
      this.props.feed.get("author_bio") &&
      this.props.feed.get("author_bio").length > 0
    ) {
      bio = <p>{this.props.feed.get("author_bio")}</p>;
    }

    var getData = function (name, title) {
      if (!this.props.feed.get("stats")) {
        return (
          <span className="data">
            <em>0</em>&nbsp;{title}
          </span>
        );
      }
      return (
        <span className="data">
          <em>{this.props.feed.get("stats")[name]}</em>&nbsp;{title}
        </span>
      );
    }.bind(this);

    let reputationUserOverview = null;
    let reputationStars = null;
    let userBadge = null;
    if (this.props.feed.get("channel") != "yes") {
      const reputationStarsDataPoints = [
        ReputationHelper.getUserReputationStarsDataPoint(
          this.props.feed.get("author_id")
        ),
      ];

      const highlightColor = this.state.highlightColor;
      reputationStars = (
        <StarsWithFBData
          key={"stars" + highlightColor}
          alignment="right"
          highlightColor={highlightColor}
          starSize={18}
          height={18}
          width={54}
          scale={window.devicePixelRatio}
          dataPoints={reputationStarsDataPoints}
        />
      );

      const reputationUserOverviewDataPoints = [
        ReputationHelper.renameDataPoint(
          ReputationHelper.getUserReputationStarsDataPoint(
            this.props.feed.get("author_id")
          ),
          "user_rep_stars"
        ),
        ReputationHelper.getUserReputationTagsDataPoint(
          this.props.feed.get("author_id")
        ),
      ];
      reputationUserOverview = (
        <ReputationUserOverviewWithFBData
          align="right"
          highlightColor={highlightColor}
          authorName={this.props.feed.get("author_name")}
          dataPoints={reputationUserOverviewDataPoints}
        >
          {reputationStars}
        </ReputationUserOverviewWithFBData>
      );
      const reputationBadgeDataPoints = [
        ReputationHelper.getUserReputationBadgeDataPoint(
          this.props.feed.get("author_id")
        ),
      ];
      userBadge = (
        <ReputationBadgeWithFBData dataPoints={reputationBadgeDataPoints} />
      );
    }

    let statsArea = (
      <p className="stats">
        {getData("posts", "posts")}
        {getData("followers", "followers")}
      </p>
    );

    let endorsement = null;
    if (this.props.feed.get("endorsement")) {
      statsArea = null;
      let feedEndorsement = this.props.feed.get("endorsement");
      endorsement = (
        <p className="endorsement">{feedEndorsement.tags.join(", ")}</p>
      );
      bio = (
        <p className="endorsement-comment">
          {feedEndorsement.comment ? feedEndorsement.comment.capitalize() : ""}
        </p>
      );
    }

    return (
      <article onClick={this.openCard} className={classes} style={divStyle}>
        <a className="avatar-link" onClick={this.openCard} href={url}>
          {avatar_image}
        </a>
        <div className="content">
          {userBadge}
          <div className="row">
            <h1>
              <a onClick={this.openCard} href={url}>
                {this.props.feed.get("author_name")}
              </a>
            </h1>
            <div className="actions">{reputationUserOverview}</div>
          </div>
          {endorsement}
          {bio}
          {statsArea}
          <Tremr.Follow.Button
            title={this.props.feed.get("author_name")}
            subfeeds={this.props.feed.get("subfeeds") || []}
            entity="feed"
            id={this.props.feed.get("name")}
            loadedCallback={this.resizedCard}
          />
        </div>
      </article>
    );
  },
});
