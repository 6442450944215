require("./scss/mediagallery.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// iterates post content and creates gallery of images and embeds
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        post: PropTypes.object.isRequired
    },

    getInitialState: function() {
        return {
            activeIndex: 0
        }
    },

    setActiveIndex: function(event) {

        event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

        // if meta key and href, allow normal open
        if (event.metaKey && event.currentTarget.getAttribute('href')) {
            return;
        }

        event.preventDefault();

        let index = event.currentTarget.getAttribute('data-index');
        this.setState({
            activeIndex: index
        });
    },

    render: function() {

        // keep a list of items
        let items = [];

        let addEmbed = function(embed, caption, identifier) {

            let item = null;

            // depends on type
            if (embed.type == 'video' && embed.thumbnail_url) {

                item = {
                    thumbnail: <Tremr.Generic.EmbedThumb key={'thumb-'+identifier} showText={false} showDescription={false} showTitle={false} shortText={false} embed={embed} width={76} maxWidth={76} height={42} maxHeight={42} />,
                    content: <Tremr.Generic.Embed key={'video-'+identifier} embed={embed} maxHeight={5000} height={false} width="auto" resizable={true} />
                };

            } else if (embed.type == 'link' && embed.thumbnail_url) {

                item = {
                    thumbnail: <Tremr.Generic.EmbedThumb key={'thumb-'+identifier} showText={false} showDescription={false} showTitle={false} shortText={false} embed={embed} width={76} maxWidth={76} height={42} maxHeight={42} />,
                    content: <Tremr.Generic.Embed key={'link-'+identifier} embed={embed} maxHeight={5000} height={false} width="auto" resizable={true} />
                };

            } else if (embed.type == 'link')  {

                // don't add pure links to the header

            } else if (embed.type == 'photo' && embed.thumbnail_url) {

                item = {
                    thumbnail: <Tremr.Generic.EmbedThumb key={'thumb-'+identifier} showText={false} showDescription={false} showTitle={false} shortText={false} embed={embed} width={76} maxWidth={76} height={42} maxHeight={42} />,
                    content: <Tremr.Generic.Embed key={'photo-'+identifier} embed={embed} maxHeight={5000} height={false} width="auto" resizable={true} />
                };

            } else if (embed.type == 'rich' && embed.thumbnail_url) {

                item = {
                    thumbnail: <Tremr.Generic.EmbedThumb key={'thumb-'+identifier} showText={false} showDescription={false} showTitle={false} shortText={false} embed={embed} width={76} maxWidth={76} height={42} maxHeight={42} />,
                    content: <Tremr.Generic.Embed key={'rich-'+identifier} embed={embed} maxHeight={5000} height={false} width="auto" resizable={true} />
                };

            }

            if (item) {

                if (caption && caption.length > 0) {
                    item.caption = caption;
                }

                if (embed && embed.url && embed.url.length > 0) {
                    item.url = embed.url;
                }

                items.push(item);
            }

        }.bind(this);

        let addImage = function(image, caption) {
            let item = {
                thumbnail: <Tremr.Generic.Image image={image} width={76} height={42} maxHeight={42} maxWidth={76} />,
                content: <Tremr.Generic.Image key={'image-'+image.public_id} image={image} maxHeight={5000} height={false} width="auto" resizable={true} />,
                url: image.url,
                caption: caption
            };
            items.push(item);
        }.bind(this);

        // add on image/embed in header
        if (this.props.post.get('image')) {

            addImage(this.props.post.get('image'), this.props.post.get('image').caption);

        } else if (this.props.post.get('embed')) {
            addEmbed(this.props.post.get('embed'), this.props.post.get('embed').caption, 'header-embed');
        }

        // iterate content blocks potentially adding each one
        _.each(this.props.post.get('content_blocks'), function(block) {
            if (block.type == 'embed') {
                addEmbed(block.embed, block.captionText ? block.captionText.join('') : '', block.identifier);
            } else if (block.type == 'upload') {
                addImage(block.image, block.captionText ? block.captionText.join('') : '', block.identifier);
            }
        });

        // list of thumbnails to each image
        let menu = null;
        if (items.length > 1) {
            menu = <div className="thumbnails">
                {_.map(items, function(item, index) {
                    let classes = classNames({
                        thumbnail: true,
                        active: (index == this.state.activeIndex)
                    });
                    return <a onClick={this.setActiveIndex} data-index={index} href={item.url} className={classes}>
                        {item.thumbnail}
                    </a>;
                }.bind(this))}
            </div>;
        }

        // actual active embed/image
        let activeContent = null;
        let caption = null;
        if (items.length > 0) {
            activeContent = <div className="content">
                {items[this.state.activeIndex].content}
            </div>;
            if (items[this.state.activeIndex].caption && items[this.state.activeIndex].caption.length > 0) {
                caption = <p className="caption">{items[this.state.activeIndex].caption}</p>;
            }
        }

        return <div className="post-mediagallery">
            {menu}
            {activeContent}
            {caption}
        </div>;
    }
});
