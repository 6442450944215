require("./scss/postreport.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");

var WithFBData = require("../generic/withFBData");
var ActivityButton = require("./button");
var ActivityButtonWithFBData = WithFBData(ActivityButton);

module.exports = CreateReactClass({
  // mixins: [PureRenderMixin],

  propTypes: {
    post_id: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return {
      dataPoints: Tremr.stores.activityStore.getPostReportDataPoints(
        this.props.post_id,
        Tremr.stores.activityStore.postreports,
        { set: true }
      ),
    };
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.state.dataPoints.forEach(
      function (dataPoint) {
        let dp = this.state.dataPoints[dataPoint.name];
        if (dp && dp.fbRef && dp.fbRef.off) {
          dp.fbRef.off();
        }
      }.bind(this)
    );
  },

  render: function () {
    let classes = {
      postreport: true,
    };
    classes = classNames(classes);

    let content = null;
    let buttons = null;

    if (this.state.dataPoints) {
      // show upvote buttons
      buttons = Tremr.stores.activityStore.postreports.map(
        function (tag) {
          let dataPoints = this.state.dataPoints.filter(function (dp) {
            return (
              dp["name"].startsWith(tag) || dp["name"].startsWith("postreports")
            );
          });
          return (
            <ActivityButtonWithFBData
              post_id={this.props.post_id}
              key={"postreport-button-" + tag}
              dataPoints={dataPoints}
              tag={tag}
            />
          );
        }.bind(this)
      );

      content = (
        <div className="postreport-buttons-wrapper">
          <div className="postreport-buttons">{buttons}</div>
        </div>
      );
    }

    return <div className={classes}>{content}</div>;
  },
});
