// require("./vars.scss"); // now included in theme
require("./forms.scss");
require("./alertify.scss");
// require("./colors.scss"); // now included in theme
// require("./typography.scss"); // now included in themew
// require("./tx.scss");
require("./tx2.scss");
require("./icons.scss");
require("./contenteditable.scss");
require("./core.scss");
