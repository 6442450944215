var UserAwareMixin = require('../../mixins/userawaremixin');
var TremrStorage = require('../../utils/tremr_storage');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

	getInitialState: function() {
		return {
			closed: (TremrStorage.getLocal('has_closed_sidebarpitch') == 'yes')
		}
	},

	handleClose: function(event) {
		event.preventDefault();
		event.stopPropagation();

		this.setState({
			closed: 'yes'
		});

		TremrStorage.setLocal('has_closed_sidebarpitch', 'yes');
	},

	openAbout: function(event) {

		if (event.metaKey) { return; }

		event.preventDefault();
		event.stopPropagation();

		Tremr.navigateCurrent({
		  target: 'post',
		  feed: 'tremr',
		  id: 'what-is-tremr'
		});

		// if mobile, also close sidebar
        if (Tremr.Utils.isDeviceOrNarrowWithSidebar()) {
            Tremr.context.batch({leftSidebar: 'closed'});
        }
	},

	render: function() {

		if (this.state.closed || this.state.user) {
			return <section></section>;
		};

		return (
			<div className="layout-sidebarpitch">
				<a className="close" onClick={this.handleClose}>
			      <Tremr.Generic.SvgIcon icon="remove" />
			    </a>

				<h2>What is Tremr?</h2>
				<p>Tremr is blogging rebooted. The place to have your say on the stories of the day.</p>
				<ul>
					<li><span className="text">Post Articles</span></li>
					<li><span className="text">Follow Writers</span></li>
					<li><span className="text">React and Respond</span></li>
				</ul>
				<p><a href="/tremr/what-is-tremr" onClick={this.openAbout}>Learn more…</a></p>
			</div>
		);
	}
});
