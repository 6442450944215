var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// displays an activity on a post
module.exports = CreateReactClass({

    propTypes: {
        tag: PropTypes.string.isRequired
    },

    getEncodedTag: function() {
        return this.props.tag.replace(/\s/g, ':');
    },

    // render
    render: function() {

        let value = this.props.liveDataPoints[this.getEncodedTag()+'-sum'].data;
        if (!value) {
            value = "0";
        }

        return <div className={"activity-count "+this.getEncodedTag() + " value-"+value}>
            <output>{value}</output>
            {this.props.children}
        </div>
    }
});
