require("./scss/header.scss");

let PropTypes = require("prop-types");
let TremrTracking = require("../../utils/tracking");
let TremrUtils = require("../../utils/tremr_utils");
let classNames = require("../../utils/classnames");
let UserAwareMixin = require("../../mixins/userawaremixin");
let DraftsTab = require("../../stores/user/draftstab");
let ManageSubfeedsButton = require("../user/manage_subfeeds_button");
let CreateReactClass = require("create-react-class");
let Config = require("config");
let Titlebar = require("../layout/titlebar");
let Share = require("../generic/share");
let HorizontalScroller = require("../generic/horizontalscroller");
let WithFBData = require("../generic/withFBData");
let Stars = require("../reputation/stars");
let StarsWithFBData = WithFBData(Stars);
let EndorseButton = require("../activity/endorsebutton");
let ReputationTags = require("../reputation/tags");
let ReputationTagsWithFBData = WithFBData(ReputationTags);
let ReputationBadge = require("../reputation/badge");
let ReputationBadgeWithFBData = WithFBData(ReputationBadge);
let ReputationHelper = require("../reputation/helper");
var FBField = require("../generic/FBField");
var FBFieldWithFBData = WithFBData(FBField);

// menu at the top of the primary position
// Tremr.Feed.Header
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  propTypes: {
    model: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
  },

  getInitialState: function () {
    return {
      haveAutoSwitched: this.props.context.scope != "featured",
      subfeedUpdates: 0,
    };
  },

  // load data from server when first added to DOM
  componentDidMount: function () {
    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // listen for updates to subfeeds - set in feed data AND update menu
    this.listenTo(
      Tremr.dispatcher,
      "tremr:" + this.props.context.feed + ":subfeeds:save:success",
      function (subfeeds) {
        let m = this.props.model;
        m.set("subfeeds", subfeeds);
        this.setState({
          model: m,
          subfeedUpdates: this.state.subfeedUpdates + 1,
        });
      }.bind(this)
    );

    // listen for sign-in/out and update user in our session
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signin:success",
      function (user) {
        this.setState({
          userUnchecked: false,
          user: user,
        });
      }.bind(this)
    );

    // listen for save profile
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:profile:success",
      function (draft_id) {
        this.setState({
          isLoading: false,
        });
      }.bind(this)
    );

    this.listenTo(
      Tremr.dispatcher,
      "tremr:close:overlay",
      function (draft_id) {
        this.setState({
          isLoading: false,
        });
      }.bind(this)
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    if (this.stopListening) {
      this.stopListening();
    }
  },

  // navigate to the new url allowing the routes to sort out context
  handleClick: function (event) {
    let tab = $(event.currentTarget).attr("data-value");
    let subfeed = null;
    if (tab == "posts") {
      subfeed = "featured";
    }
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(
      this.getContext(tab, subfeed),
      scrollpos,
      undefined,
      false
    );
  },

  handleSubmenuClick: function (event, data) {
    let context = this.getContext(data.scope, data.subfeed, data.sort);
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(context, scrollpos, undefined, false);
  },

  getContext: function (tab, subfeed, sort) {
    if (sort === undefined) {
      sort = "latest";
    }

    let context = {
      target: "feed",
      scope: tab,
      feed: this.props.context.feed,
      sort: sort,
    };
    if (subfeed) {
      context.subfeed = subfeed;
    } else {
      context.subfeed = null;
    }
    if (this.props.context.ui == "home") {
      context.ui = "home";
    }
    return context;
  },

  getUrl: function (tab, subfeed) {
    return Tremr.routes.urlFor({
      primary: this.getContext(tab, subfeed),
    });
  },

  signOut: function () {
    Tremr.dispatcher.message(this, "tremr:user:signout", {});
  },

  editProfile: function () {
    Tremr.dispatcher.message(this, "tremr:open:profile", {});
  },

  editAdminProfile: function () {
    if (this.props.model) {
      this.setState({
        isLoading: true,
      });

      Tremr.dispatcher.message(
        this,
        "tremr:open:adminprofile",
        this.props.model.get("author_id")
      );
    }
  },

  navigateNonHome: function () {
    let nonhomepagecontext = this.getContext("posts", "featured", "latest");
    nonhomepagecontext.ui = null;
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(nonhomepagecontext, scrollpos, undefined, false);
  },

  render: function () {
    var classes = {
      userheader: true,
      header: true,
      feed: true,
      loading: this.state.isLoading,
      homeui: this.props.context.ui == "home",
      "has-image":
        this.props.model.get("header_image") &&
        this.props.model.get("header_image").public_id,
    };
    classes[this.props.context.feed] = true;
    classes = classNames(classes);

    var title = this.props.context.feed.capitalize();
    if (this.props.model) {
      title = (
        <h1 key="feedName">
          <Tremr.Generic.Abbreviations
            text={this.props.model.get("author_name")}
          />
        </h1>
      );
    }
    var username = this.props.context.feed;
    if (this.props.model) {
      username = (
        <div className="username">@{this.props.model.get("name")}</div>
      );
    }

    let avatar = null;
    let bio = null;
    let location = null;
    let twitter = null;
    let instagram = null;
    let blog = null;
    let draftTab = null;
    let followbutton = null;
    let endorsebutton = null;
    let share = null;
    let manageSubfeeds = null;
    let header_image = null;

    let defaultStats = {
      posts: 0,
      followers: 0,
      following: 0,
      subfeeds: {},
      channels: 0,
    };
    let defaultSubfeedStats = {
      featured: 0,
    };
    let stats = _.extend(defaultStats, this.props.model.get("stats"));
    stats["subfeeds"] = _.extend(defaultSubfeedStats, stats["subfeeds"]);

    // if (this.state.isLoading === false) {

    var avatarSize = 200;
    if (Tremr.Utils.isDeviceOrNarrow()) {
      avatarSize = 150;
    }

    if (
      this.props.model.get("author_avatar_image") &&
      this.props.model.get("author_avatar_image").public_id
    ) {
      avatar = (
        <div
          key={
            "authorAvatar" +
            this.props.model.get("author_avatar_image").public_id
          }
          className="avatar"
        >
          <Tremr.Generic.Image
            key={
              "authorAvatarImage" +
              this.props.model.get("author_avatar_image").public_id
            }
            height={avatarSize}
            width={avatarSize}
            image={this.props.model.get("author_avatar_image")}
          />
        </div>
      );
    }

    let headerHeight = 320;
    let highlightColor = Config.colors.highlight;
    if (this.props.model.get("highlight_color")) {
      highlightColor = this.props.model.get("highlight_color");
    }

    if (
      this.props.model.get("header_image") &&
      this.props.model.get("header_image").public_id
    ) {
      header_image = (
        <div
          key={"userHeader" + this.props.model.get("header_image").public_id}
          className="header-image"
        >
          <Tremr.Generic.Image
            initialBackgroundColor={highlightColor}
            gravity="g_auto"
            urlHeight={320}
            urlWidth={1280}
            key={
              "userHeaderImage" + this.props.model.get("header_image").public_id
            }
            height={headerHeight}
            width={"auto"}
            image={this.props.model.get("header_image")}
            resizable={true}
          />
        </div>
      );
    } else {
      header_image = <div key="userHeaderBlank" className="header-image"></div>;
    }

    var user = this.state.user;

    // if user is signed-in and this is their user feed, show drafts
    if (user && user.get("feedname") == this.props.context.feed) {
      // draftTab = <Tremr.Generic.StatTab href={this.getUrl('drafts')} handleClick={this.handleClick} value="drafts" data={user.get('drafts_count')} label="Drafts" active={(this.props.context.scope == 'drafts')} />
      draftTab = (
        <DraftsTab
          href={this.getUrl("drafts")}
          onClick={this.handleClick}
          active={this.props.context.scope == "drafts"}
        />
      );

      // // button to edit profile or sign-out
      // var signout = {
      //   label: <div><Tremr.Generic.SvgIcon icon="log-out" /> Log Out</div>,
      //   action: this.signOut
      // };
      // button = <Tremr.Generic.Multibutton label="Edit Profile" action={this.editProfile} additional={[signout]} />;
      //
      // // let the author edit this feeds sub-feeds
      manageSubfeeds = (
        <ManageSubfeedsButton
          text="+ Add Feed"
          key={"managesubfeeds-" + this.state.subfeedUpdates}
          feed={this.props.context.feed}
          subfeeds={this.props.model.get("subfeeds") || []}
        />
      );

      // and use author user avatar
      if (
        this.state.user.get("avatar_image") &&
        this.state.user.get("avatar_image").public_id
      ) {
        avatar = (
          <div
            key={"userAvatar" + this.state.user.get("avatar_image").public_id}
            className="avatar"
          >
            <Tremr.Generic.Image
              key={
                "userAvatarImage" +
                this.state.user.get("avatar_image").public_id
              }
              height={avatarSize}
              width={avatarSize}
              image={this.state.user.get("avatar_image")}
            />
          </div>
        );
      }

      // and use author header image
      if (
        this.state.user.get("header_image") &&
        this.state.user.get("header_image").public_id
      ) {
        header_image = (
          <div
            key={"userHeader" + this.state.user.get("header_image").public_id}
            className="header-image"
          >
            <Tremr.Generic.Image
              initialBackgroundColor={highlightColor}
              gravity="g_auto"
              urlHeight={320}
              urlWidth={1280}
              key={
                "userHeaderImage" +
                this.state.user.get("header_image").public_id
              }
              height={headerHeight}
              width="auto"
              image={this.state.user.get("header_image")}
              resizable={true}
            />
          </div>
        );
      }

      let nameKey = this.state.user.get("name").replace(/\W/g, "_");
      let subfeed = null;
      if (this.props.context.ui == "home") {
        subfeed = this.props.context.subfeed
          ? this.props.context.subfeed.capitalize()
          : null;
      }
    } else {
      followbutton = (
        <Tremr.Follow.Button
          title={this.props.model.get("author_name")}
          subfeeds={this.props.model.get("subfeeds") || []}
          entity="feed"
          id={this.props.model.get("name")}
        />
      );

      endorsebutton = (
        <EndorseButton userfor={this.props.model.get("author_id")} />
      );
    }

    let shareImage = "";
    let shareUrl = this.getUrl("posts", "featured");
    share = (
      <Share
        moreIcon={<Tremr.Generic.SvgIcon icon="share-reverse" />}
        icons={["more"]}
        imageUrl={shareImage}
        url={shareUrl}
        title={
          this.props.model.get("author_name")
            ? this.props.model
                .get("author_name")
                .capitalize()
                .replace(/&[^;]{2,3,4};/g, " ")
            : ""
        }
        description={
          this.props.model.get("bio")
            ? this.props.model.get("bio").replace(/&[^;]{2,3,4};/g, " ")
            : ""
        }
      />
    );

    var authortags = null;
    if (
      this.props.model &&
      this.props.model.get("author_tags") &&
      _.contains(
        this.props.model.get("author_tags"),
        Tremr.Theme.RecommendedWriter.Tag
      )
    ) {
      authortags = (
        <div className="author-tags">
          {Tremr.Theme.RecommendedWriter.Description}
        </div>
      );
    }

    const reputationStarsDataPoints = [
      ReputationHelper.getUserReputationStarsDataPoint(
        this.props.model.get("author_id")
      ),
      ReputationHelper.enhanceDataPoint(
        ReputationHelper.getUserReputationBadgeDataPoint(
          this.props.model.get("author_id")
        ),
        {
          name: "suppress",
          check: function (value) {
            return value && value.length > 0;
          },
        }
      ),
    ];
    let reputationStars = null;

    const reputationTagsDataPoints = [
      ReputationHelper.getUserReputationTagsDataPoint(
        this.props.model.get("author_id")
      ),
    ];
    let reputationTags = (
      <ReputationTagsWithFBData
        highlightColor={highlightColor}
        height={18}
        scale={window.devicePixelRatio}
        dataPoints={reputationTagsDataPoints}
      />
    );

    // key="viewedusersReputation"

    reputationStars = (
      <StarsWithFBData
        highlightColor={highlightColor}
        height={19}
        width={57}
        scale={window.devicePixelRatio}
        dataPoints={reputationStarsDataPoints}
      />
    );

    const reputationBadgeDataPoints = [
      ReputationHelper.getUserReputationBadgeDataPoint(
        this.props.model.get("author_id")
      ),
    ];
    const userBadge = (
      <ReputationBadgeWithFBData dataPoints={reputationBadgeDataPoints} />
    );

    let bioText = "";
    if (
      this.state.user &&
      user.get("feedname") == this.props.context.feed &&
      this.state.user.get("bio") &&
      this.state.user.get("bio").length > 0
    ) {
      bioText = this.state.user.get("bio");
    } else {
      bioText = this.props.model.get("author_bio");
    }
    bio = (
      <div className="bio">
        {reputationStars}
        {userBadge}
        {reputationTags}
        {authortags}
        <hr />
        <Tremr.Generic.Abbreviations text={bioText} />
      </div>
    );

    if (
      this.state.user &&
      user.get("feedname") == this.props.context.feed &&
      this.state.user.get("location") &&
      this.state.user.get("location").length > 0
    ) {
      location = (
        <div className="location">
          <Tremr.Generic.Abbreviations text={this.state.user.get("location")} />
        </div>
      );
    } else if (
      this.props.model.get("author_location") &&
      this.props.model.get("author_location").length > 0
    ) {
      location = (
        <div className="location">
          <Tremr.Generic.Abbreviations
            text={this.props.model.get("author_location")}
          />
        </div>
      );
    }
    if (
      this.state.user &&
      user.get("feedname") == this.props.context.feed &&
      this.state.user.get("twitter") &&
      this.state.user.get("twitter").length > 0
    ) {
      twitter = (
        <a
          target="_blank"
          href={
            "https://twitter.com/" +
            this.state.user
              .get("twitter")
              .replace("@", "")
              .replace(/(https?:\/\/)?(twitter\.com\/?)?/, "")
          }
          className="twitter"
        >
          Twitter
        </a>
      );

      let twitterDataPoints = [{
        name: 'field',
        key: `/community/reputation/users/${user.get("_id")}/twitter_follower_count`,
      }];
      twitter = <span>{twitter}<FBFieldWithFBData prefix=" (" suffix=")" dataPoints={twitterDataPoints} /></span>;

    } else if (
      this.props.model.get("author_twitter") &&
      this.props.model.get("author_twitter").length > 0
    ) {
      twitter = (
        <a
          target="_blank"
          href={
            "https://twitter.com/" +
            this.props.model
              .get("author_twitter")
              .replace("@", "")
              .replace(/(https?:\/\/)?(twitter\.com\/?)?/, "")
          }
          className="twitter"
        >
          Twitter
        </a>
      );

      let twitterDataPoints = [{
        name: 'field',
        key: `/community/reputation/users/${this.props.model.get("author_id")}/twitter_follower_count`,
      }];
      twitter = <span>{twitter}<FBFieldWithFBData prefix=" (" suffix=")" dataPoints={twitterDataPoints} /></span>;

    }
    if (
      this.state.user &&
      user.get("feedname") == this.props.context.feed &&
      this.state.user.get("instagram") &&
      this.state.user.get("instagram").length > 0
    ) {
      instagram = (
        <a
          target="_blank"
          href={
            "https://instagram.com/" +
            this.state.user
              .get("instagram")
              .replace("@", "")
              .replace(/(https?:\/\/)?(instagram\.com\/?)?/, "")
          }
          className="instagram"
        >
          Instagram
        </a>
      );
    } else if (
      this.props.model.get("author_instagram") &&
      this.props.model.get("author_instagram").length > 0
    ) {
      instagram = (
        <a
          target="_blank"
          href={
            "https://instagram.com/" +
            this.props.model
              .get("author_instagram")
              .replace("@", "")
              .replace(/(https?:\/\/)?(instagram\.com\/?)?/, "")
          }
          className="instagram"
        >
          Instagram
        </a>
      );
    }
    if (
      this.state.user &&
      this.state.user.get("feedname") == this.props.context.feed &&
      this.state.user.get("blog") &&
      this.state.user.get("blog").length > 0
    ) {
      blog = (
        <a target="_blank" href={this.state.user.get("blog")} className="blog">
          Blog
        </a>
      );
    } else if (
      this.props.model.get("author_blog") &&
      this.props.model.get("author_blog").length > 0
    ) {
      blog = (
        <a
          target="_blank"
          href={this.props.model.get("author_blog")}
          className="blog"
        >
          Blog
        </a>
      );
    }

    var followersLink = null;
    if (this.props.model.get("id") != "tremr" && stats) {
      followersLink = (
        <Tremr.Generic.StatTab
          href={this.getUrl("followers")}
          handleClick={this.handleClick}
          value="followers"
          data={stats["followers"]}
          label="Followers"
          active={this.props.context.scope == "followers"}
        />
      );
    }

    // }

    var adminedit = null;
    if (this.state.user && this.state.user.get("admin") == "yes") {
      adminedit = (
        <Tremr.Generic.SvgIcon
          icon="admin-config"
          classes="button icon"
          onClick={this.editAdminProfile}
        />
      );
    }

    let submenu = null;
    if (
      this.props.context.scope == "posts" ||
      this.props.context.scope == "all"
    ) {
      let menuitems = _.map(this.props.model.get("subfeeds"), (subfeed) => {
        if (subfeed && subfeed.featured == "yes") {
          return null;
        } else if (stats) {
          return (
            <Tremr.Generic.StatTab
              key={subfeed.name}
              href={this.getUrl("posts", subfeed.name)}
              handleClick={this.handleSubmenuClick}
              value={{ scope: "posts", subfeed: subfeed.name }}
              data={stats["subfeeds"][subfeed.name]}
              label={subfeed.name}
              active={
                this.props.context.scope == "posts" &&
                this.props.context.subfeed == subfeed.name
              }
            />
          );
        }
      });

      if (stats) {
        let featuredMenuItem = (
          <Tremr.Generic.StatTab
            key="featured"
            href={this.getUrl("posts", "featured")}
            handleClick={this.handleSubmenuClick}
            value={{ scope: "posts", subfeed: "featured" }}
            data={stats["subfeeds"]["featured"]}
            label="Featured"
            active={
              this.props.context.scope == "posts" &&
              this.props.context.subfeed == "featured"
            }
          />
        );
        if (this.props.model.get("id") == Tremr.Theme.HomepageFeed) {
          featuredMenuItem = null;
        }
        submenu = (
          <nav className="menu">
            {featuredMenuItem}
            <Tremr.Generic.StatTab
              key="all-posts"
              href={this.getUrl("posts", "all")}
              handleClick={this.handleSubmenuClick}
              value={{ scope: "posts", subfeed: "all" }}
              data={stats["posts"]}
              label="All Posts"
              active={
                this.props.context.scope == "posts" &&
                (!this.props.context.subfeed ||
                  this.props.context.subfeed == "all")
              }
            />
            <Tremr.Generic.StatTab
              key="trending"
              href={this.getUrl("posts", "trending")}
              handleClick={this.handleSubmenuClick}
              value={{ scope: "posts", subfeed: "trending" }}
              data={stats["trending"]}
              label="Trending"
              active={
                this.props.context.scope == "posts" &&
                this.props.context.subfeed == "trending"
              }
            />
            <Tremr.Generic.StatTab
              key="recommended"
              href={this.getUrl("posts", "recommended")}
              handleClick={this.handleSubmenuClick}
              value={{ scope: "posts", subfeed: "recommended" }}
              data={stats["recommended"]}
              label="Top Rated"
              active={
                this.props.context.scope == "posts" &&
                this.props.context.subfeed == "recommended"
              }
            />
            {menuitems}
            {manageSubfeeds}
          </nav>
        );
      }
    } else if (this.props.context.scope == "followers") {
      submenu = (
        <nav className="menu">
          <Tremr.Generic.Tab
            key="latest"
            href={this.getUrl("followers", "latest")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "followers", sort: "latest" }}
            text="Latest"
            active={
              this.props.context.scope == "followers" &&
              this.props.context.sort == "latest"
            }
          />
          <Tremr.Generic.Tab
            key="alphabetical"
            href={this.getUrl("followers", "alphabetical")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "followers", sort: "alphabetical" }}
            text="A-Z"
            active={
              this.props.context.scope == "followers" &&
              this.props.context.sort == "alphabetical"
            }
          />
        </nav>
      );
    } else if (this.props.context.scope == "following") {
      submenu = (
        <nav className="menu">
          <Tremr.Generic.Tab
            key="latest"
            href={this.getUrl("following", "latest")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "following", sort: "latest" }}
            text="Latest"
            active={
              this.props.context.scope == "following" &&
              this.props.context.sort == "latest"
            }
          />
          <Tremr.Generic.Tab
            key="alphabetical"
            href={this.getUrl("following", "alphabetical")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "following", sort: "alphabetical" }}
            text="A-Z"
            active={
              this.props.context.scope == "following" &&
              this.props.context.sort == "alphabetical"
            }
          />
        </nav>
      );
    } else if (this.props.context.scope == "endorsements") {
      submenu = (
        <nav className="menu">
          <Tremr.Generic.Tab
            key="latest"
            href={this.getUrl("endorsements", "latest")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "endorsements", sort: "latest" }}
            text="Latest"
            active={
              this.props.context.scope == "endorsements" &&
              this.props.context.sort == "latest"
            }
          />
          <Tremr.Generic.Tab
            key="alphabetical"
            href={this.getUrl("endorsements", "alphabetical")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "endorsements", sort: "alphabetical" }}
            text="A-Z"
            active={
              this.props.context.scope == "endorsements" &&
              this.props.context.sort == "alphabetical"
            }
          />
        </nav>
      );
    } else if (this.props.context.scope == "channels") {
      submenu = (
        <nav className="menu">
          <Tremr.Generic.Tab
            key="latest"
            href={this.getUrl("channels", "latest")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "channels", sort: "latest" }}
            text="Latest"
            active={
              this.props.context.scope == "channels" &&
              this.props.context.sort == "latest"
            }
          />
          <Tremr.Generic.Tab
            key="alphabetical"
            href={this.getUrl("channels", "alphabetical")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "channels", sort: "alphabetical" }}
            text="A-Z"
            active={
              this.props.context.scope == "channels" &&
              this.props.context.sort == "alphabetical"
            }
          />
        </nav>
      );
    }

    let channelsTab = null;
    if (stats["channels"] && stats["channels"] > 0) {
      channelsTab = (
        <Tremr.Generic.StatTab
          href={this.getUrl("channels")}
          handleClick={this.handleClick}
          value="channels"
          data={stats["channels"]}
          label="Blogcasts"
          active={this.props.context.scope == "channels"}
        />
      );
    }

    let tabs = null;
    if (stats) {
      let endorsements = null;
      if (stats["endorsements"] && stats["endorsements"] > 0) {
        endorsements = (
          <Tremr.Generic.StatTab
            href={this.getUrl("endorsements")}
            handleClick={this.handleClick}
            value="endorsements"
            data={stats["endorsements"]}
            label="Endorsements"
            active={this.props.context.scope == "endorsements"}
          />
        );
      }

      tabs = (
        <div className="tabs">
          <Tremr.Generic.StatTab
            href={this.getUrl("posts", "featured")}
            handleClick={this.handleClick}
            value="posts"
            data={stats["posts"]}
            label="Posts"
            active={this.props.context.scope == "posts"}
          />
          {channelsTab}
          {followersLink}
          <Tremr.Generic.StatTab
            href={this.getUrl("following")}
            handleClick={this.handleClick}
            value="following"
            data={stats["following"]}
            label="Following"
            active={this.props.context.scope == "following"}
          />
          {endorsements}
          {draftTab}
        </div>
      );
    }

    // very simple version for showing in the homepage UI style
    if (this.props.context.ui == "home") {
      let subfeed = this.props.context.subfeed
        ? "(" + this.props.context.subfeed.capitalize() + ")"
        : null;
      let nonhomepagecontext = this.getContext("posts", "featured", "latest");
      nonhomepagecontext.ui = null;
      let nonhomepageurl = Tremr.routes.urlFor({
        primary: nonhomepagecontext,
      });
      title = (
        <a
          className="feedName"
          href={nonhomepageurl}
          onClick={this.navigateNonHome}
        >
          <Tremr.Generic.Abbreviations
            text={this.props.model.get("author_name")}
          />
        </a>
      );
      return (
        <div className={classes}>
          <HorizontalScroller>
            <div className="feed-posts-subfeeds-summary">
              {title}
              {submenu}
            </div>
          </HorizontalScroller>
        </div>
      );
    }

    if (submenu) {
      submenu = <HorizontalScroller>{submenu}</HorizontalScroller>;
    }

    if (
      this.props.model.get("id") == Tremr.Theme.SystemFeed ||
      this.props.model.get("id") == Tremr.Theme.HomepageFeed
    ) {
      tabs = null;
    }

    // normal version
    return (
      <div className={classes}>
        {header_image}
        <Titlebar style="light">
          <div className="subheader">Profile</div>
          {adminedit}
        </Titlebar>
        <div className="content">
          {avatar}
          <div className="info">
            {title}
            {username}
            {bio}
            <div className="links">
              {location}
              {twitter}
              {instagram}
              {blog}
            </div>
          </div>
          <div className="actions-tabs-container">
            <div className="actions">
              {followbutton}
              {endorsebutton}
              {share}
            </div>
            {tabs}
          </div>
          {submenu ? submenu : <div className="no-submenu"></div>}
        </div>
      </div>
    );
  },
});
