// class for managing a representation of a twitter embed
// Tremr.Editor.TwitterBlock
module.exports = function(identifier, type) {

	// local ref to utils
	var Ranges = window.Tremr.Utils.Ranges;

	this.allowedFormat = false; // should we show the format popup

	// default empty attributes
	this._attributes = {
		identifier: false,
        type: false,
        code: false
	};

	// constructor params
	if (identifier !== undefined) { this._attributes.identifier = identifier; }
	if (type !== undefined) { this._attributes.type = type; }

	// generate a react control
	this.getControl = function(notifyPosition, removeBlock, readOnly, width, height) {
		return <Tremr.Editor.TwitterControl readOnly={readOnly} ref={this.get('identifier')} key={this.get('identifier')} identifier={this.get('identifier')} code={this.get('code')} removeBlock={removeBlock} notifyPosition={notifyPosition} />;
	};

	// iterate child nodes then siblings counting length until we reach the
	// offset we want, then return the node and the offset in that node
	// but only count length from within the caption node
	this.findOffsetFromSubnode = function(node, offsetRemaining, inCaption) {

		if (inCaption === undefined) { inCaption = false; }

		var returnValue = {
		  found: false,
		  node: false,
		  offset: 0
		};

		return returnValue;
	};


	this.updateFromNodes = function(nodes) {

	};

	// only process the caption and take it's plain text
	this.parseNodes = function(nodes) {

	    return {
	      plainText: '',
	      formatRules: []
	    };
	  },

	// traverse previous siblings, then into parent and traverse it's prev siblings
    // until we reach .block
    this.getOffsetInBlock = function(node, newLevel) {

      // if we ever reach the block (or no node), stop counting immediately
      if (!node || (node.nodeType == 1 && node.classList.contains('block'))) {
        return 0;
      }

      // if we reach the caption then stop counting
	  if (node.nodeType == 1 && node.classList.contains('caption')) {
        return 0;
      }

      var len = 0; // keep length

      // if this ISN'T a new level add it's length
      if (!newLevel) {
        if (node.textContent && node.textContent.length) {
          len = node.textContent.length
        }
      }

      // recurse to previous OR parent (set new level for parent)
      if (node && node.previousSibling) {
        len += this.getOffsetInBlock(node.previousSibling, false);
      } else if (node && node.parentNode) {
        len += this.getOffsetInBlock(node.parentNode, true);
      }

      return len;
    };

	// create a copy, with new Ids
	this.clone = function(keepId) {

		var block = new Tremr.Editor.TwitterBlock('cloned', 'cloned');
		block._attributes = {
			identifier: this._attributes.identifier,
	        type: this._attributes.type,
	        code: _.clone(this._attributes.code)
		};
		if (!keepId) {
			block._attributes.identifier = Tremr.Utils.uniqueId('block');
		}
		return block;
	};

	// get an attribute
	this.get = function(name) {

		// sanitize the code
		if (name == 'code') {
			return Tremr.Utils.sanitize(this._attributes[name], true, true, true);
		}

		return this._attributes[name];
	};

	// set an attribute
	this.set = function(key, val) {

		// sanitize the code
		if (key == 'code') {
			val = Tremr.Utils.sanitize(val, true, true, true);
		}

		this._attributes[key] = val;
	};

	// length of the plainText array
	this.length = function() {
		return 0;
	};

	// remove x characters from the captionText and remove/adjust rules to fit
	this.removeCharactersLeft = function(index) {

	};

	// remove x characters from the captionText and remove/adjust rules to fit
	this.removeCharactersRight = function(index) {

	};

	// add content and rules from another block to the end of this one
	this.append = function(block) {

	};

	// split this block at the specified position in the captionText, return two new
	// blocks with adjusted format rules - the second one will always be a textblock
	this.splitAt = function(leftIndex, rightIndex) {

		return this;
	};

	// ignore set format requests
	this.setFormat = function(format, start, end, additional) {
	};

	// ignore set format requests
	this.unsetFormat = function(format, start, end) {
	};

	// never has a format
	this.hasFormat = function(format, position) {
		return false;
	};

	// never has a format
	this.containsFormat = function(format, start, end) {
		return false;
	}


}
