require("./scss/alerts.scss");

var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

  render: function() {

		if (this.props.children.length == 0) return null;

    return (
			<footer className="layout-alerts">
        {this.props.children}
			</footer>
		);
	}
});
