
var moment = require("moment");
var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

module.exports = CreateReactClass({
   mixins: [
      PureRenderMixin
   ],

   propTypes: {
       post: PropTypes.object.isRequired,
       expandBreadcrumb: PropTypes.func.isRequired
   },

   render: function() {

       let icon = null;
       // let icon = <Tremr.Generic.SvgIcon icon="comment"/>;
       let comment = true;
       // if (this.props.post['fieldset'] && this.props.post['fieldset'].length > 0) {
           // icon = <Tremr.Generic.SvgIcon icon={this.props.post['fieldset']} fill={true}/>;
       // }

       let created = moment(this.props.post['created_at'], "YYYY-MM-DDTHH:mmZ");
       let yearDiff = parseInt(moment().format("YYYY")) - parseInt(created.format("YYYY"));
       let age = created.format("D MMM");
       if (yearDiff > 0) {
          age = created.format("MMM'YY");
       }
       let timestamp = created.format();
       let isToday = (created.format("D M YY") == moment().format("D M YY"));
       if (isToday) {
           age = created.format("H:mma");
       }


       return <div className="breadcrumb" data-slug={this.props.post['slug']} onClick={this.props.expandBreadcrumb}>
           <div className="card-content-margin">
               <div className="avatar-link">
                   <Tremr.Generic.Image image={this.props.post['author_avatar_image']} height={60} width={60}/>
               </div>
               <Tremr.Generic.SvgIcon icon="fat-chevron" fill={true} classes="icon parent-post-icon" />
           </div>
           <div className="card-content">
               <div className="byline">
                   <a className="author">
                     {this.props.post['author_name']}
                  </a>
                  {icon}
                  <time dateTime={timestamp}>{age}</time>
                </div>
                <div className="comment">
                   {this.props.post['title']}
               </div>
               <div className="expand">
                   <Tremr.Generic.SvgIcon icon="chevron" fill={true}/>
               </div>
           </div>
       </div>;
   }
});
