require("./scss/endorsebutton.scss");

var classNames = require("../../utils/classnames");
var PopUp = require("../generic/popup");
var Subfeeds = require("./subfeeds");
var TremrUtils = require("../../utils/tremr_utils");
var IconButton = require("../generic/iconbutton");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var UserAwareMixin = require("../../mixins/userawaremixin");

// Tremr.Follow.Button
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  propTypes: {
    userfor: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return {
      set: false,
      fbRef: null,
    };
  },

  attachFirebase: function () {
    if (!this.state.fbRef && this.state.user) {
      let submitKey = "endorsements/{ofuser}/reports/{user}/submitted";
      submitKey = submitKey.replace(/\{ofuser\}/, this.props.userfor);
      submitKey = submitKey.replace(/\{user\}/, this.state.user.get("_id"));

      let fbRef = Tremr.firebase.database().ref(submitKey); //key.replace(/\{user\}/, this.state.user.get("_id")));

      fbRef.on("value", (snapshot) => {
        let data = snapshot.val();
        this.setState({
          set: data,
        });
      });

      this.setState({
        fbRef: fbRef,
      });
    }
  },

  // attach to firebase submitted field
  componentDidMount: function () {
    this.attachFirebase();
  },

  componentDidUpdate: function () {
    this.attachFirebase();
  },

  // disconnect from firebase
  componentWillUnmount: function () {},

  open: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.user) {
      var overlayData = {
        class: "Tremr.User.Endorse",
        style: "sidebar",
        props: {
          user: this.state.user,
          user_id: this.props.userfor,
        },
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    } else {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        callback: this.open,
        data: event,
      });
      return;
    }
  },

  // display
  render: function () {
    // see if we have loaded the image or not
    var classes = {
      "endorse-button": true,
      set: this.state.set,
    };
    classes = classNames(classes);

    let label = "Endorse";
    if (this.state.set) {
      label = "Endorsed";
    }

    return (
      <div className="endorse-button-container">
        <button className={classes} onClick={this.open}>
          {label}
        </button>
      </div>
    );
  },
});
