require("./scss/gdpr.scss");

var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");
var Cookies = require("js-cookie/src/js.cookie");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  getInitialState: function () {
    return {
      closed_gdpr: Cookies.get("has_closed_gdpr") == "1",
    };
  },

  close: function (e) {
    Cookies.set("has_closed_gdpr", "1", { expires: 365 });
    this.setState({
      closed_gdpr: true,
    });
  },

  render: function () {
    if (this.state.closed_gdpr) {
      return null;
    }

    // return (
    // 	<div className="layout-gdpr layout-alert-wrapper">
    // 		<div className="layout-alert-content">
    // 			<p>By using {Tremr.Theme.ShortTitle} you agree to our <a href={Tremr.Theme.CookiesLink} target="_blank">Cookies&nbsp;Use.</a>
    // 			</p>
    //       <SvgIcon icon="remove" onClick={this.close} />
    // 		</div>
    // 	</div>
    // );
    return null;
  },
});
