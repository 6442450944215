var alertify = require("alertify");
var TremrStorage = require("../../utils/tremr_storage");

// Tremr.EditorStore
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  // if the editor is open, ask the user if they want to lose their changes
  (store.canNavigate = function (callback, cancelledCallback) {
    var context = Tremr.context.getState();
    if (
      (context.editor && context.editor.open && !context.editor.allowNav) ||
      (context.current && context.current.target == "post")
    ) {
      if (!context.editing || context.editing <= 0) {
        callback();
      } else {
        // we have an editor - now ask it if it is dirty!
        Tremr.dispatcher.message(this, "tremr:editor:askdiscard", {
          discardCallback: callback,
          keepCallback: cancelledCallback,
        });
      }
    } else {
      callback();
    }
  }),
    // create the component for the editor
    (store.getEditorComponent = function (context) {
      if (context.post_id) {
        return (
          <Tremr.Editor.Editor
            key="editor"
            ref="editor"
            channel={context.channel}
            initialFieldset={context.fieldset}
            existingPostId={context.post_id}
            author={Tremr.stores.userStore.getUser()}
          />
        );
      } else if (context.draft_id) {
        return (
          <Tremr.Editor.Editor
            key="editor"
            ref="editor"
            channel={context.channel}
            initialFieldset={context.fieldset}
            existingDraftId={context.draft_id}
            author={Tremr.stores.userStore.getUser()}
          />
        );
      } else if (context.parent) {
        return (
          <Tremr.Editor.Editor
            onPost={context.onPost}
            key="editor"
            ref="editor"
            channel={context.channel}
            initialFieldset={context.fieldset}
            parent={context.parent}
            author={Tremr.stores.userStore.getUser()}
          />
        );
      } else {
        return (
          <Tremr.Editor.Editor
            key="editor"
            ref="editor"
            channel={context.channel}
            initialFieldset={context.fieldset}
            author={Tremr.stores.userStore.getUser()}
          />
        );
      }
    });

  // request editor open (and edit if postModel passed)
  store.listenTo(Tremr.dispatcher, "tremr:editor:open", function (data) {
    // block IE & Edge for now
    if (Tremr.Utils.checkForIE()) {
      // if (true) {
      alertify.alert(
        "We're sorry but your browser (Internet Explorer) does not support editing at this time."
      );
      return;
    }

    // we must only allow this if the user is signed-in, so
    // raise a signed-in only event and allow user store to
    // control it.
    if (!Tremr.stores.userStore.getUser()) {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        event: "tremr:editor:open",
        data: data,
      });
      return;
    }

    // if the editor is already open then ask the user if they really want to
    // and if they do close it and recall later
    var state = Tremr.context.getState();
    if (state.editor && state.editor.open) {
      alertify.confirm("Discard Changes?", function (e) {
        if (e) {
          _.delay(function () {
            Tremr.dispatcher.message(this, "tremr:editor:open", data);
          }, 200);
        }
      });
      return;
    }

    var editorState = {
      editor: {
        open: true,
      },
    };

    if (data.draft_id) {
      editorState.editor.draft_id = data.draft_id;
    }

    if (data.post_id) {
      editorState.editor.post_id = data.post_id;
    }

    if (data.parent) {
      editorState.editor.parent = data.parent;
      if (data.parent.get("immediate-parent-checked")) {
        console.dir(data.parent.get("ancestors-with-immediate-parent-left"));
        editorState.editor.parent.set(
          "ancestors",
          data.parent.get("ancestors-with-immediate-parent-left")
        );
      }
    }

    if (data.fieldset) {
      editorState.editor.fieldset = data.fieldset;
    }

    if (data.channel) {
      editorState.editor.channel = data.channel;
    }

    if (data.onPost) {
      editorState.editor.onPost = data.onPost;
    }

    // record current scroll position for current url
    let currentUrl = Tremr.routes.urlFor(Tremr.context.getState());
    let scrollpos = window.scrollY;
    if (scrollpos) {
      let history = TremrStorage.getSession("scrollpos") || {};
      history[currentUrl] = scrollpos;
      TremrStorage.setSession("scrollpos", history);
    }

    // OPEN
    Tremr.context.add(editorState);

    var newUrl = Tremr.routes.urlFor({
      editor: Tremr.context.getState().editor,
    });
    Tremr.routes.updateUrl(newUrl); // make sure our URL is updated
  });

  // close the editor if it is open when the user signs-out
  this.listenTo(Tremr.dispatcher, "tremr:user:signout", function (
    action,
    data
  ) {
    // only worry if the editor is open
    var state = Tremr.context.getState();
    if (state.editor && state.editor.open) {
      Tremr.dispatcher.message(this, "tremr:editor:close");
    }
  });

  // update state with count of shown editors
  this.listenTo(Tremr.dispatcher, "tremr:editor:visible", function (
    action,
    data
  ) {
    var editorCount = 0;
    var state = Tremr.context.getState();
    if (state.editing) {
      editorCount = state.editing;
    }
    editorCount = editorCount + 1;
    var editorState = { editing: editorCount };
    Tremr.context.add(editorState);
  });

  // update state with count of shown editors
  this.listenTo(Tremr.dispatcher, "tremr:editor:hidden", function (
    action,
    data
  ) {
    var editorCount = 0;
    var state = Tremr.context.getState();
    if (state.editing) {
      editorCount = state.editing;
    }
    editorCount = editorCount - 1;
    var editorState = { editing: editorCount };
    Tremr.context.add(editorState);
  });

  // close editor and reset the URL
  // if we don't have a primary or current then redirect to homepage
  // close the editor if it is open when the user signs-out
  this.listenTo(Tremr.dispatcher, "tremr:editor:close", function (
    action,
    data
  ) {
    // only worry if the editor is open
    var state = Tremr.context.getState();
    if (state.editor && state.editor.open) {
      // just close it
      Tremr.context.add({
        editor: {
          open: false,
        },
      });

      // if we don't have a primary or current then redirect to homepage
      if (state.current || state.primary) {
        var currentUrl = Tremr.routes.urlFor(Tremr.context.getState());
        Tremr.routes.updateUrl(currentUrl);

        // reset scroll position
        var scrollPos = Tremr.routes.checkForScrollPos(
          Tremr.context.getState()
        );
        if (scrollPos) {
          _.defer(function () {
            window.scrollTo({
              top: scrollPos,
            });
          });
        }
      } else {
        window.location = "/";
        return;
      }
    }
  });

  return store;
};
