// Tremr.LayoutStore = function () {
module.exports = function () {

    // use backbone events
    var store = _.extend(this, Backbone.Events);

    // handle the init event to inject our base layout into the page
    // store.listenTo(Tremr.dispatcher, "tremr:init", function(data) {
    // 	// console.log("LayoutStore - tremr:init");
    // }, this);


    // handle the contextchanged event to update our components
    // store.listenTo(Tremr.dispatcher, "tremr:contextchanged", function(state) {
    //     console.log("LayoutStore - tremr:contextchanged");
    //     console.dir(state);
    // }, this);

    // open the specified component in an overlay
    store.listenTo(Tremr.dispatcher, "tremr:open:overlay", function(overlayData) {
        // console.log("LayoutStore tremr:open:overlay ->");
        // console.dir(overlayData);

        Tremr.context.addToArray('overlays', overlayData);

    }, this);

    // close the overlay from the index
    store.listenTo(Tremr.dispatcher, "tremr:close:overlay", function(overlayIndex) {
        // console.log("LayoutStore tremr:close:overlay ->");
        // console.dir(overlayIndex);

        Tremr.context.removeFromArray('overlays', overlayIndex);

        // reset the URL
        var newUrl = Tremr.routes.urlFor(Tremr.context.getState());
        Tremr.routes.updateUrl(newUrl);

    }, this);



    return store;
};
