require("./scss/iconbutton.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// an icon button
// Tremr.Generic.Iconbutton = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	propTypes: {
      iconName: PropTypes.string.isRequired,
      text: PropTypes.string,
      identifier: PropTypes.string,
      tooltip: PropTypes.string,
      onClick: PropTypes.func,
	  active: PropTypes.bool
  	},

	getDefaultProps: function() {

		return {
			iconName: 'tremr',
			active: false,
			badgeContent: false
		};
	},

	handleClick: function(event) {

		if (this.props.onClick) {
			event.preventDefault();
			event.stopPropagation();
			var identifier = false;
			if (this.props.identifier) {
				identifier = this.props.identifier;
			}
			this.props.onClick(identifier);
		}
	},

	render: function() {

		var classes = {};
		classes['generic-iconbutton'] = true;
		classes['has-text'] = this.props.text;
		classes['active'] = this.props.active;
		if (this.props.identifier) {
			classes[this.props.identifier] = true;
		}
		classes = classNames(classes);

		var badgeContent = null;
		if (this.props.badge) {
			badgeContent = <div className="badge">{this.props.badge}</div>;
		}

		var tooltip = this.props.iconName;
		if (this.props.tooltip) {
			tooltip = this.props.tooltip;
		}

		if (this.props.text) {
			return (
				<div className={classes} onClick={this.handleClick}>
					<Tremr.Generic.SvgIcon icon={this.props.iconName} />
					{this.props.text}
					{badgeContent}
				</div>
			);
		} else {
			return (
				<div title={tooltip.capitalize()} className={classes} onClick={this.handleClick}>
					<Tremr.Generic.SvgIcon icon={this.props.iconName} />
					{badgeContent}
				</div>
			);
		}
	}
});
