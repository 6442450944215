// exports a hard-coded API url to prefix in all server calls
module.exports = {
  apiurl: "https://calexico.jimcode.org",
  firebase: {
    apiKey: "AIzaSyAp1hmYaudKMfwfD2xTSjSzmB1-Qi2RYlo",
    authDomain: "calexico.firebaseapp.com",
    databaseURL: "https://calexico.firebaseio.com",
    projectId: "calexico",
    storageBucket: "calexico.appspot.com",
    messagingSenderId: "238002503102",
    appId: "1:238002503102:web:1e0f4e320c7699d9ec0def",
  },
  colors: {
    highlight: "#FF7F10",
    grey: "#f5f4f4",
  },
  cloudinary: {
    url: "https://res.cloudinary.com/tremr/",
  },
  embedly: {
    key: "d2f0453179c645a48ff9e76bff5d8d86",
  },
  recaptcha: {
    key: "6Lc7OrEUAAAAANMxq12WLifdaQ5yzkm7H5vHO5Zm",
  },
  reputation: "all",
};
