require("./scss/pitchpage.scss");

var CreateReactClass = require('create-react-class');
var TremrUtils = require('../../utils/tremr_utils');
var Logo = require("../generic/logo");
var SvgIcon = require('../generic/svgicon');

// Tremr.Layout.Pitchpage
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  getInitialState: function() {
      return {
        showSigninInToolbar: true
      };
  },

  openSignIn: function(event) {
      event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

      // if meta key, allow normal open - for container use
      // data-href to open in new tab
      if (event.metaKey) {
          return;
      }

      if (this.props.onClose) {
        this.props.onClose();
      }

      event.preventDefault();
      Tremr.dispatcher.message(this, "tremr:open:signin", event);
  },

  requestInvite: function(event) {
      if (typeof ga !== "undefined") {
        ga("send", "event", "button", "request-invite");
      }

      event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

      // if meta key, allow normal open - for container use
      // data-href to open in new tab
      if (event.metaKey) {
          return;
      }

      event.preventDefault();
      Tremr.dispatcher.message(this, "tremr:open:requestinvite", event);
  },

  handleScroll: function(event) {

    let domNode = ReactDOM.findDOMNode(this);
    let scrollTop = 0;

    if (this.props.onClose) {
      let overlayNode = domNode.closest('.layout-overlay');
      if (overlayNode) {
        scrollTop = overlayNode.scrollTop;
      }
    } else {
      scrollTop = window.scrollY;
    }

    let requestNode = domNode.querySelector('a.request-invite');
    let requestTop = requestNode.offsetTop + requestNode.clientHeight;
    let showSigninInToolbar = true;

    if (scrollTop > requestTop) {
      showSigninInToolbar = false;
    }
    if (this.state.showSigninInToolbar != showSigninInToolbar) {
      console.log("toggle toolbar");
      this.setState({
        showSigninInToolbar: showSigninInToolbar
      });
    }
  },

  // watch for scrolling inside this element
	componentDidMount: function() {
    if (this.props.onClose) {
      let domNode = ReactDOM.findDOMNode(this);
      domNode = domNode.closest('.layout-overlay');
      if (domNode) {
        domNode.addEventListener('scroll', this.handleScroll);
      }

    } else {
      TremrUtils.addPassiveEventListener('scroll', this.handleScroll);
    }
	},

	// clear the scroll event handler
	componentWillUnmount: function() {
    if (this.props.onClose) {
      let domNode = ReactDOM.findDOMNode(this);
      domNode = domNode.closest('.layout-overlay');
      if (domNode) {
        domNode.removeEventListener('scroll', this.handleScroll);
      }
    } else {
      window.removeEventListener('scroll', this.handleScroll);
    }
	},

  navigateHome: function(event) {

    // update state
    let context = {
      target: 'homepage-feed',
      scope: 'all',
      sort: 'latest',
      feed: Tremr.Theme.HomepageFeed
    };

    Tremr.navigatePrimary(context, 0);
  },

  render: function() {

    let homeButton = null;
    if (!this.props.onClose) {
      homeButton = <a className="close" onClick={this.navigateHome}>
        <SvgIcon icon="cross" />
      </a>;
    }

    let toolbarButton = <button onClick={this.openSignIn} className="signin">Already have an account?</button>;
    if (this.state.showSigninInToolbar === false) {
      toolbarButton = <button onClick={this.requestInvite} className="request-invite-toolbar">Request Invitation</button>;
    }

  	return <div className="layout-pitchpage">
        <div className="toolbar">
            {homeButton}
            {toolbarButton}
        </div>
        <div className="section section-1">
          <div className="content tx2">

          <img className="pitch-page-logo-symbol" src="/images/pitch-page-logo-symbol.svg" />

          <Logo height={60} baseColor="#ffffff" />

          <p className="welcome-big-copy">A healthier habitat for your news, views and ideas</p>                      

          <p className="welcome-small-copy">
            Tremr turns blogging into a long-form conversation, letting you go deep on the things you care about and have rich, engaging conversations in an environment of trust and respect.<br/>
          </p>

            <p className="center">
                <a className="button request-invite" href="/request-invite" onClick={this.requestInvite}>Request Invitation</a>
            </p>
            <p className="center">
                <img className="welcome-chevron" src="/images/welcome-chevron.png" alt="more…" />
            </p>

            <h2>Say more…</h2>

            <p>Life can't always be chopped into soundbites. Tremr lets you
            say as much as you need, from a crisp one-liner to a full article with images,
            links and media.</p>

            <img src="/images/welcome-say-more-2.jpg" alt="Say more" />

        </div>
        </div>

        <div className="section section-2">
          <div className="content tx2">
          <h2>Look fabulous</h2>

          <p>Tremr gives you a beautiful space for ultimate self-expression. Not only does every post look great but your followers can choose how they browse your content: magazine, blog or list format.</p>

          <img src="/images/welcome-look-fab-2.jpg" alt="Look fabulous" />
          </div>
        </div>

        <div className="section section-3">
          <div className="content tx2">
          <h2>Everything is part of the conversation</h2>

          <p>On Tremr each post can be responded to in kind, letting members riff off each other's content and enabling a limitless web of insights and ideas.</p>

          <img src="/images/welcome-conversation-2.jpg" alt="Everything is part of the conversation" />
          </div>
        </div>

        <div className="section section-4">
          <div className="content tx2">
          <h2>Trust, respect and recognition</h2>

          <p>Tremr reboots the reward system of social media, recognizing quality over quantity and prioritizing trust and respect above all. The result is a welcoming space where ideas and opinions can co-exist, interact and procreate.</p>

          <img src="/images/welcome-trust-2.jpg" alt="Trust, Respect and Recognition" />
          </div>
        </div>

        <div className="section section-5">
          <div className="content tx2">
          <h2>A healthier home for your news, views and ideas</h2>

          <p>Escape the hustle and hype of today's social platforms and plug in to a community that gives you space - to think, to talk, to create.</p>

          <img src="/images/welcome-healthy-2.jpg" alt="A healthier home for your news, views and ideas" />
          </div>
        </div>


        <div className="section section-7">
          <div className="content tx2">

          <h2>Join Now</h2>

          <p>If you'd like to join our growing community of writers, bloggers and commentators, just click below and fill out an invite request.</p>

          <p className="center">
              <a className="button request-invite-bottom" href="/request-invite" onClick={this.requestInvite}>Request Invitation</a>
          </p>

          <img className="pitch-page-logo-symbol" src="/images/pitch-page-logo-symbol.svg" />

          <Logo height={52} baseColor="#ffffff" />

          <p className="center">
            <a className="link" target="_blank" href="mailto:info@tremr.com">info@tremr.com</a>
          </p>

          </div>
        </div>

    </div>;
  }
});
