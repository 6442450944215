var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SkippedListItem = require("./skippedlistitem");

// Tremr.Generic.List = CreateReactClass({
module.exports = CreateReactClass({
   mixins: [PureRenderMixin],

   propTypes: {
      isLoading: PropTypes.bool.isRequired,
      isInit: PropTypes.bool.isRequired,
      collection: PropTypes.object.isRequired,
      cardFactory: PropTypes.func.isRequired,
      loadedCallback: PropTypes.func,
      listStyle: PropTypes.string,
      cardoptions: PropTypes.object,
      identifier: PropTypes.string // allow identifier to be passed down to grid/list and then cards
   },

   getDefaultProps: function() {
      return {
         listStyle: 'list',
         cardoptions: {}
      }
   },

   // draw cards
   render: function() {
      // work out our top level class
      var classes = {
         "post-list": true,
         list: true,
         loading: this.props.isLoading,
         init: this.props.isInit
      };
      classes['list-style-'+this.props.listStyle] = true;
      classes = classNames(classes);

      if (!this.props.collection) {
         return <div className={classes}/>;
      } else {
         return (<div className={classes}>
            {
               _.map(this.props.collection.models, function(model, index) {

                   let options = _.extend(this.props.cardoptions, {
                      identifier: this.props.identifier + "." + index,
                      wallStyle: this.props.listStyle,
                      loadedCallback: this.props.loadedCallback
                  }) ;
                  let card = this.props.cardFactory(model, index, options);
                  if (card === null && _.isFunction(this.props.loadedCallback)) {
                      // we need to add a dummy component in order to call the callback
                      // only only on DidMount
                      card = <SkippedListItem loadedCallback={options.loadedCallback} key={this.props.identifier + ".skipped." + index} />;
                  }
                  return card;
               }.bind(this))
            }
         </div>);
      }
   }
});
