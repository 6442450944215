require("./scss/tageditor.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// edit a list of tags
// Tremr.Generic.TagEditor = CreateReactClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        initialTags: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired
    },

    getDefaultProps: function() {
        return {
            initialTags: []
        }
    },

    getInitialState: function() {
        return {
            tags: this.props.initialTags
        };
    },

    handleRemoveClick: function(event) {

        event.preventDefault();
        event.stopPropagation();

        var tag = event.currentTarget.getAttribute('data-tag');
        this.removeTag(tag);
    },

    removeTag: function(tag) {
        var tags = this.state.tags.slice(0);
        tags = _.reject(tags, function(t) {
            return (tag == t);
        })

        this.setState({
            tags: tags
        });

        this.props.onChange(tags);
    },

    focusNewTag: function(event) {

        event.preventDefault();
        event.stopPropagation();

        var node = ReactDOM.findDOMNode(this);
        var newTag = node.querySelector('.newTag');
        newTag.focus();
    },

    // whenever tag content changes and contains just a br - delete it
    handleTagChange: function(event) {

        var node = ReactDOM.findDOMNode(this.refs['newTag']).firstChild;

        if (node.innerHTML == '<br>') {
            node.innerHTML = '';
        }

    },

    // watch for enter or tab
    handleKeyDown: function(event) {

        // if there is no content then remove last tag
        if (event.key == 'Backspace') {

            if (this.state.tags.length > 0 &&
                this.refs['newTag'].state.value == '') {

                this.removeTag(_.last(this.state.tags));
            }

        } else if (event.keyCode == 188 ||
            event.key == 'Enter' ||
            event.key == 'Tab') {

            // if we are adding the tag cancel the event and do it

            event.preventDefault();
            event.stopPropagation();

            var tag = this.refs['newTag'].state.value;
            tag = tag.replace(/^#+/gi,'');
            tag = tag.trim();

            if (tag.length > 0) {

                this.refs['newTag'].setValue('');
                this.refs['newTag'].focus();

                var tags = this.state.tags.slice(0);
                tags.push(tag);
                tags = _.uniq(tags);
                this.setState({
                    tags: tags
                });

                this.props.onChange(tags);
            }
        }

    },

    focusNewTag: function(event) {

        event.preventDefault();
        event.stopPropagation();

        var node = ReactDOM.findDOMNode(this.refs['newTag']);
        node.focus();
    },

    // display
    render: function() {

        return (<div className="tag-editor" onClick={this.focusNewTag}>
            {_.map(this.state.tags, function(tag) {
                return <div className={'tag ' + tag} key={'tag-' + tag} onClick={this.handleRemoveClick} data-tag={tag}>
                    <div className="label">{tag}</div>
                    <div className="remove">
                        <Tremr.Generic.SvgIcon icon="remove" />
                    </div>
                </div>
            }.bind(this))}
            <Tremr.Editor.ContentEditable ref="newTag" classNames={{newTag: true}} initialValue="" maxLength={32} placeholder="Add hashtag…" onChange={this.handleTagChange} onKeyDown={this.handleKeyDown} />
        </div>);
    }
});
