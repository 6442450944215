var TremrTracking = require("../../utils/tracking");
var alertify = require("alertify");
var Promise = require("bluebird");
var SimpleCard = require("./simplecard");
var GridLayouts = require("../../stores/generic/gridlayouts");
var Footer = require("../../stores/layout/footer");
var PostListCardWrapper = require("../post/listcardwrapper");
var WallControls = require("../post/wallcontrols");
var PostContentCard = require("../post/contentcard");
var PostContent = require("../../models/PostContent");
var FeedCard = require("../feed/card");

// Tremr.PostStore = function () {
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  // list of post types
  this.postTypes = function () {
    return ["general", "news", "opinion", "analysis", "debate", "idea"];
  };

  // get the feed/id from the slug
  this.route = function (post) {
    if (post.get("channel") && post.get("channel").length > 0) {
      // channel stubs need to be routed differently
      return { target: "feed", feed: post.get("channel") };

      // } else if (post.get('draft') == '1' || post.get('draft_id')) {
      //
      // 	// drafts need to be routed differently
      //     return {target: 'post', feed: post.get('author_feed'), id: post.get('draft_id'), draft: true};
    } else if (post.get("slug")) {
      var parts = post.get("slug").split("!");
      return { target: "post", feed: parts[0], id: parts[1] };
    } else {
      // Tremr.Utils.errorMessage('No route for post', 'post had no slug', post.attributes);
      return { target: "post", feed: "unknown", id: "unknown" };
    }
  };

  this.getDoorPolicyDataPoints = function (post_id, channel_feed) {
    let points = [
      {
        name: "doorPolicy",
        key: "posts/" + post_id + "/door-policy",
        user: false,
      },
      {
        name: "reputation",
        key: "/community/reputation/users/{user}/rep_stars",
        user: true,
      },
    ];
    if (channel_feed) {
      points.push({
        name: "channelMembership",
        key: "channels/" + channel_feed + "/members",
        user: false,
      });
    }
    return points;
  };

  // total views of post
  this.views = function (post) {
    if (post.get("views")) {
      return post.get("views");
    } else {
      return 0;
    }
  };

  // from trending data - plus adjust for old trending data
  this.views24hrs = function (post) {
    var trending = post.get("trending");
    if (trending && trending["h24"]) {
      var lastUpdated = new Date(
        Date.UTC(
          trending["last"][0],
          trending["last"][1] - 1,
          trending["last"][2],
          trending["last"][3]
        )
      );
      lastUpdated =
        lastUpdated.getTime() / (1000 * 60) + lastUpdated.getTimezoneOffset();
      var lastHour = new Date();
      lastHour.setMinutes(0);
      lastHour.setSeconds(0);
      lastHour =
        lastHour.getTime() / (1000 * 60) + lastHour.getTimezoneOffset();
      var age = lastHour - lastUpdated;
      age = age / 60; // hours
      var h24 = trending["h24"];
      var averagePerHour = trending["h24"] / 24.0;

      // if trending is more than 1 hours old, adjust total down
      // by average per day
      if (age >= 24) {
        return 0;
      } else if (age > 1) {
        return Math.ceil(h24 - age * averagePerHour);
      } else {
        return h24;
      }
    } else {
      return 0;
    }
  };

  // count how many reacts of specified tags the post has
  this.reactSum = function (post, reactions) {
    let sum = 0;

    _.each(reactions, (reaction) => {
      sum += this.reactCount(post, reaction);
    });

    return sum;
  };

  // count how many reacts of specified tag the post has
  this.reactCount = function (post, react) {
    var reaction_counts = post.get("activity");

    if (reaction_counts && reaction_counts[react]) {
      return reaction_counts[react];
    } else {
      return 0;
    }
  };

  // count ratio of recommends to total views
  this.recommendRatio = function (post) {
    var recommends = this.reactCount(post, "recommend");
    var views = this.views(post);
    if (recommends > 0 && recommends > 0) {
      var percent = (recommends / views) * 100.0;
      return (Math.round(percent * 10) / 10).toFixed(1);
    } else {
      return 0;
    }
  };

  // count the words used in the content or content blocks
  (this.countWords = function (post) {
    if (post.get("content_blocks")) {
      var content = "";
      _.each(post.get("content_blocks"), function (block) {
        if (block.plainText) {
          content += " " + block.plainText.join("");
        }
        if (block.captionText) {
          content += " " + block.captionText.join("");
        }
        if (block.embed && block.embed.title) {
          content += " " + block.embed.title;
        }
        if (block.embed && block.embed.description) {
          content += " " + block.embed.description;
        }
      });

      if (post.get("embed") && post.get("embed").title) {
        content += " " + post.get("embed").title;
      }
      if (post.get("embed") && post.get("embed").description) {
        content += " " + post.get("embed").description;
      }
      if (post.get("image") && post.get("image").caption) {
        content += " " + post.get("image").caption;
      }

      return Tremr.Utils.countWords(content);
    } else if (post.get("content")) {
      var content = post.get("content");

      if (post.get("embed") && post.get("embed").title) {
        content += " " + post.get("embed").title;
      }
      if (post.get("embed") && post.get("embed").description) {
        content += " " + post.get("embed").description;
      }
      if (post.get("image") && post.get("image").caption) {
        content += " " + post.get("image").caption;
      }

      return Tremr.Utils.countWords(content);
    }

    return 0;
  }),
    // load posts from the server using a prebuilt backbone posts collection
    (this.loadPosts = function (page, posts, context, cache) {
      context.src = "post.posts";
      return Tremr.Utils.loadCollection(page, posts, context, cache);
    });

  // load contributors from the server using a prebuilt backbone posts collection
  this.loadContributors = function (page, data, context, cache) {
    context.src = "post.contributors";
    return Tremr.Utils.loadCollection(page, data, context, cache);
  };

  // load drafts from the server using a prebuilt backbone posts collection
  this.loadDrafts = function (page, drafts, context, cache) {
    if (page == 1) {
      context.src = "post.drafts";
      return Tremr.Utils.loadCollection(page, drafts, context, cache);
    } else {
      // always loaded in a single page
      return new Promise(function (resolve, reject) {
        resolve({
          collection: drafts,
          data: {
            length: 0,
          },
          page: page,
          options: {},
        });
      });
    }
  };

  // load a specific post by id from the server using a prebuilt backbone model
  this.loadPost = function (feed, id, post, cache, supressError) {
    return Tremr.Utils.loadModel(
      post,
      {
        id: feed + "!" + id,
      },
      cache,
      supressError
    );
  };

  // factory for getting the content for a state
  this.getPrimaryPostContent = function (context, cache) {
    var postkey = "post-" + context.feed + "-" + context.id;
    var post = (
      <Tremr.Post.Show
        key={postkey}
        ref="primaryComponent"
        cache={cache}
        datasource={this.loadPost}
        context={context}
      />
    );
    return post;
  };

  // trigger loading of activity for these posts
  this.requestActivityForPosts = function (data) {
    // if (data.length > 0) {
    // 	var ids = data.map(function(model) {
    // 		return model.id;
    // 	});
    // 	Tremr.dispatcher.message(this, "tremr:activity:forpost", {post_ids: ids});
    // }
  }.bind(this);

  // factory for footer content
  // this.getFooterContent = function(context) {
  //
  // 	var tabs = store.getTabs(context);
  // 	var defaultTab = store.getDefaultTab(context);
  //
  // 	var Wallref = defaultTab + "PostsWall";
  // 	var ref = "footer." + context.sort + "." + Wallref;
  //
  // 	return <Footer>
  // 		<Tremr.Post.AllPostsFooter key={ref} ref={ref} tabs={tabs} initialTab={defaultTab} wallIdentifier={Wallref}/>
  // 	</Footer>;
  // };

  // factory for getting the content for a state
  this.getPrimaryContent = function (context, cache) {
    var tabs = store.getTabs(context);
    var defaultTab = store.getDefaultTab(context);
    var datasources = store.getDataSources(context);

    //  factory functions in array for right-hand component for the wall
    // var rhoAboutUs = function(props) {
    //     return <Tremr.Generic.Contentcard rho={true} key="rho-about-us" ref="rho-about-us" startWidth={props.startWidth} startTop={props.startTop} cardHeightSet={props.cardHeightSet}>
    //         <h1>About Tremr</h1>
    //         <p>Some info Some info Some info Some info Some info Some info Some info Some info Some info Some info.</p>
    //         <p>Some info Some info Some info Some info Some info Some info Some info Some info Some info Some info.</p>
    //     </Tremr.Generic.Contentcard>};

    // var rightHandObjects = [
    //     rhoAboutUs
    // ];

    // var rightHandObjects = [];

    // key uses sort
    var key = defaultTab + "PostsWall";

    let controls = <WallControls wallStyleProperty="wallStylePosts" />;

    let scrollToPos = function () {
      window.scrollTo(0, context.initialScrollPos);
    };

    // call both callbacks
    var callback = function (data, wallStyle) {
      // _.defer(() => {
      // 	this.requestActivityForPosts(data);
      // });

      let fullContext = Tremr.context.getState();
      let isCached = fullContext.cache == true;
      if (isCached) {
        Tremr.context.add({ cache: false });
        // scroll to initial position
        if (context && context.initialScrollPos) {
          scrollToPos();
        }
      }
    }.bind(this);

    // when the sort changes update the URL
    var sortChanged = function (newSort) {
      var currentState = Tremr.context.getState();
      var sort = newSort.toLowerCase();
      if (sort == "top rated") {
        sort = "recommended";
      }
      var newPrimary = _.extend(currentState.primary, { sort: sort });
      Tremr.context.add(
        {
          primary: newPrimary,
        },
        true
      );

      var newUrl = Tremr.routes.urlFor({ primary: newPrimary });
      Tremr.routes.updateUrl(newUrl);
    };

    // return <Tremr.Generic.Wall key={key} ref="primaryComponent" identifier={key} sortChanged={sortChanged} rightHandObjects={rightHandObjects} loadedCallback={this.requestActivityForPosts} cardFactory={this.cardFactory} collectionFactory={this.postsFactory} cache={cache} datasources={datasources} defaultTab={defaultTab} tabs={tabs} context={context} />;
    return (
      <Tremr.Generic.Wall
        wallStyleProperty="wallStylePosts"
        controls={controls}
        identifier={key}
        key={key}
        ref="primaryComponent"
        sortChanged={sortChanged}
        loadedCallback={callback}
        cardFactory={this.cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the trending list
  this.getTrendingList = function (cache) {
    let tabs = ["Trending"];
    let defaultTab = "Trending";

    let context = {
      target: "all",
      sort: "trending",
      scope: "all",
    };
    let datasources = this.getDataSources(context);

    let key = "trendingPostsWall" + context.sort + "." + context.scope;

    let cardFactory = function (model, index, props) {
      var key = "simplecard-" + index + "-" + model.get("id");
      return <SimpleCard key={key} post={model} />;
    };

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="trendingList"
        identifier={key}
        cardFactory={cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the recommended list
  this.getRecommendedList = function (cache) {
    let tabs = ["Top Rated"];
    let defaultTab = "Top Rated";

    let context = {
      target: "all",
      sort: "recommended",
      scope: "all",
    };
    let datasources = this.getDataSources(context);

    let key = "recommendedPostsWall" + context.sort + "." + context.scope;

    let cardFactory = function (model, index, props) {
      var key = "simplecard-" + index + "-" + model.get("id");
      return <SimpleCard key={key} post={model} />;
    };

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="recommendedList"
        identifier={key}
        cardFactory={cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  this.postsFactory = function () {
    return new Tremr.Models.Posts();
  };

  this.cardFactory = function (model, index, options) {
    if (model.get("clientretry") || model.get("uievent")) {
      return null;
    }

    let identifier = "";
    if (options && options.identifier) {
      identifier = options.identifier;
    }
    let key = "card-" + index + "-" + model.get("id");

    let autoexpand = false;
    if (options && (options.autoexpand || options.sort == "Chronological")) {
      autoexpand = true;
    }
    let showBreadcrumbs = true;
    if (options && options.showBreadcrumbs == false) {
      showBreadcrumbs = false;
    }
    let showResponses = true;
    if (options && options.showResponses == false) {
      showResponses = false;
    }
    let onExpandListCard = false;
    if (options && options.onExpandListCard) {
      onExpandListCard = options.onExpandListCard;
    }

    if (options.wallStyle == "content") {
      return (
        <PostContentCard
          showBreadcrumbs={showBreadcrumbs}
          showResponses={showResponses}
          autoexpand={autoexpand}
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else if (options.wallStyle == "list") {
      return (
        <PostListCardWrapper
          onExpandListCard={onExpandListCard}
          showBreadcrumbs={showBreadcrumbs}
          showResponses={showResponses}
          autoexpand={autoexpand}
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else {
      return (
        <Tremr.Post.Card
          showBreadcrumbs={showBreadcrumbs}
          showResponses={showResponses}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
          cardHeightSet={options.cardHeightSet}
        />
      );
    }
  };

  // factory for getting the content for responses
  this.getResponsesWall = function (
    target,
    feed,
    id,
    cache,
    loadedCallback,
    onSortChanged,
    forceStyle,
    identifier,
    wallStyleProperty,
    subtab,
    cardOptions
  ) {
    if (cardOptions == undefined) {
      cardOptions = {};
    }

    if (target != "direct responses") {
      target = "responses";
    }

    if (wallStyleProperty === undefined) {
      wallStyleProperty = "";
    }

    var context = {
      target: target,
      slug: feed + "!" + id,
      scope: "all",
    };
    var tabs = store.getTabs(context);
    // var defaultTab = store.getDefaultTab(context);
    var defaultTab = "Chronological";
    if (subtab) {
      defaultTab = subtab;
    }

    // depding on subtab override the target
    var datasources = store.getResponseDataSources(context);
    // var key = identifier + ".responses." + target.replace(' ', '-') + "." + context.slug + "." + context.scope;
    var key =
      "responses." +
      target.replace(" ", "-") +
      "." +
      context.slug +
      "." +
      context.scope;

    // call both callbacks
    var callback = function (data, wallStyle) {
      // this.requestActivityForPosts(data);
      if (loadedCallback && _.isFunction(loadedCallback)) {
        loadedCallback(data);
      }

      let fullContext = Tremr.context.getState();
      let isCached = fullContext.cache == true;

      // _.defer(() => {
      // 	if (wallStyle == 'content' && !isCached) {
      // 		this.requestContentForPosts(data);
      // 	}
      // });
    }.bind(this);

    let cardFactory = function (model, index, options) {
      // remove the immediate parent and earlier from the ancestors
      let ancestors = model.get("ancestors");
      if (!model.get("immediate-parent-checked")) {
        if (ancestors && ancestors.length > 0) {
          let ancestorsCopy = ancestors.slice();
          ancestors = ancestors.reverse();
          let parent = false;
          ancestors = _.reject(ancestors, function (ancestor) {
            if (parent || ancestor["slug"] == feed + "!" + id) {
              parent = true;
              return true;
            }
            return false;
          });
          ancestors = ancestors.reverse();
          model.set("ancestors", ancestors);
          model.set("ancestors-with-immediate-parent-left", ancestorsCopy);
          model.set("immediate-parent-checked", true); // ensure we only run once per model !
        }
      }

      let identifier = "";
      options = _.extend(cardOptions, options);
      if (options && options.identifier) {
        identifier = options.identifier;
      }
      let key = "card-" + index + "-" + model.get("id");

      let autoexpand = false;
      if (options && (options.autoexpand || options.sort == "Chronological")) {
        autoexpand = true;
      }
      let showBreadcrumbs = true;
      if (options && options.showBreadcrumbs == false) {
        showBreadcrumbs = false;
      }
      let showResponses = true;
      if (options && options.showResponses == false) {
        showResponses = false;
      }

      if (options.wallStyle == "content") {
        return (
          <PostContentCard
            showBreadcrumbs={showBreadcrumbs}
            showResponses={showResponses}
            autoexpand={autoexpand}
            loadedCallback={options.loadedCallback}
            ref={key}
            key={key}
            post={model}
            identifier={identifier}
          />
        );
      } else if (options.wallStyle == "list") {
        return (
          <PostListCardWrapper
            showBreadcrumbs={showBreadcrumbs}
            showResponses={showResponses}
            autoexpand={autoexpand}
            loadedCallback={options.loadedCallback}
            ref={key}
            key={key}
            post={model}
            identifier={identifier}
          />
        );
      } else {
        return (
          <Tremr.Post.Card
            showBreadcrumbs={showBreadcrumbs}
            showResponses={showResponses}
            ref={key}
            key={key}
            post={model}
            identifier={identifier}
            cardHeightSet={options.cardHeightSet}
          />
        );
      }
    };

    let setForceStyle = "grid";
    if (forceStyle !== undefined) {
      setForceStyle = forceStyle;
    }

    return (
      <Tremr.Generic.Wall
        wallStyleProperty={wallStyleProperty}
        forceGridStyle={setForceStyle}
        key={key}
        ref="responsesComponent"
        identifier={key}
        showFilter={true}
        sortChanged={onSortChanged}
        loadedCallback={callback}
        cardFactory={cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the content for contributors
  this.getContributorsWall = function (
    feed,
    id,
    cache,
    loadedCallback,
    onSortChanged,
    identifier,
    subtab
  ) {
    var context = {
      target: "contributors",
      slug: feed + "!" + id,
    };
    var tabs = store.getTabs(context);
    var defaultTab = store.getDefaultTab(context);
    if (subtab) {
      defaultTab = subtab;
    }

    var datasources = store.getDataSources(context);
    // var key = identifier + ".responses." + target.replace(' ', '-') + "." + context.slug + "." + context.scope;
    var key = "responses.contributors." + context.slug;

    // call both callbacks
    var callback = function (data, wallStyle) {
      Tremr.stores.feedStore.requestFollowingForEntity(data);

      if (loadedCallback && _.isFunction(loadedCallback)) {
        loadedCallback(data);
      }

      let fullContext = Tremr.context.getState();
      let isCached = fullContext.cache == true;

      // _.defer(() => {
      // 	if (wallStyle == 'content' && !isCached) {
      // 		this.requestContentForPosts(data);
      // 	}
      // });
    }.bind(this);

    let cardFactory = function (model, index, options) {
      if (
        model.get("clientretry") ||
        model.get("uievent") ||
        model.get("slug") == feed + "!" + id
      ) {
        return null;
      }
      var key = "feedcard-" + index + "-" + model.get("id");
      return (
        <FeedCard
          key={key}
          feed={model}
          loadedCallback={options.loadedCallback}
        />
      );
    };

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="responsesComponent"
        identifier={key}
        showFilter={false}
        sortChanged={onSortChanged}
        loadedCallback={callback}
        cardFactory={cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the content for related
  this.getRelatedWall = function (
    feed,
    id,
    cache,
    loadedCallback,
    onSortChanged
  ) {
    let context = {
      target: "related",
      slug: feed + "!" + id,
      scope: "all",
    };
    var tabs = store.getTabs(context);
    var defaultTab = store.getDefaultTab(context);
    var datasources = store.getDataSources(context);
    var key = "related." + context.slug + "." + context.scope;

    // call both callbacks
    var callback = function (data) {
      // this.requestActivityForPosts(data);
      loadedCallback(data);
    }.bind(this);

    return (
      <Tremr.Generic.Wall
        key={key}
        ref="responsesComponent"
        identifier={key}
        showFilter={true}
        sortChanged={onSortChanged}
        loadedCallback={callback}
        cardFactory={this.cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the content for related
  this.getRelatedList = function (feed, post_id, cache, loadedCallback) {
    let context = {
      target: "related",
      slug: feed + "!" + post_id,
      scope: "all",
    };
    let tabs = ["Relevance"];
    let defaultTab = "Relevance";
    var datasources = store.getDataSources(context);
    var key = "related." + context.slug + "." + context.scope;

    let cardFactory = function (model, index, options) {
      if (
        model.get("clientretry") ||
        model.get("uievent") ||
        model.get("slug") == feed + "!" + post_id
      ) {
        return null;
      }
      var key = "simplecard-" + index + "-" + model.get("id");
      return (
        <SimpleCard
          imageHeight={50}
          imageWidth={50}
          key={key}
          post={model}
          loadedCallback={options.loadedCallback}
        />
      );
    };

    return (
      <Tremr.Generic.Wall
        loadedCallback={loadedCallback}
        forceGridStyle="list"
        key={key}
        ref="relatedComponent"
        identifier={key}
        showFilter={false}
        cardFactory={cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the content for ancestors
  // this.getAncestorsWall = function(feed, id, includeSource, cache, loadedCallback) {
  // 	var context = {
  // 		target: 'ancestors',
  // 		slug: feed + '!' + id,
  // 		scope: 'all'
  // 	};
  //     if (includeSource) {
  //         context.target = 'ancestors-parent';
  //     }
  //     if (loadedCallback ===  undefined) {
  //         loadedCallback = _.noop;
  //     }
  // 	var tabs = ['Latest'];
  // 	var defaultTab = 'Latest';
  // 	var datasources = store.getDataSources(context);
  // 	var key = "ancestors." + context.slug + "." + context.scope;
  //
  // 	// call both callbacks
  // 	var callback = function(data) {
  // 		this.requestActivityForPosts(data);
  //         loadedCallback(data);
  // 	}.bind(this);
  //
  // 	return <Tremr.Generic.Wall forceGridStyle="list" key={key} ref="ancestorsComponent" identifier={key} showFilter={false} loadedCallback={callback} cardFactory={this.cardFactory} collectionFactory={this.postsFactory} cache={cache} datasources={datasources} defaultTab={defaultTab} tabs={tabs} context={context}/>;
  // };

  // count related for a post
  this.countRelated = function (feed, id) {
    var context = {
      target: "related",
      slug: feed + "!" + id,
      scope: "count",
      sort: "relevance",
    };

    return this.loadPosts(1, new Tremr.Models.Posts(), context);
  };

  // get named tabs that we want to show for specific context
  this.getTabs = function (context) {
    if (context.target == "responses" || context.target == "direct responses") {
      return ["Chronological", "Latest", "Top Rated"];
    } else if (context.target == "contributors") {
      // tabs.push('Relevance');
      return ["Latest", "A-Z"];
    } else if (context.target == "related") {
      return ["Latest", "Relevance", "Top Rated"];
    } else {
      return ["Latest", "Relevance", "Top Rated", "Trending"];
    }
  };

  // get default tab for specific context
  this.getDefaultTab = function (context) {
    if (context.target == "contributors") {
      return "Latest";
    }
    if (context.target == "related") {
      return "Relevance";
    }
    if (context.target == "all" && context.sort == "trending") {
      return "Trending";
    }
    if (context.target == "all" && context.sort == "recommended") {
      return "Top Rated";
    }
    if (context.target == "direct responses") {
      return "Chronological";
    } else {
      return "Latest";
    }
  };

  // get named data source function for a specific context
  (this.getDataSources = function (context) {
    var self = this;

    if (
      context.target &&
      (context.target == "contributors" ||
        context.target == "all" ||
        context.target == "featured" ||
        context.target == "responses" ||
        context.target == "direct responses" ||
        context.target == "related" ||
        context.target == "ancestors" ||
        context.target == "ancestors-parent")
    ) {
      var sources = [];

      // do in a function and use dynamically in datasources
      var getDataForContext = function (c) {
        var data = {
          target: c.target,
        };
        if (c.scope) {
          data.scope = c.scope;
        }
        if (c.slug) {
          data.slug = c.slug;
        }
        return data;
      };

      sources["Trending"] = function (page, posts, cntxt, cache) {
        return self.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "trending",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["Top Rated"] = function (page, posts, cntxt, cache) {
        return self.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "recommended",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["Latest"] = function (page, posts, cntxt, cache) {
        return self.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      if (
        context.target == "responses" ||
        context.target == "direct responses"
      ) {
        sources["Chronological"] = function (page, posts, cntxt, cache) {
          return self.loadPosts(
            page,
            posts,
            _.extend(
              {
                sort: "chronological",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };
      }

      if (context.target == "related") {
        sources["Relevance"] = function (page, posts, cntxt, cache) {
          return self.loadPosts(
            page,
            posts,
            _.extend(
              {
                sort: "relevance",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };
      }

      if (context.target == "contributors") {
        sources["A-Z"] = function (page, data, cntxt, cache) {
          return self.loadContributors(
            page,
            data,
            _.extend(
              {
                sort: "alphabetical",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };
      }

      return sources;
    } else {
      console.log("DataSource not implemented for:");
      console.dir(context);
      return [];
    }
  }),
    // get named data source function for a specific context but overriding
    // the target depending on the tab
    (this.getResponseDataSources = function (context) {
      var self = this;

      if (
        context.target == "responses" ||
        context.target == "direct responses"
      ) {
        var sources = [];

        // do in a function and use dynamically in datasources
        var getDataForContext = function (c) {
          var data = {}; // set direct/indirect responses based on tab below
          if (c.scope) {
            data.scope = c.scope;
          }
          if (c.slug) {
            data.slug = c.slug;
          }
          return data;
        };

        sources["Trending"] = function (page, posts, cntxt, cache) {
          return self.loadPosts(
            page,
            posts,
            _.extend(
              {
                sort: "trending",
                target: "responses",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };
        sources["Top Rated"] = function (page, posts, cntxt, cache) {
          return self.loadPosts(
            page,
            posts,
            _.extend(
              {
                sort: "recommended",
                target: "responses",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };
        sources["Latest"] = function (page, posts, cntxt, cache) {
          return self.loadPosts(
            page,
            posts,
            _.extend(
              {
                sort: "latest",
                target: "responses",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };

        sources["Chronological"] = function (page, posts, cntxt, cache) {
          return self.loadPosts(
            page,
            posts,
            _.extend(
              {
                sort: "chronological",
                target: "direct responses",
              },
              getDataForContext(cntxt)
            ),
            cache
          );
        };

        return sources;
      } else {
        console.log("DataSource not implemented for:");
        console.dir(context);
        return [];
      }
    }),
    // listen to requests to load content for a post or array of posts
    store.listenTo(
      Tremr.dispatcher,
      "tremr:content:forpost",
      function (post_ids) {
        if (!_.isArray(post_ids)) {
          post_ids = [post_ids];
        }

        this.loadContentForPosts(post_ids);
      },
      store
    );

  (this.loadContentForPosts = function (post_ids) {
    // bit of a hack but look directly at state to see if we are caching
    // i.e. user is going back/forward and needs auto expansion of cards
    // ALWAYS CACHE
    // let fullContext = Tremr.context.getState();
    // let isCached = (fullContext.cache == true);
    let isCached = true;

    if (!_.isArray(post_ids)) {
      post_ids = [post_ids];
    }

    // send array of post ids
    let context = {
      src: "post.content", // must add unique code for this call
      post_ids: post_ids,
    };

    let collection = new PostContent();

    // load from server into our collection
    Tremr.Utils.loadCollection(1, collection, context, isCached, "post").then(
      function (params) {
        var collection = params.collection;
        var data = params.data;
        var page = params.page;
        var options = params.options;

        // iterate the ids and fire messages to show the state of each one
        _.each(post_ids, function (post_id) {
          if (post_id) {
            var element = collection.get(post_id);
            if (element) {
              Tremr.dispatcher.message(
                this,
                "tremr:content:post:" + post_id,
                element
              );
            }
          }
        });
      }.bind(this)
    );
  }),
    // listen to delete post
    store.listenTo(Tremr.dispatcher, "tremr:post:delete", function (post) {
      // ask for confirmation then send to server
      // confirm dialog
      alertify.confirm("Delete Post?", function (e) {
        if (e) {
          post.set("id", post.get("slug"));
          var delete_result = post.destroy({
            data: "draft_id=false",
            processData: true,
            success: function () {
              // notify that it worked - assume success
              Tremr.dispatcher.message(
                this,
                "tremr:post:delete:success",
                post.get("slug")
              );
            },
          });
        } else {
          Tremr.dispatcher.message(this, "tremr:post:delete:cancel");
        }
      });
    }),
    // listen to delete draft
    store.listenTo(Tremr.dispatcher, "tremr:draft:delete", function (data) {
      // ask for confirmation then send to server
      // confirm dialog
      alertify.confirm(
        "Delete Draft?",
        function (e) {
          if (e) {
            var draft = new Tremr.Models.Post({
              id: data.get("draft_id"),
              draft_id: data.get("draft_id"),
            });
            var delete_result = draft.destroy({
              data: "draft_id=" + data.get("draft_id"),
              processData: true,
              success: function () {
                // update count on user
                var user = Tremr.stores.userStore.getUser();
                if (user) {
                  Tremr.dispatcher.message(this, "tremr:draft:refresh", {
                    adjust: -1,
                  });
                }

                let draft_id = data.draft_id || data.get("draft_id");

                // notify that it worked - assume success
                console.log("tremr:draft:delete:success");
                console.dir(data);
                Tremr.dispatcher.message(
                  this,
                  "tremr:draft:delete:success",
                  draft_id
                );
              }.bind(this),
            });
          } else {
            Tremr.dispatcher.message(this, "tremr:post:delete:cancel");
          }
        }.bind(this)
      );
    }),
    // listen to save post
    store.listenTo(Tremr.dispatcher, "tremr:post:save", function (data) {
      var postModel = data;
      var callback = false;

      if (data && data.post) {
        postModel = data.post;
      }
      if (data && data.callback) {
        callback = data.callback;
      }

      // we only allow this if the user is signed-in, so
      // raise a signed-in only event and allow user store to
      // control it.
      var user = Tremr.stores.userStore.getUser();
      if (!user) {
        Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
          event: "tremr:post:save",
          data: data,
        });
        return;
      }

      // do the save
      var wasDraft = postModel.get("draft_id");
      var isEdit = postModel.get("update") == "1";
      var save_result = postModel.save(
        {},
        {
          success: function (m, response, options) {
            // fire an event (so the editor can update it's draft)
            Tremr.dispatcher.message(this, "tremr:post:save:success", m);

            // track
            if (!wasDraft && !isEdit) {
              TremrTracking.posted(user, m);
            }

            // if this is a draft, stay on the editor - otherwise
            // navigate to the post itse,f
            if (m.get("draft") == "1") {
              if (!wasDraft) {
                Tremr.dispatcher.message(this, "tremr:draft:refresh", {
                  adjust: +1,
                });
              }
            } else {
              if (wasDraft) {
                Tremr.dispatcher.message(this, "tremr:draft:refresh", {
                  adjust: -1,
                });
              }

              if (m.get("parent_id")) {
                Tremr.dispatcher.message(this, "tremr:post:response", m);
              }
            }

            if (callback) {
              callback(m);
            }
          },
          error: function (model, xhr, options) {
            // fire an event (so the editor can update)
            Tremr.dispatcher.message(this, "tremr:post:save:failed", xhr);

            if (xhr.status == 409) {
              alertify.error("Please provide a unique title");
            } else {
              if (data.type != "autosave") {
                alertify.error("Error saving " + data.type);

                try {
                  var responseJson = JSON.parse(xhr.responseText);
                  if (responseJson.errors !== undefined) {
                    console.log("Error saving: " + responseJson.errors);
                  } else if (responseJson.error !== undefined) {
                    console.log("Error saving: " + responseJson.error);
                  }
                } catch (e) {
                  if (TrackJS && TrackJS.console) {
                    TrackJS.console.error(
                      "EDITOR SAVE ERROR 9901: " + xhr.status
                    );
                  }
                }
              }
            }
          },
        }
      );
    });

  return store;
};
