require("../../scss/vars.scss");
require("../../scss/colors.scss");
require("../../scss/typography.scss");
var GDPR = require("../stores/layout/gdpr");
var Newsletter = require("../stores/layout/newsletter");

// Any text, components and colors can be defined here and switched in the webpack build
module.exports = {
  Name: "tremr",
  Title: "Tremr",
  ShortTitle: "Tremr",
  Description: "a healthier habitat for your news, views and ideas.",
  Logo: require("../stores/generic/logo"),
  OneAllCss: "/css/oneall.css",
  WelcomeTitlebar: require("../stores/layout/welcometitlebar"),
  Pitchpage: require("../stores/layout/pitchpage"),
  RequestInviteThankyou: require("../stores/layout/requestinvitethankyou"),
  SupportEmail: "support@tremr.com",
  HomepageFeed: "best-of-tremr",
  HomepageFeedTitle: "Best of Tremr",
  SystemFeed: "tremr",
  PersonalFeedTitle: "My Tremr",
  PrivacyPolicyLink:
    "https://support.tremr.com/article/16-tremr-privacy-policy",
  TermsLink:
    "https://support.tremr.com/article/15-tremr-terms-of-service#contactModal",
  ContactLink: "https://support.tremr.com/contact",
  HelpLink: "https://support.tremr.com",
  DoorPolicyHelpLink: "https://support.tremr.com",
  CookiesLink: "https://support.tremr.com/article/16-tremr-privacy-policy",
  AboutLink: "https://www.tremr.com/tremr/this-is-tremr",
  AboutPostSlug: "this-is-tremr",
  RenderSocialSignin: true,
  RenderSocialLink: true,
  RequestInvite: {
    Component: require("../stores/layout/requestinvite"),
    Title: "Request an Invitation to Tremr",
    Paragraph:
      "Tell us about yourself. Only the name, email and bio fields are required but the more you can tell us the better.",
  },
  AcceptInvite: {
    Create: {
      Title: "Welcome to Tremr!",
    },
  },
  RecommendedWriter: {
    Tag: "recommended writer",
    Description: "Recommended Writer",
  },
  Alerts: [
    // <Newsletter key="newsletter" />,
    <GDPR key="gdpr" />,
  ],
  RequestInviteTacking: function (doc) {
    var script = document.createElement("script");
    script.async = true;
    script.src = "https://risk.clearbit.com/v1/risk.js";
    var parent = document.getElementsByTagName("script")[0];
    parent.parentNode.insertBefore(script, parent);
  },
  SignupTacking: function (doc) {
    var script = document.createElement("script");
    script.async = true;
    script.src = "https://risk.clearbit.com/v1/risk.js";
    var parent = document.getElementsByTagName("script")[0];
    parent.parentNode.insertBefore(script, parent);
  },
};
