require("./scss/upvotebutton.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var Upvote = require('./icons/upvote.jsx');
var Downvote = require('./icons/downvote.jsx');
var UserAwareMixin = require('../../mixins/userawaremixin');
var TremrTracking = require('../../utils/tracking');

module.exports = CreateReactClass({

    mixins: [UserAwareMixin],

    propTypes: {
        animated: PropTypes.bool
    },

    getDefaultProps: function() {
        return {
            animated: true
        };
    },

    getEncodedTag: function() {
        return 'recommend';
    },

    // show the level in a transient element
    flagUpdate: function(level, previousLevel) {

        if (this.props.animated) {
            // if (level != 0) {
                let node = ReactDOM.findDOMNode(this);

                // remove any existing flags
                let flags = node.getElementsByClassName('flag');
                for(let i = 0;i < flags.length; i++) {
                        flags[i].remove();
                }

                // add a new element
                let flag = node.ownerDocument.createElement('div');
                console.log("level: "+level+", prev level: "+previousLevel);
                let levelText = level.toString();
                let levelClass = level.toString();
                let svg = '';
                if (level > 0 || (level == 0 && previousLevel > 0) ) {
                  levelText = '+'+level.toString();
                  svg = '<svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">';
                  svg = svg + '<rect class="level level3" x="0.5" y="0.5" width="21" height="3" rx="1.5" />';
                  svg = svg + '<rect class="level level2" x="0.5" y="6.5" width="21" height="3" rx="1.5" />';
                  svg = svg + '<rect class="level level1" x="0.5" y="12.5" width="21" height="3" rx="1.5" /></svg>';
                } else {
                  if (level == 0) {
                    levelClass = '-0';
                  }
                  svg = '<svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">';
                  svg = svg + '<rect class="level level-1" x="0.5" y="0.5" width="21" height="3" rx="1.5" />';
                  svg = svg + '<rect class="level level-2" x="0.5" y="6.5" width="21" height="3" rx="1.5" />';
                  svg = svg + '<rect class="level level-3" x="0.5" y="12.5" width="21" height="3" rx="1.5" /></svg>';
                }
                let html = "<div class=\"text\">"+levelText+"</div>";
                html = html + svg;
                flag.innerHTML = html;
                flag.classList.add("flag");
                console.log("class="+levelClass);
                flag.classList.add("level"+levelClass);
                node.appendChild(flag);
            // } // if (level != 0) {
        }
    },

    updateLevel: function(newLevel, previousLevel) {

        this.props.liveDataPoints[this.getEncodedTag()+'-set'].fbRef.set(newLevel, function(error) {
            if (error) {
                console.log("Data could not be saved:");
                console.dir(error);
            } else {
                TremrTracking.react(this.state.user, this.props.post_id, 'recommend', newLevel);
            }
        }.bind(this));

        // update this component
        this.flagUpdate(newLevel, previousLevel);

        // callback (so we can show reactions elsewhere)
        if (this.props.onChange) {
            this.props.onChange(newLevel);
        }
    },

    handleUpClick: function(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation()
        }

        if (this.state.user) {

            let level = this.props.liveDataPoints[this.getEncodedTag()+'-set'].data;
            let previousLevel = level;
            if (!level) {
                level = 0;
            }
            if (level < 0) {
                level = 1;

            } else if (level > 2) {
                level = 0;
            } else {
                level = level + 1;
            }

            this.updateLevel(level, previousLevel);
        } else {
            Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
                callback: this.handleUpClick,
                data: event
            });
            return;
        }
    },

    handleDownClick: function(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation()
        }

        if (this.state.user) {

            let level = this.props.liveDataPoints[this.getEncodedTag()+'-set'].data;
            let previousLevel = level;
            if (!level) {
                level = 0;
            }
            if (level > 0) {
                level = -1;
            } else if (level < -2) {
                level = 0;
            } else {
                level = level - 1;
            }

            this.updateLevel(level, previousLevel);
        } else {
            Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
                callback: this.handleDownClick,
                data: event
            });
            return;
        }
    },

    render: function() {

        let level = this.props.liveDataPoints[this.getEncodedTag()+'-set'].data;

        // see if we have loaded the image or not
        let classes = {
            'upvote-button': true,
            'set': level && level != 0,
        };
        classes['level-'+level] = true;
        classes = classNames(classes);

        return (<div className={classes}>
            <Upvote level={level} onClick={this.handleUpClick} />
            <Downvote level={level} onClick={this.handleDownClick} />
        </div>);
    }
});
