require("./scss/notification_settings.scss");

var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var alertify = require("alertify");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");

// users notifications settings
// Tremr.User.NotificationSettings = CreateReactClass({
module.exports = CreateReactClass({
  // mixins: [PureRenderMixin],

  promises: [], // promise references so we can cancel on unmount

  propTypes: {
    user: PropTypes.object.isRequired,
  },

  getInitialState: function () {
    return {
      model: false,
      isLoading: true,
      followers: false,
    };
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });
  },

  // load the data
  componentDidMount: function () {
    if (this.props.user) {
      var promise = Tremr.stores.userStore.requestNotificationSettings();
      this.promises.push(promise);
      promise.then(
        function (model) {
          this.setState({
            isLoading: false,
            model: model,
          });
        }.bind(this),
        function () {
          this.setState({
            isLoading: false,
          });
        }.bind(this)
      );
    }
  },

  save: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    var data = this.state.model.attributes;

    var promise = Tremr.stores.userStore.saveNotificationSetting(data);
    this.promises.push(promise);
    promise.then(
      function (model) {
        alertify.success("Notification settings saved.");

        _.each(_.pairs(model.attributes), function (attribute) {
          if (attribute[0].startsWith("following-")) {
            var entity = attribute[0].split("-");
            if (entity.length > 2) {
              Tremr.dispatcher.message(
                this,
                "tremr:notifications:" + entity[1] + ":" + entity[2],
                attribute[1]
              );
            }
          }
        });

        Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
      }.bind(this),
      function () {
        this.setState({
          isLoading: false,
        });
      }.bind(this)
    );
  },

  toggleOption: function (event) {
    event.preventDefault();
    event.stopPropagation();

    var element = event.currentTarget;
    var option = element.getAttribute("data-option");
    var value = element.getAttribute("data-value");

    // update that value in state
    var notifications = this.state.model;
    if (!notifications.get(option)) {
      notifications.set(option, value);
    }

    if (notifications.get(option) == "yes") {
      notifications.set(option, "no");
    } else {
      notifications.set(option, "yes");
    }

    this.setState({
      model: notifications,
    });
  },

  // when follow list has loaded a page - try and load the next page unless nothing returned
  followingLoadedCallback: function (data) {
    if (data.length > 0 && this.refs["notificationSettingsFollowingWall"]) {
      this.refs["notificationSettingsFollowingWall"].loadMore();
    }
  },

  // factory for creating the follow list
  getFollowingWall: function () {
    var notifications = this.state.model;

    // require notifications have loaded before we load the followers wall
    if (!notifications) {
      return null;
    }

    var tabs = ["alphabetical"];
    var defaultTab = "alphabetical";

    var collFactory = function () {
      return new Tremr.Models.Following();
    };

    var cardFactory = function (model, index, props) {
      var avatar_image = null;
      var name = null;

      if (model.get("type") == "tag") {
        var hex = TremrUtils.stringToColor(model.get("tag"));
        var style = {};
        style["backgroundColor"] = hex;
        // avatar_image = <Tremr.Generic.SvgIcon icon="tag" />;
        avatar_image = (
          <div className="tag-avatar" style={style}>
            {model.get("tag").substring(0, 2)}
          </div>
        );

        name = Tremr.Utils.truncate(model.get("tag"), 42);
      } else {
        if (model.get("author_avatar_image")) {
          avatar_image = (
            <Tremr.Generic.Image
              image={model.get("author_avatar_image")}
              height={30}
              width={30}
            />
          );
        }
        name = Tremr.Utils.truncate(model.get("author_name"), 42);
      }

      var id = model.get("id") || model.get("_id");
      var key = "card-" + index + "-" + id;
      var option = false;
      if (model.get("type") == "tag") {
        option = "following-tag-" + model.get("tag");
      } else {
        option = "following-feed-" + model.get("name");
      }

      // check if this is a user or a tag
      return (
        <div
          key={key}
          className="notification-option line"
          onClick={this.toggleOption}
          data-option={option}
          data-value={
            notifications.get(option) ? notifications.get(option) : "yes"
          }
        >
          {avatar_image}
          <span>{name}</span>
          <Tremr.Generic.SvgIcon
            icon="ticked"
            classes={
              notifications.get(option) == "no" ? "icon tick hide" : "icon tick"
            }
          />
          <Tremr.Generic.SvgIcon
            icon="unticked"
            classes={
              notifications.get(option) != "no" ? "icon tick hide" : "icon tick"
            }
          />
        </div>
      );
    }.bind(this);

    var context = {
      target: "feed",
      feed: this.props.user.get("feedname"),
      scope: "following",
    };
    var datasources = Tremr.stores.feedStore.getDataSources(context);

    var key =
      "followingNotificationSettings." + context.feed + "." + context.scope;

    // remove based on tag/name
    var customDelete = function (id, collection) {
      var coll = collection.reject(function (item) {
        if (item.get("tag") && item.get("tag") == id) {
          return true;
        } else if (item.get("name") && item.get("name") == id) {
          return true;
        }
        return false;
      });
      return new Tremr.Models.Following(coll);
    };

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="notificationSettingsFollowingWall"
        customDelete={customDelete}
        identifier={key}
        cardFactory={cardFactory}
        collectionFactory={collFactory}
        cache={false}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
        loadedCallback={this.followingLoadedCallback}
        loadedCallbackWait={false}
      />
    );
  },

  render: function () {
    var classes = {
      "user-notification-settings": true,
      sidebar: true,
      loading: this.state.isLoading,
    };
    classes = classNames(classes);

    if (this.state.isLoading) {
      return <div className={classes}></div>;
    }

    var buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading,
    });

    var buttonLabel = "Done";
    if (this.state.isLoading) {
      buttonLabel = "Saving";
    }

    var notifications = this.state.model;

    var createOption = function (option, icon, text) {
      if (!this.state.model) {
        return null;
      }
      return (
        <div
          className="notification-option line"
          onClick={this.toggleOption}
          data-option={option}
          data-value={
            notifications.get(option) ? notifications.get(option) : "yes"
          }
        >
          <span>{text}</span>
          <Tremr.Generic.SvgIcon
            icon="ticked"
            classes={
              notifications.get(option) == "no" ? "icon tick hide" : "icon tick"
            }
          />
          <Tremr.Generic.SvgIcon
            icon="unticked"
            classes={
              notifications.get(option) != "no" ? "icon tick hide" : "icon tick"
            }
          />
        </div>
      );
    }.bind(this);

    // {createOption('reactions', 'quote', 'Reactions')}
    // {createOption('any-emails', 'email', 'Any Tremr Emails')}
    // {createOption('channel-invite', 'feed', 'Invitations to contribute to a Channel')}

    var followingWall = this.getFollowingWall();

    return (
      <div className={classes}>
        <div className="user-notification-settings-toolbar toolbar">
          <div className="row">
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
            <a className="button save" onClick={this.save}>
              {buttonLabel}
            </a>
          </div>
        </div>
        <div
          ref="scrollContainer"
          className="controls"
          onClick={this.props.onClose}
        >
          <h2>Email Notification Settings</h2>
          <p className="line">Email me when I get these notifications:</p>

          {createOption("weekly-summary", "calendar", "Weekly Summary")}
          {createOption("direct-responses", "respond", "Direct Responses")}
          {createOption("all-responses", "respond", "All Responses")}
          {createOption("recommends", "recommend", "Recommends")}
          {createOption("reposts", "repost", "Reposts")}
          {createOption(
            "staff-pick",
            Tremr.Theme.HomepageFeed,
            "Reposted by " + Tremr.Theme.HomepageFeedTitle
          )}
          {createOption("follows", "follow", "New Followers")}
          {createOption("new-post", "feed", "New Posts from my Following List")}
          {createOption("endorsements", "endorsement", "New Endorsements")}
          {createOption(
            "reputation-and-badges",
            "reputation-and-badges",
            "Reputation and Badges"
          )}

          <p className="line long">
            Email me when there is a new post from these users, channels or
            tags:
          </p>
          {followingWall}
        </div>
      </div>
    );
  },
});
