require("normalize.css");

require("./scss/base.js");

var TremrSettings = require("./js/utils/tremr_settings");

var Promise = require('bluebird');
if (typeof Promise.config == 'function') {
	Promise.config({
	    cancellation: true,
	    // warnings: true,
	    // longStackTraces: true,
	    // monitoring: true,
	    warnings: false,
	    longStackTraces: false,
	    monitoring: false
	});
}

// backbone fix for rails CSRF
var backboneRailsSync = require('./js/plugins/backbone-rails-sync');

var embedly = require('jquery-embedly');

var Tremr = require('./js/tremr');

// google recaptcha
window.tremr_googlerecaptcha = false;
window.tremr_googlerecaptcha_onloadCallback = function() {
    window.tremr_googlerecaptcha = true;
};

jQuery.ready.then(function() {
// $(document).ready(function() {

    // inject google recaptcha
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = 'https://www.google.com/recaptcha/api.js?onload=tremr_googlerecaptcha_onloadCallback&render=explicit';
    document.body.appendChild(script);

	$.embedly.defaults.key = TremrSettings.embedly_key;

	// add Tremr to window object (legacy)
	window.Tremr = Tremr;

	Tremr.Utils.checkForOldIE(); // redirect
	Tremr.Utils.checkForOldIOS(); // redirect

	// kick-off processing
	Tremr.start();

	// mark html element with ie
	if (Tremr.Utils.checkForIE()) {
		var html = document.getElementsByTagName("HTML")[0];
		html.classList.add('ie');

	} else if (Tremr.Utils.checkForEdge()) {
		var html = document.getElementsByTagName("HTML")[0];
		html.classList.add('edge');
	}

}).fail(function(error) {
  throw error;
});
