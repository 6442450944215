var CreateReactClass = require('create-react-class');

// contains child components that can be passed loadMore call without
// us needing to have a direct reference to them
// HACK: has to have the parents refs sent in because children SOMEHOW don't give their
// ref to the container only the parent of the container ie. where they are declatred.
module.exports = CreateReactClass({

    loadMore: function() {

        React.Children.forEach(this.props.children, function(child) {

            let refChild = this.props.parentrefs[child.ref];
            if (refChild && refChild.loadMore) {
                refChild.loadMore();
            }
        }.bind(this));
    },

    // display
    render: function() {
        return <div className="generic-loadmorecontainer">
            {this.props.children}
        </div>;
    }
});

