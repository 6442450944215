var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// Tremr.Generic.OptionItem = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    label: PropTypes.string,
    set: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
    useTick: PropTypes.bool,
  },

  getDefaultProps: function () {
    return {
      label: false,
      set: false,
      readonly: false,
      useTick: false,
    };
  },

  handleClick: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.readonly) {
      return;
    }
    if (this.props.onChange) {
      this.props.onChange(this.props.value, !this.props.set);
    }
  },

  render: function () {
    let marker = <div className="marker"></div>;

    if (this.props.useTick) {
      marker = <Tremr.Generic.SvgIcon classes="marker tick" icon="ticked" />;
    }

    var label = null;
    if (this.props.label) {
      label = <div className="label">{this.props.label}</div>;
    }

    var classes = {
      set: this.props.set,
      readonly: this.props.readonly,
    };
    classes["option-item"] = true;
    classes[
      this.props.label.toLowerCase().replaceAll(/[^a-zA-Z]/g, "-")
    ] = true;
    classes = classNames(classes);

    return (
      <div className={classes} onClick={this.handleClick}>
        {marker}
        {label}
      </div>
    );
  },
});
