var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultState: function() {
		return {
			initialRegion: 'US'
		}
	},

	getInitialState: function() {
		return {
			activeRegion: this.props.initialRegion
		}
	},

	getRegion: function(region) {

		var ukregions = ['GG', 'JE', 'IM', 'GI', 'GB'];
        if (_.contains(ukregions, region)) {
            return 'GB';
        }

      	return region;
	},

	// listen for set region
    componentDidMount: function() {

        _.extend(this, Backbone.Events);

        // see if this post is deleted
        this.listenTo(Tremr.dispatcher, "tremr:uievent:setregion", function(region) {

        	this.setState({
            	activeRegion: this.getRegion(region)
            });
        }.bind(this));

    },

    // unregister listeners
    componentWillUnmount: function() {

        this.stopListening();
    },

    render: function() {

    	return (
			<div className="region-toggle">
	        	<a className={this.state.activeRegion == 'US' ? 'active' : ''} href="/us">US</a>
	        	<a className={this.state.activeRegion == 'GB' ? 'active' : ''} href="/uk">UK</a>
	        </div>
		);
	}
});
