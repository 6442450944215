// mixin that sets-up backnone events
// Tremr.Generic.EventsMixin
module.exports = {

	// hook into events
    componentWillMount: function() {

    	if (!this.stopListening) {
        	_.extend(this, Backbone.Events);
        }
    },

    // stop listening to events
    componentWillUnmount: function() {

        this.stopListening();
    }

};