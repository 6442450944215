var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// Plus button with menu to select new block type
// Tremr.Editor.Addblockcontrol
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      addBlock: PropTypes.func.isRequired,
      updateBlock: PropTypes.func.isRequired,
      identifier: PropTypes.string.isRequired,
      blockIdentifier: PropTypes.string.isRequired,
      onOpen: PropTypes.func.isRequired
  },

  uploadBlockId: false,
  embedBlockId: false,

  // start unexpanded
  getInitialState: function() {

    return {
      open: false,
      mode: 'menu',
      position: {
        top: 0, right: 0
      }
    }
  },

  toggleMenu: function(event) {
    event.preventDefault();
    event.stopPropagation();

    // raise event if we are opening
    if (this.props.onOpen && !this.state.open) {
      this.props.onOpen(this.props.identifier);
    }

    this.setState({
      open: !this.state.open
    });
  },

  componentDidUpdate: function(prevProps, prevState) {

    if (this.state.mode == 'prompt-video' ||
               this.state.mode == 'prompt-link') {

      this.refs['prompt'].focus();
    }
  },

  addBlock: function(event) {
    event.preventDefault();
    event.stopPropagation();

    var blockType = $(event.currentTarget).attr('data-block-type');
    var newBlock = this.props.addBlock(blockType, this.props.blockIdentifier);

    this.setState({ open: false });
  },

  // failed uploads - notify user and delete the block
  uploadFailHandler: function() {

    if (this.uploadBlockId) {
      var props = {
        inProgress: false,
        progress: 0,
        image: false
      };
      this.props.removeBlock(this.uploadBlockId);
    }

    this.setState({ open: false });
    alertify.error("Error uploading");
  },

  // when upload started tell editor to add a new block and remember the id
  uploadStartHandler: function() {

    var uploadBlock = this.props.addBlock('upload', this.props.blockIdentifier);
    this.uploadBlockId = uploadBlock.get('identifier');

    this.setState({ open: false });
  },

  // tell editor to set progress by id
  uploadUpdatedHandler: function(image) {

      if (this.uploadBlockId) {
        var props = {
          image: image,
          inProgress: false,
          progress: 0
        };
        this.props.updateBlock(this.uploadBlockId, props);
        this.uploadBlockId = false;
      }
  },

  // tell editor to set image by id
  uploadProgressHandler: function(progress) {

    if (this.uploadBlockId) {
      var props = {
        inProgress: true,
        progress: progress
      };
      this.props.updateBlock(this.uploadBlockId, props);
    }
  },

  promptLink: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      mode: 'prompt-link'
    });
  },

  promptVideo: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      mode: 'prompt-video'
    });
  },

  promptTwitter: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      mode: 'prompt-twitter'
    });
  },

  close: function() {
    this.embedBlockId = false;

    this.setState({
      open: false,
      mode: 'menu'
    });
  },

  cancelEmbed: function() {

    this.close();
  },

  // show "loading"
  startEmbed: function() {
    var embedBlock = this.props.addBlock('embed', this.props.blockIdentifier);
    this.embedBlockId = embedBlock.get('identifier');

    this.setState({
      open: false,
      mode: 'menu'
    });
  },

  setEmbed: function(data) {

    if (this.embedBlockId) {
      var props = {
        embed: data
      };
      this.props.updateBlock(this.embedBlockId, props);
      this.embedBlockId = false;
    }
  },

  embedError: function(message) {

    alertify.error(message);
  },

  setTwitterEmbed: function(data) {

    this.setState({
      open: false,
      mode: 'menu'
    });

    var newBlock = this.props.addBlock('twitter', this.props.blockIdentifier, {
      code: data
    });
  },

  render: function() {

    var classes = {
        'editor-control': true,
        'add-block': true,
        'open': this.state.open,
        'first': (this.props.identifier == 'add-first')
    };
    classes[this.state.mode] = true;
    classes = classNames(classes);

    var handleText = 'ADD';
    if (this.state.open) {
      handleText = 'CANCEL';
    }
    // var handle = <a onClick={this.toggleMenu}>+</a>;
    var handle = <a onClick={this.toggleMenu}><Tremr.Generic.SvgIcon icon="add" /></a>;

    var menu = null;
    if (this.state.mode == 'menu') {
      menu = <ul className="menu">
        <li onClick={this.addBlock} data-block-type="text"><Tremr.Generic.SvgIcon icon="paragraph" /></li>
        <li><Tremr.Generic.Imageupload ref="upload" buttonText={<Tremr.Generic.SvgIcon icon="photo" />} image={this.state.image} failHandler={this.uploadFailHandler} startHandler={this.uploadStartHandler} updatedHandler={this.uploadUpdatedHandler} progressHandler={this.uploadProgressHandler} showProgress={false} showPreview={false} /></li>
        <li onClick={this.promptLink} data-block-type="text"><Tremr.Generic.SvgIcon icon="link" /></li>
        <li onClick={this.promptVideo} data-block-type="text"><Tremr.Generic.SvgIcon icon="play" /></li>
        <li onClick={this.promptTwitter} data-block-type="text"><Tremr.Generic.SvgIcon icon="tweet" /></li>
      </ul>;
    } else if (this.state.mode == 'prompt-link') {
      menu = <div className='menu'>
        <Tremr.Generic.EmbedPrompt ref="prompt" prompt="Paste link…" onStart={this.startEmbed} onCancel={this.cancelEmbed} onSuccess={this.setEmbed} onInvalid={this.embedError} />
      </div>;
    } else if (this.state.mode == 'prompt-video') {
      menu = <div className='menu'>
        <Tremr.Generic.EmbedPrompt ref="prompt" prompt="Paste video link…" onStart={this.startEmbed} onCancel={this.cancelEmbed} onSuccess={this.setEmbed} onInvalid={this.embedError} />
      </div>;
    } else if (this.state.mode == 'prompt-twitter') {
      menu = <div className='menu'>
        <Tremr.Generic.EmbedCodePrompt ref="prompt" prompt="Paste twitter embed code…" onCancel={this.cancelEmbed} onSuccess={this.setTwitterEmbed} onInvalid={this.embedError} />
      </div>;
    }

    var styles = this.state.position;

    return (

      <div className={classes} style={styles}>
        {handle}
        {menu}
      </div>
    );
  }
});
