// Useful react things
var ReactUtils = {};

// see if we have a valid email address
ReactUtils.validateEmail = function (text) {

	if (Tremr.Utils.validEmail(text)) {
		return {
			valid: true
		}
	} else {
		return {
			valid: false,
			message: 'Please enter a valid email'
		}
	}

}

ReactUtils.validateValue = function (text, name) {

	if (!text || text.lenth == 0) {
        return {
			valid: false,
			message: 'Please enter '+name
		}
    } else {
		return {
			valid: true
		}
	}

}

// replace whitespace with dash and lowercase
ReactUtils.validateSlug = function (text) {

	var value = text.toLowerCase();
    value = value.replace(/[^a-zA-Z0-9-]+/g, "-");
    value = value.replace(/-{2,}/g, "-");

    // ensure at least length 3
  //   if (value.length < 3) {
  //   	return {
		// 	valid: false,
		// 	message: 'Must be at least 3 characters long',
  //   		value: value
		// }
  //   }

    // ensure we start with an ascii character
    if (value.charAt(0) == '-') {
    	return {
			valid: false,
			message: 'Must start with a valid letter or number',
    		value: value
		}
    }

    return {
    	valid: true,
    	value: value
    }
}


// min length and possibly force use of upper/lower etc.
ReactUtils.validatePassword = function (text) {

	var value = text.replace(/\s/gi, "");
	var returnData = {
		valid: true,
		value: value
	};
    if (value.length < 8) {
    	returnData['valid'] = false;
    	returnData['message'] = 'Password must be at least 8 characters long';
    }
    return returnData;
}

module.exports = ReactUtils;
