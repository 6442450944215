require("./scss/subfeeds.scss");

var alertify = require('alertify');
var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');
var TremrUtils = require('../../utils/tremr_utils');
var IconButton = require('../generic/iconbutton');
var Subfeed = require('../../models/Subfeed');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [UserAwareMixin], // cant be pure because the sortablejs re-orders the array!

	getDefaultProps: function() {
		return {
			subfeeds: [],
			selectedSubfeeds: [],
            showAllPosts: true
		};
	},

	getInitialState: function() {

        return {
			subfeeds: this.props.subfeeds ? this.props.subfeeds.slice() : [],
			selectedSubfeeds: this.props.selectedSubfeeds ? this.props.selectedSubfeeds.slice() : [],
            newsubfeedname: '',
		};
	},

	selectedSubfeed: function(params) {

		// let allPostsSet = this.state.selectedSubfeeds.includes('all-posts'); // include as a selectable option

		// if (allPostsSet) {
			// don't replace all-posts as that one is now either/or with others
			// params.push('all-posts');
		// }
        console.log("selectedSubfeed:");
        console.dir(arguments);

		this.setState({
			selectedSubfeeds: params
		});

		// send to parent
		if (this.props.onChange) {
			this.props.onChange(params);
		}
	},

	toggleAll: function(subfeed, isSet) {

		let selected = this.state.selectedSubfeeds.slice(0);
		let newSelected = _.reject(selected, function(item) {
			return (item == subfeed);
		});

		if (isSet) {
			newSelected = []; // make the all posts toggle everything else off
			newSelected.push(subfeed);
		}

		this.setState({
			selectedSubfeeds: newSelected
		});

		// send to parent
		if (this.props.onChange) {
			this.props.onChange(newSelected);
		}
	},

    // keep track of new subfeed, until it is entered
	newSubfeed: function(content) {

		this.setState({
			newsubfeedname: content.value
		});
	},

    handleKeyUp: function(event) {

        if (event.keyCode === 13) {
            this.endNewSubfeed();
        }
    },


	// add the new subfeed and clear the new field
	endNewSubfeed: function() {

		let isError = false;

        if (this.state.newsubfeedname.trim() == '') {
			alertify.error("Please enter a feed name");
			isError = true;
		}

		let subfeeds = this.state.subfeeds.slice();

		// check for duplicate
		_.each(subfeeds, (subfeed) => {
				if (subfeed.name.toLowerCase() == this.state.newsubfeedname.trim().toLowerCase()) {
				alertify.error("Duplicate feed name");
				isError = true;
			}
		});

		if (!isError) {

            let newSF = {
				id: TremrUtils.uniqueId(),
				name: this.state.newsubfeedname.trim()
			};
            let subfeeds = subfeeds ? subfeeds.concat([newSF]) : [newSF];
            let selected = this.state.selectedSubfeeds ? this.state.selectedSubfeeds.concat([this.state.newsubfeedname]) : [this.state.newsubfeedname];
            this.selectedSubfeed(selected);

            this.setState({
				subfeeds: subfeeds,
				selectedSubfeeds: selected,
				newsubfeedname: '',
				subfeedsUpdatedIndex: this.state.subfeedsUpdatedIndex + 1
			});
		}
	},

	render: function() {

        // use a key on the input field so we can force a new component when the
		// new feed is saved, thus clearing the text of the field.
		let inputKey = TremrUtils.uniqueId();
		if (this.state.subfeeds.length > 0) {
			inputKey = this.state.subfeeds[this.state.subfeeds.length - 1].id;
		}

        let allPostsSet = this.state.selectedSubfeeds.includes('all-posts'); // include as a selectable option
		let nonAllSelected = _.reject(this.state.selectedSubfeeds, function(item) {
			return (item == 'all-posts');
		});

        let all_posts = null;
        if (this.props.showAllPosts) {
            all_posts = <div className="option-list">
                <div className="disabled option-item">
                    <Tremr.Generic.OptionItem key={'option-allposts'} label="All Posts" value="all-posts" set={true} readonly={true} />
                </div>
            </div>;
        }

        let addControl = null;
        if (this.props.canAdd) {
            addControl = <div className="option-list">
                <div className="option-item">
                    <Tremr.Generic.SvgIcon icon="plus" />
                    <Tremr.Editor.ContentEditable key={inputKey} classNames={{contenteditable: true, newFeed: true}} onKeyUp={this.handleKeyUp} initialValue={this.state.newsubfeedname} onChange={this.newSubfeed} maxLength={30} placeholder="Add new feed"/>
                    <div className="button add" onClick={(event) => {
						this.endNewSubfeed();
					}}>Add</div>
                </div>
            </div>;
        }


        return <div className="post-subfeeds">

				{all_posts}

			    <Tremr.Generic.Optionlist key={nonAllSelected.length} multiSelect={true} initialSet={nonAllSelected} onChange={this.selectedSubfeed} options={_.map(this.state.subfeeds, function(subfeed) {
                    if (_.isArray(subfeed.name)) {
                        return {label: _.last(subfeed.name), value:  _.last(subfeed.name)};
                    } else {
                        return {label: subfeed.name, value: subfeed.name};
                    }
				}.bind(this))} />

                {addControl}
		</div>;
	}

});
