require("./scss/titlebar.scss");

var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var Titlebar = require("../layout/titlebar");
var Config = require("config");
var ChannelAvatar = require("../feed/channelavatar");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    feedname: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return {
      channel: null,
    };
  },

  // listen for channel post
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    // listen for the posts channel data
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:channel:changed",
      function (channel) {
        this.setState({
          channel: channel,
        });
      }.bind(this)
    );

    // request the posts channel in case we missed it first time
    Tremr.dispatcher.message(this, "tremr:post:channel:changed:request");
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.stopListening();
  },

  // navigate to the users feed
  openContext: function (event, context) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({
          primary: context,
        });
        Channel;
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    Tremr.navigatePrimary(context);
  },

  openChannel: function (event) {
    var feedContext = {
      target: "feed",
      scope: "all",
      subfeed: "featured",
      feed: this.state.channel.get("name"),
    };

    this.openContext(event, feedContext);
  },

  // display standard titlebar until we have a post load event for a post with a channel
  render: function () {
    if (this.state.channel) {
      // let avatar = <Tremr.Generic.SvgIcon icon="tag" />;
      if (this.state.channel.get("avatar_image")) {
        // avatar = <Tremr.Generic.Image key={"avatar"+this.state.channel.get('avatar_image').public_id} height={36} width={36} initialBackgroundColor="#4c4957" image={this.state.channel.get('avatar_image')} />;
      } else {
        let color = Config.colors.highlight;
        if (this.state.channel.get("highlight_color")) {
          color = this.state.channel.get("highlight_color");
        }
        // avatar = <div key="authorAvatar" className="avatar dummy">
        //     <ChannelAvatar title={this.state.channel.get('title')} color={color} />
        // </div>;
      }
      // let title = <div className="channel-title">{this.state.channel.get('title')}</div>;
      let follow = null;

      let channelUrl = Tremr.routes.urlFor({
        primary: {
          target: "feed",
          scope: "all",
          subfeed: "featured",
          feed: this.state.channel.get("name"),
        },
      });

      
      // {avatar}
      // {title}
      return (
        <Titlebar extraClass="with-channel">
          <a className="subheader" href={channelUrl} onClick={this.openChannel}>
            {this.state.channel.get("title")}
          </a>
          <a
            className="newlinesubheader"
            href={channelUrl}
            onClick={this.openChannel}
          >
            {this.state.channel.get("title")}
          </a>
        </Titlebar>
      );
    } else {

      return <Titlebar showBack={true} />;
    }
  },
});
