var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	render: function() {

    return <div className="layout-requestinvite layout-requestinvite-thankyou layout-staticpage">
        <div className="content">
            <h2>Thanks! Three quick things before you go…</h2>

            <ol>
            <li>We've sent an email confirming your request to <strong>{this.props.data ? this.props.data.email : 'you'}</strong>. If you don't see it within a few minutes please check your junk mail folder.</li>

            <li>Please add <strong>{Tremr.Theme.SupportEmail}</strong> to your email contacts to ensure we can contact you when your invitation is approved.</li>

            <li>Remember, you don’t need to be signed up to enjoy browsing and reading {Tremr.Theme.Title}. Click the big button to carry on…</li>
            </ol>

            <p><button onClick={this.props.close}>Return to {Tremr.Theme.Title}</button></p>
        </div>
    </div>;
	}
});
