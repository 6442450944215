var classNames = require('../../utils/classnames');
var TremrSettings = require("../../utils/tremr_settings");
var CreateReactClass = require('create-react-class');

// Tremr.Generic.Image = CreateReactClass({
module.exports = CreateReactClass({

   mixins: [PureRenderMixin], // can't use this because of fade in

   // state to allow for fading in images
   getInitialState: function() {
      return {
         loadedImage: false,
         height: this.props.height,
         width: this.props.width,
         backgroundColor: this.props.initialBackgroundColor,
         url: '',
         notFound: false,
         imgElement: null, // keep reference to create img element
         mounted: false
      }
   },

   // set default props (as reference to what we can send in)
   getDefaultProps: function() {
      return {
         image: false, // cloudinary image json
         width: 200,
         height: false, // if height or width are false then calculate from width
         maxHeight: false, // allow setting a max allowed height
         minHeight: false, // allow setting a max allowed height
         crop: true, // can we crop to make image match requested dimensions
         gravity: 'g_face', // cloudinary gravity mode
         initialBackgroundColor: 'white',
         calcWidthOnTallerThanMaxHeight: false, // set width when resizing because too tall (max is width)
         urlHeight: false, // override url height
         urlWidth: false, // override url width
         resizable: false,
         default_image: "avatar-default"
      }
   },

   // remove any event handlers
   componentWillUnmount: function() {

      if (this.state.imgElement) {
         this.state.imgElement.removeEventListener('load', this.handleImageLoad);
         this.state.imgElement.removeEventListener('error', this.handleImageError);
      }
   },

   // change state and run callback
   handleImageLoad: function(event) {

      if (this.state.mounted) {
         this.setState({loadedImage: true});
      }

      if (this.props.onLoad) {
         this.props.onLoad();
      }
   },

   // change state
   handleImageError: function(event) {
      this.setState({notFound: true});
   },

   calculateDimensions: function() {

      // work out the dimensions we want
      var setHeight = this.props.height;
      var setWidth = this.props.width;
      var domNode = ReactDOM.findDOMNode(this);

      // allow props to be set to auto and use the dimension of this node
      if (setHeight == 'auto') {
         setHeight = Tremr.Utils.getHeight(domNode);
      }

      if (setWidth == 'auto') {
         setWidth = Tremr.Utils.getWidth(domNode);
      }

      // if we aren't bothered use actual
      if (setHeight == false && setWidth == false) {

         setHeight = this.props.image.height;
         setWidth = this.props.image.width;

      } else if (setHeight == false) {
         // if one is false then calculate it based on the other
         var widthRatio = setWidth / this.props.image.width;
         setHeight = Math.round(this.props.image.height * widthRatio);
      } else if (setWidth == false) {
         // if one is false then calculate it based on the other
         var heightRatio = setHeight / this.props.image.height;
         setWidth = Math.round(this.props.image.width * heightRatio);
      }

      // never show bigger than screen width
      var current_width = Math.round($(window).innerWidth());
      // var current_width = $(window).innerWidth();
      if (setWidth > current_width) {
          // var widthRatio = current_width / setWidth;
          var widthRatio = current_width / this.props.image.width;
         setWidth = current_width;
         setHeight = Math.round(this.props.image.height * widthRatio);
      }

      // never show taller than max height
      if (this.props.maxHeight && setHeight > this.props.maxHeight) {

         var heightRatio = this.props.maxHeight / setHeight;
         setHeight = this.props.maxHeight;

         if (this.props.calcWidthOnTallerThanMaxHeight || this.props.width == false || this.props.width == 'auto') { // adjust width only if we are calculating it

            var newWidth = Math.round(setWidth * heightRatio);
            if (this.props.calcWidthOnTallerThanMaxHeight && (this.props.width == false || newWidth < this.props.width || this.props.width == 'auto')) {
               setWidth = newWidth;
            }
         }
      }

      // never, ever show taller than 5000 (when adjusted for retina)
      var pxRatio = parseFloat(Tremr.Utils.getPixelRatio());
      if (setHeight * pxRatio > 5000) {
          // var heightRatio = (setHeight * pxRatio) / 5000;
          var heightRatio = (setHeight * pxRatio) / 5000;
          setHeight = Math.floor(5000 / pxRatio);

         if (this.props.width == false) { // adjust width only if we are calculating it
            setWidth = Math.floor(this.props.image.width / heightRatio);
         }
      }

      // check min height
      if (this.props.minHeight && setHeight < this.props.minHeight) {
         var heightRatio = setHeight / this.props.minHeight;
         setHeight = this.props.minHeight;
         if (this.props.width == false) { // adjust width only if we are calculating it
            setWidth = Math.round(this.props.image.width / heightRatio);
         }
      }

      // never show wider than 5000 (when adjusted for retina)
      if (setWidth * pxRatio > 5000) {
         var widthRatio = (setWidth * pxRatio) / 5000;
         setWidth = Math.floor(5000 / pxRatio);
         if (this.props.height == false) { // adjust height only if we are calculating it
            setHeight = Math.floor(this.props.image.height / widthRatio);
         }
      }

      var cropMode = 'c_fit';
      if (this.props.crop) {
         cropMode = 'c_fill';
      }

      var backgroundColor = this.state.backgroundColor; // read from image info in the future?

      // go to cloudinary if we have a public id or try url prop
      let url = '';
      if (this.props.image && this.props.image.public_id) {

         let urlWidth = setWidth;
         let urlHeight = setHeight;
         if (this.props.urlWidth) {
            urlWidth = this.props.urlWidth;
         }

         if (this.props.urlHeight) {
            urlHeight = this.props.urlHeight;
         } else if (this.props.urlWidth) {
            let urlWidthRatio = 1;
            if (urlWidth != "auto") {
                urlWidthRatio = urlWidth / this.props.image.width;
            }
            urlHeight = Math.round(this.props.image.height * urlWidthRatio);
        }

        if (urlWidth && urlWidth != "auto" && urlWidth * pxRatio > 5000) {
            urlWidth = ",w_"+Math.floor(5000 / pxRatio);
        } else if (urlWidth == "auto") {
            urlWidth = "";
        } else {
            urlWidth = ",w_"+Math.floor(urlWidth);
        }
        if (urlHeight && urlHeight != "auto" && urlHeight * pxRatio > 5000) {
            urlHeight = ",w_"+Math.floor(5000 / pxRatio);
        } else if (urlHeight == "auto") {
            urlHeight = "";
        } else {
            urlHeight = ",h_"+Math.floor(urlHeight);
        }

        url = TremrSettings.cloudinary_url + "image/upload/dpr_" + Tremr.Utils.getPixelRatio() + ",f_auto,q_90" + urlWidth + "" + urlHeight + "," + cropMode + "," + this.props.gravity + "/" + this.props.image.public_id + ".jpg";
     } else if (this.props.image && this.props.image.url) {
        url = this.props.image.url;
    } else if (this.props.default_image) {

         let urlWidth = Math.floor(setWidth);
         let urlHeight = Math.floor(setHeight);
         if (this.props.urlWidth) {
            urlWidth = Math.floor(this.props.urlWidth);
         }
         if (this.props.urlHeight) {
            urlHeight = Math.floor(this.props.urlHeight);
         }

         // avatar-default
         url = TremrSettings.cloudinary_url + "image/upload/dpr_" + Tremr.Utils.getPixelRatio() + ",f_auto,q_90,w_" + urlWidth + ",h_" + urlHeight + "," + cropMode + "," + this.props.gravity + "/"+this.props.default_image+".jpg";
     }

      // preload image and update state when it has
      let imgElement = document.createElement('img');
      imgElement.addEventListener('load', this.handleImageLoad);
      imgElement.addEventListener('error', this.handleImageError);
      imgElement.src = url;

      this.setState({
         height: setHeight,
         width: setWidth,
         backgroundColor: backgroundColor,
         url: url,
         imgElement: imgElement,
         mounted: true
      });
   },

   // work out the URL and styles needed and add to state
   componentDidMount: function() {

      this.calculateDimensions();
   },

   // display based on the image property
   render: function() {

      // see if we have loaded the image or not
      var classes = classNames({
         'cover': true,
         'image': true,
         'fadein': !this.state.loadedImage,
         'error': this.state.notFound
      });

      // cover style image
      var styles = {
         backgroundColor: this.state.backgroundColor,
         height: this.state.height,
         width: this.state.width
      };
      if (this.props.resizable) {
         // styles.width = 'unset';
         styles.width = '100%';
      }

      if (this.state.url != false) {
         styles['backgroundImage'] = 'url(' + this.state.url + ')';
      }

      // fadein style
      var fadeinStyles = {
         backgroundColor: this.state.backgroundColor
      }

      // display error
      if (this.state.notFound) {
         return (<div className={classes} style={styles}></div>);
      }

      // put the image in using specified dimensions
      return <div className={classes} style={styles}>
         <div className="fader" style={fadeinStyles}></div>
      </div>;
   }
});
