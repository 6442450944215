require("./scss/icon_button.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var UserAwareMixin = require('../../mixins/userawaremixin');

module.exports = CreateReactClass({

    mixins: [UserAwareMixin],

    propTypes: {
        tag: PropTypes.string.isRequired,
        onClick: PropTypes.func
    },

    getEncodedTag: function() {
        return this.props.tag.replace(/\s/g, ':');
    },

    // show the repost menu
    handleClick: function(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.state.user) {

            if (this.props.onClick) {
                this.props.onClick();
            }

            var overlayData = {
               class: "Tremr.Activity.RepostMenu",
               style: "sidebar",
               props: {
                  user: this.state.user,
                  post_id: this.props.post_id,
                  post_author: this.props.post_author,
                  post_title: this.props.post_title
               }
            }
            Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);

        } else {
            Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
                callback: this.handleClick,
                data: event
            });
            return;
        }
    },

    // display icon for the tag
    render: function() {

        let iconName = this.props.tag;
        let iconComponent = <Tremr.Generic.SvgIcon key="icon" icon={iconName} classes="button icon" />;
        let value = this.props.liveDataPoints[this.getEncodedTag()+'-set'].data;

        // see if we have loaded the image or not
        let classes = {};
        classes['activity-icon-button'] = true;
        classes['set'] = value;
        // 'set': this.state.userSet,
        classes[this.props.tag.replace(/\s/g, '-')] = true;
        classes = classNames(classes);

        return (<button title={this.props.tag.capitalize()} className={classes} onClick={this.handleClick}>
            {iconComponent}
        </button>);
    }
});
