var CreateReactClass = require('create-react-class');
var TremrUtils = require('../../utils/tremr_utils');
var PropTypes = require('prop-types');

module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
		title: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        textColor: PropTypes.string,
        onClick: PropTypes.func
	},

    getDefaultProps: function() {
		return {
            onClick: _.noop,
            textColor: '#fff',
            color: '#000'
        };
	},

	render: function() {

        let text = this.props.title.substring(0, 2);
        if (this.props.title.includes(' ')) {
            let words = this.props.title.split(' ');
            if (words.length > 1) {
                text = words[0].substring(0, 1).toUpperCase() + words[1].substring(0, 1).toUpperCase();
            } else {
                text = text.toUpperCase();
            }
        } else {
            if (text.length > 1) {
                let words = this.props.title.split('');
                text = words[0].toUpperCase() + words[1].toLowerCase();
            } else {
                text = text.toUpperCase();
            }
        }

        let style = {
            backgroundColor: this.props.color,
            color: this.props.textColor
        };

        return <div title={this.props.title} className="channel-avatar" onClick={this.props.onClick} style={style}>{text}</div>;
    }
});
