require("./scss/embedthumb.scss");

var embedly = require("jquery-embedly");
var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");

// Tremr.Generic.EmbedThumb
module.exports = CreateReactClass({
  // mixins: [PureRenderMixin], // can't use this because of fade in

  // state to allow for fading in images
  getInitialState: function () {
    return {
      height: 200,
      width: 200,
      url: "",
      noImage: false,
      resizable: false,
    };
  },

  // // set default props (as reference to what we can send in)
  getDefaultProps: function () {
    return {
      embed: false, // embedly json
      width: 200,
      height: false, // if height or width are false then calculate from width
      maxHeight: false,
      minHeight: false,
      showImage: true,
      showText: true,
      showLink: true,
      showTitle: true,
      showDescription: true,
      shortText: false,
      showMedia: false,
      clickHandler: function (event) {},
    };
  },

  // work out the URL and styles needed and add to state
  componentDidMount: function () {
    // work out the dimensions we want
    var setHeight = this.props.height;
    var setWidth = this.props.width;

    var domNode = ReactDOM.findDOMNode(this);

    // allow props to be set to auto and use the dimension of this node
    if (setHeight == "auto") {
      setHeight = Tremr.Utils.getHeight(domNode);
    }

    if (setWidth == "auto") {
      setWidth = Tremr.Utils.getWidth(domNode);
    }

    // if we are a link and the thumbnail is the same as the URL it
    // probably isn't a real thumbnail so ignore it.
    if (
      this.props.embed.type == "link" &&
      this.props.embed.thumbnail_url == this.props.embed.url
    ) {
      this.setState({
        height: 0,
        width: setWidth,
        url: false,
        noImage: true,
      });
      return;
    }

    // if we don't have thumbnail data then use standard
    if (!this.props.embed.thumbnail_url) {
      this.props.embed.thumbnail_url = this.props.embed.url;
    }
    if (!this.props.embed.thumbnail_height) {
      this.props.embed.thumbnail_height = this.props.embed.height;
    }
    if (!this.props.embed.thumbnail_width) {
      this.props.embed.thumbnail_width = this.props.embed.width;
    }

    // if we aren't bothered use actual
    if (setHeight == false && setWidth == false) {
      setHeight = this.props.embed.thumbnail_height;
      setWidth = this.props.embed.thumbnail_width;
    } else if (setHeight == false) {
      // if one is false then calculate it based on the other
      var widthRatio = setWidth / this.props.embed.thumbnail_width;
      setHeight = Math.round(this.props.embed.thumbnail_height * widthRatio);
    } else if (setWidth == false) {
      // if one is false then calculate it based on the other
      setHeight = this.props.height;
      var heightRatio = setHeight / this.props.embed.thumbnail_height;
      setWidth = Math.round(this.props.embed.thumbnail_height * heightRatio);
    }

    // never show bigger than screen width
    var current_width = $(window).innerWidth();
    if (setWidth > current_width) {
      var widthRatio = current_width / setWidth;
      setWidth = current_width;
      setHeight = Math.round(setWidth * widthRatio);
    }

    // never show taller than max height
    if (this.props.maxHeight && setHeight > this.props.maxHeight) {
      var heightRatio = setHeight / this.props.maxHeight;
      setHeight = this.props.maxHeight;
      if (this.props.width == false) {
        // adjust width only if we are calculating it
        setWidth = Math.round(this.props.embed.thumbnail_width * heightRatio);
      }
    }

    // never show taller than 5000
    if (setHeight > 5000) {
      var heightRatio = setHeight / 5000;
      setHeight = 5000;
      if (this.props.width == false) {
        // adjust width only if we are calculating it
        setWidth = Math.round(this.props.embed.thumbnail_width * heightRatio);
      }
    }

    // check min height
    if (this.props.minHeight && setHeight < this.props.minHeight) {
      var heightRatio = setHeight / this.props.minHeight;
      setHeight = this.props.minHeight;
      if (this.props.width == false) {
        // adjust width only if we are calculating it
        setWidth = Math.round(this.props.embed.thumbnail_width * heightRatio);
      }
    }

    // work out the url
    var url = this.props.embed.thumbnail_url
      ? this.props.embed.thumbnail_url
      : this.props.embed.url;
    url = $.embedly.display.crop(url, {
      query: {
        height: setHeight,
        width: setWidth,
        animate: false,
      },
    });

    this.setState({
      height: setHeight,
      width: setWidth,
      url: url,
    });
  },

  handleClick: function (event) {
    event.stopPropagation();
  },

  handleImageLoad: function () {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  },

  // display based on the image property
  render: function () {
    // take our state and use for standard image props
    var image = {
      url: this.state.url,
      height: this.state.height,
      width: this.state.width,
    };

    var tags = null;
    if (this.props.showText && this.props.tags) {
      tags = this.props.tags;
    }

    var description = null;
    if (this.props.showText && this.props.showDescription) {
      if (this.props.shortText) {
        //description = <p>{Tremr.Utils.truncate(this.props.embed.description, 35, ' ')}</p>;
        description = (
          <p>
            {Tremr.Utils.truncate(
              this.props.embed.description,
              1,
              /[.\!\?]/,
              ".",
              "."
            )}
          </p>
        );
      } else {
        description = <p>{this.props.embed.description}</p>;
      }
    }

    var link = null;
    var title = null;
    var embed = this.props.embed;

    if (this.props.showText && embed && embed.original_url) {
      if (this.props.showTitle) {
        title = (
          <h4>
            <a
              onClick={this.handleClick}
              href={embed.original_url}
              target="_blank"
            >
              {this.props.embed.title}
            </a>
          </h4>
        );
      }
      var linktext = Tremr.Utils.parseUrlString(embed.original_url).hostname;
      linktext = linktext.replace(/^www\./, "");
      if (this.props.showLink && linktext.length > 0) {
        link = (
          <p className="link">
            <a
              onClick={this.handleClick}
              href={embed.original_url}
              target="_blank"
            >
              {linktext}
            </a>
          </p>
        );
      }
    } else if (this.props.showText && this.props.showTitle) {
      title = (
        <h4>
          <a onClick={this.handleClick} href={this.state.url} target="_blank">
            {this.props.embed.title}
          </a>
        </h4>
      );
    }

    var imageComponent = null;
    if (
      this.props.showMedia &&
      this.props.showImage &&
      image.url &&
      image.url.length > 0
    ) {
      imageComponent = (
        <Tremr.Generic.Embed
          embed={this.props.embed}
          maxHeight={this.props.maxHeight}
          height={false}
          width="auto"
          resizable={this.props.resizable}
        />
      );
    } else if (this.props.showImage && image.url && image.url.length > 0) {
      imageComponent = (
        <Tremr.Generic.Image
          onLoad={this.handleImageLoad}
          image={image}
          height={this.props.height}
          width={this.props.width}
          resizable={this.props.resizable}
        />
      );
    }

    let classes = classNames({
      "embed-thumb": true,
      "no-image": this.state.noImage,
    });

    let info = null;
    if (this.props.showText) {
      info = (
        <div className="info">
          {tags}
          {title}
          {description}
          {link}
        </div>
      );
    }

    if (this.state.url || this.state.noImage) {
      return (
        <div className={classes} onClick={this.props.clickHandler}>
          {imageComponent}
          {info}
        </div>
      );
    } else {
      return <div className="embed-thumb"></div>;
    }
  },
});
