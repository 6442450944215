var classNames = require('../../utils/classnames');
var CardMixin = require('../../mixins/cardmixin');
var CreateReactClass = require('create-react-class');

// Tremr.Generic.Contentcard
module.exports = CreateReactClass({

    mixins: [PureRenderMixin, CardMixin],

    // display
    render: function() {

        var divStyle = this.getCardStyle();

        // work out our top level class
        var classes = {
            'content-card': true,
            'card': true
        };
        classes['rho'] = this.props.rho;
        classes = classNames(classes);

        return (
        <div onClick={this.openCard} className={classes} style={divStyle}>
            {this.props.children}
        </div>
        );
    }
});

