require("./scss/toolbar.scss");

var CreateReactClass = require('create-react-class');
var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');
var HorizontalScroller = require('../generic/horizontalscroller');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

    getInitialState: function() {
      return {
        tags: Tremr.stores.tagStore.broadTags(),
        stricky: false
      };
    },

	handleClick: function(event) {

		// if the meta key is pressed, ignore
     if (event.metaKey) { return; }

     // if the meta key is pressed, ignore
     let link = event.currentTarget.getAttribute('data-link');
	  let taglink = event.currentTarget.getAttribute('data-tag');

		if (link) {
			event.preventDefault();
			event.stopPropagation();

			// do something on the link
			// if (link == "latest") {

			// 	Tremr.navigatePrimary({
			//         target: 'all',
			//         scope: 'all',
			//         sort: 'latest'
			// 	});

			// } else
			if (link == "trending") {

				Tremr.navigatePrimary({
			        target: 'all',
			        scope: 'all',
			        sort: 'trending'
				});

			} else if (link == "recommended") {

				Tremr.navigatePrimary({
			        target: 'all',
			        scope: 'all',
			        sort: 'recommended'
				});

			} else if (link == 'homepage') {

				Tremr.navigatePrimary({
			        target: 'homepage',
			        scope: 'all',
			        sort: 'latest'
				});

			} else if (link == 'homepage-feed') {

				Tremr.navigatePrimary({
			        target: 'homepage-feed',
			        scope: 'all',
			        feed: Tremr.Theme.HomepageFeed,
			        sort: 'latest'
				});

			} else if (link == Tremr.Theme.HomepageFeed) {

				Tremr.navigatePrimary({
			        target: 'feed',
			        scope: 'posts',
			        feed: Tremr.Theme.HomepageFeed,
			        subfeed: 'all'
				});
			}

		} else if (taglink) {
			event.preventDefault();
			event.stopPropagation();

			Tremr.navigatePrimary({
		        target: 'tag',
		        scope: 'all',
		        tag: taglink,
		        sort: 'latest'
			});
		}
	},

	// set/unset sticky class based on position
	checkSticky: function() {
		let node = ReactDOM.findDOMNode(this);
		if (node) {
			let rect = node.getBoundingClientRect();
			if (rect.top == 0 && !this.state.sticky) {
				this.setState({sticky: true});
			} else if (rect.top > 0 && this.state.sticky) {
				this.setState({sticky: false});
			}
		}
	},

	componentDidMount: function() {
		window.addEventListener("scroll", this.checkSticky);
		window.addEventListener("resize", this.checkSticky);

        if (!this.stopListening) {
          _.extend(this, Backbone.Events);
        }

        this.listenTo(Tremr.dispatcher, "tremr:firebase:cms", function(data) {
            if (data == 'tags') {
                this.setState({
                    tags: Tremr.stores.tagStore.broadTags()
                });
            }
        }.bind(this));
	},

	// clear the scroll event handler
	componentWillUnmount: function() {
		window.removeEventListener("scroll", this.checkSticky);
		window.removeEventListener("resize", this.checkSticky);

        if (this.stopListening) {
          this.stopListening();
        }
    },

	render: function() {

		let classes = {
			sticky: this.state.sticky
		};
		classes['layout-toolbar'] = true;
		classes = classNames(classes);

		let items = [];
		let itemClass = null;

        // my tremr for signed-in user only
		if (this.state.user) {
			itemClass = {
				active: this.props.active == 'homepage' || this.props.active == 'homepage-feed'
			}
			itemClass = classNames(itemClass);
			items.push(<a key="homepage" data-link="homepage" href="/" className={itemClass} onClick={this.handleClick}>{Tremr.Theme.PersonalFeedTitle}</a>);
		} else {
			itemClass = {
				active: this.props.active == 'homepage-feed'
			}
			itemClass = classNames(itemClass);
			items.push(<a key="homepage-feed" data-link="homepage-feed" href="/" className={itemClass} onClick={this.handleClick}>{Tremr.Theme.HomepageFeedTitle}</a>);
		}

		// trending
		itemClass = {
			active: this.props.active == 'trending'
		}
		itemClass = classNames(itemClass);
		
		// BRENT - commented out Trending tab below until we improve the algorithm
		//items.push(<a key="trending" data-link="trending" href="/trending" className={itemClass} onClick={this.handleClick}>Trending</a>);

		// top rated
		// itemClass = {
		// 	active: this.props.active == 'recommended'
		// }
		// itemClass = classNames(itemClass);
		// items.push(<a key="recommended" data-link="recommended" href="/recommended" className={itemClass} onClick={this.handleClick}>Top Rated</a>);

		// staff picks - when signed-in
		if (this.state.user) {
			itemClass = {
				active: this.props.active == Tremr.Theme.HomepageFeed
			}
			itemClass = classNames(itemClass);
			items.push(<a key={Tremr.Theme.HomepageFeed} data-link={Tremr.Theme.HomepageFeed} href={"/"+Tremr.Theme.HomepageFeed+"/posts/all"} className={itemClass} onClick={this.handleClick}>{Tremr.Theme.HomepageFeedTitle}</a>);
		}


		// tags
		let tags = this.state.tags;
		_.each(tags, (tag) => {
			itemClass = {
				active: this.props.active == tag,
				tag: true
			}
			itemClass = classNames(itemClass);
			items.push(<a className="tag" key={tag} data-tag={tag} href={"/tags/"+tag} className={itemClass} onClick={this.handleClick}>{tag}</a>);

		});


		return (
			<div className={classes}>

				<HorizontalScroller>
					{items}
				</HorizontalScroller>
			</div>
		);
	}
});
