require("./scss/wallcontrols.scss");

var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');
var classNames = require("../../utils/classnames");
var TremrUtils = require("../../utils/tremr_utils");
var PropTypes = require("prop-types");
var IconButton = require('../generic/iconbutton');

// links to effect options for a wall of posts
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    wallStyleProperty: PropTypes.string.isRequired
  },

  getInitialState: function() {
    return {
      wallStyle: Tremr.stores.userStore.getPreference(this.props.wallStyleProperty)
    }
  },

  updateWallStyle: function(identifier) {

    this.setState({
      wallStyle: identifier
    });

    // raise event for the wallStyleProperty
    Tremr.dispatcher.message(this, "tremr:user:preference", {key: this.props.wallStyleProperty, value: identifier});
  },

  render: function() {

    return <div className="post-wallcontrols">

      <div className="wallStyle">
        <IconButton identifier="grid" iconName="grid" tooltip="Grid" onClick={this.updateWallStyle} active={this.state.wallStyle == 'grid'} />
        <IconButton identifier="content" iconName="content" tooltip="Content" onClick={this.updateWallStyle} active={this.state.wallStyle == 'content'} />
        <IconButton identifier="list" iconName="list" tooltip="List" onClick={this.updateWallStyle} active={this.state.wallStyle == 'list'} />
      </div>

    </div>;
  }
});
