let React = require("react");
let CreateReactClass = require("create-react-class");
let PureCanvas = require("./purecanvas");
let Bar = require("./bar");
let Stars = require("./stars");

module.exports = CreateReactClass({
  getDefaultProps: function () {
    return {
      tags: {},
      starColor: "#fbac01",
      barColor: "#fbfbfb",
      percentBarColor: "#eeeeee",
      scale: 1,
      stars: [10, 20, 30],
    };
  },

  render: function () {
    if (this.props.tags) {
      // get the higherst score
      let highestScore = 0;
      Object.keys(this.props.tags).forEach(
        function (key) {
          if (highestScore < this.props.tags[key].rep_score) {
            highestScore = this.props.tags[key].rep_score;
          }
        }.bind(this)
      );

      // sort the tags by their value
      let tagNames = Object.keys(this.props.tags);
      tagNames.sort(
        function (a, b) {
          return this.props.tags[b].rep_score - this.props.tags[a].rep_score;
        }.bind(this)
      );

      let styles = {
        color: this.props.starColor,
      };
      return (
        <div className="reputation-tags" style={styles}>
          {tagNames.map(
            function (key) {
              const rep_score = this.props.tags[key].rep_score;
              const rep_stars = this.props.tags[key].rep_stars;
              let percent =
                (100 / highestScore) * this.props.tags[key].rep_score;
              return (
                <Bar
                  height={46 * this.props.scale}
                  width={324 * this.props.scale}
                  key={key}
                  scale={this.props.scale}
                  percent={percent}
                  backgroundColor={this.props.barColor}
                  foregroundColor={this.props.percentBarColor}
                >
                  <div className="tag">{key}</div>
                  <Stars
                    alignment="right"
                    setColor={this.props.starColor}
                    width={58 * this.props.scale}
                    height={16 * this.props.scale}
                    setColor={this.props.starColor}
                    starSize={16 * this.props.scale}
                    drawUnset={false}
                    stars={rep_stars}
                    filledStars={rep_stars}
                    scale={this.scale}
                  />
                  <div className="score">{rep_score}</div>
                </Bar>
              );
            }.bind(this)
          )}
        </div>
      );
    } else {
      return null;
    }
  },
});
