var CreateReactClass = require('create-react-class');
var PropTypes = require('prop-types');

// wrapper for a controlled input element that allows for validation, type etc. to be handled
// Tremr.Generic.Input = CreateReactClass({
module.exports = CreateReactClass({

	// mixins: [PureRenderMixin],

    propTypes: {
        initialValue: PropTypes.string,
        placeholder: PropTypes.string,
        type: PropTypes.string,
        initialError: PropTypes.bool,
        initialErrorMessage: PropTypes.string,
        maxLength: PropTypes.number,
        multiline: PropTypes.bool,
        readOnly: PropTypes.bool,
        validation: PropTypes.func,
        validationName: PropTypes.string,
        onChange: PropTypes.func,
        onEnter: PropTypes.func,
        onEscape: PropTypes.func
    },

    getDefaultProps: function() {
		return {
			initialValue: '',
			type: 'text',
			initialError: false,
			initialErrorMessage: '',
			maxLength: 255,
			multiline: false,
			readOnly: false,
            validationName: 'value',
            fixederror: false
		}
	},

	getInitialState: function() {
		return {
			error: this.props.initialError,
			errorMessage: this.props.initialErrorMessage,
			value: this.props.initialValue
		}
	},

	handleBlur: function(event) {

		var $el = $(event.currentTarget);
		var newState = {
	    	value: $el.val()
	    };

		// if we have a validation function then run it
	    if (this.props.validation) {
	    	var valid = this.props.validation(newState.value, this.props.validationName);
	    	if (!valid.valid) {
                newState['error'] = true;
	    		newState['errorMessage'] = valid.message;
	    	} else {
	    		newState['fixederror'] = (this.state.fixederror || this.state.error)
                newState['error'] = false;
	    		newState['errorMessage'] = '';
	    	}

	    	// allow validation to set the value
	    	if (valid.value) {
    			newState['value'] = valid.value;
    		}

    		this.setState(newState);
	    }
	},

	handleChange: function(event) {
		var $el = $(event.currentTarget);
		var newState = {
	    	value: $el.val()
	    };

	    // if we already have an error and we have a validation function then run it
	    if (this.state.error && this.props.validation) {
	    	var valid = this.props.validation(newState.value);
	    	if (!valid.valid) {
	    		newState['error'] = true;
	    		newState['errorMessage'] = valid.message;
	    	} else {
                newState['fixederror'] = (this.state.fixederror || this.state.error)
                newState['error'] = false;
	    		newState['errorMessage'] = '';
	    	}

	    	// allow validation to set the value
	    	if (valid.value) {
    			newState['value'] = valid.value;
    		}
	    }
	    this.setState(newState);

	    // callback if we have one
	    if (this.props.onChange) {
	    	this.props.onChange(newState.value);
	    }
	},

	// event fired on enter
	handleKeyPress: function(event) {

		if (this.props.onEnter && event.key == 'Enter') {
			this.props.onEnter();
		}

		if (this.props.onEscape && event.key == 'Escape') {
			this.props.onEscape();
		}
	},

	// focus on the input field (doesn't always work!?)
    focus: function(alsoSelect) {

      _.delay(function() {

      	if (this.refs['control']) {
        	var node = ReactDOM.findDOMNode(this.refs['control']);
        	if (node) {
        		node.focus();
                if (alsoSelect) {
                    node.select();
                }
        	}
        }

      }.bind(this), 100);

    },

	render: function() {

		var classNames = require('../../utils/classnames');
		var classes = {
            'field': true,
            'error': this.state.error,
            'fixederror': this.state.fixederror,
			'readonly': this.props.readOnly
		};
		classes[this.props.name] = true;
		classes = classNames(classes);

		var errorMessage = '';
		if (this.state.error) {
			errorMessage = <div className="error-message">{this.state.errorMessage}</div>;
		}

		var control = null;
		if (this.props.multiline) {
			control = <textarea ref="control" readOnly={this.props.readOnly} onBlur={this.handleBlur} onChange={this.handleChange} placeholder={this.props.placeholder ? this.props.placeholder : this.props.label} name={this.props.name} type={this.props.type} value={this.state.value || ""} maxLength={this.props.maxLength} />;
		} else {
			control = <input ref="control" readOnly={this.props.readOnly} onBlur={this.handleBlur} onKeyPress={this.handleKeyPress} onChange={this.handleChange} placeholder={this.props.placeholder ? this.props.placeholder : this.props.label} name={this.props.name} type={this.props.type} value={this.state.value || ""} maxLength={this.props.maxLength} />;
		}

    let label = null;
    if (this.props.label) {
      label = <label htmlFor={this.props.name}>{this.props.label}</label>;
    }

		return <div className={classes}>
            {label}
			{control}
            <div className="info">
    			{errorMessage}
                {this.props.children}
            </div>
		</div>;
	}
});
