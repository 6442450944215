var CreateReactClass = require('create-react-class');

// Tremr.Tag.Tagsmenu = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultState: function() {
		return {
			isLoading: false,
			tags: []
		};
	},

	// get context to open a tag
    getContext: function(tag) {
        return {
            target: 'tag',
            scope: 'posts',
            tag: tag
        };
    },

    // open the tag in primary area
    handleClick: function(event) {

        // if the meta key is pressed, ignore
        if (event.metaKey) { return; }

        event.preventDefault();
        event.stopPropagation();

        Tremr.navigatePrimary(this.getContext($(event.currentTarget).attr('data-tag')));

        // if mobile, also close sidebar
        if (Tremr.Utils.isDeviceOrNarrowWithSidebar()) {
            Tremr.context.batch({leftSidebar: 'closed'});
        }
    },

    // draw cards
    render: function() {

        return (

        <section className="layout-tagsmenu">
        	<ul>
            <li className="subheader">Popular Tags</li>
            {_.map(Tremr.stores.tagStore.standardTags(), function(tag, index) {
                var url = Tremr.routes.urlFor({ primary: this.getContext(tag) });
            	return <li key={tag} className="tag"><a href={url} onClick={this.handleClick} data-tag={tag} className={(this.props.active == 'tag-'+tag) ? 'active' : ''}><Tremr.Generic.Iconbutton iconName="tag" text={tag} /></a></li>;
	        }.bind(this))}
        	</ul>
        </section>
        );
    }
});
