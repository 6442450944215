require("./scss/editmenu.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var classNames = require('../../utils/classnames');
var SvgIcon = require('../generic/svgicon');
var UserAwareMixin = require("../../mixins/userawaremixin");

module.exports = CreateReactClass({

  mixins: [PureRenderMixin, UserAwareMixin],

  propTypes: {
      buttonText: PropTypes.string,
      isSaving: PropTypes.bool,
      onSave: PropTypes.func,
      onDelete: PropTypes.func
  },

  getDefaultProps: function() {
    return {
        buttonText: "Launch",
        isSaving: false,
        tags: [],
        subfeeds: [],
        selectedSubfeeds: []
    }
  },

  getInitialState: function() {
      return {
          open: false
      };
  },

  // main save button clicked - either when open or closed
  save: function(event) {
      this.setState({
          open: false
      });
      this.props.onSave(event);
  },

  delete: function(event) {
      this.props.onDelete(event);
  },

  // open/close this menu
  toggleOpen: function(event) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
          open: !this.state.open
      });
  },

  sidebarClick: function(event) {
      event.preventDefault();
      event.stopPropagation();
  },

  render: function() {

    let classes = classNames({
        "feed-edit-menu": true,
        open: this.state.open
    });

    let buttonText = this.props.buttonText;
    if (this.props.isSaving) {
      buttonText = 'Saving';
    }

    let discard = null;
    if (this.state.user && this.state.user.get('admin') == 'yes') {
        discard = <div onClick={this.delete} className="delete"><Tremr.Generic.SvgIcon icon="delete" /></div>;
    }

    if (this.state.open) {
        return <div className={classes}>
            <div className="overlay" onClick={this.toggleOpen}>
                <div className="sidebar" onClick={this.sidebarClick}>
                    <div className="handle">
                        {discard}
                        <div className="save-button" onClick={this.save}>{buttonText}</div>
                        <div className="toggle-button" onClick={this.toggleOpen}>
                            <SvgIcon icon="up" />
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        </div>;
    } else {
        return <div className={classes}>
            <div className="handle">
                <div className="save-button" onClick={this.save}>{buttonText}</div>
                <div className="toggle-button" onClick={this.toggleOpen}>
                    <SvgIcon icon="dropdown" />
                </div>
            </div>
        </div>;
    }
  }
});
