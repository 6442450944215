var Backbone = require("backbone");
var TremrStorage = require("./utils/tremr_storage");
var TremrTracking = require("./utils/tracking");
var TremrUtils = require("./utils/tremr_utils");
var Cookies = require("js-cookie/src/js.cookie");

// handle routes
module.exports = Backbone.Router.extend({
  routes: {
    "": "homepage",
    //"uk": "homepage",
    //"us": "homepage",
    welcome: "welcome",
    toppicks: "homepage",
    tags: "homepage",
    latest: "all",
    everything: "all",
    trending: "trending",
    recommended: "recommended",
    editor: "editor",
    signup: "signup",
    signin: "signin",
    "request-invite": "requestInvite",
    "invite/:token": "acceptInvite",
    editprofile: "editprofile",
    "notification-settings": "notification_settings",
    "recoverpassword/:token": "recoverpassword",
    "editor/draft/:draft_id": "editorDraft",
    "editor/post/:post_id": "editorPost",
    "editor/:fieldset": "editorFieldset",
    "tags/:id/followers/:sort": "tagSortedFollowers",
    "tags/:id/followers": "tagFollowers",
    "tags/:id/posts/:sort": "tagPostsSort",
    "tags/:id/posts": "tagPosts",
    "tags/:id": "tag",
    "=/tags/:id/posts/:sort": "home_tagPostsSort",
    "=/tags/:id/posts": "home_tagPosts",
    "=/tags/:id": "home_tag",
    "search/:id/users/:sort": "searchSortedUsers",
    "search/:id/tags/:sort": "searchSortedTags",
    "search/:id/channels/:sort": "searchSortedChannels",
    "search/:id/posts/:sort": "searchSortedPosts",
    "search/:id/users": "searchUsers",
    "search/:id/tags": "searchTags",
    "search/:id/channels": "searchChannels",
    "search/:id/posts": "searchPosts",
    "search/:id": "searchPosts",
    "feed/featured": "homepage",
    feed: "homepage",
    bookmarks: "bookmarks",
    "inbox/:sort": "sortedInbox",
    inbox: "inbox",
    nearby: "nearby",
    ":feed_id/drafts": "feedDrafts",
    ":feed_id/members/:sort": "feedSortedContributors",
    ":feed_id/members": "feedContributors",
    ":feed_id/channels/:sort": "feedSortedChannels",
    ":feed_id/channels": "feedChannels",
    ":feed_id/following/:sort": "feedSortedFollowing",
    ":feed_id/following": "feedFollowing",
    ":feed_id/followers/:sort": "feedSortedFollowers",
    ":feed_id/followers": "feedFollowers",
    ":feed_id/endorsements/:sort": "feedSortedEndorsements",
    ":feed_id/endorsements": "feedEndorsements",
    "=/:feed_id/posts": "home_feed",
    "=/:feed_id/posts/latest": "home_feedPosts",
    "=/:feed_id/posts/top": "home_feedPosts",
    "=/:feed_id/posts/:subfeed": "home_subfeedPosts",
    ":feed_id/invite/:action/:invite": "channelInvite",
    ":feed_id/posts": "feed",
    ":feed_id/posts/latest": "feedPosts",
    ":feed_id/posts/top": "feedPosts",
    ":feed_id/posts/:subfeed": "subfeedPosts",
    // ":feed_id/:post_id?tab=:tab": "post",
    // ":feed_id/:post_id?subtab=:subtab": "post",
    // ":feed_id/:post_id?tab=:tab&subtab=:subtab": "post",
    // ":feed_id/:post_id?subtab=:subtab&tab=:tab": "post",
    ":feed_id/:post_id": "post",
    ":feed_id": "featuredPosts",
  },

  // wrapper for navigate function that also causes an update to the title
  updateUrl: function (url, replace) {
    if (typeof replace == "undefined") {
      replace = false;
    }

    // get the URL slug
    var urlParts = Tremr.Utils.parseUrlString(url);
    urlParts = urlParts.pathname.split("/");

    // remove ='s character used on homepage ui style
    urlParts = urlParts.filter(function (part) {
      return part != "=";
    });

    var type = urlParts[0];

    // convert into something readable
    urlParts.reverse();
    urlParts.push(Tremr.Theme.Title);

    urlParts = _.map(urlParts, function (item) {
      var words = item.split("-");
      words = _.map(words, function (word) {
        return word.trim().capitalize();
      });
      return words.join(" ");
    });

    urlParts = _.reject(urlParts, function (item) {
      return item.trim() == "" || item == "Tags";
    });

    var newTitle = urlParts.join(" - ").trim();
    // check for special cases
    if (newTitle == "Uk - " + Tremr.Theme.Title) {
      newTitle = Tremr.Theme.Title + " is " + Tremr.Theme.Description;
    } else if (newTitle == "Trending - " + Tremr.Theme.Title) {
      newTitle = "Trending Posts - " + Tremr.Theme.Description;
    } else if (newTitle == "Recommended - " + Tremr.Theme.Title) {
      newTitle = "Top Rated Posts - " + Tremr.Theme.Description;
    } else if (newTitle == "Latest - " + Tremr.Theme.Title) {
      newTitle = "Latest Posts - " + Tremr.Theme.Description;
    } else if (newTitle == "Featured - Feed - " + Tremr.Theme.Title) {
      newTitle = "Personal Feed Featured - " + Tremr.Theme.Description;
    } else if (newTitle == "Feed - " + Tremr.Theme.Title) {
      newTitle = "Personal Feed - " + Tremr.Theme.Description;
    } else if (newTitle == Tremr.Theme.Title) {
      newTitle = Tremr.Theme.Title + " is " + Tremr.Theme.Description;
    }

    document.title = newTitle;

    // check what the current url is
    var currentUrl = TremrUtils.parseUrl();
    if (currentUrl.pathname != url) {
      // also log a new page
      TremrTracking.trackPage(type, url);

      // keep track on each url
      if (!this.tremrUrls) {
        this.tremrUrls = [];
      } else if (this.tremrUrls.length > 3) {
        this.tremrUrls.shift();
      }
      this.tremrUrls.push(url);

      // now update url
      if (replace) {
        this.navigate(url, { replace: true });
      } else {
        this.navigate(url);
      }
    }
  },

  // helper util for figuring out the url for a given state
  urlFor: function (state) {
    const homechar = "=";
    let url = "";

    if (state.primary) {
      if (state.primary.target == "post") {
        url = "/" + state.primary.feed + "/" + state.primary.id;
      } else if (
        state.primary.target == "homepage" &&
        state.primary.scope == "featured"
      ) {
        url = "/";
      } else if (state.primary.target == "welcome") {
        url = "/welcome";
      } else if (state.primary.target == "homepage") {
        url = "/";
      } else if (state.primary.target == "bookmarks") {
        url = "/bookmarks";
      } else if (state.primary.target == "inbox" && state.primary.sort) {
        url = "/inbox/" + state.primary.sort;
      } else if (state.primary.target == "inbox") {
        url = "/inbox";
      } else if (state.primary.target == "homepage") {
        url = "/";
        // var ukregions = ['GG', 'JE', 'IM', 'GI', 'GB'];
        // if (_.contains(ukregions, Cookies.get('region'))) {
        //     url = '/uk';
        // }
      } else if (
        state.primary.target == "all" &&
        state.primary.sort == "recommended"
      ) {
        url = "/recommended";
      } else if (
        state.primary.target == "all" &&
        state.primary.sort == "trending"
      ) {
        url = "/trending";
      } else if (state.primary.target == "all") {
        url = "/latest";
      } else if (state.primary.target == "personalfeed") {
        url = "/";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "channels" &&
        state.primary.sort
      ) {
        url = "/" + state.primary.feed + "/channels/" + state.primary.sort;
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "channels"
      ) {
        url = "/" + state.primary.feed + "/channels";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "contributors" &&
        state.primary.sort
      ) {
        url = "/" + state.primary.feed + "/members/" + state.primary.sort;
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "contributors"
      ) {
        url = "/" + state.primary.feed + "/members";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "drafts"
      ) {
        url = "/" + state.primary.feed + "/drafts";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "following" &&
        state.primary.sort
      ) {
        url = "/" + state.primary.feed + "/following/" + state.primary.sort;
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "following"
      ) {
        url = "/" + state.primary.feed + "/following";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "followers" &&
        state.primary.sort
      ) {
        url = "/" + state.primary.feed + "/followers/" + state.primary.sort;
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "followers"
      ) {
        url = "/" + state.primary.feed + "/followers";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "endorsements" &&
        state.primary.sort
      ) {
        url = "/" + state.primary.feed + "/endorsements/" + state.primary.sort;
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "endorsements"
      ) {
        url = "/" + state.primary.feed + "/endorsements";
      } else if (
        state.primary.target == "feed" &&
        state.primary.ui == "home" &&
        (state.primary.scope == "all" || state.primary.scope == "posts") &&
        state.primary.subfeed
      ) {
        url =
          "/" +
          homechar +
          "/" +
          state.primary.feed +
          "/posts/" +
          state.primary.subfeed;
      } else if (
        state.primary.target == "feed" &&
        state.primary.ui == "home" &&
        state.primary.scope == "all"
      ) {
        state.primary.subfeed = "featured";
        url = "/" + homechar + "/" + state.primary.feed + "/posts";
      } else if (
        state.primary.target == "feed" &&
        state.primary.ui == "home" &&
        state.primary.scope == "posts"
      ) {
        state.primary.subfeed = "featured";
        url = "/" + homechar + "/" + state.primary.feed + "/posts";
      } else if (state.primary.target == "feed" && state.primary.ui == "home") {
        state.primary.scope = "posts";
        state.primary.subfeed = "featured";
        url = "/" + homechar + "/" + state.primary.feed + "/posts";
      } else if (
        state.primary.target == "feed" &&
        (state.primary.scope == "all" || state.primary.scope == "posts") &&
        state.primary.subfeed
      ) {
        url = "/" + state.primary.feed + "/posts/" + state.primary.subfeed;
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "all"
      ) {
        url = "/" + state.primary.feed + "/posts";
        state.primary.subfeed = "featured";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "posts"
      ) {
        url = "/" + state.primary.feed + "/posts";
        state.primary.subfeed = "featured";
      } else if (state.primary.target == "feed") {
        state.primary.scope = "posts";
        state.primary.subfeed = "featured";
        url = "/" + state.primary.feed + "/posts";
      } else if (
        state.primary.target == "tag" &&
        state.primary.scope == "followers" &&
        state.primary.sort
      ) {
        url = "/tags/" + state.primary.tag + "/followers/" + state.primary.sort;
      } else if (
        state.primary.target == "tag" &&
        state.primary.scope == "followers"
      ) {
        url = "/tags/" + state.primary.tag + "/followers";
      } else if (
        state.primary.target == "feed" &&
        state.primary.scope == "posts" &&
        state.primary.sort
      ) {
        url = "/tags/" + state.primary.tag + "/" + this.primary.sort;
      } else if (
        state.primary.target == "tag" &&
        state.primary.ui == "home" &&
        state.primary.scope == "posts" &&
        state.primary.sort
      ) {
        url =
          "/" +
          homechar +
          "/tags/" +
          state.primary.tag +
          "/posts/" +
          state.primary.sort;
      } else if (
        state.primary.target == "tag" &&
        state.primary.ui == "home" &&
        state.primary.scope == "posts"
      ) {
        url = "/" + homechar + "/tags/" + state.primary.tag + "/posts";
        state.primary.sort = "featured";
      } else if (state.primary.target == "tag" && state.primary.ui == "home") {
        state.primary.scope = "posts";
        state.primary.sort = "featured";
        url = "/" + homechar + "/tags/" + state.primary.tag;
      } else if (
        state.primary.target == "tag" &&
        state.primary.scope == "posts" &&
        state.primary.sort
      ) {
        url = "/tags/" + state.primary.tag + "/posts/" + state.primary.sort;
      } else if (
        state.primary.target == "tag" &&
        state.primary.scope == "posts"
      ) {
        state.primary.sort = "featured";
        url = "/tags/" + state.primary.tag + "/posts";
      } else if (state.primary.target == "tag") {
        state.primary.scope = "posts";
        state.primary.sort = "featured";
        url = "/tags/" + state.primary.tag;
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "posts" &&
        state.primary.sort
      ) {
        url = "/search/" + state.primary.term + "/posts/" + state.primary.sort;
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "posts"
      ) {
        url = "/search/" + state.primary.term + "/posts";
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "channels" &&
        state.primary.sort
      ) {
        url =
          "/search/" + state.primary.term + "/channels/" + state.primary.sort;
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "channels"
      ) {
        url = "/search/" + state.primary.term + "/channels";
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "users" &&
        state.primary.sort
      ) {
        url = "/search/" + state.primary.term + "/users/" + state.primary.sort;
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "users"
      ) {
        url = "/search/" + state.primary.term + "/users";
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "tags" &&
        state.primary.sort
      ) {
        url = "/search/" + state.primary.term + "/tags/" + state.primary.sort;
      } else if (
        state.primary.target == "search" &&
        state.primary.scope == "tags"
      ) {
        url = "/search/" + state.primary.term + "/tags";
      } else if (state.primary.target == "search") {
        state.primary.scope = "posts";
        url = "/search/" + state.primary.term;
      } else if (
        state.primary.target == "all" &&
        state.primary.scope == "posts"
      ) {
        url = "/";
      } else if (state.primary.target == "post") {
        url = "/" + state.primary.feed + "/" + state.primary.id;
      }
    } else if (state.editor && state.editor.open) {
      if (state.editor.post_id) {
        url = "/editor/post/" + state.editor.post_id;
      } else if (state.editor.draft_id) {
        url = "/editor/draft/" + state.editor.draft_id;
      } else if (state.editor.fieldset) {
        url = "/editor/" + state.editor.fieldset;
      } else {
        url = "/editor";
      }
    }

    return url;
  },

  // check the new url against the back/forward queue in store to see if we have a match
  checkForScrollPos: function (newState) {
    var newUrl = this.urlFor(newState);

    // if the url is on the history then we can use it's scroll
    // position, move to front of list
    // (reverse order - means back works better than forward)
    var history = TremrStorage.getSession("scrollpos");
    if (history && history[newUrl]) {
      return history[newUrl];
    } else {
      return false;
    }
  },

  // check for reload in current state and if present set it to false
  // and don't allow read from cache
  checkForReload: function () {
    let cache = Tremr.context.data.reload !== true;

    // immediately remove one layer of history if cache
    if (cache) {
      let history = TremrStorage.getSession("tremr_history") || [];
      history.pop();
      TremrStorage.setSession("tremr_history", history);
    }

    return cache;
  },

  // check editor on all navigation and provide a navigate callback
  checkEditor: function (callback) {
    Tremr.stores.editorStore.canNavigate(
      function () {
        callback();
      }.bind(this),
      function () {
        // cancelled callback - replace /editor URL
        if (this.tremrUrls && this.tremrUrls.length > 0) {
          Tremr.routes.updateUrl(this.tremrUrls.pop());
        } else {
          Tremr.routes.updateUrl("/editor");
        }
      }.bind(this)
    );
  },

  // show a post
  post: function (feed_id, post_id) {
    var cache = this.checkForReload();

    this.checkEditor(
      function () {
        var newState = {
          primary: {
            target: "post",
            feed: feed_id,
            id: post_id,
          },
          editor: {
            open: false,
          },
          cache: cache,
        };

        var scrollPos = this.checkForScrollPos(newState);
        if (scrollPos) {
          newState.primary.initialScrollPos = scrollPos;
        }

        newState = _.extend(newState, Tremr.sidebarDefaultState(newState));

        Tremr.context.batch(newState, ["reload"]);
      }.bind(this)
    );
  },

  // just show homepage AND pop-up the recover password dialog
  recoverpassword: function (token) {
    this.loadPrimary({
      target: "homepage-feed",
      scope: "all",
      sort: "latest",
      feed: Tremr.Theme.HomepageFeed,
    });

    // open popup
    var overlayData = {
      class: "Tremr.User.NewPassword",
      modal: true,
      props: {
        token: token,
      },
    };
    Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
  },

  // just show homepage AND pop-up editprofile
  editprofile: function () {
    this.loadPrimary({
      target: "homepage-feed",
      scope: "all",
      sort: "latest",
      feed: Tremr.Theme.HomepageFeed,
    });

    this.openPopupRequiresUser(function () {
      Tremr.dispatcher.message(this, "tremr:open:profile", "signin");
    });
  },

  // just show homepage AND pop-up signin
  signin: function () {
    this.loadPrimary({
      target: "homepage-feed",
      scope: "all",
      sort: "latest",
      feed: Tremr.Theme.HomepageFeed,
    });

    Tremr.dispatcher.message(this, "tremr:open:signin", "signin");
  },

  // just show homepage AND pop-up signup
  signup: function () {
    this.loadPrimary({
      target: "welcome",
    });
  },

  welcome: function () {
    this.loadPrimary({
      target: "welcome",
    });
  },

  // just show homepage AND pop-up request invitation
  requestInvite: function () {
    this.loadPrimary({
      target: "homepage-feed",
      scope: "all",
      sort: "latest",
      feed: Tremr.Theme.HomepageFeed,
    });

    Tremr.dispatcher.message(this, "tremr:open:requestinvite");
  },

  // just show homepage AND pop-up the accept invite (e.g. sign up)
  acceptInvite: function (token) {
    this.loadPrimary({
      target: "homepage-feed",
      scope: "all",
      sort: "latest",
      feed: Tremr.Theme.HomepageFeed,
    });

    // open popup
    var overlayData = {
      class: "Tremr.User.AcceptInvite",
      modal: true,
      props: {
        token: token,
      },
    };
    Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
  },

  homepage: function () {
    // delay until we have checked for a user
    if (Tremr.stores.userStore.isPendingUserCheck()) {
      _.delay(
        function () {
          this.homepage();
        }.bind(this),
        200
      );
      return;
    }

    // if signed-in then personal feed
    let user = Tremr.stores.userStore.getUser();
    if (user) {
      this.personalfeed();
      this.updateUrl("/", true);
    } else {
      this.loadPrimary({
        target: "homepage-feed",
        scope: "all",
        sort: "latest",
        feed: Tremr.Theme.HomepageFeed,
      });
    }
  },

  all: function () {
    this.loadPrimary({
      target: "all",
      scope: "all",
      sort: "latest",
    });
  },

  trending: function () {
    this.loadPrimary({
      target: "all",
      scope: "all",
      sort: "trending",
    });
  },

  recommended: function () {
    this.loadPrimary({
      target: "all",
      scope: "all",
      sort: "recommended",
    });
  },

  personalfeed: function () {
    this.loadPrimary({
      target: "homepage",
      scope: "all",
      sort: "latest",
    });
  },

  bookmarks: function () {
    this.loadPrimary({
      target: "bookmarks",
      scope: "all",
      sort: "latest",
    });
  },

  sortedInbox: function (sort) {
    this.loadPrimary({
      target: "inbox",
      scope: "posts",
      sort: sort,
    });
  },

  inbox: function () {
    this.loadPrimary({
      target: "inbox",
      scope: "posts",
      sort: "latest",
    });
  },

  tagPostsSort: function (id, sort) {
    this.loadPrimary({
      target: "tag",
      scope: "posts",
      tag: id,
      sort: sort,
    });
  },

  tagPosts: function (id) {
    this.tagPostsSort(id, "featured");
  },

  home_tagPostsSort: function (id, sort) {
    this.loadPrimary({
      target: "tag",
      scope: "posts",
      tag: id,
      sort: sort,
      ui: "home",
    });
  },

  home_tagPosts: function (id) {
    this.home_tagPostsSort(id, "featured");
  },

  tagSortedFollowers: function (id, sort) {
    this.loadPrimary({
      target: "tag",
      scope: "followers",
      sort: sort,
      tag: id,
    });
  },

  tagFollowers: function (id) {
    this.loadPrimary({
      target: "tag",
      scope: "followers",
      sort: "latest",
      tag: id,
    });
  },

  tag: function (id) {
    this.tagPostsSort(id, "featured");
  },

  home_tag: function (id) {
    this.home_tagPostsSort(id, "featured");
  },

  feedSortedChannels: function (feed_id, sort) {
    this.loadPrimary({
      target: "feed",
      scope: "channels",
      sort: sort,
      feed: feed_id,
    });
  },

  feedChannels: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "channels",
      sort: "latest",
      feed: feed_id,
    });
  },

  feedSortedContributors: function (feed_id, sort) {
    this.loadPrimary({
      target: "feed",
      scope: "contributors",
      sort: sort,
      feed: feed_id,
    });
  },

  feedContributors: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "contributors",
      sort: "oldest",
      feed: feed_id,
    });
  },

  feedSortedFollowing: function (feed_id, sort) {
    this.loadPrimary({
      target: "feed",
      scope: "following",
      sort: sort,
      feed: feed_id,
    });
  },

  feedFollowing: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "following",
      sort: "latest",
      feed: feed_id,
    });
  },

  feedSortedFollowers: function (feed_id, sort) {
    this.loadPrimary({
      target: "feed",
      scope: "followers",
      feed: feed_id,
      sort: sort,
    });
  },

  feedFollowers: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "followers",
      feed: feed_id,
      sort: "latest",
    });
  },

  feedSortedEndorsements: function (feed_id, sort) {
    this.loadPrimary({
      target: "feed",
      scope: "endorsements",
      feed: feed_id,
      sort: sort,
    });
  },

  feedEndorsements: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "endorsements",
      feed: feed_id,
      sort: "latest",
    });
  },

  feedPosts: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "posts",
      feed: feed_id,
    });
  },

  subfeedPosts: function (feed_id, subfeed) {
    this.loadPrimary({
      target: "feed",
      scope: "posts",
      feed: feed_id,
      subfeed: subfeed,
    });
  },

  feedDrafts: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "drafts",
      feed: feed_id,
    });
  },

  feed: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "posts",
      feed: feed_id,
    });
  },

  // default feed posts to the featured subfeed
  featuredPosts: function (feed_id) {
    this.subfeedPosts(feed_id, "featured");
  },

  home_feedPosts: function (feed_id) {
    this.loadPrimary({
      target: "feed",
      scope: "posts",
      feed: feed_id,
      ui: "home",
    });
  },

  home_subfeedPosts: function (feed_id, subfeed) {
    this.loadPrimary({
      target: "feed",
      scope: "posts",
      feed: feed_id,
      subfeed: subfeed,
      ui: "home",
    });
  },

  // default feed posts to the featured subfeed
  home_feed: function (id) {
    this.home_subfeedPosts(id, "featured");
  },

  openPopupRequiresUser: function (callback) {
    // wait until we have checked for a user
    if (Tremr.stores.userStore.isPendingUserCheck()) {
      _.delay(
        function () {
          this.openPopupRequiresUser(callback);
        }.bind(this),
        200
      );
    } else {
      callback();
    }
  },

  notification_settings: function () {
    // user allowed nav
    this.loadPrimary({
      target: "homepage-feed",
      scope: "all",
      sort: "latest",
      feed: Tremr.Theme.HomepageFeed,
    });

    this.openPopupRequiresUser(function () {
      Tremr.dispatcher.message(this, "tremr:open:notificationsettings", false);
    });
  },

  editor: function () {
    this.openPopupRequiresUser(function () {
      // show sidebars and fire event to open editor
      var newState = Tremr.sidebarDefaultState({});
      Tremr.context.batch(newState, []);

      Tremr.dispatcher.message(this, "tremr:editor:open", false);
    });
  },

  channelInvite: function (feed_id, action, invite) {
    this.loadPrimary({
      target: "feed",
      scope: "all",
      feed: feed_id,
    });

    this.openPopupRequiresUser(function () {
      Tremr.dispatcher.message(this, "tremr:feed:channelinvite", {
        feed_id: feed_id,
        invite: invite,
        action: action,
      });
    });
  },

  editorDraft: function (draft_id) {
    this.openPopupRequiresUser(function () {
      // show sidebars and fire event to open editor
      var newState = Tremr.sidebarDefaultState({});
      Tremr.context.batch(newState, []);

      Tremr.dispatcher.message(this, "tremr:editor:open", {
        draft_id: draft_id,
      });
    });
  },

  editorPost: function (post_id) {
    this.openPopupRequiresUser(function () {
      // show sidebars and fire event to open editor
      var newState = Tremr.sidebarDefaultState({});
      Tremr.context.batch(newState, []);

      Tremr.dispatcher.message(this, "tremr:editor:open", {
        post_id: post_id,
      });
    });
  },

  editorFieldset: function (fieldset) {
    this.openPopupRequiresUser(function () {
      // show sidebars and fire event to open editor
      var newState = Tremr.sidebarDefaultState({});
      Tremr.context.batch(newState, []);

      Tremr.dispatcher.message(this, "tremr:editor:open", {
        fieldset: fieldset,
      });
    });
  },

  loadPrimary: function (context) {
    this.checkEditor(
      function () {
        var cache = this.checkForReload();

        // state changes as a batch of removes and a json update
        var removeState = ["reload"];
        var newState = {
          primary: context,
          editor: {
            open: false,
          },
          cache: cache,
        };

        var scrollPos = this.checkForScrollPos(newState);
        if (scrollPos) {
          newState.primary.initialScrollPos = scrollPos;
        }

        newState = _.extend(newState, Tremr.sidebarDefaultState(newState));
        Tremr.context.batch(newState, removeState);
      }.bind(this)
    );
  },

  searchSortedPosts: function (term, sort) {
    this.loadPrimary({
      target: "search",
      scope: "posts",
      sort: sort,
      term: term,
    });
  },

  searchSortedUsers: function (term, sort) {
    this.loadPrimary({
      target: "search",
      scope: "users",
      sort: sort,
      term: term,
    });
  },

  searchSortedTags: function (term, sort) {
    this.loadPrimary({
      target: "search",
      scope: "tags",
      sort: sort,
      term: term,
    });
  },

  searchSortedChannels: function (term, sort) {
    this.loadPrimary({
      target: "search",
      scope: "channels",
      sort: sort,
      term: term,
    });
  },

  searchPosts: function (term) {
    this.loadPrimary({
      target: "search",
      scope: "posts",
      sort: "relevance",
      term: term,
    });
  },

  searchUsers: function (term) {
    this.loadPrimary({
      target: "search",
      scope: "users",
      term: term,
    });
  },

  searchTags: function (term) {
    this.loadPrimary({
      target: "search",
      scope: "tags",
      term: term,
    });
  },

  searchChannels: function (term) {
    this.loadPrimary({
      target: "search",
      scope: "channels",
      term: term,
    });
  },
});
