var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// an item that can be rendered with a loadedCallback but will not be displayed
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

    propTypes: {
        loadedCallback: PropTypes.func.isRequired
    },

    componentDidMount: function() {
        _.defer(() => {
            this.props.loadedCallback();
        });
	},

	render: function() {
        return <div className="generic-skipped-list-item" style={{display: "none"}}></div>;
	}
});
