var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// rich text editor block
// Tremr.Editor.TextControl
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      identifier: PropTypes.string.isRequired,
      plainText: PropTypes.array, // array of chars
      formatRules: PropTypes.array,
      notifyPosition: PropTypes.func.isRequired, // notify parent of position
      tag: PropTypes.string,
      readOnly: PropTypes.bool,
      isFirst: PropTypes.bool
  },

  lastBounds: false, // keep track of bounds so we only notify if actually changed

  getDefaultProps: function() {

    return {
      plainText: [],
      formatRules: [],
      tag: 'p',
      readOnly: false,
      isFirst: false
    }
  },

  componentDidMount: function() {
    this.notifyPosition();
  },

  componentDidUpdate: function() {
    this.notifyPosition();
  },

  // set focus to this element (if it can)
  focus: function() {
    var domNode = ReactDOM.findDOMNode(this);
    domNode.focus();
  },

  // notify parent of it's position
  notifyPosition: function() {

    if (!this.props.readOnly) {
      var node = ReactDOM.findDOMNode(this);
      var bounds = node.getBoundingClientRect();
      if (bounds.top != this.lastBounds.top || bounds.height != this.lastBounds.height) {
        if (this.props.notifyPosition(this.props.identifier, bounds, (bounds.height != this.lastBounds.height), this)) {
          this.lastBounds = bounds;
        }
      }
    } else if (!this.props.readOnly) {
      _.delay(_.throttle(this.notifyPosition, 1000), 200);
    }
  },

  // turn the plaintext and rules into markup
  getMarkup: function() {

    var formats = ['A', 'EM', 'STRONG'];

    var markup = '';

    // create array containing opening and closing tags, ordered by position and tag
    var tags = _.flatten(_.map(this.props.formatRules, function(rule) {

      // create array - one opening and one closing tag
      return [
        {
          tag: rule.format.toLowerCase(),
          position: rule.start,
          opening: true,
          order: [rule.start, formats.indexOf(rule.format)],
          rule: rule
        },
        {
          tag: rule.format.toLowerCase(),
          position: rule.end,
          opening: false,
          order: [rule.end, ((formats.length + 1) - formats.indexOf(rule.format))],
          rule: rule
        }
      ];

    }), true);

    // order by position and tag
    tags = tags.sort(function(a, b) {
      if (a.order[0] == b.order[0]) {
        return a.order[1] - b.order[1]
      } else {
        return a.order[0] - b.order[0];
      }
    });

    // iterate tags placing plainText up until the position,
    // then placing the tag and moving to the next one.
    var lastPos = 0;
    _.each(tags, function(tag) {

      // insert plainText up to the tag
      if (tag.position > lastPos) {
        // markup += this.props.plainText.slice(lastPos, tag.position);
        if (this.props.plainText && this.props.plainText.length > 0) {
          markup += this.props.plainText.slice(lastPos, tag.position).join('');
        }
      }

      // remember the new postion
      lastPos = tag.position;

      // insert tag (open/close) - special case for anchor href
      if (tag.opening && tag.tag == 'a') {
        markup += '<' + tag.tag + ' target="_blank" href="' + tag.rule.href + '">';
      } else if (tag.opening) {
        markup += '<' + tag.tag + '>';
      } else {
        markup += '</' + tag.tag + '>';
      }

    }.bind(this));

    // add any remaining plainText
    if (this.props.plainText && lastPos < this.props.plainText.length) {
        // markup += this.props.plainText.slice(lastPos);
        markup += this.props.plainText.slice(lastPos).join('');
      }

    // if we have no content AND we are the first block then show a placeholder
    if (markup == '' && this.props.isFirst && !this.props.readOnly) {
      markup = '<span class="placeholder" contentEditable="false">Enter some content...</span>';
    } else if (markup == '' && (Tremr.Utils.checkForFirefox() || Tremr.Utils.checkForSafari())) {
      // if we have zero content then put in a placeholder for FF (placeholder :before block solves this)
      markup = '<br />';
    }

    return markup;
  },

  render: function() {

    var classes = classNames({
        'block': true,
        'text-block': true
    });

    return (
      React.createElement(this.props.tag, {className: classes, 'data-id': this.props.identifier, dangerouslySetInnerHTML: { __html: this.getMarkup() } })
    );
  }
});
