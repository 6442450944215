var Modernizr = require("!webpack-modernizr-loader!../plugins/.modernizrrc");
var Cookies = require('js-cookie/src/js.cookie');

// Util for saving to local/session store falling back on cookies
module.exports = {

	// get and sets

	setLocal: function(key, value) {
		if (Modernizr.localstorage) {
            try {
				return localStorage.setItem(key, JSON.stringify(value));
			} catch (e) {}
		} else {
			var jsonValue = JSON.stringify(value);
			if (jsonValue.length <= 255) {
				Cookies.set(key, jsonValue, { expires: 1 });
				return true;
			}
		}
		return false;
	},

	getLocal: function(key) {
		if (Modernizr.localstorage) {
            try {
				var data = localStorage.getItem(key);
				return data ? JSON.parse(data): false;
			} catch (e) {}
		} else {
			var data = Cookies.get(key);
			return data ? JSON.parse(data): false;
		}
		return false;
	},

	setSession: function(key, value) {
		if (Modernizr.sessionstorage) {
		    try {
        		return sessionStorage.setItem(key, JSON.stringify(value));
			} catch (e) {}
		} else {
			var jsonValue = JSON.stringify(value);
			if (jsonValue.length <= 255) {
				Cookies.set(key, jsonValue, { expires: 1 });
				return true;
			}
		}
		return false;
	},

	getSession: function(key) {
		if (Modernizr.sessionstorage) {
            try {
				var data = sessionStorage.getItem(key);
				return data ? JSON.parse(data): false;
			} catch (e) {}
		} else {
			var data = Cookies.get(key);
			return data ? JSON.parse(data): false;
		}
		return false;
	},

	// stack implementation

	// pushLocal: function(key, value) {
	// 	var stack = this.getLocal(key);
	// 	var val = JSON.stringify(value);
	// 	stack ? stack.push(val) : stack = [val];
	// },

	// popLocal: function(key) {
	// 	var stack = this.getLocal(key);
	// 	if (stack && stack.length > 0) {
	// 		var val = stack.pop();
	// 		this.setLocal(key, stack);
	// 		return val;
	// 	} else {
	// 		return false;
	// 	}
	// },

	// pushSession: function(key, value) {
	// 	var stack = this.getSession(key);
	// 	stack ? stack.push(value) : stack = [value];
	// 	this.setSession(key, stack);
	// },

	// popSession: function(key) {
	// 	var stack = this.getSession(key);
	// 	if (stack && stack.length > 0) {
	// 		var val = stack.pop();
	// 		this.setSession(key, stack);
	// 		return val;
	// 	} else {
	// 		return false;
	// 	}
	// },

}
