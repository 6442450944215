// class for managing a representation of an image upload and caption
// Tremr.Editor.UploadBlock
module.exports = function(identifier, type) {

	// local ref to utils
	var Ranges = window.Tremr.Utils.Ranges;
	var self = this;

	this.allowedFormat = false; // should we show the format popup

	// default empty attributes
	this._attributes = {
		identifier: false,
        type: false,
        captionText: [], // stored as array of chars
		image: false,
		inProgress: false,
		progress: 0
	};

	// constructor params
	if (identifier !== undefined) { this._attributes.identifier = identifier; }
	if (type !== undefined) { this._attributes.type = type; }

	// generate a react control
	this.getControl = function(notifyPosition, removeBlock, readOnly, width, height) {
		// ignore height - and allow auto but with maximum
		return <Tremr.Editor.UploadControl maxHeight={3000} height={false} width={width} readOnly={readOnly} ref={this.get('identifier')} key={this.get('identifier')+'-'+width} identifier={this.get('identifier')} image={this.get('image')} inProgress={this.get('inProgress')} progress={this.get('progress')} captionText={this.get('arrayText')} removeBlock={removeBlock} notifyPosition={notifyPosition} />;
	};

	// iterate child nodes then siblings counting length until we reach the
	// offset we want, then return the node and the offset in that node
	// but only count length from within the caption node
	this.findOffsetFromSubnode = function(node, offsetRemaining, inCaption) {

		if (inCaption === undefined) { inCaption = false; }

		var returnValue = {
		  found: false,
		  node: false,
		  offset: offsetRemaining
		};

		// recurse into child nodes
		if (node && node.nodeType == 1 && node.classList.contains('caption')) {

			inCaption = true;
		}

		if (node && node.nodeType == 1 && node.hasChildNodes()) {

		  _.each(node.childNodes, function(child) {

		    if (!returnValue.found) {

		      if (child.tagName && child.tagName.toLowerCase() == "br") {
		        returnValue.node = child;
		      } else {
		        returnValue = this.findOffsetFromSubnode(child, returnValue.offset, inCaption);
		      }
		    }

		  }.bind(this));

		} else if (inCaption) {

		  // see if the text length of this node contains the offset
		  if (node.textContent.length >= offsetRemaining) {
		    // found!
		    returnValue.found = true;
		    returnValue.node = node;
		    returnValue.offset = offsetRemaining;

		  } else {
		    // not found, reduce offset remaining and return
		    returnValue.offset -= node.textContent.length;
		  }

		}

		return returnValue;

	};


	this.updateFromNodes = function(nodes) {

		var parsed = this.parseNodes(nodes);

		// update the block and fix overlaps etc.
        this.set('captionText', parsed.plainText);
	};

	// only process the caption and take it's plain text
	this.parseNodes = function(nodes) {

	    var plainText = '';

	    _.each(nodes, function(node) {

	    	// only worry about the caption
	    	if (node.nodeType == 1) { // element

	        // start a format for this posi
	        if (node.classList.contains('caption')) {
	        	plainText = $(node).text();
	        }
	      }

	    }.bind(this));

	    return {
	      plainText: plainText,
	      formatRules: []
	    };
	  },

	// traverse previous siblings, then into parent and traverse it's prev siblings
    // until we reach .block
    this.getOffsetInBlock = function(node, newLevel) {

      // if we ever reach the block (or no node), stop counting immediately
      if (!node || (node.nodeType == 1 && node.classList.contains('block'))) {
        return 0;
      }

      // if we reach the caption then stop counting
	  if (node.nodeType == 1 && node.classList.contains('caption')) {
        return 0;
      }

      var len = 0; // keep length

      // if this ISN'T a new level add it's length
      if (!newLevel) {
        if (node.textContent && node.textContent.length) {
          len = node.textContent.length
        }
      }

      // recurse to previous OR parent (set new level for parent)
      if (node && node.previousSibling) {
        len += this.getOffsetInBlock(node.previousSibling, false);
      } else if (node && node.parentNode) {
        len += this.getOffsetInBlock(node.parentNode, true);
      }

      return len;
    };

	// create a copy, with new Ids
	this.clone = function(keepId) {

		var block = new Tremr.Editor.UploadBlock('cloned', 'cloned');
		block._attributes = {
			identifier: this._attributes.identifier,
	        type: this._attributes.type,
	        captionText: _.map(this._attributes.captionText, _.clone),
	        image: _.clone(this._attributes.image)
		};
		if (!keepId) {
			block._attributes.identifier = Tremr.Utils.uniqueId('block');
		}
		return block;
	};

	// get an attribute
	this.get = function(name) {

		// special case to get plainText as string (or arrayText for array)
		if (name == 'captionText') {
			if (this._attributes['captionText']) {
				return this._attributes['captionText'].join('');
			} else {
				return '';
			}
		} else if (name == 'arrayText') {
			if (this._attributes['captionText']) {
				return this._attributes['captionText'];
			} else {
				return [];
			}
		}

		return this._attributes[name];
	};

	// set an attribute
	this.set = function(key, val) {

		// special case to turn string plainText into array of chars
		if (key == 'captionText') {
			if (_.isString(val)) {
				val = this.createCharArray(val);
			}
		}

		this._attributes[key] = val;
	};

	// create an array of characters and entities from string
	this.createCharArray = function(val) {

		var charArray = [];
		var encoded = Tremr.Utils.htmlEncode(val); // encode html entities

		var newString = encoded.replace(/([^&]*)(&\w+;)?([^&;]*)/gi, function(match, p1, p2, p3, offset, string) {

			var p1Array = [];
			var p2Array = [];
			var p3Array = [];

			if (p1 !== undefined && p1 !== '') { p1Array = p1.split(''); }
			if (p2 !== undefined && p2 !== '') { p2Array = [p2]; } // keep html entities intact (and one element in array)
			if (p3 !== undefined && p3 !== '') { p3Array = p3.split(''); }

	        charArray = charArray.concat(p1Array, p2Array, p3Array);

	    });

	    // for FF we need a trailing space to be a &nbsp; but why not
	    // convert non-trailing &nbsp; to normal spaces while we can
	    charArray = _.map(charArray, function(item, index) {
	    	return (item == '&nbsp;') ? ' ' : item;
	    });
		if (_.last(charArray) == ' ') {
			charArray[charArray.length-1] = '&nbsp;';
		}

		return charArray;
	};

	// length of the plainText array
	this.length = function() {
		return this._attributes.captionText.length;
	};

	// remove a range of characters from the captionText
	this.removeCharacters = function(start, end) {

		// remove the characters from the array
		this._attributes.captionText.splice(start, (end - start));

	};

	// remove x characters from the captionText and remove/adjust rules to fit
	this.removeCharactersLeft = function(index) {

		this.removeCharacters(0, index);
	};

	// remove x characters from the captionText and remove/adjust rules to fit
	this.removeCharactersRight = function(index) {

		this.removeCharacters(index, this.length());
	};

	// add content and rules from another block to the end of this one
	this.append = function(block) {

		var adjustRule = this.length();

		this._attributes.captionText = this._attributes.captionText.concat(block.get('arrayText'));
	};

	// split this block at the specified position in the captionText, return two new
	// blocks with adjusted format rules - the second one will always be a textblock
	this.splitAt = function(leftIndex, rightIndex) {

		if (rightIndex === undefined) { rightIndex = leftIndex; }

		// create two new blocks, clones of this one
		var before = this.clone(false);
		var after = new Tremr.Editor.TextBlock('cloned', 'cloned');

		after._attributes = {
			identifier: Tremr.Utils.uniqueId('block'),
	        type: 'text',
	        plainText: _.map(this._attributes.captionText, _.clone)
		};

		// reduce characters from left/right from each
		before.removeCharactersRight(leftIndex);
		after.removeCharactersLeft(rightIndex);

		return [before, after];
	};

	// ignore set format requests
	this.setFormat = function(format, start, end, additional) {
	};

	// ignore set format requests
	this.unsetFormat = function(format, start, end) {
	};

	// never has a format
	this.hasFormat = function(format, position) {
		return false;
	};

	// never has a format
	this.containsFormat = function(format, start, end) {
		return false;
	}


}
