var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.Post
module.exports = Backbone.Model.extend({
    urlRoot: Config.apiurl+'/api/posts'





});

