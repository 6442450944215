require("./scss/menu.scss");

var classNames = require("../../utils/classnames");
var PopUp = require("../generic/popup");
var Subfeeds = require("./subfeeds");
var TremrUtils = require("../../utils/tremr_utils");
var SvgIcon = require("../generic/svgicon");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var UserAwareMixin = require("../../mixins/userawaremixin");

// Tremr.Follow.Button
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  propTypes: {
    subfeeds: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
  },

  getDefaultProps: function () {
    return {
      subfeeds: [],
      responsive: false,
      title: null,
    };
  },

  getInitialState: function () {
    let setSubfeeds = ["featured"]; // default

    return {
      subfeeds: this.props.subfeeds.slice(),
      selectedSubfeeds: setSubfeeds, // loaded by listening for event - which is triggered by feed header/wall etc. Urgh!
      userSet: false,
      selectedSubfeedsUpdate: 0,
    };
  },

  // listen for user updating this elsewhere
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    // listen for data
    this.listenTo(
      Tremr.dispatcher,
      "tremr:follow:" +
        this.props.entity +
        ":" +
        this.props.id.replace(/\W+/g, "_"),
      function (subfeeds) {
        if (this.props.entity == "tag") {
          this.setState({
            selectedSubfeeds: [],
            selectedSubfeedsUpdate: TremrUtils.seededRandom(
              999,
              99,
              "follow_button"
            ),
            userSet: subfeeds,
          });
        } else {
          this.setState({
            selectedSubfeeds: subfeeds,
            selectedSubfeedsUpdate: TremrUtils.seededRandom(
              999,
              99,
              "follow_button"
            ),
            userSet: subfeeds && subfeeds.length > 0,
          });
        }
      }.bind(this)
    );

    // request update on whether the user is following or not
    Tremr.stores.feedStore.requestFollowingForEntity([
      {
        name: this.props.id,
      },
    ]);
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.stopListening();
  },

  changeSubfeeds: function (subfeeds) {
    this.setState({
      selectedSubfeeds: subfeeds,
      selectedSubfeedsUpdate:
        this.state.selectedSubfeedsUpdate +
        TremrUtils.seededRandom(999, 99, "follow_button"),
    });
  },

  save: function (identifier) {
    var data = {
      id: this.props.id,
      entity: this.props.entity,
      subfeeds: this.state.selectedSubfeeds,
    };

    this.setState({
      open: false,
    });

    Tremr.dispatcher.message(this, "tremr:follow", data);

    this.props.onClose();
  },

  unfollow: function (event) {
    event.preventDefault();
    event.stopPropagation();

    var data = {
      id: this.props.id,
      entity: this.props.entity,
      subfeeds: [], // empty subfeeds = unfollow
    };

    this.setState({
      open: false,
    });

    Tremr.dispatcher.message(this, "tremr:follow", data);

    this.props.onClose();
  },

  // display
  render: function () {
    // see if we have loaded the image or not
    var classes = {
      "follow-menu": true,
      set: this.state.userSet,
    };
    classes[this.props.entity] = true;
    classes = classNames(classes);

    let cancelButton = null;
    if (this.state.userSet) {
      cancelButton = (
        <div className="button cancel" onClick={this.unfollow}>
          Unfollow
        </div>
      );
    }

    return (
      <div className="follow-menu-container sidebar">
        <div className="follow-menu-toolbar toolbar">
          <div className="row">
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
            {cancelButton}
            <a className="button save" onClick={this.save}>
              Confirm
            </a>
          </div>
          <div className="row entity">{this.props.title}</div>
        </div>
        <div className="controls">
          <Subfeeds
            key={"subfeeds-" + this.state.selectedSubfeedsUpdate}
            selectedSubfeeds={this.state.selectedSubfeeds}
            onChange={this.changeSubfeeds}
            subfeeds={this.state.subfeeds}
          />
        </div>
      </div>
    );
  },
});
