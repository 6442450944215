require("./scss/favbar.scss");

var classNames = require('../../utils/classnames');
var UserAwareMixin = require('../../mixins/userawaremixin');
var Cookies = require('js-cookie/src/js.cookie');
var RegionToggle = require('../layout/regiontoggle');
var CreateReactClass = require('create-react-class');
var HorizontalScroller = require('../generic/horizontalscroller');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

	getInitialState: function() {
		return {
			initial: true
		};
	},

	loadMore: function() {

		this.refs['favbar-content'].loadMore();
	},

	componentDidMount: function() {

		_.delay(function() {

			this.setState({
				initial: false
			});
		}.bind(this), 1000);

    },

    handleScroll: function(left, viewport, width) {
        // console.log(arguments);
        // let node = ReactDOM.findDOMNode(this);
        if (left + width + 100 > viewport) {
            this.loadMore();
        }
    },

    homepage: function(event) {

		// if the meta key is pressed, ignore
     if (event.metaKey) { return; }

        // if we are already on the top picks then allow page refresh
        if (!this.props.current && this.props.primary && this.props.primary.target == 'homepage-feed') {
        	return;
        }

		event.stopPropagation();
		event.preventDefault();

		Tremr.navigatePrimary({
	        target: 'homepage-feed',
	        scope: 'all',
	        feed: Tremr.Theme.HomepageFeed,
	        sort: 'latest'
		});

	},

	render: function() {

		if (!this.state.user) {
			return null;
		}

		// container classes
		var classes = {
			'layout-favbar': true
		};
		classes = classNames(classes);

		// figure out what to set as active - this is passed down to content views because
		// they used to livein this class before we had tabs, but feel it is better to keep
		// this logic in one place to avoid accidentally showing two links as active etc.
		var active = false;
        if (!this.props.current && this.props.primary && this.props.primary.target == 'bookmarks') {
			active = 'bookmarks';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'inbox') {
			active = 'inbox';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'homepage-feed') {
			active = 'homepage';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'homepage') {
			active = 'homepage';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'all') {
			active = 'homepage';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'feed' &&
				   this.state.user && this.props.primary.feed == this.state.user.get('feedname')) {
			active = 'userfeed';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'feed' && this.props.primary.subfeed) {
			// active = 'feed-'+this.props.primary.feed+'-'+this.props.primary.subfeed;
            active = 'feed-'+this.props.primary.feed+'-featured';
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'feed') {
			active = 'feed-'+this.props.primary.feed;
		} else if (!this.props.current && this.props.primary && this.props.primary.target == 'tag') {
			active = 'tag-'+this.props.primary.tag;
		}

        // figure out if we're on the homepage (or uk homepage) and show the toggle
		var regionToggle = null;
		// if (!this.props.current && this.props.primary &&
		// 	(this.props.primary.target == 'homepage-feed' ||
		// 	this.props.primary.target == 'ushomepagefeed' ||
		// 	this.props.primary.target == 'ukhomepagefeed')) {

		// 	var activeRegion = 'US';
		// 	var ukregions = ['GG', 'JE', 'IM', 'GI', 'GB'];
	 //        if (_.contains(ukregions, Cookies.get('region'))) {
		// 		activeRegion = 'GB';
	 //        }
	 //        regionToggle = <RegionToggle initialRegion={activeRegion} />;
	 //    }

		return (
			<nav className={classes}>

                <hr />

				<HorizontalScroller childSize={58} onScroll={this.handleScroll}>

					<Tremr.Layout.Homemenu ref="favbar-content" active={active} />

			    	{regionToggle}

		    	</HorizontalScroller>

			</nav>
		);
	}
});
