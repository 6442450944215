var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');

// a link anchor tag that can have an active class, intended for tabs
// containing extra decoration for a number to be contained within it
// Tremr.Generic.StatTab = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultProps: function() {
		return {
			data: 0
		}
	},

	handleClick: function(event) {
		if (this.props.handleClick) {
			event.preventDefault();
			event.stopPropagation();
			this.props.handleClick(event, this.props.value);
		} else if (this.props.href == undefined) {
			event.preventDefault();
			event.stopPropagation();
		}
	},

	render: function() {

		var classes = classNames({
			'active': this.props.active
		});

		var href = '#';
	    if (this.props.href) {
	      href = this.props.href;
	    }

	    var data = this.props.data;
	    if (isNaN(data)) {
	    	data = 0;
	    }

		return (
			<a href={href} onClick={this.handleClick} className={classes} data-value={this.props.value}>
				<label htmlFor={this.props.value}>{this.props.label}</label>
				<output name={this.props.value}>{data}</output>
			</a>
		);
	}
});
