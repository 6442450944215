var CreateReactClass = require('create-react-class');
var Alert = require('./alert');
var Share = require('../generic/share');
var SocialLink = require('../user/sociallink');
var Theme = require("theme");
var Cookies = require('js-cookie/src/js.cookie');

module.exports = CreateReactClass({

	getInitialState: function() {
		return {
        alerts: []
		};
	},

	// listen for events we want to create alerts from
	componentDidMount: function() {

		if (!this.stopListening) { _.extend(this, Backbone.Events); }

		// prompt to connect to twitter after login
		// this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function(model) {
		//
		// 	let alertIndex = 99; // only ever write one - so hardcoded index
		//
		// 	let alerts = this.state.alerts;
		//
		// 	if (Theme.RenderSocialLink && !model.get('social')) {
		//
		// 			if (Cookies.get('has_closed_social_link') != '1') {
		// 				let alert = <Alert key={"alert-"+alertIndex} index={alertIndex} onClose={function(index) {
		// 						Cookies.set('has_closed_social_link', '1', {expires: 365});
		// 						this.closeAlert(index);
		// 				}.bind(this)}>
		// 					<div><span>Connect your twitter account, there are some compelling reasons for this.</span>
		// 						<SocialLink user={model} />
		// 					</div>
		// 				</Alert>;
		//
		// 				alerts[alertIndex] = alert;
		// 				this.setState({
		// 					alerts: alerts
		// 				});
		// 			}
		//
		// 	} else if (alerts[alertIndex]) {
		//
		// 		delete alerts[alertIndex];
		// 		this.setState({
		// 			alerts: alerts
		// 		});
		// 	}
		//
		// }.bind(this));

		// prompt to share post after publish post
		// this.listenTo(Tremr.dispatcher, "tremr:post:save:success", function(model) {
		//
		// 	if (model.get('draft') == '1') {
		// 		return;
		// 	}
		//
		// 	let route = Tremr.stores.postStore.route(model);
		// 	let url = Tremr.routes.urlFor({primary: route});
		// 	let imageUrl = null;
		// 	if (model.get('image')) {
		// 		imageUrl = model.get('image').secure_url;
		// 	} else if (model.get('embed')) {
		// 		if (model.get('embed').thumbnail_url) {
		// 			imageUrl = model.get('embed').thumbnail_url;
		// 		} else {
		// 			imageUrl = model.get('embed').original_url;
		// 		}
		// 	}
		//
		// 	let alerts = this.state.alerts;
		// 	let alertIndex = alerts.length;
		// 	let alert = <Alert key={"alert-"+alertIndex} index={alertIndex} onClose={this.closeAlert}>
		// 		<p><span>Why not share your post on Social Media?</span>
		// 		<Share imageUrl={imageUrl} url={url} title={model.get('title')
		// 	? model.get('title').capitalize().replace(/&[^;]{2,3,4};/g, ' ')
		// 	: ''} description={model.get('summary')
		// 	? model.get('summary').replace(/&[^;]{2,3,4};/g, ' ')
		// 	: ''}/>
		// 		</p>
		// 	</Alert>;
		//
		// 	alerts[alertIndex] = alert;
		// 	this.setState({
		// 		alerts: alerts
		// 	});
		//
		// }.bind(this));

	},

	closeAlert: function(index) {

		let alerts = this.state.alerts;
		alerts.splice(index, 1);
		this.setState({
			alerts: alerts
		});
	},

	// undind events
	componentWillUnmount: function() {

		if (this.stopListening) {
      this.stopListening();
    }
	},

  render: function() {

		return <span>
			{this.state.alerts}
		</span>;
	}
});
