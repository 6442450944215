require("./scss/toolbar_sticky.scss");

var CreateReactClass = require('create-react-class');
var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

    getInitialState: function() {
      return {
        stricky: false
      };
    },

	// set/unset sticky class based on position
	checkSticky: function() {
		let node = ReactDOM.findDOMNode(this);
		if (node) {
			let rect = node.getBoundingClientRect();
			if (rect.top == 0 && !this.state.sticky) {
				this.setState({sticky: true});
			} else if (rect.top > 0 && this.state.sticky) {
				this.setState({sticky: false});
			}
		}
	},

	componentDidMount: function() {
		window.addEventListener("scroll", this.checkSticky);
		window.addEventListener("resize", this.checkSticky);
	},

	// clear the scroll event handler
	componentWillUnmount: function() {
		window.removeEventListener("scroll", this.checkSticky);
		window.removeEventListener("resize", this.checkSticky);
    },

	render: function() {

		let classes = {
			sticky: this.state.sticky
		};
		classes['layout-toolbar-sticky'] = true;
		classes = classNames(classes);

		return (
			<div className={classes}>
                {this.props.children}
			</div>
		);
	}
});
