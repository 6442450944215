var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var TremrUtils = require('../../utils/tremr_utils');

// stateful filter capable of receiving wall tab change events and updating itself
// Tremr.Layout.Filter = CreateReactClass({
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    tabs: PropTypes.array.isRequired,
    initialTab: PropTypes.string.isRequired,
    wallIdentifier: PropTypes.string.isRequired, // events from this wall should update our activeTab
    icons: PropTypes.bool,
    tipPrefix: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      icons: false,
      tipPrefix: ''
    };
  },

  getInitialState: function() {
    return {
      activeTab: this.props.initialTab
    }
  },

  // fire event to tell the wall to switch tab
  switchTab: function(event) {

    var newTab = $(event.currentTarget).attr('data-value');

    let currentUrl = TremrUtils.parseUrl();
    currentUrl.params.subtab = newTab;
    let newUrl = TremrUtils.createUrlString(currentUrl);
    Tremr.routes.updateUrl(newUrl, true);

    Tremr.dispatcher.message(this, "tremr:filter:sort:"+this.props.wallIdentifier, newTab);
    this.setState({
        activeTab: newTab
    });
  },

  // listen for updated to the walls activeTab (could be from this
  // components switchTab method or directly from the wall)
  componentDidMount: function() {

    _.extend(this, Backbone.Events);

    this.listenTo(Tremr.dispatcher, "tremr:filter:sort:"+this.props.wallIdentifier, function(tab) {
      this.setState({
        activeTab: tab
      });
    }.bind(this));
  },

  // unregister listeners
  componentWillUnmount: function() {
      if (this.stopListening) {
        this.stopListening();
      }
  },


  render: function() {

    return (
        <nav className="layout-filter">
        {this.props.tabs.map(function(tab, index) {
            var text = tab;
            var tip = this.props.tipPrefix+tab;
            if (tab == 'Chronological') {
              tip =  this.props.tipPrefix+'Oldest and Expanded';
              text = 'Threaded';
            }
            if (this.props.icons) {
              text = <Tremr.Generic.SvgIcon icon={tab.toLowerCase().replace(/\s+/, '-')} />;
            }
            return <Tremr.Generic.Tab tip={tip} handleClick={this.switchTab} key={index} value={tab} text={text} active={(this.state.activeTab == tab)} />;
        }.bind(this))}
        </nav>
    );
  }

});
