var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// div that can have error message surrounding any child components
// Tremr.Generic.Errorfield
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      error: PropTypes.bool,
      message: PropTypes.string,
      fieldName: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      error: false,
      message: ''
    };
  },

  render: function() {

    var classes = {
      field: true,
      error: this.props.error
    };
    if (this.props.fieldName) {
      classes[this.props.fieldName] = true;
    }
    classes = classNames(classes);

  	return (

    	<div className={classes} data-message={this.props.message}>
          {this.props.children}
      </div>
    );
  }
});
