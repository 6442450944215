var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');

// Tremr.User.Followingmenu = CreateReactClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin, UserAwareMixin],

    loadMore: function() {

        if (this.state.user && this.refs['sidebarFollowingWall']) {
            this.refs['sidebarFollowingWall'].loadMore();
        }
    },

    // listen to user changes in following and refresh the list
    componentDidMount: function() {

        if (!this.stopListening) {
            _.extend(this, Backbone.Events);
        }

        this.listenTo(Tremr.dispatcher, "tremr:follow:change", function(data) {
            if (this.refs['sidebarFollowingWall'] && this.refs['sidebarFollowingWall'].delete) {

                // always remove existing
                this.refs['sidebarFollowingWall'].delete(data.id);

                // add each subfeed back in
                if (data.subfeeds) {
                    _.each(data.subfeeds, function(subfeed) {
                        if (subfeed == 'all-posts') {
                            this.refs['sidebarFollowingWall'].inject(data.model);
                        } else {
                            let subfeedModel = data.model.clone();
                            subfeedModel.set('followedsubfeed', subfeed);
                            subfeedModel.set('id', subfeedModel.get('id') + "!" + subfeed);
                            this.refs['sidebarFollowingWall'].inject(subfeedModel);
                        }

                    }.bind(this));
                }
            }

        }.bind(this));
    },

    // unregister listeners
    componentWillUnmount: function() {

        if (this.stopListening) {
            this.stopListening();
        }
    },

    render: function() {

        // hide the whole view if the user isn't signed-in
        if (!this.state.user || Tremr.stores.userStore.isPendingUserCheck()) {
            return null;
        }

        var wall = Tremr.stores.userStore.getSidebarFollowingContent(this.state.user, this.props.active, false);

        return (
            <nav className="layout-followingmenu">
            {wall}
            </nav>
        );
    }
});
