require("./scss/breadcrumbs.scss");
require("./scss/listcard.scss");
require("../layout/scss/dummycontent.scss");

var moment = require("moment");
var classNames = require("../../utils/classnames");
var TremrStorage = require("../../utils/tremr_storage");
var TremrUtils = require("../../utils/tremr_utils");
var CreateReactClass = require("create-react-class");
var PropTypes = require("prop-types");
var Breadcrumb = require("./breadcrumb");

// displays a summary of a posts breadcrumbs then loads a list styled wall when expanded
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    posts: PropTypes.array.isRequired,
    forslug: PropTypes.string,
    cache: PropTypes.bool,
    loadedCallback: PropTypes.func,
  },

  getDefaultProps: function () {
    return {
      cache: false,
      loadedCallback: _.noop,
    };
  },

  getInitialState: function () {
    return {
      loading: false,
      promises: [],
      posts: new Tremr.Models.Posts(),
      expanded: false,
      breadcrumbsState: _.map(this.props.posts, function (post) {
        return {
          slug: post["slug"],
          expanded: false,
        };
      }),
      loadedPosts: false,
    };
  },

  // gets the state of a specific posts breadcrumb
  getBreadcrumbState: function (post) {
    let returnState = null;
    _.each(this.state.breadcrumbsState, function (state) {
      if (state.slug == post["slug"]) {
        returnState = state;
      }
    });
    return returnState;
  },

  getIdentifier: function () {
    if (this.props.identifier && this.props.identifier.length > 0) {
      return this.props.identifier;
    } else {
      return this.getSlug();
    }
  },

  getSlug: function () {
    if (this.props.forslug) {
      return this.props.forslug;
    } else if (this.props.posts.length > 0) {
      return _.last(this.props.posts).slug;
    } else {
      return false;
    }
  },

  // open all breadcrumbs
  toggleExpand: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    // log the expand, so we can auto-expand when coming back
    if (this.state.expanded) {
      TremrStorage.setSession(
        "breadcrumbs.expand." + this.getIdentifier(),
        "closed"
      );
    } else {
      TremrStorage.setSession(
        "breadcrumbs.expand." + this.getIdentifier(),
        "open"
      );
    }

    this.setState({
      expanded: !this.state.expanded,
    });
  },

  // listen for deletes and updates
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    // trigger load (once only)
    if (!this.state.loading) {
      this.loadPosts();
    }

    // bit of a hack but look directly at state to see if we are caching
    // i.e. user is going back/forward and needs auto expansion of cards
    let fullContext = Tremr.context.getState();
    let isCached = fullContext.cache == true;

    // check session state to see if we should open this cards ancestors
    if (isCached) {
      var cacheddata = TremrStorage.getSession(
        "breadcrumbs.expand." + this.getIdentifier()
      );
      let newState = {};
      let stateChanged = false;
      if (cacheddata && cacheddata == "open") {
        newState.expanded = true;
        stateChanged = true;
      }

      var cachedBreadcrumbState = TremrStorage.getSession(
        "breadcrumbs.state." + this.getIdentifier()
      );
      if (cachedBreadcrumbState) {
        newState.breadcrumbState = cachedBreadcrumbState;
        stateChanged = true;
      }
      if (stateChanged) {
        this.setState(newState);
      }

      _.defer(() => {
        // must be delayed
        this.checkFullyLoaded();
      });
    } else {
      // set not expanded
      TremrStorage.setSession(
        "breadcrumbs.expand." + this.getIdentifier(),
        "closed"
      );

      // set default breadcrumb data to state
      TremrStorage.setSession(
        "breadcrumbs.state." + this.getIdentifier(),
        this.state.breadcrumbsState
      );

      _.defer(() => {
        // must be delayed
        this.checkFullyLoaded();
      });
    }
  },

  checkFullyLoaded: function () {
    if (this.state.loadedPosts) {
      if (this.props.loadedCallback) {
        this.props.loadedCallback();
      }
    } else {
      setTimeout(this.checkFullyLoaded, 500);
    }
  },

  componentWillUnmount: function () {
    this.state.promises = _.reject(this.state.promises, function (promise) {
      promise.cancel();
      return true;
    });
  },

  // load all breadcrumb summaries by using the get ancestor call
  loadPosts: function () {
    // defer so we can always safely use setState

    _.defer(
      function () {
        let fullContext = Tremr.context.getState();
        let isCached = fullContext.cache == true;

        let slug = this.getSlug();
        if (slug) {
          let context = {
            target: "ancestors-parent",
            slug: slug,
            scope: "all",
            sort: "latest",
          };

          let promise = Tremr.stores.postStore.loadPosts(
            1,
            this.state.posts,
            context,
            isCached
          );

          let promises = this.state.promises.slice(0);
          promises.push(promise);
          this.setState({
            loading: true,
            promises: promises,
          });

          promise.then(
            function (params) {
              this.setState({
                posts: new Tremr.Models.Posts(params.data),
                loadedPosts: true,
              });

              this.checkFullyLoaded();
            }.bind(this),
            function () {
              console.log("error loading breadcrumb summaries");
            }.bind(this)
          );
        }
      }.bind(this)
    );
  },

  expandBreadcrumb: function (post) {
    // removed because we already handle expanding breadcrumbs from within
    // listcardwrapper
    // // mark that crumb as expanded
    // let slug = post.get("slug");
    // let crumbState = _.map(this.state.breadcrumbsState, function (state) {
    //   if (state.slug == slug) {
    //     return _.extend(state, { expanded: true });
    //   } else {
    //     return state;
    //   }
    // });
    // this.setState({
    //   breadcrumbsState: crumbState,
    // });
  },

  // draw cards
  render: function () {
    // work out our top level class
    var classes = classNames({
      "post-breadcrumbs": true,
    });

    var classes = classNames({
      "post-breadcrumbs": true,
      expanded: this.state.expanded,
    });

    var toggleButton = null;
    if (
      !this.state.expanded &&
      this.props.posts &&
      this.props.posts.length > 1
    ) {
      toggleButton = (
        <span
          key="toggle"
          onClick={this.toggleExpand}
          title="Show Conversation"
          className="toggle"
        >
          <div className="count">
            <div className="number">{this.props.posts.length - 1}</div>
          </div>
          <div className="show">Show Conversation</div>
        </span>
      );
    }

    // only show last post if notexpanded
    if (this.props.posts && this.props.posts.length > 0) {
      let shownPosts = this.props.posts;
      if (!this.state.expanded) {
        shownPosts = [_.last(shownPosts)];
      }

      let breadcrumbs = _.map(
        shownPosts,
        function (post) {
          let state = this.getBreadcrumbState(post);

          let listStyle = "list";
          if (state.expanded) {
            listStyle = "content";
          }

          // have we loaded posts
          let postSummary = this.state.posts.findWhere({ slug: state.slug });

          // if (false) {
          if (postSummary) {
            return (
              <div
                className="breadcrumb"
                key={"breadcrumb-" + postSummary.get("id")}
              >
                {Tremr.stores.postStore.cardFactory(
                  postSummary,
                  postSummary.get("id"),
                  {
                    wallStyle: listStyle,
                    showBreadcrumbs: false,
                    showResponses: false,
                    autoexpand: false,
                    onExpandListCard: this.expandBreadcrumb,
                    identifier:
                      "breadcrumbs." +
                      this.getIdentifier() +
                      "." +
                      postSummary.get("slug"),
                  }
                )}
              </div>
            );
          } else if (post) {
            let title = (
              <Tremr.Generic.Abbreviations
                text={post.title ? post.title.trim() : ""}
              />
            );
            let avatar = null;
            if (post.author_avatar_image) {
              avatar = (
                <Tremr.Generic.Image
                  image={post.author_avatar_image}
                  height={60}
                  width={60}
                />
              );
            }

            let created = moment(post.created_at, "YYYY-MM-DDTHH:mmZ");
            let yearDiff =
              parseInt(moment().format("YYYY")) -
              parseInt(created.format("YYYY"));
            let age = created.format("D MMM");
            if (yearDiff > 0) {
              age = created.format("MMM'YY");
            }
            let timestamp = created.format();
            let isToday = created.format("D M YY") == moment().format("D M YY");
            if (isToday) {
              age = created.format("H:mma");
            }

            let titleComment = (
              <div className="comment">
                <a>{title}</a>
              </div>
            );
            if (post.summary && post.summary.length > 0) {
              titleComment = (
                <h1>
                  <a>{title}</a>
                </h1>
              );
            }

            let embed = null;
            if (post.embed_title) {
              let embed_link = null;
              if (post.embed_domain) {
                embed_link = (
                  <div className="link-content-domain">{post.embed_domain}</div>
                );
              }
              embed = (
                <div className="link-content">
                  <div className="link-content-title">{post.embed_title}</div>
                  {embed_link}
                </div>
              );
            }

            return (
              <div className="breadcrumb" key={"breadcrumb-" + post.id}>
                <div className="post-list-card article">
                  <article>
                    <a className="avatar-link">{avatar}</a>
                    <div className="card-content dummycontent">
                      <div className="byline">
                        <div className="people">
                          <div className="author">
                            {post.author_name}
                            <time
                              style={{ marginLeft: "auto" }}
                              dateTime={timestamp}
                            >
                              {age}
                            </time>
                          </div>
                        </div>
                      </div>
                      {titleComment}
                      {/* embed */}
                      <section className="post-content">
                        {post.summary ? post.summary : "\u00A0"}
                      </section>
                      <div className="post-stats">
                        <div className="post-contentsummary">&nbsp;</div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            );
          }
        }.bind(this)
      );

      return (
        <div className={classes}>
          {toggleButton}
          {breadcrumbs}
        </div>
      );
    } else {
      return null;
    }
  },
});
