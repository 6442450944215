require("./scss/optionlist.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// list of options, maintains a list of which values have been selected
// Tremr.Generic.Optionlist = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    initialSet: PropTypes.array.isRequired, // values that should start set
    options: PropTypes.array.isRequired, // list of objects with label/value props
    multiSelect: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    canUnselect: PropTypes.bool,
    useTicks: PropTypes.bool,
  },

  getDefaultProps: function () {
    return {
      multiSelect: false,
      canUnselect: true,
      useTicks: false,
    };
  },

  getInitialState: function () {
    return {
      set: this.props.initialSet,
    };
  },

  // update which items are set (if no multi-select then unset all others)
  onChange: function (value, set) {
    if (!this.props.canUnselect && !set) {
      return;
    }

    // update our state
    var newSet = [];
    if (this.props.multiSelect) {
      newSet = this.state.set.slice(0);

      if (set && !_.contains(this.state.set, value)) {
        newSet.push(value);
      } else if (!set && _.contains(this.state.set, value)) {
        newSet = _.reject(this.state.set.slice(0), function (item) {
          return item == value;
        });
      }
    } else if (set) {
      newSet.push(value);
    }

    this.setState({
      set: newSet,
    });

    // raise event for owner
    if (this.props.onChange) {
      this.props.onChange(newSet);
    }
  },

  // display
  render: function () {
    return (
      <div className="option-list">
        {this.props.options.map(
          function (option) {
            return (
              <Tremr.Generic.OptionItem
                key={"option-" + option.label + "-" + option.value.toString()}
                readonly={option.readonly ? option.readonly : false}
                label={option.label}
                value={option.value}
                onChange={this.onChange}
                useTick={this.props.useTicks}
                set={_.contains(this.state.set, option.value)}
              />
            );
          }.bind(this)
        )}
      </div>
    );
  },
});
