var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");
var TremrUtils = require("../../utils/tremr_utils");

// Tremr.Generic.Grid
module.exports = CreateReactClass({
  // mixins: [PureRenderMixin],

  propTypes: {
    isLoading: PropTypes.bool.isRequired,
    isInit: PropTypes.bool.isRequired,
    collection: PropTypes.object.isRequired,
    cardFactory: PropTypes.func.isRequired,
    mobileWidth: PropTypes.number,
    nextColumn: PropTypes.func,
    columnSpan: PropTypes.func,
    clearNewRow: PropTypes.func,
    cardoptions: PropTypes.object,
    identifier: PropTypes.string, // allow identifier to be passed down to grid/list and then cards
  },

  getDefaultProps: function () {
    return {
      // util to get the next column to use, used as default
      nextColumn: function (sizes, column_heights, rowIndex, lastColumn) {
        var min = false;
        var minIndex = 0;
        for (var i = 0; i < sizes.columns; i++) {
          if (min === false || column_heights[i] < min) {
            min = column_heights[i];
            minIndex = i;
          }
        }
        return minIndex;
      },
      // util to force all cards to span only 1 column, used as default
      columnSpan: function (
        index,
        sizes,
        column_heights,
        rowIndex,
        lastColumn,
        lastSpan
      ) {
        // randomly attempt to widen columns
        let rnd = TremrUtils.seededRandom(1, 10, "gridlayout");

        if (
          lastSpan <= 1 &&
          lastColumn < sizes.columns - 1 &&
          (index == 0 || rnd > 0)
        ) {
          let colIndex = lastColumn + 1;

          // check the two columns this will use are within 100 height of each other
          let col1 = column_heights[colIndex - 1];
          let col2 = column_heights[colIndex];
          let diff = Math.abs(col1 - col2);
          if ((index == 0 || diff > 0) && diff < 100) {
            return 2;
          } else {
            return 1;
          }
        }

        return 1;
      },
      // do we want to clear the row heights on every new row?
      clearNewRow: function (sizes, column_heights, rowIndex, lastColumn) {
        return false;
      },
      cardoptions: {},
    };
  },

  // default to position off screen
  getInitialState: function () {
    return { height: 50 };
  },

  // handler to deal with a resize and reflow
  resized: _.throttle(
    function () {
      if (this.setSizes()) {
        this.flowCards();
      }
    },
    50,
    { leading: false }
  ), // if fast enough reduce 200 ms throttling

  // start listening to resize events to
  // rearrange the grid
  componentDidMount: function () {
    // _.extend(this, Backbone.Events);

    // $(window).on("resize", this.resized);
    TremrUtils.addPassiveEventListener("resize", this.resized);

    // initialise state with sizes by calling now
    this.setSizes();
  },

  // remove event handler
  componentWillUnmount: function () {
    // this.stopListening();
    // $(window).off("resize", this.resized);
    window.removeEventListener("resize", this.resized);
  },

  // cards will all have heights at this point
  componentDidUpdate: function () {
    // have to call this because it is the only way we know
    // new cards have been added
    this.flowCards();
  },

  // event fired by cards if they change height
  cardHeightSet: function (card) {
    // reflow the whole lot every time a new card is added (hope react makes this efficent-ish)
    this.flowCards();
  },

  // get settings for the sizing of the grid (different for mobile and small screens!)
  getSizing: function () {
    var domNode = ReactDOM.findDOMNode(this);
    var current_width = $(domNode).width();

    if (Tremr.Utils.isDeviceOrNarrow()) {
      return {
        columns: 2,
        margin: 0,
        gutter: 4,
        width: Math.floor((current_width - 4) / 2),
        row: 4,
        currentWidth: current_width,
      };
    } else if (Tremr.Utils.isMediumWidth()) {
      var sizes = {
        columns: 3,
        margin: 0,
        gutter: 30,
        width: Math.min(Math.floor((current_width - 60) / 3), 280),
        row: 30,
        currentWidth: current_width,
      };
      // sizes.columns = Math.floor((current_width + sizes.gutter) / (sizes.width + sizes.gutter));

      // reverse width, and use to set margin to centre
      var actualWidth =
        sizes.columns * (sizes.width + sizes.gutter) - sizes.gutter;
      sizes.margin = Math.floor((current_width - actualWidth) / 2);

      return sizes;
    } else {
      var sizes = {
        columns: 4,
        margin: 0,
        gutter: 30,
        width: Math.min(Math.floor((current_width - 90) / 4), 280),
        row: 30,
        currentWidth: current_width,
      };
      // sizes.columns = Math.floor((current_width + sizes.gutter) / (sizes.width + sizes.gutter));

      // reverse width, and use to set margin to centre
      var actualWidth =
        sizes.columns * (sizes.width + sizes.gutter) - sizes.gutter;
      sizes.margin = Math.floor((current_width - actualWidth) / 2);

      return sizes;
    }
  },

  // check if our size data has changed
  hasSizingChanged: function (oldSize, newSize) {
    if (oldSize == undefined) {
      return true;
    }

    if (
      oldSize.columns != newSize.columns ||
      oldSize.margin != newSize.margin ||
      oldSize.gutter != newSize.gutter ||
      oldSize.width != newSize.width
    ) {
      return true;
    }

    return false;
  },

  // update state with new sizes - needed at the start and
  // when we resize but not any other time
  setSizes: function () {
    var sizes = this.getSizing();

    // if this is different (or new) from old state then
    // we need to re-arrange (if the same no action needed)
    if (
      this.state == undefined ||
      this.hasSizingChanged(this.state.sizes, sizes)
    ) {
      this.setState({ sizes: sizes });

      return true;
    } else {
      return false;
    }
  },

  // arrange all cards
  flowCards: function () {
    var zIndex = 1000;

    // reset random sequence
    TremrUtils.seededSequence("gridlayout", 2);
    if (this.props.collection && this.props.collection.length > 1) {
      let seed_data = "title";
      if (
        !this.props.collection.at(1).has("title") &&
        this.props.collection.at(1).has("name")
      ) {
        seed_data = "name";
      } else if (
        !this.props.collection.at(1).has("title") &&
        this.props.collection.at(1).has("tag")
      ) {
        seed_data = "tag";
      }
      let seed = this.props.collection.at(1).get(seed_data);
      if (!seed || !seed.length) {
        seed = "seed";
      }
      TremrUtils.seededSequence("gridlayout", seed.length);
    }

    // get the sizes for the columns
    var sizes = this.state.sizes;
    if (!sizes) {
      return;
    }

    // keep a list of cards in each column
    this.column_cards = [];

    // keep variables for where to position cards
    this.column_heights = [];
    this.column_positions = [];

    // work out column positions and init vars
    for (var i = 0; i < sizes.columns; i++) {
      this.column_cards[i] = [];
      // this.column_heights[i] = sizes.row;
      this.column_heights[i] = 0;

      if (i == 0) {
        // first column has margin
        this.column_positions[i] = sizes.margin;
      } else {
        // other columns have gutter
        this.column_positions[i] =
          this.column_positions[i - 1] + sizes.width + sizes.gutter;
      }
    }

    // keep track of the row and column index
    let rowIndex = 1;
    let lastColumnIndex = 0;
    let lastSpan = 0;
    let cardIndex = 0;

    // iterate all cards and position
    _.each(
      _.pairs(this.refs),
      function (pair) {
        if (pair[0].indexOf("card-") == 0) {
          var cardDomNode = ReactDOM.findDOMNode(pair[1]);
          var cardHeight = $(cardDomNode).outerHeight();

          // only process cards with a height
          if (cardHeight > 0) {
            // if we are beyond the max columns, reset to no earlier cols and new row
            if (lastColumnIndex >= sizes.columns) {
              rowIndex++;
              lastColumnIndex = 0;

              // each new row must start with equal heights
              if (this.props.clearNewRow()) {
                for (let colIndex = 0; colIndex < sizes.columns; colIndex++) {
                  this.column_heights[colIndex] = Math.max(
                    ...this.column_heights
                  );
                }
              }
            }

            // see which column to use next
            let nextColIndex = this.props.nextColumn(
              sizes,
              this.column_heights,
              rowIndex,
              lastColumnIndex
            );

            // should the card span?
            let cardSpan = this.props.columnSpan(
              cardIndex,
              sizes,
              this.column_heights,
              rowIndex,
              nextColIndex,
              lastSpan
            );

            // if we wanted a full width width ALWAYS reset heights
            if (cardSpan == sizes.columns) {
              for (let colIndex = 0; colIndex < sizes.columns; colIndex++) {
                this.column_heights[colIndex] = Math.max(
                  ...this.column_heights
                );
              }
            }

            // if not allowed to span that much try next row
            if (cardSpan > 1 && !pair[1].allowSize(cardSpan)) {
              // try next row, fallback to 1 span
              let tryNextColIndex = this.props.nextColumn(
                sizes,
                this.column_heights,
                rowIndex + 1,
                0
              );
              let tryCardSpan = this.props.columnSpan(
                cardIndex,
                sizes,
                this.column_heights,
                rowIndex + 1,
                tryNextColIndex,
                lastSpan
              );

              if (tryCardSpan >= cardSpan) {
                cardSpan = 1;
              } else if (tryCardSpan > 1 && !pair[1].allowSize(tryCardSpan)) {
                cardSpan = 1;
              } else {
                // keep the chosen card span
                cardSpan = tryCardSpan;
              }
            }

            // get array of spanned columns
            let spannedCols = this.column_heights.slice(
              nextColIndex,
              nextColIndex + cardSpan
            );

            // if spanning multiple columns, get the top position of lowest
            let tallestSpannedColHeight =
              spannedCols.length == 1
                ? spannedCols[0]
                : Math.max(...spannedCols);

            // get the width
            let cardWidth =
              sizes.width * cardSpan + sizes.gutter * (cardSpan - 1);

            // set position
            pair[1].setState({
              left: this.column_positions[nextColIndex],
              top: tallestSpannedColHeight,
              height: cardHeight,
              width: cardWidth,
              zIndex: zIndex,
              columns: cardSpan,
              sizes: sizes,
              rowIndex: rowIndex,
            });

            lastSpan = cardSpan;
            cardIndex++;

            // update all spanned columns
            for (
              let spannedColIndex = nextColIndex;
              spannedColIndex < nextColIndex + cardSpan;
              spannedColIndex++
            ) {
              this.column_heights[spannedColIndex] =
                tallestSpannedColHeight + cardHeight + sizes.row;
            }

            // make sure breadcrumbs/responses/popups from cards will be in front of later cards
            zIndex--;

            // move to next column
            lastColumnIndex = lastColumnIndex + cardSpan;
          } // is height > 0 ?
        } // is a card ref ?
      }.bind(this)
    ); // every card

    // update grid to contain cards
    var newHeight = Math.max.apply(Math, this.column_heights);
    if (newHeight != this.state.height) {
      this.setState({ height: newHeight });
    }
  },

  // draw cards
  render: function () {
    // work out our top level class
    var classes = classNames({
      "post-grid": true,
      grid: true,
      loading: this.props.isLoading,
      init: this.props.isInit,
    });

    var divStyles = {
      height: this.state.height,
    };

    var startTop = this.state.height * 1.2;
    var windowHeight = $(window).height() * 1.2;
    if (windowHeight > startTop) {
      startTop = windowHeight;
    }
    var startWidth = Math.floor($(window).width() * 0.5);

    if (!this.props.collection) {
      return <div className={classes} style={divStyles} />;
    } else {
      return (
        <div className={classes} style={divStyles}>
          {_.map(
            this.props.collection.models,
            function (model, index) {
              let options = _.extend(this.props.cardoptions, {
                identifier: this.props.identifier + "." + index,
                startWidth: startWidth,
                startTop: startTop,
                cardHeightSet: this.cardHeightSet,
                wallStyle: "grid",
              });

              return this.props.cardFactory(model, index, options);
            }.bind(this)
          )}
        </div>
      );
    }
  },
});
