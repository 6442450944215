var UserAwareMixin = require("../../mixins/userawaremixin");
var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var TremrUtils = require("../../utils/tremr_utils");
var FirebaseEncode = require("firebase-encode");

// higher-order component to watch for changes in one or more firebase nodes
// and keep child component updated
module.exports = function (WrappedComponent) {
  return CreateReactClass({
    mixins: [UserAwareMixin],

    propTypes: {
      dataPoints: PropTypes.array,
      onChange: PropTypes.func,
    },

    getDefaultProps: function () {
      return {
        dataPoints: [],
        onChange: null,
      };
    },

    getInitialState: function () {
      let dataPoints = {};
      this.props.dataPoints.forEach(function (dp) {
        dataPoints[dp.name] = {
          name: dp.name,
          key: dp.key,
          user: dp.user,
          data: null,
          fbRef: null,
        };
      });

      return {
        dataPoints: dataPoints,
      };
    },

    shouldComponentUpdate: function (nextProps, nextState) {
      // // check if any data in dataPoints has changed
      // let found = false;
      // this.props.dataPoints.forEach(
      //   function (dataPoint) {
      //     let dp = this.state.dataPoints[dataPoint.name];
      //     if (nextState.dataPoints[dp.name]) {
      //       if (dp.data != nextState.dataPoints[dp.name].data) {
      //         found = found || true;
      //       }
      //     } else {
      //       found = found || true;
      //     }
      //   }.bind(this)
      // );
      //
      // return found;
      return true; // always render until we change this to handle any prop changes AND dataPoint changes
    },

    // encode but allowing / for folders
    encodeKey: function (key) {
      return FirebaseEncode.encodeComponents(key);
    },

    bindUserDataPoints: function () {
      // connect to firebase for every dataPoint
      this.props.dataPoints.forEach(
        function (dataPoint) {
          let key = this.encodeKey(dataPoint.key);

          let dp = this.state.dataPoints[dataPoint.name];
          if (dp.user && this.state.user) {
            dp.component = this; // needed so we can use in updateValue callback
            dp.fbRef = Tremr.firebase
              .database()
              .ref(key.replace(/\{user\}/, this.state.user.get("_id")));
            dp.fbRef.on(
              "value",
              this.updateValue.bind(dp),
              this.callbackError,
              dp
            );
          }
        }.bind(this)
      );
    },

    bindDataPoints: function () {
      // connect to firebase for every dataPoint
      this.props.dataPoints.forEach(
        function (dataPoint) {
          let dp = this.state.dataPoints[dataPoint.name];
          let key = this.encodeKey(dp.key);

          if (!dp.user) {
            dp.component = this; // needed so we can use in updateValue callback
            dp.fbRef = Tremr.firebase.database().ref(key);
            dp.fbRef.on("value", this.updateValue, this.callbackError, dp);
          }
        }.bind(this)
      );
    },

    callbackError: function (error) {
      console.debug("Error binding:" + error);
    },

    // listen for user updating this elsewhere
    componentDidMount: function () {
      if (!this.stopListening) {
        _.extend(this, Backbone.Events);
      }

      // listen for sign-in/out and update user in our session
      this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function (
        user
      ) {
        this.bindUserDataPoints();
      });

      // listen for sign-in from firebase, in case this rendered before that happened
      this.listenTo(Tremr.dispatcher, "tremr:firebase:auth", function (user) {
        this.bindDataPoints();
        this.bindUserDataPoints();
      });

      // dymanic event handler so it isn't bound to this object!
      this.updateValue = function (snapshot) {
        let data = snapshot.val();

        let dataPoints = this.component.state.dataPoints;

        dataPoints[this.name] = {
          name: this.name,
          key: this.key,
          user: this.user,
          data: data,
          fbRef: dataPoints[this.name].fbRef,
        };

        this.component.forceUpdate();
      };

      if (this.state.user && this.state.user.get("_id")) {
        this.bindUserDataPoints();
      }

      this.bindDataPoints();
    },

    // unregister listeners
    componentWillUnmount: function () {
      if (this.stopListening) {
        this.stopListening();
      }

      this.props.dataPoints.forEach(
        function (dataPoint) {
          let dp = this.state.dataPoints[dataPoint.name];
          if (dp && dp.fbRef && dp.fbRef.off) {
            dp.fbRef.off("value", this.updateValue);
            dp.fbRef = null;
          }
        }.bind(this)
      );
    },

    // render the wrapped component
    render: function () {
      return (
        <WrappedComponent
          onRender={this.props.onChange}
          liveDataPoints={this.state.dataPoints}
          {...this.props}
        />
      );
    },
  });
};
