var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// rich text editor block
// Tremr.Editor.UploadControl
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      identifier: PropTypes.string.isRequired,
      captionText: PropTypes.array.isRequired, // array of chars
      notifyPosition: PropTypes.func.isRequired, // notify parent of position
      removeBlock: PropTypes.func.isRequired, // notify parent of position
      image: PropTypes.object.isRequired,
      inProgress: PropTypes.bool,
      progress: PropTypes.number,
      image: PropTypes.object.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      maxHeight: PropTypes.number.isRequired,
      readOnly: PropTypes.bool,
      updatedCaption: PropTypes.func.isRequired
  },

  lastBounds: false, // keep track of bounds so we only notify if actually changed

  getDefaultProps: function() {

    return {
      captionText: [],
      height: 200,
      width: 200,
      maxHeight: 1000,
      inProgress: false,
      progress: 0,
      readOnly: false
    }
  },

  getInitialState: function() {
    return {
      captionText: this.props.captionText
    };
  },

  // set focus to this element (selects the input)
  focus: function() {
    if (this.refs['caption'] && this.refs['caption'].focus) {
      this.refs['caption'].focus();
    }
  },

  componentDidMount: function() {
    this.notifyPosition();
  },

  componentDidUpdate: function() {
    this.notifyPosition();
  },

  // notify parent of it's position
  notifyPosition: function() {

    if (!this.props.readOnly) {
      var node = ReactDOM.findDOMNode(this);
      var bounds = node.getBoundingClientRect();
      if (bounds.top != this.lastBounds.top || bounds.height != this.lastBounds.height) {

        // ignore under 35 (default height before loading starts)
        if (bounds.height > 35) {
          if (this.props.notifyPosition(this.props.identifier, bounds, (bounds.height != this.lastBounds.height), this)) {
            this.lastBounds = bounds;
          }
        } else {
          _.delay(_.throttle(this.notifyPosition, 1000), 200);
        }
      }

    } else if (!this.props.readOnly) {} else {
      _.delay(_.throttle(this.notifyPosition, 1000), 200);
    }
  },

  // tell the editor to remove this block
  handleRemove: function(event) {

    event.preventDefault();
    event.stopPropagation();
    this.props.removeBlock(this.props.identifier);
  },

  render: function() {

    var classes = classNames({
        'block': true,
        'upload-block': true
    });

    var image = <div className="image loading" style={{height: this.props.height}}></div>;
    if (this.props.image) {
      image = <Tremr.Generic.Image ref="image" calcWidthOnTallerThanMaxHeight={true} image={this.props.image} maxHeight={this.props.maxHeight} width="auto" resizable={true} height="auto" />;
    }

    var inProgress = '';
    if (!this.props.readOnly && this.props.inProgress) {
        var progressStyle = {
            width: this.props.progress+'%'
        };
        inProgress = <div className="progress">
            <span className="meter" style={progressStyle}></span>
        </div>;
    }

    var removeButton = null;
    if (!this.props.readOnly && this.props.image) {
      removeButton = <a onClick={this.handleRemove} className="remove">
        <Tremr.Generic.SvgIcon icon="remove" />
      </a>;
    }

    var getCaptionMarkup = function()  {

      return this.props.captionText.join('');
    }.bind(this);
    var caption = null;
    if (this.props.image && !this.props.inProgress) {
      caption = React.createElement('p', {"data-placeholder": 'Enter a caption', className: 'caption', contentEditable: (!this.props.readOnly), dangerouslySetInnerHTML: { __html: getCaptionMarkup() } })
    }

    return (<div className={classes} contentEditable="false" data-id={this.props.identifier}>
      {image}
      {inProgress}
      {caption}
      {removeButton}
    </div>);

  }

});
