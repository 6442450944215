// require("./scss/gdpr.scss");

var CreateReactClass = require('create-react-class');
var SvgIcon = require('../generic/svgicon');
var Cookies = require('js-cookie/src/js.cookie');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

  getInitialState: function() {

     return {
       closed_newsletter: (Cookies.get('has_closed_newsletter') == '1')
     }
  },

  close: function(e) {
    Cookies.set('has_closed_newsletter', '1', {expires: 365});
    this.setState({
      closed_newsletter: true
    });
  },

	signup: function(event) {
		event.stopPropagation();
		event.preventDefault();
		Tremr.dispatcher.message(this, "tremr:open:pitchpage");
		this.close(event);
	},

  render: function() {

    if (this.state.closed_newsletter) {
      return null;
    }

		return (
			<div className="layout-newsletter layout-alert-wrapper">
				<div className="layout-alert-content">
					<p>
						<a onClick={this.signup} href="/signup">Sign up
						</a> to get the {Tremr.Theme.ShortTitle} <a target="_blank" href="https://covid-action.us19.list-manage.com/subscribe?u=363b0a971304bb262c2023041&id=a6232dabbe">Newsletter</a>
					</p>
	        <SvgIcon icon="remove" onClick={this.close} />
				</div>
			</div>
		);
	}
});
