let moment = require("moment");
let TremrUtils = require("../../utils/tremr_utils");
let classNames = require("../../utils/classnames");
let PropTypes = require("prop-types");
let CreateReactClass = require("create-react-class");
let Config = require("config");

let WithFBData = require("../generic/withFBData");
let Stars = require("../reputation/stars");
let StarsWithFBData = WithFBData(Stars);
let ReputationOverview = require("../reputation/postoverview");
let ReputationOverviewWithFBData = WithFBData(ReputationOverview);
let ReputationBadge = require("../reputation/badge");
let ReputationBadgeWithFBData = WithFBData(ReputationBadge);
let ReputationHelper = require("../reputation/helper");
let PostWarningBadgeOverview = require("../reputation/postwarningbadge");
let PostWarningBadgeOverviewWithFBData = WithFBData(PostWarningBadgeOverview);

// Tremr.User.Byline = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    post: PropTypes.object.isRequired,
    chosenReason: PropTypes.object,
    showAncestorCount: PropTypes.bool,
    showTag: PropTypes.bool,
    showFollow: PropTypes.bool,
    subheaderContent: PropTypes.string,
  },

  getDefaultProps: function () {
    return {
      avatar_size: 36,
      via_avatar_size: 18,
      showAncestorCount: false,
      showTag: false,
      showFollow: false,
      subheaderContent: null,
    };
  },

  // navigate to the users feed
  openContext: function (event, context) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({
          primary: context,
        });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    Tremr.navigatePrimary(context);
  },

  openFeed: function (event) {
    var feedContext = {
      target: "feed",
      scope: "posts",
      subfeed: "featured",
      feed:
        this.props.post.get("channel_author_feed") ||
        this.props.post.get("author_feed"),
    };

    this.openContext(event, feedContext);
  },

  openParentFeed: function (event) {
    var feed = event.currentTarget.getAttribute("data-feed");
    var feedContext = {
      target: "feed",
      scope: "posts",
      subfeed: "featured",
      feed: feed,
    };

    this.openContext(event, feedContext);
  },

  // display
  render: function () {
    let image = null;
    if (this.props.post.get("author_avatar_image")) {
      image = (
        <Tremr.Generic.Image
          key={
            "avatar-" +
            (this.props.post.get("author_avatar_image")
              ? this.props.post.get("author_avatar_image").public_id
              : "")
          }
          image={this.props.post.get("author_avatar_image")}
          height={this.props.avatar_size}
          width={this.props.avatar_size}
        />
      );
    }

    let bylinesubheader = [];

    let recommended = false;
    if (
      this.props.post.get("author_tags") &&
      _.contains(
        this.props.post.get("author_tags"),
        Tremr.Theme.RecommendedWriter.Tag
      )
    ) {
      recommended = true;
    }

    let authorUrl = Tremr.routes.urlFor({
      primary: {
        target: "feed",
        scope: "all",
        subfeed: "featured",
        feed:
          this.props.post.get("channel_author_feed") ||
          this.props.post.get("author_feed"),
      },
    });

    let followButton = null;
    if (this.props.showFollow) {
      followButton = (
        <Tremr.Follow.Button
          title={this.props.post.get("author_name")}
          subfeeds={this.props.subfeeds || []}
          entity="feed"
          id={
            this.props.post.get("channel_author_feed") ||
            this.props.post.get("author_feed")
          }
        />
      );
    }

    let avatar = (
      <a onClick={this.openFeed} href={authorUrl}>
        {image}
      </a>
    );
    let name = (
      <a onClick={this.openFeed} href={authorUrl}>
        {this.props.post.get("author_name")}
      </a>
    );

    let subheader = bylinesubheader;
    if (this.props.subheaderContent) {
      subheader = this.props.subheaderContent;
    }

    let avatarContainer = <div className="avatar">{avatar}</div>;

    let post_id = this.props.post.get("_id") || this.props.post.get("id");

    const reputationStarsDataPoints = [
      ReputationHelper.getUserReputationStarsDataPoint(
        this.props.post.get("author_id")
      ),
      ReputationHelper.getPostReputationStarsDataPoint(
        this.props.post.get("author_id"),
        post_id
      ),
      ReputationHelper.enhanceDataPoint(
        ReputationHelper.getPostReputationBadgeDataPoint(
          this.props.post.get("author_id"),
          post_id
        ),
        {
          name: "suppress",
          check: function (value) {
            return value && value.length > 0;
          },
        }
      ),
    ];
    let reputationStars = null;
    reputationStars = (
      <StarsWithFBData
        alignment="right"
        highlightColor={this.props.highlightColor}
        height={18}
        width={54}
        scale={window.devicePixelRatio}
        dataPoints={reputationStarsDataPoints}
      />
    );

    const reputationOverviewDataPoints = [
      ReputationHelper.renameDataPoint(
        ReputationHelper.getUserReputationStarsDataPoint(
          this.props.post.get("author_id")
        ),
        "user_rep_stars"
      ),
      ReputationHelper.getPostReputationAutoTagsDataPoint(
        this.props.post.get("author_id"),
        post_id
      ),
    ];
    let reputationOverview = (
      <ReputationOverviewWithFBData
        align="right"
        highlightColor={this.props.highlightColor}
        authorName={this.props.post.get("author_name")}
        dataPoints={reputationOverviewDataPoints}
      >
        {reputationStars}
      </ReputationOverviewWithFBData>
    );

    const reputationBadgeDataPoints = [
      ReputationHelper.getUserReputationBadgeDataPoint(
        this.props.post.get("author_id")
      ),
    ];
    const userBadge = (
      <ReputationBadgeWithFBData dataPoints={reputationBadgeDataPoints} />
    );

    const reputationPostBadgeDataPoints = [
      ReputationHelper.getPostReputationBadgeDataPoint(
        this.props.post.get("author_id"),
        post_id
      ),
    ];
    const postWarningBadge = (
      <PostWarningBadgeOverviewWithFBData
        dataPoints={reputationPostBadgeDataPoints}
      />
    );

    let recommenededText = null;
    if (recommended) {
      // recommenededStar = <span title="Recommended Writer">
      //     <Tremr.Generic.SvgIcon icon="recwriter" classes="icon recommended-writer" />
      // </span>;
      recommenededText = (
        <div
          title={Tremr.Theme.RecommendedWriter.Description}
          className="author-tags"
        >
          {Tremr.Theme.RecommendedWriter.Description}
        </div>
      );
    }

    let classes = classNames({
      byline: true,
      recommeneded: recommended,
    });

    return (
      <div className={classes}>
        {avatarContainer}
        <div className="info">
          {userBadge}
          <div className="name-container">
            <div className="name">{name}</div>
            {followButton}
            {reputationOverview}
            {postWarningBadge}
          </div>
          {recommenededText}
          <div className="subheader">{subheader}</div>
          {this.props.children}
        </div>
      </div>
    );
  },
});
