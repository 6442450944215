require("./scss/menu.scss");

var CreateReactClass = require('create-react-class');
var OnClickOutside = require('../../mixins/onclickoutside');

// Tremr.Search.Menu = CreateReactClass({
module.exports = CreateReactClass({

	// mixins: [PureRenderMixin, OnClickOutside],
    mixins: [PureRenderMixin], // come back and fix the onClickOutside

    getInitialState: function() {
      return {
        open: false,
        tags: Tremr.stores.tagStore.broadTags()
      };
    },

    // close when click outside
    // handleClickOutside: function(event) {
    //     this.close();
    // },

    open: function(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({open: true});
    },

    close: function(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({open: false});
    },

    stopPropagation: function(event) {
        event.preventDefault();
        event.stopPropagation();
    },

    // fired on enter
	searchKeyPress: function(event) {

		if (event.key == 'Enter') {

			var node = $(this.refs['search']);
			var term = node.val();

			if (term && term.length > 0) {

                this.close();

				Tremr.navigatePrimary({
		            target: 'search',
                    scope: 'posts',
                    sort: 'relevance',
		            term: term
		        });

		        // if mobile, also close sidebar
		        if (Tremr.Utils.isDeviceOrNarrowWithSidebar()) {
		            Tremr.context.batch({leftSidebar: 'closed'});
		        }
			}

		}
	},

    // listen to cms event
    componentDidMount: function() {

        if (!this.stopListening) {
          _.extend(this, Backbone.Events);
        }

        this.listenTo(Tremr.dispatcher, "tremr:firebase:cms", function(data) {
            if (data == 'tags') {
                this.setState({
                    tags: Tremr.stores.tagStore.broadTags()
                });
            }
        }.bind(this));

    },

    componentDidUpdate: function() {

        if(this.refs['search']) {
            _.once(() => {
                this.refs['search'].focus();
            })();
        }
    },

    componentWillUnmount: function() {
        if (this.stopListening) {
          this.stopListening();
        }
    },

	render: function() {

        let tags = <Tremr.Tag.List onClick={this.close} tags={this.state.tags} commas={false}/>;

        if (this.state.open) {
      		return <nav className="search-menu" onClick={this.stopPropagation}>
                        <div className="search-form">
        			      <input ref="search" onKeyPress={this.searchKeyPress} type="text" placeholder="Search..." />
                          <Tremr.Generic.SvgIcon icon="remove" classes="icon" onClick={this.close} />
        			    </div>
                        <div className="suggestions">
                            <strong>Popular</strong>
                            {tags}
                        </div>
        			</nav>;
        } else {
            return <Tremr.Generic.SvgIcon key="open" icon="search" classes="icon search-menu-button" onClick={this.open} />;
        }
	}
});
