require("./scss/subfeeds.scss");

var alertify = require('alertify');
var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');
var TremrUtils = require('../../utils/tremr_utils');
var IconButton = require('../generic/iconbutton');
var Subfeed = require('../../models/Subfeed');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [UserAwareMixin], // cant be pure because the sortablejs re-orders the array!

	getDefaultProps: function() {
		return {
			subfeeds: [],
			selectedSubfeeds: [],
		};
	},

	getInitialState: function() {

		return {
			subfeeds: _.isArray(this.props.subfeeds) ? this.props.subfeeds.slice() : [],
			selectedSubfeeds: _.isArray(this.props.selectedSubfeeds) ? this.props.selectedSubfeeds.slice() : []
		};
	},

	selectedSubfeed: function(params) {

		let allPostsSet = this.state.selectedSubfeeds.includes('all-posts'); // include as a selectable option

		if (allPostsSet) {
			// don't replace all-posts as that one is now either/or with others
			// params.push('all-posts');
		}

		this.setState({
			selectedSubfeeds: params
		});

		// send to parent
		if (this.props.onChange) {
			this.props.onChange(params);
		}
	},

	toggleAll: function(subfeed, isSet) {

		let selected = this.state.selectedSubfeeds.slice(0);
		let newSelected = _.reject(selected, function(item) {
			return (item == subfeed);
		});

		if (isSet) {
			newSelected = []; // make the all posts toggle everything else off
			newSelected.push(subfeed);
		}

		this.setState({
			selectedSubfeeds: newSelected
		});

		// send to parent
		if (this.props.onChange) {
			this.props.onChange(newSelected);
		}
	},

	render: function() {

		let allPostsSet = this.state.selectedSubfeeds.includes('all-posts'); // include as a selectable option
		let nonAllSelected = _.reject(this.state.selectedSubfeeds, function(item) {
			return (item == 'all-posts');
		});

		return <div className="post-subfeeds">

				<div className="option-list">
					<div className="option-item">
						<Tremr.Generic.OptionItem onChange={this.toggleAll} key={'option-allposts'} label="All Posts" value="all-posts" set={allPostsSet} />
					</div>
				</div>

			    <Tremr.Generic.Optionlist key={nonAllSelected.length} multiSelect={true} initialSet={nonAllSelected} onChange={this.selectedSubfeed} options={_.map(this.state.subfeeds, function(subfeed) {
				    return {label: subfeed.name, value: subfeed.name};
				})} />
		</div>;
	}

});