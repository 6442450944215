let Config = require("config");

// mixin for any component to be kept up to date with css variable color changes
// so they can update the color they use
module.exports = {
  getInitialState: function () {
    const highlightColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--highlight-color");
    const feedhighlightColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--feed-highlight-color");
    return {
      highlightColor: highlightColor,
      feedHighlightColor: feedhighlightColor,
    };
  },

  // hook into events
  componentDidMount: function () {
    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    this.listenTo(
      Tremr.dispatcher,
      "tremr:color:highlight",
      function (newColor) {
        this.setState({
          highlightColor: newColor,
        });
      }.bind(this)
    );

    this.listenTo(
      Tremr.dispatcher,
      "tremr:color:feedhighlight",
      function (newColor) {
        this.setState({
          feedHighlightColor: newColor,
        });
      }.bind(this)
    );
  },

  // stop listening to events
  componentWillUnmount: function () {
    if (this.stopListening) {
      this.stopListening();
    }
  },
};
