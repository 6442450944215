require("./scss/contentcard.scss");
require("./scss/responsesbutton.scss");

let moment = require("moment");
let TremrUtils = require("../../utils/tremr_utils");
let classNames = require("../../utils/classnames");
let TremrStorage = require("../../utils/tremr_storage");
let UserAwareMixin = require("../../mixins/userawaremixin");
let Breadcrumbs = require("./breadcrumbs");
let PropTypes = require("prop-types");
let CreateReactClass = require("create-react-class");
let ContentSummary = require("./contentsummary");
let PostOrigin = require("./postorigin");

let WithFBData = require("../generic/withFBData");
var ReplyArea = require("./replyarea");
var ReplyAreaWithFBData = WithFBData(ReplyArea);
let ActivityIconButton = require("../activity/icon_button");
let ActivityIconButtonWithFBData = WithFBData(ActivityIconButton);
let RepostButton = require("../activity/repost_button");
let RepostButtonWithFBData = WithFBData(RepostButton);
let UpvoteButton = require("../activity/upvotebutton");
let UpvoteButtonWithFBData = WithFBData(UpvoteButton);
let ActivitySum = require("../activity/sum");
let ActivitySumWithFBData = WithFBData(ActivitySum);
let Reactions = require("../activity/reactions");
let SvgIcon = require("../generic/svgicon");
let FactcheckBadge = require("../factcheck/badge");
let Stars = require("../reputation/stars");
let StarsWithFBData = WithFBData(Stars);
let OriginRules = require("./originRules");
let ReputationOverview = require("../reputation/postoverview");
let ReputationOverviewWithFBData = WithFBData(ReputationOverview);
let ColorChangesMixin = require("../../mixins/colorchangesmixin");
let ReputationBadge = require("../reputation/badge");
let ReputationBadgeWithFBData = WithFBData(ReputationBadge);
let ReputationHelper = require("../reputation/helper");
let PostWarningBadgeOverview = require("../reputation/postwarningbadge");
let PostWarningBadgeOverviewWithFBData = WithFBData(PostWarningBadgeOverview);

module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin, ColorChangesMixin],

  propTypes: {
    post: PropTypes.object.isRequired,
    showBreadcrumbs: PropTypes.bool,
    showResponses: PropTypes.bool,
    identifier: PropTypes.string,
    loadedCallback: PropTypes.func,
    autoexpand: PropTypes.bool,
    autoLoadContent: PropTypes.bool,
    onToggleResponses: PropTypes.func,
  },

  getDefaultProps: function () {
    return { showBreadcrumbs: true, showResponses: true };
  },

  getInitialState: function () {
    return {
      currentResponsesCount: 0,
      pendingResponses: [],
      isDeleted: false,
      breadcrumbsExpanded: false,
      columns: 1,
      sizes: {
        columns: 4,
        margin: 40,
        gutter: 20,
        width: 300,
        row: 20,
        currentWidth: 0,
      },
      expanded: false,
      loadedCallback: _.noop,
      showContent: false,
      childRenderStyle: "content",
      bodyRecommendOpen: false,
      contentBottom: false, // have we scrolled to the bottom of the content
    };
  },

  upvoteChange: function (newLevel) {
    this.setState({
      recommendLevel: newLevel,
    });
  },

  // the first time the content has been rendered, measure the
  // height of the content and decide whether to show the expand button
  componentDidUpdate: function () {
    let newState = {};

    // if (this.state.post_content && !this.state.contentHeight) {
    if (this.state.post_content && !this.state.contentHeight) {
      let node = ReactDOM.findDOMNode(this.refs["containded"]);
      if (node) {
        let rect = node.getBoundingClientRect();
        let height = rect.height;

        // estimate images and embeds
        _.each(this.state.post_content.get("content_blocks"), (block) => {
          if (
            block.type == "upload" ||
            block.type == "embed" ||
            block.type == "twitter"
          ) {
            height += 600;
          }
        });

        newState.contentHeight = height;
      }
    }

    // inject pending
    let pendingResponses = this.state.pendingResponses;
    if (pendingResponses && pendingResponses.length > 0) {
      if (this.refs["responsesComponent"]) {
        pendingResponses.forEach(
          function (post) {
            this.refs["responsesComponent"].inject(post);
          }.bind(this)
        );
        newState.pendingResponses = [];
      }
    }

    // update state
    this.setState(newState);
  },

  // listen for deletes and updates
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    // if we are auto loading content then do it now
    if (this.props.autoLoadContent) {
      this.loadContent();
    }

    // find the top most wall element and find out what style it is
    let childRenderStyle = this.state.childRenderStyle;
    let node = ReactDOM.findDOMNode(this);
    let firstWallParent = _.find(TremrUtils.parents(node).reverse(), function (
      parentNode
    ) {
      return parentNode.classList && parentNode.classList.contains("wall");
    });
    if (firstWallParent) {
      if (firstWallParent.classList.contains("list")) {
        childRenderStyle = "list";
      } else if (firstWallParent.classList.contains("content")) {
        childRenderStyle = "content";
      }
      if (firstWallParent.classList.contains("grid")) {
        childRenderStyle = "grid";
      }
      if (childRenderStyle != this.state.childRenderStyle) {
        this.setState({
          childRenderStyle: childRenderStyle,
        });
      }
    }

    // bit of a hack but look directly at state to see if we are caching
    // i.e. user is going back/forward and needs auto expansion of cards
    let fullContext = Tremr.context.getState();
    let isCached = fullContext.cache == true;

    let autoexpand = false;
    if (
      this.props.autoexpand &&
      this.props.post.get("responses_count") &&
      this.props.post.get("responses") &&
      this.props.post.get("responses").length > 0
    ) {
      autoexpand = true;
    }

    // check session state to see if we should open this cards responses
    if (autoexpand || isCached) {
      let hasExpanded = false;
      var cacheddata = TremrStorage.getSession(
        "expand." + this.props.identifier
      );
      if (autoexpand || (cacheddata && cacheddata == "open")) {
        hasExpanded = true;
        this.expand();
      }

      var cachedshowcontentdata = TremrStorage.getSession(
        "content." + this.props.identifier
      );
      if (cachedshowcontentdata && cachedshowcontentdata == "open") {
        this.showContent();
      }

      // if we haven't expanded we can load the wall callback
      if (!hasExpanded) {
        _.defer(() => {
          // must be delayed
          this.wallLoadedCallback();
        });
      }
    } else {
      // set not expanded and not content shown
      TremrStorage.setSession("expand." + this.props.identifier, "closed");
      TremrStorage.setSession("content." + this.props.identifier, "closed");
      _.defer(() => {
        // must be delayed
        if (this.props.loadedCallback) {
          this.props.loadedCallback();
        }
      });
    }

    // listen for content being loaded
    this.listenTo(
      Tremr.dispatcher,
      "tremr:content:post:" + this.props.post.get("id"),
      function (model) {
        // when loaded always update cache as an individual entry so we can load
        // on back button
        let cachekey =
          "collection." +
          JSON.stringify({
            src: "post.content",
            post_ids: [this.props.post.get("id")],
          });
        let data = {
          data: [model.attributes],
        };
        TremrStorage.setSession(cachekey, data);

        // update state
        this.setState({
          post_content: model,
        });

        _.defer(() => {
          this.contentLoadedCallback();
        });
      }
    );

    // event fired whenever a response is posted, so we can refreh the parent's response list if shown
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:response",
      function (model) {
        var slug = model.get("parent")["slug"];

        // did a response get posted for THIS post
        if (slug == this.props.post.get("slug")) {
          console.log("RESPONSE POSTED - REFRESH");

          // increment responses
          // var newState = {
          //    children_count: this.state.children_count + 1,
          //    direct_children_count: this.state.direct_children_count + 1
          // };
          //
          // // refresh responses
          // if (this.state.responsesTab == 'direct responses' || this.state.responsesTab == 'responses') {
          //    this.refs['responsesComponent'].refresh();
          // } else {
          //    // switch to responses
          //    newState.responsesTab = 'direct responses';
          // }
          //
          // this.setState(newState);
        }
      },
      this
    );

    // see if this post is deleted
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:delete:success",
      function (post_id) {
        if (this.props.post.get("slug") == post_id) {
          this.setState({ isDeleted: true });
        }
      }.bind(this)
    );

    // highlight if the current feed was last seen before this post was created
    this.listenTo(
      Tremr.dispatcher,
      "tremr:feed:lastseen",
      function (data) {
        let lastSeen = moment(data.seen_at);

        // iterate feeds (so we can compare against the date it was added to this feed)
        _.each(
          this.props.post.get("feeds"),
          function (feed) {
            if (feed.name == data.feed) {
              let postAdded = moment(feed.added, "YYYY-MM-DDTHH:mmZ");

              if (postAdded.isAfter(lastSeen)) {
                this.setState({ isUnseen: true });
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    // highlight if the current tag was last seen before this post was created
    this.listenTo(
      Tremr.dispatcher,
      "tremr:tag:lastseen",
      function (data) {
        let lastSeen = moment(data.seen_at);

        // iterate feeds (so we can compare against the date it was added to this feed)
        _.each(
          this.props.post.get("feeds"),
          function (feed) {
            if (feed.name == data.feed) {
              let postAdded = moment(feed.added, "YYYY-MM-DDTHH:mmZ");

              if (postAdded.isAfter(lastSeen)) {
                this.setState({ isUnseen: true });
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    window.removeEventListener("scroll", this.handleScroll);

    this.stopListening();
  },

  // open a card
  openCard: function (event) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: this.getPostContext() });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    if (this.state.isDeleted) {
      return;
    }

    this.openContext(event, this.getPostContext());
  },

  // get the context for this post
  getPostContext: function () {
    var route = Tremr.stores.postStore.route(this.props.post);
    return route;
  },

  // handle moving off the card, or onto a different link
  cardUnhover: function (event) {
    event.preventDefault();
    event.stopPropagation();
    var node = ReactDOM.findDOMNode(this);
    node.classList.remove("hover");
  },

  // handle moving on the card, or off a different link
  cardHover: function (event) {
    event.preventDefault();
    event.stopPropagation();
    var node = ReactDOM.findDOMNode(this);
    node.classList.add("hover");
  },

  getChosenReason: function () {
    let chosenReason = null;
    if (this.props.post.get("reasons")) {
      _.each(this.props.post.get("reasons"), function (reason) {
        if (reason["reason"] == "repost") {
          if (
            !chosenReason ||
            reason["reposted_at"] < chosenReason["reposted_at"]
          ) {
            chosenReason = reason;
          }
        }
      });
    } else if (this.props.post.get("repost")) {
      chosenReason = this.props.post.get("repost");
    }
    return chosenReason;
  },

  // navigate to the users feed
  openContext: function (event, context) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: context });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(context, scrollpos);
  },

  openAuthorFeed: function (event) {
    let feedContext = {
      target: "feed",
      scope: "posts",
      subfeed: "featured",
      feed:
        this.props.post.get("channel_author_feed") ||
        this.props.post.get("author_feed"),
    };

    this.openContext(event, feedContext);
  },

  openParentAuthorFeed: function (event) {
    if (this.props.post.get("parent")) {
      let parentSlug = this.props.post.get("parent")["slug"];
      let feedContext = {
        target: "feed",
        scope: "posts",
        subfeed: "featured",
        feed: parentSlug[0],
      };

      this.openContext(event, feedContext);
    }
  },

  openRepostFeed: function (event) {
    let feed = null;
    let chosenReason = this.getChosenReason();
    if (chosenReason && chosenReason["reason"] == "repost") {
      feed = chosenReason["feed"];
    } else if (
      this.props.post.get("repost") &&
      this.props.post.get("repost")["author_feed"]
    ) {
      feed = this.props.post.get("repost")["author_feed"];
    }
    let feedContext = {
      target: "feed",
      scope: "posts",
      subfeed: "featured",
      feed: feed,
    };

    this.openContext(event, feedContext);
  },

  openTag: function (event) {
    let tag = event.currentTarget.getAttribute("data-tag");
    let tagContext = null;

    tagContext = {
      target: "tag",
      tag: tag,
    };

    tagContext ? this.openContext(event, tagContext) : null;
  },

  loadMore: function () {
    let responsesComponent = this.refs["responsesComponent"];
    if (responsesComponent) {
      responsesComponent.loadMore();
    }
  },

  checkContentBottom: function () {
    // if the bottom of the post content then hide the next button and show the close button
    let contentNode = ReactDOM.findDOMNode(this.refs["content"]);
    if (this.state.showContent && contentNode) {
      let contentRect = contentNode.getBoundingClientRect();
      let atBottom = contentRect.bottom - window.innerHeight < 0;
      this.setState({
        contentBottom: atBottom,
      });
    }
  },

  // when expanded watch for the bottom of the wall
  handleScroll: _.throttle(function (event) {
    this.checkContentBottom();

    // if we have more responses then infinate scroll
    if (this.props.post.get("direct_responses_count") > 20) {
      let node = ReactDOM.findDOMNode(this);
      if (node) {
        let rect = node.getBoundingClientRect();
        if (rect.bottom - 140 < window.innerHeight) {
          this.loadMore();
        }
      }
    }
  }, 333),

  // expand the child posts and log to session that this is open
  expand: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    // log the expand, so we can auto-expand when coming back
    if (this.state.expanded) {
      TremrStorage.setSession("expand." + this.props.identifier, "closed");
      // window.removeEventListener("scroll", this.handleScroll);
    } else {
      TremrStorage.setSession("expand." + this.props.identifier, "open");

      // only add handler when needed
      if (
        this.props.post.get("direct_responses_count") > 20 &&
        !this.state.showContent
      ) {
        window.addEventListener("scroll", this.handleScroll);
      }
    }

    // fire event
    if (this.props.onToggleResponses) {
      this.props.onToggleResponses(!this.state.expanded);
    }

    // toggle expanded
    this.setState({
      expanded: !this.state.expanded,
    });
  },

  hideContent: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    TremrStorage.setSession("content." + this.props.identifier, "closed");

    // this.setState({showContent: 2});
    this.setState({ showContent: false });

    // scroll to the top of this post
    let node = ReactDOM.findDOMNode(this);
    let rect = node.getBoundingClientRect();
    let newPos = window.pageYOffset + rect.top - 40;
    $.scrollTo({ left: 0, top: newPos }, { duration: 200 });
  },

  // load content (if not already loaded and show)
  loadContent: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    // always request its own content
    if (!this.state.postContent) {
      let ids = [this.props.post.get("id")];
      Tremr.dispatcher.message(this, "tremr:content:forpost", ids);
    }

    this.showContent();
  },

  showContent: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    TremrStorage.setSession("content." + this.props.identifier, "open");

    // only if not already active,
    if (
      !this.state.expanded ||
      this.props.post.get("direct_responses_count") <= 20
    ) {
      window.addEventListener("scroll", this.handleScroll);
    }

    _.delay(() => {
      this.checkContentBottom(); // needed, otherwise only activated on scroll
    });

    // this.setState({showContent: 2});
    this.setState({ showContent: true });
  },

  // open in the editor
  edit: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    Tremr.dispatcher.message(this, "tremr:editor:open", {
      post_ids: this.props.post.get("slug"),
    });
  },

  nextPost: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    // scroll to the bottom of this post
    let node = ReactDOM.findDOMNode(this);
    let rect = node.getBoundingClientRect();
    let newPos = window.pageYOffset + rect.top + rect.height - 40;
    $.scrollTo({ left: 0, top: newPos }, { duration: 200 });
  },

  // respond
  respond: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    let data = {
      parent: this.props.post,
      onPost: function (post) {
        // close the editor
        Tremr.dispatcher.message(this, "tremr:editor:close");

        // inject the post
        post.unset("ancestors");
        post.set("injected", true);
        if (this.refs["responsesComponent"]) {
          this.refs["responsesComponent"].inject(post.attributes);

          let currentResponses =
            this.state.currentResponsesCount ||
            this.props.post.get("children_count") ||
            0;
          currentResponses = currentResponses + 1;
          this.setState({
            currentResponsesCount: currentResponses,
          });
        } else {
          // open responses AND inject afterwards
          let pendingResponses = this.state.pendingResponses;
          pendingResponses.push(post.attributes);
          let currentResponses =
            this.state.currentResponsesCount ||
            this.props.post.get("children_count") ||
            0;
          currentResponses = currentResponses + pendingResponses.length;
          this.setState({
            pendingResponses: pendingResponses,
            expanded: true,
            currentResponsesCount: currentResponses,
          });
        }
      }.bind(this),
    };
    if (this.props.post.get("channel_name")) {
      data.channel = this.state.channel;
    }

    Tremr.dispatcher.message(this, "tremr:editor:open", data);
  },

  contentLoadedCallback: function (data, wallStyle) {
    // callback only when we have both the wall loaded And
    // the content loaded
    if (this.state.wallLoaded) {
      this.props.loadedCallback(data, wallStyle);
    }
  },

  wallLoadedCallback: function (data, wallStyle) {
    // callback only when we have both the wall loaded And
    // the content loaded
    this.setState({
      wallLoaded: true,
    });

    if (this.state.showContent == false || this.state.post_content) {
      this.props.loadedCallback(data, wallStyle);
    }
  },

  openPostMenu: function (event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    let url = Tremr.routes.urlFor({ primary: this.getPostContext() });
    let imageUrl = null;
    if (this.props.post.get("image")) {
      imageUrl = this.props.post.get("image").secure_url;
    } else if (this.props.post.get("embed")) {
      if (this.props.post.get("embed").thumbnail_url) {
        imageUrl = this.props.post.get("embed").thumbnail_url;
      } else {
        imageUrl = this.props.post.get("embed").original_url;
      }
    }

    let summary = "";
    if (this.props.post.get("summary")) {
      summary = this.props.post.get("summary").replace(/&[^;]{2,3,4};/g, " ");
    }

    var overlayData = {
      class: "Tremr.Post.Menu",
      style: "sidebar",
      props: {
        post_id: this.props.post.get("id"),
        post_slug: this.props.post.get("slug"),
        author_id: this.props.post.get("author_id"),
        post_author: this.props.post.get("author_name"),
        post_title: this.props.post.get("title"),
        post_url: url,
        post_imageUrl: imageUrl,
        post_summary: summary,
        isFactChecked: this.props.post.get("factcheck") || false,
      },
    };

    Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
  },

  openBodyRecommends: function () {
    this.setState({
      bodyRecommendOpen: true,
    });
  },

  // display
  render: function () {
    // have we got old v1 content?
    var isV1 = this.props.post.get("v") == "1";

    // same horrible hack to see if we're cached
    let fullContext = Tremr.context.getState();
    let isCached = fullContext.cache == true;

    var url = Tremr.routes.urlFor({ primary: this.getPostContext() });

    var title = this.props.post.get("title");
    if (title) {
      title = <Tremr.Generic.Abbreviations text={title.trim()} />;
    }

    let summaryWordCount = TremrUtils.countWords(
      this.props.post.get("summary")
        ? this.props.post.get("summary")
        : this.props.post.get("generated_summary")
    );
    let contentWordCount = this.props.post.get("wordcount");
    let hasMoreContent = contentWordCount > summaryWordCount;
    if (
      this.props.post.get("contentsummary") &&
      this.props.post.get("contentsummary")["images"] > 0
    ) {
      hasMoreContent = true;
    }

    let nextButton = null;
    if (this.state.showContent && !this.state.contentBottom) {
      nextButton = (
        <div
          className="next-button content-card-button"
          onClick={this.nextPost}
        >
          <Tremr.Generic.SvgIcon icon="dropdown-line" fill={true} />
        </div>
      );
    }
    let closeButton = null;
    if (this.state.showContent && this.state.contentBottom) {
      closeButton = (
        <div
          className="close-button content-card-button"
          onClick={this.hideContent}
        >
          <Tremr.Generic.SvgIcon icon="close-up" fill={true} />
        </div>
      );
    }

    let tags = this.props.post.get("tags");
    let tagLinks = null;
    if (tags.length > 0) {
      tags = tags.slice(0, 3);
      tagLinks = _.flatten(
        _.map(
          tags,
          function (tag) {
            let tagLink = (
              <a
                key={"tag-" + tag}
                className="tag"
                href={"/tags/" + tag}
                onClick={this.openTag}
                data-tag={tag}
              >
                <Tremr.Generic.Abbreviations uppercaseOnly={false} text={tag} />
              </a>
            );
            return [tagLink, ", "];
          }.bind(this)
        ),
        true
      );
    }
    if (tagLinks) {
      tagLinks.pop();
      tagLinks = <div className="tags">{tagLinks}</div>;
    }

    let responsesButton = null;
    let responses = null;
    let responses_container_styles = {
      minHeight: 0,
    };
    if (
      this.state.currentResponsesCount > 0 ||
      this.state.pendingResponses.length > 0 ||
      this.state.expanded ||
      (this.props.showResponses &&
        this.props.post.get("responses_count") &&
        this.props.post.get("responses") &&
        this.props.post.get("responses").length > 0)
    ) {
      responsesButton = (
        <div className="responses-button-container">
          <div className="responses-button" onClick={this.expand}>
            <span>
              {this.state.currentResponsesCount ||
                this.props.post.get("responses_count")}
            </span>
          </div>
          <Tremr.Generic.SvgIcon icon="speechpointer" fill={true} />
        </div>
      );

      if (this.state.expanded) {
        let preload_responses = 1;
        if (
          this.props.post.get("direct_responses_count") > 1 &&
          this.props.post.get("direct_responses_count") < 20
        ) {
          preload_responses = this.props.post.get("direct_responses_count");
        } else if (this.props.post.get("direct_responses_count") > 20) {
          preload_responses = 20;
        }
        responses_container_styles = {
          minHeight: preload_responses * 70,
        };
        let slug = this.props.post.get("slug").split("!");

        let cardOptions = { showBreadcrumbs: false, autoexpand: true };
        let wall = Tremr.stores.postStore.getResponsesWall(
          "direct responses",
          slug[0],
          slug[1],
          isCached,
          this.wallLoadedCallback,
          _.noop,
          this.state.childRenderStyle,
          this.props.identifier,
          null,
          null,
          cardOptions
        );
        responses = (
          <div
            className="responses-container"
            style={responses_container_styles}
          >
            <div className="responses">{wall}</div>
          </div>
        );
      }
    }

    // var views = this.props.post.get("views");
    // if (!views) {
    //     views = "0";
    // }

    let recommended = false;
    if (
      this.props.post.get("author_tags") &&
      _.contains(
        this.props.post.get("author_tags"),
        Tremr.Theme.RecommendedWriter.Tag
      )
    ) {
      recommended = true;
    }

    var poststats = null;
    if (!this.state.showContent && hasMoreContent) {
      if (
        this.props.post.get("contentsummary") &&
        this.props.post.get("contentsummary")["total"] > 0
      ) {
        poststats = (
          <div className="post-stats" onClick={this.loadContent}>
            <ContentSummary post={this.props.post} />
          </div>
        );
      } else if (
        this.props.post.get("wordcount") &&
        this.props.post.get("wordcount") > 0
      ) {
        poststats = (
          <div className="post-stats" onClick={this.loadContent}>
            {this.props.post.get("wordcount").toLocaleString()}&nbsp;words…
          </div>
        );
      }
    }
    // hide summary when content in summary is already visible
    // if (this.props.post.get("image") &&
    //     this.props.post.get("contentsummary")["images"] == 1 &&
    //     this.props.post.get("contentsummary")["total"] == 1) {
    //     poststats = null;
    // }
    // if (this.props.post.get("embed") &&
    //     this.props.post.get("contentsummary")["links"] == 1 &&
    //     this.props.post.get("contentsummary")["total"] == 1) {
    //     poststats = null;
    // }
    // if (this.props.post.get("embed") &&
    //     this.props.post.get("contentsummary")["images"] == 1 &&
    //     this.props.post.get("contentsummary")["links"] == 1 &&
    //     this.props.post.get("contentsummary")["total"] == 2) {
    //     poststats = null;
    // }

    // why is this in your feed
    let chosenReason = this.getChosenReason();
    let repostedBy = <PostOrigin post={this.props.post} />;
    let originRules = OriginRules(this.props.post);

    let viaAvatar = null;
    if (originRules.isVia && originRules.viaAvatar) {
      viaAvatar = (
        <Tremr.Generic.Image
          image={originRules.viaAvatar}
          height={20}
          width={20}
        />
      );
    }

    // let viaAvatar = null;
    // if (chosenReason && chosenReason.feed != Tremr.Theme.HomepageFeed && chosenReason.avatar && this.props.post.get('author_avatar_image') && this.props.post.get('author_avatar_image').public_id != chosenReason.avatar.public_id) {
    //   if (this.props.post.get("author_feed") && this.props.post.get("repost") &&
    //       this.props.post.get("author_feed") != this.props.post.get("repost").author_feed) {
    //         // don't show via avatar
    //       } else {
    //     viaAvatar = <Tremr.Generic.Image image={chosenReason.avatar} height={24} width={24} />;
    //   }
    // }

    let comment = true;
    if (
      (this.props.post.get("generated_summary") &&
        this.props.post.get("generated_summary").length > 0) ||
      (this.props.post.get("summary") &&
        this.props.post.get("summary").length > 0)
    ) {
      comment = false;
    } else if (
      this.props.post.get("contentsummary") &&
      this.props.post.get("contentsummary").total > 0
    ) {
      // comment, with media
    } else {
      if (!this.state.showContent) {
        // poststats = <div className="post-stats">Comment</div>;
        poststats = null;
      }
    }

    // work out our top level class
    var classes = {
      "post-content-card": true,
      "has-responses": responsesButton,
      expanded: this.state.expanded,
      repost: chosenReason,
      deleted: this.state.isDeleted,
      unseen: this.state.isUnseen,
      "show-breadcrumbs": this.state.breadcrumbsExpanded,
      "has-breadcrumbs":
        this.props.post.get("ancestors") &&
        this.props.post.get("ancestors").length > 0,
      "show-content": this.state.showContent,
      // "show-content": this.state.showContent == 2,
      // "show-partial-content": this.state.showContent == 1,
      comment: comment,
      "has-more": hasMoreContent,
      response: this.props.post.get("parent"),
      injected: this.props.post.get("injected"),
    };
    if (recommended) {
      classes[Tremr.Theme.RecommendedWriter.Tag.replace(" ", "-")] = true;
    }
    if (this.props.post.get("fieldset")) {
      classes[this.props.post.get("fieldset")] = true;
    }
    classes["columns-" + this.state.columns] = true;
    if (this.props.post.get("post_type")) {
      classes[this.props.post.get("post_type")] = true;
    }
    if (
      this.props.post.get("author_tags") &&
      _.contains(
        this.props.post.get("author_tags"),
        Tremr.Theme.RecommendedWriter.Tag
      )
    ) {
      classes["author-tags"] = true;
    }

    classes = classNames(classes);

    let avatar = null;
    if (this.props.post.get("author_avatar_image")) {
      avatar = (
        <Tremr.Generic.Image
          image={this.props.post.get("author_avatar_image")}
          height={40}
          width={40}
        />
      );
    }

    let created = moment(
      this.props.post.get("created_at"),
      "YYYY-MM-DDTHH:mmZ"
    );
    let yearDiff =
      parseInt(moment().format("YYYY")) - parseInt(created.format("YYYY"));
    let age = created.format("D MMM");
    if (yearDiff > 0) {
      age = created.format("MMM'YY");
    }
    let timestamp = created.format();
    let isToday = created.format("D M YY") == moment().format("D M YY");
    if (isToday) {
      age = created.format("H:mma");
    }
    // let age = TremrUtils.agoDescription(created);

    // let stat = <ActivitySum tags={['recommend']} post_id={this.props.post.get("id")} initialCount={Tremr.stores.postStore.reactSum(this.props.post, ['recommend'])}/>;

    let headerImageMaxHeight = 320;
    if (TremrUtils.isDeviceOrNarrow()) {
      headerImageMaxHeight = 215;
    }

    if (this.state.showContent) {
      headerImageMaxHeight = false;
    }

    let image = null;
    let imageHeader = this.props.post.get("image");
    if (imageHeader) {
      image = (
        <div onClick={this.openCard}>
          <Tremr.Generic.Image
            key={"image-" + imageHeader.public_id + "-" + headerImageMaxHeight}
            image={imageHeader}
            maxHeight={headerImageMaxHeight}
            height={false}
            width="auto"
            resizable={true}
          />
        </div>
      );
      if (imageHeader.caption) {
        // imageCaption = <p className='caption'>{imageHeader.caption}</p>;
      }
    } else if (this.props.post.get("embed")) {
      image = (
        <Tremr.Generic.Embed
          showLinkDescription={this.props.post.get("fieldset") != "article"}
          embed={this.props.post.get("embed")}
          maxHeight={headerImageMaxHeight}
          height={false}
          width="auto"
          resizable={true}
        />
      );
      if (this.props.post.get("embed").caption) {
        // imageCaption = <p className='caption embed-caption'>{this.props.post.get('embed').caption}</p>;
      }
    }
    if (image) {
      image = <div className="header-image">{image}</div>;
    }

    // show edit button if author OR admin
    var user = this.state.user;
    var editButton = null;
    if (
      user &&
      (user.get("admin") == "yes" ||
        user.get("_id") == this.props.post.get("author_id"))
    ) {
      if (!isV1) {
        editButton = (
          <button
            title="Edit Post"
            className="edit activity-button dropup-item"
            onClick={this.edit}
          >
            <Tremr.Generic.SvgIcon
              key="icon"
              icon="edit"
              classes="button icon"
            />
            <div key="description" className="description">
              <h6>
                <em>Edit</em> this post
              </h6>
              <p>Make changes or delete post</p>
            </div>
          </button>
        );
      }
    }

    let bookmarkDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
      this.props.post.get("id"),
      ["bookmark"]
    );
    let repostDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
      this.props.post.get("id"),
      ["repost"]
    );
    let upvoteDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
      this.props.post.get("id"),
      ["recommend"]
    );
    let postScoreDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
      this.props.post.get("id"),
      ["recommend", "repost", "bookmark"],
      { set: false, sum: true }
    );

    let actions = (
      <div className="actions">
        <ActivitySumWithFBData
          post_id={this.props.post.get("id")}
          onClick={this.openBodyRecommends}
          dataPoints={postScoreDataPoints}
          tags={["recommend", "repost", "bookmark"]}
        />
        <UpvoteButtonWithFBData
          post_id={this.props.post.get("id")}
          dataPoints={upvoteDataPoints}
          tag="recommend"
          onChange={this.openBodyRecommends}
        />
        <RepostButtonWithFBData
          onClick={this.openBodyRecommends}
          dataPoints={repostDataPoints}
          tag="repost"
          post_id={this.props.post.get("id")}
          post_title={this.props.post.get("title")}
          post_author={this.props.post.get("author_name")}
        />
        <ActivityIconButtonWithFBData
          post_id={this.props.post.get("id")}
          onClick={this.openBodyRecommends}
          dataPoints={bookmarkDataPoints}
          tag="bookmark"
        />
        <SvgIcon
          key="openPostMenu"
          classes="post-menu-icon icon"
          icon="menu"
          onClick={this.openPostMenu}
        />
      </div>
    );

    let reactions = (
      <Reactions
        onOpen={this.openBodyRecommends}
        open={this.state.bodyRecommendOpen}
        post_id={this.props.post.get("id")}
      />
    );

    let postSummary = null;
    let postUserSummary = null;
    let postContent = null;
    if (this.state.showContent && this.state.post_content) {
      let getHTML = function () {
        let content = this.state.post_content.get("content") || "";
        return { __html: content };
      }.bind(this);

      if (this.state.post_content.get("content_blocks")) {
        // new posts use content_blocks
        postContent = (
          <Tremr.Editor.Richtext
            margins={30}
            initialBlocks={this.state.post_content.get("content_blocks")}
            key="richtext"
            ref="richtext"
          />
        );
      } else {
        postContent = (
          <div className="post-content" dangerouslySetInnerHTML={getHTML()} />
        );
      }
    }
    if (
      this.props.post.get("summary") &&
      this.props.post.get("summary").length > 0 &&
      this.props.post.get("generated_summary") &&
      this.props.post.get("generated_summary").length > 0
    ) {
      postUserSummary = (
        <div className="user-summary">{this.props.post.get("summary")}</div>
      );
    }
    if (!this.state.showContent || !postContent) {
      postSummary = this.props.post.get("generated_summary")
        ? this.props.post.get("generated_summary")
        : this.props.post.get("summary");
    }

    // never show post stats when expanded or when we have nothing beyond the summary
    if (this.state.showContent || contentWordCount < summaryWordCount) {
      poststats = null;
    }

    let breadcrumbs = null;
    let ancestorTitle = null;
    let parentAuthor = null;
    if (
      this.props.post.get("origin") &&
      this.props.post.get("origin")["id"] != this.props.post.get("parent")["id"]
    ) {
      ancestorTitle = (
        <p className="ancestor-title">
          Re: {this.props.post.get("origin")["title"]}
        </p>
      );
    }
    if (this.props.post.get("parent")) {
      // <Tremr.Generic.SvgIcon icon="respond"/>
      parentAuthor = (
        <div className="parent-author">
          to {this.props.post.get("parent")["author_name"]}{" "}
        </div>
      );
    }

    if (
      this.props.post.get("ancestors") &&
      this.props.post.get("ancestors").length > 0
    ) {
      if (this.props.showBreadcrumbs) {
        // breadcrumbs = (<CardBreadcrumbs onToggle={this.breadcrumbsToggled} posts={this.props.post.get("ancestors")} />);
        let breadcrumbPosts = this.props.post.get("ancestors");
        breadcrumbs = (
          <Breadcrumbs
            forslug={this.props.post.get("slug")}
            identifier={"content-card-" + this.props.post.get("slug")}
            loadedCallback={this.breadcrumbsLoaded}
            cache={this.props.cache}
            posts={breadcrumbPosts}
          />
        );
      }
    }

    let titleHeadline = null;
    let titleComment = null;
    // if (this.props.post.get("fieldset") == "article") {
    if (!comment) {
      titleHeadline = (
        <h1 onClick={this.openCard}>
          <a onClick={this.openCard} href={url}>
            {title}
          </a>
        </h1>
      );
    } else {
      titleComment = (
        <div className="comment" onClick={this.openCard}>
          <a onClick={this.openCard} href={url}>
            {title}
          </a>
        </div>
      );
    }

    let reputationStarsDataPoints = [
      ReputationHelper.getUserReputationStarsDataPoint(
        this.props.post.get("author_id")
      ),
      ReputationHelper.getPostReputationStarsDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
      ReputationHelper.enhanceDataPoint(
        ReputationHelper.getPostReputationBadgeDataPoint(
          this.props.post.get("author_id"),
          this.props.post.get("id")
        ),
        {
          name: "suppress",
          check: function (value) {
            return value && value.length > 0;
          },
        }
      ),
    ];
    const highlightColor = this.state.highlightColor;
    const reputationStars = (
      <StarsWithFBData
        key={"stars" + highlightColor}
        alignment="right"
        highlightColor={highlightColor}
        height={15}
        width={54}
        scale={window.devicePixelRatio}
        dataPoints={reputationStarsDataPoints}
      />
    );

    const reputationOverviewDataPoints = [
      ReputationHelper.renameDataPoint(
        ReputationHelper.getUserReputationStarsDataPoint(
          this.props.post.get("author_id")
        ),
        "user_rep_stars"
      ),
      ReputationHelper.getPostReputationStarsDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
      ReputationHelper.getPostReputationAutoTagsDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
    ];
    const reputationOverview = (
      <ReputationOverviewWithFBData
        key={"reqover" + highlightColor}
        align="right"
        highlightColor={highlightColor}
        authorName={this.props.post.get("author_name")}
        dataPoints={reputationOverviewDataPoints}
      >
        {reputationStars}
      </ReputationOverviewWithFBData>
    );

    const reputationBadgeDataPoints = [
      ReputationHelper.getUserReputationBadgeDataPoint(
        this.props.post.get("author_id")
      ),
    ];
    const userBadge = (
      <ReputationBadgeWithFBData dataPoints={reputationBadgeDataPoints} />
    );

    const reputationPostBadgeDataPoints = [
      ReputationHelper.getPostReputationBadgeDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
    ];
    const postWarningBadge = (
      <PostWarningBadgeOverviewWithFBData
        dataPoints={reputationPostBadgeDataPoints}
      />
    );

    let channel_feed = null;
    if (this.props.post?.get('channel_title')) {
      channel_feed = this.props.post.get('author_feed');

      // check if this is only in the community subfeed
      let memberFeeds = this.props.post.get('feeds').filter((feed) => {
        return (feed['name'] == channel_feed && feed['subfeed'] != 'community');
      });
      
      if (!memberFeeds || memberFeeds.length == 0) {
        channel_feed = null;
      }
    }
        
    let replyArea = (
      <ReplyAreaWithFBData
        dataPoints={Tremr.stores.postStore.getDoorPolicyDataPoints(
          this.props.post.get("id"), channel_feed
        )}
        onClick={this.respond}
      />
    );

    return (
      <div className={classes}>
        <Tremr.Generic.Mouseover
          onMouseOver={this.cardUnhover}
          onMouseLeave={this.cardHover}
        >
          {breadcrumbs}
        </Tremr.Generic.Mouseover>

        <article>
          <div className="card-content" ref="content">
            <a className="avatar-link" onClick={this.openAuthorFeed}>
              {avatar}
              {viaAvatar}
            </a>

            <div className="byline-content">
              {repostedBy}
              {userBadge}
              <div className="byline">
                <div className="author" onClick={this.openAuthorFeed}>
                  {this.props.post.get("author_name")}
                  <time dateTime={timestamp}>{age}</time>
                </div>
                {reputationOverview}
                {postWarningBadge}
              </div>

              {ancestorTitle ? ancestorTitle : null}

              {parentAuthor ? parentAuthor : tagLinks}

              <FactcheckBadge
                post_id={this.props.post.get("id")}
                set={this.props.post.get("factcheck") || false}
              />
            </div>
            <div className="content">
              {titleHeadline}

              {titleComment}

              {postUserSummary}

              {image}

              <section className="post-content">
                {postSummary}
                {postContent}
              </section>

              {poststats}

              {actions}

              {reactions}
            </div>

            {closeButton}
            {nextButton}

            {responsesButton}

            {replyArea}
          </div>
        </article>

        {responses}
      </div>
    );
  },
});
