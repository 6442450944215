var Backbone = require("backbone");
var Config = require("config");

// Tremr.Models.User
module.exports = Backbone.Model.extend({
  urlRoot: Config.apiurl + "/api/users",

  // lets check we have everything we need
  validate: function (attributes) {
    var errors = { count: 0 };

    if (attributes.name === undefined || attributes.name == "") {
      errors.name = "Please enter a name";
      errors.count = errors.count + 1;
    }

    if (attributes.feedname === undefined || attributes.feedname == "") {
      errors.feedname = "Please enter a username";
      errors.count = errors.count + 1;
    }

    if (errors.count > 0) {
      return errors;
    }
  },

  // see if the user is a X role of the feed
  // uses the users feed list (unsafe) unless a
  // feed object is passed,
  hasRole(role, feedname, feedObj) {
    let contributors = null;
    if (feedObj) {
      contributors = feedObj.get("contributors") || feedObj["contributors"];
    }

    if (feedObj !== undefined && contributors) {
      let isRole = false;
      _.each(
        contributors,
        function (c) {
          if (c.role == role && c.id == this.get("_id")) {
            isRole = true;
          } else if (c.permission == role && c.id == this.get("_id")) {
            isRole = true;
          }
        }.bind(this)
      );
      return isRole;
    } else {
      var feeds = this.get("feeds");
      if (!feeds || feeds.length == 0) {
        return false;
      }

      var isRole = false;
      _.each(feeds, function (f) {
        if (f.name == feedname && !f.channel) {
          isRole = true; // users userfeed
        } else if (f.role == role && f.name == feedname) {
          isRole = true;
        } else if (f.permission == role && f.name == feedname) {
          isRole = true;
        }
      });
      return isRole;
    }
  },

  isEditorOfFeed: function (feedname, feedObj) {
    return (
      this.hasRole("editor", feedname, feedObj) ||
      this.hasRole("admin", feedname, feedObj)
    );
  },

  isContributorOfFeed: function (feedname, feedObj) {
    return (
      this.hasRole("editor", feedname, feedObj) ||
      this.hasRole("admin", feedname, feedObj) ||
      this.hasRole("contributor", feedname, feedObj)
    );
  },

  // check if the user is following an entity
  isFollowing: function (entity, id, subfeed) {
    if (subfeed === undefined) {
      subfeed = false;
    }

    var following = this.get("following");
    if (!following || following.length == 0) {
      return false;
    }

    var found = false;
    _.each(following, function (follow) {
      if (follow.entity == entity && follow.id == id) {
        if (subfeed == false) {
          found = follow;
        } else {
          if (follow.subfeeds && follow.subfeeds.includes(subfeed)) {
            found = follow;
          }
        }
      }
    });

    return found;
  },

  // add to following
  addFollowing: function (entity, id, subfeeds) {
    var following = this.get("following");
    if (!following || following.length == 0) {
      return false;
    }

    // replace is alredy present
    let existingFollowIndex = following.findIndex((item) => {
      return item.entity == entity && item.id == id;
    });

    if (existingFollowIndex > -1) {
      following[existingFollowIndex].subfeeds = subfeeds;
    } else {
      // or add
      following.unshift({
        entity: entity,
        id: id,
        subfeeds: subfeeds,
      });
    }

    this.set("following", following);
  },

  // remove from following
  removeFollowing: function (entity, id) {
    var following = this.get("following");
    if (!following || following.length == 0) {
      return false;
    }

    var following = _.reject(following, function (f) {
      return f.entity == entity && f.id == id;
    });

    this.set("following", following);
  },
});
