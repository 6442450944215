var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.UserRegistration
module.exports = Backbone.Model.extend({
    url: Config.apiurl+'/api/users.json',
    paramRoot: 'user',

    defaults: {
        "name": "",
        "feed": "",
        "email": "",
        "password": ""
    },

    // lets check we have everything we need
    validate: function (attributes) {

        var errors = {count: 0};

        if (attributes.name != undefined && attributes.name.replace(/\s/gi, "").length == 0) {
            errors.name = "Please enter your full name";
            errors.count = errors.count + 1;
        }

        if (attributes.email != undefined && Tremr.Utils.validEmail(attributes.email) == false) {
            this.set('email', '');
            errors.email = "Please enter a valid email address";
            errors.count = errors.count + 1;
        }

        if (attributes.social_data == null && attributes.password != undefined && attributes.password.replace(/\s/gi, "").length == 0) {
            errors.password = "Please enter a password";
            errors.count = errors.count + 1;
        } else if (attributes.social_data == null && attributes.password != undefined && attributes.password.replace(/\s/gi, "").length < 8) {
            this.set('password', '');
            errors.password = "Must be 8 or more characters";
            errors.count = errors.count + 1;
        }

        if (attributes.feedname != undefined && attributes.feedname.replace(/\s/gi, "").length == 0) {
            errors.feedname = "Please enter a username";
            errors.count = errors.count + 1;
        }

        if (errors.count > 0) {
            return errors;
        }
    }

});
