var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.UserSession
module.exports = Backbone.Model.extend({
    // url: Config.apiurl+'/api/sessions.json',
    urlRoot: Config.apiurl+'/api/sessions',
    paramRoot: 'user',

    // lets check we have everything we need
    validate: function (attributes) {

        var errors = {count: 0};

//        if (attributes.email != undefined && Tremr.Utils.validEmail(attributes.email) == false) {
//            errors.email = "Please enter a valid email address";
//            errors.count = errors.count + 1;
//        }

        if (attributes.email != undefined && attributes.email.replace(/\s/gi, "").length == 0) {
            errors.email = "Please enter your email";
            errors.count = errors.count + 1;
        }

        if (attributes.password != undefined && attributes.password.replace(/\s/gi, "").length == 0) {
            errors.password = "Please enter your password";
            errors.count = errors.count + 1;
        }

        if (errors.count > 0) {
            return errors;
        }
    }

});

