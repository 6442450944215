require("./scss/badge.scss");

let React = require("react");
let CreateReactClass = require("create-react-class");
let Stars = require("../controls/stars");
var UserAwareMixin = require("../../mixins/userawaremixin");
var SvgIcon = require("../generic/svgicon");
var Config = require("config");

// designed to be wrapped with a withDBData and then passes liveDataPoints data
// down to an animatedStars control
module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  componentDidMount: function () {
    if (this.props.onRender) {
      this.props.onRender();
    }
  },

  componentDidUpdate: function () {
    if (this.props.onRender) {
      this.props.onRender();
    }
  },

  render: function () {
    if (
      Config.reputation == "admin" &&
      (!this.state.user || this.state.user.get("admin") != "yes")
    ) {
      return null;
    }

    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.badge &&
      this.props.liveDataPoints.badge.data
    ) {
      let badgeName = this.props.liveDataPoints.badge.data;
      let iconName = null;
      let text = null;
      if (badgeName.includes("new")) {
        iconName = "new";
        text = "New Member";
      } else if (badgeName.includes("trust")) {
        iconName = "warning";
        text = "Low Trust Member";
      } else if (badgeName.includes("respect")) {
        iconName = "warning";
        text = "Low Respect Member";
      } else if (badgeName.includes("suspended")) {
        iconName = "suspended";
        text = "Member Suspended";
      }

      if (iconName) {
        return (
          <div className="reputation-badge">
            <Tremr.Generic.SvgIcon icon={iconName} fill={true} />
            <div className="reputation-badge-description">{text}</div>
          </div>
        );
      }

      return null;
    } else {
      return null;
    }
  },
});
