require("./scss/simplecard.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
      post: PropTypes.object.isRequired,
      imageHeight: PropTypes.number,
      loadedCallback: PropTypes.func
    },

    getDefaultProps: function() {
      return {
         imageHeight: 100
      }
   },

    getInitialState: function() {
        return {
            isDeleted: false,
            loadedCallback: _.noop
        };
    },

    // listen for deletes and updates
    componentDidMount: function() {

        _.extend(this, Backbone.Events);

        // see if this post is deleted
        this.listenTo(Tremr.dispatcher, "tremr:post:delete:success", function(post_id) {
            if (this.props.post.get('slug') == post_id) {
                this.setState({
                    isDeleted: true
                });
            }
        }.bind(this));

        _.defer(() => { // must be delayed
           this.props.loadedCallback();
        });

    },

    // unregister listeners
    componentWillUnmount: function() {

        this.stopListening();
    },

    // open a card
    openCard: function(event) {

        event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

        // if meta key, allow normal open - for container use
        // data-href to open in new tab
        if (event.metaKey) {
            if (event.currentTarget && !event.currentTarget.getAttribute('href')) {
                var url = Tremr.routes.urlFor({
                    primary: this.getPostContext()
                });
                window.open(url);
            }
            return;
        }

        event.preventDefault();

        if (this.state.isDeleted) {
            return;
        }

        let scrollpos = window.scrollY;
        Tremr.navigateCurrent(this.getPostContext(), scrollpos);
    },

    // get the context for this post
    getPostContext: function() {
        var route = Tremr.stores.postStore.route(this.props.post);
        return route;
    },

    // display
    render: function() {

        let url = Tremr.routes.urlFor({
            primary: this.getPostContext()
        });

        let image = null;
        let headerImageKey = "card-image";

        const imageHeight = this.props.imageHeight;

        if (this.props.post.get('image')) {
            image = <Tremr.Generic.Image key={headerImageKey} image={this.props.post.get('image')} width="auto" resizable={true} minHeight={imageHeight} height={imageHeight} maxHeight={imageHeight} />;
        } else if (this.props.post.get('embed')) {
            if (this.props.post.get('embed').thumbnail_width || this.props.post.get('embed').width) {
                image = <Tremr.Generic.EmbedThumb key={headerImageKey} showText={false} embed={this.props.post.get('embed')} width="auto" resizable={true} minHeight={imageHeight} height={imageHeight} maxHeight={imageHeight} />;
            }
        }

        let title = this.props.post.get('title');
        let allowedTitleLength = 250;
        (image) ? allowedTitleLength = allowedTitleLength - 50 : '';
        if (title) {
            title = <Tremr.Generic.Abbreviations text={Tremr.Utils.truncate(title.trim(), allowedTitleLength, '')} />;
        }


        let tags = this.props.post.get('tags');
        let tag = null;
        if (tags && tags.length > 0) {
            tag = <div className="tag">{tags[0]}</div>
        }

        // work out our top level class
        var classes = {
            'post-simplecard': true,
            'has-image': image,
            'deleted': this.state.isDeleted
        };
        classes = classNames(classes);

        return (
        <a onClick={this.openCard} href={url} className={classes}>

            {image}

            <div className="info">
                <h2>{title}</h2>
                {tag}
            </div>
        </a>
        );
    }
});
