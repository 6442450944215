require("../layout/scss/staticpage.scss");
require("./scss/acceptinvite.scss");

var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");
// var OptionList = require('../generic/optionlist');
var ReactUtils = require("../../utils/react_utils");
// var TremrStorage = require('../../utils/tremr_storage');
var TremrUtils = require("../../utils/tremr_utils");
var Config = require("config");
var alertify = require("alertify");
var SvgIcon = require("../generic/svgicon");
var UserAwareMixin = require("../../mixins/userawaremixin");
var FirebaseEncode = require("firebase-encode");

// import {
//   encode,
//   decode,
//   encodeComponents,
//   decodeComponents,
// } from 'firebase-encode';

// Tremr.Layout.AcceptInvite
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  getInitialState: function () {
    return {
      name: "",
      email: "",
      token: false,
      validToken: false,
      tab: "loading",
      suggestedTags: [],
    };
  },

  close: function (event) {
    // if the user has signed-in/up then redirect to the homepage
    if (this.state.user) {
      Tremr.navigatePrimary({
        target: "homepage",
        scope: "all",
        sort: "latest",
      });
    }
    Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
  },

  componentDidUpdate: function () {
    if (this.state.tab == "create") {
      this.renderSocialSignin();

      // set to user clicked
      let token = FirebaseEncode.encode(this.props.token);
      let key = "community/approved_invites/" + token + "/is_clicked";
      let fbRef = Tremr.firebase.database().ref(key);
      fbRef.set(true);
    }
  },

  renderSocialSignin: function () {
    // if (!Tremr.Theme.RenderSocialSignin) {
    //   return;
    // }
    //
    // // add the social signup
    // var url = Tremr.current_url.protocol + "//" + Tremr.current_url.hostname;
    // if (Tremr.current_url.port && Tremr.current_url.port != '80' && Tremr.current_url.port != '0') {
    //     url = url + ':' + Tremr.current_url.port;
    // }
    // var social_callback_script = url + '/api/social_callback';
    // // var css = url + '/css/oneall.css';
    // var css = url + Tremr.Theme.OneAllCss;
    // _oneall.push(['social_login', 'set_providers', ['facebook', 'twitter', 'google']]);
    // _oneall.push(['social_login', 'set_grid_sizes', [1,3]]);
    // _oneall.push(['social_login', 'set_custom_css_uri', css]);
    // _oneall.push(['social_login', 'set_callback_uri', social_callback_script]);
    // _oneall.push(['social_login', 'set_event', 'on_open_popup_ui', function(arg) {
    //     this.setState({
    //         isLoading: true
    //     });
    //     return false;
    // }.bind(this)]);
    // _oneall.push(['social_login', 'set_event', 'on_login_end', function(arg) {
    //     this.setState({
    //         isLoading: false
    //     });
    //     return false;
    // }.bind(this)]);
    // _oneall.push(['social_login', 'set_event', 'on_login_end_success', function(arg) {
    //     // console.log("on_login_end_success");
    // }]);
    // _oneall.push(['social_login', 'set_event', 'on_login_redirect', function(arg) {
    //
    //     // console.log("on_login_redirect:");
    //     // console.dir(arg);
    //
    //     // complete sign-in with social but special version will not
    //     // save the user record on the server
    //     Tremr.dispatcher.message(this, "tremr:social_login:signup", arg);
    //     return false;
    // }.bind(this)]);
    //
    // // seriously?  needs to be run last otherwie the JS doesn't fire!
    // _.defer(function() {
    //     _oneall.push(['social_login', 'do_render_ui', 'social_signup']);
    // });
  },

  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    // watch for social sign-up
    this.listenTo(
      Tremr.dispatcher,
      "tremr:social_login:signup:success",
      function (data) {
        // auto-set username
        let slugValidation = ReactUtils.validateSlug(
          data.user.identity.preferredUsername || data.user.identity.displayName
        );
        let feedname = slugValidation.value;

        // move to next stage
        this.setState({
          isLoading: false,
          tab: "username",
          feedname: feedname,
          socialData: data,
        });
      }.bind(this)
    );

    // watch for success and move to step 2
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signup:success",
      function (user) {
        this.setState({
          tab: "rules",
          isLoading: false,
        });

        // update the invitation to show used plus add additional data
        let token = FirebaseEncode.encode(this.props.token);
        let key = "community/approved_invites/" + token + "/used";
        let fbRef = Tremr.firebase.database().ref(key);
        fbRef.set(user.get("_id") || user.get("id"));

        key = "community/approved_invites/" + token + "/user";
        fbRef = Tremr.firebase.database().ref(key);
        fbRef.set({
          user_id: user.get("_id") || user.get("id"),
          username: user.get("feedname"),
          email: user.get("email"),
          name: user.get("name"),
          ip_address: user.get("ip"),
        });
      }.bind(this)
    );

    // watch for validation errors
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signup:failed",
      function (errors) {
        // clear old errors
        _.each(
          this.refs,
          function (ref) {
            if (ref.state.error) {
              ref.setState({
                error: false,
                isLoading: false,
              });
            }
          }.bind(this)
        );

        _.each(
          _.pairs(errors),
          function (error) {
            if (this.refs[error[0]]) {
              this.refs[error[0]].setState({
                error: true,
                errorMessage: error[1],
              });
            }
          }.bind(this)
        );

        this.setState({
          isLoading: false,
        });
      }.bind(this)
    );

    // check for the token
    let token = FirebaseEncode.encode(this.props.token);
    let key = "community/approved_invites/" + token;
    let fbRef = Tremr.firebase.database().ref(key);
    fbRef.once("value").then(
      function (snapshot) {
        let data = snapshot.val();
        if (data && !data.used) {
          this.setState({
            name: data.name,
            email: data.email,
            token: token,
            validToken: true,
            tab: "create",
            // tab: 'follows'
          });
        } else {
          this.setState({
            token: token,
            validToken: false,
            tab: "invalid",
            // tab: 'follows'
          });
        }
      }.bind(this),
      function (error) {
        if (error) {
          this.setState({
            token: token,
            validToken: false,
            tab: "invalid",
          });
        }
      }.bind(this)
    );

    // load the tags that we will suggest in final stage
    TremrUtils.fetch(Config.apiurl + "/api/tags/suggested", {
      method: "POST",
      // body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    }).then(
      function (response) {
        if (response.ok) {
          response.json().then(
            function (suggested) {
              this.setState({
                suggestedTags: suggested,
              });
            }.bind(this)
          );
        } else {
          console.log(
            "Error loading suggested follows:" +
              response.status +
              ":" +
              response.statusText
          );
        }
      }.bind(this)
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.stopListening();
  },

  saveEmail: function (event) {
    if (event) {
      if (typeof ga !== "undefined") {
        ga("send", "event", "button", "create-account");
      }
      //alert(this.refs.email.state.value);
      if (typeof twq !== "undefined" && this.refs.email.state.value) {
          twq('event', 'tw-oaadg-nuh37', {email_address: this.refs.email.state.value});
      }
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.state.isLoading) {
      return;
    }

    var data = {
      name: this.refs.name.state.value,
      email: this.refs.email.state.value,
    };

    // validate!
    let errors = false;
    if (data.name.length == 0) {
      errors = true;

      let newState = {
        error: true,
        errorMessage: "Please enter your name",
      };
      this.refs.name.setState(newState);
    }

    if (data.email.length == 0) {
      errors = true;

      let newState = {
        error: true,
        errorMessage: "Please enter your email address",
      };
      this.refs.email.setState(newState);
    } else {
      let emailValid = ReactUtils.validateEmail(data.email);
      if (!emailValid.valid) {
        errors = true;
        this.refs.email.setState(emailValid);
      }
    }

    if (!errors) {
      this.setState({
        isLoading: true,
      });

      // check email is unused with server
      TremrUtils.fetch(Config.apiurl + "/api/users/email_available", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
      }).then(
        function (response) {
          if (response.ok) {
            response.json().then(
              function (responseData) {
                if (responseData.found) {
                  let newState = {
                    error: true,
                    errorMessage: "This email address is already in use.",
                  };
                  this.refs.email.setState(newState);
                  this.setState({
                    isLoading: false,
                  });
                } else {
                  // this could also return a suggested username for the next stage

                  // auto-set username
                  let slugValidation = ReactUtils.validateSlug(data.name);
                  let feedname = slugValidation.value;

                  // move to next stage
                  this.setState({
                    isLoading: false,
                    tab: "username",
                    feedname: feedname,
                  });
                }
              }.bind(this)
            );
          } else {
            this.setState({
              isLoading: false,
            });
            console.log("Error:" + response.status + ":" + response.statusText);
          }
        }.bind(this)
      );
    } else {
      let domNode = ReactDOM.findDOMNode(this);
      let parentElement = domNode.parentElement;
      parentElement.scrollTop = 0;
    }
  },

  saveFeedname: function () {
    this.setState({
      isLoading: true,
    });

    // send up to server to validate and create user
    var data = {
      name: this.refs.name.state.value,
      email: this.refs.email.state.value,
      feedname: this.refs.feedname.state.value,
    };

    if (this.state.socialData) {
      data.social_data = this.state.socialData;
    } else {
      data.password = this.refs.password.state.value;
    }

    // console.log("saveFeedname:");
    // console.dir(data);
    Tremr.dispatcher.message(this, "tremr:user:signup", data);
  },

  agreeRules: function (event) {
    this.setState({
      isLoading: true,
    });

    // check email is unused with server
    TremrUtils.fetch(Config.apiurl + "/api/users/agree_rules", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    }).then(
      function (response) {
        if (response.ok) {
          this.setState({
            isLoading: false,
            tab: "follows",
          });

          if (
            event &&
            event.currentTarget.getAttribute("data-profile") == "yes"
          ) {
            Tremr.dispatcher.message(this, "tremr:open:profile", {});
          }
        } else {
          this.setState({
            isLoading: false,
          });
          console.log("Error:" + response.status + ":" + response.statusText);
        }
      }.bind(this)
    );
  },

  render: function () {
    // check if the user has already signed-in!!
    if (this.state.tab == "create" || this.state.tab == "username") {
      if (this.state.user) {
        this.setState({
          tab: "signedin",
        });
        return;
      }
    }

    let buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading,
    });
    let classes = {
      loading: this.state.isLoading,
    };
    classes["tab-" + this.state.tab] = true;
    classes["user-acceptinvite"] = true;
    classes["layout-staticpage"] = true;
    classes = classNames(classes);

    let toolbarContant = null;
    let content = null;

    if (this.state.tab == "loading") {
    } else if (this.state.tab == "signedin") {
      content = (
        <div className="content tx2">
          <h2>Already Signed-in</h2>
          <p>You are already signed-in and cannot use this invitation.</p>
          <p>
            <button onClick={this.close}>Return to {Tremr.Theme.Title}</button>
          </p>
        </div>
      );
    } else if (this.state.tab == "invalid") {
      content = (
        <div className="content tx2">
          <h2>Invite Not Recognised</h2>
          <p>Your invitation is not recognised or has expired.</p>
          <p>
            <button onClick={this.close}>Return to {Tremr.Theme.Title}</button>
          </p>
        </div>
      );
    } else if (this.state.tab == "create") {
      let socialSignin = null;
      // if (Tremr.Theme.RenderSocialSignin) {
      //   socialSignin = [<p key="info" className="info">Or create account with your preferred social media:</p>,
      //                   <div key="social_signup" id="social_signup"></div>];
      // }

      content = (
        <div className="content tx2">
          <h2>{Tremr.Theme.AcceptInvite.Create.Title}</h2>

          <p>
            Confirm the name and email you want to use then click create
            account.
          </p>

          <Tremr.Generic.Input
            key="name"
            ref="name"
            validation={ReactUtils.validateValue}
            validationName="your name"
            name="name"
            label="Name*"
            placeholder="Your name"
            type="text"
            initialValue={this.state.name}
            maxLength={50}
          />
          <Tremr.Generic.Input
            onEnter={this.saveEmail}
            key="email"
            ref="email"
            validation={ReactUtils.validateEmail}
            name="email"
            placeholder="email@address.com"
            label="Email*"
            type="email"
            initialValue={this.state.email}
            maxLength={100}
          />

          <p className="info">
            By creating an account you agree to the {Tremr.Theme.Title}{" "}
            <a href={Tremr.Theme.TermsLink} target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href={Tremr.Theme.PrivacyPolicyLink} target="_blank">
              Privacy Policy
            </a>
          </p>

          <p>
            <button onClick={this.saveEmail} className={buttonClasses}>
              Create Account
            </button>
          </p>

          {socialSignin}
        </div>
      );
    } else if (this.state.tab == "username") {
      toolbarContant = (
        <div className="stages">
          <div className="stage active">1</div>
          <div className="stage">2</div>
          <div className="stage">3</div>
        </div>
      );

      let passwordCtrl = null;
      if (!this.state.socialData) {
        passwordCtrl = (
          <Tremr.Generic.Input
            onEnter={this.saveFeedname}
            key="password"
            ref="password"
            name="password"
            label="Password"
            placeholder="Choose a password"
            type="password"
            initialValue={this.state.password}
            validation={ReactUtils.validatePassword}
            maxLength="50"
          />
        );
      }

      content = (
        <div className="content tx2">
          <h2>Choose a Username</h2>

          <p>
            Your username will also be your address on {Tremr.Theme.Title}.
          </p>

          <Tremr.Generic.Input
            key="name"
            ref="name"
            validation={ReactUtils.validateValue}
            validationName="your name"
            name="name"
            label="Name*"
            placeholder="Your name"
            type="text"
            initialValue={this.state.name}
            maxLength={50}
          />
          <Tremr.Generic.Input
            key="email"
            ref="email"
            validation={ReactUtils.validateEmail}
            name="email"
            placeholder="email@address.com"
            label="Email*"
            type="email"
            initialValue={this.state.email}
            maxLength={100}
          />
          <Tremr.Generic.Input
            onEnter={this.saveFeedname}
            key="feedname"
            ref="feedname"
            validationName="a username"
            placeholder="Your username"
            name="feedname"
            label="Username"
            type="text"
            initialValue={this.state.feedname}
            validation={ReactUtils.validateSlug}
            maxLength="30"
          />
          {passwordCtrl}

          <p>
            <button onClick={this.saveFeedname} className={buttonClasses}>
              Confirm
            </button>
          </p>
        </div>
      );
    } else if (this.state.tab == "rules") {
      toolbarContant = (
        <div className="stages">
          <div className="stage">1</div>
          <div className="stage active">2</div>
          <div className="stage">3</div>
        </div>
      );

      content = (
        <div className="content tx2">
          <h2>Play Nice</h2>
          <p>
            In joining {Tremr.Theme.Title} you agree to abide by the house
            rules.
          </p>

          <h3>I agree to:</h3>
          <ul>
            <li>
              <SvgIcon icon="tick" />
              <strong>Respect all members</strong>
            </li>
            <li>
              <SvgIcon icon="tick" />
              <strong>Be passionate, but polite</strong>
            </li>
            <li>
              <SvgIcon icon="tick" />
              <strong>Be truthful</strong>
            </li>
          </ul>

          <h3>I agree NOT to:</h3>
          <ul>
            <li>
              <SvgIcon icon="remove" />
              <strong>Spam</strong>
            </li>
            <li>
              <SvgIcon icon="remove" />
              <strong>Troll</strong>
            </li>
            <li>
              <SvgIcon icon="remove" />
              <strong>Post hateful, private or pornographic material.</strong>
            </li>
          </ul>

          <p className="actions">
            <button onClick={this.agreeRules} className={buttonClasses}>
              I Agree
            </button>
          </p>
        </div>
      );
    } else if (this.state.tab == "follows") {
      toolbarContant = [
        <div className="stages">
          <div className="stage">1</div>
          <div className="stage">2</div>
          <div className="stage active">3</div>
        </div>,
        <button onClick={this.close} className={buttonClasses}>
          Done
        </button>,
      ];

      content = (
        <div className="content tx2">
          <h2>Your Interests</h2>
          <p>Follow the topics you like. You can edit these at any time.</p>
          {this.state.suggestedTags.map(function (tag) {
            let dummyTag = {
              model: tag,
              get: function (field) {
                return tag[field];
              },
            };
            return (
              <Tremr.Tag.Card
                clickable={false}
                ref={tag.tag + "-card"}
                key={tag.tag + "-card"}
                tag={dummyTag}
              />
            );
          })}
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="toolbar">{toolbarContant}</div>

        {content}
      </div>
    );
  },
});
