var SignInUpMenu = require('../../stores/user/signinupmenu');
var UserAwareMixin = require('../../mixins/userawaremixin');
var SidebarPitch = require('../../stores/layout/sidebarpitch');
var CreateReactClass = require('create-react-class');

// Tremr.Layout.Homemenu = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

	loadMore: function() {
		if (this.state.user) {
			this.refs['following-menu'].loadMore();
		}
	},

	render: function() {

		// <SignInUpMenu />
		// <Tremr.Search.Menu />
		// <Tremr.User.Menu active={this.props.active} />
		// <Tremr.Layout.Navmenu {...this.props} active={this.props.active} />
		// <SidebarPitch />
		return (
			<div className="layout-homemenu container">
				<Tremr.User.Menu active={this.props.active} />
				<Tremr.User.Followingmenu ref="following-menu" active={this.props.active} />
			</div>
		);
	}
});
