require("./scss/dropdown.scss");

var classNames = require('../../utils/classnames');
var OnClickOutside = require('../../mixins/onclickoutside');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// button that opens a dropdown containing child components
// Tremr.Generic.Dropdown
module.exports = CreateReactClass({

    mixins: [PureRenderMixin, OnClickOutside],

    propTypes: {
        label: PropTypes.string,
        icon: PropTypes.string,
        initialOpen: PropTypes.bool,
        dropdown: PropTypes.bool,
        tooltip: PropTypes.string
    },

    // close when click outside
    handleClickOutside: function(event) {
        this.close();
    },

    getDefaultProps: function() {
        return {
            initialOpen: false,
            dropdown: true
        }
    },

    getInitialState: function() {
        return {
            open: this.props.initialOpen
        };
    },

    toggleOpen: function(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            open: !this.state.open
        });
    },

    close: function(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({
            open: false
        });
    },

    // display
    render: function() {

        var dropdown = null;

        if (this.state.open) {
            dropdown = <div onClick={this.close} className="content">
            <Tremr.Generic.SvgIcon icon="speechpointer" classes="speechpointer" fill={true} />
            {this.props.children}
            </div>;
        }

        var iconname = 'down';
        if (!this.props.dropdown) {
            iconname = "up";
        }
        if (this.props.icon) {
            iconname = this.props.icon;
        }
        let icon = <Tremr.Generic.SvgIcon icon={iconname} title={this.props.tooltip} />;

        let label = null;
        if (this.props.label) {
            label = <div className="label">{this.props.label}</div>;
        }

        var classes = classNames({
            dropdown: true,
            dropup: !this.props.dropdown
        });

        return (<div className={classes}>
            <div onClick={this.toggleOpen} className="handle">
                {label}
                {icon}
            </div>
            {dropdown}
        </div>);
    }
});
