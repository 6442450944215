require("./scss/card.scss");

var Card = require('./card');
var CardMixin = require('../../mixins/cardmixin');
var CreateReactClass = require('create-react-class');

// Tremr.Post.DraftCard
module.exports = CreateReactClass({

    mixins: [PureRenderMixin, CardMixin],

    // mixins: [PureRenderMixin, CardMixin],
    render: function() {

        let divStyle = this.getCardStyle();

        return <Card ref="postCard" cardMixinStyle={divStyle} {...this.props} />;
    }

});
