var moment = require("moment");
var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// Tremr.Activity.ListItem
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  fbSeenRef: null,
  timestampUpdateTimer: null,

  propTypes: {
    activity: PropTypes.object.isRequired,
    isNotification: PropTypes.bool,
    post_slug: PropTypes.string,
  },

  getDefaultProps: function () {
    return { isNotification: true };
  },

  getInitialState: function () {
    return { seen: false, updatedAt: Date.now() };
  },

  openChannel: function (event) {
    // event.stopPropagation();

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = this.props.activity.get("target_slug");
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    var domNode = ReactDOM.findDOMNode(this);
    let scrollpos = window.scrollY;

    this.flagSeen();

    // Tremr.dispatcher.message(this, "tremr:layout:rightsidebarcloseonmobile");
    // Tremr.dispatcher.message(this, "tremr:layout:leftsidebarcloseonmobile");

    let context = {
      target: "feed",
      scope: "all",
      feed:
        this.props.activity.get("target_feedname") ||
        this.props.activity.get("channel_name"),
      subfeed: "featured",
    };
    Tremr.navigatePrimary(context, scrollpos);
  },

  updateChannelInvite: function (event, action) {
    // event.stopPropagation();

    this.flagSeen();

    // if meta key, allow normal open
    if (event.metaKey) {
      return;
    }

    // location.href = this.props.activity.get('invite_link');
    Tremr.dispatcher.message(this, "tremr:feed:channelinvite", {
      feed_id: this.props.activity.get("target_feedname"),
      invite: this.props.activity.get("invite_code"),
      action: action,
    });

    event.preventDefault();
  },

  acceptChannelInvite: function (event) {
    this.updateChannelInvite(event, "accept");
  },

  declineChannelInvite: function (event) {
    this.updateChannelInvite(event, "decline");
  },

  userRepStarsDescription: function (activity) {
    return (
      <p className="description">
        Your <strong>overall</strong> reputation stars have changed from{" "}
        <strong>{activity.get("old_value")}</strong> to{" "}
        <strong>{activity.get("new_value")}</strong>
      </p>
    );
  },

  tagRepStarsDescription: function (activity) {
    var tagLink = (
      <a onClick={this.openTag} href={"/tags/" + activity.get("tag")}>
        {activity.get("tag").titlecase()}
      </a>
    );
    return (
      <p className="description">
        Your reputation stars in the topic {tagLink} have changed from{" "}
        <strong>{activity.get("old_value")}</strong> to{" "}
        <strong>{activity.get("new_value")}</strong>
      </p>
    );
  },

  userBadgeDescription: function (activity) {
    let verb = "was added to";
    if (activity.get("notification_type").includes("removed")) {
      verb = "was removed from";
    }
    return (
      <p className="description">
        The badge <strong>{activity.get("badge").titlecase()}</strong> {verb}{" "}
        your profile
      </p>
    );
  },

  postBadgeDescription: function (activity) {
    var postLink = (
      <a onClick={this.openPost} href={activity.get("target_slug")}>
        {activity.get("target_title")}
      </a>
    );
    let verb = "was added to";
    if (activity.get("notification_type").includes("removed")) {
      verb = "was removed from";
    }
    return (
      <p className="description">
        The badge <strong>{activity.get("badge").titlecase()}</strong> {verb}{" "}
        your post {postLink}
      </p>
    );
  },

  channelInviteDescription: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_title")}
      </a>
    );
    var acceptLink = (
      <a
        onClick={this.acceptChannelInvite}
        href={
          activity.get("target_slug") +
          "/invite/accept/" +
          activity.get("invite_code")
        }
      >
        Accept
      </a>
    );
    var declineLink = (
      <a
        onClick={this.declineChannelInvite}
        href={
          activity.get("target_slug") +
          "/invite/decline/" +
          activity.get("invite_code")
        }
      >
        Decline
      </a>
    );

    return (
      <p className="description">
        has invited you to be a member of their Blogcast, {postLink}. You may{" "}
        {acceptLink}, or {declineLink} this invitation.
      </p>
    );
  },

  channelInviteAccepted: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_title")}
      </a>
    );

    return (
      <p className="description">
        has accepted their invitation to be a member of your Blogcast, {postLink}
        .
      </p>
    );
  },

  channelInviteDeclined: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_title")}
      </a>
    );

    return (
      <p className="description">
        has declined their invitation to be a member of your Blogcast, {postLink}
        .
      </p>
    );
  },

  endorsementDescription: function (activity) {
    console.log("endorsementDescription:");

    if (activity.get("reactions") && activity.get("reactions").length > 0) {
      additional_description = (
        <span>
          <em className="reactions-list">
            &nbsp;
            {activity.get("reactions")
              ? activity
                  .get("reactions")
                  .map(function (r) {
                    return r.replace(":", " ");
                  })
                  .join(", ")
              : null}
          </em>
        </span>
      );
    }

    let comment = null;
    if (activity.get("comment") && activity.get("comment").length > 0) {
      comment = (
        <strong className="repost-comment">{activity.get("comment")}</strong>
      );
    }
    return (
      <p className="description">
        has endorsed you: {additional_description}
        {comment}
      </p>
    );
  },

  channelContributorRemoved: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_name")}
      </a>
    );
    var removedLink = (
      <a onClick={this.openTargetUser} href={activity.get("target_slug")}>
        {activity.get("removed_name")}
      </a>
    );

    return (
      <p className="description">
        {removedLink} has been removed from your channel, {postLink}.
      </p>
    );
  },

  channelPendingContributorRemoved: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_name")}
      </a>
    );
    var removedLink = (
      <a onClick={this.openTargetUser} href={activity.get("target_slug")}>
        {activity.get("removed_name")}
      </a>
    );

    return (
      <p className="description">
        {removedLink} has been removed from invited members on your Blogcast,{" "}
        {postLink}.
      </p>
    );
  },

  channelContributorRemovedUser: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_name")}
      </a>
    );

    return (
      <p className="description">has removed you from Blogcast {postLink}.</p>
    );
  },

  channelPendingContributorRemovedUser: function (activity) {
    var postLink = (
      <a onClick={this.openChannel} href={activity.get("target_slug")}>
        {activity.get("target_name")}
      </a>
    );

    return (
      <p className="description">
        has withdrawn your invitation to Blogcast {postLink}.
      </p>
    );
  },

  // get a description for a notiification
  notificationDescription: function (
    activity,
    postUrl,
    userUrl,
    target_type,
    actor_type
  ) {
    let description = activity.get("notification_type").capitalize();
    let reactions = null;
    let additional_description = null;
    var postLink = null;

    if (description == "Recommended") {
      if (
        activity.get("recommend_level") &&
        activity.get("recommend_level") < 0
      ) {
        description = "Downvoted your " + target_type + ": ";
      } else {
        description = "Upvoted your " + target_type + ": ";
      }
    } else if (description == "Reacted") {
      description = description + " to your " + target_type + ": ";

      if (activity.get("reactions") && activity.get("reactions").length > 0) {
        additional_description = (
          <span>
            <em className="reactions-list">
              &nbsp;
              {activity.get("reactions")
                ? activity
                    .get("reactions")
                    .map(function (r) {
                      return r.replace(":", " ");
                    })
                    .join(", ")
                : null}
            </em>
          </span>
        );
      }
    } else if (description == "Responded") {
      // var prefix = 'a';
      // if (/^[aeiou]/i.test(actor_type)) {
      //  prefix = 'an';
      // }
      description = "replied to you with a new " + actor_type + ": ";
    } else if (description == "Reposted") {
      // Reposted your post with a comment:
      // Very smart piece. And well chosen finish with David Brooks
      // ‘Some wise advise here that’s well worth heading’

      // description = description + ' your ' + target_type + ': ';
      description = "Reposted your post: ";
      if (activity.get("comment") && activity.get("comment").length > 0) {
        description = "Reposted your post with a comment: ";
        additional_description = (
          <em className="repost-comment">{activity.get("comment")}</em>
        );
      }

      // if (activity.get('channels')) {
      //
      //     let channels = Object.keys(activity.get('channels')).map((channel) => {
      //         if (activity.get('channels').length > 1 && activity.get('actor_feedname') == channel) {
      //             return null;
      //         } else {
      //             let subfeed = _.first(activity.get('channels')[channel]);
      //             let channelUrl = "/"+channel+"/posts/"+subfeed;
      //             return <a href={channelUrl} onClick={this.openChannel} data-channel={channel} data-subfeed={subfeed} key={'channel-'+channel+"-"+subfeed}>
      //             {channel}
      //             </a>;
      //         }
      //     });
      //     additional_description = <span>
      //         &nbsp;to<br />
      //         <em className="channels-list">
      //             {channels}
      //         </em>
      //     </span>;
      // }
    } else if (description == "Followed") {
      if (this.props.activity.get("channel_name")) {
        postLink = (
          <a onClick={this.openChannel} href={activity.get("target_slug")}>
            {activity.get("channel_title")}
          </a>
        );

        description = description + " your Blogcast ";
      } else {
        description = description + " you";
      }
    }

    if (
      activity.get("notification_type") == "responded" &&
      activity.get("actor_title")
    ) {
      postLink = (
        <a onClick={this.openPost} href={postUrl}>
          {activity.get("actor_title")}
          {reactions}
        </a>
      );
    } else if (this.props.activity.get("target_title")) {
      postLink = (
        <a onClick={this.openPost} href={postUrl}>
          {activity.get("target_title")}
          {reactions}
        </a>
      );
    }

    return (
      <p className="description">
        {description}
        {postLink}
        {additional_description}
      </p>
    );
  },

  // get a description for an activity
  activityDescription: function (
    activity,
    postUrl,
    userUrl,
    target_type,
    actor_type
  ) {
    var target_author_name = activity.get("target_author_name");
    if (activity.get("target_author_id") == activity.get("actor_author_id")) {
      target_author_name = "themselves";
    }

    var target_author_url = Tremr.routes.urlFor({
      primary: this.getTargetUserContext(),
    });

    var target_author_link = (
      <a onClick={this.openTargetUser} href={target_author_url}>
        {target_author_name}
      </a>
    );

    var reactions = null;
    if (activity.get("notification_type").capitalize() == "Reacted") {
      reactions = (
        <em className="reactions">
          -&nbsp; {activity.get("reactions").join(", ")}
        </em>
      );
    }

    var postLink = null;
    if (
      activity.get("notification_type") == "responded" &&
      activity.get("actor_title")
    ) {
      postLink = (
        <a onClick={this.openPost} href={postUrl}>
          {activity.get("actor_title")}
          {reactions}
        </a>
      );
    } else if (this.props.activity.get("target_title")) {
      postLink = (
        <a onClick={this.openPost} href={postUrl}>
          {activity.get("target_title")}
          {reactions}
        </a>
      );
    }

    var description = activity.get("notification_type").capitalize();

    if (description == "Recommended") {
      description = description + " the " + target_type + ": ";
    } else if (description == "Reacted") {
      description = description + " to the " + target_type + ": ";
    } else if (description == "Responded") {
      description = (
        <span>
          replied to {target_author_link}&nbsp;with a new {actor_type}
        </span>
      );
    } else if (description == "Reposted") {
      description = description + " the " + target_type + ": ";
    }

    return (
      <p className="description">
        {description}
        {postLink}
      </p>
    );
  },

  componentDidMount: function () {
    // rerender every minute to ensure our timestamp data is refreshed
    this.timestampUpdateTimer = setInterval(
      function () {
        this.setState({ updatedAt: Date.now() });
      }.bind(this),
      60000
    );

    // // listen for "seeall" event so we dont have to wait for firebase when WE did it.
    // this.listenTo(Tremr.dispatcher, "tremr:activity:seenall", function() {
    // 	this.setState({seen: true});
    // }.bind(this));

    this.bindToFirebase();

    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }
  },

  componentDidUpdate: function () {
    this.bindToFirebase();
  },

  bindToFirebaseSeen: function () {
    this.fbSeenRef = Tremr.firebase
      .database()
      .ref(
        "notifications/users/" +
          this.state.user.get("_id") +
          "/seen/" +
          this.props.activity.get("key")
      );
    this.fbSeenRef.on(
      "value",
      function (snapshot) {
        let data = snapshot.val();

        if (data) {
          this.setState({ seen: true });
        }
      }.bind(this)
    );
  },

  // watch firebase to see if this has been seen
  bindToFirebase: function () {
    if (!this.fbSeenRef && this.state.user && this.props.activity) {
      // check for seenall timestamp
      this.fbSeenRef = Tremr.firebase
        .database()
        .ref("notifications/users/" + this.state.user.get("_id") + "/seenall");
      this.fbSeenRef.on(
        "value",
        function (snapshot) {
          let data = snapshot.val();
          if (data) {
            if (data >= this.props.activity.get("timestamp")) {
              this.setState({ seen: true });
            } else {
              this.bindToFirebaseSeen();
            }
          } else {
            this.bindToFirebaseSeen();
          }
        }.bind(this)
      );
    }
  },

  // stop watching firebase
  componentWillUnmount: function () {
    if (this.fbSeenRef) {
      this.fbSeenRef.off();
    }

    if (this.timestampUpdateTimer) {
      clearInterval(this.timestampUpdateTimer);
    }

    if (this.stopListening) {
      this.stopListening();
    }
  },

  // open user with specific context
  navigateUser: function (event, context) {
    // event.stopPropagation();

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: context });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    var domNode = ReactDOM.findDOMNode(this);
    // var scrollpos;
    // scrollpos = $(domNode).closest('.current, .primary').scrollTop();  get scroll pos of container
    let scrollpos = window.scrollY;

    this.flagSeen();

    // Tremr.dispatcher.message(this, "tremr:layout:rightsidebarcloseonmobile");
    // Tremr.dispatcher.message(this, "tremr:layout:leftsidebarcloseonmobile");

    Tremr.navigatePrimary(context, scrollpos);
  },

  // open channel
  // openChannel: function(event) {
  //       let channel = event.target.getAttribute('data-channel');
  //       let subfeed = event.target.getAttribute('data-subfeed');
  //       let context = {target: 'feed', scope: subfeed, feed: channel};
  // 	this.navigateUser(event, context);
  // },

  // open a user in primary, keeping the scroll position
  openUser: function (event) {
    this.navigateUser(event, this.getUserContext());
  },

  openTargetUser: function (event) {
    this.navigateUser(event, this.getTargetUserContext());
  },

  // fire event to update server and immediately show as seen
  flagSeen: function () {
    // only bother if not already seen
    if (this.fbSeenRef && !this.state.seen) {
      // update our own state so we dont need ot wait
      this.setState({ seen: true });

      // write to firebase
      let fbSeenRef = Tremr.firebase
        .database()
        .ref(
          "notifications/users/" +
            this.state.user.get("_id") +
            "/seen/" +
            this.props.activity.get("key")
        );
      fbSeenRef.set(this.props.activity.get("notification_type"));
    }
  },

  // open a post
  openPost: function (event) {
    // event.stopPropagation();

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: this.getPostContext() });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    this.flagSeen();

    Tremr.dispatcher.message(this, "tremr:layout:rightsidebarcloseonmobile");
    Tremr.dispatcher.message(this, "tremr:layout:leftsidebarcloseonmobile");

    Tremr.navigateCurrent(this.getPostContext());
  },

  openTag: function (event) {
    // event.stopPropagation();

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: this.getPostContext() });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    this.flagSeen();

    Tremr.dispatcher.message(this, "tremr:layout:rightsidebarcloseonmobile");
    Tremr.dispatcher.message(this, "tremr:layout:leftsidebarcloseonmobile");

    Tremr.navigateCurrent(this.getTagContext());
  },

  getUserContext: function () {
    return {
      target: "feed",
      scope: "featured",
      feed: this.props.activity.get("actor_feedname"),
    };
  },

  getTagContext: function () {
    return {
      target: "tag",
      tag: this.props.activity.get("tag"),
    };
  },

  getTargetUserContext: function () {
    return {
      target: "feed",
      scope: "featured",
      feed: this.props.activity.get("target_slug").split("!")[0],
    };
  },

  getPostContext: function () {
    var slug = this.props.activity.get("target_slug");
    if (this.props.activity.get("notification_type") == "responded") {
      slug = this.props.activity.get("actor_slug");
    }
    if (!slug) {
      return false;
    }
    slug = slug.split("!");
    return { target: "post", feed: slug[0], id: slug[1] };
  },

  // display
  render: function () {
    var avatar_image = null;
    if (this.props.activity.get("actor_avatar_public_id")) {
      avatar_image = (
        <Tremr.Generic.Image
          key={"avatar" + this.props.activity.get("actor_avatar_public_id")}
          height={50}
          width={50}
          image={{
            public_id: this.props.activity.get("actor_avatar_public_id"),
          }}
        />
      );
    } else if (
      this.props.activity.get("actor_avatar") &&
      this.props.activity.get("actor_avatar").public_id
    ) {
      avatar_image = (
        <Tremr.Generic.Image
          key={"avatar" + this.props.activity.get("actor_avatar").public_id}
          height={50}
          width={50}
          image={this.props.activity.get("actor_avatar")}
        />
      );
    } else if (this.props.activity.get("actor_avatar")) {
      avatar_image = (
        <img
          src={this.props.activity.get("actor_avatar")}
          height="50"
          width="50"
        />
      );
    }

    // var iconName = this.props.activity.get('notification_type');
    // if (iconName == 'recommended') {
    // 	iconName = 'recommend';
    // } else if (iconName == 'reposted') {
    // 	iconName = 'repost';
    // } else if (iconName == 'responded') {
    // 	iconName = 'respond';
    // } else if (iconName == 'reacted') {
    // 	iconName = 'quote';
    // } else if (iconName == 'channel-invite') {
    // 	iconName = 'feed';
    // }
    // var avatarIcon = <Tremr.Generic.SvgIcon icon={iconName} classes={'icon action-' + iconName}/>;

    var userUrl = Tremr.routes.urlFor({ primary: this.getUserContext() });
    var postUrl = Tremr.routes.urlFor({ primary: this.getPostContext() });

    var target_type = "post";
    // if (this.props.activity.get('target_type') && this.props.activity.get('target_type') != 'general') {
    // 	target_type = this.props.activity.get('target_type');
    // }
    var actor_type = "post";
    if (
      this.props.activity.get("actor_type") &&
      this.props.activity.get("actor_type") != "general"
    ) {
      actor_type = this.props.activity.get("actor_type");
    }

    var description = null;
    if (this.props.isNotification) {
      if (
        this.props.activity.get("notification_type") == "user_badge_added" ||
        this.props.activity.get("notification_type") == "user_badge_removed"
      ) {
        description = this.userBadgeDescription(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") == "post_badge_added" ||
        this.props.activity.get("notification_type") == "post_badge_removed"
      ) {
        description = this.postBadgeDescription(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") == "user_rep_stars_changed"
      ) {
        description = this.userRepStarsDescription(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") ==
        "topic_rep_stars_changed"
      ) {
        description = this.tagRepStarsDescription(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") == "channel-invite"
      ) {
        description = this.channelInviteDescription(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") ==
        "channel-invite-accepted"
      ) {
        description = this.channelInviteAccepted(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") ==
        "channel-invite-declined"
      ) {
        description = this.channelInviteDeclined(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") ==
        "channel-contributor-removed"
      ) {
        description = this.channelContributorRemoved(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") ==
        "channel-pending-contributor-removed"
      ) {
        description = this.channelPendingContributorRemoved(
          this.props.activity
        );
      } else if (
        this.props.activity.get("notification_type") ==
        "channel-contributor-removed-user"
      ) {
        description = this.channelContributorRemovedUser(this.props.activity);
      } else if (
        this.props.activity.get("notification_type") ==
        "channel-pending-contributor-removed-user"
      ) {
        description = this.channelPendingContributorRemovedUser(
          this.props.activity
        );
      } else if (
        this.props.activity.get("notification_type") == "endorsed_user"
      ) {
        description = this.endorsementDescription(this.props.activity);
      } else {
        description = this.notificationDescription(
          this.props.activity,
          postUrl,
          userUrl,
          target_type,
          actor_type
        );
      }
    } else {
      description = this.activityDescription(
        this.props.activity,
        postUrl,
        userUrl,
        target_type,
        actor_type
      );
    }

    var created = moment(parseInt(this.props.activity.get("timestamp")));
    var timeago = created.fromNow();

    var classes = {
      "activity-item": true,
      line: true,
      seen: this.state.seen,
    };
    classes[this.props.activity.get("notification_type")] = true;
    if (actor_type && actor_type != "post") {
      classes[actor_type] = true;
    } else if (target_type && target_type != "post") {
      classes[target_type] = true;
    }
    classes = classNames(classes);

    let key = "activity-" + this.props.activity.get(key);

    return (
      <div className={classes} key={key}>
        <a className="avatar" onClick={this.openUser} href={userUrl}>
          {avatar_image}
        </a>
        <div className="info">
          <a onClick={this.openUser} href={userUrl}>
            {Tremr.Utils.truncate(
              this.props.activity.get("actor_author_name"),
              22
            )}
            <time dateTime={this.props.activity.get("timestamp")}>
              {timeago}
            </time>
          </a>
          {description}
        </div>
      </div>
    );
  },
});
