var classNames = require("../../utils/classnames");
var CardMixin = require("../../mixins/cardmixin");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// single post on a wall that loads latest single headline post for a feed
// and renders using standard card with a container
// Tremr.Post.Headline = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, CardMixin],

  promises: [], // promise references so we can cancel on unmount

  propTypes: {
    feedname: PropTypes.string.isRequired,
    startTop: PropTypes.number,
    startWidth: PropTypes.number,
    useCache: PropTypes.bool,
  },

  getDefaultProps: function () {
    return {
      useCache: false,
    };
  },

  getInitialState: function () {
    return {
      isLoading: true,
      isEmpty: false,
      model: new Tremr.Models.FeedHeadline({
        id: this.props.feedname,
      }),
      size: 1,
    };
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });
  },

  // when mounted, load the requested post
  componentDidMount: function () {
    var promise = Tremr.stores.feedStore.loadHeadline(
      this.state.model,
      this.props.feedname,
      this.props.useCache
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        if (this.state.model.get("noheadline")) {
          this.setState({
            isEmpty: true,
          });
        } else {
          this.setState({
            isLoading: false,
            model: this.state.model,
          });

          // raise event to hide this post on that feed
          Tremr.dispatcher.message(this, "tremr:headline:load", {
            feedname: this.props.feedname,
            id: model.get("id"),
          });
        }
      }.bind(this)
    );
  },

  // let the component decide if it can be shown at multi-column sizes
  // based on the model
  _allowSize: function (size) {
    if (size > 1) {
      if (!this.state.isLoading && !this.state.isEmpty) {
        if (this.state.model.get("image") || this.state.model.get("embed")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true; // always allow size 1
    }
  },

  // update the state of the card
  setCardPosition: function (newState) {
    var key = "card-headline" + this.state.model.get("id");
    this.refs[key].setState(newState);
  },

  // display
  render: function () {
    // work out our top level class
    var classes = {
      headline: true,
      loading: this.state.isLoading,
      empty: this.state.isEmpty,
    };
    classes["size" + this.state.size] = true;
    classes = classNames(classes);

    // if empty (because no headline just null)
    if (this.state.isEmpty) {
      return null;
    }

    var card = null;
    if (this.state.isLoading === false) {
      var setHeight = 430;
      var imageSize = {
        height: setHeight,
        width: 724,
        maxHeight: setHeight,
        minHeight: setHeight,
      };
      var startTop = this.props.startTop;
      var startWidth = this.props.startWidth;

      if (Tremr.Utils.isDeviceOrNarrow()) {
        // setHeight = false;
        var setHeight = 217;

        var imageSize = {
          height: setHeight,
          width: $(window).width(),
          maxHeight: setHeight,
          minHeight: setHeight,
        };
        var startTop = 0;
        var startWidth = 0;
      }
      var key = "card-headline" + this.state.model.get("id");

      card = (
        <Tremr.Post.Card
          ref={key}
          key={key}
          imageSize={imageSize}
          post={this.state.model}
          startTop={startTop}
          startWidth={startWidth}
          showBreadcrumbs={false}
          showResponses={false}
          cardHeightSet={this.props.cardHeightSet}
        />
      );
    }

    return <div className={classes}>{card}</div>;
  },
});
