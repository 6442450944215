var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var PropTypes = require("prop-types");
let Stars = require("../controls/stars");
var SvgIcon = require("../generic/svgicon");

// sideways scrolling list of related posts
module.exports = CreateReactClass({
  // mixins: [PureRenderMixin],

  mixins: [UserAwareMixin],

  propTypes: {
    authorName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    // liveDataPoints: PropTypes.array.isRequired,
  },

  getDefaultProps: function () {
    return {};
  },

  getInitialState: function () {
    return {};
  },

  click: function (e) {
    this.props.onClick(e);
  },

  noAccess: function (e) {},

  render: function () {

    // check for channel membership and post door policy
        
    // assume allowed to reply
    let CanRespond = true;
    let BlockReason = "";
    let policies = [];
    let channelMembership = false;

    // channel membership
    if (
      this.state.user && 
      this.state.user.get("_id") && 
      this.props.liveDataPoints?.channelMembership?.data?.length > 0
    ) {

      // check our id is in the list
      if (!this.props.liveDataPoints.channelMembership.data.includes(this.state.user.get("_id"))) {
        CanRespond = false;
        channelMembership = true;
        BlockReason = "Not a member of the Blogcast."
      }
    }

    // post door policy
    if (
      this.state.user && 
      this.state.user.get("_id") && 
      this.props.liveDataPoints?.doorPolicy?.data?.option == "Restricted"
    ) {
    
      // we have a post door policy, check if user can respond or not
      if (
        this.props.liveDataPoints?.doorPolicy?.data?.restricted.includes("Reputation")
      ) {
        if (this.props.liveDataPoints.doorPolicy.data?.restrictedStars > 0) {
          policies.push(
            <Stars
              key="stars"
              alignment="left"
              scale={window.devicePixelRatio}
              drawUnset={false}
              setColor={"#2d2d2d"}
              lineWidth={3}
              starSize={14}
              height={14}
              width={51}
              stars={this.props.liveDataPoints.doorPolicy.data.restrictedStars}
              filledStars={0}
            />
          );
        }

        if (
          this.props.liveDataPoints.reputation.data >=
          this.props.liveDataPoints.doorPolicy.data.restrictedStars
        ) {
          // ok, leave alone
        } else {
          CanRespond = false;
          BlockReason = "Post door policy requires higher reputation."
        }
      }

      // always allow author to respond
      if (
        this.state.user &&
        this.props.liveDataPoints?.doorPolicy?.data?.author_id ==
          this.state.user.get("_id")
      ) {
        CanRespond = CanRespond && true;
      }  
    }


    // UI added here for either channel membership or door policy block    
    
    let policiesDiv = null;
    if (channelMembership) {
      policiesDiv = <div>{BlockReason}</div>;
    } else  if (policies.length > 0) {
      policiesDiv = <div>{policies}</div>;
    } 
    let doorPolicySummary = null;  
    if (this.state.user || !CanRespond) {
      doorPolicySummary = (
        <div className="door-policy-summary-container">
          <div className="door-policy-summary">
            <SvgIcon title={BlockReason} icon={CanRespond ? "unlocked" : "locked"} />
            {policiesDiv}
          </div>
        </div>
      );
    }
             
    
    let classes = {};
    classes["reply-prompt"] = true;
    classes["reply-prompt-readonly"] = !CanRespond;
    classes = classNames(classes);

    console.log("CanRespond", CanRespond);

    return (
      <div>
        <div
          className={classes}
          onClick={CanRespond ? this.click : this.noAccess}
        >
          <SvgIcon icon="respond" />
          <div className="prompt">Reply to {this.props.authorName}…</div>
        </div>
        {doorPolicySummary}
      </div>
    );
  },
});
