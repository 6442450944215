// copy of https://github.com/codebrew/backbone-rails/blob/master/vendor/assets/javascripts/backbone_rails_sync.js
// except when we have paramRoot we send params wrapped and unwrapped like an early bugged
// version of the gem that our server is coded against.

'use strict';

var $        = require('jquery');
var Backbone = require('backbone');
var _        = require('underscore');

Backbone._sync = Backbone.sync;

Backbone.sync = function(method, model, options) {


  // add the CSRF token if we have one
  if (!options.noCSRF) {
    var beforeSend = options.beforeSend;

    // Set X-CSRF-Token HTTP header
    options.beforeSend = function(xhr) {
      // manually add X-Requested-With dropped for X-Origin
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      // add csrf token
      var token = $('meta[name="csrf-token"]').attr('content');
      if (token) { xhr.setRequestHeader('X-CSRF-Token', token); }
      if (beforeSend) { return beforeSend.apply(this, arguments); }
    };
  } else {
    // manually add X-Requested-With dropped for X-Origin
    var beforeSend = options.beforeSend;
    options.beforeSend = function(xhr) {
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      if (beforeSend) { return beforeSend.apply(this, arguments); }
    };
  }

  // Serialize data, optionally using paramRoot
  if (!options.data && model && (method === 'create' || method === 'update' || method === 'patch')) {
    options.contentType = 'application/json';
    var data = JSON.stringify(options.attrs || model.toJSON(options));
    if (model.paramRoot) {
      data = options.attrs || model.toJSON(options); // keep unwrapped version
      data[model.paramRoot] = model.toJSON(options);
    } else {
      data = model.toJSON();
    }
    options.data = JSON.stringify(data);
  }

  return Backbone._sync(method, model, options);
};
