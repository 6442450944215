require("./scss/listcardwrapper.scss");

var classNames = require("../../utils/classnames");
var TremrUtils = require("../../utils/tremr_utils");
var TremrStorage = require("../../utils/tremr_storage");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var ListCard = require("./listcard");
var ContentCard = require("./contentcard");
var ExpandButton = require("./expandbutton");

module.exports = CreateReactClass({
  // mixins: [PureRenderMixin],

  propTypes: {
    post: PropTypes.object.isRequired,
    showBreadcrumbs: PropTypes.bool,
    showResponses: PropTypes.bool,
    identifier: PropTypes.string,
    loadedCallback: PropTypes.func,
    autoexpand: PropTypes.bool,
    onExpandListCard: PropTypes.func,
  },

  getDefaultProps: function () {
    return { showBreadcrumbs: true, showResponses: true };
  },

  getInitialState: function () {
    return {
      expanded: false,
      listCardHeight: 0,
      loadContent: false,
    };
  },

  listCardHeightSet: function (height) {
    this.setState({
      listCardHeight: height,
    });
  },

  expand: function (event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    TremrStorage.setSession("wrapper." + this.props.identifier, "content");
    this.setState({
      expanded: true,
    });

    if (this.props.onExpandListCard) {
      this.props.onExpandListCard(this.props.post);
    }

    // request activity for this card
    // Tremr.stores.postStore.requestActivityForPosts([this.props.post]);
  },

  onToggleResponses: function (expanded) {
    this.setState({
      responsesExpanded: expanded,
    });
  },

  // if returning back to here reset expanded state - or set closed
  componentDidMount: function () {
    // bit of a hack but look directly at state to see if we are caching
    // i.e. user is going back/forward and needs auto expansion of cards
    let fullContext = Tremr.context.getState();
    let isCached = fullContext.cache == true;

    if (isCached) {
      var cacheddata = TremrStorage.getSession(
        "wrapper." + this.props.identifier
      );
      if (cacheddata && cacheddata == "content") {
        this.expand();
      }
    } else {
      // set not expanded to conversation ard
      TremrStorage.setSession("wrapper." + this.props.identifier, "list");
    }

    TremrUtils.addPassiveEventListener("resize", this.resized);
  },

  // remove event handler
  componentWillUnmount: function () {
    window.removeEventListener("resize", this.resized);
  },

  resized: function () {
    let contentElement = ReactDOM.findDOMNode(this.refs["card"]);
    if (contentElement) {
      this.listCardHeightSet(contentElement.clientHeight);
    }
  },

  onLoadContent: function (event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    TremrStorage.setSession("wrapper." + this.props.identifier, "content");
    this.setState({
      expanded: true,
      loadContent: true,
    });

    if (this.props.onExpandListCard) {
      this.props.onExpandListCard(this.props.post);
    }
  },

  render: function () {
    // by default show listcard
    let card = null;
    let expandButton = null;
    let cardChildType = null;
    if (this.state.expanded) {
      cardChildType = "content";
      card = (
        <ContentCard
          onToggleResponses={this.onToggleResponses}
          autoLoadContent={this.state.loadContent}
          autoexpand={this.state.responsesExpanded}
          {...this.props}
        />
      );
    } else {
      cardChildType = "list";
      card = (
        <ListCard
          ref="card"
          autoexpand={this.state.responsesExpanded}
          onToggleResponses={this.onToggleResponses}
          onLoadContent={this.onLoadContent}
          reportHeight={this.listCardHeightSet}
          {...this.props}
        />
      );

      let expandButtonStyles = {};
      if (this.state.listCardHeight && this.state.listCardHeight > 0) {
        expandButtonStyles["bottom"] = "auto";
        expandButtonStyles["top"] = this.state.listCardHeight.toString() + "px";
      }
      expandButton = (
        <ExpandButton styles={expandButtonStyles} onClick={this.expand} />
      );
    }

    let classes = classNames({
      "post-list-card-wrapper": true,
      "card-child-list": cardChildType == "list",
      "card-child-content": cardChildType == "content",
      "responses-expanded": this.state.responsesExpanded,
    });
    return (
      <div className={classes}>
        {card}
        {expandButton}
      </div>
    );
  },
});
