require("./scss/editor.scss");
require("./scss/editortoolbar.scss");

var moment = require("moment");
var alertify = require("alertify");
var classNames = require("../../utils/classnames");
var Subfeeds = require("./subfeeds");
var TremrUtils = require("../../utils/tremr_utils");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");
// var TypesMenu = require("./typesmenu");
var Article = require("./fieldset/article");
var Breadcrumbs = require("../post/breadcrumbs");
var PostMenu = require("./postmenu");
var Byline = require("../user/byline");
// var DoorPolicySettings = require("./doorpolicysettings");
let Stars = require("../controls/stars");

// container for editor
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],
  promises: [], // promise references so we can cancel on unmount

  propTypes: {
    existingPostId: PropTypes.string, // if editing we need the original id
    existingDraftId: PropTypes.string, // if editing a draft we need the original id
    author: PropTypes.object.isRequired,
    parent: PropTypes.object,
    showBreadcrumbs: PropTypes.bool,
    onPost: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]), // action to take when the post is saved - default is to navigate to post
    initialFieldset: PropTypes.string,
    channel: PropTypes.object,
  },

  getDefaultProps: function () {
    return {
      showBreadcrumbs: true,
      initialFieldset: "article",
      onPost: function (m) {
        // redirect to post
        var route = Tremr.stores.postStore.route(m);
        Tremr.navigateCurrent(route, false, false);
      },
    };
  },

  getInitialState: function () {
    var defaultSubfeeds = [];
    if (!this.props.parent) {
      defaultSubfeeds.push("featured");
    }
    console.log("getInitialState");
    var defaultState = {
      title: "",
      summary: "",
      postType: "general",
      tags: [],
      currentErrors: false,
      headerMode: "unset",
      headerData: false,
      isSaving: false,
      // showControls: false,
      headline: false,
      dirty: false,
      isHeadline: false,
      feed: false,
      subfeeds: defaultSubfeeds,
      channelSubfeeds: [],
      fieldset: this.props.initialFieldset,
      subfeedsToggleState: true,
      doorPolicy: {
        option: "Everyone",
        restricted: [],
      },
    };
    // if we have a parent, default to it's tags etc.
    if (this.props.parent) {
      defaultState.tags = this.props.parent.get("tags");
    }

    return defaultState;
  },

  getModelData: function (model) {
    var newState = {
      title: model.get("title"),
      summary: model.get("summary"),
      postType: model.get("post_type"),
      tags: model.get("tags"),
      content_blocks: model.get("content_blocks"),
      featured: model.get("featured")
    };

    if (model.get("door_policy")) {
      newState.doorPolicy = model.get("door_policy");
    }

    // get the feeds from the model
    let feeds = model.get("feeds");

    // load subfeeds state from feeds data on post from server
    let user = Tremr.stores.userStore.getUser();
    let subfeeds = [];
    if (user) {
      let usersfeeds = user.get("feeds");
      let userfeed = usersfeeds[0].name;

      _.each(feeds, (f) => {
        if (f.name.trim().toLowerCase() == userfeed.trim().toLowerCase()) {
          if (f.subfeed) {
            subfeeds.push(f.subfeed);
          }
        }
      });
    }
    newState["subfeeds"] = subfeeds;

    // iterate feeds and see if the authors feed is the headline
    if (feeds) {
      _.each(
        feeds,
        function (feed) {
          if (feed.name == this.props.author.get("feedname")) {
            if (feed.headline && feed.headline == "true") {
              newState.headline = "true";
            }
          }
        }.bind(this)
      );
    }

    if (model.get("image")) {
      newState["headerMode"] = "image";
      newState["headerData"] = model.get("image");
    } else if (model.get("embed")) {
      newState["headerMode"] = "embed";
      newState["headerData"] = model.get("embed");
    }

    return newState;
  },

  // load draft from the id
  loadDraft: function (id) {
    var promise = Tremr.Utils.loadModel(
      new Tremr.Models.Draft(),
      {
        id: this.props.existingDraftId,
      },
      false
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        var newState = this.getModelData(model);
        newState.existingDraft = model;
        newState.fieldset = model.get("fieldset")
          ? model.get("fieldset")
          : "article";

        if (model.get("post_id")) {
          newState.existingPostId = model.get("post_id");
        }

        let user = Tremr.stores.userStore.getUser();
        if (user) {
          let feed = user.get("feedname");
          this.loadUserFeed(feed);
        }

        let channel_name = null;
        if (
          model.get("channel_name") &&
          model.get("channel_name") != user.get("feedname")
        ) {
          this.loadChannel(model.get("channel_name"));
        }

        this.setState(newState);
      }.bind(this),
      function (params) {
        // if user is signed-in then take them to their drafts page
        var user = Tremr.stores.userStore.getUser();
        if (user) {
          Tremr.dispatcher.message(this, "tremr:open:userdrafts", event);
        }
      }.bind(this)
    );
  },

  // load post from the id
  loadPost: function (id) {
    var promise = Tremr.Utils.loadModel(
      new Tremr.Models.Post(),
      {
        id: id,
      },
      false
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        var newState = {};
        newState.fieldset = model.get("fieldset")
          ? model.get("fieldset")
          : "article";

        // only set the state from post IF we haven't got a draft
        if (!this.props.existingDraftId) {
          newState = this.getModelData(model);
        }
        newState.existingPost = model;

        let user = Tremr.stores.userStore.getUser();
        if (user) {
          let feed = user.get("feedname");
          this.loadUserFeed(feed);
        }

        let channel_name = null;
        if (model.get("channel_name")) {
          this.loadChannel(model.get("channel_name"));
        }

        this.setState(newState);
      }.bind(this),
      function (params) {
        alertify.error("Error loading post in editor");
      }.bind(this)
    );
  },

  // if an existing post / draft has been loaded validate it
  // componentDidUpdate: function(prevProps, prevState) {

  //    has a draft or post arrived
  //   if ((this.state.existingPost && !prevState.existingPost) ||
  //       (this.state.existingDraft && !prevState.existingDraft)) {

  //      validate existing post
  //     var invalid = this.invalid();
  //     if (invalid) {
  //       this.setState({
  //         currentErrors: invalid
  //       });
  //     }
  //   }

  // },

  // handle window before unload and prompt if dirty
  confirmNavigateAway: function () {
    if (this.state.dirty) {
      return "Discard your changes?";
    }
  },

  closeEditor: function () {
    Tremr.stores.editorStore.canNavigate(() => {
      Tremr.dispatcher.message(this, "tremr:editor:close");
    });
  },

  // stop listening to events
  componentWillUnmount: function () {
    if (this.autosaveInterval) {
      clearInterval(this.autosaveInterval);
    }

    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });

    $(window).off("beforeunload", this.confirmNavigateAway);

    Tremr.dispatcher.message(this, "tremr:editor:hidden");

    this.stopListening();
  },

  // when mounted load the post or draft and update state when they have loaded
  componentDidMount: function () {
    Tremr.dispatcher.message(this, "tremr:editor:visible");

    // every XXs autosave if dirty and not already saving
    this.autosaveInterval = setInterval(
      function () {
        if (this.state.dirty && !this.state.isSaving) {
          var invalid = this.invalid(true);
          if (!invalid) {
            var post = this.getPostForSave();

            // set draft field
            post.set("draft", "1");

            this.setState({ isAutosaving: true });

            // raise event to save
            Tremr.dispatcher.message(this, "tremr:post:save", {
              type: "autosave",
              post: post,
              callback: function () {},
            });
          }
        }
      }.bind(this),
      10000
    );

    // listen for saves, so we can update our draft if we save
    _.extend(this, Backbone.Events);

    // watch for before unload while editing
    $(window).on("beforeunload", this.confirmNavigateAway);

    // if the editor is open then listen for askdiscard events - if not dirty or
    // user confirms then call the callback
    this.listenTo(
      Tremr.dispatcher,
      "tremr:editor:askdiscard",
      function (callbacks) {
        if (!this.state.dirty) {
          if (callbacks.discardCallback) {
            callbacks.discardCallback();
          }
        } else {
          alertify.confirm("Discard Changes?", function (e) {
            if (e) {
              if (callbacks.discardCallback) {
                callbacks.discardCallback();
              }
            } else if (callbacks.keepCallback) {
              callbacks.keepCallback();
            }
          });
        }
      }.bind(this)
    );

    // if the editor is open then close it when that draft is deleted
    this.listenTo(
      Tremr.dispatcher,
      "tremr:draft:delete:success",
      function (draft_id) {
        alertify.success("Draft deleted.");
        Tremr.dispatcher.message(this, "tremr:editor:close");
      }.bind(this)
    );

    // if the editor is open then close it when that post is deleted
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:delete:success",
      function (post_id) {
        alertify.success("Post deleted.");
        Tremr.dispatcher.message(this, "tremr:editor:close");
      }.bind(this)
    );

    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:delete:cancel",
      function (draft_id) {
        this.setState({ isSaving: false });
      }.bind(this)
    );

    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:save:success",
      function (postModel) {
        var newState = {};

        // only update the post model if we aren't axutosaving
        if (!this.state.isAutosaving) {
          newState = this.getModelData(postModel);
        }
        newState.existingDraft = postModel; // make sure we update draft_id
        newState.isAutosaving = false;
        newState.isSaving = false;
        newState.dirty = false;
        this.setState(newState);

        // update the URL to match the (potentially) new draft_id
        if (postModel.get("draft") == "1" && postModel.get("draft_id")) {
          // dummy editor state, to generate URL
          var editorState = {
            open: true,
            draft_id: postModel.get("draft_id"),
          };

          var newUrl = Tremr.routes.urlFor({ editor: editorState });
          Tremr.routes.updateUrl(newUrl); // make sure our URL is updated
        }
      }.bind(this)
    );

    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:save:failed",
      function (postModel) {
        this.setState({ isSaving: false, isAutosaving: "failed" });
      }.bind(this)
    );

    // load existing posts if needed
    if (this.props.existingPostId) {
      this.loadPost(this.props.existingPostId);
    } else if (this.props.existingDraftId) {
      // load draft if needed
      this.loadDraft(this.props.existingDraftId);
    } else {
      // load the users feed, to get subfeeds (unless we have been provided a channel)
      let user = Tremr.stores.userStore.getUser();
      if (user) {
        let feed = user.get("feedname");
        this.loadUserFeed(feed);
        if (
          this.props.channel &&
          this.props.channel.get("name") &&
          this.props.channel.get("name") != feed
        ) {
          this.loadChannel(this.props.channel.get("name"));
        }
      }
    }
  },

  loadUserFeed: function (channelname) {
    let feedModel = new Tremr.Models.Feed();
    let promise = Tremr.stores.feedStore.loadFeed(
      feedModel,
      channelname,
      false
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        this.setState({ feed: params.model });
      }.bind(this)
    );
  },

  loadChannel: function (channelname) {
    this.setState({
      subfeedsToggleState: false,
    });
    let feedModel = new Tremr.Models.Feed();
    let promise = Tremr.stores.feedStore.loadFeed(
      feedModel,
      channelname,
      false
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        let newState = { channel: params.model };

        // set the users role within this channel
        let user = Tremr.stores.userStore.getUser();
        if (
          user &&
          user.isEditorOfFeed(params.model.get("name"), params.model)
        ) {
          newState.isEditor = true;
        } else if (
          user &&
          user.isContributorOfFeed(params.model.get("name"), params.model)
        ) {
          newState.isContributor = true;
        } else {
          newState.isCommunity = true;
        }

        // if we have channel subfeed data on the model, set these in state
        let channelSubfeeds = [];
        let channelfeed = params.model.get("name");
        if (this.state.existingPost) {
          _.each(this.state.existingPost.get("feeds"), (f) => {
            if (
              f.name.trim().toLowerCase() == channelfeed.trim().toLowerCase()
            ) {
              if (f.subfeed) {
                channelSubfeeds.push(f.subfeed);
              }
            }
          });
          newState["channelSubfeeds"] = channelSubfeeds;
        }

        // update the state
        this.setState(newState);
      }.bind(this)
    );
  },

  // check all fields have appropriate values,
  // returns false if valid and an object containing
  // the errors if not valid
  invalid: function (isDraft) {
    if (isDraft === undefined) {
      isDraft = false;
    }

    var errors = false;

    // validate from the fieldset
    if (this.state.fieldset == "article") {
      errors = this.refs["fieldsetArticle"].invalid(this.state);
    } else if (this.state.fieldset == "images") {
      errors = this.refs["fieldsetImages"].invalid(this.state);
    } else if (this.state.fieldset == "link") {
      errors = this.refs["fieldsetLink"].invalid(this.state);
    } else if (this.state.fieldset == "video") {
      errors = this.refs["fieldsetVideo"].invalid(this.state);
    } else if (this.state.fieldset == "tweet") {
      errors = this.refs["fieldsetTweet"].invalid(this.state);
    }

    // length of summary (if we have one)
    if (this.state.summary && this.state.summary.length > 280) {
      if (!errors) {
        errors = {};
      }
      errors.summary = {
        message: "Please reduce to 280 characters or less",
      };
    }

    // number of tags (if we have any)
    if (this.state.tags && this.state.tags.length > 10) {
      if (!errors) {
        errors = {};
      }
      errors.tags = {
        message: "Please reduce to a maximum of 10 tags",
      };
    }

    // must have 1 tag for original post
    // if (!this.props.parent && !isDraft) {
    //     if (!this.state.tags || this.state.tags.length == 0) {
    //         if (!errors) {
    // 		errors = {};
    // 	}
    // 	errors.tags = {
    // 		message: 'Please enter at least one tag'
    // 	};
    //     }
    // }

    if (this.state.postType == "") {
      if (!errors) {
        errors = {};
      }
      errors.postType = {
        message: "Please select a post type",
      };
    }

    return errors;
  },

  // util to get the appropriate post (new, edit or draft version) and
  // copy the core fields in.  without specifics for saving as new post,
  // draft or preview
  getPostForSave: function () {
    // get the post we want to start with
    var post = false;
    if (this.state.existingDraft) {
      // need to convert draft to a post
      post = new Tremr.Models.Post(this.state.existingDraft.attributes);
      post.unset("id");

      // if we ALSO have an existing post then copy it's post_id in
      if (this.state.existingPostId) {
        post.set("update", "1");
        post.set("post_id", this.state.existingPostId);
      }
    } else if (this.state.existingPost) {
      post = this.state.existingPost;

      post.set("update", "1");
      post.set("post_id", post.get("_id"));
    } else {
      post = new Tremr.Models.Post();

      post.set("author_id", this.props.author.get("id"));
      post.set("author_feed", _.first(this.props.author.get("feeds")).name);
    }

    // parent if we have one
    if (this.props.parent) {
      post.set(
        "parent_id",
        this.props.parent.get("_id") || this.props.parent.get("id")
      );
    }

    // update fields
    post.set("post_type", this.state.postType);
    post.set("featured", this.state.featured);
    post.set("headline", this.state.headline);
    post.set("subfeeds", this.state.subfeeds);
    post.set("fieldset", this.state.fieldset);
    post.set("door_policy", this.state.doorPolicy);

    if (this.state.channel) {
      post.set("channel_name", this.state.channel.get("name"));
      post.set("channel_subfeeds", this.state.channelSubfeeds);
    } else {
      post.set("channel_name", this.state.feed.get("id"));
    }

    post.set("tags", this.state.tags);

    // delete the fields set by the fieldsets
    post.unset("title");
    post.unset("summary");
    post.unset("image");
    post.unset("embed");
    post.unset("content_blocks");

    // get content from the fieldset
    if (this.state.fieldset == "article") {
      post.set(this.refs["fieldsetArticle"].getFieldsContent(this.state));
    } else if (this.state.fieldset == "images") {
      post.set(this.refs["fieldsetImages"].getFieldsContent(this.state));
    } else if (this.state.fieldset == "link") {
      post.set(this.refs["fieldsetLink"].getFieldsContent(this.state));
    } else if (this.state.fieldset == "video") {
      post.set(this.refs["fieldsetVideo"].getFieldsContent(this.state));
    } else if (this.state.fieldset == "tweet") {
      post.set(this.refs["fieldsetTweet"].getFieldsContent(this.state));
    }

    return post;
  },

  // set/unset featured flag on post
  toggleFeatured: function () {
    this.setState({
      featured: !this.state.featured,
    });
  },

  // set/unset headline flag on post
  toggleHeadline: function () {
    this.setState({
      headline: !this.state.headline,
    });
  },

  // delete if we have a draft or post
  delete: function () {
    // do nothing if we are saving
    if (this.state.isSaving) {
      return;
    }

    // handle depending on draft/post
    if (this.state.existingDraft) {
      Tremr.dispatcher.message(
        this,
        "tremr:draft:delete",
        this.state.existingDraft
      );
      this.setState({ isSaving: true });
    } else if (this.state.existingPost) {
      Tremr.dispatcher.message(
        this,
        "tremr:post:delete",
        this.state.existingPost
      );
      this.setState({ isSaving: true });
    } else {
      alertify.error("You may only delete existing drafts and posts.");
    }
  },

  // save the post as a post (not draft)
  save: function () {
    // do nothing if we are saving
    if (this.state.isSaving) {
      return;
    }

    var invalid = this.invalid();
    if (!invalid) {
      this.setState({ isSaving: true, currentErrors: invalid });

      var post = this.getPostForSave();

      // remove draft field
      post.unset("draft");

      // raise event to save
      Tremr.dispatcher.message(this, "tremr:post:save", {
        type: "post",
        post: post,
        callback: this.props.onPost,
      });
    } else {
      this.setState({ currentErrors: invalid });
      alertify.error("Please fix all of the errors.");

      // set the scroll position
      this.scrollTop();
    }
  },

  // util method used in handler for save draft + preview
  saveDraft: function (callback) {
    // do nothing if we are saving
    if (this.state.isSaving) {
      return;
    }

    var invalid = this.invalid(true);
    if (!invalid) {
      this.setState({ isSaving: true, currentErrors: invalid });

      var post = this.getPostForSave();

      // set draft field
      post.set("draft", "1");

      // raise event to save
      Tremr.dispatcher.message(this, "tremr:post:save", {
        type: "draft",
        post: post,
        callback: callback,
      });

      return true;
    } else {
      this.setState({ currentErrors: invalid });
      alertify.error("Please fix all of the errors.");

      return false;
    }
  },

  // save the post as a draft and preview
  preview: function () {
    this.saveDraft(function (m) {
      alertify.success("Draft saved.");

      var overlayData = {
        class: "Tremr.Post.Preview",
        modal: true,
        props: {
          post: m,
        },
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    });
  },

  // save the post as a draft
  draft: function () {
    this.saveDraft(function (m) {
      alertify.success("Draft saved.");
    });
  },

  // update postType
  changePostType: function (selected) {
    var item = "";
    if (selected && selected.length > 0) {
      item = selected[0];
    }

    this.setState({ postType: item, dirty: true });
  },

  changeSubfeeds: function (subfeeds) {
    this.setState({ subfeeds: subfeeds, dirty: true });
  },

  changeChannelSubfeeds: function (subfeeds) {
    this.setState({ channelSubfeeds: subfeeds, dirty: true });
  },

  // changeFieldset: function(fieldset) {
  //     // only change is actually needed and we're not saving
  //     if (this.state.fieldset != fieldset && !this.state.isSaving) {
  //
  //         // confirm, if we are dirty or have existing content
  //         if (this.state.dirty || this.state.existingDraft || this.state.existingPost) {
  //             let fieldsetname = this.state.fieldset.capitalize();
  //             if (fieldsetname == "Images") {
  //                 fieldsetname = "Image";
  //             }
  //
  //             alertify.confirm("Discard "+fieldsetname+" specific fields?", function(confirmed) {
  //                 if (confirmed) {
  //
  //                    let newState = {fieldset: fieldset};
  //
  //                    // depending on the new fieldset delete/convert fields
  //                    if (fieldset != 'article') {
  //                        newState.content_blocks = null;
  //                    }
  //                    if (fieldset == 'images' && this.state.headerMode != 'image') {
  //                        newState.headerMode = 'unset';
  //                        newState.headerData = null;
  //                        newState.summary = null;
  //                    }
  //                    if (fieldset == 'link' && this.state.headerMode != 'embed' && this.state.headerData) {
  //                        // if (fieldset == 'link' && this.state.headerMode != 'embed' && this.state.headerData && this.state.headerData.type != 'link') {
  //                        newState.headerMode = 'unset';
  //                        newState.headerData = null;
  //                        newState.summary = null;
  //                    }
  //                    if (fieldset == 'video' && this.state.headerMode != 'embed' && this.state.headerData && this.state.headerData.type != 'video') {
  //                        newState.headerMode = 'unset';
  //                        newState.headerData = null;
  //                        newState.summary = null;
  //                    }
  //                    if (fieldset == 'twitter' && this.state.headerMode != 'embed' && this.state.headerData && this.state.headerData.provider_name != 'Twitter') {
  //                        newState.headerMode = 'unset';
  //                        newState.headerData = null;
  //                        newState.summary = null;
  //                    }
  //
  //
  //                    this.setState(newState);
  //                 }
  // 			}.bind(this));
  //         } else {
  //             this.setState({fieldset: fieldset});
  //         }
  //     }
  // },

  changeTags: function (tags) {
    delete this.state.currentErrors["tags"];
    this.setState({ tags: tags, dirty: true });
  },

  changeTitle: function (title) {
    delete this.state.currentErrors["title"];
    this.setState({ title: title.value, dirty: true });
  },

  changeSummary: function (summary) {
    this.setState({ summary: summary.value, dirty: true });
  },

  changeHeader: function (data) {
    var newState = {
      headerMode: data.mode,
      dirty: true,
    };
    if (data.mode == "unset") {
      newState["headerData"] = false;
    } else {
      newState["headerData"] = data.data;
    }

    this.setState(newState);
  },

  // set if this post is a headline (not to be confused with header)
  // changeHeadline: function(selected) {

  //   var isHeadline = false;
  //   if (selected && selected.length > 0) {
  //     isHeadline = true;
  //   }

  //   this.setState({
  //     isHeadline: isHeadline,
  //     dirty: true
  //   });
  // },

  // toggleControls: function(event) {

  //   this.setState({
  //     showControls: !this.state.showControls
  //   });
  // },

  // rich text has changed, count words in blocks
  contentChanged: function (blocks) {
    // don't update in state - we just read that when saving
    if (!this.state.dirty) {
      this.setState({ dirty: true });
    }
  },

  scrollTop: function (event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    var domNode = ReactDOM.findDOMNode(this);
    var container = $(domNode).closest(".editor");
    $(container).scrollTo(0, { duration: 500 });
  },

  minimize: function (event) {
    console.log("minimize");
  },

  fieldsetChange: function (event) {
    if (event.field == "title") {
      this.changeTitle(event.data);
    } else if (event.field == "tags") {
      this.changeTags(event.data);
    } else if (event.field == "header") {
      this.changeHeader(event.data);
    } else if (event.field == "content") {
      this.contentChanged(event.data);
    }
  },

  updateDoorPolicy: function (newPolicy) {
    console.log("updateDoorPolicy", newPolicy);
    this.setState({
      doorPolicy: Object.assign({}, newPolicy),
    });
  },

  // draw the editor.  If we have an existingPostId or existingDraftId and they aren't
  // yet in state then show loading version.
  render: function () {
    // see if we are loading
    if (
      (this.props.existingPostId && !this.state.existingPost) ||
      (this.props.existingDraftId && !this.state.existingDraft)
    ) {
      return <div className="editor loading"></div>;
    }

    var header_data = false;
    if (this.state.header_image) {
      header_data = this.state.header_image;
    }

    var errors = this.state.currentErrors;

    var avatar = null;
    var username = null;
    var user = Tremr.stores.userStore.getUser();
    var status = "";

    if (user) {
      var draftSavedTime = "";
      if (
        this.state.existingDraft &&
        this.state.existingDraft.has("created_at")
      ) {
        var created = moment(
          this.state.existingDraft.get("created_at"),
          "YYYY-MM-DDTHH:mmZ"
        );
        var age = created.fromNow(true);
        draftSavedTime = age.toLowerCase() + " ago";
      }
      if (this.state.existingDraft && this.props.existingPostId) {
        status = (
          <span>
            Draft - saved <span className="time">{draftSavedTime}</span>
          </span>
        );
      } else if (this.state.existingDraft) {
        status = (
          <span>
            Draft - saved <span className="time">{draftSavedTime}</span>
          </span>
        );
      } else if (this.props.existingPostId) {
        status = "Editing";
      } else {
        status = "New Post";
      }

      if (this.state.isAutosaving == "failed") {
        status = "Autosave failed";
      } else if (this.state.isAutosaving) {
        status = "Saving…";
      }
      status = <div className="status">{status}</div>;

      username = (
        <div className="info">
          <div className="username">{user.get("name")}</div>
        </div>
      );

      if (user.get("avatar_image") && user.get("avatar_image").public_id) {
        avatar = (
          <div className="avatar">
            <Tremr.Generic.Image
              height={54}
              width={54}
              image={user.get("avatar_image")}
            />
          </div>
        );
      }
    }

    // feed is loaded so we can get subfeeds but may not have loaded yet
    let subfeedsCtrl = null;
    let channelSubfeedsCtrl = null;

    if (this.state.isEditor && this.state.channel) {
      channelSubfeedsCtrl = (
        <Subfeeds
          title={"Post in " + this.state.channel.get("title") + "…"}
          key={"channelSubfeeds"}
          selectedSubfeeds={this.state.channelSubfeeds}
          onChange={this.changeChannelSubfeeds}
          subfeeds={this.state.channel.get("subfeeds")}
        />
      );
    } else if (this.state.isContributor && this.state.channel) {
      let allowedSubfeeds = _.reject(
        this.state.channel.get("subfeeds"),
        (subf) => {
          return subf.featured == "yes";
        }
      );
      channelSubfeedsCtrl = (
        <Subfeeds
          canAdd={false}
          title={"Post in " + this.state.channel.get("title") + "…"}
          key={"channelSubfeeds"}
          selectedSubfeeds={this.state.channelSubfeeds}
          onChange={this.changeChannelSubfeeds}
          subfeeds={allowedSubfeeds}
        />
      );
    } else if (this.state.channel) {
      // show a stub of saving to community
      channelSubfeedsCtrl = (
        <div key={"channelSubfeeds"} className="post-subfeeds">
          <h2>Post in {this.state.channel.get("title")} Community</h2>
        </div>
      );
    }

    if (this.state.feed) {
      let canToggleSubfeeds = channelSubfeedsCtrl;
      let onToggle = function () {
        this.setState({
          subfeedsToggleState: !this.state.subfeedsToggleState,
        });
      }.bind(this);
      // console.log(
      //   "this.state.subfeedsToggleState=" + this.state.subfeedsToggleState
      // );
      subfeedsCtrl = (
        <Subfeeds
          onToggle={onToggle}
          toggleState={this.state.subfeedsToggleState}
          canToggle={true}
          title="Post in feed(s)…"
          key={"subfeeds"}
          selectedSubfeeds={this.state.subfeeds}
          onChange={this.changeSubfeeds}
          subfeeds={this.state.feed.get("subfeeds")}
        />
      );
      if (this.state.channel) {
        if (this.state.feed.get("id") == this.state.channel.get("id")) {
          channelSubfeedsCtrl = null;
        }
      }
    }

    // container for lots of the controls
    let controls = null;
    let summaryControl = null;
    if (this.state.fieldset == "article") {
      summaryControl = (
        <Tremr.Generic.Errorfield
          error={errors.summary}
          message={errors.summary ? errors.summary.message : ""}
        >
          <Tremr.Editor.ContentEditable
            classNames={{
              summary: true,
            }}
            initialValue={this.state.summary}
            onChange={this.changeSummary}
            maxLength={280}
            placeholder="Add summary…"
          />
        </Tremr.Generic.Errorfield>
      );
    }

    // var preview = <div onClick={this.props.preview} className="preview"><Tremr.Generic.SvgIcon icon="view" /></div>;
    let saveDraft = (
      <div onClick={this.draft} className="draft">
        <SvgIcon icon="save" />
        <span>Save Draft</span>
      </div>
    );
    let discardPost = null;
    let deleteText = this.state.existingPost ? "Delete Post" : "Discard Draft";
    let canDelete = this.state.existingPost || this.state.existingDraft;
    if (canDelete) {
      discardPost = (
        <div className="line">
          <div onClick={this.delete} className="discard">
            {deleteText}
          </div>
        </div>
      );
    }

    let doorPolicySettings = null;
    
    // always hide door policy (which will default to Everyone)
    // if (!this.props.parent) {
      
    //   console.log("doorPolicySettings", this.state.doorPolicy);

    //   doorPolicySettings = (
    //     <DoorPolicySettings
    //       policy={this.state.doorPolicy}
    //       change={this.updateDoorPolicy}
    //     />
    //   );      
    // }

    // if (this.state.showControls) {
    controls = (
      <div className="controls">
        {/* <div className="post-type">
				<Tremr.Generic.Errorfield error={errors.postType} message={errors.postType
						? errors.postType.message
						: ''}>
					<Tremr.Generic.Optionlist initialSet={[this.state.postType]} onChange={this.changePostType} options={_.map(Tremr.stores.postStore.postTypes(), function(type) {
							return {label: type, value: type};
						})}/>
				</Tremr.Generic.Errorfield>
			</div> */}

        {/* <Tremr.Generic.Errorfield fieldName="categoryField" error={errors.category} message={errors.category
					? errors.category.message
					: ''}>
				<Tremr.Generic.Dropdown ref="categoryDropdown" dropdown={false} label={this.state.category}>
					<div className="broadTags">
						<div className="col-header">Popular</div>
						{
							_.map(Tremr.stores.tagStore.broadTags(), function(tag) {
								return <div key={'tag-' + tag} onClick={this.changeCategory} data-value={tag}>{tag}</div>;
							}.bind(this))
						}
					</div>
					<div className="nicheTags">
						<div className="col-header">A-Z</div>
						{
							_.map(Tremr.stores.tagStore.nicheTags(), function(tag) {
								return <div key={'category-' + tag} onClick={this.changeCategory} data-value={tag}>{tag}</div>;
							}.bind(this))
						}
					</div>
				</Tremr.Generic.Dropdown>
            </Tremr.Generic.Errorfield> */}

        <div className="line summary-line">{summaryControl}</div>
        <div className="line">{saveDraft}</div>
        {discardPost}
        {doorPolicySettings}

        {subfeedsCtrl}
        {channelSubfeedsCtrl}
      </div>
    );

    // }

    // let postButton = <Tremr.Editor.PostButton isSaving={this.state.isSaving} savingText="Saving" canDelete={this.state.existingPost || this.state.existingDraft} deleteText={this.state.existingPost
    // 		? 'Delete'
    // 		: 'Discard'} isHeadline={this.state.headline} saveText={this.state.existingPost
    // 		? 'Update'
    // 		: 'Post'} save={this.save} draft={this.draft} delete={this.delete} preview={this.preview} toggleFeatured={this.toggleFeatured} toggleHeadline={this.toggleHeadline}/>;

    let postButton = (
      <PostMenu
        closeEditor={this.closeEditor}
        isSaving={this.state.isSaving}
        savingText="Saving"
        saveText={this.state.existingPost ? "Update" : "Post"}
        save={this.save}
        draft={this.draft}
      >
        {controls}
      </PostMenu>
    );

    // if we have been asked to and have ancestors show them
    var breadcrumbs = null;
    if (
      this.state.existingDraft &&
      this.state.existingDraft.get("ancestors") &&
      this.state.existingDraft.get("ancestors").length > 0
    ) {
      let ancestorSlug = _.last(
        this.state.existingDraft.get("ancestors")
      ).slug.split("!");
      let breadcrumbsContext = {
        feed: ancestorSlug[0],
        id: ancestorSlug[1],
      };
      breadcrumbs = (
        <Breadcrumbs
          includeSource={true}
          context={breadcrumbsContext}
          cache={false}
          posts={this.state.existingDraft.get("ancestors")}
        />
      );
    } else if (
      this.state.existingPost &&
      this.state.existingPost.get("ancestors") &&
      this.state.existingPost.get("ancestors").length > 0
    ) {
      let ancestorSlug = _.last(
        this.state.existingPost.get("ancestors")
      ).slug.split("!");
      let breadcrumbsContext = {
        feed: ancestorSlug[0],
        id: ancestorSlug[1],
      };
      breadcrumbs = (
        <Breadcrumbs
          includeSource={true}
          context={breadcrumbsContext}
          cache={false}
          posts={this.state.existingPost.get("ancestors")}
        />
      );
    } else if (this.props.parent) {
      let ancestorSlug = this.props.parent.get("slug").split("!");
      let breadcrumbsContext = {
        feed: ancestorSlug[0],
        id: ancestorSlug[1],
      };
      let ancestors = [];
      if (
        this.props.parent.get("ancestors") &&
        this.props.parent.get("ancestors").length > 0
      ) {
        ancestors = this.props.parent
          .get("ancestors")
          .concat([this.props.parent.attributes]);
      } else {
        ancestors = [this.props.parent.attributes];
      }

      breadcrumbs = (
        <Breadcrumbs
          includeSource={true}
          context={breadcrumbsContext}
          cache={false}
          posts={ancestors}
        />
      );
    }

    var classes = {
      editor: true,
      saving: this.state.isSaving,
      autosaving: this.state.isAutosaving,
      parent: this.props.parent,
      update: this.state.existingPost,
    };
    if (this.state.postType) {
      classes[this.state.postType] = true;
    }
    classes = classNames(classes);

    // <TypesMenu current={this.state.fieldset} change={this.changeFieldset}/>
    // <SvgIcon key="minimize" icon="home" onClick={this.minimize}/>
    let userRoleDesc = null;
    if (this.state.isContributor) {
      userRoleDesc = " (Contributor)";
    } else if (this.state.isCommunity) {
      userRoleDesc = " (Community)";
    } else {
      userRoleDesc = " (Editor)";
    }

    let channel = null;
    if (this.state.channel && this.state.channel.get("channel") == "yes") {
      channel = (
        <div className="channel">
          {this.state.channel.get("title")} {userRoleDesc}
        </div>
      );
    }

    var toolbar = (
      <div className="editor-toolbar">
        <SvgIcon key="close" icon="remove" onClick={this.closeEditor} />
        {channel}
        {status}
        {postButton}
      </div>
    );

    let fields = null;
    if (this.state.fieldset == "article") {
      fields = (
        <Article
          ref="fieldsetArticle"
          fields={{
            title: this.state.title,
            tags: this.state.tags,
            headerMode: this.state.headerMode,
            headerData: this.state.headerData,
            header_data: this.state.header_data,
            content_blocks: this.state.content_blocks,
          }}
          change={this.fieldsetChange}
          errors={errors}
        />
      );
      // } else if (this.state.fieldset == "images") {
      //     fields = <Images ref="fieldsetImages" fields={{
      //         title: this.state.title,
      //         headerMode: this.state.headerMode,
      //         headerData: this.state.headerData,
      //         header_data: this.state.header_data
      //     }} change={this.fieldsetChange} errors={errors} />;
      // } else if (this.state.fieldset == "link") {
      //     fields = <Link ref="fieldsetLink" fields={{
      //         title: this.state.title,
      //         headerMode: this.state.headerMode,
      //         headerData: this.state.headerData,
      //         header_data: this.state.header_data
      //     }} change={this.fieldsetChange} errors={errors} />;
      // } else if (this.state.fieldset == "video") {
      //     fields = <Video ref="fieldsetVideo" fields={{
      //         title: this.state.title,
      //         headerMode: this.state.headerMode,
      //         headerData: this.state.headerData,
      //         header_data: this.state.header_data
      //     }} change={this.fieldsetChange} errors={errors} />;
      //
      // } else if (this.state.fieldset == "tweet") {
      //     fields = <Tweet ref="fieldsetTweet" fields={{
      //         title: this.state.title,
      //         headerMode: this.state.headerMode,
      //         headerData: this.state.headerData,
      //         header_data: this.state.header_data
      //     }} change={this.fieldsetChange} errors={errors} />;
    }

    // massive fudge for the byline - wrap the user so we can convert the
    // fields used on a post directly from the user
    let byline = null;
    if (user) {
      let dummyPost = {
        model: user,
        get: function (field) {
          if (field == "author_bio") {
            return user.get("bio");
          } else if (field == "author_avatar_image") {
            return user.get("avatar_image");
          } else if (field == "author_tags") {
            return user.get("tags");
          } else if (field == "channel_author_feed") {
            return user.get("feedname");
          } else if (field == "author_name") {
            return user.get("name");
          }
        },
      };
      byline = (
        <Byline
          ref="byline"
          subfeeds={[]}
          avatar_size={40}
          showFollow={false}
          post={dummyPost}
          subheaderContent={dummyPost.get("author_bio")}
        ></Byline>
      );
    }

    // let doorPolicySummary = null;
    // if (this.state.doorPolicy) {
    //   if (this.state.doorPolicy.option == "Restricted") {
    //     policies = [];
    //     if (this.state.doorPolicy.restricted?.includes("Reputation")) {
    //       if (this.state.doorPolicy.restrictedStars > 0) {
    //         policies.push(
    //           <Stars
    //             alignment="left"
    //             scale={window.devicePixelRatio}
    //             drawUnset={false}
    //             setColor={"#2d2d2d"}
    //             lineWidth={3}
    //             starSize={14}
    //             height={14}
    //             width={51}
    //             stars={this.state.doorPolicy.restrictedStars}
    //             filledStars={0}
    //           />
    //         );
    //       }
    //     }
    //     if (policies.length > 0) {
    //       doorPolicySummary = (
    //         <div className="door-policy-summary-container">
    //           <div className="door-policy-summary">
    //             <SvgIcon icon="locked" />
    //             <p>{policies}</p>
    //           </div>
    //         </div>
    //       );
    //     }
    //   }
    //   // doorPolicy: {
    //   //   option: "Everyone",
    //   //   restricted: [],
    //   // },
    // }

    return (
      <div className={classes}>
        {toolbar}

        {/* {doorPolicySummary} */}

        {breadcrumbs}

        {byline}

        {fields}
      </div>
    );
  },
});
