require("./scss/subfeeds.scss");

var alertify = require('alertify');
var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');
var TremrUtils = require('../../utils/tremr_utils');
var IconButton = require('../generic/iconbutton');
var Subfeed = require('../../models/Subfeed');
var CreateReactClass = require('create-react-class');
var PropTypes = require('prop-types');

module.exports = CreateReactClass({

	mixins: [UserAwareMixin], // cant be pure because the sortablejs re-orders the array!

    propTypes: {
        subfeeds: PropTypes.array,
        selectedSubfeeds: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        title: PropTypes.string,
        canAdd: PropTypes.bool,
        onToggle: PropTypes.func,
        toggleState: PropTypes.bool,
        canToggle: PropTypes.bool
    },

    getDefaultProps: function() {
		return {
			subfeeds: [],
			selectedSubfeeds: [],
            title: "Post in…",
            canAdd: true,
            onToggle: _.noop,
            toggleState: true,
            canToggle: false
		};
	},

	getInitialState: function() {

		return {
			subfeeds: this.props.subfeeds.slice(),
			selectedSubfeeds: this.props.selectedSubfeeds.slice(),
			newsubfeedname: '',
			subfeedsUpdatedIndex: 0
		};
	},

	// keep track of new subfeed, until it is entered
	newSubfeed: function(content) {

		this.setState({
			newsubfeedname: content.value
		});
	},

    handleKeyUp: function(event) {

        if (event.keyCode === 13) {
            this.endNewSubfeed();
        }
    },


	// add the new subfeed and clear the new field
	endNewSubfeed: function() {

		let isError = false;

        if (this.state.newsubfeedname.trim() == '') {
			alertify.error("Please enter a feed name");
			isError = true;
		}

		let subfeeds = this.state.subfeeds.slice();

		// check for duplicate
		_.each(subfeeds, (subfeed) => {
				if (subfeed.name.toLowerCase() == this.state.newsubfeedname.trim().toLowerCase()) {
				alertify.error("Duplicate feed name");
				isError = true;
			}
		});

		if (!isError) {

			// add new one, with generated guid
			subfeeds.push({
				id: TremrUtils.uniqueId(),
				name: this.state.newsubfeedname.trim()
			});

			let selected = this.state.selectedSubfeeds.concat([this.state.newsubfeedname]);

			// send to parent
			if (this.props.onChange) {
				this.props.onChange(selected);
			}

			this.setState({
				subfeeds: subfeeds,
				selectedSubfeeds: selected,
				newsubfeedname: '',
				subfeedsUpdatedIndex: this.state.subfeedsUpdatedIndex + 1
			});
		}
	},

	selectedSubfeed: function(params) {

		this.setState({
			selectedSubfeeds: params
		});

		// send to parent
		if (this.props.onChange) {
			this.props.onChange(params);
		}
	},

	render: function() {

		// use a key on the input field so we can force a new component when the
		// new feed is saved, thus clearing the text of the field.
		let inputKey = TremrUtils.uniqueId();
		if (this.state.subfeeds.length > 0) {
			inputKey = this.state.subfeeds[this.state.subfeeds.length - 1].id;
		}

        // <Tremr.Generic.Input key={inputKey} onEnter={this.endNewSubfeed} onChange={this.newSubfeed} name="newFeed" label="Add new feed" type="text" initialValue={this.state.newsubfeedname} maxLength={30} />
        let addControl = null;
        if (this.props.canAdd) {
            addControl = <div className="option-list">
                <div className="option-item">
                    <Tremr.Generic.SvgIcon icon="plus" />
                    <Tremr.Editor.ContentEditable key={inputKey} classNames={{contenteditable: true, newFeed: true}} onKeyUp={this.handleKeyUp} initialValue={this.state.newsubfeedname} onChange={this.newSubfeed} maxLength={30} placeholder="Add new feed"/>
                    <div className="button add" onClick={(event) => {
						this.endNewSubfeed();
					}}>Add</div>
                </div>
            </div>;
        }

        let header =  <h2>{this.props.title}</h2>;
        if (this.props.canToggle) {
            let headerClasses = {
                handle: true,
                open: (this.props.toggleState)
            };
            headerClasses = classNames(headerClasses);
            header =  <h2 className={headerClasses} onClick={this.props.onToggle}>
                {this.props.title}
            </h2>;
        }

        let content = null;
        if (this.props.toggleState || !this.props.canToggle) {
            content = <div>
                <div className="option-list">
                    <div className="disabled option-item">
                        <Tremr.Generic.OptionItem key={'option-allposts'} label="All Posts" value="all-posts" set={true} readonly={true} />
                    </div>
                </div>

                <Tremr.Generic.Optionlist key={"subfeedslist-"+this.state.subfeedsUpdatedIndex} multiSelect={true} initialSet={this.state.selectedSubfeeds} onChange={this.selectedSubfeed} options={_.map(this.state.subfeeds, function(subfeed) {
                    return {label: subfeed.name, value: subfeed.name};
                })} />

                {addControl}
            </div>;
        }

		return <div className="post-subfeeds">
				{header}
                {content}
		</div>;
	}

});
