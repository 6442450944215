var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// an individual breadcrumb (list of breadcrumbs follows)
// Tremr.Post.CardBreadcrumb = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	propTypes: {
        expanded: PropTypes.bool,
        expandHandler: PropTypes.func,
        post: PropTypes.object.isRequired,
        isFirst: PropTypes.bool
    },

	getDefaultProps: function() {

		return {
			expanded: false,
			isFirst: false,
			expandHandler: function(event) { }
		}
	},

	openPost: function(event) {

		event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

        var slugparts = this.props.post.slug.split('!');
        var route = {
            feed: slugparts[0],
            id: slugparts[1]
        };
        var postContext = {
            target: 'post',
            feed: route.feed,
            id: route.id
        };

        // if meta key, allow normal open - for container use
        // data-href to open in new tab
        if (event.metaKey) {
            if (event.currentTarget && !event.currentTarget.getAttribute('href')) {
                var url = Tremr.routes.urlFor({
                    primary: postContext
                });
                window.open(url);
            }
            return;
        }

        event.preventDefault();

        // var scrollpos;
        // var node = ReactDOM.findDOMNode(this);
        // scrollpos = $(node).closest('.primary').scrollTop(); // get scroll pos of container
        let scrollpos = window.scrollY;

        Tremr.navigateCurrent(postContext, scrollpos);
	},

	// draw breadcrumb - depending on expanded property
    render: function() {

    	// work out our top level class
		var classNames = require('../../utils/classnames');
        var classes = classNames({
			'breadcrumb': true,
			'expanded': this.props.expanded,
			'first': this.props.isFirst
		});

        var template = (<div onClick={this.props.expandHandler} className={classes}></div>);

		if (this.props.expanded) {
			template = (<div onClick={this.openPost} className={classes}>
				<div className="author">{this.props.post.author_name}</div>
				<div className="title">{this.props.post.title}</div>
			</div>);
		}

		return template;
    }
});
