var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');

// Tremr.Layout.Rightsidebar = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	// actually close this sidebar
	close: function() {

		var newState = 'closed';
		// if (this.props.rightSidebar == 'closed') {
		// 	newState = 'open';
		// }

		// update state
		var newState = { rightSidebar: newState }
	    Tremr.context.batch(newState);
	},

	componentDidMount: function() {

        // listen to alert tab for events instead of checking ourselves
        if (!this.stopListening) { _.extend(this, Backbone.Events); }

        this.listenTo(Tremr.dispatcher, "tremr:layout:rightsidebarcloseonmobile", function() {
        	if (Tremr.Utils.isDeviceOrNarrowWithSidebar()) {
            	this.close();
            }
        }.bind(this));

    },

    componentWillUnmount: function() {

        if (this.stopListening) {
            this.stopListening();
        }
    },


	// handle infinite scroll
	loadMore: function() {

		this.refs['rightsidebar-content'].loadMore();
	},

	render: function() {

		var classes = {
			'layout-sidebar': true,
			'right': true
		};
		if (this.props.rightSidebar) { classes[this.props.rightSidebar] = true; }
		classes = classNames(classes);

		var content = <Tremr.Activity.PostActivity ref="rightsidebar-content" />;

		var footer = <div>
	    	<a onClick={this.close}>Hide Activity</a>
	    </div>;

		return (
			<aside className={classes}>

				{content}
				{footer}

			</aside>
		);
	}
});
