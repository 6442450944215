var TremrTracking = require("../../utils/tracking");
var Promise = require("bluebird");
var GridLayouts = require("../../stores/generic/gridlayouts");
var ActivityListItem = require("./listitem");

// Tremr.ActivityStore
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  store.reactions = [
    "agree",
    "disagree",
    "awesome",
    "interesting",
    "important",
    "smart",
    "funny",
    "sad",
    "worrying",
    "misleading",
    "easy,:tiger!",
  ];

  store.postreports = [
    "ad:hominem",
    "abusive",
    "inappropriate",
    "spam",
    "hate:speech",
    "fake:news",
    "nudity",
    "trolling",
    "other",
  ];

  store.endorsements = [
    "writing",
    "images",
    "video",
    "insightful",
    "informative",
    "balanced",
    "respectful",
    "trustworthy",
    "conversation",
  ];

  // standard reaction tags
  store.reaction_tags = store.reactions;

  // // keep a collection of loaded activity
  // store.activity = new Tremr.Models.Activities();
  //
  // // keep a collection of seen activity
  // store.seen = new Tremr.Models.SeenActivities();
  //
  // store.getTagDesc = function(tag) {
  //
  //     if (tag == 'recommend') {
  //         return 'Recommended';
  //     } else if (tag == 'repost') {
  //         return 'Reposted';
  //     } else {
  //         return 'Marked '+tag.capitalize();
  //     }
  // };

  (store.getEncodedTag = function (tag) {
    return tag.replace(/\s/g, ":");
  }),
    // options can include:
    //    * set (has the user set this on this postor not),
    //    * sum (count of total done to post)
    //    * users (count of all users acted on this post)
    (store.getActivityDataPoints = function (post_id, tags, options) {
      if (options === undefined) {
        options = {
          set: true,
          sum: false,
          users: false,
        };
      }

      let dataPoints = [];

      if (options.set) {
        tags.forEach(
          function (tag) {
            let key = "posts/{post}/activity/{user}/" + this.getEncodedTag(tag);
            key = key.replace(/\{post\}/, post_id);

            dataPoints.push({
              name: this.getEncodedTag(tag) + "-set",
              key: key,
              user: true,
            });

            // reaction flag this user separately
            if (this.reactions.includes(tag)) {
              key = "posts/{post}/reactors/{user}";
              key = key.replace(/\{post\}/, post_id);

              dataPoints.push({
                name: "reactors",
                key: key,
                user: true,
              });
            }
          }.bind(this)
        );
      }

      if (options.sum) {
        tags.forEach(
          function (tag) {
            let key = "posts/{post}/" + this.getEncodedTag(tag) + "-sum";
            key = key.replace(/\{post\}/, post_id);

            dataPoints.push({
              name: this.getEncodedTag(tag) + "-sum",
              key: key,
            });
          }.bind(this)
        );
      }

      if (options.users) {
        // reactors
        let key = "posts/{post}/reactors-count";
        key = key.replace(/\{post\}/, post_id);
        dataPoints.push({
          name: "reactors-count",
          key: key,
        });
      }

      return dataPoints;
    });

  // options can include:
  //    * set (has the user set this on this postor not),
  store.getPostReportDataPoints = function (post_id, tags, options) {
    if (options === undefined) {
      options = {
        set: true,
      };
    }

    let dataPoints = [];

    if (options.set) {
      tags.forEach(
        function (tag) {
          let key =
            "posts/{post}/reports/{user}/tags/" + this.getEncodedTag(tag);
          key = key.replace(/\{post\}/, post_id);

          dataPoints.push({
            name: this.getEncodedTag(tag) + "-set",
            key: key,
            user: true,
          });
        }.bind(this)
      );
    }

    return dataPoints;
  };

  // options can include:
  //    * set (has the user set this on this postor not),
  store.getUserEndorsementDataPoints = function (ofuser, tags, options) {
    if (options === undefined) {
      options = {
        set: true,
      };
    }

    let dataPoints = [];

    if (options.set) {
      tags.forEach(
        function (tag) {
          let key =
            "endorsements/{ofuser}/reports/{user}/tags/" +
            this.getEncodedTag(tag);
          key = key.replace(/\{ofuser\}/, ofuser);

          dataPoints.push({
            name: this.getEncodedTag(tag) + "-set",
            key: key,
            user: true,
          });
        }.bind(this)
      );
    }

    return dataPoints;
  };

  // send to server to mark everything as seen
  store.listenTo(Tremr.dispatcher, "tremr:activity:seenall", function () {
    var user = Tremr.stores.userStore.getUser();
    if (user) {
      // set flag in firebase to flag all
      let user_id = user.get("_id");
      let fbSeenAllRef = Tremr.firebase
        .database()
        .ref("notifications/users/" + user_id + "/seenall");
      fbSeenAllRef.set(Date.now());
    }
  });

  // send to server to mark all responses as seen
  store.listenTo(
    Tremr.dispatcher,
    "tremr:activity:seenallresponses",
    function () {
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        // set flag in firebase to flag all
        let user_id = user.get("_id");
        let fbSeenAllRef = Tremr.firebase
          .database()
          .ref("notifications/users/" + user_id + "/responded_seenall");
        fbSeenAllRef.set(Date.now());
      }
    }
  );

  return store;
};
