require("./scss/icon_button.scss");

var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var UserAwareMixin = require('../../mixins/userawaremixin');
var TremrTracking = require('../../utils/tracking');

module.exports = CreateReactClass({

    mixins: [UserAwareMixin],

    propTypes: {
        tag: PropTypes.string.isRequired,
        onClick: PropTypes.func
    },

    getEncodedTag: function() {
        return this.props.tag.replace(/\s/g, ':');
    },

    // immediately toggle value in firebase
    handleClick: function(event) {

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.state.user) {
            if (this.props.onClick) {
                this.props.onClick();
            }

            // write true/false to the ref
            let value = this.props.liveDataPoints[this.getEncodedTag()+'-set'].data;
            if (!value) {
                value = false;
            }
            value = !value; // toggle
            this.props.liveDataPoints[this.getEncodedTag()+'-set'].fbRef.set(value, function(error) {
                if (error) {
                    console.log("Data could not be saved:");
                    console.dir(error);
                } else {
                    TremrTracking.react(this.state.user, this.props.post_id, this.props.tag, (value != false));
                }
            }.bind(this));
        } else {
            Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
                callback: this.handleClick,
                data: event
            });
            return;
        }
    },

    // display icon for the tag
    render: function() {

        let iconName = this.props.tag;
        let iconComponent = <Tremr.Generic.SvgIcon key="icon" icon={iconName} classes="button icon" />;
        let value = this.props.liveDataPoints[this.getEncodedTag()+'-set'].data;

        // see if we have loaded the image or not
        let classes = {};
        classes['activity-icon-button'] = true;
        classes['set'] = value;
        // 'set': this.state.userSet,
        classes[this.props.tag.replace(/\s/g, '-')] = true;
        classes = classNames(classes);

        return (<button title={this.props.tag.capitalize()} className={classes} onClick={this.handleClick}>
            {iconComponent}
        </button>);
    }
});
