var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// an SVG bar chart
// Tremr.Generic.BarChart
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	propTypes: {
        data: PropTypes.array.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired
    },

    render: function() {

		var min = Math.min.apply(Math, this.props.data);
		var max = Math.max.apply(Math, this.props.data);

		// always scale Y to 0 unless our min is negative
		if (min > 0) {
			min = 0;
		}
		var yOffset = 0;
		if (min < 0) {
			yOffset = -1 * min;
		}

		// make sure we have some height on the y axis even if data is all the same
		if (min == max) {
			max = min + 1;
		}

		// work out scale
		var barWidth = 1;
		var barSpace = 1;
		barWidth = Math.floor(this.props.width / this.props.data.length);
		var extraSpace = this.props.width - (barWidth * this.props.data.length);
		extraSpace = Math.floor(extraSpace / 2);
		barWidth = barWidth - barSpace;

		var scaleY = 1;
		// scaleY = Math.round((this.props.height-2) / (max - min));
		scaleY = (this.props.height-2) / (max - min);

		// convert bar number to screen coord
		var translateX = function(value) {
			return (value * (barWidth+barSpace));
		};

		// convert y value to screen coord (switch origin at bottom left to top left!)
		var translateY = function(value) {
			return (this.props.height - (-1 * (Math.round(value * scaleY) + yOffset)));
		}.bind(this);

		var xAxis = <line x1={translateX(0)} y1={translateY(0)} x2={this.props.width} y2={translateY(0)} />;

		var viewBox = "0 0 "+this.props.width+" "+this.props.height;

		return (
			<svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
				<g className="bars" strokeWidth="0">
				{this.props.data.map(function(value, key) {
					// // minus 2 gives us a gap between bar and axis
					return <rect className="bar" key={key} x={extraSpace + translateX(key)} y={translateY(0 - value) - 2} width={barWidth} height={Math.round(value * scaleY)} />;
				})}
				</g>
                <g className="axis" stroke="#4e4646" strokeWidth="3">
					{xAxis}
				</g>
			</svg>
		);
	}
});
