var GridLayouts = require("../../stores/generic/gridlayouts");
var Footer = require("../../stores/layout/footer");
var PostListCardWrapper = require("../post/listcardwrapper");
var WallControls = require("../post/wallcontrols");
var PostContentCard = require("../post/contentcard");

// Tremr.TagStore = function () {
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  // load tag from the server using a prebuilt backbone model
  this.loadTag = function (model, tagname, cache) {
    return Tremr.Utils.loadModel(
      model,
      {
        id: tagname,
      },
      cache
    );
  };

  // load the tags from firebase once
  this.loadedBroadTags = [];

  this.listenTo(
    Tremr.dispatcher,
    "tremr:init",
    function (data) {
      this.tagsRef = Tremr.firebase.database().ref("cms/tags");
      this.tagsRef.on(
        "value",
        function (snapshot) {
          this.loadedBroadTags = snapshot.val();
          Tremr.dispatcher.message(Tremr, "tremr:firebase:cms", "tags");
        }.bind(this)
      );
    },
    this
  );

  // list of broad tags
  this.broadTags = function () {
    return this.loadedBroadTags;

    // return [
    //    'politics',
    //    'business',
    //    'sports',
    //    'technology',
    //    'lifestyle',
    //    'environment',
    //    'entertainment',
    //    'journal'
    // ];
  };

  // list of niche tags
  this.nicheTags = function () {
    return [
      "architecture",
      "art",
      "autos",
      "books",
      "culture",
      "design",
      "economics",
      "education",
      "events",
      "fashion",
      "food & drink",
      "health & fitness",
      "history",
      "house & home",
      "humor",
      "internet",
      "music",
      "people",
      "photography",
      "science & nature",
      "shopping",
      "travel",
      "tv & movies",
      "other",
    ];
  };

  // list of broad and niche tags
  this.standardTags = function () {
    return this.broadTags().concat(this.nicheTags());
  };

  // trigger loading of activity for these posts
  this.requestActivityForPosts = function (data) {
    // if (data.length > 0) {
    //    var ids = data.map(function(model) {
    //       return model.id;
    //    });
    //    Tremr.dispatcher.message(this, "tremr:activity:forpost", {post_ids: ids});
    // }
  }.bind(this);

  // trigger loading of follow data (i.e. the follow button) on users or tags
  this.requestFollowingForEntity = function (data) {
    if (data.length > 0) {
      var ids = data.map(function (model) {
        var key = {
          entity: "feed",
          id: model.id,
        };
        if (model.type !== undefined) {
          key["entity"] = model.get("type");
        }
        return key;
      });
      Tremr.dispatcher.message(this, "tremr:follow:forentity", ids);
    }
  }.bind(this);

  // factory for footer content
  // this.getFooterContent = function(context) {
  //
  //    var tabs = store.getTabs(context);
  //    var defaultTab = store.getDefaultTab(context);
  //
  //    var key = "postsWall";
  //    if (context.scope == 'followers') {
  //       // cardFactory = this.usercardFactory;
  //       // collFactory = this.followersFactory;
  //       key = "followersWall";
  //    }
  //
  //    var Wallref = key + "." + context.tag.replace(/\W/g, '-') + "." + context.scope;
  //    var ref = "footer" + Wallref;
  //
  //    return <Footer ref={ref} key={ref}>
  //       <section>
  //          <Tremr.Generic.SvgIcon icon="tag"/>
  //          <label>{context.tag}</label>
  //          &nbsp;|&nbsp;
  //          <label>{context.scope}</label>
  //       </section>
  //       <Tremr.Layout.Filter tipPrefix="Order by " icons={true} wallIdentifier={Wallref} tabs={tabs} initialTab={defaultTab}/>
  //    </Footer>;
  // };

  // factory for getting the content for a state
  this.getPrimaryContent = function (context, cache) {
    let tabs = store.getTabs(context);
    let defaultTab = store.getDefaultTab(context);
    let datasources = store.getDataSources(context);
    let controls = null;
    let wallStyleProperty = null;
    let forceGridStyle = null;
    let loadedCallback = null;
    let loadedCallbackWait = true;

    // let loadedCallback = this.requestActivityForPosts;
    var cardFactory = this.postcardFactory;
    var collFactory = this.postsFactory;
    var key = "postsWall";
    if (context.scope == "followers") {
      loadedCallback = this.requestFollowingForEntity;
      forceGridStyle = "list";
      cardFactory = this.usercardFactory;
      collFactory = this.followersFactory;
      key = "followersWall";
      loadedCallbackWait = false;
    } else {
      controls = <WallControls wallStyleProperty="wallStylePosts" />;
      wallStyleProperty = "wallStylePosts";
    }

    var ref =
      key +
      "." +
      context.tag.replace(/\W/g, "-") +
      "." +
      context.scope +
      "." +
      context.sort;

    let scrollToPos = function () {
      window.scrollTo(0, context.initialScrollPos);
    };

    // call both callbacks
    var callback = function (data, wallStyle) {
      let fullContext = Tremr.context.getState();
      let isCached = fullContext.cache == true;

      Tremr.stores.feedStore.requestFollowingForEntity(data);

      _.defer(() => {
        if (loadedCallback) {
          loadedCallback(data);
        }
        // if (wallStyle == 'content' && !isCached) {
        //    this.requestContentForPosts(data);
        // }
      });

      if (isCached) {
        Tremr.context.add({
          cache: false,
        });
        // scroll to initial position
        if (context && context.initialScrollPos) {
          scrollToPos();
        }
      }
    }.bind(this);

    // return <Tremr.Generic.Wall key={ref} ref={ref} identifier={ref} loadedCallback={this.requestFollowingForEntity}  cardFactory={cardFactory} collectionFactory={collFactory} cache={cache} datasources={datasources} defaultTab={defaultTab} tabs={tabs} context={context} />;
    return (
      <Tremr.Generic.Wall
        loadedCallbackWait={loadedCallbackWait}
        forceGridStyle={forceGridStyle}
        wallStyleProperty={wallStyleProperty}
        controls={controls}
        key={ref}
        ref={ref}
        identifier={ref}
        loadedCallback={callback}
        cardFactory={cardFactory}
        collectionFactory={collFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  this.postsFactory = function () {
    return new Tremr.Models.Posts();
  };

  this.followersFactory = function () {
    return new Tremr.Models.Followers();
  };

  this.usercardFactory = function (model, index, props) {
    var key = "card-" + index + "-" + model.get("id");
    return (
      <Tremr.Feed.Card
        ref={key}
        key={key}
        feed={model}
        startWidth={props.startWidth}
        startTop={props.startTop}
        cardHeightSet={props.cardHeightSet}
      />
    );
  };

  this.postcardFactory = function (model, index, options) {
    let identifier = "";
    if (options && options.identifier) {
      identifier = options.identifier;
    }
    var key = "card-" + index + "-" + model.get("id");

    if (options.wallStyle == "content") {
      return (
        <PostContentCard
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else if (options.wallStyle == "list") {
      return (
        <PostListCardWrapper
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else {
      return (
        <Tremr.Post.Card
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
          cardHeightSet={options.cardHeightSet}
        />
      );
    }
  };

  // get named tabs that we want to show for specific context
  this.getTabs = function (context) {
    if (context.scope == "followers") {
      return ["latest", "alphabetical"];
    } else {
      return ["latest", "recommended", "trending"];
    }
  };

  // get default tab for specific context
  this.getDefaultTab = function (context) {
    return "latest";
  };

  // get named data source function for a specific context
  this.getDataSources = function (context) {
    // do in a function and use dynamically in datasources
    var getDataForContext = function (c) {
      var data = {
        target: c.target,
      };
      if (c.scope) {
        data.scope = c.scope;
      }
      if (c.sort) {
        data.sort = c.sort;
      }
      if (c.target == "tag") {
        data.id = c.tag;
      }
      return data;
    };

    if (
      context.target &&
      context.target == "tag" &&
      context.scope &&
      context.scope == "followers"
    ) {
      var sources = [];

      sources["latest"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.followStore.loadFollowers(
          page,
          followers,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["alphabetical"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.followStore.loadFollowers(
          page,
          followers,
          _.extend(
            {
              sort: "alphabetical",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (context.target && context.target == "tag") {
      var sources = [];

      sources["trending"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "trending",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["recommended"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "recommended",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["latest"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      return sources;
    } else {
      console.log("DataSource not implemented for:");
      console.dir(context);
    }
  };

  return store;
};
