require("./scss/list.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// simply display tags in comma delimited list
// Tremr.Tag.List = CreateReactClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        tags: PropTypes.array.isRequired,
        allowLink: PropTypes.bool,
        commas: PropTypes.bool,
        onClick: PropTypes.func
    },

    getDefaultProps: function() {
        return {
            allowLink: true,
            commas: true,
            onClick: _.noop
        }
    },

    // open a tag when clicked
    openTag: function(event) {

        event.preventDefault();
        event.stopPropagation();

        var tag = event.currentTarget.getAttribute('data-tag');

        Tremr.navigatePrimary({
            target: 'tag',
            tag: tag
        });

        this.props.onClick();
    },


    // display
    render: function() {

        // get the tags (if we want them)
        var tags = null;

        if (this.props.tags && this.props.tags.length > 0) {
            if (this.props.allowLink) {

                tags = <div className="tag-list">
                    {
                    _.flatten(_.map(this.props.tags, function(t, index) {
                        var element = <a key={t} href={'/tags/'+t} onClick={this.openTag} data-tag={t}>
                            <Tremr.Generic.Abbreviations uppercaseOnly={false} text={t} />
                        </a>;
                        if (this.props.commas && index > 0) {
                            return [<span>, </span>, element];
                        } else {
                            return element;
                        }
                    }.bind(this)))}
                </div>;

            } else {
                tags = <div className="tags">{this.props.tags.join(',')}</div>;
            }

        }


        // simple render
        return tags;
    }

});
