var Backbone = require('backbone');
var Config = require('config');
var User = require('./User');

// Tremr.Models.UsersLookup
module.exports = Backbone.Collection.extend({
    url: Config.apiurl+'/api/users/lookup',
    model: User

});
