require("./scss/genericoverviewpopup.scss");

let React = require("react");
let CreateReactClass = require("create-react-class");
let UserAwareMixin = require("../../mixins/userawaremixin");
let SvgIcon = require("../generic/svgicon");
let classNames = require("../../utils/classnames");
let Stars = require("../controls/stars");
let TremrUtils = require("../../utils/tremr_utils");
let Config = require("config");

// wraps a child component - click opens a pop-up with data about a specific post
module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  getDefaultProps: function () {
    return {
      moreInfoUrl:
        "http://support.tremr.com/article/41-trust-and-respect-badges",
    };
  },

  getInitialState: function () {
    let state = {
      open: false,
      isMounted: false,
    };
    return state;
  },

  handleLinkClick: function (event) {
    // otherwise can be cancelled by other click handlers in parents
    event.stopPropagation();
  },

  getUnsetColor: function () {
    if (
      this.props.highlightColor &&
      this.props.highlightColor.startsWith("#")
    ) {
      return TremrUtils.pSBC(0.9, this.props.highlightColor, false, true);
    }
    return "#eeeeee";
  },

  // update the pop-up position
  handleMove: function () {
    if (this.state.open && this.state.isMounted) {
      this.setState(this.getPosition());
    }
  },

  componentDidMount: function () {
    this.setState({
      isMounted: true,
    });
  },

  componentWillUnmount: function () {
    this.setState({
      isMounted: false,
    });
  },

  getPosition: function () {
    // find the position of this element in the viewport
    let domNode = ReactDOM.findDOMNode(this);
    let rect = domNode.getBoundingClientRect();
    return {
      left: rect.x,
      top: rect.y,
      width: rect.width,
      height: rect.height,
    };
  },

  toggleOpen: function (event) {
    event.preventDefault();
    event.stopPropagation();

    // only capture events when open!
    if (this.state.open) {
      window.removeEventListener("scroll", this.handleMove);
      window.removeEventListener("resize", this.handleMove);
    } else {
      TremrUtils.addPassiveEventListener("scroll", this.handleMove);
      TremrUtils.addPassiveEventListener("resize", this.handleMove);
    }

    if (this.state.isMounted) {
      let newState = this.getPosition();
      newState.open = !this.state.open;
      this.setState(newState);
    }
  },

  open: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.open) {
      if (this.state.isMounted) {
        TremrUtils.addPassiveEventListener("scroll", this.handleMove);
        TremrUtils.addPassiveEventListener("resize", this.handleMove);

        let newState = this.getPosition();
        newState.open = true;
        this.setState(newState);
      }
    }
  },

  close: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.open) {
      window.removeEventListener("scroll", this.handleMove);
      window.removeEventListener("resize", this.handleMove);

      if (this.state.isMounted) {
        let newState = this.getPosition();
        newState.open = false;
        this.setState(newState);
      }
    }
  },

  info: function (event) {
    event.preventDefault();
    event.stopPropagation();
    // console.log("DO INFO LINK!");
  },

  render: function () {
    // if user is not an admin just return null
    if (
      Config.reputation == "admin" &&
      (!this.state.user || this.state.user.get("admin") != "yes")
    ) {
      return null;
    }

    let unsetColor = this.getUnsetColor();

    // configure content based on message
    let badgeIconName = "warning";
    let popupContent = (
      <p>
        The Tremr community have flagged this post as suspicious or
        untrustworthy.
      </p>
    );
    let popupContent2 = (
      <p>
        <a
          href={this.props.moreInfoUrl}
          target="_blank"
          onClick={this.handleLinkClick}
        >
          LEARN MORE...
        </a>
      </p>
    );

    // needs to be provided with firebase data
    let overlay = null;
    let isLeft = this.props.align == "left";
    let popup = null;
    let popupPointer = null;
    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.badge &&
      this.props.liveDataPoints.badge.data &&
      this.props.liveDataPoints.badge.data.includes("low")
    ) {
      if (this.state.open) {
        let styles = {
          top: this.state.top,
        };

        // if the left will overlap the edge of the viewport then left align
        let leftPosition = this.state.left + this.state.width - 250;
        leftPosition = leftPosition + 16; // offset a bit

        // if the right overlaps the right edge then just set to zero
        if (leftPosition + 265 > window.innerWidth) {
          styles.right = 0;
        } else {
          styles.left = leftPosition;
        }

        if (this.props.align == "left") {
          leftPosition = this.state.left;
          leftPosition = leftPosition - 18; // offset a bit

          // if we overlap the right edge then set back
          if (leftPosition + 265 > window.innerWidth) {
            // can't do this on the wall because we can't overlap
            // an earlier card (earlier cards can already expand over
            // following cards - zIndex is used to allow this)
            // leftPosition = window.innerWidth - 265;

            // instead restrict the width
            styles.maxWidth = window.innerWidth - (leftPosition + 8);
          }

          // if the left overlaps the right edge then just set to zero
          if (leftPosition < 0) {
            styles.left = 0;
          } else {
            styles.left = leftPosition;
          }
        }

        if (this.props.liveDataPoints.badge.data.includes("trust")) {
          popupContent = (
            <p>
              The Tremr community have flagged this post as suspicious or
              untrustworthy.
            </p>
          );
          popupContent2 = (
            <p>
              <a
                href={this.props.moreInfoUrl}
                target="_blank"
                onClick={this.handleLinkClick}
              >
                LEARN MORE...
              </a>
            </p>
          );
        } else if (this.props.liveDataPoints.badge.data.includes("respect")) {
          popupContent = (
            <p>The Tremr community have flagged this post as disrespectful.</p>
          );
          popupContent2 = (
            <p>
              <a
                href={this.props.moreInfoUrl}
                target="_blank"
                onClick={this.handleLinkClick}
              >
                LEARN MORE...
              </a>
            </p>
          );
        }

        popup = (
          <div
            key="popup"
            className="reputation-generic-overview-popup"
            style={styles}
          >
            <h4>
              <span>{this.props.liveDataPoints.badge.data}</span>
              <SvgIcon icon={badgeIconName} onClick={this.info} />
            </h4>
            {popupContent}
            {popupContent2}
            <SvgIcon icon="speechpointer" />
          </div>
        );

        overlay = <div className="overlay" onClick={this.toggleOpen} />;
      } // is open?
    } else {
      return null;
    }

    let classes = classNames({
      "reputation-generic-overview": true,
      open: this.state.open,
      left: isLeft,
    });

    const badge = (
      <div className="reputation-post-warning-badge">
        <div>{this.props.liveDataPoints.badge.data}</div>
        <SvgIcon icon={badgeIconName} />
      </div>
    );

    if (TremrUtils.hasMouse) {
      return (
        <div
          key="hasMouse"
          className={classes}
          onMouseEnter={this.open}
          onMouseLeave={this.close}
        >
          {popup}
          {badge}
        </div>
      );
    } else {
      return (
        <div key="noMouse" className={classes} onClick={this.toggleOpen}>
          {overlay}
          {popup}
          {badge}
        </div>
      );
    }
  },
});
