var TremrTracking = require("../../utils/tracking");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var UnseenCount = require("../generic/unseencount");
var Config = require("config");

// Tremr.User.Menu = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  viewFeed: function (event) {
    if (event.metaKey) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:open:personalfeed", event);
  },

  viewBookmarks: function (event) {
    if (event.metaKey) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:open:bookmarks", event);
  },

  viewInbox: function (event) {
    if (event.metaKey) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:open:inbox", event);
  },

  setUserColor: function (color) {
    if (color) {
      // document.documentElement.style.setProperty('--highlight-color', color);
      Tremr.setHighlightColor(color);
    }
  },

  replaceStandardColor: function () {
    // document.documentElement.style.setProperty('--highlight-color', Config.colors.highlight);
    Tremr.setHighlightColor(Config.colors.highlight);
  },

  componentDidMount: function () {
    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // listen for sign-in/out and update user in our session
    this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function (
      user
    ) {
      this.setUserColor(user.get("highlight_color"));
    });

    this.listenTo(Tremr.dispatcher, "tremr:user:signout:success", function (
      user
    ) {
      this.replaceStandardColor();
    });

    if (this.state.user) {
      this.setUserColor(this.state.user.get("highlight_color"));
    }
  },
  componentWillUnmount: function () {
    if (this.stopListening) {
      this.stopListening();
    }

    this.replaceStandardColor();
  },

  render: function () {
    if (this.state.user) {
      let inboxTotalKey =
        "notifications/users/{user}/response-notifications-count";
      let inboxSeenlKey =
        "notifications/users/{user}/response-notifications-seen";

      return (
        <div className="user-menu">
          <a
            title="Home"
            href="/"
            onClick={this.viewFeed}
            className={this.props.active == "homepage" ? "active" : ""}
          >
            <Tremr.Generic.SvgIcon icon="home" />
          </a>
          <a
            title="Inbox"
            href="/inbox"
            onClick={this.viewInbox}
            className={this.props.active == "inbox" ? "active" : ""}
          >
            <Tremr.Generic.SvgIcon icon="inbox" />
            <UnseenCount total_path={inboxTotalKey} seen_path={inboxSeenlKey} />
          </a>
          <a
            title="Bookmarks"
            href="/bookmarks"
            onClick={this.viewBookmarks}
            className={this.props.active == "bookmarks" ? "active" : ""}
          >
            <Tremr.Generic.SvgIcon icon="bookmark-solid" />
          </a>
        </div>
      );
    } else {
      return null;
    }
  },
});
