var CreateReactClass = require('create-react-class');

// a link anchor tag that can have an active class, intended for tabs
// Tremr.Generic.Tab = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	handleClick: function(event) {

		// if the meta key is pressed, ignore
        if (this.props.href != undefined && event.metaKey) { return; }

		if (this.props.handleClick) {
			event.preventDefault();
			event.stopPropagation();
            let data = null;
            if (this.props.data) {
                data = this.props.data;
            }
			this.props.handleClick(event, data);
		} else if (this.props.href == undefined) {
			event.preventDefault();
			event.stopPropagation();
		}
	},

	render: function() {

		var classNames = require('../../utils/classnames');
        var classes = classNames({
			'active': this.props.active
		});

		if (this.props.href) {
			let href = this.props.href;
            return (
    			<a title={this.props.tip} onClick={this.handleClick} className={classes} data-value={this.props.value} href={this.props.href}>{this.props.text}</a>
    		);
		} else {
            return (
    			<a title={this.props.tip} onClick={this.handleClick} className={classes} data-value={this.props.value}>{this.props.text}</a>
    		);
        }
	}
});
