require("./scss/richtext.scss");

var classNames = require('../../utils/classnames');
var TremrCopy = require('../../plugins/copytext');
var alertify = require('alertify');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// container for rich text field including uploads etc.
// Tremr.Editor.Richtext
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      readOnly: PropTypes.bool,
      initialBlocks: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      margins: PropTypes.number.isRequired
  },

  // store current selection in a local var - definately not state!
  currentSelection: false,

  // store undo and redo stacks
  undoStack: [],
  redoStack: [],

  getDefaultProps: function() {

    return {
      readOnly: true,
      initialBlocks: false,
      margins: 0
    }
  },

  // start with a single rich text block
  getInitialState: function() {

    // iterates json data and adds each block to an array ready for one big state update
    var blocks = [];
    if (this.props.initialBlocks) {
      _.each(this.props.initialBlocks, function(attributes) {
        blocks.push(this.addBlock(attributes.type, 'none', attributes, true));
      }.bind(this));
    } else {
      blocks = [new Tremr.Editor.TextBlock(Tremr.Utils.uniqueId('block'), 'text')];
    }

    return {
      blocks: blocks,
      showFormat: false,
      width: false,
      focus: false,
      contentEffected: false
    }
  },

  // updates state with params but first logs current blocks and selection in undo queue
  setBlockState: function(newState, timeDependentUndo) {

    if (typeof timeDependentUndo == "undefined") {
      timeDependentUndo = false;
    }

    // console.log("setBlockState");
    // console.dir(this.currentSelection);

    // check if we have any 'in progress' blocks and
    // don't store that state
    var inProgress = false;
    _.each(this.state.blocks, function(block) {
      inProgress = inProgress || (block.get('inProgress') || block.get('progress') == 0);
      inProgress = inProgress || (block.get('embed') == false);
    });

    if (!inProgress) {

      // check that at least 5s have passed between calls
      var sinceLastUndo = 5000;
      if (timeDependentUndo == true && this.undoStack.length > 0) {
        sinceLastUndo = Date.now() - _.last(this.undoStack).timestamp;
      }

      if (sinceLastUndo >= 5000) {
        this.undoStack.push({
          blocks: JSON.stringify(this.getBlocksJson()),
          selection: _.clone(this.currentSelection),
          timestamp: Date.now()
        });
      }
    }

    this.setState(newState);
  },

  // update content with state from undo/redo stack
  applyOldState: function(prevState, currentStateDest) {

    // move current state to undo/redo stack
    // check if we have any 'in progress' blocks and
    // don't store that state
    var inProgress = false;
    _.each(this.state.blocks, function(block) {
      inProgress = inProgress || block.get('inProgress');
    });

    if (!inProgress) {
      currentStateDest.push({
        blocks: JSON.stringify(this.getBlocksJson()),
        selection: _.clone(this.currentSelection)
      });
    }

    // get the index of the block the cursor is currently in (before we update state)
    var cursorBlockIndex = 0;
    var matchedCursorBlock = false
    _.each(this.state.blocks, function(block) {
      if (!matchedCursorBlock) {
        if (block.get('identifier') == prevState.selection.startBlockId) {
          matchedCursorBlock = true;
        }
        cursorBlockIndex = cursorBlockIndex + 1;
      }
    });

    // apply old state
    var newBlocks = JSON.parse(prevState.blocks);
    this.setBlocksJson(newBlocks);

    // work out the new selection, if we have one (we should!)
    if (prevState.selection) {

      // check if the new selected block is still present
      var startSelectedBlock = _.filter(newBlocks, function(block) {
        return (block.identifier == prevState.selection.startBlockId);
      });

      var newSelection = _.clone(prevState.selection);

      if (startSelectedBlock.length > 0) {

        // check the selection isn't beyond the end of the block
        if (startSelectedBlock[0].plainText.length < newSelection.startOffset) {
          newSelection.startOffset = startSelectedBlock[0].plainText.length;
        }

      } else {

        // select the end of the block in front of the selection
        if (cursorBlockIndex == 0) {
          newSelection = {
            startBlockId: newBlocks[cursorBlockIndex].identifier,
            startOffset: newBlocks[cursorBlockIndex].plainText.length
          }
        } else if (cursorBlockIndex > newBlocks.length) {
          newSelection = {
            startBlockId: newBlocks[newBlocks.length-1].identifier,
            startOffset: newBlocks[newBlocks.length-1].plainText.length
          }
        } else {
          newSelection = {
            startBlockId: newBlocks[cursorBlockIndex-1].identifier,
            startOffset: newBlocks[cursorBlockIndex-1].plainText.length
          }
        }
      } // (startSelectedBlock.length > 0)

      // set the selection - once we have updated
      _.defer(function() {
        try {
          this.setCurrentSelection(newSelection);

        } catch (e) {
          console.log("Could not set selection:");
          console.dir(e);
        }
      }.bind(this));

    } // (prevState.selection)
  },

  // if we have an undo stack pop the top element back into state
  handleUndo: function() {

    if (this.undoStack && this.undoStack.length > 0) {

      var prevState = this.undoStack.pop();
      this.applyOldState(prevState, this.redoStack);
    }
  },

  // if we have a redo stack pop the top element back into state
  handleRedo: function() {

    if (this.redoStack && this.redoStack.length > 0) {

      var prevState = this.redoStack.pop();
      this.applyOldState(prevState, this.undoStack);
    }
  },

  // updates block based on params
  updateBlock: function(identifier, params) {

    var blocksArray = this.state.blocks.slice()
    blocksArray = this.mapBlocks(blocksArray, function(block) {
      if (block.get('identifier') == identifier) {
        _.each(_.keys(params), function(key) { // THIS ISN'T WORKING
          block.set(key, params[key]);
        });
      }
      return block;
    });

    this.setBlockState({
      blocks: blocksArray,
      contentEffected: true
    });
  },

  // get the content blocks as json
  getBlocksJson: function() {
    return _.map(this.state.blocks, function(block) {
      return block._attributes;
    });
  },

  // update all content from JSON
  setBlocksJson: function(data) {

    // iterates json data and adds each block to an array ready for one big state update
    var blocks = [];
    _.each(data, function(attributes) {
      blocks.push(this.addBlock(attributes.type, 'none', attributes, true));
    }.bind(this));

    // don't use setBlockState here because we never want to undo
    this.setState({
      blocks: blocks
    });
  },

  // adds a new block of specified type after the specified block
  addBlock: function(blockType, identifier, attributes, suppressUpdate) {

    // use identifier to position the control in the array of blocks

    // work out where to add our new block
    var addIndex = 0;
    if (identifier != 'none') {

      // iterate blocks until we find the identified and insert afterwards
      addIndex = _.findIndex(this.state.blocks, function(block) {
        return (block.get('identifier') == identifier);
      });
      if (addIndex > -1) { addIndex++; }
    }

    if (addIndex > -1) {

      var id = Tremr.Utils.uniqueId('block');

      // create a new block depending on type
      var newBlock = false;
      if (blockType == 'upload') {
        newBlock = new Tremr.Editor.UploadBlock(id, blockType);
      } else if (blockType == 'embed') {
        newBlock = new Tremr.Editor.EmbedBlock(id, blockType);
      } else if (blockType == 'twitter') {
        newBlock = new Tremr.Editor.TwitterBlock(id, blockType);
      } else {
        // default to text block
        newBlock = new Tremr.Editor.TextBlock(id, blockType);
      }

      // set the attributes if we have any
      if (attributes) {
        newBlock._attributes = _.extend(newBlock._attributes, attributes); // horrible to use private member but why create wrapper?
      }

      // use splice to inject new block in
      var blocksArray = [];
      if (!suppressUpdate) {
        var blocksArray = this.state.blocks.slice();
      }
      blocksArray.splice(addIndex, 0, newBlock);

      // select the start of the new block
      if (!this.currentSelection) {
        this.currentSelection = {};
      }
      this.currentSelection.startBlockId = id;
      this.currentSelection.startOffset = 0;
      delete this.currentSelection['endBlockId'];
      delete this.currentSelection['endOffset'];

      if (!suppressUpdate) {
        this.setBlockState({
          blocks: blocksArray,
          contentEffected: true
        });
      }

      return newBlock;
    }
  },

  blockControlIdentifier: function(identifier) {
    var id ='add-first';
    if (identifier != 'none') {
      id = 'add-after-'+identifier;
    }
    return id;
  },

  // when an add block is open, close any others
  onAddBlockOpen: function(identifier) {

    // get a list of block refs to close
    var addBlockRefs = ['none'];
    _.each(this.state.blocks, function(value, index) {
      var id = this.blockControlIdentifier(value.get('identifier'));
      addBlockRefs.push(id);
    }.bind(this));

    // remove the one that opened, causing this
    addBlockRefs = _.reject(addBlockRefs, function(id) {
      return (id == identifier);
    });

    // iterate and close them
    _.each(addBlockRefs, function(ref) {
      if (this.refs[ref]) {
        this.refs[ref].close();
      }
    }.bind(this));

  },

  createAddBlockControl: function(identifier) {
    var id = this.blockControlIdentifier(identifier);
    return {
      identifier: id,
      control: <Tremr.Editor.Addblockcontrol ref={id} key={id} identifier={id} onOpen={this.onAddBlockOpen} blockIdentifier={identifier} addBlock={this.addBlock} removeBlock={this.removeBlock} updateBlock={this.updateBlock} />
    };
  },

  // util for creating a block array of cloned blocks and executing
  // a callback for each one (so like map, with each item cloned)
  mapBlocks: function(array, callback) {
    return _.map(_.map(array, function(block) {
      return block.clone(true);
    }), callback);
  },

  // watch for changes and update our blocks to match content
  // then render - text shouldn't change so we can just put the
  // selection back
  handleInput: function(event) {

    // read the current selection
    this.currentSelection = this.getCurrentSelection();

    // find which block we are updating
    var blockId = this.currentSelection.startBlockId;

    if (this.refs[blockId]) {
      var blockNode = ReactDOM.findDOMNode(this.refs[blockId]);
    } else {
      var blockNode = {
        childNodes: []
      };
    }

    // update the component
    var updatedBlock = false;
    var blocksArray = this.state.blocks.slice();
    blocksArray = this.mapBlocks(blocksArray, function(element) {
      if (element.get('identifier') == blockId) {

        // parse the nodes into plain text and format rules - let the block do this!
        element.updateFromNodes(blockNode.childNodes);

        updatedBlock = element;
      }

      return element;
    });

    // check the character immediately before the cursor, only update state if
    // this was a non-word/number
    var nonWord = false;
    var nonWordChars = ' !.?<>-=|\/#$¢£∞^&*_'.split('')
    nonWordChars.push('&nbsp;');
    if (updatedBlock && updatedBlock.length() == 0) {

      nonWord = true;

    } else if (updatedBlock) {
      var char = updatedBlock.get('arrayText')[this.currentSelection.startOffset-1];
      if (_.contains(nonWordChars, char)) {
        nonWord = true;
      }
    }
    // always add to undo when no undo content yet
    if (this.undoStack.length == 0) {
      nonWord = true;
    }

    // update state
    if (nonWord) {
      this.setBlockState({
        blocks: blocksArray,
        contentEffected: true
      }, true);
    } else {
      this.setState({
        blocks: blocksArray,
        contentEffected: true
      });
    }

    // notify parent that we have an update
    if (this.props.onChange) {
      this.props.onChange(this.getBlocksJson());
    }
  },

  // fire callbacks for each block indicating if it is the first, last, middle or not in the selection,
  // return the blocks array - so it can be updated in state manually by caller
  updateBlocks: function(callbacks) {

    // get the blocks
    var blocksArray = this.state.blocks.slice()

    var foundFirst = false;
    var foundLast = false;

    // iterate blocks calling callbacks
    blocksArray = _.flatten(this.mapBlocks(blocksArray, function(block) {

      var isFirst = false;
      var isLast = false;

      // see if this is the last block
      if (block.get('identifier') == this.currentSelection.endBlockId) {
        foundLast = true;
        isLast = true;
      }

      // see if we have the first block
      if (block.get('identifier') == this.currentSelection.startBlockId) {
        foundFirst = true;
        isFirst = true;
      }

      var isMiddle = foundFirst && !foundLast && !isFirst && this.currentSelection.endBlockId != undefined; // middle blocks

      if (!foundFirst) {
        // console.log("callback: beforeFirst");
        return _.isFunction(callbacks.beforeFirst) ? callbacks.beforeFirst(block) : block;
      } else if (isFirst && (isLast || !this.currentSelection.endBlockId)) {
        // console.log("callback: firstAndLast");
        return _.isFunction(callbacks.firstAndLast) ? callbacks.firstAndLast(block) : block;
      } else if (isFirst) {
        // console.log("callback: first");
        return _.isFunction(callbacks.first) ? callbacks.first(block) : block;
      } else if (isMiddle) {
        // console.log("callback: middle");
        return _.isFunction(callbacks.middle) ? callbacks.middle(block) : block;
      } else if (isLast) {
        // console.log("callback: last");
        return _.isFunction(callbacks.last) ? callbacks.last(block) : block;
      } else if (foundLast) {
        // console.log("callback: afterLast");
        return _.isFunction(callbacks.afterLast) ? callbacks.afterLast(block) : block;
      } else {
        // console.log("callback: afterLast");
        return _.isFunction(callbacks.afterLast) ? callbacks.afterLast(block) : block;
      }

    }.bind(this)), true);

    // notify parent that we have an update
    if (this.props.onChange) {
      this.props.onChange(this.getBlocksJson());
    }

    return blocksArray;
  },


  // handle a delete action
  // remove anything selected and if at end of block combine block with next.
  // on no selection callback and allow caller to adjust blocks (to allow diff between backspace/delete behaviour)
  handleDeleteSelection: function(noSelectionCallback) {

    // set which block to select in callback
    var selectBlockId = false;
    var selectOffset = 0;
    var firstId = false;

    // func that can be set in the callbacks below to make changes outside of the loop
    var updateAfter = function(blocks) { return blocks; };

    // flag if we have a range we want to delete some blocks
    var deleteMiddle = false;
    if (this.currentSelection.endBlockId) {
      deleteMiddle = true;

      // update with callbacks - each one expects an array of blocks to be returned (or not!)
      var updatedBlocks = this.updateBlocks({

        // first - remove selection
        first: function(block) {
          block.removeCharactersRight(this.currentSelection.startOffset);
          selectBlockId = block.get('identifier');
          selectOffset = block.length();
          firstId = block.get('identifier');
          return block;
        }.bind(this),

        // first AND last - just remove selection, if 1st pos - in which case join with prev block
        firstAndLast: function(block) {
          if (this.currentSelection.endOffset) {
            block.removeCharacters(this.currentSelection.startOffset, this.currentSelection.endOffset);
            selectOffset = this.currentSelection.startOffset;
          } else {
            block.removeCharacters(this.currentSelection.startOffset-1, this.currentSelection.startOffset);
            selectOffset = this.currentSelection.startOffset-1;
          }
          selectBlockId = block.get('identifier');
          firstId = block.get('identifier');
          return block;
        }.bind(this),

        // middle - delete
        middle: function(block) {
          return [];
        }.bind(this),

        // last one - join to first block and remove selection
        last: function(block) {
          block.removeCharactersLeft(this.currentSelection.endOffset);

          // merge into first
          updateAfter = function(blocks) {

            // add content to first
            if (firstId) {
              _.each(blocks, function(b) {
                if (b.get('identifier') == firstId) {
                  b.append(block);
                }
              });
            }

            return blocks;
          }

          return [];

        }.bind(this)

      });

    } else {

      var selections = {
        selectBlockId: selectBlockId,
        selectOffset: selectOffset
      };
      var updatedBlocks = noSelectionCallback(this.state.blocks.slice(), selections);
      selectBlockId = selections.selectBlockId;
      selectOffset = selections.selectOffset;
    }

    // call function that might have been set-up in above callbacks
    updatedBlocks = updateAfter(updatedBlocks);

    // set the selection to the start of the new block
    if (selectBlockId) {
      this.currentSelection.startBlockId = selectBlockId;
      this.currentSelection.startOffset = selectOffset;
      delete this.currentSelection['endBlockId'];
      delete this.currentSelection['endOffset'];
    }

    // update the blocks
    this.setBlockState({
      blocks: updatedBlocks
    });
  },

  // remove selection and when at end of block merge with next block
  handleDelete: function() {

    this.handleDeleteSelection(function(updatedBlocks, selections) {

      if (this.currentSelection.startOffset >= this.getBlock(this.currentSelection.startBlockId).length()) {

        // do in reverse so we can remove what would be next (in forward)
        var origLength = updatedBlocks.length;
        for (var index=origLength-1; index >= 0; index--) {

          if (updatedBlocks[index].get('identifier') == this.currentSelection.startBlockId) {
            if (index+1 < origLength) {
              var nextBlock = updatedBlocks[index+1];
              if (nextBlock.get('type') == 'text') {
                selections.selectOffset = updatedBlocks[index].length();
                updatedBlocks[index].append(nextBlock);
                selections.selectBlockId = this.currentSelection.startBlockId;
                updatedBlocks.splice(index+1, 1);
              }
            }
          }
        }

      }

      return updatedBlocks;
    }.bind(this));

    // notify parent that we have an update
    if (this.props.onChange) {
      this.props.onChange(this.getBlocksJson());
    }
  },

  // handle a backspace action
  // remove anything selected and if at start of block combine block with previous
  handleBackspace: function() {

    this.handleDeleteSelection(function(updatedBlocks, selections) {

      if (this.currentSelection.startOffset == 0) {

        // itrate in reverse
        var origLength = updatedBlocks.length;
        for (var index=origLength-1; index >= 0; index--) {

          if (updatedBlocks[index].get('identifier') == this.currentSelection.startBlockId) {
            if (index > 0) {
              var prevBlock = updatedBlocks[index-1];
              if (prevBlock.get('type') == 'text') {
                selections.selectOffset = prevBlock.length();
                prevBlock.append(updatedBlocks[index]);
                selections.selectBlockId = prevBlock.get('identifier');
                updatedBlocks.splice(index, 1);
              }
            }
          }
        }

      }

      return updatedBlocks;
    }.bind(this));

    // notify parent that we have an update
    if (this.props.onChange) {
      this.props.onChange(this.getBlocksJson());
    }
  },

  // handle an Enter action
  // on Enter, create a new block after the start of the selection
  handleEnter: function() {

    // flag if we have a range and therefore want to delete some blocks
    var deleteMiddle = false;
    if (this.currentSelection.endBlockId) {
      deleteMiddle = true;
    }

    // set which block to select in callback
    var selectBlockId = false;

    // update with callbacks - each one expects an array of blocks to be returned (or not!)
    var updatedBlocks = this.updateBlocks({

      // first - remove selection
      first: function(block) {
        block.removeCharactersRight(this.currentSelection.startOffset);
        return block;
      }.bind(this),

      // first AND last - split around selection (or cursor)
      firstAndLast: function(block) {
        var newBlocks = [];
        if (this.currentSelection.endBlockId) {
          newBlocks = block.splitAt(this.currentSelection.startOffset, this.currentSelection.endOffset);
        } else {
          newBlocks = block.splitAt(this.currentSelection.startOffset);
        }
        selectBlockId = newBlocks[1].get('identifier');
        return newBlocks;
      }.bind(this),

      // middle - delete
      middle: function(block) {
        return [];
      }.bind(this),

      // last one - in a selection, simply remove the selection
      last: function(block) {
        block.removeCharactersLeft(this.currentSelection.endOffset);
        selectBlockId = block.get('identifier');
        return block;
      }.bind(this)

    });


    // set the selection to the start of the new block
    if (selectBlockId) {
      this.currentSelection.startBlockId = selectBlockId;
      this.currentSelection.startOffset = 0;
      delete this.currentSelection['endBlockId'];
      delete this.currentSelection['endOffset'];
    }

    // update the blocks
    this.setBlockState({
      blocks: updatedBlocks,
      contentEffected: true
    });

    // notify parent that we have an update
    if (this.props.onChange) {
      this.props.onChange(this.getBlocksJson());
    }
  },

  // toggle blocks in selection to/from the specified tag
  toggleTag: function(tag, range) {

    // are we turning on/off
    var containsTag = this.containsTag(tag, range);

    var setTag = function(block) {

      if (containsTag && block.get('tag') == tag) {
        block.set('tag', 'p');
      } else if (!containsTag) {
        block.set('tag', tag);
      }
      return block;
    }
    // update with callbacks - each one expects an array of blocks to be returned (or not!)
    var updatedBlocks = this.updateBlocks({
      first: setTag,
      firstAndLast: setTag,
      middle: setTag,
      last: setTag
    });

    // update state with new blocks
    this.setBlockState({
      blocks: updatedBlocks
    });

  },

  // generic func to set or unset a format
  setFormat: function(format, range, add, meta) {

    // util for working out if what to format when part of block is selected
    var formatRange = function(block, format) {

      // apply depending on the offsets in the selection
      var start = range.startOffset;
      var end = range.endOffset;
      if (block.get('identifier') == range.startBlockId && block.get('identifier') == range.endBlockId) {
        // first and last - do nothing
      } else if (block.get('identifier') == range.startBlockId) {
        // first block
        end = block.length();
      } else if (block.get('identifier') == range.endBlockId) {
        // it's the last block
        start = 0;
      }

      if (add) {
        block.setFormat(format, start, end, meta);
      } else {
        block.unsetFormat(format, start, end);
      }

      return block;
    };

    // update with callbacks - each one expects an array of blocks to be returned (or not!)
    var updatedBlocks = this.updateBlocks({

      // first - format selection
      first: function(block) {

        return formatRange(block, format, range);

      }.bind(this),

      // first AND last - format selection
      firstAndLast: function(block) {

        return formatRange(block, format, range);

      }.bind(this),

      // middle - format whole block
      middle: function(block) {

        if (add) {
          block.setFormat(format, false, false, meta);
        } else {
          block.unsetFormat(format);
        }
        return block;

      }.bind(this),

      // last one - format selection
      last: function(block) {
        return formatRange(block, format, range);

      }.bind(this)
    });

    // update state with new blocks
    this.setBlockState({
      blocks: updatedBlocks
    });

  },

  // apply a format to blocks between two block id's with the given offsets (might be just 1, or more blocks)
  toggleFormat: function(format, range, meta) {

    if (meta === undefined) { meta = {}; }

    // if we have any content that matches the format in the range then unset
    var containsFormat = this.containsFormat(format, range);
    this.setFormat(format, range, !containsFormat, meta);
  },

  // create a range for our selection
  createRangeForSelection: function(selection) {

    // get the start node
    var startBlock = this.refs[selection.startBlockId];

    // if the start block hasn't been rendered yet, delay
    // this call until it is
    if (!startBlock) {
      _.delay(this.createRangeForSelection, 200, selection);
      return;
    }

    var startNode = ReactDOM.findDOMNode(startBlock);
    var startNodeData = this.getBlock(selection.startBlockId);
    var startSel = startNodeData.findOffsetFromSubnode(startNode, selection.startOffset);
    var node = false;
    var nodeOffset = 0;

    if (!startSel.found) {
      // select from the block
      startSel.node = startNode;
    }

    // create selection using range
    var range = document.createRange();
    range.setStart(startSel.node, startSel.offset);

    // if we have a selection (rather than just the cursor) set the end differently
    if (selection.endBlockId) {

      var endNode = ReactDOM.findDOMNode(this.refs[selection.endBlockId]);
      var endNodeData = this.getBlock(selection.startBlockId);
      var endSel = endNodeData.findOffsetFromSubnode(endNode, selection.endOffset);
      if (endSel.node) {
        range.setEnd(endSel.node, endSel.offset);
      }

    } else {
      range.setEnd(startSel.node, startSel.offset);
    }

    return {
      range: range,
      startNode: startNode,
      startBlock: startBlock
    };
  },

  // resets a selection we have read back to the position
  setCurrentSelection: function(selection) {

      if (selection) {
        var rangeForSelection = this.createRangeForSelection(selection);
        if (rangeForSelection && rangeForSelection.range) {
          // select
          var selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(rangeForSelection.range);

          // focus depends on block type!
          rangeForSelection.startBlock.focus();
        }

      }
  },

  // converts current selection into block and offset for carat,
  // and for range block and offset for start and end.
  // relies on the block to calculate the offset
  getCurrentSelection: function(start) {

    var selection = window.getSelection();

    var sel = { };

    var anchorBlock = $(selection.anchorNode).closest('.block').get(0);

    // if we don't have an anchor block then we have selected outside of a block (ff bug!)
    // so just return the first blocks
    if (!anchorBlock) {
        sel['startBlockId'] = this.state.blocks[0].get('identifier');
        sel['startOffset'] = 0;
        sel['invalid'] = true;
        return sel;
    }

    var anchorBlockId = anchorBlock.getAttribute('data-id');

    var startDataBlock = this.getBlock(anchorBlockId);

    sel['startBlockId'] = anchorBlockId;
    sel['startOffset'] = selection.anchorOffset;
    if (startDataBlock && startDataBlock.getOffsetInBlock) {
      sel['startOffset'] = sel['startOffset'] + startDataBlock.getOffsetInBlock(selection.anchorNode, true);
    }

    if (!selection.isCollapsed) {

      var endBlock = $(selection.focusNode).closest('.block').get(0);
      var endBlockId = endBlock.getAttribute('data-id');

      var endDataBlock = this.getBlock(endBlockId);

      sel['endBlockId'] = endBlockId;
      sel['endOffset'] = selection.focusOffset;
      if (endDataBlock && endDataBlock.getOffsetInBlock) {
        sel['endOffset'] = sel['endOffset'] + endDataBlock.getOffsetInBlock(selection.focusNode, true);
      }

      // check if we are forward/backward selecting
      var backwards = false;
      if (sel['startBlockId'] == sel['endBlockId']) {

        if (sel['startOffset'] > sel['endOffset']) {
          backwards = true;
        }

      } else {
        // if the endBlock is higher up the source code then switch the start/end around
        var blocks = $(selection.focusNode).closest('.content').find('.block');
        var firstBlock = false;
        _.each(blocks, function(b) {
          if (!firstBlock && (b.getAttribute('data-id') == sel['startBlockId'] || b.getAttribute('data-id') == sel['endBlockId'])) {
            firstBlock = b;
          }
        });
        if (firstBlock && firstBlock.getAttribute('data-id') == sel['endBlockId']) {
          backwards = true;
        }
      }

      if (backwards) {
        var anchorOffset = sel['startOffset'];
        sel['startBlockId'] = sel['endBlockId'];
        sel['startOffset'] = sel['endOffset'];
        sel['endBlockId'] = anchorBlockId;
        sel['endOffset'] = anchorOffset;
      }
    }

    return sel;
  },

  // util for getting block from Id
  getBlock: function(id) {

    return _.find(this.state.blocks, function(block) {
      return (block.get('identifier') == id);
    });
  },

  // user has typed a 'normal' printable key but has a selection
  // that crosses blocks, thus we must join blocks
  handleInsertKeyAcrossBlocks: function(keyCode, shift) {

    var newCharacter = String.fromCharCode(keyCode);
    if (shift) {
      newCharacter = newCharacter.toUpperCase();
    } else {
      newCharacter = newCharacter.toLowerCase();
    }

    // set which block to select in callback
    var selectBlockId = false;
    var selectOffset = 0;
    var firstBlock = false;

    // update with callbacks - each one expects an array of blocks to be returned (or not!)
    var updatedBlocks = this.updateBlocks({

      // first - delete (but remember)
      first: function(block) {
        firstBlock = block;
        return [];
      }.bind(this),

      // middle - delete
      middle: function(block) {
        return [];
      }.bind(this),

      // last - replace with first, with new character added and last appended
      last: function(block) {
        if (firstBlock) {
          block.removeCharactersLeft(this.currentSelection.endOffset);
          firstBlock.removeCharactersRight(this.currentSelection.startOffset);
          firstBlock.set('plainText', firstBlock.get('plainText') + newCharacter);
          selectBlockId = firstBlock.get('identifier');
          selectOffset = firstBlock.length();
          firstBlock.append(block);
          return firstBlock;
        }
        return block;
      }.bind(this)

    });


    // set the selection to the start of the new block
    if (selectBlockId) {
      this.currentSelection.startBlockId = selectBlockId;
      this.currentSelection.startOffset = selectOffset;
      delete this.currentSelection['endBlockId'];
      delete this.currentSelection['endOffset'];
    }

    // update the blocks
    this.setBlockState({
      blocks: updatedBlocks
    });

    // notify parent that we have an update
    if (this.props.onChange) {
      this.props.onChange(this.getBlocksJson());
    }
  },

  // get the content selected in the richtext
  getContentForSelection: function() {

    var html = "";
    if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
            var container = document.createElement("div");
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents());
            }
            html = container.innerHTML;
        }
    } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
            html = document.selection.createRange().htmlText;
        }
    }
    return html;
  },

  // normal keypress - when multiple blocks selected
  // Delete, Backspace - to remove blocks
  // Typing when we have a selection - to remove blocks
  // Enter - to add block
  // Shortcuts Ctrl/Cmd-B - For Bold etc.
  // Also: always read the current selection into our domain specific form of block and offset
  handleKeyDown: function(event) {

    // console.log("KeyDown: "+event.keyCode+" "+event.key);

    // always get the current selection
    var selection = window.getSelection();
    this.currentSelection = this.getCurrentSelection();

    // handle REDO manually
    if ((event.keyCode == 89 && event.metaKey) ||
        (event.keyCode == 90 && event.metaKey && event.shiftKey)) {

      event.preventDefault();
      event.stopPropagation();

      this.handleRedo();

      return;
    }

    // handle UNDO manually
    if (event.keyCode == 90 && event.metaKey) {

      event.preventDefault();
      event.stopPropagation();

      this.handleUndo();

      return;
    }

    // handle CUT manually
    if (event.keyCode == 88 && event.metaKey) {

      // let it happen automatically if we are in one block
      if (this.currentSelection.startBlockId !== this.currentSelection.endBlockId) {

        if (TremrCopy.copy(this.getContentForSelection(), window, document)) {

          // remove the content
          this.handleDelete();

        } else {
          alertify.error("Sorry, we cannot support 'cut' in you're borwser, plese use "+Tremr.Utils.getMetaKeyDesc()+"-C and Delete.");
        }
        event.preventDefault();
        event.stopPropagation();

        return;
      }
    }

    // check we are in a block - if not, move selection to last of first block
    if (this.currentSelection.invalid) {
      // event.preventDefault();
      this.currentSelection.invalid = true;
      this.setCurrentSelection(this.currentSelection);
    }

    // watch for backspace - remove selected and join block with prev if we are start of block
    if (event.key == 'Backspace') {

      // SAFARI HACK: if the offset is 1 we need to do this manually as well
      var offsetOneAndSafari = selection.isCollapsed && (this.currentSelection.startOffset == 1) && Tremr.Utils.checkForSafari();
      if (offsetOneAndSafari) {
        // select the character and use our own logic
        this.currentSelection.endBlockId = this.currentSelection.startBlockId;
        this.currentSelection.startOffset = 0;
        this.currentSelection.endOffset = 1;
      }

      // only do this if we at the start of a block or have a selection - otherwise no need
      if (offsetOneAndSafari || (!selection.isCollapsed || this.currentSelection.startOffset == 0)) {
        event.preventDefault();
        event.stopPropagation();
        this.handleBackspace();
      }
      return;
    }

    // watch for delete - remove selected and join block with next if we are at the end
    if (event.key == 'Delete') {

      // SAFARI HACK: if the offset is last character we need to do this manually as well
      var offsetOneAndSafari = selection.isCollapsed && (this.currentSelection.startOffset == 0 && this.getBlock(this.currentSelection.startBlockId).length() == 1) && Tremr.Utils.checkForSafari();
      if (offsetOneAndSafari) {
        // select the character and use our own logic
        this.currentSelection.endBlockId = this.currentSelection.startBlockId;
        this.currentSelection.startOffset = this.currentSelection.startOffset;
        this.currentSelection.endOffset = this.currentSelection.startOffset+1;
      }

      // only do this if we at the END of a block or have a selection - otherwise no need
      if (offsetOneAndSafari || (!selection.isCollapsed || this.currentSelection.startOffset >= this.getBlock(this.currentSelection.startBlockId).length())) {
        event.preventDefault();
        event.stopPropagation();
        this.handleDelete();
      }

      return;
    }

    // watch for enter
    if (event.key == 'Enter' || event.key == 'Tab') {

      event.preventDefault();
      event.stopPropagation();

      this.handleEnter();
      return;
    }

    // watch for apple/ctrl-b to toggle bold
    if (event.metaKey) {

      var format = false;
      if (event.keyCode == '66') { // B
        format = 'STRONG';
      } else if (event.keyCode == '73') { // I
        format = 'EM';
      }

      if (format) {
        event.preventDefault();
        event.stopPropagation();

        // handle range only, might span multiple blocks
        if (this.currentSelection.endOffset) {

          // send the update
          this.toggleFormat(format, this.currentSelection);
        }


      }

      return;
    }


    // if we have a selection that crosses blocks then handle keypress manually
    if (event.key == 'Unidentified' &&
        this.currentSelection['startBlockId'] &&
        this.currentSelection['endBlockId'] &&
        this.currentSelection['startBlockId'] != this.currentSelection['endBlockId']) {

      event.preventDefault();
      event.stopPropagation();
      this.handleInsertKeyAcrossBlocks(event.keyCode, event.shiftKey);
      return;
    }
  },

  // needs to be manually handled unless withing one block, so just prevent unless we're
  // in that simple case
  handleCut: function(event) {

    this.currentSelection = this.getCurrentSelection();
    if (this.currentSelection.startBlockId !== this.currentSelection.endBlockId) {
      event.preventDefault();
      event.stopPropagation();
      alertify.error("Please use "+Tremr.Utils.getMetaKeyDesc()+"-X for cutting content.");
    }
  },

  // sanitize and manually add pasted data
  handlePaste: function(event) {

    // always cancel the event and manually update
    event.preventDefault();
    event.stopPropagation();

    this.currentSelection = this.getCurrentSelection();

    // get the content as html or plain
    var clipboardData = event.clipboardData.getData('text/html');
    if (clipboardData == "") {
        clipboardData = event.clipboardData.getData('text/plain');
    } else {
      // check if we have a body - if we do ONLY keep that (remove the rest)
      if (clipboardData.match(/.*<body.*/gmi)) {

        var docFragment = document.createDocumentFragment();
        var child = document.createElement('html');
        docFragment.appendChild(child);
        child.innerHTML = clipboardData;
        var head = docFragment.querySelector('head');
        var body = docFragment.querySelector('body');
        if (body) {
          // just use body
          clipboardData = body.innerHTML;

        } else if (head) {
          // head but no body - revert to text only
          clipboardData = event.clipboardData.getData('text/plain');
        }
      }

      // also remove ANY style or script elements including their content
      clipboardData = clipboardData.replace(/<script.*>[\s\S]*<\/script>/gmi, "");
      clipboardData = clipboardData.replace(/<style.*>[\s\S]*<\/style>/gmi, "");
    }

    console.log("clipboardData:"+clipboardData);

    // reduce to just text and simple markup
    var sanitizedClipboardData = Tremr.Utils.sanitize(clipboardData, true, true);
    console.log("sanitizedClipboardData:"+sanitizedClipboardData);

    // also replace empty p tags
    sanitizedClipboardData = sanitizedClipboardData.replace(/<span>\s*<\/span>/gmi, "");
    sanitizedClipboardData = sanitizedClipboardData.replace(/<p>\s*<\/p>/gmi, "");

    // replace &nbsp; with space
    sanitizedClipboardData = sanitizedClipboardData.replace(/(&nbsp;)/gmi, " ");

    // replace leading whitespace and &nbsp;
    sanitizedClipboardData = sanitizedClipboardData.replace(/^(&nbsp;)+/gmi, "");
    sanitizedClipboardData = sanitizedClipboardData.replace(/^(\s)+/gmi, "");
    sanitizedClipboardData = sanitizedClipboardData.replace(/^(&nbsp;)+/gmi, "");

    // let troublesomeChars = String.fromCharCode(32, 160, 32, 10);
    // while (sanitizedClipboardData.indexOf(troublesomeChars) > -1) {
    //   sanitizedClipboardData = sanitizedClipboardData.replace(troublesomeChars, " ");
    // }

    // reduce multiple whitespace
    // sanitizedClipboardData = sanitizedClipboardData.replace(/\u00A0\u0020\u000A/gmi, " "); // nbsp!
    // sanitizedClipboardData = sanitizedClipboardData.replace(/\x20\xA0\x20\x0A/gmi, " "); // nbsp!
    sanitizedClipboardData = sanitizedClipboardData.replace(/\u0020+/gmi, " "); // multiple spaces

    // remove control characters
    // sanitizedClipboardData = sanitizedClipboardData.replace(/[\x00-\x1F\x7F-\x9F]/gmi, "");

    // build array of content that we wan't to add
    var lines = sanitizedClipboardData.split("\n");

    var blocks = [];
    var newBlock = new Tremr.Editor.TextBlock(Tremr.Utils.uniqueId('block'), 'text');

    // iterate lines, adding to the current block until we find a new block indicator
    // adding all content to the current block.
    _.each(lines, function(line) {

      var newTag = false;
      if (line.startsWith('<p>')) {
        newTag = 'p';
      } else if (line.startsWith('<blockquote>')) {
        newTag = 'blockquote';
      } else if (line.startsWith('<h2>') || line.startsWith('<h3>')) {
        newTag = 'h2';
      }

      if (newTag) {

        // if current block has some content, add it to list
        if (newBlock.length() > 0) {

          // count leading whitespace and remove (allowing for formatting)
          let leadMatch = newBlock.get('plainText').match(/^\s+/);
          if (leadMatch && leadMatch.length > 0) {
            var leadingSpaces = leadMatch[0].length;
            if (leadingSpaces > 0) {
              newBlock.removeCharactersLeft(leadingSpaces);
            }
          }

          blocks.push(newBlock);
        }

        // create new block of specified type
        newBlock = new Tremr.Editor.TextBlock(Tremr.Utils.uniqueId('block'), 'text');
        newBlock.set('tag', newTag);

      }

      // add content, parse tags using dummy element and existing parse func
      var dummyNode = document.createElement('div');
      dummyNode.innerHTML = line;

      var dummyBlock = new Tremr.Editor.TextBlock(Tremr.Utils.uniqueId('block'), 'text');
      var parsed = dummyBlock.parseNodes(dummyNode.childNodes);
      console.log("parsed plainText:"+parsed.plainText);
      if (parsed.plainText.trim().length > 0) {

        // add a space on all but first
        // (these are lines broken on newline, so original html had whitespace)
        if (newBlock.get('plainText').length > 0) {
          dummyBlock.set('plainText', " " + parsed.plainText);

          // also adjust format rules to include this extra space
          parsed.formatRules = parsed.formatRules.map(function(rule) {
            return _.extend(rule, {
              start: rule.start + 1,
              end: rule.end + 1
            });
          });

        } else {
          dummyBlock.set('plainText', parsed.plainText);
        }
        dummyBlock.set('formatRules', parsed.formatRules);
        newBlock.append(dummyBlock);
      }

    }.bind(this));

    // add the last block on
    if (newBlock.length() > 0) {

      // count leading whitespace and remove (allowing for formatting)
      var leadMatch = newBlock.get('plainText').match(/^\s+/);
      if (leadMatch && leadMatch.length > 0) {
        var leadingSpaces = leadMatch[0].length;
        if (leadingSpaces > 0) {
          newBlock.removeCharactersLeft(leadingSpaces);
        }
      }

      blocks.push(newBlock);
    }

    // add the blocks, append 1st to start block, last prepend to last and middle add.

    // flag if we have a range and therefore want to delete some blocks
    var deleteMiddle = false;
    if (this.currentSelection.endBlockId) {
      deleteMiddle = true;
    }

    // set which block to select in callback
    var selectBlockId = false;
    var startOffset = 0;

    // update with callbacks - each one expects an array of blocks to be returned (or not!)
    var updatedBlocks = this.updateBlocks({

      // first - remove selection and append the first block
      first: function(block) {
        block.removeCharactersRight(this.currentSelection.startOffset);
        startOffset = block.length();
        selectBlockId = block.get('identifier');
        block.append(blocks.shift());
        return [block];
      }.bind(this),

      // first AND last - split around selection and insert new blocks
      firstAndLast: function(block) {
        var newBlocks = [];

        if (this.currentSelection.endBlockId) {
          newBlocks = block.splitAt(this.currentSelection.startOffset, this.currentSelection.endOffset);
        } else {
          newBlocks = block.splitAt(this.currentSelection.startOffset);
        }

        // replace the first block with split and merge first in
        var oldFirstBlock = _.first(blocks);
        blocks[0] = newBlocks[0];
        _.first(blocks).append(oldFirstBlock);

        // set selection of length of current last block
        selectBlockId = _.last(blocks).get('identifier');
        startOffset = _.last(blocks).length();

        // merge split after last block
        _.last(blocks).append(newBlocks[1]);

        return blocks;
      }.bind(this),

      // middle - ignore existing, return empty
      middle: function(block) {
        // return blocks.slice(0, blocks.length - 1);
        return [];
      }.bind(this),

      // last one - return all new blocks (except first) and
      // we know last block has a selection, simply remove the selection
      last: function(block) {
        block.removeCharactersLeft(this.currentSelection.endOffset);
        var newBlock = blocks.pop();
        if (newBlock) {
          newBlock.append(block);
          selectBlockId = newBlock.get('identifier');
          return blocks.slice(1, blocks.length - 1).concat([newBlock]);
        } else {
          return blocks.slice(1, blocks.length - 1).concat([block]);
        }
      }.bind(this)

    });

    // set the selection to the start of the new block
    if (selectBlockId) {
      this.currentSelection.startBlockId = selectBlockId;
      this.currentSelection.startOffset = startOffset;
      delete this.currentSelection['endBlockId'];
      delete this.currentSelection['endOffset'];
    }

    // update the blocks
    this.setBlockState({
      blocks: updatedBlocks
    });

    // notify parent that we have an update
    if (this.props.onChange) {
      _.delay(function() {
        this.props.onChange(this.getBlocksJson());
      }.bind(this), 200);
    }
  },

  // update selection when mouse released
  handleMouseUp: function(event) {

    _.defer(function() {
      // read the current selection
      this.currentSelection = this.getCurrentSelection();

      this.checkForFormat();
    }.bind(this));
  },

  // update selection when key released
  handleKeyUp: function(event) {

    // console.log("KeyUp: "+event.keyCode+" "+event.key);

    if (event.key.startsWith('Arrow')) {
      // read the current selection
      this.currentSelection = this.getCurrentSelection();
    }

    this.checkForFormat();
  },

  // show or hide the format popup
  checkForFormat: function() {

    // never allow on mobile
    if (Tremr.Utils.isDevice()) {
      return;
    }

    // only update on change
    if ((this.state.showFormat && this.currentSelection.endBlockId === undefined) ||
        (!this.state.showFormat && this.currentSelection.endBlockId !== undefined)) {

      if (this.currentSelection.endBlockId) {
        // check if at least one selected block is allowed formatting
        var allowedFormat = this.updateBlocks({
          beforeFirst: function(block) { return false; },
          first: function(block) { return (block.allowedFormat); },
          firstAndLast: function(block) { return (block.allowedFormat); },
          middle: function(block) { return (block.allowedFormat); },
          last: function(block) { return (block.allowedFormat); },
          afterLast: function(block) { return false; }
        });
        allowedFormat = _.contains(allowedFormat, true);
      } else {
        allowedFormat = false;
      }

      this.setState({
        showFormat: allowedFormat
      });
    } else if (this.refs['formatPopup']) {
      this.refs['formatPopup'].setPosition();
    }

  },

  // set our width in state, so we can tell upload/embed blocks
  // how wide to be
  setWidth: function() {
    if (this.state.width == false) {

      var domNode = ReactDOM.findDOMNode(this);
      var width = domNode.offsetWidth;

      // no margins on media any more
      // width = width - (this.props.margins * 2);

      this.setState({
        width: width
      });
    }
  },

  componentDidMount: function() {
    this.setWidth();
  },

  componentDidUpdate: function(prevProps, prevState) {

    if (!this.stopListening) { _.extend(this, Backbone.Events); }

    // set the selection back after markup changes
    if (this.currentSelection) {
      // console.log("setCurrentSelection");
      this.setCurrentSelection(this.currentSelection);
    } else {
      // console.log("set to start");
      // check if the user has a selection not in a block and set to 1st block
      var selection = window.getSelection();
      if ( (selection.anchorNode &&
            selection.anchorNode.classList &&
            selection.anchorNode.classList.contains('content')) ||
          (selection.anchorNode == null && this.state.contentEffected)
        )  {
        this.setCurrentSelection({
          startBlockId: this.state.blocks[0].get('identifier'),
          startOffset: 0,
          invalid: true
        });
      }
    }

    this.repositionControls();
    this.setWidth();
  },

  componentWillUnmount: function() {

        if (this.stopListening) {
            this.stopListening();
        }
    },

  // called from the format popup
  formatSelect: function(selection, format, meta) {

    this.currentSelection = selection;

    if (format == 'A') {
      this.toggleFormat(format, selection, meta);
      // this.closeFormatPopup(selection);
    } else if (format == '-A') {
      this.toggleFormat(format, selection, meta);
      // this.restoreSelection(selection);
    } else if (format == 'H2' || format == 'BLOCKQUOTE') {
      this.toggleTag(format, selection);
    } else {
      this.toggleFormat(format, selection, meta);
    }
    this.restoreSelection(selection);
    this.closeFormatPopup();
  },

  // called from the format popup to restore a selection
  restoreSelection: function(selection) {
    this.currentSelection = selection;
    if (this.currentSelection) {
      this.setCurrentSelection(this.currentSelection);
    }
    this.checkForFormat();
  },

  // close the format popup
  closeFormatPopup: function() {
    delete this.currentSelection['endBlockId'];
    delete this.currentSelection['endOffset'];
    this.checkForFormat();
  },

  // does the selection contain the specified format
  containsFormat: function(format, selection) {

      if (selection === undefined) {
        selection = this.currentSelection;
      }

      var checkSelection = this.updateBlocks({
        beforeFirst: function(block) { return false; },
        first: function(block) {
          return block.containsFormat(format, selection.startOffset);
        },
        firstAndLast: function(block) {
          return block.containsFormat(format, selection.startOffset, selection.endOffset);
        },
        middle: function(block) {
          return block.containsFormat(format, 0, block.length());
        },
        last: function(block) {
          return block.containsFormat(format, 0, selection.endOffset);
        },
        afterLast: function(block) { return false; }
      });
      checkSelection = _.contains(checkSelection, true);
      return checkSelection;
  },

  // does the selection contain the specified tag
  containsTag: function(tag, selection) {

      if (selection === undefined) {
        selection = this.currentSelection;
      }

      var isTag = function(block) { return block.get('tag') == tag; }

      var checkSelection = this.updateBlocks({
        beforeFirst: function(block) { return false; },
        first: isTag,
        firstAndLast: isTag,
        middle: isTag,
        last: isTag,
        afterLast: function(block) { return false; }
      });
      checkSelection = _.contains(checkSelection, true);
      return checkSelection;
  },

  getSelection: function() {
    return this.currentSelection;
  },

  // block should be removed
  removeBlock: function(identifier) {

    var selecting = false;
    var selectBlockId = false;

    var blocksArray = this.state.blocks.slice();
    blocksArray = _.flatten(this.mapBlocks(blocksArray, function(block) {
      if (block.get('identifier') != identifier) {
        if (selecting && !selectBlockId) { selectBlockId = block.get('identifier'); }
        return block;
      } else {
        selecting = true;
        return [];
      }
    }), true);

    // make sure we select the start of the next block or the last block
    if (!selectBlockId) {
      selectBlockId = _.last(blocksArray).get('identifier');
    }
    this.currentSelection.startBlockId = selectBlockId;
    this.currentSelection.startOffset = 0;
    delete this.currentSelection['endBlockId'];
    delete this.currentSelection['endOffset'];

    this.setBlockState({
      blocks: blocksArray
    });
  },

  // block updated, move it's add after control to new position
  blockPosition: function(identifier, bounds, checkSubsequent, caller) {

    if (checkSubsequent === undefined) { checkSubsequent = false; }

    var domNode = ReactDOM.findDOMNode(this);
    var parentBounds = domNode.getBoundingClientRect();
    var top = bounds.top - (parentBounds.top - domNode.scrollTop);
    var bottom = bounds.bottom - (parentBounds.top - domNode.scrollTop);

    // update the control
    var blockControl = this.refs[this.blockControlIdentifier(identifier)];

    // if we haven't got the control, it probably hasn't been rendered yet - just re-call
    if (!blockControl) {

      if (caller && caller.notifyPosition) {
        _.delay(caller.notifyPosition, 200);
      }
      return false;
    }

    // update state with new position, depending on mobile/res
    var controlOffset = '0';
    // if (Tremr.Utils.isDeviceOrNarrow()) {
    //   controlOffset = '0';
    // }
    blockControl.setState({
      position: {
        top: bottom - 15,
        right: controlOffset
      }
    });

    // force all subsequent blocks to update by calling this
    if (checkSubsequent) {
     this.repositionControls(identifier);
    }

    return true;
  },

  // ask all blocks after the identifier to notify of their position
  repositionControls: _.throttle(function(identifier) {
    var found = false;
    if (!identifier) {
      found = true;
    }
    _.each(this.state.blocks, function(block) {
        if (found) {
          var blockComponent = this.refs[block.get('identifier')];
          if (blockComponent) {
            blockComponent.notifyPosition();
          }
        }
        if (block.get('identifier') == identifier) {
          found = true;
        }
    }.bind(this));
  }, 1000),

  onFocus: function() {

    this.setContentEffected();

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  },

  onBlur: function() {

    // console.log("onBlur");
    // console.trace();

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  },

  setContentEffected: function(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.setState({
        contentEffected: true
      });
   },

  render: function() {

    var formatPopup = null;
    if (!this.props.readOnly && this.state.showFormat) {
      var rangeForSelection = this.createRangeForSelection(this.currentSelection);
      if (rangeForSelection && rangeForSelection.range) {
        formatPopup = <Tremr.Editor.Formatpopup range={rangeForSelection.range} ref="formatPopup" key="formatPopup" containsFormat={this.containsFormat} containsTag={this.containsTag} close={this.closeFormatPopup} getSelection={this.getSelection} format={this.formatSelect} />;
      }
    }

    var controls = null;
    if (!this.props.readOnly) {
      controls = <div className="controls">
        {_.map(this.state.blocks, function(value, index) {
          return this.createAddBlockControl(value.get('identifier')).control;
        }.bind(this)) }
      </div>;
    }

    var mediaWidth = 768;
    if (this.state.width) {
      mediaWidth = this.state.width;
    }

    var classes = {
      focus: this.state.focus
    };
    classes['editor-richtext'] = true;
    classes = classNames(classes);

    // if we have a single block and it has no content, display a placeholder instead
    var renderBlocks = [];
    var placeholderControl = null;
    if (!this.state.blocks || !this.state.blocks[0]) {
        renderBlocks = [];
        placeholderControl = null;

    } else if (this.state.contentEffected ||
        this.state.blocks.length > 1 ||
        this.state.blocks[0].length() > 0) {
      renderBlocks = this.state.blocks;
    } else if (!this.props.readOnly) {
    placeholderControl = <Tremr.Editor.PlaceholderControl identifier={this.state.blocks[0].get('identifier')} onClick={this.setContentEffected} notifyPosition={this.blockPosition} />;
    }

    return (

      <div className={classes}>
        <div className="content" onFocus={this.onFocus} onBlur={this.onBlur} contentEditable={!this.props.readOnly} onPaste={this.handlePaste} onCut={this.handleCut} onMouseUp={this.handleMouseUp} onInput={this.handleInput} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} data-gramm="false" >
          {_.map(renderBlocks, function(value, index) {
            var proportionalHeight = Math.round(mediaWidth / 1.61);
            return value.getControl(this.blockPosition, this.removeBlock, this.props.readOnly, mediaWidth, proportionalHeight, index);
          }.bind(this)) }
          {placeholderControl}
        </div>
        {controls}
        {formatPopup}
      </div>
    );
  }
});
