// single place that can determine what should or should not be displayed
// for post origin and via avatar.
// returns object with keys for various items that might be displayed or not
module.exports = function (post) {
  const supressViaForFeeds = [
    "tremr",
    "best-of-tremr",
    "top-picks",
    "covid-action",
  ];

  let obj = {
    isVia: false,
    inChannel: false,
    inTag: false,
    tagSlug: null,
    tagName: null,
    channelSlug: null,
    channelTitle: null,
    viaSlug: null,
    viaName: null,
    viaAvatar: null,
    viaComment: null,
  };

  if (!post) {
    return obj;
  }

  if (post.get("reasons") && post.get("reasons").length > 0) {
    // if we have reasons then this is a personal feed
    let reason = post.get("reasons")[0];

    // always show via/channel info if we have it
    if (reason.reason == "tag" && reason.tag) {
      obj.inTag = true;
      obj.tagSlug = reason.tag;
      obj.tagName = reason.tag;
    } else if (reason.reason == "feed" && reason.author) {
      // if reason is simply a feed - no need to show anything
      // obj.isVia = true;
      // obj.viaSlug = post.get("channel_author_feed") || reason.author_feed;
      // obj.viaName = reason.author;
      // obj.viaAvatar = reason.avatar;
    } else if (reason.reason == "repost") {
      // default to showing via
      if (reason.comment && reason.comment.length > 0) {
        obj.viaComment = reason.comment;
      }

      if (reason.author) {
        obj.isVia = true;
        obj.viaSlug = reason.author_feed;
        obj.viaName = reason.author;
        obj.viaAvatar = reason.avatar;
      }

      if (reason.feed_title && reason.author_feed != reason.feed) {
        obj.isVia = true;
        obj.viaSlug = reason.feed;
        obj.viaName = reason.feed_title;
        obj.viaAvatar = reason.avatar;
      }

      // no comment, hide for certain feeds
      // if (supressViaForFeeds.includes(reason.feed) && !obj.viaComment) {
      //   obj.isVia = false;
      //   obj.viaSlug = null;
      //   obj.viaName = null;
      //   obj.viaAvatar = null;
      // }
    }

    // may also be posted/reposted in a channel
    if (reason.channel_title && reason.channel) {
      obj.inChannel = true;
      obj.channelSlug = reason.channel;
      obj.channelTitle = reason.channel_title;

      // if we have a channel and it matches then via data, remove
      // the via data
      if (reason.channel == reason.feed) {
        obj.isVia = false;
        obj.viaSlug = null;
        obj.viaName = null;
        obj.viaAvatar = null;
      }
    }
  } else if (post.get("repost")) {
    // reposted to the feed we're looking at
    let repostedData = post.get("repost");
    // console.log("is repost");
    // console.dir(repostedData);

    // default to showing via
    obj.isVia = true;
    obj.viaSlug = repostedData.feed || repostedData.author_feed;
    obj.viaName = repostedData.author || repostedData.author_feed;
    obj.viaAvatar = repostedData.avatar;

    // if we have a comment - always show via
    if (repostedData.comment && repostedData.comment.length > 0) {
      obj.viaComment = repostedData.comment;
    } else {
      // no comment, so we hide when repost was done by original author
      if (
        (post.get("author_feed") &&
          repostedData.author_feed == post.get("author_feed")) ||
        (post.get("channel_author_feed") &&
          repostedData.author_feed == post.get("channel_author_feed"))
      ) {
        obj.isVia = false;
        obj.viaSlug = null;
        obj.viaName = null;
        obj.viaAvatar = null;
      }

      // no comment, so we hide when repost was already posted here
      if (repostedData.feed == post.get("author_feed")) {
        obj.isVia = false;
        obj.viaSlug = null;
        obj.viaName = null;
        obj.viaAvatar = null;
      }

      // no comment, hide for certain feeds
      if (
        supressViaForFeeds.includes(
          repostedData.feed || repostedData.author_feed
        )
      ) {
        obj.isVia = false;
        obj.viaSlug = null;
        obj.viaName = null;
        obj.viaAvatar = null;
      }
    }

    if (post.get("channel_title")) {
      // is posted in a channel, show channel unless that is what we are viewing
      if (
        post.get("channel_author_feed") &&
        post.get("author_feed") != repostedData.feed
      ) {
        obj.inChannel = true;
        obj.channelSlug = post.get("author_feed");
        obj.channelTitle = post.get("channel_title");
      }
    }
  } else if (post.get("posted")) {
    // posted to the feed we're looking at

    // never show repost - no need, this was posted to this place.
    // DO, show 'in channel' when this isn't a channel_name
    let postedData = post.get("posted");

    if (postedData && postedData.channel) {
      // is posted in a channel, show channel if we are viewing the channel author feed
      if (post.get("channel_author_feed") == postedData.feed) {
        obj.inChannel = true;
        obj.channelSlug = post.get("author_feed");
        obj.channelTitle = postedData.channeltitle;
      }
    } else {
      // not in a channel and not reposted, show nothing - do nothing.
    }
  } else {
    // no data for the above conditions means we're loading the
    // post in a context such as ancestors/responses where we don't need
    // this information - so just leave alone
  }

  return obj;
};
