var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// users new password after using forgotten password link
// Tremr.User.NewPassword = CreateReactClass({
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      token: PropTypes.string.isRequired
  },


  getInitialState: function() {
    return {
      isLoading: false
    }
  },

  // register listeners
  componentDidMount: function() {

    _.extend(this, Backbone.Events);

    // close overlay and set current user
    this.listenTo(Tremr.dispatcher, "tremr:user:newpassword:success", function(user) {

      Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);

    }.bind(this));

    // error saving, display errors
    this.listenTo(Tremr.dispatcher, "tremr:user:newpassword:failed", function(errors) {

      // clear old errors
      _.each(this.refs, function(ref) {
        if (ref.state.error) {
          ref.setState({
            error: false
          });
        }
      }.bind(this));

      _.each(_.pairs(errors), function(error) {
        if (this.refs[error[0]]) {
          this.refs[error[0]].setState({
            error: true,
            errorMessage: error[1]
          });
        }
      }.bind(this));

      this.setState({
        isLoading: false
      });

    }.bind(this));

  },

  // unregister listeners
  componentWillUnmount: function() {

    this.stopListening();
  },

  save: function(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    var data = {
      token: this.props.token,
      password: this.refs.password.state.value,
      confirm_password: this.refs.confirm_password.state.value,
    };

    Tremr.dispatcher.message(this, "tremr:user:newpassword", data);
  },

  render: function() {

    var buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading
    });

    var buttonLabel = 'Set Password';
    if (this.state.isLoading) {
      buttonLabel = 'Setting Password';
    }

    return (

        <div className="user-signin layout-staticpage new-password">
            <form className="content tx2">

            <h2>Set your new password</h2>

            <Tremr.Generic.Input ref="password" name="password" label="New password" placeholder="Enter new password" type="password" maxLength="50" />
            <Tremr.Generic.Input ref="confirm_password" name="confirm_password" label="Confirm new password" placeholder="Enter new password" type="password" maxLength="50" />

            <p>
            <button onClick={this.save} className={buttonClasses}>
                {buttonLabel}
            </button>
            </p>
            </form>
      </div>
    );
  }
});
