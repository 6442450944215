require("./scss/wall.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var Grid = require("./grid");
var Footer = require('../../stores/layout/footer');
var TremrUtils = require('../../utils/tremr_utils');
var DummyCard = require('../../stores/layout/dummycard');

// Tremr.Generic.Wall = CreateReactClass({
module.exports = CreateReactClass({
   // mixins: [PureRenderMixin],

   propTypes: {
      tabs: PropTypes.array.isRequired,
      defaultTab: PropTypes.string.isRequired,
      datasources: PropTypes.array.isRequired,
      context: PropTypes.object.isRequired,
      cardFactory: PropTypes.func.isRequired,
      collectionFactory: PropTypes.func.isRequired,
      cache: PropTypes.bool,
      loadedCallback: PropTypes.func,
      sortChanged: PropTypes.func,
      // rightHandObjects: PropTypes.array,
      // headlineFactory: PropTypes.object,
      showFilter: PropTypes.bool,
      identifier: PropTypes.string, // so we can send events in specific to this wall & pass to cards
      forceGridStyle: PropTypes.string,
      customDelete: PropTypes.func, // so we can delete using fields other than id
      gridSettings: PropTypes.object,
      controls: PropTypes.element,
      wallStyleProperty: PropTypes.string,
      loadedCallbackWait: PropTypes.bool
   },

   getDefaultProps: function() {
      return {
          showFilter: false,
          forceGridStyle: "",
          isNested: false,
          loadedCallbackWait: true
      };
   },

   // default to an empty list of posts, in the "latest"
   getInitialState: function() {
      // setup tabs based on properties
      var tabs = {};
      _.each(this.props.tabs, function(tab) {
         tabs[tab] = {
            name: tab,
            nextPage: 1,
            lastLoaded: 0,
            loadedAll: false,
            collection: this.props.collectionFactory()
         };
      }.bind(this));

      // set state
      return {
         injectedCount: 0,
         promises: [], // promise references so we can cancel on unmount
         isLoading: true, // flag turned on/off to show loading image
         currentTab: this.props.defaultTab,
         gridStyle: this.props.forceGridStyle || Tremr.stores.userStore.getPreference(this.props.wallStyleProperty),
         tabs: tabs,
         isInit: true,
         collection: this.props.collectionFactory(), // keep a copy of current tabs posts, and start as empty
         waitingForCardLoads: 0,
         cardsLoaded: 0
      };
   },

   // toggle from grid to list
   switchStyle: function(newstyle) {
		if (this.props.wallStyleProperty) {
         Tremr.stores.userStore.setPreference(this.props.wallStyleProperty, newstyle);

         // for content style we should wait for the cards so they can load they
         // can load the content that we know those cards need via load callback
         let waitForCards = 0;
         if (newstyle == 'content' && this.state.collection) {
            let uniqData = TremrUtils.filterUniqueIds(this.state.collection);
            waitForCards =  uniqData.length
         }

         this.setState({gridStyle: newstyle, waitingForCardLoads: waitForCards, cardsLoaded: 0});
         if (this.props.sortChanged) {
            this.props.sortChanged(this.state.currentTab);
         }
      }
   },

   // switch to a different tab
   switchTab: function(tab) {

      var collection = this.state.tabs[tab].collection;

      if (!collection) {
         collection = this.props.collectionFactory();
      }

      var newState = {
         currentTab: tab,
         collection: collection,
         tabs: this.state.tabs,
         isInit: true,
         waitingForCardLoads: 0,
         cardsLoaded: 0
      };

      // load page if we haven't loaded any yet
      if (this.state.tabs[tab].nextPage == 1) {
         // load directly using the new tab (so the data is correct before and after setState is evaluated)
         this.loadData(tab, this.state.tabs[tab].nextPage, this.state.tabs[tab].collection);
         newState.tabs[tab] = {
            nextPage: this.state.tabs[tab].nextPage + 1
         };
      } else {
         // we already have some data loaded so just go straight to it
         newState.isLoading = false;
         newState.isInit = false;
      }

      this.setState(newState);
      if (this.props.sortChanged) {
         this.props.sortChanged(tab);
      }
   },

   // see if we need to load some initial data, happens when rendered with new props (i.e. target changes)
   // and when switching tabs
   componentDidUpdate: function() {
      // load page if we haven't loaded any yet, this only happens
      // when initially placed in the page and when the user changes
      // the target (i.e. switches from posts to posts+responses)
      if (this.state.tabs[this.state.currentTab] && this.state.tabs[this.state.currentTab].nextPage == 1) {
         this.loadMore();
     } else if (!this.state.tabs[this.state.currentTab]) {
         _.defer(() => {
             this.loadMore();
         });
     }
   },

   // load data from server when first added to DOM,
   // WE ACTUALLY WAIT AND DO THIS ONCE UPDATED BECAUSE THAT IS CALLED
   // FREQUENTLY AND IMPORTANTLY WHEN RERENDERED WITH NEW PROPS, SO WE
   // CAN LOAD IN DIFFERENT DATA!
   componentDidMount: function() {
      if (this.state.tabs[this.state.currentTab] && this.state.tabs[this.state.currentTab].nextPage == 1) {
         this.loadMore();
     } else if (!this.state.tabs[this.state.currentTab]) {
         _.defer(() => {
             this.loadMore();
         });
     }

      _.extend(this, Backbone.Events);

      this.listenTo(Tremr.dispatcher, "tremr:filter:sort:" + this.props.identifier, function(tab) {
          this.switchTab(tab);
      }.bind(this));

      if (this.props.wallStyleProperty) {
         this.listenTo(Tremr.dispatcher, "tremr:user:preference:" + this.props.wallStyleProperty, function(data) {
            this.switchStyle(data.value);

            // scroll to the top of this wall
            let wallNode = ReactDOM.findDOMNode(this);
            let wallRect = wallNode.getBoundingClientRect();
            window.scrollBy(0, wallRect.top - 60); // 60px = sticky header height

         }.bind(this));
      }

   },

   // unregister listeners
   componentWillUnmount: function() {
      this.state.promises = _.reject(this.state.promises, function(promise) {
         promise.cancel();
         return true;
      });

      if (this.stopListening) {
         this.stopListening();
      }
   },

   // load data from provided datasource and update state
   loadData: function(tab, page, collection) {

      // exit if we have reached the end
      if (this.state.tabs[tab].loadedAll == true) {
         return;
      }

      this.setState({isLoading: true, collection: this.state.tabs[tab].collection});

      // load data from datasource with promise
      if (this.props.datasources[tab]) {

         let promise = this.props.datasources[tab](page, collection, this.props.context, this.props.cache);
         let promises = this.state.promises.slice(0);
         promises.push(promise);
         this.setState({promises: promises});

         promise.then(function(params) {
            var collection = params.collection;
            var data = params.data;
            var page = params.page;
            var options = params.options;

            var wasInit = this.state.isInit;
            var newState = {
               isLoading: false,
               isInit: false,
               collection: collection,
               tabs: this.state.tabs
            };
            newState["tabs"][tab].collection = collection;

            // flag no more records, if no more are returned
            // code for loading drafts is broken by this because 2nd page
            // of drafts calls the callback before the 1st page returns.
            // removing this doesn't seem to break other pages - it perhaps costs us one extra empty
            // page loading?
            if (data.length == 0) {
                newState["tabs"][tab].loadedAll = true;
            }

            // flag this page has loaded
            newState.tabs[tab].lastLoaded = page;

            // check if we actually have more data than expected
            if (newState.tabs[tab].nextPage < page) {
               newState.tabs[tab].nextPage = page + 1;
            }

            // remove uievent special meta data and dedupe ids
            let uniqData = TremrUtils.filterUniqueIds(data);

				// fire loaded callback, unless this is a list/content view and the first page (in which case wait for cards)
            if (this.props.loadedCallbackWait && uniqData.length > 0 && this.state.gridStyle == "content" && page == 1) {

               newState.waitingForCardLoads = uniqData.length;

            } else if (this.props.loadedCallbackWait && uniqData.length > 0 && this.state.gridStyle == "list" && page == 1) {

               newState.waitingForCardLoads = uniqData.length;

            } else {
               if (this.props.loadedCallback && _.isFunction(this.props.loadedCallback)) {
                  _.defer(() => { // must be delayed
                     this.props.loadedCallback(uniqData, this.state.gridStyle);
                  });
               }
            }

				this.setState(newState);

            // fire event if empty collection
            if (collection.length == 0 && wasInit) {
               Tremr.dispatcher.message(this, "tremr:wall:empty:" + this.props.context.target + ":" + this.props.context.scope, this.props.context);
            }
         }.bind(this), function(params) {
            // clear loading state
            this.setState({isLoading: false, isInit: false});
         }.bind(this));
     }
   },

   // cards are given this only in lists
   cardLoadedCallback: function() {

      let newState = {
         cardsLoaded: this.state.cardsLoaded + 1
      };

      if (this.state.waitingForCardLoads > 0 && newState.cardsLoaded == this.state.waitingForCardLoads) {
         newState.waitingForCardLoads = 0;
         _.defer(() => { // must be delayed
            let uniqData = TremrUtils.filterUniqueIds(this.state.collection);

            if (this.props.loadedCallback) {
	           this.props.loadedCallback(uniqData, this.state.gridStyle);
           }
         });
      }

      this.setState(newState);
   },

   // reloads the data for the current tab
   refresh: function() {
      // update tab to initial state, with no data
      var newState = {
         tabs: this.state.tabs
      };
      newState.tabs[this.state.currentTab].loadedAll = false;
      newState.tabs[this.state.currentTab].nextPage = 1;
      newState.tabs[this.state.currentTab].lastLoaded = 0;
      newState.tabs[this.state.currentTab].collection = this.props.collectionFactory();
      newState.collection = newState.tabs[this.state.currentTab].collection;
      newState.isInit = true;
      newState.isLoading = true;
      newState.waitingForCardLoads = 0;
      newState.cardsLoaded = 0;
      this.setState(newState);
   },

   // injects a new item at the start of the current list
   inject: function(data) {
      var newState = {
         tabs: this.state.tabs,
         injectedCount: this.state.injectedCount + 1
      };
      newState.tabs[this.state.currentTab].collection.add(data, {at: 0});
      newState.collection = newState.tabs[this.state.currentTab].collection;
      this.setState(newState);
   },

   // removes an item if it is present from the current list
   delete: function(id) {
      var newState = {
         tabs: this.state.tabs,
         injectedCount: this.state.injectedCount + 1
      };
      if (this.props.customDelete) {
         newState.tabs[this.state.currentTab].collection = this.props.customDelete(id, newState.tabs[this.state.currentTab].collection);
      } else {
         newState.tabs[this.state.currentTab].collection.remove(id);
      }
      newState.collection = newState.tabs[this.state.currentTab].collection;
      this.setState(newState);
   },

   // force reload of first page of current tab - if no new data react will make no changes
   loadNew: function() {
      if (this.state.tabs[this.state.currentTab] && this.state.tabs[this.state.currentTab].collection) {
         this.loadData(this.state.currentTab, 1, this.state.tabs[this.state.currentTab].collection);
      }
   },

   // load more
   loadMore: function(event) {

      if (this.state.tabs[this.state.currentTab] && this.state.tabs[this.state.currentTab].collection) {
         // only allow next page to start load once the last one has loaded
         if (this.state.tabs[this.state.currentTab].lastLoaded + 1 == this.state.tabs[this.state.currentTab].nextPage) {
            this.loadData(this.state.currentTab, this.state.tabs[this.state.currentTab].nextPage, this.state.tabs[this.state.currentTab].collection);

            // update pageno immediately
            var newState = {
               tabs: this.state.tabs
            };
            newState.tabs[this.state.currentTab].nextPage = this.state.tabs[this.state.currentTab].nextPage + 1;

            this.setState(newState);
         }
      }
   },

   // produce the page
   render: function() {

      // work out our top level class
      var classNames = require("../../utils/classnames");
      var classes = classNames({
         wall: true,
         loading: this.state.isLoading,
         content: this.state.gridStyle == "content",
         list: this.state.gridStyle == "list",
         grid: this.state.gridStyle == "grid"
      });

      let cardoptions = {
        sort: this.state.currentTab
      };

      // var headlineFactory = _.noop;
      // if (this.props.headlineFactory && this.props.headlineFactory[this.state.currentTab]) {
      // 	headlineFactory = this.props.headlineFactory[this.state.currentTab];
      // }
      var collectionComponent = "";
      if (this.state.gridStyle == "content") {
         collectionComponent = (<Tremr.Generic.List cardoptions={cardoptions} key={this.state.currentTab + "Content" + this.state.injectedCount
} listStyle="content" identifier={this.props.identifier} cardFactory={this.props.cardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={this.state.collection} loadedCallback={this.cardLoadedCallback}/>);
      } else if (this.state.gridStyle == "list") {
         // collectionComponent = <Tremr.Generic.List headlineFactory={headlineFactory} rightHandObjects={this.props.rightHandObjects} key={this.state.currentTab+"List"+this.state.injectedCount} cardFactory={this.props.cardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={this.state.collection} />;
         collectionComponent = (<Tremr.Generic.List cardoptions={cardoptions} key={this.state.currentTab + "List" + this.state.injectedCount
} identifier={this.props.identifier} cardFactory={this.props.cardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={this.state.collection} loadedCallback={this.cardLoadedCallback}/>);
      } else {
         // collectionComponent = <Tremr.Generic.Grid headlineFactory={headlineFactory} rightHandObjects={this.props.rightHandObjects} key={this.state.currentTab+"Grid"+this.state.injectedCount} cardFactory={this.props.cardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={this.state.collection} />;

         if (this.props.gridSettings && this.props.gridSettings.nextColumn && this.props.gridSettings.columnSpan && this.props.gridSettings.clearNewRow) {
            collectionComponent = (<Grid cardoptions={cardoptions} identifier={this.props.identifier} nextColumn={this.props.gridSettings.nextColumn} columnSpan={this.props.gridSettings.columnSpan} clearNewRow={this.props.gridSettings.clearNewRow} key={this.state.currentTab + "Grid" + this.state.injectedCount
} cardFactory={this.props.cardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={this.state.collection}/>);
         } else {
            collectionComponent = (<Grid cardoptions={cardoptions} key={this.state.currentTab + "Grid" + this.state.injectedCount
} identifier={this.props.identifier} cardFactory={this.props.cardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={this.state.collection}/>);
         }
      }

      let controls = null;
      if (this.props.controls) {
         controls = <Footer>
            {this.props.controls}
         </Footer>;
      }

      if (this.state.isLoading && this.state.collection.length == 0) {

          let dummyCardFactory = function(model, index, options) {

            let identifier = '';
            if (options && options.identifier) {
                identifier = options.identifier;
            }
            var key = "card-" + index + "-" + model.get('id') + "-" + options.wallStyle;
            return <DummyCard wallStyle={"card-" + options.wallStyle} ref={key} identifier={identifier} key={key} model={model}/>
          };
          let dummyCollectiobClass = Backbone.Collection.extend();
          let dummyCollection = new dummyCollectiobClass();
          dummyCollection.push({id: 1});
          dummyCollection.push({id: 2});
          dummyCollection.push({id: 3});
          dummyCollection.push({id: 4});
          dummyCollection.push({id: 5});
          dummyCollection.push({id: 6});
          dummyCollection.push({id: 7});
          dummyCollection.push({id: 8});

          if (this.state.gridStyle == "content" || this.state.gridStyle == "list") {

              collectionComponent = <Tremr.Generic.List cardoptions={cardoptions} key={this.state.currentTab + this.state.gridStyle + this.state.injectedCount} listStyle={this.state.gridStyle} identifier={this.props.identifier} cardFactory={dummyCardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={dummyCollection} />

          } else {

              // dummy grid
              if (this.props.gridSettings && this.props.gridSettings.nextColumn && this.props.gridSettings.columnSpan && this.props.gridSettings.clearNewRow) {

                  collectionComponent = (<Grid cardoptions={cardoptions} identifier={this.props.identifier} nextColumn={this.props.gridSettings.nextColumn} columnSpan={this.props.gridSettings.columnSpan} clearNewRow={this.props.gridSettings.clearNewRow} key={this.state.currentTab + "Grid" + this.state.injectedCount} cardFactory={dummyCardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={dummyCollection}/>);

              } else {

                  collectionComponent = (<Grid cardoptions={cardoptions} key={this.state.currentTab + "Grid" + this.state.injectedCount} identifier={this.props.identifier} cardFactory={dummyCardFactory} isInit={this.state.isInit} isLoading={this.state.isLoading} collection={dummyCollection}/>);
              }
          }

// } else if (this.state.isLoading === false && this.state.collection.length == 0) {
//     return <div id="empty">
//     Wall Empty
//     </div>
}

      // if we were supplied extra footer content deal with differently
      // var filter = null;
      // if (this.props.showFilter) {
      // 	filter = <Tremr.Layout.Filter tipPrefix="Order by " icons={false} wallIdentifier={this.props.identifier} tabs={this.props.tabs} initialTab={this.state.currentTab} />;
      // }
      // {filter}

      // render html
      return <div className={classes}>
         {collectionComponent}
         {controls}
      </div>;
   }
});
