require("./scss/welcometitlebar.scss");

var classNames = require('../../utils/classnames');
var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');
var Titlebar = require("../layout/titlebar");
var Cookies = require('js-cookie/src/js.cookie');

module.exports = CreateReactClass({

  mixins: [PureRenderMixin, UserAwareMixin],

  openWelcomer: function(event) {
    if (typeof ga !== "undefined") {
      ga("send", "event", "button", "welcomer-open");
    }
    event.stopPropagation();
    event.preventDefault();
  // Tremr.dispatcher.message(this, "tremr:open:requestinvite");
    Tremr.dispatcher.message(this, "tremr:open:pitchpage");
  },

  hideWelcomer: function(event) {
    if (typeof ga !== "undefined") {
      ga("send", "event", "button", "welcomer-hide");
    }
    Cookies.set('has_closed_welcomer', '1', {expires: 1});
    this.setState({
      hideWelcomer: true
    });
  },

  render: function() {

    var classes = {
        'welcometitlebar': true,
        'header': true
    };
    classes = classNames(classes);

    if (this.state.hideWelcomer || this.state.user || Cookies.get('has_signed_in') == '1' || Cookies.get('has_closed_welcomer') == '1') {
      return <Titlebar />;
    }

    return   <div className={classes}>
        <Titlebar style="light" />
        <div className="content">
            <h1>Space to think</h1>
            <h2>Welcome to {Tremr.Theme.Title}, {Tremr.Theme.Description}</h2>
            <p>
            <a className="button open-welcomer" href="/welcome" onClick={this.openWelcomer}>Learn More</a>
            <br />
            <button className="hide-welcomer" onClick={this.hideWelcomer}>Hide this</button></p>
        </div>
      </div>;
  }
});
