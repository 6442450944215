require("./scss/show.scss");
require("./scss/responsesbutton.scss");
require("../layout/scss/dummycontent.scss");

var moment = require("moment");
var TremrUtils = require("../../utils/tremr_utils");
var TremrStorage = require("../../utils/tremr_storage");
var classNames = require("../../utils/classnames");
var TremrTracking = require("../../utils/tracking");
var Cookies = require("js-cookie/src/js.cookie");
var UserAwareMixin = require("../../mixins/userawaremixin");
var TremrSettings = require("../../utils/tremr_settings");
var Logo = require("../generic/logotr");
var ResponsesMenu = require("./responsesmenu");
var ContentSummary = require("./contentsummary");
var MediaGallery = require("./mediagallery");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var WallControls = require("./wallcontrols");
var Breadcrumbs = require("./breadcrumbs");
var RelatedPosts = require("./related");
var Config = require("config");
var Share = require("../generic/share");
var Byline = require("../user/byline");
var Reactions = require("../activity/reactions");
var SvgIcon = require("../generic/svgicon");
var FactcheckBadge = require("../factcheck/badge");

var WithFBData = require("../generic/withFBData");
var ReplyPrompt = require("./replyprompt");
var ReplyPromptWithFBData = WithFBData(ReplyPrompt);
var ActivityIconButton = require("../activity/icon_button");
var ActivityIconButtonWithFBData = WithFBData(ActivityIconButton);
var RepostButton = require("../activity/repost_button");
var RepostButtonWithFBData = WithFBData(RepostButton);
var UpvoteButton = require("../activity/upvotebutton");
var UpvoteButtonWithFBData = WithFBData(UpvoteButton);
var ActivitySum = require("../activity/sum");
var ActivitySumWithFBData = WithFBData(ActivitySum);

// Tremr.Post.Show = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  promises: [], // promise references so we can cancel on unmount

  propTypes: {
    context: PropTypes.object.isRequired,
  },

  lastScrollPosition: 0,

  getDefaultSubTab: function (tab) {
    let currentUrl = TremrUtils.parseUrl();

    let subtab = "Chronological";
    if (currentUrl && currentUrl.params && currentUrl.params.subtab) {
      subtab = decodeURI(currentUrl.params.subtab);
    }

    if (
      tab == "responses" &&
      !["Chronological", "Latest", "Top Rated"].includes(subtab)
    ) {
      subtab = "Chronological";
    } else if (tab == "contributors" && !["Latest", "A-Z"].includes(subtab)) {
      subtab = "Latest";
    }

    return subtab;
  },

  // default to an empty page waiting to load
  getInitialState: function () {
    let currentUrl = TremrUtils.parseUrl();

    let tab = "responses";
    if (currentUrl && currentUrl.params && currentUrl.params.tab) {
      tab = decodeURI(currentUrl.params.tab);
    }

    let subtab = this.getDefaultSubTab(tab);

    // set state
    return {
      pendingResponses: [],
      isLoading: true, // flag turned on/off to show loading image
      post: new Tremr.Models.Post(), // keep a copy of current post
      hasInitialScrolled: false,
      cache: false,
      responsesTab: tab,
      responsesSubTab: subtab,
      relatedCount: 0,
      fixedHeaderbar: false,
      // showWelcomer: true,
      // closedWelcomer: false,
      loadedFeed: false,
      isMounted: false,
      titlebarRecommendOpen: false,
      bodyRecommendOpen: false,
      feed: new Tremr.Models.Feed({ id: this.props.context.feed }),
      doorPolicy: null,
    };
  },

  // prevent clicks on the post background from propagating
  clicked: function (event) {
    // allow A tags with href attribute
    if (
      event.target.nodeName == "A" &&
      event.target.getAttribute("href") &&
      event.target.getAttribute("href") != ""
    ) {
      // let hyperlinks just happen as normal
    } else {
      // check if we are inside an anchor with a href
      var closestA = $(event.target).closest("a, .content");
      if (closestA.length) {
        closestA = closestA.get(0);
      } else {
        closestA = false;
      }
      if (
        closestA &&
        closestA.nodeName == "A" &&
        closestA.getAttribute("href") &&
        closestA.getAttribute("href") != ""
      ) {
        // let hyperlinks just happen as normal
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  },

  // // open in the editor
  // edit: function(event) {
  //
  // 	event.preventDefault();
  // 	event.stopPropagation();
  //
  // 	if (this.state.isLoading) {
  // 		return;
  // 	}
  //
  // 	Tremr.dispatcher.message(this, "tremr:editor:open", {post_ids: this.state.post.get('slug')});
  // },

  // respond
  respond: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    let data = {
      parent: this.state.post,
      onPost: function (post) {
        // close the editor
        Tremr.dispatcher.message(this, "tremr:editor:close");

        // inject the post
        post.unset("ancestors");
        post.set("injected", true);
        if (this.refs["responsesComponent"]) {
          this.refs["responsesComponent"].inject(post.attributes);
        } else {
          // open responses AND inject afterwards - how?
          let pendingResponses = this.state.pendingResponses;
          pendingResponses.push(post.attributes);
          let existingPost = this.state.post;
          existingPost.set(
            "children_count",
            (existingPost.get("children_count") || 0) + 1
          );
          this.setState({
            pendingResponses: pendingResponses,
            expanded: true,
            post: existingPost,
          });
        }
      }.bind(this),
    };
    if (this.state.post.get("channel_name")) {
      data.channel = this.state.channel;
    }

    Tremr.dispatcher.message(this, "tremr:editor:open", data);
  },

  emitAncestor: function () {
    if (this.state.post) {
      // var topAncestor = this.state.post.toJSON();
      let topAncestor = null;
      if (
        this.state.post.get("ancestors") &&
        this.state.post.get("ancestors").length > 0
      ) {
        topAncestor = this.state.post.get("ancestors")[0];
      } else {
        topAncestor = this.state.post.toJSON();
      }
      Tremr.dispatcher.message(
        this,
        "tremr:post:ancestor:changed",
        topAncestor
      );
    }
  },

  emitChannel: function (model) {
    Tremr.dispatcher.message(this, "tremr:post:channel:changed", model);
  },

  loadFollowing: function () {
    // trigger loading of activity for this entity or delay until we have loaded
    // and likely rendered the button
    if (this.state.isLoading) {
      _.delay(() => {
        this.loadFollowing();
      }, 300);
    } else {
      Tremr.dispatcher.message(this, "tremr:follow:forentity", {
        entity: "feed",
        id: this.props.context.feed,
      });
    }
  },

  loadUserfeed: function (userfeedName) {
    // load the data
    var promise = Tremr.stores.feedStore.loadFeed(
      this.state.feed,
      userfeedName,
      this.props.cache
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.setState({
          loadedFeed: true,
          feed: this.state.feed,
        });

        _.defer(() => {
          this.loadFollowing();
        });
      }.bind(this)
    );
  },

  loadFeed: function (feedModel, feedname, cache) {
    // load the data
    var promise = Tremr.stores.feedStore.loadFeed(feedModel, feedname, cache);
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        // if this is a channel then load authorfeed
        if (model.get("channel") == "yes" && model.get("editor_feedname")) {
          // trigger loading of channel for titlebar
          this.emitChannel(model);

          // switch the feed out
          this.setState({
            feed: new Tremr.Models.Feed({ id: model.get("editor_feedname") }),
            channel: model,
          });

          // load the userfeed
          _.defer(() => {
            this.loadUserfeed(model.get("editor_feedname"));
          });
        } else {
          this.setState({ loadedFeed: true, feed: this.state.feed });

          // // trigger loading of activity for this entity
          // Tremr.dispatcher.message(this, "tremr:follow:forentity", {
          // 	entity: 'feed',
          // 	id: this.props.context.feed
          // });
          _.defer(() => {
            this.loadFollowing();
          });
        }

        // set a global highlight colour
        if (model.get("highlight_color")) {
          // document.documentElement.style.setProperty(
          //   "--highlight-color",
          //   model.get("highlight_color")
          // );
          Tremr.setHighlightColor(model.get("highlight_color"));
        } else {
          // document.documentElement.style.setProperty(
          //   "--highlight-color",
          //   Config.colors.highlight
          // );
          Tremr.setHighlightColor(Config.colors.highlight);
        }
      }.bind(this)
    );
  },

  // load post from server when added to dom
  componentDidMount: function () {
    this.setState({
      isMounted: true,
    });

    // window.addEventListener('scroll', this.handleScroll);
    TremrUtils.addPassiveEventListener("scroll", this.handleScroll);
    TremrUtils.addPassiveEventListener("scroll", this.closeTitleBarRecommends);
    TremrUtils.addPassiveEventListener("resize", this.handleScroll);
    // window.addEventListener('deviceorientation', this.handleScroll);
    window.addEventListener("orientationchange", this.handleScroll);

    // load the post
    this.loadData(
      this.props.context.feed,
      this.props.context.id,
      this.state.post,
      this.state.cache
    );

    // until we load se the highlight as grey
    // document.documentElement.style.setProperty("--highlight-color", "#cccccc");
    Tremr.setHighlightColor("#cccccc");

    // load the feed
    this.loadFeed(this.state.feed, this.props.context.feed, this.props.cache);

    // listen to activity areas asking for our ancestor
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:ancestor:changed:request",
      function (ancestor) {
        this.emitAncestor();
      }.bind(this)
    );

    // listen to titlebar asking for channel
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:channel:changed:request",
      function (ancestor) {
        this.emitChannel(this.state.feed);
      }.bind(this)
    );

    // see if this post is deleted
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:delete:success",
      function (post_id) {
        if (this.state.isMounted) {
          // Tremr.closeCurrent();
          // TODO: find something to do when a post is deleted. back?
          console.log("POST DELETED: DO SOMETHING LIKE REDIRECT BACK!");
        }
      }.bind(this)
    );

    // see if this post has been updated
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:save:success",
      function (m) {
        if (this.state.isMounted) {
          if (m.get("_id") == this.state.post.get("_id")) {
            this.setState({
              post: m,
              children_count: m.get("children_count") || 0,
              direct_children_count: m.get("direct_children_count") || 0,
            });

            this.refs["richtext"].setBlocksJson(m.get("content_blocks"));
          }
        }
      }.bind(this)
    );

    // if the editor is closed then ensure our editor is (also?) closed
    this.listenTo(Tremr.dispatcher, "tremr:editor:close", function (
      action,
      data
    ) {
      if (this.refs["editor"]) {
        this.refs["editor"].setState({ open: false });
      }
    });

    // event fired whenever a response is posted, so we can refreh the parent's response list if shown
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:response",
      function (model) {
        var slug = model.get("parent")["slug"];

        // did a response get posted for THIS post
        if (slug == this.state.post.get("slug")) {
          // increment responses
          var newState = {
            children_count: this.state.children_count + 1,
            direct_children_count: this.state.direct_children_count + 1,
          };

          // refresh responses
          if (
            this.state.responsesTab == "direct responses" ||
            this.state.responsesTab == "responses"
          ) {
            if (
              this.refs["responsesComponent"] &&
              this.refs["responsesComponent"].refresh
            ) {
              this.refs["responsesComponent"].refresh();
            }
          } else {
            // switch to responses
            newState.responsesTab = "responses";
          }

          this.setState(newState);
        }
      },
      this
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.closeTitleBarRecommends);
    window.removeEventListener("resize", this.handleScroll);
    // window.removeEventListener('deviceorientation', this.handleScroll);
    window.removeEventListener("orientationchange", this.handleScroll);

    // return hard-coded highlight color
    document.documentElement.style.setProperty(
      "--highlight-color",
      Config.colors.highlight
    );

    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });

    this.stopListening();
  },

  // load data from provided datasource and update state
  loadData: function (feed, id, post) {
    // load data from datasource with promise
    if (this.props.datasource) {
      var promise = this.props.datasource(feed, id, post, this.props.cache);
      this.promises.push(promise);
      promise.then(
        function (params) {
          TremrTracking.logView("post", post.get("_id"));

          // trigger loading of activity in sidebar for ancestor
          this.emitAncestor();

          var newState = {
            isLoading: false,
            post: post,
            children_count: post.get("children_count") || 0,
            direct_children_count: post.get("direct_children_count") || 0,
          };

          // HACK FOR COVID POSTS - check if this is a user post that is actually not reposted into covid-action
          // and force it to load the covid-action channel as the main feed
          let isCovidPost = false;
          post.get("feeds").forEach(function (f) {
            if (f.name == "covid-action" && !f.repost) {
              isCovidPost = true;
            }
          });
          if (isCovidPost) {
            // log a pageview and flag for subsequent tab changes
            let currentUrl = TremrUtils.parseUrl();
            let url = currentUrl.pathname;
            TremrTracking.trackPage("", "/covid-pre-migration" + url);
            newState.isCovidPost = true;

            // load covid channel
            this.loadFeed(this.state.feed, "covid-action", this.props.cache);
          }

          // count the related posts
          var promise = Tremr.stores.postStore.countRelated(
            this.props.context.feed,
            this.props.context.id
          );
          this.promises.push(promise);
          promise.then(
            function (params) {
              var models = params.collection;
              if (models.length > 0) {
                // update and optionally swith tab if no responses
                // if (this.state.responsesTab == 'responses' &&
                // 	post && !post.get('children_count')) {

                // 	this.setState({
                // 		responsesTab: 'related',
                // 		relatedCount: models.models[0].get('count')
                // 	});

                // } else {
                this.setState({ relatedCount: models.models[0].get("count") });
                // }
              }
            }.bind(this)
          );

          // update page meta
          _.defer(function () {
            $("meta[property=og\\:title]").remove();
            $("meta[name=twitter\\:title]").remove();
            $("meta[name=description]").remove();

            if (post.get("title")) {
              $("head").prepend(
                '<meta property="og:title" content="' +
                  post.get("title").replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<meta name="twitter:title" content="' +
                  post.get("title").replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("title").innerHTML =
                post.get("title").replace(/\"/g, "&quot;") + " - Tremr";
            }
            
            $("meta[property=og\\:description]").remove();
            $("meta[name=twitter\\:description]").remove();
            $("meta[property=og\\:type]").remove();

            if (post.get("summary")) {
              $("head").prepend(
                '<meta name="description" content="' +
                  post.get("summary").replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<meta property="og:description" content="' +
                  post.get("summary").replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<meta name="twitter:description" content="' +
                  post.get("summary").replace(/\"/g, "&quot;") +
                  '" />'
              );
            } else if (post.get("generated_summary")) {
              $("head").prepend(
                '<meta name="description" content="' +
                  post.get("generated_summary").replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<meta property="og:description" content="' +
                  post.get("generated_summary").replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<meta name="twitter:description" content="' +
                  post.get("generated_summary").replace(/\"/g, "&quot;") +
                  '" />'
              );
            }
            
            $("head").prepend('<meta property="og:type" content="article" />');
            $("meta[property=article\\:published_time]").remove();
            $("head").prepend(
              '<meta property="article:published_time" content="' +
                post.get("created_at") +
                '" />'
            );
            $("meta[property=article\\:section]").remove();
            $("meta[property=article\\:tag]").remove();
            _.each(post.get("tags"), function (tag, index) {
              var tagtype = "tag";
              if (index == 0) {
                tagtype = "section";
              }
              $("head").prepend(
                '<meta property="article:' +
                  tagtype +
                  '" content="' +
                  tag.replace(/\"/g, "&quot;") +
                  '" />'
              );
            });
            $("meta[name=twitter\\:card]").remove();
            $("head").prepend(
              '<meta name="twitter:card" content="summary_large_image" />'
            );
            $("meta[name=twitter\\:creator]").remove();
            if (post.get("author_twitter")) {
              $("head").prepend(
                '<meta name="twitter:creator" content="' +
                  post.get("author_twitter") +
                  '" />'
              );
            }

            $("meta[property=og\\:image]").remove();
            $("meta[name=twitter\\:image]").remove();
            $("link[rel=image_src]").remove();

            let image_url = false;
            let image_width = false;
            let image_height = false;
            if (post.get("image")) {
              image_url =
                TremrSettings.cloudinary_url +
                "image/upload/f_auto,q_90,w_800,h_600,c_fill,g_face/" +
                post.get("image").public_id +
                ".jpg";
              image_width = 800;
              image_height = 600;
            } else if (post.get("embed")) {
              if (post.get("embed").thumbnail_url) {
                image_url = post.get("embed").thumbnail_url;
                image_width = post.get("embed").thumbnail_width;
                image_height = post.get("embed").thumbnail_height;
              } else if (
                post.get("embed").type == "photo" &&
                post.get("embed").url
              ) {
                image_url = post.get("embed").url;
                image_width = post.get("embed").width;
                image_height = post.get("embed").height;
              }
            }
            if (image_url) {
              $("head").prepend(
                '<meta property="og:image" content="' +
                  image_url.replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<meta property="og:image:width" content="' +
                  image_width +
                  '" />'
              );
              $("head").prepend(
                '<meta property="og:image:height" content="' +
                  image_height +
                  '" />'
              );
              $("head").prepend(
                '<meta name="twitter:image" content="' +
                  image_url.replace(/\"/g, "&quot;") +
                  '" />'
              );
              $("head").prepend(
                '<link rel="image_src" href="' +
                  image_url.replace(/\"/g, "&quot;") +
                  '" />'
              );
            }

            // update add this
            if (
              typeof addthis !== "undefined" &&
              addthis.layers &&
              addthis.layers.refresh
            ) {
              addthis.layers.refresh();
            }
          });

          // must be last line called as no subsequent lines execute - why is not clear!
          // presuming a react optimisation
          this.setState(newState);
        }.bind(this),
        function (params) {
          // clear loading state
          this.setState({ isLoading: false });
        }.bind(this)
      );
    }
  },

  // let the wall tell us when it has loaded data
  breadcrumbsLoaded: function () {
    // scroll to initial position, once only
    if (
      this.state.responsesLoaded &&
      this.props.context &&
      this.props.context.initialScrollPos &&
      this.state.hasInitialScrolled == false
    ) {
      this.scrollToPosition();
    } else {
      this.setState({ breadcrumbsLoaded: true });
    }
  },

  // let the breadcrumbs tell us when the data is loaded
  responsesLoaded: function () {
    // scroll to initial position, once only
    if (
      this.state.breadcrumbsLoaded &&
      this.props.context &&
      this.props.context.initialScrollPos &&
      this.state.hasInitialScrolled == false
    ) {
      this.scrollToPosition();
    } else {
      this.setState({ responsesLoaded: true });
    }
  },

  scrollToPosition: function () {
    var domNode = ReactDOM.findDOMNode(this);
    $(domNode)
      .closest(".primary")
      .scrollTop(this.props.context.initialScrollPos);

    this.setState({ hasInitialScrolled: true });
  },

  // updates pushed to DOM, so do a render of media components in content
  componentDidUpdate: function () {
    // check scroll positions
    // this.handleScroll();  not clear why this is needed - causes looping setState->componentDidUpdate

    // inject pending
    let pendingResponses = this.state.pendingResponses;
    if (pendingResponses && pendingResponses.length > 0) {
      if (this.refs["responsesComponent"]) {
        pendingResponses.forEach(
          function (post) {
            this.refs["responsesComponent"].inject(post);
          }.bind(this)
        );
        _.defer(() => {
          this.setState({
            pendingResponses: [],
          });
        });
      }
    }

    // iterate embeds replacing each one
    var embedded = this.state.post.get("embedded");
    if (this.state.post && embedded) {
      _.each(
        embedded,
        function (definition) {
          var component = false;

          if (definition.resource_type && definition.resource_type == "image") {
            component = (
              <Tremr.Generic.Image
                image={definition}
                maxHeight={580}
                height={false}
                width="auto"
                resizable={true}
              />
            );
          } else if (true) {
            // component = <Tremr.Generic.Embed embed={definition} maxHeight={580} height={false} width="auto" />;
            component = (
              <Tremr.Generic.Embed
                embed={definition}
                maxHeight={1580}
                height="auto"
                width="auto"
                resizable={true}
              />
            );
          }

          if (component) {
            var node = ReactDOM.findDOMNode(this);
            var domnode = $(node).find(".post-content #" + definition.embed_id);
            if (domnode && domnode.length) {
              ReactDOM.render(component, domnode.get(0));
            }
          }
        }.bind(this)
      );
    }
  },

  scrollToRespond: function (event) {
    if (this.state.isMounted) {
      var node = $(ReactDOM.findDOMNode(this));
      var container = node.closest(".primary");
      var responses = node.find(".responses");
      if (container && responses) {
        container.scrollTo(responses, { duration: "200ms" });
      }
    }
  },

  // pas load more requests on to the wall
  loadMore: function (event) {
    if (this.refs["responsesComponent"]) {
      this.refs["responsesComponent"].loadMore(event);
    }
  },

  // switch the responses tab
  switchResponsesTab: function (event) {
    event.preventDefault();
    event.stopPropagation();

    let tab = $(event.currentTarget).attr("data-value");
    let subtab = this.getDefaultSubTab(tab);

    let currentUrl = TremrUtils.parseUrl();
    currentUrl.params.tab = tab;
    currentUrl.params.subtab = null;
    let newUrl = TremrUtils.createUrlString(currentUrl);
    Tremr.routes.updateUrl(newUrl, true);

    if (this.state.isCovidPost) {
      TremrTracking.trackPage("", "/covid-pre-migration" + newUrl);
    }

    this.setState({
      responsesTab: tab,
      responsesSubTab: subtab,
    });
  },

  scrollTop: function (event) {
    event.stopPropagation();
    event.preventDefault();
    var node = ReactDOM.findDOMNode(this);
    var container = $(node).closest(".primary");
    $(container).scrollTo(0, { duration: 500 });
  },

  // closeWelcomer: function() {
  //
  // 	// Cookies.set('has_closed_welcomer', '1');
  // 	Cookies.set('has_closed_welcomer', '1', {expires: 1});
  //
  // 	this.setState({showWelcomer: false, closedWelcomer: true});
  // },

  handleScroll: function (event) {
    if (this.state.isMounted == false) {
      return;
    }

    let scrollTop = window.scrollY;

    // watch scroll and direction
    let scrollDirection = scrollTop - this.lastScrollPosition;
    this.lastScrollPosition = scrollTop;

    // find positions on-page of elements we're using to check scroll against
    let postNode = ReactDOM.findDOMNode(this);
    let headerbarNode = ReactDOM.findDOMNode(this.refs["byline"]);
    let responsesMenuNode = ReactDOM.findDOMNode(
      this.refs["responsesContainer"]
    );

    // get their positions the first time only in case we move them
    let headerbarStaticTop = this.state.headerbarStaticTop;
    if (!headerbarStaticTop && headerbarNode) {
      headerbarStaticTop = headerbarNode.offsetTop;
    }
    let responsesMenuStaticTop = this.state.responsesMenuStaticTop;
    if (!responsesMenuStaticTop) {
      responsesMenuStaticTop = responsesMenuNode.offsetTop;
    }

    // some variables for positioning things
    let viewableHeight = window.innerHeight - 128; //include offset for page header + post header
    const titlebarHeight = 74;
    const nonfixedheaderHeight = 60;
    const nonfixedresponsesMenuHeight = 60;

    if (postNode) {
      let postBounds = postNode.getBoundingClientRect();

      // see if we have scrolled at all
      if (
        headerbarNode &&
        responsesMenuNode &&
        scrollTop > postNode.offsetTop
      ) {
        // once we have scrolled, decide which state we should be in

        // if (scrollTop + viewableHeight > responsesMenuStaticTop && scrollTop + titlebarHeight <= responsesMenuStaticTop) {
        //   responses menu is ON the page
        // 	this.setState({
        // 		scrollPosition: 'scroll-wall',
        // 		scrollDirection: scrollDirection > 0 ? 'scroll-down' : 'scroll-up'
        // 		postBounds: postBounds
        // 	});

        // } else
        if (
          scrollTop + window.innerHeight >
          responsesMenuStaticTop + nonfixedresponsesMenuHeight
        ) {
          // we have scrolled past the responses menu
          this.setState({
            scrollPosition: "scroll-wall",
            scrollDirection: scrollDirection > 0 ? "scroll-down" : "scroll-up",
            postBounds: postBounds,
          });
        } else if (scrollTop > headerbarStaticTop + nonfixedheaderHeight) {
          // we have scrolled past the headerbar
          this.setState({
            scrollPosition: "scroll-post",
            scrollDirection: scrollDirection > 0 ? "scroll-down" : "scroll-up",
            postBounds: postBounds,
          });
        } else {
          // scrolled but not past the headerbar
          this.setState({
            scrollPosition: "scroll-none",
            scrollDirection: scrollDirection > 0 ? "scroll-down" : "scroll-up",
            postBounds: postBounds,
          });
        }
      } else {
        this.setState({
          scrollPosition: "scroll-none",
          scrollDirection: scrollDirection > 0 ? "scroll-down" : "scroll-up",
          postBounds: postBounds,
        });
      }
    }
  },

  openFeed: function (event) {
    var feedContext = {
      target: "feed",
      scope: "featured",
      feed: this.state.post.get("author_feed"),
    };

    this.openContext(event, feedContext);
  },

  // open a tag when clicked
  openTag: function (event) {
    var tag = event.currentTarget.getAttribute("data-tag");
    var tagContext = {
      target: "tag",
      tag: tag,
    };

    this.openContext(event, tagContext);
  },

  // navigate to context
  openContext: function (event, context) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: context });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    Tremr.navigatePrimary(context);
  },

  openPostMenu: function (event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    let url = Tremr.routes.urlFor({ primary: this.props.context });
    let imageUrl = null;
    if (this.state.post.get("image")) {
      imageUrl = this.state.post.get("image").secure_url;
    } else if (this.state.post.get("embed")) {
      if (this.state.post.get("embed").thumbnail_url) {
        imageUrl = this.state.post.get("embed").thumbnail_url;
      } else {
        imageUrl = this.state.post.get("embed").original_url;
      }
    }
    let summary = "";
    if (this.state.post.get("summary")) {
      summary = this.state.post.get("summary").replace(/&[^;]{2,3,4};/g, " ");
    }

    var overlayData = {
      class: "Tremr.Post.Menu",
      style: "sidebar",
      props: {
        post_id: this.state.post.get("_id"),
        post_slug: this.state.post.get("slug"),
        author_id: this.state.post.get("author_id"),
        post_author: this.state.post.get("author_name"),
        post_title: this.state.post.get("title"),
        post_url: url,
        post_imageUrl: imageUrl,
        post_summary: summary,
        isFactChecked:
          this.state.post.get("factcheck") &&
          this.state.post.get("factcheck")["set"] == true,
      },
    };
    Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
  },

  // closeReactions: function(event) {
  //
  //     this.setState({
  //         recommendClosed: true
  //     });
  // },

  openBodyRecommends: function () {
    this.setState({
      bodyRecommendOpen: true,
    });
  },

  openTitleBarRecommends: function () {
    this.setState({
      titlebarRecommendOpen: true,
    });
  },

  closeTitleBarRecommends: function () {
    this.setState({
      titlebarRecommendOpen: false,
    });
  },

  // produce the page
  render: function () {
    // have we got old v1 content?
    var isV1 = this.state.post.get("v") == "1";

    // component for responses
    if (
      this.props.context === undefined ||
      this.props.context.id === undefined
    ) {
      console.log("No context defined:");
      console.dir(this.props);
    }

    // content we only want once loaded
    let actions = null;
    let actioncounts = null;
    let reactions = null;
    let breadcrumbs = null;
    let mediagallery = null;
    let responses = null;
    let responsesMenu = null;
    // let editButton = null;
    let editor = null;
    let wordCount = null;
    let tags = null;
    let factcheck_badge = null;
    let byline = null;
    let tagline = null;
    let contentsummary = null;
    let title = null;
    // let welcomer = null;
    let replyPrompt = null;
    let relatedPosts = null;
    let created_at = null;
    // let reactRespondTitle = null;

    // four possible header/footer containers
    let headerbarByline = null;
    let headerbarResponses = null;
    let footerResponses = null;
    let footerResponsesSort = null;

    if (this.state.isLoading === false) {
      // check scroll to ensure we have drawn the post and measured its position
      if (!this.state.postBounds) {
        _.defer(() => {
          this.handleScroll();
        });
      }

      if (this.state.post.get("fieldset") == "article") {
        title = <h1>{this.state.post.get("title")}</h1>;
      } else {
        title = <div className="comment">{this.state.post.get("title")}</div>;
      }

      // let created = moment(this.props.post.get('created_at'), "YYYY-MM-DDTHH:mmZ");
      // let yearDiff = parseInt(moment().format("YYYY")) - parseInt(created.format("YYYY"));
      // let age = created.format("D MMM");
      // if (yearDiff > 0) {
      //    age = created.format("MMM'YY");
      // }
      // let timestamp = created.format();
      // let isToday = (created.format("D M YY") == moment().format("D M YY"));
      // if (isToday) {
      //     age = created.format("H:mma");
      // }
      let created = moment(this.state.post.get("created_at")).format(
        "H:mma, Do MMMM YYYY"
      );
      let timestamp = moment(this.state.post.get("created_at")).format();
      created_at = <time dateTime={timestamp}>{created}</time>;

      // show edit button if author OR admin
      // var user = this.state.user;
      // if (user && (user.get('admin') == 'yes' || user.get('_id') == this.state.post.get('author_id'))) {
      //
      // 	if (!isV1) {
      // 		editButton = <button title="Edit Post" className="edit activity-button dropup-item" onClick={this.edit}>
      // 			<Tremr.Generic.SvgIcon key="icon" icon="edit" classes="button icon"/>
      // 			<div key="description" className="description">
      // 				<h6>
      // 					<em>Edit</em> this post</h6>
      // 				<p>Make changes or delete post</p>
      // 			</div>
      // 		</button>;
      // 	}
      // }

      // if (!this.state.user && Cookies.get('has_signed_in') != '1' && Cookies.get('has_closed_welcomer') != '1') {
      //
      // 	var tagtext = "Tremr";
      // 	if (this.state.post.get('tags') && this.state.post.get('tags').length > 1) {
      // 		tagtext = this.state.post.get('tags')[1];
      // 	} else if (this.state.post.get('tags').length == 1) {
      // 		tagtext = this.state.post.get('tags')[0];
      // 	}
      // welcomer = <Tremr.Layout.Welcomer tag={tagtext} visible={this.state.showWelcomer} close={this.closeWelcomer}/>;
      // }

      tags = (
        <Tremr.Tag.List tags={this.state.post.get("tags")} commas={false} />
      );

      if (
        this.state.post.get("contentsummary") &&
        this.state.post.get("contentsummary")["total"] > 0
      ) {
        contentsummary = <ContentSummary post={this.state.post} />;
      } else if (
        this.state.post.get("wordcount") &&
        this.state.post.get("wordcount") > 0
      ) {
        contentsummary = (
          <div className="post-contentsummary">
            {this.state.post.get("wordcount").toLocaleString()} words
          </div>
        );
      }

      tagline = (
        <div className="tagline">
          {this.state.post.get("summary")
            ? this.state.post.get("summary").replace(/&[^;]{2,3,4};/g, " ")
            : ""}
        </div>
      );

      let isFactChecked =
        this.state.post.get("factcheck") &&
        this.state.post.get("factcheck")["set"] == true;
      factcheck_badge = (
        <FactcheckBadge
          post_id={this.state.post.get("_id")}
          set={isFactChecked}
        />
      );

      // reactRespondTitle = <div className="react-title">React / Respond…</div>;

      // if (this.state.responsesTab == 'direct responses') {
      // 	responses = Tremr.stores.postStore.getResponsesWall('direct responses', this.props.context.feed, this.props.context.id, this.props.cache, this.responsesLoaded, this.scrollToRespond,null,null,"wallStyleResponses");
      // } else
      if (this.state.post.get("children_count") > 0) {
        if (this.state.responsesTab == "responses") {
          responses = Tremr.stores.postStore.getResponsesWall(
            "direct responses",
            this.props.context.feed,
            this.props.context.id,
            this.props.cache,
            this.responsesLoaded,
            this.scrollToRespond,
            null,
            null,
            "wallStyleResponses",
            this.state.responsesSubTab || "Chronological"
          );
        } else if (this.state.responsesTab == "contributors") {
          // get contributors from top ancestor
          let contributors_slug = {
            feed: this.props.context.feed,
            id: this.props.context.id,
          };
          let ancestors = this.state.post.get("ancestors");
          if (ancestors && ancestors.length > 0) {
            let ancestors_slug = ancestors[0].slug.split("!");
            contributors_slug = {
              feed: ancestors_slug[0],
              id: ancestors_slug[1],
            };
          }
          responses = Tremr.stores.postStore.getContributorsWall(
            contributors_slug.feed,
            contributors_slug.id,
            this.props.cache,
            this.responsesLoaded,
            this.scrollToRespond,
            null,
            this.state.responsesSubTab || "Latest"
          );
          // } else if (this.state.responsesTab == 'related') {
          // 	responses = Tremr.stores.postStore.getRelatedWall(this.props.context.feed, this.props.context.id, this.props.cache, this.responsesLoaded, this.scrollToRespond, null, null, "wallStyleResponses");
        }

        let responseStats = {
          direct: this.state.direct_children_count,
          all: this.state.children_count,
          related: this.state.relatedCount,
          contributors: this.state.post.get("contributors_count"),
        };

        responsesMenu = (
          <ResponsesMenu
            key={
              "response-menu-" +
              this.state.responsesTab +
              "-" +
              this.state.responsesSubTab
            }
            ref="responsesMenu"
            level={0}
            feed={this.props.context.feed}
            post_id={this.props.context.id}
            switchTab={this.switchResponsesTab}
            counts={responseStats}
            currentTab={this.state.responsesTab}
            initialSubTab={this.state.responsesSubTab}
          />
        );
      }

      if (this.state.post.get("image") || this.state.post.get("embed")) {
        mediagallery = <MediaGallery post={this.state.post} />;
      } else {
        mediagallery = <hr />;
      }

      if (
        this.state.post.get("ancestors") &&
        this.state.post.get("ancestors").length > 0
      ) {
        breadcrumbs = (
          <Breadcrumbs
            identifier={"post-page-" + this.state.post.get("slug")}
            loadedCallback={this.breadcrumbsLoaded}
            context={this.props.context}
            cache={this.props.cache}
            posts={this.state.post.get("ancestors")}
          />
        );
      }

      let url = Tremr.routes.urlFor({ primary: this.props.context });
      let imageUrl = null;
      if (this.state.post.get("image")) {
        imageUrl = this.state.post.get("image").secure_url;
      } else if (this.state.post.get("embed")) {
        if (this.state.post.get("embed").thumbnail_url) {
          imageUrl = this.state.post.get("embed").thumbnail_url;
        } else {
          imageUrl = this.state.post.get("embed").original_url;
        }
      }

      let bookmarkDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
        this.state.post.get("_id"),
        ["bookmark"]
      );
      let repostDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
        this.state.post.get("_id"),
        ["repost"]
      );
      let upvoteDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
        this.state.post.get("_id"),
        ["recommend"]
      );
      let postScoreDataPoints = Tremr.stores.activityStore.getActivityDataPoints(
        this.state.post.get("_id"),
        ["recommend", "repost", "bookmark"],
        { set: false, sum: true }
      );

      actions = (
        <div className="actions">
          <Share
            imageUrl={imageUrl}
            url={url}
            title={
              this.state.post.get("title")
                ? this.state.post
                    .get("title")
                    .capitalize()
                    .replace(/&[^;]{2,3,4};/g, " ")
                : ""
            }
            description={
              this.state.post.get("summary")
                ? this.state.post.get("summary").replace(/&[^;]{2,3,4};/g, " ")
                : ""
            }
          />
          <ActivitySumWithFBData
            post_id={this.state.post.get("_id")}
            onClick={this.openBodyRecommends}
            dataPoints={postScoreDataPoints}
            tags={["recommend", "repost", "bookmark"]}
          />
          <UpvoteButtonWithFBData
            post_id={this.state.post.get("_id")}
            dataPoints={upvoteDataPoints}
            tag="recommend"
            onChange={this.openBodyRecommends}
          />
          <RepostButtonWithFBData
            onClick={this.openBodyRecommends}
            dataPoints={repostDataPoints}
            tag="repost"
            post_id={this.state.post.get("_id")}
            post_title={this.state.post.get("title")}
            post_author={this.state.post.get("author_name")}
          />
          <ActivityIconButtonWithFBData
            post_id={this.state.post.get("_id")}
            onClick={this.openBodyRecommends}
            dataPoints={bookmarkDataPoints}
            tag="bookmark"
          />
          <SvgIcon
            key="openPostMenu"
            classes="post-menu-icon icon"
            icon="menu"
            onClick={this.openPostMenu}
          />
        </div>
      );

      reactions = (
        <Reactions
          onOpen={this.openBodyRecommends}
          open={this.state.bodyRecommendOpen}
          post_id={this.state.post.get("_id")}
        />
      );

      let highlightColor = Config.colors.highlight;
      if (this.state.loadedFeed && this.state.feed) {
        if (this.state.feed.get("highlight_color")) {
          highlightColor = this.state.feed.get("highlight_color");
        }
      }
      byline = (
        <Byline
          ref="byline"
          highlightColor={highlightColor}
          feed={this.state.feed}
          subfeeds={
            this.state.loadedFeed ? this.state.feed.get("subfeeds") : []
          }
          avatar_size={40}
          showFollow={this.state.loadedFeed}
          post={this.state.post}
          subheaderContent={this.state.post.get("author_bio")}
        ></Byline>
      );

      var getHTML = function () {
        var content = this.state.post.get("content") || "";
        return { __html: content };
      }.bind(this);

      var postContent = null;
      if (this.state.post.get("content_blocks")) {
        // new posts use content_blocks
        postContent = (
          <Tremr.Editor.Richtext
            margins={30}
            initialBlocks={this.state.post.get("content_blocks")}
            key="richtext"
            ref="richtext"
          />
        );
      } else {
        postContent = (
          <div className="post-content" dangerouslySetInnerHTML={getHTML()} />
        );
      }

      // morebyauthor = <MoreByAuthor author_name={this.state.post.get('author_name')} feed={this.state.post.get('channel_author_feed') || this.state.post.get('author_feed')} post_id={this.state.post.get('post_id')} cache={this.state.cache}/>;
      if (this.state.post.get("post_id")) {
        relatedPosts = (
          <RelatedPosts
            feed={this.state.post.get("author_feed")}
            post_id={this.state.post.get("post_id")}
            cache={this.state.cache}
          />
        );
      }
      // show response editor
      // editor = <Tremr.Editor.ResponseEditor ref="editor" parent={this.state.post} />;

      // set-up our four possible bars
      let authorUrl = Tremr.routes.urlFor({
        primary: {
          target: "feed",
          scope: "featured",
          feed: this.state.post.get("author_feed"),
        },
      });

      let authorAvatarImage = "";
      if (this.state.post.get("author_avatar_image")) {
        authorAvatarImage = (
          <Tremr.Generic.Image
            image={this.state.post.get("author_avatar_image")}
            height={36}
            width={36}
          />
        );
      }
      let authorAvatar = (
        <a className="avatar" onClick={this.openFeed} href={authorUrl}>
          {authorAvatarImage}
        </a>
      );
      let authorName = (
        <a className="name" onClick={this.openFeed} href={authorUrl}>
          {this.state.post.get("author_name")}
        </a>
      );

      let logo = (
        <a href="/" className="logo">
          <Logo
            baseColor="#5f5c6a"
            highlightColor="#5f5c6a"
            width={"auto"}
            height={"32"}
          />
        </a>
      );

      let scrollToTop = function (event) {
        window.scrollTo(0, 0);
      }.bind(this);

      headerbarByline = (
        <div className="toolbar post-toolbar">
          <div className="toolbar-content">
            <Share
              imageUrl={imageUrl}
              url={url}
              title={
                this.state.post.get("title")
                  ? this.state.post
                      .get("title")
                      .capitalize()
                      .replace(/&[^;]{2,3,4};/g, " ")
                  : ""
              }
              description={
                this.state.post.get("summary")
                  ? this.state.post
                      .get("summary")
                      .replace(/&[^;]{2,3,4};/g, " ")
                  : ""
              }
            />

            <div className="title-container" onClick={scrollToTop}>
              <div className="title">
                {this.state.post.get("title")
                  ? this.state.post
                      .get("title")
                      .capitalize()
                      .replace(/&[^;]{2,3,4};/g, " ")
                  : ""}
              </div>
            </div>

            <div className="actions">
              <ActivitySumWithFBData
                post_id={this.state.post.get("id")}
                onClick={this.openTitleBarRecommends}
                dataPoints={postScoreDataPoints}
                tags={["recommend", "repost", "bookmark"]}
              />
              <UpvoteButtonWithFBData
                post_id={this.state.post.get("id")}
                dataPoints={upvoteDataPoints}
                tag="recommend"
                onChange={this.openTitleBarRecommends}
              />
              <RepostButtonWithFBData
                onClick={this.openTitleBarRecommends}
                dataPoints={repostDataPoints}
                tag="repost"
                post_id={this.state.post.get("_id")}
                post_title={this.state.post.get("title")}
                post_author={this.state.post.get("author_name")}
              />
              <ActivityIconButtonWithFBData
                post_id={this.state.post.get("id")}
                onClick={this.openTitleBarRecommends}
                dataPoints={bookmarkDataPoints}
                tag="bookmark"
              />
              <SvgIcon
                key="openPostMenu"
                classes="post-menu-icon icon"
                icon="menu"
                onClick={this.openPostMenu}
              />
            </div>
          </div>
          <div className="toolbar-content">
            <Reactions
              ref="toolbarReactions"
              onOpen={this.openTitleBarRecommends}
              open={this.state.titlebarRecommendOpen}
              post_id={this.state.post.get("_id")}
            />
          </div>
        </div>
      );

      // headerbarResponses = <div className="toolbar wall-toolbar">
      // 	<ResponsesMenu key={"toolbar-response-menu-"+this.state.responsesTab+"-"+this.state.responsesSubTab} ref="headerbarResponses" level={1} feed={this.props.context.feed} post_id={this.props.context.id} switchTab={this.switchResponsesTab} counts={responseStats} currentTab={this.state.responsesTab} initialSubTab={this.state.responsesSubTab} />
      // </div>;

      let footerStyles = {
        // display: 'none'
      };
      // if (this.state.postBounds) {
      //    footerStyles = {
      //       left: this.state.postBounds.left,
      //       width: this.state.postBounds.width
      //    };
      // }
      var scrollToResponses = function () {
        // console.log("scrollToResponses");
        let responsesMenuNode = ReactDOM.findDOMNode(
          this.refs["responsesContainer"]
        );
        $(window).scrollTo(responsesMenuNode, {
          axis: "y",
          offset: -35,
          duration: 500,
        });
      }.bind(this);
      let count = null;
      if (
        this.state.post.get("children_count") &&
        this.state.post.get("children_count") > 0
      ) {
        count = (
          <div className="count" key="count">
            {this.state.post.get("children_count")}
          </div>
        );
        footerResponses = (
          <div className="responses-button-container">
            <div className="responses-button" onClick={scrollToResponses}>
              {count}
            </div>
            <Tremr.Generic.SvgIcon icon="response-pointer" />
          </div>
        );
      }

      // <ResponsesMenu ref="footerResponsesSort" level={2} feed={this.props.context.feed} post_id={this.props.context.id} switchTab={this.switchResponsesTab} counts={responseStats} currentTab={this.state.responsesTab} />
      if (this.state.responsesTab == "responses") {
        footerResponsesSort = (
          <div className="footer wall-footer" style={footerStyles}>
            <WallControls wallStyleProperty="wallStyleResponses" />
          </div>
        );
      }

      let channel_feed = null;
      if (this.state.post?.get('channel_title')) {
        channel_feed = this.state.post.get('author_feed');

        // check if this is only state the community subfeed
        let memberFeeds = this.state.post.get('feeds').filter((feed) => {
          return (feed['name'] == channel_feed && feed['subfeed'] != 'community');
        });
        
        if (!memberFeeds || memberFeeds.length == 0) {
          channel_feed = null;
        }
      }          
      
      replyPrompt = (
        <ReplyPromptWithFBData
          dataPoints={Tremr.stores.postStore.getDoorPolicyDataPoints(
            this.state.post.get("_id"), channel_feed
          )}
          onClick={this.respond}
          authorName={this.state.post.get("author_name")}
        />
      );
    } else {
      // loading
      let divStyle = {
        minHeight: "100vh",
      };
      postContent = (
        <div className="dummycontent" style={divStyle}>
          <div className="block extra-margin">
            <div className="avatar"></div>
            <div className="block veritcal">
              <div className="content w-30"></div>
              <div className="content w-60"></div>
            </div>
          </div>
          <div className="content fat extra-margin w-90"></div>
          <div className="content w-100"></div>
          <div className="content w-90"></div>
          <div className="content w-100"></div>
          <div className="content w-90"></div>
        </div>
      );
    }

    let newsletterPromo = null;
    if (
      this.state.channel &&
      this.state.channel.get("has_newsletter") &&
      this.state.channel.get("has_newsletter") != "no" &&
      this.state.channel.get("newsletter_promoted") &&
      this.state.channel.get("newsletter_promoted") != "no" &&
      this.state.channel.get("newsletter_promo") &&
      this.state.channel.get("newsletter_promo").length > 0 &&
      this.state.channel.get("newsletter") &&
      this.state.channel.get("newsletter").length > 0
    ) {
      newsletterPromo = (
        <a
          className="block text-block newsletter-promo"
          target="_blank"
          href={this.state.channel.get("newsletter")}
        >
          {this.state.channel.get("newsletter_promo")}
        </a>
      );
    }

    // work out our top level class
    var classes = {
      "post-show": true,
      post: true,
      loading: this.state.isLoading,
      image: this.state.post && this.state.post.get("image"),
      embed: this.state.post && this.state.post.get("embed"),
      v1: isV1,
    };
    if (this.state.post.get("content_blocks")) {
      classes[
        "content-blocks-" + this.state.post.get("content_blocks").length
      ] = true;
    }
    if (this.state.post.get("post_type")) {
      classes[this.state.post.get("post_type")] = true;
    }
    if (this.state.scrollPosition) {
      classes[this.state.scrollPosition] = true;
    }
    if (this.state.scrollDirection) {
      classes[this.state.scrollDirection] = true;
    }

    classes = classNames(classes);

    // render html
    return (
      <div className={classes}>
        {headerbarByline}

        <div className="article-container">
          {breadcrumbs}

          <article onClick={this.clicked}>
            {byline}

            {tags}

            {factcheck_badge}

            {title}

            {tagline}

            {created_at}

            {contentsummary}

            {actions}

            {reactions}

            {/* actioncounts */}

            {mediagallery}

            <section className="post-show-content">
              {postContent}

              {newsletterPromo}
            </section>

            {replyPrompt}
          </article>

          {relatedPosts}
        </div>

        {responsesMenu}

        <div ref="responsesContainer" className="post-show-responses">
          {responses}
        </div>

        {footerResponses}
        {footerResponsesSort}
      </div>
    );
  },
});
