require("./scss/expandbutton.scss");

var CreateReactClass = require("create-react-class");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  render: function () {
    return (
      <div
        className="expand"
        style={this.props.styles}
        onClick={this.props.onClick}
      >
        <div className="outer">
          <div className="inner">
            <Tremr.Generic.SvgIcon icon="fat-chevron" fill={true} />
          </div>
        </div>
      </div>
    );
  },
});
