// use firebase to display count of total posts minus the number the signed-in user has seen
require("./scss/unseencount.scss");

var UserAwareMixin = require('../../mixins/userawaremixin');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var FirebaseEncode = require('firebase-encode');

// import {
//   encode,
//   decode,
//   encodeComponents,
//   decodeComponents,
// } from 'firebase-encode';


module.exports = CreateReactClass({

    // mixins: [PureRenderMixin, UserAwareMixin],
    mixins: [UserAwareMixin],

    propTypes: {
        total_path: PropTypes.string.isRequired,
        seen_path: PropTypes.string
    },

    getInitialState: function() {
    	return {
    		total: 0,
    		seen: 0
    	};
    },

    componentDidMount: function() {

        if (!this.stopListening) {
          _.extend(this, Backbone.Events);
        }

        // listen for sign-in/out and update user in our session
        this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function(user) {
            this.bindToFirebase();
        });

        if (this.state.user && this.state.user.get('_id')) {
            this.bindToFirebase();
        }
    },

    // componentDidUpdate: function() {
    //     this.bindToFirebase();
    // },

    componentWillUnmount: function() {

        if (this.stopListening) {
          this.stopListening();
        }

        // unbind from firebase
    	if (this.state.fbTotalRef) {
            this.state.fbTotalRef.off();
        }
        if (this.state.fbSeenRef) {
            this.state.fbSeenRef.off();
        }

    },


    // bind to firebase
    bindToFirebase: function() {

        let fbTotalRef = null;
        let fbSeenRef = null;

        if ((!this.state.fbSeenRef) && (!this.state.fbTotalRef) && this.props.total_path && this.state.user) {

            let key = this.props.total_path.replace(/\{user\}/, this.state.user.get('_id'));
            key = FirebaseEncode.encodeComponents(key);

            fbTotalRef = Tremr.firebase.database().ref(key);
            fbTotalRef.on('value', function(snapshot) {
                let data = snapshot.val();

                if (!data) {
                    data = 0;
                }
                this.setState({
                    total: data
                });
            }.bind(this));

            fbSeenRef = Tremr.firebase.database().ref(this.props.seen_path.replace(/\{user\}/, this.state.user.get('_id')));
            fbSeenRef.on('value', function(snapshot) {
              let data = snapshot.val();

              if (!data) {
                data = 0;
              }
              this.setState({
                seen: data
              });
            }.bind(this));
        }

        // add to state
        if (fbTotalRef || fbSeenRef) {

            let newState = {};

            if (fbTotalRef) {
                newState.fbTotalRef = fbTotalRef;
            }
            if (fbSeenRef) {
                newState.fbSeenRef = fbSeenRef;
            }

            this.setState(newState)
        }
    },

    // display
    render: function() {

        let count = this.state.total - this.state.seen;
    	if (count <= 0) {
    		return null;
    	}
        return <div className="unseencount">
            {count}
        </div>;
    }
});
