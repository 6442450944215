var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');

// contains a component but provides markup for background of needed etc.
// also has properties for modal and replacable
// Tremr.Layout.Overlay = CreateReactClass({
module.exports = CreateReactClass({

  // mixins: [PureRenderMixin],

  getDefaultProps: function() {
    return {
      modal: false,
      replace: false,
      handleClose: false
    }
  },

  handleClose: function(event) {
    if (this.props.handleClose) {
      this.props.handleClose(this.props.index);
    } else {
      Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
    }
  },

  suppressClose: function(event) {
    event.preventDefault();
    event.stopPropagation();
  },

  render: function() {

  	let classes = {
      'layout-overlay': true,
      'modal': this.props.modal,
      'replace': this.props.replace
    };
    classes[this.props.content.replace(/\./g, '--')] = true;

    let props = this.props.props;
    if (!props) {
      props = {};
    }
    props = _.extend({
        index: this.props.index,
        onClose: this.handleClose,
        ref: "overlayContnent"
    }, props);

    let component = React.createElement(Tremr.Utils.stringToFunction(this.props.content), props);
    let closeButton = <a className="close" onClick={this.handleClose}>
      <Tremr.Generic.SvgIcon icon="cross" />
    </a>;

    if (this.props.style == 'sidebar') {

        closeButton = null;
        classes['overlay-sidebar'] = true;
        component = <div className="overlay" onClick={this.handleClose}>
            <div onClick={this.suppressClose}>
                {component}
            </div>
        </div>;

    } else {

    }

    classes = classNames(classes);

    return <div className={classes}>
        {closeButton}
        {component}
    </div>;

  }

});
