require("./scss/repost_menu.scss");

var classNames = require("../../utils/classnames");
var PopUp = require("../generic/popup");
var Subfeeds = require("./subfeeds");
var TremrUtils = require("../../utils/tremr_utils");
var IconButton = require("../generic/iconbutton");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var Channels = require("../../models/Channels");
var UserAwareMixin = require("../../mixins/userawaremixin");
var SvgIcon = require("../generic/svgicon");
var TremrTracking = require("../../utils/tracking");
var alertify = require("alertify");

// import {
//   encode,
//   decode,
//   encodeComponents,
//   decodeComponents,
// } from 'firebase-encode';

module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  promises: [], // so we can cancel on unmount

  propTypes: {
    post_id: PropTypes.string.isRequired,
    post_author: PropTypes.string.isRequired,
    post_title: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return {
      userSet: false,
      selectedSubfeeds: {},
      channels: new Channels(),
      channelOpen: {},
      channelsLoaded: false,
    };
  },

  loadChannels: function () {
    let promise = Tremr.stores.feedStore.loadChannels(
      1,
      this.state.channels,
      { sort: "alphabetical", user: this.state.user.get("_id") },
      false
    );

    this.promises.push(promise);
    promise.then(
      function (params) {
        // if target post is by the reposter then exclude their userfeed
        if (this.props.post_author != "") {
          params.collection = params.collection.filter((item) => {
            return item.get("name") != this.props.post_author;
          });
        }

        let channels = new Channels(params.collection);

        let channelOpen = channels.map(function () {
          return false;
        });
        channelOpen[channels.at(0).get("name")] = true;

        if (this.state.selectedSubfeeds) {
          Object.keys(this.state.selectedSubfeeds).forEach(
            function (key) {
              channelOpen[key] = this.state.selectedSubfeeds[key];
            }.bind(this)
          );
        }

        // let defaultSelectedSubfeeds = null;
        // if (this.state.selectedSubfeeds[channels.at(0).get('name')] === undefined && channels.at(0).get('channel') === undefined) {
        //     defaultSelectedSubfeeds = this.state.defaultSelectedSubfeeds || [];
        //     defaultSelectedSubfeeds[channels.at(0).get('name')] = ['featured'];
        // }

        let newState = {
          open: true,
          channels: channels,
          channelOpen: channelOpen,
          channelsLoaded: true,
        };
        // if (defaultSelectedSubfeeds != null) {
        //     newState['selectedSubfeeds'] = defaultSelectedSubfeeds;
        // }
        this.setState(newState);
      }.bind(this)
    );
  },

  bindToFirebase: function () {
    let key = "posts/{post}/activity/{user}/repost";
    key = key.replace(/\{post\}/, this.props.post_id);
    key = key.replace(/\{user\}/, this.state.user.get("_id"));

    let fbRef = Tremr.firebase.database().ref(key);
    fbRef.once(
      "value",
      function (snapshot) {
        let data = snapshot.val();

        if (data && data.data) {
          let channelOpen = this.state.channelOpen;
          let subfeeds = snapshot.child("subfeeds").val();
          let comment = snapshot.child("comment").val();
          this.refs["repostComment"].setValue(comment);

          // remove "all-posts" stub in subfeeds.. needed to store in firebase
          data.subfeeds = {};
          Object.keys(subfeeds).forEach(function (channel) {
            let subfeed = subfeeds[channel];
            channelOpen[channel] = true;
            if (subfeed == "all-posts") {
              data.subfeeds[channel] = [];
            } else {
              data.subfeeds[channel] = subfeed;
            }
          });

          this.setState({
            userSet: true,
            selectedSubfeeds: data.subfeeds,
            channelOpen: channelOpen,
            firebaseSubfeeds: subfeeds, // remember so we can add any missing subfeeds (ie created in UI)
          });
        }
      }.bind(this)
    );
  },

  // load channels from api & repost data from firebase
  componentDidMount: function () {
    if (this.state.user) {
      this.loadChannels();
      this.bindToFirebase();
    } else {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        callback: () => {
          this.loadChannels();
          this.bindToFirebase();
        },
      });
      return;
    }
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });

    // no need to unregiter FB because 'once' used
  },

  // immediately toggle useRated and send message to dispatcher
  save: function () {
    if (this.state.channelsLoaded == false) {
      alertify.error("Please wait for channels to load before reposting.");
      return;
    }

    // let isSet = (this.state.selectedSubfeeds.length > 0);
    let isSet = true; // save button ALWAYS sets because all-posts is fixed

    // only include data for open (selected) channels
    let subfeed_data = {};
    Object.keys(this.state.channelOpen).forEach(
      function (channel) {
        if (channel != "" && this.state.channelOpen[channel]) {
          if (this.state.selectedSubfeeds) {
            if (
              this.state.selectedSubfeeds[channel] &&
              this.state.selectedSubfeeds[channel].length > 0
            ) {
              subfeed_data[channel] =
                this.state.selectedSubfeeds[channel] || "all-posts";
            } else {
              subfeed_data[channel] = "all-posts";
            }
          } else {
            subfeed_data[channel] = "all-posts";
          }
        }
      }.bind(this)
    );

    let data = {
      data: true,
      subfeeds: subfeed_data,
    };

    let repostComment = this.refs["repostComment"].getValue();
    if (repostComment && repostComment.length > 0) {
      data.comment =
        repostComment.length > 140
          ? repostComment.substring(0, 140)
          : repostComment;
    }

    let key = "posts/{post}/activity/{user}/repost";
    key = key.replace(/\{post\}/, this.props.post_id);
    key = key.replace(/\{user\}/, this.state.user.get("_id"));

    let fbRef = Tremr.firebase.database().ref(key);
    fbRef.set(
      data,
      function (error) {
        if (error) {
          console.log("repost failed:");
          console.dir(error);
        } else {
          // Object.keys(this.state.channelOpen).forEach(function(channel) {
          //     if (channel != "" && this.state.channelOpen[channel]) {

          // find channel name
          // let channelName = channel.capitalize();
          // this.state.channels.each(function(c) {
          //     if (c.get('id') == channel) {
          //         channelName = c.get('title');
          //     }
          // });
          // alertify.success("Reposted in "+channelName);
          // }
          // }.bind(this));
          alertify.success("Reposted to selected feed(s)");
          TremrTracking.react(
            this.state.user,
            this.props.post_id,
            "repost",
            true
          );
        }
      }.bind(this)
    );

    this.props.onClose();
  },

  cancel: function () {
    // set the data to false
    let key = "posts/{post}/activity/{user}/repost";
    key = key.replace(/\{post\}/, this.props.post_id);
    key = key.replace(/\{user\}/, this.state.user.get("_id"));

    let fbRef = Tremr.firebase.database().ref(key);
    fbRef.set(
      false,
      function (error) {
        if (error) {
          // The write failed...
          console.log("cancel failed:");
          console.dir(error);
        } else {
          TremrTracking.react(
            this.state.user,
            this.props.post_id,
            "repost",
            false
          );
        }
      }.bind(this)
    );

    this.props.onClose();
  },

  changeSubfeeds: function (channel, args) {
    let subfeeds = args[0];
    let event = args[1];

    // set the selcted subfeeds
    let selectedSubfeeds = this.state.selectedSubfeeds || {};
    selectedSubfeeds[channel] = subfeeds;

    // add the selected subfeeds to the subfeeds and de-dedeupe
    // thus allowing for new ones to be added
    let channels = this.state.channels.slice(0).map(function (c) {
      if (c.get("id") == channel) {
        // add any missing subfeeds, as id/name object
        let sf = c.get("subfeeds");
        let newSFs = [];
        subfeeds.forEach(function (s) {
          if (
            !sf.find(function (x) {
              x["name"] == s;
            })
          ) {
            newSFs.push({
              id: TremrUtils.uniqueId(),
              name: s.trim(),
            });
          }
        });

        sf = sf.concat(newSFs);
        c.set("subfeeds", sf);
      }
      return c;
    });

    this.setState({
      selectedSubfeeds: selectedSubfeeds,
      channels: channels,
    });
  },

  selectChannel: function (event) {
    event.stopPropagation();
    event.preventDefault();
    if (
      event.currentTarget &&
      event.currentTarget.getAttribute("data-channel")
    ) {
      let channel = event.currentTarget.getAttribute("data-channel");
      if (channel && channel != this.state.user.get("feedname")) {
        let channelOpen = _.extend({}, this.state.channelOpen || {});
        channelOpen[channel] = !channelOpen[channel];
        this.setState({
          channelOpen: channelOpen,
        });
      }
    }
  },

  // util for filtering out the featured feed for non-editors
  getSubfeeds(channel) {
    let subfeeds = channel.get("subfeeds");

    // add any missing subfeeds we have from firebase (ie created)
    let fbSubfeeds = this.state.firebaseSubfeeds
      ? this.state.firebaseSubfeeds[channel["id"]]
      : [];

    if (fbSubfeeds == "all-posts") {
      // fbSubfeeds = [fbSubfeeds];
      fbSubfeeds = []; // want to hide all-posts as that is added automatically
    }

    if (fbSubfeeds && fbSubfeeds.length > 0) {
      fbSubfeeds = fbSubfeeds.filter(function (fbSF) {
        return !subfeeds.find(function (x) {
          x["name"] == fbSF;
        });
      });
      fbSubfeeds = fbSubfeeds.map(function (fbSF) {
        return {
          id: TremrUtils.uniqueId(),
          name: fbSF.trim(),
        };
      });
      subfeeds = subfeeds.concat(fbSubfeeds);
    }

    if (this.state.user && subfeeds && subfeeds.length > 0) {
      subfeeds = subfeeds.filter(
        function (subfeed) {
          return (
            !subfeed.featured ||
            this.state.user.isEditorOfFeed(subfeed.name, channel)
          );
        }.bind(this)
      );
    }
    return subfeeds;
  },

  // display
  render: function () {
    // see if we have loaded the image or not
    let classes = {
      "activity-menu": true,
      set: this.state.userSet,
      repost: true,
    };
    classes = classNames(classes);

    let cancelButton = null;

    if (this.state.userSet) {
      cancelButton = (
        <div className="button cancel" onClick={this.cancel}>
          Unrepost
        </div>
      );
    }

    return (
      <div className="repost-menu-container sidebar">
        <div className="repost-menu-toolbar toolbar">
          <div className="row">
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
            {cancelButton}
            <a className="button save" onClick={this.save}>
              Repost
            </a>
          </div>
          <div className="row title">{this.props.post_title}</div>
        </div>
        <div className="controls">
          <Tremr.Editor.ContentEditable
            key="repost-comment"
            ref="repostComment"
            classNames={{ contenteditable: true, repostComment: true }}
            initialValue={this.state.repostComment}
            maxLength={140}
            placeholder="Add a comment…"
          />

          {this.state.channels.map((channel) => {
            let canAddFeed = this.state.user.isEditorOfFeed(
              channel.get("name"),
              channel
            );
            let channel_classes = classNames({
              channel: true,
              open: this.state.channelOpen[channel.get("name")],
            });
            return (
              <div
                onClick={this.selectChannel}
                data-channel={channel.get("name")}
                className={channel_classes}
                key={"channel-subfeeds-" + channel.get("name")}
              >
                <div className="channel-title">{channel.get("title")}</div>
                <div className="channel-subfeeds">
                  <Subfeeds
                    canAdd={canAddFeed}
                    key={"subfeeds-" + channel.get("name")}
                    showAllPosts={true}
                    selectedSubfeeds={
                      this.state.selectedSubfeeds
                        ? this.state.selectedSubfeeds[channel.get("name")]
                        : []
                    }
                    onChange={function () {
                      this.changeSubfeeds(channel.get("name"), arguments);
                    }.bind(this)}
                    subfeeds={this.getSubfeeds(channel)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
});
