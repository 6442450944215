var TremrTracking = require("../../utils/tracking");
var TremrUtils = require("../../utils/tremr_utils");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var Config = require("config");
var UserHeader = require("./userheader");
var ChannelHeader = require("./channelheader");
var FirebaseEncode = require("firebase-encode");

// import {
//   encode,
//   decode,
//   encodeComponents,
//   decodeComponents,
// } from 'firebase-encode';

// loads the feed and determines if it should be a channel or user header
module.exports = CreateReactClass({
  // mixins: [PureRenderMixin, UserAwareMixin],
  mixins: [UserAwareMixin],

  promises: [], // promise references so we can cancel on unmount

  getInitialState: function () {
    return {
      isLoading: true,
      loggedSeen: false,
      lastLoadedContributors: 0,
      model: new Tremr.Models.Feed({
        id: this.props.context.feed,
      }),
    };
  },

  // when we have moved from homeui, check for followed
  componentDidUpdate: function (prevProps, prevState) {
    if (prevProps.context.ui == "home" && this.props.context.ui != "home") {
      _.defer(() => {
        Tremr.dispatcher.message(this, "tremr:follow:forentity", {
          entity: "feed",
          id: this.props.context.feed,
        });
      });
    }
  },

  // loads the data and then reloads for x attempts or until the
  // number of contributors changes from previous load
  // this is unnecessery for normal loading but used when the
  // header receives an edit success event
  // (users contributor status may have changed)
  loadData: function (max_attempts) {
    
    var promise = Tremr.stores.feedStore.loadFeed(
      this.state.model,
      this.props.context.feed,
      this.props.cache
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        if (
          this.props.context.scope == "posts" &&
          this.props.context.subfeed == "featured"
        ) {
          let stats = model.get("stats");
          if (stats && stats.subfeeds && stats.subfeeds.featured == 0) {
            let context = _.clone(this.props.context);
            context = _.extend(context, { subfeed: "all" });
            Tremr.navigatePrimary(context);
          }
        }
        
        // if we have remaining attempts and the lastLoadedContributors is the same
        // try again shortly        
        if (
          max_attempts > 0 &&
          this.state.model.get("channel") == "yes" && 
          this.state.lastLoadedContributors ==
            this.state.model.get("contributors").length
        ) {
          _.delay(() => {
            this.loadData(max_attempts - 1);
          }, 1000);
        } else {
          TremrTracking.logView("feed", this.props.context.feed);

          this.logSeen(null, model);

          // trigger loading of activity for this entity
          _.defer(() => {
            Tremr.dispatcher.message(this, "tremr:follow:forentity", {
              entity: "feed",
              id: this.props.context.feed,
            });
          });
        }

        this.setState({
          isLoading: false,
          model: this.state.model,
          lastLoadedContributors: this.state.model.get("contributors")
            ? this.state.model.get("contributors").length
            : 0,
        });

        // set a global highlight colour - read from user!
        if (this.props.context.ui != "home") {
          if (model.get("highlight_color")) {
            // document.documentElement.style.setProperty('--highlight-color', model.get('highlight_color'));
            Tremr.setHighlightColor(model.get("highlight_color"));
          } else {
            // document.documentElement.style.setProperty('--highlight-color', Config.colors.highlight);
            Tremr.setHighlightColor(Config.colors.highlight);
          }
        }
      }.bind(this)
    );
  },

  // load data from server when first added to DOM
  componentDidMount: function () {
    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // listen for sign-in/out and update user in our session
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signin:success",
      function (user) {
        this.logSeen(user, null);

        this.setState({
          userUnchecked: false,
          user: user,
        });
      }.bind(this)
    );

    // until we load se the highlight as grey
    if (this.props.context.ui != "home") {
      // document.documentElement.style.setProperty(
      //   "--highlight-color",
      //   "#cccccc"
      // );
      Tremr.setHighlightColor("#cccccc");
    }

    // load the data
    this.loadData(3);

    this.listenTo(
      Tremr.dispatcher,
      "tremr:feed:invite:success",
      function (user) {
        // poll until x attempts or the number of contributors has changed
        this.loadData(10);
      },
      this
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });

    if (this.stopListening) {
      this.stopListening();
    }

    // return hard-coded highlight color
    if (this.props.context.ui != "home") {
      // document.documentElement.style.setProperty(
      //   "--highlight-color",
      //   Config.colors.highlight
      // );
      Tremr.setHighlightColor(Config.colors.highlight);
    }
  },

  logSeen: function (user, model) {
    if (!user) {
      user = this.state.user;
    }
    if (!model) {
      model = this.state.model;
    }

    // if we have loaded the user and the model
    if (model && user && !this.state.loggedSeen) {
      let user_id = user.get("_id") || user.get("id");
      let key = "feed-seen/" + user_id + "/" + this.state.model.get("name");
      key = FirebaseEncode.encodeComponents(key);
      let fbSeenRef = Tremr.firebase.database().ref(key);

      fbSeenRef.once("value").then(
        function (snapshot) {
          // add totals for posts and subfeeds
          let counts = {
            count: 0,
            seen_at: new Date().getTime(),
            subfeeds: {},
          };

          if (model.get("stats") && model.get("stats")["posts"]) {
            counts["count"] = model.get("stats")["posts"];
          }

          _.each(this.state.model.get("subfeeds"), function (subfeed) {
            counts.subfeeds[FirebaseEncode.encode(subfeed.name)] = model.get(
              "stats"
            )["subfeeds"][subfeed.name];
          });

          // update only after looking for value
          fbSeenRef.set(counts);

          // fire event to let people know last time we saw this feed
          let data = snapshot.val();
          if (data) {
            data.feed = this.state.model.get("name"); // add feed name
            Tremr.dispatcher.message(this, "tremr:feed:lastseen", data);
          }
        }.bind(this)
      );

      // ensure we only do this once
      this.setState({
        loggedSeen: true,
      });
    }
  },

  render: function () {
    if (this.state.isLoading) {
      return null;
    } else {
      if (this.state.model.get("channel") == "yes") {
        return (
          <ChannelHeader
            model={this.state.model}
            context={this.props.context}
          />
        );
      } else {
        return (
          <UserHeader model={this.state.model} context={this.props.context} />
        );
      }
    }
  },
});
