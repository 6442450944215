var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");

// really simple - just display a label and count based on state
// Tremr.Editor.Count
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    initialLabel: PropTypes.string.isRequired,
    initialCount: PropTypes.number.isRequired,
  },

  getInitialState: function () {
    return {
      label: this.props.initialLabel,
      count: this.props.initialCount,
    };
  },

  render: function () {
    var classes = classNames({
      count: true,
      invalid: this.state.count > 7000,
    });

    return (
      <div className={classes}>
        {this.state.count} {this.state.label}
      </div>
    );
  },
});
