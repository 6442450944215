var TremrTracking = require("../../utils/tracking");

// Tremr.FollowStore
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  // keep a list of entities we have loaded so we can check the users activity on them
  // when the user signs-in
  store.pendingEntities = [];

  // load followers of an entity from the server
  this.loadFollowers = function (page, collection, context, cache) {
    context.src = "follow.followers";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  // load items followed by a user
  this.loadFollowing = function (page, collection, context, cache) {
    context.src = "follow.following";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  // check if user follows a list of entities and generate messages to set that data
  store.loadFollowForEntityUser = function (user, entities) {
    if (!_.isArray(entities)) {
      entities = [entities];
    }

    // iterate the entities and fire messages to show the state of each one
    // dependant on the users data
    _.each(entities, function (entity) {
      let follow = user.isFollowing(entity.entity, entity.id);
      if (follow) {
        Tremr.dispatcher.message(
          this,
          "tremr:follow:" +
            entity.entity +
            ":" +
            entity.id.replace(/\W+/g, "_"),
          follow.subfeeds || true
        );
      }
    });
  };

  // listen to requests to check follow data for entities
  store.listenTo(
    Tremr.dispatcher,
    "tremr:follow:forentity",
    function (entities) {
      if (!_.isArray(entities)) {
        entities = [entities];
      }

      // if user is signed-in then following is stored on their user
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        this.loadFollowForEntityUser(user, entities);
      } else {
        // if not signed-in queue the entities
        this.pendingEntities = _.union(this.pendingEntities, entities);
      }
    },
    store
  );

  // listen for user sign-in and fire pending post_ids
  store.listenTo(
    Tremr.dispatcher,
    "tremr:user:signin:success",
    function (user) {
      if (this.pendingEntities.length > 0) {
        this.loadFollowForEntityUser(user, this.pendingEntities);
      }
    },
    store
  );

  // listen to user setting or unsetting follow
  store.listenTo(
    Tremr.dispatcher,
    "tremr:follow",
    function (data) {
      // we must only allow this if the user is signed-in, so
      // raise a signed-in only event and allow user store to
      // control it.
      if (!Tremr.stores.userStore.getUser()) {
        Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
          event: "tremr:follow",
          data: data,
        });
        return;
      }

      if (data.entity == "tag") {
        Tremr.dispatcher.message(
          this,
          "tremr:follow:" + data.entity + ":" + data.id.replace(/\W+/g, "_"),
          data.userSet
        ); // notify other buttons
      } else {
        Tremr.dispatcher.message(
          this,
          "tremr:follow:" + data.entity + ":" + data.id.replace(/\W+/g, "_"),
          data.subfeeds
        ); // notify other buttons
      }

      // create or destroy an activity
      if (
        (data.entity == "tag" && data.userSet) ||
        (data.subfeeds && data.subfeeds.length > 0)
      ) {
        var activity = new Tremr.Models.Follow({
          entity: data.entity,
          follow_id: data.id,
        });

        if (data.entity == "feed") {
          activity.set("subfeeds", data.subfeeds);
        }

        activity.save(
          {},
          {
            success: function (m, response, options) {
              // also add to user model
              Tremr.context.data.user.addFollowing(
                data.entity,
                data.id,
                data.subfeeds
              );

              // generic follow success
              data.model = m;
              Tremr.dispatcher.message(this, "tremr:follow:change", data);

              TremrTracking.event(
                "follow",
                "follow",
                data.entity + "-" + data.id
              );
            },
            error: function (model, xhr, options) {
              alertify.error("Error saving follow to server");
            },
          }
        );
      } else {
        // pack the variables into the id so we can send a delete
        var activity = new Tremr.Models.Follow({
          id: data.entity + "!" + data.id,
        });
        activity
          .destroy(
            {},
            {
              success: function (m, response, options) {
                // // also remove from user model
                // Tremr.context.data.user.removeFollowing(data.entity, data.id);
                // // generic follow success
                // Tremr.dispatcher.message(this, "tremr:follow:change", data);
              },
              error: function (model, xhr, options) {
                alertify.error("Error deleting follow from server");
              },
            }
          )
          .always(function () {
            // also remove from user model
            Tremr.context.data.user.removeFollowing(data.entity, data.id);

            // generic follow success
            Tremr.dispatcher.message(this, "tremr:follow:change", data);

            TremrTracking.event(
              "follow",
              "unfollow",
              data.entity + "-" + data.id
            );
          });
      }
    },
    store
  );

  return store;
};
