require("./scss/page.scss");
require("./scss/page-emptywalls.scss");

var LoadMoreContainer = require("../../stores/generic/loadmorecontainer");
var classNames = require("../../utils/classnames");
var TremrUtils = require("../../utils/tremr_utils");
var Titlebar = require("../layout/titlebar");
var Toolbar = require("../layout/toolbar");
var ToolbarSticky = require("../layout/toolbar_sticky");
var Favbar = require("../layout/favbar");
var HighlightList = require("../layout/highlightlist");
var CreateReactClass = require("create-react-class");
// var NewPostMenu = require('../../stores/post/newpostmenu');
var PostTitlebar = require("../../stores/post/titlebar");
// var PersonalFeedHeader = require('../../stores/user/personal_feed_header');
var InboxHeader = require("../../stores/user/inbox_header");
var BookmarksHeader = require("../../stores/user/bookmarks_header");
var Alerts = require("./alerts");
var DynamicAlerts = require("./dynamic_alerts");

// Tremr.Layout.Page = CreateReactClass({
module.exports = CreateReactClass({
  lastScrollTop: {},

  getInitialState() {
    return {
      isDeviceOrNarrow: null,
      // ,scrolled: false
    };
  },

  checkElementNearBottom: function (scrollTop, containerRef, loadmoreRef) {
    let node = ReactDOM.findDOMNode(this.refs[loadmoreRef]);
    if (node) {
      let height = node.clientHeight;

      if (!this.lastScrollTop[containerRef]) {
        this.lastScrollTop[containerRef] = 0;
      }
      if (this.lastScrollTop[containerRef] > height) {
        this.lastScrollTop[containerRef] = 0; // reset when page is now smaller than prev scroll pos
      }
      var bottom_margin = 2 * window.innerHeight; // how close do we need to be to the bottom of the page
      var scrollAmount = scrollTop - this.lastScrollTop[containerRef];
      this.lastScrollTop[containerRef] = scrollTop;

      // check if we are near the bottom and raise an event
      if (scrollAmount > 0 && scrollTop + bottom_margin > height) {
        if (this.refs[loadmoreRef] && this.refs[loadmoreRef].loadMore) {
          this.refs[loadmoreRef].loadMore();
        }
      }
    }
  },

  handleScroll: _.throttle(function (event) {
    let scrollTop = window.scrollY;

    // console.log("primary: " + ReactDOM.findDOMNode(this.refs['primaryContainer']).clientHeight);
    // flag when we have scrolled at-all
    // if (this.state.scrolled > 0 && scrollTop == 0) {
    // 	this.setState({scrolled: false});
    // } else if (this.state.scrolled == 0 && scrollTop > 0) {
    // 	this.setState({scrolled: true});
    // }

    // check if we are close to bottom of primary
    this.checkElementNearBottom(
      scrollTop,
      "primaryContainer",
      "loadMorePrimary"
    );
  }, 333),

  // watch for scrolling inside this element
  componentDidMount: function () {
    TremrUtils.addPassiveEventListener("scroll", this.handleScroll);
    // TremrUtils.addPassiveEventListener("resize", this.resized);
  },

  // clear the scroll event handler
  componentWillUnmount: function () {
    window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("resize", this.handleResize);
  },

  // handleResize: function() {
  // 	let isDeviceOrNarrow = TremrUtils.isDeviceOrNarrow();
  // 	if (this.state.isDeviceOrNarrow != isDeviceOrNarrow) {
  // 		this.setState({
  // 			isDeviceOrNarrow: isDeviceOrNarrow
  // 		});
  // 	}
  // },

  // focus on component
  componentDidUpdate: function () {
    // reset the cached scroll-amounts when page changes! this fixes
    // the awful bug where infinifite scoll ALWAYS fires
    this.lastScrollTop = {};
  },

  render: function () {
    // child components depend on props and served up by the stores
    let titlebar = <Titlebar />;
    let primaryHeader = null;
    let primaryContent = null;
    let toolbar = null;
    let rightSidebar = null;
    let favbar = null;
    // let newPostMenu = <NewPostMenu />;

    // let secondary = <HighlightList ref="secondaryList" />;

    // force check for device/narrow if not yet set
    // let isDeviceOrNarrow = this.state.isDeviceOrNarrow;
    // if (isDeviceOrNarrow == null) {
    // 	isDeviceOrNarrow = TremrUtils.isDeviceOrNarrow();
    // }
    if (this.props.primary !== undefined) {
      if (this.props.primary.target == "all") {
        toolbar = <Toolbar ref="toolbar" active={this.props.primary.sort} />;
        favbar = <Favbar ref="favbar" {...this.props} />;

        var headerKey = "all";
        // primaryHeader = (
        // 	<Tremr.Layout.HomepageHeader
        // 		key={headerKey}
        // 		ref="headerComponent"
        // 		cache={this.props.cache}
        // 		user={this.props.user}
        // 		context={this.props.primary}
        // 	/>
        // );
        primaryContent = Tremr.stores.postStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "bookmarks") {
        // toolbar = <Toolbar ref="toolbar" active="homepage" />;
        favbar = <Favbar ref="favbar" {...this.props} />;

        var headerKey = "personalfeed";
        primaryHeader = (
          <ToolbarSticky>
            <BookmarksHeader
              key={headerKey}
              ref="headerComponent"
              cache={this.props.cache}
              user={this.props.user}
              context={this.props.primary}
            />
          </ToolbarSticky>
        );
        primaryContent = Tremr.stores.userStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "inbox") {
        // toolbar = <Toolbar ref="toolbar" active="homepage" />;
        favbar = <Favbar ref="favbar" {...this.props} />;

        var headerKey = "personalfeed";
        primaryHeader = (
          <ToolbarSticky>
            <InboxHeader
              key={headerKey}
              ref="headerComponent"
              cache={this.props.cache}
              user={this.props.user}
              context={this.props.primary}
            />
          </ToolbarSticky>
        );

        primaryContent = Tremr.stores.userStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "homepage") {
        toolbar = <Toolbar ref="toolbar" active="homepage" />;
        favbar = <Favbar ref="favbar" {...this.props} />;

        var headerKey = "personalfeed";
        // primaryHeader = (
        // 	<PersonalFeedHeader
        // 		key={headerKey}
        // 		ref="headerComponent"
        // 		cache={this.props.cache}
        // 		user={this.props.user}
        // 		context={this.props.primary}
        // 	/>
        // );
        primaryContent = Tremr.stores.userStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "welcome") {
        titlebar = null;
        toolbar = null;
        favbar = null;
        // newPostMenu = null;
        primaryContent = <Tremr.Theme.Pitchpage />;
      } else if (this.props.primary.target == "tag") {
        if (this.props.primary.ui == "home") {
          favbar = <Favbar ref="favbar" {...this.props} />;
        }

        var headerKey = "tag." + this.props.primary.tag;
        primaryHeader = (
          <Tremr.Tag.Header
            key={headerKey}
            ref="headerComponent"
            cache={this.props.cache}
            user={this.props.user}
            context={this.props.primary}
          />
        );

        if (this.props.primary.ui == "home") {
          primaryHeader = <ToolbarSticky>{primaryHeader}</ToolbarSticky>;
        }

        primaryContent = Tremr.stores.tagStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "feed") {
        if (this.props.primary.ui == "home") {
          favbar = <Favbar ref="favbar" {...this.props} />;
        } else {
          titlebar = null; // manage its own titlebar
        }

        let headerKey = "feed." + this.props.primary.feed;
        primaryHeader = (
          <Tremr.Feed.Header
            key={headerKey}
            ref="headerComponent"
            cache={this.props.cache}
            user={this.props.user}
            context={this.props.primary}
          />
        );

        if (this.props.primary.ui == "home") {
          primaryHeader = <ToolbarSticky>{primaryHeader}</ToolbarSticky>;
        }

        primaryContent = Tremr.stores.feedStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "homepage-feed") {
        titlebar = <Tremr.Theme.WelcomeTitlebar />;
        toolbar = <Toolbar ref="toolbar" active="homepage-feed" />;
        favbar = <Favbar ref="farbar" {...this.props} />;

        var headerKey = "homepagefeed." + this.props.primary.feed;
        // primaryHeader = (
        // 	<Tremr.Layout.HomepageHeader
        // 		key={headerKey}
        // 		ref="headerComponent"
        // 		cache={this.props.cache}
        // 		user={this.props.user}
        // 		context={this.props.primary}
        // 	/>
        // );
        primaryContent = Tremr.stores.feedStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "search") {
        var headerKey = "search";
        if (this.props.primary.term) {
          headerKey =
            headerKey +
            "." +
            this.props.primary.term +
            "." +
            this.props.primary.scope +
            "." +
            this.props.primary.sort;
        }

        primaryHeader = (
          <Tremr.Search.Header
            context={this.props.primary}
            key={headerKey}
            ref="headerComponent"
            cache={this.props.cache}
            user={this.props.user}
            term={this.props.primary.term}
          />
        );
        primaryContent = Tremr.stores.searchStore.getPrimaryContent(
          this.props.primary,
          this.props.cache
        );
      } else if (this.props.primary.target == "post") {
        
        titlebar = null;

        let headerKey = "feed." + this.props.primary.feed;
        titlebar = (
          <PostTitlebar key={headerKey} feedname={this.props.primary.feed} />
        );

        primaryContent = Tremr.stores.postStore.getPrimaryPostContent(
          this.props.primary,
          this.props.cache
        );

        // newPostMenu = null;
      }
    }

    // see if we have an editor
    var editor = null;
    if (this.props.editor !== undefined) {
      if (this.props.editor.open) {
        editor = Tremr.stores.editorStore.getEditorComponent(this.props.editor);
      }
    }

    // check the overlays to see if any are modal
    let hasModalOverlay = false;
    if (this.props.overlays && this.props.overlays.length > 0) {
      this.props.overlays.forEach(function (overlay) {
        if (overlay.modal) {
          hasModalOverlay = true;
        }
      });
    }

    // set classes on the container depending on what is shown
    var classes = {
      "layout-page": true,
      // "scrolled": this.state.scrolled,
      "has-editor": editor,
      // "has-right-sidebar": this.props.userSidebar,
      // 'editing': (this.state && this.state.editorVisible === true),
      "has-modal-overlay": hasModalOverlay,
      "has-overlay": this.props.overlays && this.props.overlays.length > 0,
    };
    if (this.props.primary && this.props.primary.target) {
      classes[this.props.primary.target] = true;
    }
    classes = classNames(classes);

    // {newPostMenu}

    // render html
    return (
      <div className={classes}>
        {titlebar}
        {favbar}
        {toolbar}

        {primaryHeader}

        <main ref="primaryContainer" className="primary">
          <LoadMoreContainer ref="loadMorePrimary" parentrefs={this.refs}>
            {primaryContent}
          </LoadMoreContainer>
        </main>

        {editor}

        <Tremr.Layout.Overlays
          ref="overlaysComponent"
          overlays={this.props.overlays}
        />

        {rightSidebar}
        <Alerts>
          <DynamicAlerts />
          {Tremr.Theme.Alerts}
        </Alerts>
      </div>
    );
  },
});
