var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// represents a simple contenteditable element
// Tremr.Editor.ContentEditable
module.exports = CreateReactClass({
  propTypes: {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    classNames: PropTypes.object,
    initialValue: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    rawPaste: PropTypes.bool,
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    var domNode = ReactDOM.findDOMNode(this.refs["input"]);
    return nextState.value !== $(domNode).text();
  },

  getDefaultProps: function () {
    return {
      rawPaste: false,
      allowHtml: false,
      classNames: {
        contenteditable: true,
      },
      placeholder: "Enter text…",
    };
  },

  getInitialState: function () {
    var value = this.props.initialValue;
    return {
      value: value,
    };
  },

  // focus on the input field (doesn't always work!?)
  focus: function () {
    _.delay(
      function () {
        var node = ReactDOM.findDOMNode(this.refs["input"]);
        node.focus();
      }.bind(this),
      1
    );
  },

  setValue: function (val) {
    var node = ReactDOM.findDOMNode(this.refs["input"]);
    $(node).text(val);

    this.setState({
      value: val,
    });
  },

  getValue: function () {
    var node = ReactDOM.findDOMNode(this.refs["input"]);
    return $(node).text();
  },

  emitChange: function () {
    var inputNode = ReactDOM.findDOMNode(this.refs["input"]);
    var value = $(inputNode).text();

    this.setState({
      value: value,
    });

    if (this.props.onChange) {
      this.props.onChange({ value: value });
    }

    this.checkLength(value);

    // if the content is supposed to be empty - really empty it (get rid of br etc.)
    if (inputNode.textContent && !inputNode.textContent.trim().length) {
      inputNode.innerHTML = "";
    }
  },

  onBlur: function () {
    this.emitChange();
    if (this.props.onBlur) {
      this.props.onBlur({ value: value });
    }
  },

  // strip out html and line breaks and trim to max length
  handlePaste: function (event) {
    if (this.props.rawPaste) {
      return;
    }

    // always cancel the event and manually update
    event.preventDefault();
    event.stopPropagation();

    // get the content as html or plain
    var clipboardData = event.clipboardData.getData("text/html");
    if (clipboardData == "") {
      clipboardData = event.clipboardData.getData("text/plain");
    }

    var sanitizedClipboardData = Tremr.Utils.sanitize(
      clipboardData,
      false,
      false
    );
    // console.log("CLIPBOARD:"+sanitizedClipboardData);

    let x = sanitizedClipboardData.split("");
    // x.forEach((y) => {
    //    console.log("char:"+y+"="+y.charCodeAt(0));
    // });

    // replace newlines leading whitespace and &nbsp;
    sanitizedClipboardData = sanitizedClipboardData.replace(/\s+/gim, " ");
    // sanitizedClipboardData = sanitizedClipboardData.replace(/^(&nbsp;)+/gmi, "");
    sanitizedClipboardData = sanitizedClipboardData.replace(/^(\s)+/gim, "");
    // sanitizedClipboardData = sanitizedClipboardData.replace(/^(&nbsp;)+/gmi, "");

    if (
      this.props.maxLength &&
      sanitizedClipboardData.length > this.props.maxLength
    ) {
      sanitizedClipboardData = sanitizedClipboardData.substring(
        0,
        this.props.maxLength
      );
    }

    document.execCommand("insertHTML", false, sanitizedClipboardData);
  },

  handleKeyUp: function (event) {
    event.stopPropagation();

    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);
    }
  },

  // sets the remaining text based on current length
  checkLength: function (value) {
    if (this.state.value && this.props.maxLength) {
      var remaining = this.props.maxLength - this.state.value.length;

      if (this.props.maxLength) {
        var domNode = ReactDOM.findDOMNode(this);
        var remaining = this.props.maxLength;
        if (value) {
          remaining = remaining - value.length;
        }
        if (remaining >= 0) {
          domNode.removeAttribute("data-too-long");
          // remaining = remaining.toString() + ' remaining';
          remaining =
            value.length.toString() + " / " + this.props.maxLength.toString();
        } else {
          domNode.setAttribute("data-too-long", true);
          // remaining = value.length - this.props.maxLength;
          // remaining = remaining.toString() + ' too many';
          remaining =
            value.length.toString() + " / " + this.props.maxLength.toString();
        }
        domNode.setAttribute("data-remaining", remaining);
      }
    }
  },

  // prevent enter, and when we have no characters left from maxLength prevent normal characters
  handleKeyDown: function (event) {
    event.stopPropagation();

    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }

    if (event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (event.key == "Backspace" || event.key == "delete") {
      return;
    }

    if (
      this.state.value &&
      this.props.maxLength &&
      event.metaKey == false &&
      event.keyCode >= 48 &&
      event.keyCode <= 90
    ) {
      var remaining = this.props.maxLength - this.state.value.length;
      if (remaining <= 0) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  },

  render: function () {
    var remaining = "";
    if (this.props.maxLength) {
      var remaining = this.props.maxLength;
      if (this.state.value) {
        remaining = remaining - this.state.value.length;
      }
      if (remaining >= 0) {
        if (this.state.value) {
          remaining =
            this.state.value.length.toString() +
            " / " +
            this.props.maxLength.toString();
        }
        // remaining = remaining.toString() + ' remaining';
      } else {
        if (this.state.value) {
          remaining =
            this.state.value.length.toString() +
            " / " +
            this.props.maxLength.toString();
        }
        // remaining = this.state.value.length - this.props.maxLength;
        // remaining = remaining.toString() + ' too many';
      }
    }

    var classes = this.props.classNames;
    classes["contenteditable"] = true;
    classes = classNames(classes);

    return (
      <div
        className={classes}
        data-remaining={remaining}
        data-too-long={remaining < 0 ? true : false}
      >
        <div
          contentEditable
          ref="input"
          key="input"
          onInput={this.emitChange}
          onBlur={this.OnBlur}
          onPaste={this.handlePaste}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          data-placeholder={this.props.placeholder}
          data-gramm="false"
        >
          {this.state.value}
        </div>
      </div>
    );
  },
});
