require("./scss/horizontalscroller.scss");

var CreateReactClass = require("create-react-class");
var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var SvgIcon = require('../generic/svgicon');

// contains child components that scroll (using styles) unless on windows
// in which case scrolling is off (using styles) and scrolling is handled by custom handlers
module.exports = CreateReactClass({
    mixins: [PureRenderMixin],

    propTypes: {
        customScrollbars: PropTypes.bool,
        childSize: PropTypes.number,
        onScroll: PropTypes.func
    },

    getDefaultProps: function() {
        return {
            customScrollbars: !TremrUtils.isDevice(),
            childSize: 0
        };
    },

    getInitialState: function() {
        return {
            scrollLeft: 0
        };
    },

    didScroll: function(scrollLeft, scrollViewport, scrollWidth) {
        if (this.props.onScroll) {
            this.props.onScroll(scrollLeft, scrollViewport, scrollWidth);
        }
    },

    handleScroll: function(event) {
        if (this.props.onScroll) {
            let node = ReactDOM.findDOMNode(this);
            node = node.querySelectorAll('.scroll-content')[0]; // get the scroll-content element
            this.didScroll(node.scrollLeft, node.clientWidth, node.scrollWidth);
        }
    },

    componentDidMount: function() {

        // watch for changes to children and scroll
        let node = ReactDOM.findDOMNode(this);
        if (node) {
            node = node.querySelectorAll('.scroll-content')[0]; // get the scroll-content element

            // if (this.props.customScrollbars) {

                // Options for the observer (which mutations to observe)
                const config = { attributes: false, childList: true, subtree: true };

                // Create an observer instance linked to the callback function
                const observer = new MutationObserver((mutationsList, observer) => {
                    this.componentDidUpdate();
                });

                // Start observing the target node for configured mutations
                observer.observe(node, config);

                this.setState({
                    mutationObserver: observer
                });

            if (!this.props.customScrollbars) {

                // watch for scroll
                if (TremrUtils.passiveSupported) {
                    node.addEventListener('scroll', this.handleScroll, {passive: true});
                } else {
                    node.addEventListener('scroll', this.handleScroll);
                }
            }
        }

    },

    componentWillUnmount: function() {
        if (this.state.mutationObserver) {
            this.state.mutationObserver.disconnect();
        }

        // stop listening to scroll
        let node = ReactDOM.findDOMNode(this);
        node.removeEventListener('scroll', this.handleScroll);
    },

    componentDidUpdate: function() {

        let node = ReactDOM.findDOMNode(this);

        if (node) {
            node = node.querySelectorAll('.scroll-content')[0]; // get the scroll-content element

            if (this.props.customScrollbars) {
                node.scrollLeft = this.state.scrollLeft;
            }

            let newState = {
                scrollWidth: node.scrollWidth,
                scrollViewport: node.clientWidth
            };

            this.setState(newState);
            this.didScroll(this.state.scrollLeft, node.clientWidth, node.scrollWidth)
        }
    },

    pageLeft: function(event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.state.scrollLeft > 0) {
            let newLeft = this.state.scrollLeft - this.state.scrollViewport;
            if (this.props.childSize && this.props.childSize > 0) {
                // scroll to whole number of childSize
                newLeft = Math.floor(newLeft / this.props.childSize) * this.props.childSize;
            }

            if (newLeft < 0) {
                newLeft = 0;
            }
            this.setState({
                scrollLeft: newLeft
            });
            this.didScroll(newLeft, this.state.scrollViewport, this.state.scrollWidth)
        }
    },

    pageRight: function(event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.state.scrollWidth && (this.state.scrollWidth > this.state.scrollViewport + this.state.scrollLeft)) {
            let newLeft = this.state.scrollLeft + this.state.scrollViewport;
            if (this.props.childSize && this.props.childSize > 0) {
                // scroll to whole number of childSize
                newLeft = Math.floor(newLeft / this.props.childSize) * this.props.childSize;
            }

            this.setState({
                scrollLeft: newLeft
            });
            this.didScroll(newLeft, this.state.scrollViewport, this.state.scrollWidth)
        }
    },

    // display
    render: function() {

        let scrollLeft = null;
        let scrollRight = null;
        let contentFits = true;

        if (this.state && this.state.scrollWidth && (this.state.scrollWidth > this.state.scrollViewport)) {

            contentFits = false;

            // calculate display of custom bar
            if (this.props.customScrollbars) {
                // draw the butttons
                let leftClasses = {};
                leftClasses['scroll-button'] = true;
                leftClasses['left'] = true;
                leftClasses['active'] = this.state.scrollLeft > 0;
                scrollLeft = <div onClick={this.pageLeft} className={classNames(leftClasses)}>
                    <Tremr.Generic.SvgIcon icon="chevron-left" />
                </div>;

                let rightClasses = {};
                rightClasses['scroll-button'] = true;
                rightClasses['right'] = true;
                rightClasses['active'] = this.state.scrollLeft + this.state.scrollViewport < this.state.scrollWidth;
                scrollRight = <div onClick={this.pageRight} className={classNames(rightClasses)}>
                    <Tremr.Generic.SvgIcon icon="chevron-right" />
                </div>;
            }
        }

        let classes = {};
        classes["generic-horizontalscroller"] = true;
        classes["custom-scrolling"] = scrollLeft || scrollRight;
        classes["no-scrolling"] = this.props.customScrollbars && !classes["custom-scrolling"];
        classes["content-fits"] = contentFits && !this.props.customScrollbars;
        classes = classNames(classes);

        return <div className={classes}>
                {scrollLeft}
                <div className="scroll-content">
                    {this.props.children}
                </div>
                {scrollRight}
            </div>;
    }
});
