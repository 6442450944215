var alertify = require("alertify");
var Promise = require("bluebird");
var moment = require("moment");
var TremrStorage = require("./tremr_storage");

// var createHash = require('sha.js');

var Utils = {};

Utils.Ranges = require("./ranges");

Utils.retryTimeout = 1000;

// Utils.hash = function(seed) {
// https://www.npmjs.com/package/sha.js
// }

Utils.passiveSupported = false;

// always set passive support flag here (rather than faffing with modernizr build)
try {
  var options = Object.defineProperty({}, "passive", {
    get: function () {
      Utils.passiveSupported = true;
    },
  });

  window.addEventListener("test", options, options);
  window.removeEventListener("test", options, options);
} catch (err) {
  Utils.passiveSupported = false;
}

// set a passive event listener with massive if we can
Utils.addPassiveEventListener = function (event, handler) {
  if (Utils.passiveSupported) {
    window.addEventListener(event, handler, { passive: true });
  } else {
    window.addEventListener(event, handler, false);
  }
};

Utils.hasMouse = false;
if (window.matchMedia("(pointer:fine)").matches) {
  Utils.hasMouse = true;
}

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
Utils.pSBC = function (p, c0, c1, l) {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    i = parseInt,
    m = Math.round,
    a = typeof c1 == "string";
  if (
    typeof p != "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 != "string" ||
    (c0[0] != "r" && c0[0] != "#") ||
    (c1 && !a)
  )
    return null;
  if (!this.pSBCr)
    this.pSBCr = (d) => {
      let n = d.length,
        x = {};
      if (n > 9) {
        ([r, g, b, a] = d = d.split(",")), (n = d.length);
        if (n < 3 || n > 4) return null;
        (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))),
          (x.g = i(g)),
          (x.b = i(b)),
          (x.a = a ? parseFloat(a) : -1);
      } else {
        if (n == 8 || n == 6 || n < 4) return null;
        if (n < 6)
          d =
            "#" +
            d[1] +
            d[1] +
            d[2] +
            d[2] +
            d[3] +
            d[3] +
            (n > 4 ? d[4] + d[4] : "");
        d = i(d.slice(1), 16);
        if (n == 9 || n == 5)
          (x.r = (d >> 24) & 255),
            (x.g = (d >> 16) & 255),
            (x.b = (d >> 8) & 255),
            (x.a = m((d & 255) / 0.255) / 1000);
        else
          (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
      }
      return x;
    };
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
    (f = this.pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 != "c"
        ? this.pSBCr(c1)
        : P
        ? { r: 0, g: 0, b: 0, a: -1 }
        : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l)
    (r = m(P * f.r + p * t.r)),
      (g = m(P * f.g + p * t.g)),
      (b = m(P * f.b + p * t.b));
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
  (a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h)
    return (
      "rgb" +
      (f ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      (f ? "," + m(a * 1000) / 1000 : "") +
      ")"
    );
  else
    return (
      "#" +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
};

Utils.getIpAddress = function () {
  return new Promise(function (resolve, reject) {
    fetch("https://www.cloudflare.com/cdn-cgi/trace").then(function (response) {
      if (response.ok) {
        response.text().then(function (text) {
          const regex = /ip=([\d\w\.\:]+)/g;
          const found = text.match(regex);
          const ip = found.toString().substr(3);

          resolve(ip);
        });
      } else {
        reject(response.status, response.statusText);
      }
    }).catch((error) => {
      console.log(error);
      resolve("unknown");
    });
  });
};

Utils.fetch = function (url, options) {
  if (!options["headers"]) {
    options["headers"] = {};
  }
  options["headers"]["X-Requested-With"] = "XMLHttpRequest";
  options["credentials"] = "same-origin";

  // add csrf token
  let token = $('meta[name="csrf-token"]').attr("content");
  if (token) {
    options["headers"]["X-CSRF-Token"] = token;
  }

  return fetch(url, options);
};

Utils.parents = function (elem) {
  let matched = [];

  while ((elem = elem["parentNode"]) && elem.nodeType !== 9) {
    if (elem.nodeType === 1) {
      matched.push(elem);
    }
  }
  return matched;
};

// formats big numbers using k for thousands
Utils.formatStat = function (number) {
  if (number > 100000) {
    return Math.round(number / 1000) + "k";
  } else if (number > 1000) {
    return Math.round(number / 100) / 10 + "k";
  } else {
    return number;
  }
};

// returns elements in an array with unique id attributes, Ignoring
// duplicates and ones without an id
// here because it is used in several stores and is a v simple function
Utils.filterUniqueIds = function (data) {
  if (data && data.filter) {
    return data.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.id === item.id) && item.id
    );
  } else {
    return data;
  }
};

Utils.stringToColor = function (str) {
  // let hash = 0;
  // for (let i = 0; i < str.length; i++) {
  //    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  // }
  // let colour = '#';
  // for (let i = 0; i < 3; i++) {
  //    let value = (hash >> (i * 8)) & 0xFF;
  //    colour += ('00' + value.toString(16)).substr(-2);
  // }

  //  let hash = 0;
  //  if (str.length > 0) {
  //      for (let i = 0; i < str.length; i++) {
  //          hash = str.charCodeAt(i) + ((hash << 5) - hash);
  //          hash = hash & hash; // Convert to 32bit integer
  //      }
  //  }
  //
  // // adjust hex into hsl
  // let hue = hash % 360;
  // let colour = "hsl(" + hue + ",80%,43%)";
  // return colour;

  // let colors = ['#FF7F10', '#54A3DD', '#93CC58', '#E74100', '#DD67C2'];
  if (str) {
    let strLowercase = str.toLowerCase();
    if (["environment", "environmental"].includes(strLowercase)) {
      return "#93CC58";
    } else if (strLowercase.includes("envir")) {
      return "#93CC58";
    } else if (["technology"].includes(strLowercase)) {
      return "#54A3DD";
    } else if (["entertainment", "music"].includes(strLowercase)) {
      return "#DD67C2";
    } else if (strLowercase.includes("mov")) {
      return "#DD67C2";
    } else if (strLowercase.includes("music")) {
      return "#DD67C2";
    } else if (strLowercase.includes("sci")) {
      return "#54A3DD";
    } else if (strLowercase.includes("tech")) {
      return "#54A3DD";
    } else if (["a", "b", "c"].includes(strLowercase.charAt(0))) {
      return "#FF7F10";
    } else if (["d", "e", "f", "g"].includes(strLowercase.charAt(0))) {
      return "#54A3DD";
    } else if (
      ["h", "i", "j", "k", "l", "m"].includes(strLowercase.charAt(0))
    ) {
      return "#93CC58";
    } else if (
      ["n", "o", "p", "q", "r", "s"].includes(strLowercase.charAt(0))
    ) {
      return "#E74100";
    } else if (
      ["t", "u", "v", "w", "x", "y", "z"].includes(strLowercase.charAt(0))
    ) {
      return "#DD67C2";
    }
  }

  return "#FF7F10";
};

Utils.agoDescription = function (m) {
  let now = moment();
  let d = now.diff(m);

  let seconds = Math.floor(d / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let weeks = Math.floor(days / 7);
  let months = Math.floor(days / (365 / 12));
  let years = Math.floor(days / 365);

  if (years > 0) {
    return years + "y";
  } else if (months > 0) {
    return months + "m";
  } else if (weeks > 0) {
    return weeks + "w";
  } else if (days > 0) {
    return days + "d";
  } else if (hours > 0) {
    return hours + "h";
  } else if (minutes > 0) {
    return minutes + "mins";
  }

  // return seconds + "s";
  // return "";
  return "Now";
};

Utils.daysAgo = function (days) {
  let d = new Date();
  d.setDate(d.getDate() + (0 - days));
  return d;
};

Utils.sleep = function (milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break;
    }
  }
};

Utils.isUpperCase = function (str) {
  return str === str.toUpperCase();
};

// gets a pixel ratio as a string
Utils.getPixelRatio = function () {
  // allowed by cloudinary
  var allowedRatios = [4.0, 3.0, 2.0, 1.5, 1.3, 1.0, 0.75];
  var pixelRatio = 1.0;
  var actualRatio = window.devicePixelRatio;

  // adjust for under 1.0 ratio
  if (actualRatio < 0.75) {
    actualRatio = 0.75;
  }
  if (actualRatio > 0.75 && actualRatio < 1.0) {
    actualRatio = 1.0;
  }

  // get the smallest ratio above actual
  _.each(allowedRatios, function (ratio) {
    if (actualRatio <= ratio) {
      pixelRatio = ratio;
    }
  });

  if (pixelRatio) {
    if (pixelRatio == 0.75) {
      pixelRatio = "0.75";
    } else {
      pixelRatio = pixelRatio.toFixed(1).toString();
    }
  } else {
    pixelRatio = "1.0";
  }

  return pixelRatio;
};

// estimate reading time of a string
Utils.readingTime = function (words) {
  var wordsPerMinute = 250;
  // var wordsPerSecond = wordsPerMinute / 60;
  // var seconds = Math.round(words / wordsPerSecond);
  var minutes = Math.round(words / wordsPerMinute);
  if (minutes <= 1) {
    return "under 1 min";
  } else if (minutes < 60) {
    return minutes + " mins";
  } else {
    return "ages";
  }
};

// count words in a string
Utils.countWords = function (s) {
  if (!s) {
    return 0;
  }
  s = s.trim();
  s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
  s = s.replace(/\s{2,}/gi, " "); //2 or more space to 1
  s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
  s = s.trim();
  if (s.length == 0) {
    return 0;
  }
  return s.split(" ").length;
};

// get width from client bounds
Utils.getWidth = function (element) {
  return element.getBoundingClientRect().width;
};

// get height from client bounds
Utils.getHeight = function (element) {
  return element.getBoundingClientRect().height;
};

// cached loading of collection using backbone and jscache,
// query (except pageno is passed in), cached version records
// the max page no loaded and will return all cached records
// for any page up to that and will return the next page that can be
// loaded.  Pages beyond the page recorded in the cache will only load that page and
// add themselves to the cached collection
Utils.loadCollection = function (page, collection, context, cache, method) {
  return new Promise(function (resolve, reject) {
    var fromCache = false;

    if (method === undefined) {
      method = "get";
    }
    if (cache === undefined) {
      cache = false;
    }

    var cachedPage = 0;
    var cachekey = "collection." + JSON.stringify(context); // have to exclude pageno, so this seems best way

    if (cache) {
      var cacheddata = TremrStorage.getSession(cachekey);
      if (cacheddata) {
        // if we have cached data up to or beyond the requested page just return it all
        if (cacheddata.maxPage === undefined || cacheddata.maxPage >= page) {
          collection.set(cacheddata.data, {
            add: true,
            reset: false,
            remove: false,
            merge: true,
          });
          _.defer(function () {
            resolve({
              collection: collection,
              data: cacheddata.data,
              page: cacheddata.maxPage,
              options: null,
            });
          });
          fromCache = true;
        } else {
          // we have some cached data but not up to the page we want
          // so add the existing data and get the rest from the server
          collection.set(cacheddata.data, {
            add: true,
            reset: false,
            remove: false,
            merge: true,
          });
          cachedPage = cacheddata.maxPage;
        }
      }
    }

    if (!fromCache) {
      // if not return cached data then just load from server
      collection.fetch({
        type: method,
        add: true,
        reset: false,
        remove: false,
        merge: true,
        data: _.extend(
          {
            pageno: page,
          },
          context
        ),
        success: function (coll, data, options) {
          if (data && data.success == false) {
            Tremr.Utils.errorMessage(
              Tremr.messages.dataError,
              data.message,
              data
            );
          }

          // always check for setregion
          if (data.length > 0 && data[0].uievent == "setregion") {
            // check for special set_region
            Tremr.dispatcher.message(
              this,
              "tremr:uievent:setregion",
              data[0].data
            );

            // remove the clientretry
            coll.reset(
              coll.reject((item) => {
                return item.get("uievent") == "setregion";
              })
            );
            collection = coll;
          }

          // check for retry
          if (data.length > 0 && data[0].uievent == "retry") {
            // remove the clientretry
            coll.reset(
              coll.reject((item) => {
                return item.get("uievent") == "retry";
              })
            );
            collection = coll;

            console.info("Retrying Cloudant in " + Utils.retryTimeout + "ms");

            setTimeout(function () {
              Utils.loadCollection(page, coll, context, cache, method).then(
                (params) => {
                  console.debug("Retry Cloudant Success");
                  resolve({
                    collection: params.collection,
                    data: params.data,
                    page: params.page,
                    options: params.options,
                  });
                },
                (params) => {
                  reject({
                    collection: params.collection,
                    response: params.response,
                    options: params.options,
                  });
                }
              );
            }, Utils.retryTimeout);
            Utils.retryTimeout = Utils.retryTimeout + 1000;
          } else {
            Utils.retryTimeout = 1000;

            // add new cache record
            TremrStorage.setSession(cachekey, {
              data: collection.models,
              maxPage: page,
            }); // remember regardless of cache setting
            _.defer(function () {
              resolve({
                collection: collection,
                data: data,
                page: page,
                options: options,
              });
            });
          }
        },
        error: function (coll, response, options) {
          Tremr.Utils.errorMessage(
            Tremr.messages.dataError,
            response.status,
            response
          );
          _.defer(function () {
            reject({
              collection: collection,
              response: response,
              options: options,
            });
          });
        },
      });
    }
  });
};

// cached loading of model using backbobe and jscache,
// relies on model id being unique.
// key will be set on model for fetch call.
Utils.loadModel = function (model, key, cache, supressError) {
  return new Promise(function (resolve, reject) {
    var fromCache = false;

    if (cache === undefined) {
      cache = false;
    }
    if (supressError === undefined) {
      supressError = false;
    }

    // set key so our backbone model can fetch and cache key can use url
    model.set(key);

    // if caller is allowing cache the check
    var cachekey = "model." + model.url();

    if (cache) {
      var attributes = TremrStorage.getSession(cachekey);
      if (attributes) {
        model.set(attributes);
        resolve({ model, model, data: false, options: null });
        fromCache = true;
      }
    }

    // load from server
    if (!fromCache) {
      model.fetch({
        success: function (m, d, o) {
          if (d && d.success == false && !supressError) {
            Tremr.Utils.errorMessage(Tremr.messages.dataError, d.message, d);
          }

          if (_.isArray(d) && d.length > 0 && d[0].uievent == "retry") {
            console.log("Retrying Cloudant in " + Utils.retryTimeout + "ms");
            setTimeout(function () {
              Utils.loadModel(model, key, cache, supressError).then(
                (params) => {
                  resolve({ model: m, data: d, options: o });
                },
                (params) => {
                  reject({
                    model: model,
                    response: response,
                    options: options,
                  });
                }
              );
            }, Utils.retryTimeout);
            Utils.retryTimeout = Utils.retryTimeout + 1000;
          } else {
            Utils.retryTimeout = 1000;

            TremrStorage.setSession(cachekey, d); // remember regardless of cache setting
            resolve({ model: m, data: d, options: o });
          }
        },
        error: function (model, response, options) {
          if (!supressError) {
            Tremr.Utils.errorMessage(
              Tremr.messages.dataError,
              response.status,
              response
            );
          }
          reject({ model: model, response: response, options: options });
        },
      });
    }
  });
};

// throttled version of alertify to prevent user getting too many!
Utils.alertifyError = _.throttle(alertify.error, 5000, { trailing: false });

// wrapper for error handling
Utils.errorMessage = function (message, moreInfo, variable) {
  console.log("Error: " + message);
  console.log("Error Info: " + moreInfo);
  console.dir(variable);

  // prevent too much
  // Tremr.Utils.alertifyError(message);
};

// create a string url from a hash returned by parseUrlString
Utils.createUrlString = function (hash) {
  // let url = hash.protocol + '//' + hash.host + hash.pathname;
  let url = hash.pathname;
  let delim = "?";
  _.each(hash.params, function (value, key) {
    if (key && value) {
      url = url + delim + encodeURI(key) + "=" + encodeURI(value);
      delim = "&";
    }
  });
  url = url + hash.hash;
  return url;
};

Utils.parseUrlString = function (url) {
  var parser = document.createElement("a");
  parser.href = url;

  return {
    protocol: parser.protocol, // => "http:"
    hostname: parser.hostname, // => "example.com"
    port: parser.port, // => "3000"
    pathname: parser.pathname, // => "/pathname/"
    hash: parser.hash, // => "#hash"
    host: parser.host,
    params: Tremr.Utils.parseQueryString(parser.search.substring(1) || ""),
  };
};

// Parse query string
Utils.parseUrl = function () {
  return Tremr.Utils.parseUrlString(document.location);
};

// extract array of parameters from location.search
Utils.parseQueryString = function (queryString) {
  var params = {},
    queries,
    temp,
    i,
    l;

  // Split into key/value pairs
  queries = queryString.split("&");

  // Convert the array of strings into an object
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split("=");
    params[temp[0]] = temp[1];
  }

  return params;
};

Utils.NarrowWidth = 600;
Utils.MediumWidth = 1000;

Utils.isDeviceOrNarrow = function () {
  // return (Tremr.Utils.isDevice() || $(window).width() <= Tremr.Utils.NarrowWidth);
  return $(window).width() <= Tremr.Utils.NarrowWidth;
};

Utils.isMediumWidth = function () {
  return $(window).width() <= Tremr.Utils.MediumWidth;
};

Utils.isDeviceOrNarrowWithSidebar = function () {
  console.error("DEPRICATED: Utils.isDeviceOrNarrowWithSidebar");
  return Tremr.Utils.isDevice() || $(window).width() <= Tremr.Utils.MediumWidth;
};

// check the user agent string to see if we are a mobile device
Utils.isDevice = function () {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

// check the user agent string to see if we are not on a mobile device
Utils.isDesktop = function () {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return false;
  } else {
    return true;
  }
};

// returns a string representing the likely meta key for the user
Utils.getMetaKeyDesc = function () {
  if (Tremr.Utils.checkForMac()) {
    return "Cmd";
  } else {
    return "Ctrl";
  }
};

// check for windows/linux which will probably have
// naff scrollbars that are always present!
Utils.hasOldScrollbars = function () {
  let platform = navigator.platform;
  if (platform.match(/(Win)|(Linux)/)) {
    return !Utils.checkForAndroid();
  } else {
    return false;
  }
};

// check for any 'mac' style os
Utils.checkForMac = function () {
  if (navigator.userAgent.match(/OS\sX/)) {
    return true;
  } else {
    return false;
  }
};

// check for any version of IE
Utils.checkForIE = function () {
  if (navigator.userAgent.match(/Trident/)) {
    return true;
  } else if (/msie/.test(navigator.userAgent.toLowerCase())) {
    return true;
  } else {
    return false;
  }
};

// check for ms edge
Utils.checkForEdge = function () {
  if (navigator.userAgent.match(/Edge/)) {
    return true;
  } else {
    return false;
  }
};

// check for firefox
Utils.checkForFirefox = function () {
  if (navigator.userAgent.match(/Firefox/)) {
    return true;
  } else {
    return false;
  }
};

// check for safari
Utils.checkForSafari = function () {
  if (navigator.userAgent.match(/Safari/)) {
    return !navigator.userAgent.match(/Chrome/);
  } else {
    return false;
  }
};

// check for android
Utils.checkForAndroid = function () {
  if (navigator.userAgent.match(/Android/)) {
    return true;
  } else {
    return false;
  }
};

// check for versions of IE below 9 and redirect
Utils.checkForOldIE = function () {
  return Utils.checkForIE() && !Utils.checkForEdge();
  // var appVer = navigator.appVersion.toLowerCase();
  // var is_minor = parseFloat(appVer);
  // var iePos = appVer.indexOf('msie');
  // if (iePos != -1) {
  //    is_minor = parseFloat(appVer.substring(iePos + 5, appVer.indexOf(';', iePos)))
  // }
  // var is_ie = ((iePos != -1));
  // if (is_ie && is_minor <= 9 || navigator.appVersion.indexOf("MSIE 9.") != -1) {
  //    var root = location.protocol + '//' + location.host;
  //    document.location = root + '/ie.html'
  // }
};

// check for versions of IOS below 4 and redirect
Utils.checkForOldIOS = function () {
  if (navigator.userAgent.match(/iPhone\sOS\s4/)) {
    var root = location.protocol + "//" + location.host;
    document.location = root + "/ie.html";
  }
};

// generate a probably random id
Utils.uniqueId = function (prefix) {
  if (prefix === undefined) {
    prefix = "";
  } else {
    prefix = prefix + "-";
  }
  return prefix + Math.random().toString(36).slice(2);
};

Utils.seeds = {};

(Utils.seededSequence = function (seq, seed) {
  Utils.seeds[seq] = seed;
}),
  // provide repeatable random numbers
  (Utils.seededRandom = function (max, min, seq) {
    max = max || 1;
    min = min || 0;

    if (seq === undefined) {
      seq = "seed-" + _.keys(Utils.seeds).length;
    }

    if (!Utils.seeds[seq]) {
      Utils.seeds[seq] = _.keys(Utils.seeds).length + 1;
    }
    Utils.seeds[seq] = (Utils.seeds[seq] * 9301 + 49297) % 233280;
    var rnd = Utils.seeds[seq] / 233280.0;

    return min + rnd * (max - min);
  });

/**
 * Simple function to append http:// to string if ommitted
 */
Utils.addHttp = function (url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
};

/**
 * Truncate a string to the given length, breaking at word boundaries and adding an elipsis
 */
Utils.truncate = function (
  str,
  limit,
  separator,
  truncatedMark,
  replaceSeparator
) {
  var bits, i;
  var truncated = false;

  if (separator === undefined) {
    separator = "";
  }
  if (truncatedMark === undefined) {
    truncatedMark = "…";
  }
  if (replaceSeparator === undefined) {
    replaceSeparator = " ";
  }

  // remove any html
  str = $("<div />").html(str).text();

  bits = str.split(separator);
  if (bits.length > limit) {
    for (i = bits.length - 1; i > -1; --i) {
      if (i > limit) {
        bits.length = i;
      } else if (" " === bits[i]) {
        bits.length = i;
        break;
      }
    }
    truncated = true;
  }

  if (_.isRegExp(separator)) {
    separator = replaceSeparator;
  }
  var text = bits.join(separator);

  if (truncated === true) {
    text = text + truncatedMark;
  }
  return text;
};

Utils.stringToFunction = function (str) {
  var arr = str.split(".");

  var fn = window || this;
  for (var i = 0, len = arr.length; i < len; i++) {
    fn = fn[arr[i]];
  }

  if (typeof fn !== "function") {
    throw new Error("function not found: " + str);
  }

  return fn;
};

// get a list of ids as a CSV list
Utils.getIdList = function (collection) {
  var delim = "";
  var ids = _.reduce(
    collection,
    function (memo, item) {
      if (item === undefined) {
        return memo;
      }
      var newval = memo + delim + item.id;
      delim = ",";
      return newval;
    },
    ""
  );
  return ids;
};

// hack to force a redraw
jQuery.fn.redraw = function () {
  // remember style
  var style = $(this).attr("style");
  if (style == undefined) {
    style = "";
  }

  // hide and show tween hack - adds to style attr!
  return this.hide(
    0,
    function () {
      $(this).show();
    },
    function () {
      $(this).attr("style", style);
    }
  ); // so put it back once finished
};

// validate an email address
Utils.validEmail = function (email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

// strip out all but the simplest html tags from a string using regular expressions
Utils.sanitize = function (html, allowlinks, allowtags, allowattributes) {
  if (allowlinks == undefined) {
    allowlinks = false;
  }
  if (allowtags == undefined) {
    allowtags = false;
  }
  if (allowattributes == undefined) {
    allowattributes = false;
  }

  var sanitized = html;

  // tags we may want to allow
  var allowed = ["b", "em", "strong", "i", "p", "blockquote", "h2"];

  // attributes we may want to allow
  var allowed_attributes = ["class", "lang", "dir"];

  // strip the content (converting some)
  sanitized = sanitized.replace(/(<\/?)(\w*\s?)([^>]*)(>)/gi, function (
    match,
    p1,
    p2,
    p3,
    p4,
    offset,
    string
  ) {
    if (allowtags == false) {
      return " ";
    }

    if (_.contains(allowed, p2.trim())) {
      if (allowattributes && p3.length > 0) {
        var attributes_to_add = "";
        var attributes = p3.split(" ");
        _.each(attributes, function (attr) {
          var parts = attr.split("=");
          if (_.contains(allowed_attributes, parts[0])) {
            attributes_to_add += " " + parts[0];
            if (parts.length > 1) {
              attributes_to_add += "=" + parts[1];
            }
          }
        });
        return "\n " + p1 + p2.trim() + attributes_to_add + p4;
      } else {
        return "\n " + p1 + p2.trim() + p4;
      }
    } else {
      if (p2.trim() == "h1") {
        return "\n " + p1 + "h3" + p4;
      } else if (p2.trim() == "h2") {
        return "\n " + p1 + "h4" + p4;
      } else if (p2.trim() == "div") {
        return "\n " + p1 + "p" + p4;
      } else if (allowlinks == true && p2.trim() == "a") {
        if (!p3.match(/href=["']http/gi)) {
          p3 = p3.replace(/href(["'])/, "href=$1http://");
        }
        var content = p3.replace(/(.*)(href=["']\S+["'])(.*)/gi, "$2");
        return "\n " + p1 + p2 + " " + content + p4;
      } else {
        return " ";
      }
    }
  });

  return sanitized;
};

// encode anything outside of ascii as entities
function extendedHTMLEncode(str) {
  var i = str.length,
    aRet = [];

  while (i--) {
    var iC = str[i].charCodeAt();
    // if (iC < 65 || iC > 127 || (iC>90 && iC<97)) {
    if (iC > 10000) {
      // aRet[i] = '&#'+iC+';';
      aRet[i] = "";
    } else {
      aRet[i] = str[i];
    }
  }
  return aRet.join("");
}

// encode json as a string
Utils.htmlEncode = function (value) {
  return extendedHTMLEncode($("<div/>").text(value).html());
  // return $('<div/>').text(extendedHTMLEncode(value)).html();
  // return $('<div/>').text(value).html();
  // return extendedHTMLEncode(value);
};

// capitalize first letter of each word
String.prototype.titlecase = function () {
  return this.split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
};

// capitalize first letter of string
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

module.exports = Utils;
