require("./scss/button.scss");

var classNames = require("../../utils/classnames");
var PopUp = require("../generic/popup");
var Subfeeds = require("./subfeeds");
var TremrUtils = require("../../utils/tremr_utils");
var IconButton = require("../generic/iconbutton");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var UserAwareMixin = require("../../mixins/userawaremixin");

// Tremr.Follow.Button
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  propTypes: {
    subfeeds: PropTypes.array.isRequired,
    responsive: PropTypes.bool,
    title: PropTypes.string,
  },

  getDefaultProps: function () {
    return {
      responsive: false,
      title: null,
    };
  },

  getInitialState: function () {
    return {
      userSet: false,
      hover: false,
    };
  },

  // listen for user updating this elsewhere
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    var self = this;

    if (this.props.id) {
      // request update on whether the user is following or not
      this.listenTo(
        Tremr.dispatcher,
        "tremr:follow:" +
          this.props.entity +
          ":" +
          this.props.id.replace(/\W+/g, "_"),
        function (subfeeds) {
          if (this.props.entity == "tag") {
            this.setState({
              userSet: subfeeds,
            });
          } else {
            this.setState({
              userSet: subfeeds && subfeeds.length > 0,
            });
          }
        }.bind(this)
      );
    }
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.stopListening();
  },

  open: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.user) {
      // if we have a tag then toggle the state
      if (this.props.entity == "tag") {
        var data = {
          id: this.props.id,
          entity: this.props.entity,
          userSet: !this.state.userSet,
        };

        this.setState({
          open: false,
          userSet: !this.state.userSet,
        });

        Tremr.dispatcher.message(this, "tremr:follow", data);
      } else {
        var overlayData = {
          class: "Tremr.Follow.Menu",
          style: "sidebar",
          props: {
            user: this.state.user,
            entity: this.props.entity,
            id: this.props.id,
            subfeeds: this.props.subfeeds,
            title: this.props.title,
          },
        };
        Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
      }
    } else {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        callback: this.open,
        data: event,
      });
      return;
    }
  },

  // display
  render: function () {
    // see if we have loaded the image or not
    var classes = {
      "follow-button": true,
      set: this.state.userSet,
      hover: this.state.hover,
      responsive: this.props.responsive,
    };
    classes[this.props.entity] = true;
    classes = classNames(classes);

    var label = "Follow"; // …
    if (this.state.userSet) {
      label = "Following";
    }

    return (
      <div className="follow-button-container">
        <button className={classes} onClick={this.open}>
          {label}
        </button>
      </div>
    );
  },
});
