var classNames = require('../../utils/classnames');
var alertify = require('alertify');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// menu to allow user to upload an image or link to a video or post
// Tremr.Editor.Header
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      initialData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // an embed or image object
      initialMode: PropTypes.string, // unset, upload, embed
      initialCaption: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
      onChange: PropTypes.func,
      captionText: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      initialData: false,
      initialMode: 'unset',
      initialCaption: '',
      height: 200,
      width: 200,
      captionText: ''
    }
  },

  getInitialState: function() {
    return {
      data: this.props.initialData,
      mode: this.props.initialMode,
      caption: this.props.initialCaption
    };
  },

  uploadStartHandler: function() {

  },

  uploadUpdatedHandler: function(image) {

    this.setState({
      data: image
    });

    if (this.props.onChange) {
      this.props.onChange('image', image);
    }
  },

  uploadProgressHandler: function(progress) {

  },

  // when switching to image from other mode, start the upload!
  componentDidUpdate: function(prevProps, prevState) {

    if (prevState.mode != 'image' && this.state.mode == 'image') {
      this.refs['upload'].startUpload();
    } else if (this.state.mode == 'prompt-video' ||
               this.state.mode == 'prompt-twitter' ||
               this.state.mode == 'prompt-link') {

      this.refs['prompt'].focus();
    }
  },

  setImage: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      mode: 'image'
    });
  },

  setPromptVideo: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      mode: 'prompt-video'
    });
  },

  setPromptLink: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      mode: 'prompt-link'
    });
  },

  setPromptTwitter: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      mode: 'prompt-twitter'
    });
  },

  unset: function(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({
      mode: 'unset',
      data: false
    });

    if (this.props.onChange) {
      this.props.onChange('unset');
    }
  },

  // show loading
  startEmbed: function() {
    this.setState({
      mode: 'embed-loading',
      data: false
    });
  },

  setEmbed: function(data) {

    this.setState({
      mode: 'embed',
      data: data
    });

    if (this.props.onChange) {
      this.props.onChange('embed', data);
    }
  },

  embedError: function(message) {

    this.setState({
      mode: 'unset',
      data: {}
    });

    alertify.error(message);
  },

  changeCaption: function(caption) {

    this.setState({
      caption: caption.value
    });
  },

  render: function() {

    var classes = classNames({
      'header': true,
      'loading': this.state.mode == 'embed-loading',
      'unset': this.state.mode == 'unset'
    });

    // depends on type
    var content = null;
    var resetButton = <a onClick={this.unset} className="reset">
      <Tremr.Generic.SvgIcon icon="remove" />
    </a>;

    if (this.state.mode == 'image' ||
        this.state.mode == 'embed') {
      var caption = null;
      if (this.state.data) {
        caption = <Tremr.Editor.ContentEditable classNames={{caption: true}} initialValue={this.state.data.caption} onChange={this.changeCaption} maxLength={280} placeholder="Enter Caption…" />
      }
    }

    if (this.state.mode == 'image') {

      content = <div>
                  <Tremr.Generic.Imageupload ref="upload" buttonText="Upload" initialImage={this.state.data}
                  startHandler={this.uploadStartHandler} updatedHandler={this.uploadUpdatedHandler}
                  progressHandler={this.uploadProgressHandler}
                  showProgress={true} showPreview={true}
                  previewHeight={false} previewWidth={this.props.width} previewMaxHeight={5000} previewResize={true} />
                  {caption}
      </div>

    } else if (this.state.mode == 'prompt-video') {

      resetButton = null;
      content = <div className="prompt">
        <Tremr.Generic.EmbedPrompt ref="prompt" prompt="Paste video link…" onStart={this.startEmbed} onCancel={this.unset} onSuccess={this.setEmbed} onInvalid={this.embedError} />
      </div>

     } else if (this.state.mode == 'prompt-link') {

        resetButton = null;
        content = <div className="prompt">
          <Tremr.Generic.EmbedPrompt ref="prompt" prompt="Paste link…" onCancel={this.unset} onSuccess={this.setEmbed} onInvalid={this.embedError} />
        </div>;

    } else if (this.state.mode == 'prompt-twitter') {

      resetButton = null;
      content = <div className="prompt">
        <Tremr.Generic.EmbedPrompt ref="prompt" prompt="Paste link to tweet…" onCancel={this.unset} onSuccess={this.setEmbed} onInvalid={this.embedError} />
      </div>;

    } else if (this.state.mode == 'embed-loading') {

        resetButton = null;
        content = null;

    } else if (this.state.mode == 'embed') {

      content = <div className="embed-with-caption">
        <Tremr.Generic.Embed embed={this.state.data} maxHeight={5000} height={false} width="auto" />
        {caption}
      </div>;

    } else if (this.state.mode == 'unset') {

      // <a onClick={this.setPromptTwitter}><Tremr.Generic.SvgIcon icon="tweet" /></a>
      
      resetButton = null;
      content = <div className="options">
        <a onClick={this.setImage}><Tremr.Generic.SvgIcon icon="photo" /></a>
        <a onClick={this.setPromptLink}><Tremr.Generic.SvgIcon icon="link" /></a>
        <a onClick={this.setPromptVideo}><Tremr.Generic.SvgIcon icon="play" /></a>
      </div>;

    }

    return (

      <div className={classes}>
        {content}
        {resetButton}
      </div>
    );
  }
});
