// var UserAwareMixin = require('../../mixins/userawaremixin');

require("../layout/scss/staticpage.scss");
require("./scss/sociallink.scss");

var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');
var Theme = require("theme");

// ui for connecting social account to existing user
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  getDefaultProps: function() {
    return {
      // expects a user prop
    }
  },

  getInitialState: function() {
    return {
      hasRenderedSocialLink: false
    };
  },

  // register listeners
  componentDidMount: function() {

    // _.extend(this, Backbone.Events);
    if (!this.state.hasRenderedSocialLink) {
      this.renderSocialLink();
    }
  },

  componentWillUpdate: function() {

    if (!this.state.hasRenderedSocialLink) {
      this.renderSocialLink();
    }
  },

  renderSocialLink: function() {

    if (!Theme.RenderSocialLink || !this.props.user) {
      return;
    } else if (this.props.user.get('social')) {
      return;
    }

    this.setState({
      hasRenderedSocialLink: true
    });

    // add the social link
    var url = Tremr.current_url.protocol + "//" + Tremr.current_url.hostname;
    if (Tremr.current_url.port && Tremr.current_url.port != '80' && Tremr.current_url.port != '0') {
        url = url + ':' + Tremr.current_url.port;
    }
    var social_callback_script = url + '/api/social_link';
    var css = url + '/css/oneall.css';
    _oneall.push(['social_link', 'set_providers', ['twitter']]);
    _oneall.push(['social_link', 'set_grid_sizes', [1,1]]);
    _oneall.push(['social_link', 'set_custom_css_uri', css]);
    _oneall.push(['social_link', 'set_callback_uri', social_callback_script]);
    _oneall.push(['social_link', 'set_user_token', this.props.user.get('id')]);
    // _oneall.push(['social_link', 'set_event', 'on_login_end_success', function(arg) {
        // console.log("on_login_end_success");
    // }]);
    _oneall.push(['social_link', 'set_event', 'on_login_redirect', function(arg) {
        Tremr.dispatcher.message(this, "tremr:social_link", arg);

        return false;
    }.bind(this)]);

    _oneall.push(['social_link', 'do_render_ui', 'social_link']);

  },

  render: function() {

    if (!Theme.RenderSocialSignin) {
      return null;
    }

    return <div key="social_link" id="social_link"></div>;
  }

});
