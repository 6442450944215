// simple helper for reputation - especially the locations in firebase of data
let ReputationHelper = {};

ReputationHelper.nodes = {
  UserReputationStars: "/community/reputation/users/{user_id}/rep_stars",
  UserReputationBadge: "/community/reputation/users/{user_id}/active_badge",
  UserReputationTags: "/community/reputation/users/{user_id}/tags",
  PostReputationStars:
    "/community/reputation/users/{user_id}/posts/{post_id}/related_topics_rep_stars",
  PostReputationAutoTags:
    "/community/reputation/users/{user_id}/posts/{post_id}/auto_tags",
  PostReputationBadge:
    "/community/reputation/users/{user_id}/posts/{post_id}/active_badge",
};

ReputationHelper.substituteData = function (key, data) {
  let k = key;
  Object.keys(data).forEach(function (prop) {
    k = k.split("{" + prop + "}").join(data[prop]);
  });
  return k;
};

ReputationHelper.getDataPoint = function (name, key, data) {
  return {
    name: name,
    key: ReputationHelper.substituteData(key, data),
  };
};

ReputationHelper.getUserReputationStarsDataPoint = function (user_id) {
  return ReputationHelper.getDataPoint(
    "rep_stars",
    ReputationHelper.nodes.UserReputationStars,
    { user_id: user_id }
  );
};

ReputationHelper.getUserReputationBadgeDataPoint = function (user_id) {
  return ReputationHelper.getDataPoint(
    "badge",
    ReputationHelper.nodes.UserReputationBadge,
    { user_id: user_id }
  );
};

ReputationHelper.getUserReputationTagsDataPoint = function (user_id) {
  return ReputationHelper.getDataPoint(
    "tags",
    ReputationHelper.nodes.UserReputationTags,
    { user_id: user_id }
  );
};

ReputationHelper.getPostReputationStarsDataPoint = function (user_id, post_id) {
  return ReputationHelper.getDataPoint(
    "post_rep_stars",
    ReputationHelper.nodes.PostReputationStars,
    { user_id: user_id, post_id: post_id }
  );
};

ReputationHelper.getPostReputationAutoTagsDataPoint = function (
  user_id,
  post_id
) {
  return ReputationHelper.getDataPoint(
    "auto_tags",
    ReputationHelper.nodes.PostReputationAutoTags,
    { user_id: user_id, post_id: post_id }
  );
};

ReputationHelper.getPostReputationBadgeDataPoint = function (user_id, post_id) {
  return ReputationHelper.getDataPoint(
    "badge",
    ReputationHelper.nodes.PostReputationBadge,
    { user_id: user_id, post_id: post_id }
  );
};

ReputationHelper.renameDataPoint = function (dataPoint, newName) {
  return ReputationHelper.enhanceDataPoint(dataPoint, { name: newName });
};

ReputationHelper.enhanceDataPoint = function (dataPoint, newData) {
  Object.assign(dataPoint, newData);
  return dataPoint;
};

module.exports = ReputationHelper;
