require("./scss/tags.scss");

let React = require("react");
let CreateReactClass = require("create-react-class");
let Stars = require("../controls/stars");
let UserAwareMixin = require("../../mixins/userawaremixin");
let Config = require("config");

// designed to be wrapped with a withDBData and then passes liveDataPoints data
module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  getStars: function (count) {
    return (
      <Stars
        alignment="center"
        scale={window.devicePixelRatio}
        drawUnset={false}
        setColor={this.props.highlightColor}
        lineWidth={1}
        starSize={this.props.height}
        height={this.props.height}
        width={(this.props.height + 1) * count}
        stars={count}
        filledStars={count}
      />
    );
  },

  componentDidMount: function () {
    if (this.props.onRender) {
      this.props.onRender();
    }
  },

  componentDidUpdate: function () {
    if (this.props.onRender) {
      this.props.onRender();
    }
  },

  render: function () {
    if (
      Config.reputation == "admin" &&
      (!this.state.user || this.state.user.get("admin") != "yes")
    ) {
      return null;
    }

    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.tags &&
      this.props.liveDataPoints.tags.data
    ) {
      // sort the tags by rep_score and return top 3

      let keys = Object.keys(this.props.liveDataPoints.tags.data);
      if (keys.length > 0) {
        keys = keys.slice(0).sort((a, b) => {
          return (
            this.props.liveDataPoints.tags.data[b].rep_score -
            this.props.liveDataPoints.tags.data[a].rep_score
          );
        });
        keys = keys.slice(0, 3);

        let tags = [];
        keys.forEach((k) => {
          if (this.props.liveDataPoints.tags.data[k].rep_stars > 0) {
            let tagName = this.props.liveDataPoints.tags.data[k].tag;
            let tagStars = null;
            if (this.props.liveDataPoints.tags.data[k].rep_stars > 0) {
              tagStars = this.getStars(
                this.props.liveDataPoints.tags.data[k].rep_stars
              );
            }
            tags.push(
              <div key={tagName} className="reputation-tag">
                <div className="reputation-tag-name">{tagName}</div>
                {tagStars}
              </div>
            );
          }
        });

        return <div className="reputation-tags">{tags}</div>;
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
});
