// class for managing a representation of a paragraph and formatting within it
// Tremr.Editor.TextBlock
module.exports = function(identifier, type) {

	// local ref to utils
	var Ranges = window.Tremr.Utils.Ranges;

	this.allowedFormat = true; // should we show the format popup

	// default empty attributes
	this._attributes = {
		identifier: false,
        type: false,
        plainText: [], // stored as array of chars
        formatRules: [],
        tag: 'p'
	};

	// constructor params
	if (identifier !== undefined) { this._attributes.identifier = identifier; }
	if (type !== undefined) { this._attributes.type = type; }

	// traverse previous siblings, then into parent and traverse it's prev siblings
    // until we reach .block
    this.getOffsetInBlock = function(node, newLevel) {

      // if we ever reach the block (or no node), stop counting immediately
      if (!node || (node.nodeType == 1 && $(node).hasClass('block'))) {
        return 0;
      }

      var len = 0; // keep length

      // if this ISN'T a new level add it's length
      if (!newLevel) {
        if (node.textContent && node.textContent.length) {
          len = node.textContent.length
        }
      }

      // recurse to previous OR parent (set new level for parent)
      if (node && node.previousSibling) {
        len += this.getOffsetInBlock(node.previousSibling, false);
      } else if (node && node.parentNode) {
        len += this.getOffsetInBlock(node.parentNode, true);
      }

      return len;
    };

	// generate a react control
	this.getControl = function(notifyPosition, removeBlock, readOnly, width, height) {
		return <Tremr.Editor.TextControl readOnly={readOnly} ref={this.get('identifier')} key={this.get('identifier')} identifier={this.get('identifier')} tag={this.get('tag')} plainText={this.get('arrayText')} formatRules={this.get('formatRules')} notifyPosition={notifyPosition} />;
	};

	// iterate child nodes then siblings counting length until we reach the
	// offset we want, then return the node and the offset in that node
	this.findOffsetFromSubnode = function(node, offsetRemaining) {

		var returnValue = {
		  found: false,
		  node: false,
		  offset: offsetRemaining
		};

		// recurse into child nodes
		if (node && node.nodeType == 1 && node.hasChildNodes()) {

		  _.each(node.childNodes, function(child) {

		    if (!returnValue.found) {

		      if (child.tagName && child.tagName.toLowerCase() == "br") {
		        returnValue.node = child;
		      } else {
		        returnValue = this.findOffsetFromSubnode(child, returnValue.offset);
		      }
		    }

		  }.bind(this));

		} else {

		  // see if the text length of this node contains the offset
		  if (node.textContent.length >= offsetRemaining) {
		    // found!
		    returnValue.found = true;
		    returnValue.node = node;
		    returnValue.offset = offsetRemaining;

		  } else {
		    // not found, reduce offset remaining and return
		    returnValue.offset -= node.textContent.length;
		  }

		}

		return returnValue;

	};

	this.updateFromNodes = function(nodes) {

		var parsed = this.parseNodes(nodes);

		// update the block and fix overlaps etc.
        this.set('plainText', parsed.plainText);
        this.set('formatRules', parsed.formatRules);
        this.consolidateFormats();
	};


	// turn html nodes into plain text and format rules for this block
	  this.parseNodes = function(nodes) {

	    var plainText = '';
	    var formatRules = [];

	    _.each(nodes, function(node) {
	      //var n = node;
	      // add text on for text nodes
	      if (node.nodeType == 3) {
	        plainText += node.nodeValue;
	      } else if (node.nodeType == 1) { // element

	        // start a format for this position
	        var rule = {
	          format: node.tagName,
	          start: plainText.length
	        };

	        // special cases for certain tags
	        if (node.tagName == 'A' && node.getAttribute("href")) {
	          // allow href on anchor tags
	          rule.href = node.getAttribute("href");

	        } else if (node.tagName == 'B') {
	          // special case to switch b and i for strong and em
	          rule.format = 'STRONG';

	        } else if (node.tagName == 'I') {
	          // special case to switch b and i for strong and em
	          rule.format = 'EM';

	        }

	        // parse the child nodes
	        var parsed = this.parseNodes(node.childNodes);

	        // work out end of rule and add
	        rule.end = rule.start + parsed.plainText.length;
	        formatRules.push(rule);

	        // add the plain text
	        plainText += parsed.plainText;

	        // move rules from within this element into the whole and adjust their positions
	        _.each(parsed.formatRules, function(formatRule) {
	          formatRules.push(_.extend(_.clone(formatRule), {
	            start: rule.start + formatRule.start,
	            end: rule.end + formatRule.start
	          }));
	        });

	      }
	    }.bind(this));

	    // remove anything we are not allowing, or have zero content
	    formatRules = _.filter(formatRules, function(filterRule) {
	      return filterRule.start != filterRule.end && _.contains(['A', 'STRONG', 'EM'], filterRule.format);
	    });


	    return {
	      plainText: plainText,
	      formatRules: formatRules
	    };
	  },

	// create a copy, with new Ids
	this.clone = function(keepId) {

		var block = new Tremr.Editor.TextBlock('cloned', 'cloned');
		block._attributes = {
			identifier: this._attributes.identifier,
	        type: this._attributes.type,
	        plainText: _.map(this._attributes.plainText, _.clone),
	        formatRules: _.map(this._attributes.formatRules, _.clone),
	        tag: this._attributes.tag
		};
		if (!keepId) {
			block._attributes.identifier = Tremr.Utils.uniqueId('block');
		}
		return block;
	};

	// get an attribute
	this.get = function(name) {

		// special case to get plainText as string (or arrayText for array)
		if (name == 'plainText') {
			return this._attributes['plainText'].join('');
		} else if (name == 'arrayText') {
			return this._attributes['plainText'];
		}

		return this._attributes[name];
	};

	// set an attribute
	this.set = function(key, val) {

        // console.log("set:"+val);
		// special case to turn string plainText into array of chars
		if (key == 'plainText') {
			if (_.isString(val)) {
				val = this.createCharArray(val);
                // console.log("val:");
                // console.dir(val);
			}
		}

		this._attributes[key] = val;
	};

	// create an array of characters and entities from string
	this.createCharArray = function(val) {

		var charArray = [];
		var encoded = Tremr.Utils.htmlEncode(val); // encode html entities
        // console.log("encoded:"+encoded);
		var newString = encoded.replace(/([^&]*)(&#?\w+;)?([^&;]*)/gi, function(match, p1, p2, p3, offset, string) {

            // console.log("match:"+match);
            // console.log("p1:"+p1);
            // console.log("p2:"+p2);
            // console.log("p3:"+p3);
            // console.log("offset:"+offset);
            // console.log("string:"+string);
			var p1Array = [];
			var p2Array = [];
			var p3Array = [];

			if (p1 !== undefined && p1 !== '') { p1Array = p1.split(''); }
			if (p2 !== undefined && p2 !== '') { p2Array = [p2]; } // keep html entities intact (and one element in array)
			if (p3 !== undefined && p3 !== '') { p3Array = p3.split(''); }

	        charArray = charArray.concat(p1Array, p2Array, p3Array);
	    });
        // console.log("newString:");
        // console.dir(newString);

	    // for FF we need a trailing space to be a &nbsp; but why not
	    // convert non-trailing &nbsp; to normal spaces while we can
	    charArray = _.map(charArray, function(item, index) {
	    	return (item == '&nbsp;') ? ' ' : item;
	    });
        // console.log("charArray:");
        // console.dir(charArray);

	    // if (Tremr.Utils.checkForFirefox()) {
			if (_.last(charArray) == ' ') {
				charArray[charArray.length-1] = '&nbsp;';
			}
		// }

		return charArray;
	};

	// length of the plainText array
	this.length = function() {
		if (this._attributes.plainText) {
			return this._attributes.plainText.length;
		} else {
			return 0;
		}
	};

	// remove a range of characters from the plainText and remove/adjust rules to fit
	this.removeCharacters = function(start, end) {

		// remove the characters from the array
		this._attributes.plainText.splice(start, (end - start));
		// this._attributes.plainText = this._attributes.plainText.substring(0, start) + this._attributes.plainText.substring(end);

		// reduce start+end from all rules, removing any wholly outside new length
		this._attributes.formatRules = _.flatten(_.map(this._attributes.formatRules, function(rule) {

			// see if the rule needs to be adjusted on this rule
			var intersect = Ranges.intersection(rule, {start: start, end: end});

			switch (intersect) {
				case 'contained': // if rule is totally contained remove it
					return []; break;

				case 'after': // pull rule start and end back
				case 'end': // pull rule start and end back
					rule.start = rule.start - (end - start);
					if (rule.start < 0) { rule.start = 0; }
					rule.end = rule.end - (end - start);
					if (rule.end < 0) { rule.end = 0; }
					break;

				case 'container': // move end back based on length
					rule.end = rule.end - (end - start);
					if (rule.end < 0) { rule.end = 0; }
					break;

				case 'start': // leave start alone, pull end back
					rule.end = start;
					break;

				case 'before': // leave it alone
					break;
			}

			return rule;

		}.bind(this)), true);

	};

	// remove x characters from the plainText and remove/adjust rules to fit
	this.removeCharactersLeft = function(index) {

		this.removeCharacters(0, index);
	};

	// remove x characters from the plainText and remove/adjust rules to fit
	this.removeCharactersRight = function(index) {

		this.removeCharacters(index, this.length());
	};

	// add content and rules from another block to the end of this one
	this.append = function(block) {

		var adjustRule = this.length();

		// var plainText = this._attributes.plainText + block.get('plainText');
		// this.set('plainText', plainText);
		if (this._attributes.plainText) {
			this._attributes.plainText = this._attributes.plainText.concat(block.get('arrayText'));
		}

		var newRules = _.map(block.get('formatRules'), function(rule) {
			return _.extend(rule, {
				start: rule.start + adjustRule,
				end: rule.end + adjustRule
			});
		});

		if (this._attributes.formatRules) {
			this._attributes.formatRules = this._attributes.formatRules.concat(newRules);
		}
	};

	// split this block at the specified position in the plainText, return two new
	// blocks with adjusted format rules
	this.splitAt = function(leftIndex, rightIndex) {

		if (rightIndex === undefined) { rightIndex = leftIndex; }

		// create two new blocks, clones of this one
		var before = this.clone(false);
		var after = this.clone();

		// reduce characters from left/right from each
		before.removeCharactersRight(leftIndex);
		after.removeCharactersLeft(rightIndex);

		return [before, after];
	};

	// add a format rule for the specified position, if none
	// given then surround the whole block.
	this.setFormat = function(format, start, end, additional) {

		if (!start) { start = 0; }
		if (!end) { end = this.length(); }

		// just do it and allow the consolidation to deal with issues
		var rule = _.extend({
			format: format,
			start: start,
			end: end
		}, additional);
		if (!this._attributes.formatRules) {
			this._attributes.formatRules = [];
		}
		this._attributes.formatRules.push(rule);

		// fix any overlaps
		this.consolidateFormats();
	};

	// remove a format rule for the specified position, if none
	// given then remove all, this will reduce or push
	// other formats of this type if they extend beyond the limits here
	this.unsetFormat = function(format, start, end) {

		if (start == undefined) { start = 0; }
		if (end == undefined) { end = this.length(); }

		// iterate rules - any that cross over amend
		var rules = _.flatten(_.map(this._attributes.formatRules, function(rule, index) {

			// check how it intersects
			if (rule.format != format) {

				// different format always kept
				return [rule];

			} else {

				return Ranges.subtract({start: start, end: end}, rule);

			}

		}), true);

		this._attributes.formatRules = rules;
	};

	// join any rules of the same type that overlap or meet, then
	// compare every format in importance order (alpha) when a less important rule crosses
	// the boundry of a more important turn it into two rules - this will map 1:1 with tags
	this.consolidateFormats = function() {

		var tags = ['A', 'EM', 'STRONG'];

		// clone rules
		var rules = this._attributes.formatRules.slice(0);

		// iterate rules, adding to new set and eliminating those that are merged
		var deduped = _.map(rules, function(rule, index) {

			// if the rule has already been included in a previous one skip it
			if (rule.included === undefined) {

				rule.included = true;

				// check all other rules for ones that might be added to this one
				_.each(rules, function(checkRule, checkIndex) {

					// only check same formats that haven't already been checked
					if (checkRule.included === undefined && rule.format == checkRule.format) {

						// if rule is same type and overlaps mark it included
						// and return our unchecked rule modified to include this one
						if ((checkRule.start >= rule.start && checkRule.start <= rule.end) ||
							(checkRule.end >= rule.start && checkRule.start <= rule.end)) {

							// overlaps - mark included and expand out rule
							checkRule.included = true;

							if (rule.start > checkRule.start) {
								rule.start = checkRule.start;
							}

							if (rule.end < checkRule.end) {
								rule.end = checkRule.end;
							}
						}
					}

				});

				return rule;
			} else {
				return false;
			}

		});


		// remove the included flags and skipped elements
		deduped = _.filter(_.map(deduped, function(rule, index) {
			delete rule['included'];
			return rule;
		}), function(rule, index) {
			return (rule !== false);
		});

		// check tags that cross over
		_.each(tags, function(tag, index) {

			// index - 0, always leave
			if (index > 0) {

				// iterate all rules of different type that overlap edges (totally surrounded/contained is fine)
				deduped = _.flatten(_.map(deduped, function(rule, ruleIndex) {

					var includeRules = [rule]; // always return the rule (perhaps add more, if splitting)

					// we should be looking at EM or STRONG
					if (rule.format == tag) {

						// break this tag into two if it crosses these tags
						var breakOnTags = tags.slice(0, index);

						// check against other rules
						_.each(deduped, function(checkRule, checkRuleIndex) {

							// only of types, that are earlier in the tag order
							if (_.contains(breakOnTags, checkRule.format)) {

								// check of crosses one (not both) boundries
								if (rule.start < checkRule.start && rule.end > checkRule.start && rule.end < checkRule.end) {

									// breaks start boundry - split into two
									var afterRule = _.clone(rule);
									rule.end = checkRule.start;
									afterRule.start = checkRule.start;
									includeRules.push(afterRule);

								} else if (rule.start > checkRule.start && rule.start < checkRule.end && rule.end > checkRule.end) {

									// breaks end boundry - split into two
									var afterRule = _.clone(rule);
									rule.end = checkRule.end;
									afterRule.start = checkRule.end;
									includeRules.push(afterRule);

								}
							}
						});

					}

					return includeRules;

				}), true);

			}

		});

		this._attributes.formatRules = deduped;
	}


	// check if a position has a format
	this.hasFormat = function(format, position) {

		var has = false;

		_.each(this._attributes.formatRules, function(rule) {

			if (has == false &&
				format == rule.format &&
				position >= rule.start &&
				position < rule.end) {

				has = true;
			}
		});

		return has;
	}

	// check if a range contains one or more formats
	this.containsFormat = function(format, start, end) {

		if (end === undefined) { end = this.length(); }

		var has = false;

		_.each(this._attributes.formatRules, function(rule) {

			if (has == false &&
				format == rule.format) {

				// check bounds
				if (start < rule.end && end > rule.start) {

					has = true;
				}
			}
		});

		return has;
	}


}
