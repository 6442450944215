// mixin for any component to be kept up to date with user as they sign-in/out
// and setting that user in state.
module.exports = {

  // default to user from tremr
	getInitialState: function() {
    return {
      userUnchecked: Tremr.stores.userStore.isPendingUserCheck(),
      user: Tremr.stores.userStore.getUser()
		}
	},

  // hook into events
  componentDidMount: function() {

      if (!this.stopListening) { _.extend(this, Backbone.Events); }

      var user = Tremr.stores.userStore.getUser();
      if (!this.state.user && user) {
        this.setState({
          userUnchecked: false,
          user: user
        });
      }

      // listen for sign-in/out and update user in our session
      this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function(user) {
        this.setState({
          userUnchecked: false,
          user: user
        });

      }.bind(this));

      // listen for sign-in/out and update user in our session
      this.listenTo(Tremr.dispatcher, "tremr:user:signout:success", function() {
        this.setState({
          user: false
        });
      }.bind(this));

      this.listenTo(Tremr.dispatcher, "tremr:user:profile:success", function(user) {
        this.setState({
          user: user
        });

      }.bind(this));

      this.listenTo(Tremr.dispatcher, "tremr:user:signin:failed", function() {
        this.setState({
          userUnchecked: false
        });

      }.bind(this));

  },

  // stop listening to events
  componentWillUnmount: function() {

      if (this.stopListening) {
        this.stopListening();
      }
  }



};
