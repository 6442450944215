require("./scss/card.scss");

var classNames = require("../../utils/classnames");
var CardMixin = require("../../mixins/cardmixin");
var CreateReactClass = require("create-react-class");
var TremrUtils = require("../../utils/tremr_utils");

// Tremr.Tag.Card = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, CardMixin],

  getDefaultProps: function () {
    return {
      clickable: true,
    };
  },

  // open a card
  openCard: function (event) {
    event.stopPropagation();

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({
          primary: this.getTagContext(),
        });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    this.openPrimary(this.getTagContext());
  },

  componentDidMount: function () {
    if (this.props.loadedCallback) {
      _.defer(() => {
        // must be delayed
        this.props.loadedCallback();
      });
    }
  },

  // get the context for this users feed
  getTagContext: function () {
    return {
      target: "tag",
      tag: this.props.tag.get("tag"),
    };
  },

  // display
  render: function () {
    var divStyle = this.getCardStyle();

    // work out our top level class
    var classes = classNames({
      "tag-card": true,
      card: true,
      clickable: this.props.clickable,
    });

    var url = Tremr.routes.urlFor({
      primary: this.getTagContext(),
    });

    var getData = function (name, title) {
      return (
        <span className="data">
          <em>{this.props.tag.get(name)}</em>&nbsp;{title}
        </span>
      );
    }.bind(this);

    let hex = TremrUtils.stringToColor(this.props.tag.get("tag"));
    let avatarStyle = {
      background: hex,
    };

    let onClick = null;
    let h1 = <h1>{this.props.tag.get("tag")}</h1>;
    let avatar = (
      <div className="avatar-link" style={avatarStyle}>
        {this.props.tag.get("tag").substring(0, 2)}
      </div>
    );
    if (this.props.clickable) {
      onClick = this.openCard;
      h1 = (
        <h1>
          <a onClick={onClick} href={url}>
            {this.props.tag.get("tag")}
          </a>
        </h1>
      );
      avatar = (
        <a
          className="avatar-link"
          style={avatarStyle}
          onClick={onClick}
          href={url}
        >
          {this.props.tag.get("tag").substring(0, 2)}
        </a>
      );
    }

    return (
      <article onClick={onClick} className={classes} style={divStyle}>
        {avatar}
        <div className="content">
          <div className="row">
            {h1}
            <div className="actions"></div>
          </div>
          <p className="stats">
            {getData("posts", "posts")}
            {getData("followers", "followers")}
          </p>
          <Tremr.Follow.Button
            entity="tag"
            id={this.props.tag.get("tag")}
            loadedCallback={this.resizedCard}
          />
        </div>
      </article>
    );
  },
});
