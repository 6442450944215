require("./scss/edit.scss");

var classNames = require("../../utils/classnames");
var ReactUtils = require("../../utils/react_utils");
var Utils = require("../../utils/tremr_utils");
var alertify = require("alertify");
var UserAwareMixin = require("../../mixins/userawaremixin");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var EditMenu = require("./editmenu");
var Subfeeds = require("../editor/subfeeds");
var Contributors = require("./editcontributors");
var ColorPicker = require("../generic/colorpicker");
var Config = require("config");
var OptionItem = require("../generic/optionitem");

// var ColorNames = require('colornames');

// Tremr.Feed.Edit
module.exports = CreateReactClass({
  mixins: [
    UserAwareMixin, // can't be pure because we update model.contributors
  ],

  propTypes: {
    feedname: PropTypes.string,
    parentFeedname: PropTypes.string,
    tab: PropTypes.string,
  },

  getDefaultProps: function () {
    return {};
  },

  getInitialState: function () {
    let state = {
      isLoading: false,
      tab: this.props.tab || "info",
      promises: [],
      subfeeds: [],
      isEditing: false,
      custom_colors: [],
    };

    if (this.props.feedname) {
      state.model = new Tremr.Models.Feed({ id: this.props.feedname });
    } else {
      state.model = new Tremr.Models.Feed();
    }

    if (this.props.parentFeedname) {
      state.parent = new Tremr.Models.Feed({ id: this.props.parentFeedname });
    } else {
      state.parent = new Tremr.Models.Feed();
    }

    return state;
  },

  loadParent: function (name) {
    _.defer(() => {
      let newState = {};

      // load the data
      var promiseParent = Tremr.stores.feedStore.loadFeed(
        this.state.parent,
        name,
        this.props.cache
      );
      let promises = this.state.promises.slice(0);
      promises.push(promiseParent);

      newState.isLoading = true;
      newState.promises = promises;

      promiseParent.then(
        function (params) {
          var model = params.model;
          var data = params.data;
          var options = params.options;

          // // check the user is contributor of this feed
          // let user = Tremr.stores.userStore.getUser();
          // if (!user || !user.isEditorOfFeed(name, model)) {
          //     alertify.error("You do not have permission to edit "+model.get('title'));
          //     Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
          // }

          var gotFeed = !this.props.feedname || this.state.model.get("id");

          this.setState({
            isLoading: !gotFeed,
            parent: this.state.parent,
          });
        }.bind(this)
      );

      this.setState(newState);
    });
  },

  // register listeners
  componentDidMount: function () {
    if (this.state.highlight_color && !this.state.original_highlight_color) {
      this.onSelectColor(this.state.highlight_color);
      // this.setState({
      // 		original_highlight_color: document.documentElement.style.getPropertyValue('--highlight-color')
      // });
      //
      // // always default to standard color
      // // document.documentElement.style.setProperty('--highlight-color', this.state.highlight_color);
      // document.documentElement.style.setProperty('--highlight-color', Config.colors.highlight);
    } else if (!this.state.original_highlight_color) {
      this.onSelectColor(Config.colors.highlight);
    }

    _.extend(this, Backbone.Events);

    let newState = {};

    // if we have a parentFeedname then load it
    if (this.props.parentFeedname) {
      this.loadParent(this.props.parentFeedname);
    }

    // if we have a feedname then load it
    if (this.props.feedname) {
      // load the data
      var promiseFeed = Tremr.stores.feedStore.loadFeed(
        this.state.model,
        this.props.feedname,
        this.props.cache
      );
      let promises = this.state.promises.slice(0);
      promises.push(promiseFeed);

      newState.isLoading = true;
      newState.promises = promises;

      promiseFeed.then(
        function (params) {
          var model = params.model;
          var data = params.data;
          var options = params.options;

          // check the user is editor of this feed
          // check the user is contributor of this feed
          let user = Tremr.stores.userStore.getUser();
          if (
            !user ||
            (!user.isEditorOfFeed(name, model) && !user.get("admin") == "yes")
          ) {
            alertify.error(
              "You do not have permission to edit " + model.get("title")
            );
            Tremr.dispatcher.message(
              this,
              "tremr:close:overlay",
              this.props.index
            );
          }

          var gotParent =
            !this.props.parentFeedname || this.state.parent.get("id");

          // load the parent
          if (this.state.model.get("parent_feedname")) {
            this.loadParent(this.state.model.get("parent_feedname"));
          }

          this.setState({
            isLoading: !gotParent,
            model: this.state.model,
            isEditing: true,
            highlight_color: model.get("highlight_color"),
            custom_colors: model.get("custom_colors") || [],
            slug: this.state.model.get("name"),
          });
        }.bind(this)
      );
    } else {
      // set default data from user
      let contributors = [];
      contributors.push({
        role: "editor",
        id: this.state.user.get("_id"),
        name: this.state.user.get("name"),
        feedname: this.state.user.get("feedname"),
        bio: this.state.user.get("bio"),
        avatar_image: this.state.user.get("avatar_image"),
      });
      let model = this.state.model;
      model.set("contributors", contributors);
      newState.model = model;
    }

    this.setState(newState);

    // save success, just close
    this.listenTo(
      Tremr.dispatcher,
      "tremr:feed:edit:success",
      function (user) {
        Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
        alertify.log(
          "Your Blogcast change(s) may take a moment to be processed"
        );

        // redirect now
        _.defer(() => {
          let feedContext = {
            target: "feed",
            scope: "posts",
            subfeed: "featured",
            feed: this.props.feedname || this.state.model.get("name"),
          };

          let scrollpos = window.scrollY;
          Tremr.navigatePrimary(feedContext, scrollpos);
        });
      }.bind(this)
    );

    // delete success
    this.listenTo(Tremr.dispatcher, "tremr:feed:delete:success", function (
      errors
    ) {
      Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
      alertify.log("Your channel delete may take a moment to be processed");

      // redirect now
      _.defer(() => {
        let feedContext = {
          target: "feed",
          scope: "all",
          subfeed: "featured",
          feed: this.state.user.get("feedname"),
        };
        let scrollpos = window.scrollY;
        Tremr.navigatePrimary(feedContext, scrollpos);
      });
    });

    // error saving, display errors
    this.listenTo(
      Tremr.dispatcher,
      "tremr:feed:edit:failed",
      function (errors) {
        _.each(
          _.pairs(errors),
          function (error) {
            _.delay(() => {
              if (this.refs[error[0].toString()]) {
                var newState = {
                  error: true,
                  errorMessage: error[1],
                  tab: "info",
                };
                this.refs[error[0]].setState(newState);
              }
            }, 500);
          }.bind(this)
        );

        this.setState({
          isLoading: false,
          tab: "info",
        });
      }.bind(this)
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    if (this.state.original_highlight_color) {
      // document.documentElement.style.setProperty(
      //   "--highlight-color",
      //   this.state.original_highlight_color
      // );
      Tremr.setHighlightColor(this.state.original_highlight_color);
    }

    this.state.promises = _.reject(this.state.promises, function (promise) {
      promise.cancel();
      return true;
    });

    this.stopListening();
  },

  handleDelete: function (event) {
    event.preventDefault();
    event.stopPropagation();

    let data = {
      id: this.props.feedname,
    };
    Tremr.dispatcher.message(this, "tremr:feed:delete", data);
  },

  handleSave: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    let data = this.state.model.attributes;
    if (this.state.parent) {
      data.parentFeedname = this.props.parentFeedname;
    }
    data.user = this.state.user.get("feedname");

    if (this.props.showTags) {
      data.channel_tags = this.state.tags;
    }

    // check mandatory fields
    let areErrors = false;
    if (!data.title || data.title == "") {
      areErrors = true;
      _.delay(() => {
        this.refs.title.setState({
          error: true,
          errorMessage: "Please enter a Channel Name",
        });
      }, 500);
    }
    if (this.refs.name && (!data.name || data.name == "")) {
      areErrors = true;
      _.delay(() => {
        this.refs.name.setState({
          error: true,
          errorMessage: "Please enter a Channel Address",
        });
      }, 500);
    }

    // start save
    if (!areErrors) {
      this.setState({ isLoading: true });

      // create a feed and save to Server
      console.log("SAVE:");
      console.dir(data);

      Tremr.dispatcher.message(this, "tremr:feed:save", data);
    } else {
      this.setState({ tab: "info" });
    }
  },
  buttonText: PropTypes.string,

  tabInfo: function (event) {
    this.setState({ tab: "info" });
  },

  tabTeam: function (event) {
    this.setState({ tab: "team" });
  },

  changeTags: function (tags) {
    let model = this.state.model;
    model.set("tags", tags);

    this.setState({ model: model });
  },

  changeName: function (value) {
    let model = this.state.model;
    var slugValidation = ReactUtils.validateSlug(value);
    var feedname = slugValidation.value;

    this.refs.name.setState({
      value: feedname,
      error: !slugValidation.valid,
      errorMessage: slugValidation.message,
    });

    model.set("name", feedname);
    this.setState({ model: model, slug: feedname });
  },

  changeTitle: function (value) {
    let model = this.state.model;
    let newState = {};
    if (!this.props.feedname) {
      var slugValidation = ReactUtils.validateSlug(value);
      var feedname = slugValidation.value;

      this.refs.name.setState({
        value: feedname,
        error: !slugValidation.valid,
        errorMessage: slugValidation.message,
      });
      model.set("name", feedname);
      newState.slug = feedname;
    }

    model.set("title", value);
    newState.model = model;

    this.setState(newState);
  },

  changeBio: function (value) {
    let model = this.state.model;
    model.set("bio", value);

    this.setState({ model: model, bioLength: value.length });
  },

  // changeHighlightColor: function(value) {
  // 	let model = this.state.model;
  // 	model.set('highlight_color', value);
  //
  // 	this.setState({model: model});
  // },

  changeTwitter: function (value) {
    let model = this.state.model;
    model.set("twitter", value);

    this.setState({ model: model });
  },

  changeFacebook: function (value) {
    let model = this.state.model;
    model.set("facebook", value);

    this.setState({ model: model });
  },

  changeInstagram: function (value) {
    let model = this.state.model;
    model.set("instagram", value);

    this.setState({ model: model });
  },

  changeWeb: function (value) {
    let model = this.state.model;
    model.set("blog", value);

    this.setState({ model: model });
  },

  changeChannelIcon: function (value) {
    let model = this.state.model;
    model.set("avatar_image", value);

    this.setState({ model: model });
  },

  changeBackgroundImage: function (value) {
    let model = this.state.model;
    model.set("header_image", value);

    this.setState({ model: model });
  },

  changeSubfeeds: function (subfeeds) {
    let model = this.state.model;
    model.set("subfeeds", subfeeds);

    this.setState({ model: model });
  },

  setContributorPermission: function (id, permission) {
    // set permission to contributor
    let model = this.state.model;
    let contributors = model.get("contributors");

    contributors = contributors.map(function (c, i) {
      if (c.id == id) {
        if (c.role == "editor") {
          // can't unset owner - so always set as admin
          alertify.error("You cannot remove the owners admin permission.");
          c.permission = "admin";
        } else {
          c.permission = permission;
        }
      }
      return c;
    });

    model.set("contributors", contributors);
    this.setState({
      model: model,
    });
  },

  changeContributors: function (change, data) {
    if (change == "set-channel-admin") {
      // set permission to admin
      this.setContributorPermission(data, "admin");
    } else if (change == "remove-channel-admin") {
      // check isn't owner - they cannot be removed
      this.setContributorPermission(data, "contributor");
    } else if (change == "add-pending") {
      let model = this.state.model;

      let pendingContributors = model.get("pending_contributors");
      if (!pendingContributors) {
        pendingContributors = [];
      }

      let contributors = model.get("contributors");
      if (!contributors) {
        contributors = [];
      }

      // check if we already have that user and reject
      let alreadyIncluded = false;
      _.each(pendingContributors, (pc) => {
        if (pc.id == data.id) {
          alreadyIncluded = true;
        }
      });
      _.each(contributors, (c) => {
        if (c.id == data.id) {
          alreadyIncluded = true;
        }
      });

      if (alreadyIncluded) {
        alertify.error("User is already a contributor (or pending)");
      } else {
        // remove from removed_contributors
        let removed_contributors = model.get("removed_contributors");
        if (!removed_contributors) {
          removed_contributors = [];
        }
        removed_contributors = _.reject(removed_contributors, (item) => {
          return item.id == data.id;
        });

        pendingContributors.unshift(data);
        model.set("pending_contributors", pendingContributors);
        model.set("removed_contributors", removed_contributors);
        this.setState({
          model: model,
          countPending: pendingContributors.length, // force render
        });
      }
    } else if (change == "remove-pending") {
      let model = this.state.model;
      let pendingContributors = model.get("pending_contributors");
      if (!pendingContributors) {
        pendingContributors = [];
      }

      // keep track of removed users so we can send that to the server
      let removed_contributors = model.get("removed_contributors");
      if (!removed_contributors) {
        removed_contributors = [];
      }

      // remove
      pendingContributors = _.reject(pendingContributors, (item) => {
        if (item.id == data) {
          removed_contributors.push(item);
          return true;
        } else {
          return false;
        }
      });

      model.set("removed_contributors", removed_contributors);
      model.set("pending_contributors", pendingContributors);
      this.setState({
        model: model,
        countPending: pendingContributors.length, // force render
      });
    } else if (change == "remove-contributor") {
      let model = this.state.model;
      let contributors = model.get("contributors");
      if (!contributors) {
        contributors = [];
      }

      // keep track of removed users so we can send that to the server
      let removed_contributors = model.get("removed_contributors");
      if (!removed_contributors) {
        removed_contributors = [];
      }

      // remove
      contributors = _.reject(contributors, (item) => {
        // don't reject if editor
        if (item.id == data) {
          if (item.role == "editor") {
            alertify.error("Cannot remove owner");
            return false;
          } else {
            removed_contributors.push(item);
            return true;
          }
        } else {
          return false;
        }
      });

      model.set("contributors", contributors);
      model.set("removed_contributors", removed_contributors);
      this.setState({
        model: model,
        countContributors: contributors.length, // force render
      });
    }
  },

  onAddColor: function (c) {
    let model = this.state.model;
    model.set("custom_colors", this.state.custom_colors.concat([c]));

    let newState = {
      highlight_color: c,
      custom_colors: this.state.custom_colors.concat([c]),
      model: model,
    };

    if (!this.state.original_highlight_color) {
      newState.original_highlight_color = document.documentElement.style.getPropertyValue(
        "--highlight-color"
      );
    }
    // document.documentElement.style.setProperty("--highlight-color", c);
    Tremr.setHighlightColor(c);

    this.setState(newState);
  },

  onSelectColor: function (c) {
    let model = this.state.model;
    model.set("highlight_color", c);

    let newState = {
      highlight_color: c,
      model: model,
    };

    if (!this.state.original_highlight_color) {
      newState.original_highlight_color = document.documentElement.style.getPropertyValue(
        "--highlight-color"
      );
    }
    // document.documentElement.style.setProperty("--highlight-color", c);
    Tremr.setHighlightColor(c);

    this.setState(newState);
  },

  setNewsletterChanged: function (data) {
    console.log(data);
    let model = this.state.model;
    let current = model.get("has_newsletter");
    console.log(current);
    if (current && current != "no") {
      current = "no";
    } else {
      current = "yes";
    }
    model.set("has_newsletter", current);

    this.setState({ model: model });
  },

  setNewsletterPromotedChanged: function (data) {
    console.log(data);
    let model = this.state.model;
    let current = model.get("newsletter_promoted");
    console.log(current);
    if (current && current != "no") {
      current = "no";
    } else {
      current = "yes";
    }
    model.set("newsletter_promoted", current);

    this.setState({ model: model });
  },

  changeNewsletter: function (value) {
    let model = this.state.model;
    model.set("newsletter", value);

    this.setState({ model: model });
  },

  changeNewsletterPromo: function (value) {
    let model = this.state.model;
    model.set("newsletter_promo", value);

    this.setState({ model: model });
  },

  render: function () {
    var buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading,
    });

    var buttonLabel = "Update Profile";
    if (this.state.isLoading) {
      buttonLabel = "Updating Profile";
    }

    var upload = <Tremr.Generic.SvgIcon icon="plus" />;

    let tags = (
      <div className="field channel-tags">
        <Tremr.Generic.TagEditor
          ref="tageditor"
          onChange={this.changeTags}
          initialTags={this.state.model.get("tags")}
        />
      </div>
    );

    let subfeeds = null;
    if (this.state.parent) {
      subfeeds = (
        <Subfeeds
          key={"subfeeds"}
          title="Posting in these feeds:"
          selectedSubfeeds={this.state.model.get("subfeeds")}
          onChange={this.changeSubfeeds}
          subfeeds={this.state.parent.get("subfeeds")}
        />
      );
    }

    let title = "New Blogcast";
    if (this.props.feedname && this.state.model) {
      title = this.state.model.get("title") || "";
    }

    // <Tremr.Generic.Input ref="highlight_color" onChange={this.changeHighlightColor} name="highlight_color" label="Channel Color" placeholder={this.state.model.get('highlight_color')} type="text" initialValue={this.state.model.get('highlight_color')} maxLength={12}/>

    let highlightColor = Config.colors.highlight;
    if (this.state.highlight_color) {
      highlightColor = this.state.highlight_color;
    }

    let nameField = null;
    if (!this.props.feedname) {
      nameField = (
        <Tremr.Generic.Input
          ref="name"
          name="name"
          onChange={this.changeName}
          validation={ReactUtils.validateSlug}
          label="Blogcast Address"
          placeholder="Enter a unique address for your Blogcast"
          type="text"
          initialValue={this.state.model.get("name")}
          maxLength={50}
        >
          <div className="slug">www.tremr.com/{this.state.slug}</div>
        </Tremr.Generic.Input>
      );
    }

    let content = null;
    if (this.state.tab == "info" && this.state.model && !this.state.isLoading) {
      content = (
        <div className="fields tx2">
          <Tremr.Generic.Input
            ref="title"
            name="title"
            onChange={this.changeTitle}
            label="Blogcast Name"
            placeholder="Enter your Blogcast's name"
            type="text"
            initialValue={this.state.model.get("title")}
            maxLength={50}
          />

          {nameField}

          <Tremr.Generic.Input
            ref="bio"
            name="bio"
            onChange={this.changeBio}
            multiline={true}
            label="Description"
            type="text"
            initialValue={this.state.model.get("bio")}
            maxLength={140}
          >
            <div className="meta char-count">{this.state.bioLength}/140</div>
          </Tremr.Generic.Input>

          <div className="field channel-icon image-upload-field">
            <label>Blogcast Icon</label>
            <div className="row">
              <p className="help">
                This works like a user icon and appears in previews of your
                Blogcast content throughout Tremr. It is square and should be
                at least 60x60px in size.
              </p>
              <Tremr.Generic.Imageupload
                ref="avatar_image"
                updatedHandler={this.changeChannelIcon}
                showPlaceholder={true}
                buttonText={upload}
                initialImage={this.state.model.get("avatar_image")}
                previewImage={true}
                previewHeight={"auto"}
                previewWidth={"auto"}
              />
            </div>
          </div>

          <div className="field channel-background image-upload-field">
            <label>Background Image</label>
            <p className="help">
              This works like a user icon and appears in previews of your
              Blogcast content throughout Tremr. It is landscape and should
              be at least 768x432px in size.
            </p>
            <Tremr.Generic.Imageupload
              ref="header_image"
              updatedHandler={this.changeBackgroundImage}
              showPlaceholder={true}
              buttonText={React.cloneElement(upload)}
              initialImage={this.state.model.get("header_image")}
              previewImage={true}
              previewHeight={"auto"}
              previewWidth={"auto"}
            />
          </div>

          <h3>Theme Color</h3>

          <ColorPicker
            ref="colorpicker"
            colors={this.state.custom_colors}
            selectedColor={highlightColor}
            addColor={this.onAddColor}
            selectColor={this.onSelectColor}
          />

          <h3>Social</h3>

          <Tremr.Generic.Input
            ref="twitter"
            onChange={this.changeTwitter}
            name="twitter"
            label="Twitter"
            placeholder="@yourtwitterhandle"
            type="text"
            initialValue={this.state.model.get("twitter")}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="facebook"
            onChange={this.changeFacebook}
            name="facebook"
            label="Facebook"
            placeholder="www.facebook.com/…"
            type="text"
            initialValue={this.state.model.get("facebook")}
            maxLength={250}
          />
          <Tremr.Generic.Input
            ref="instagram"
            onChange={this.changeInstagram}
            name="instagram"
            label="Instagram"
            placeholder="@yourinstagramhandle"
            type="text"
            initialValue={this.state.model.get("instagram")}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="blog"
            onChange={this.changeWeb}
            name="blog"
            label="Web"
            placeholder="www.website.com"
            type="text"
            initialValue={this.state.model.get("blog")}
            maxLength={250}
          />

          {/* <div className="field newsletter-section">
            <label>Newsletter</label>
            <p>
              <em>
                If you operate a newsletter you can associate it with your
                Blogcast
              </em>
            </p>
            <div className="option-list">
              <OptionItem
                onChange={this.setNewsletterChanged}
                label="Associate a newsletter with this Blogcast."
                value="has-newsletter"
                set={
                  this.state.model.get("has_newsletter") &&
                  this.state.model.get("has_newsletter") != "no"
                }
                readonly={false}
              />
            </div>
            <Tremr.Generic.Input
              ref="newsletter"
              onChange={this.changeNewsletter}
              name="newsletter"
              placeholder="www.newsletter-sign-up-page.com"
              type="text"
              initialValue={this.state.model.get("newsletter")}
              maxLength={250}
            />

            <div className="option-list">
              <OptionItem
                onChange={this.setNewsletterPromotedChanged}
                label="Promote your newsletter on all your posts."
                value="has-newsletter-promoted"
                set={
                  this.state.model.get("newsletter_promoted") &&
                  this.state.model.get("newsletter_promoted") != "no"
                }
                readonly={false}
              />
            </div>
            <Tremr.Generic.Input
              ref="newsletter_promo"
              onChange={this.changeNewsletterPromo}
              name="newsletterPromo"
              placeholder="This text will appear at the bottom of all your posts."
              type="text"
              initialValue={this.state.model.get("newsletter_promo")}
              maxLength={250}
            />
          </div> */}
        </div>
      );
    }
    if (this.state.tab == "team" && this.state.model) {
      content = (
        <Contributors
          contributors={this.state.model.get("contributors")}
          pendingContributors={this.state.model.get("pending_contributors")}
          onChange={this.changeContributors}
        />
      );
    }

    var classes = classNames({
      "feed-edit": true,
      loading: this.state.isLoading,
    });

    let saveText = "Launch";
    if (this.state.isEditing) {
      saveText = "Save";
    }

    return (
      <div className={classes}>
        <EditMenu
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          buttonText={saveText}
        >
          <div className="controls">
            <div className="title">{title}</div>
            {tags}
            {subfeeds}
          </div>
        </EditMenu>

        <h2>{title}</h2>

        <div className="tabs">
          <div
            className={"handle " + (this.state.tab == "info" ? " active" : "")}
            onClick={this.tabInfo}
          >
            Info
          </div>
          <div
            className={"handle " + (this.state.tab == "team" ? " active" : "")}
            onClick={this.tabTeam}
          >
            Members
          </div>
        </div>

        {content}
      </div>
    );
  },
});
