require("./scss/highlightlist.scss");

var CreateReactClass = require('create-react-class');

// drop down and content for secondary column
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  getInitialState: function() {
    return {
      activeTab: 'trending'
    }
  },

  // fire event to tell the wall to switch tab
  switchTab: function(event) {

    var newTab = $(event.currentTarget).attr('data-value');
    this.setState({
      activeTab: newTab
    });

  },

  loadMore: function() {

    if (this.refs['trendingList'] && this.refs['trendingList'].loadMore) {
      this.refs['trendingList'].loadMore();
    }
    if (this.refs['recommendedList'] && this.refs['recommendedList'].loadMore) {
      this.refs['recommendedList'].loadMore();
    }
  },

  render: function() {

    let wall = null;
    if (this.state.activeTab == 'trending') {
      wall = Tremr.stores.postStore.getTrendingList(false);
    } else if (this.state.activeTab == 'recommended') {
      wall = Tremr.stores.postStore.getRecommendedList(false);
    }

    return (
        <div className="layout-highlightlist">

          <Tremr.Generic.Dropdown ref="tabDropdown" label={this.state.activeTab}>
            <div key="trending" onClick={this.switchTab} data-value="trending">trending</div>
            <div key="recommended" onClick={this.switchTab} data-value="recommended">recommended</div>
          </Tremr.Generic.Dropdown>

          {wall}
        </div>
    );
  }

});
