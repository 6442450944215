require("./scss/popup.scss");

var CreateReactClass = require('create-react-class');

// SVG based logo, allowing us to add styles
// Tremr.Generic.Logo = CreateReactClass({
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	handleClose: function(event) {

		event.preventDefault();
		event.stopPropagation();

		this.props.onClose(event);
	},

	render: function() {

		return <div className="popup">
			<div className="content">{this.props.children}</div>
			<a className="close" onClick={this.handleClose}>
			  <Tremr.Generic.SvgIcon icon="remove" />
			</a>
		</div>
	}
});
