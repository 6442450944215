var Modernizr = require("!webpack-modernizr-loader!../plugins/.modernizrrc");

// card mixin for grid and lists on walls.
module.exports = {
  // default to position off screen
  getInitialState: function () {
    return {
      top: this.props.startTop,
      left: this.props.startWidth,
      height: 0,
    };
  },

  // after added to DOM we need to read the height and
  // tell the grid so it can arrange the other cards
  componentDidMount: function () {
    // this is managed by the grids componentDidUpdate method,
    // we just need to do this when the card has changed i.e. data gets loaded
    // this.resizedCard();
  },

  resizedCard: function () {
    var domNode = ReactDOM.findDOMNode(this);
    var height = $(domNode).height();

    if (this.props.cardHeightSet) {
      // rely on grid to set the height along with position info
      this.props.cardHeightSet(height);
    } else {
      // if we don't have a callback then set our own height
      this.setState({
        height: height,
      });
    }
  },

  // let the component decide if it can be shown at multi-column sizes
  // based on the model
  allowSize: function (size) {
    if (this._allowSize) {
      return this._allowSize(size);
    }
    return !(size > 1);
  },

  // open a card in current, keeping the scroll position
  openCurrent: function (context) {
    // var domNode = ReactDOM.findDOMNode(this);
    // var scrollpos = $(domNode).closest('.primary').scrollTop(); // get scroll pos of container
    let scrollpos = window.scrollY;
    Tremr.navigateCurrent(context, scrollpos);
  },

  // open a card in primary, keeping the scroll position
  openPrimary: function (context) {
    // var domNode = ReactDOM.findDOMNode(this);
    // var scrollpos = $(domNode).closest('.primary').scrollTop(); // get scroll pos of container
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(context, scrollpos);
  },

  // set styles for position
  getCardStyle: function () {
    // if we have a position then set it specifically
    var divStyle = {};

    if (this.state.top !== undefined && this.state.left !== undefined) {
      // use top/left if transitions not supported (or we have a percent!)
      if (!Modernizr.csstransitions) {
        divStyle = {
          position: "absolute",
          float: "none",
          top: this.state.top,
          left: this.state.left,
          width: this.state.width,
        };
      } else {
        // use vendor prefixed transitions
        divStyle = {
          position: "absolute",
          float: "none",
          width: this.state.width,
        };
        divStyle[Modernizr.prefixed("transform")] =
          "translateX(" +
          this.state.left +
          "px) translateY(" +
          this.state.top +
          "px)";
      }

      if (this.state.zIndex) {
        divStyle.zIndex = this.state.zIndex;
      }
    }

    return divStyle;
  },
};
