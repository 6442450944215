require("./scss/header.scss");

var TremrTracking = require("../../utils/tracking");
var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var TremrUtils = require("../../utils/tremr_utils");
var Share = require("../generic/share");
var Config = require("config");
var HorizontalScroller = require("../generic/horizontalscroller");
var FirebaseEncode = require("firebase-encode");

// import {
//   encode,
//   decode,
//   encodeComponents,
//   decodeComponents,
// } from 'firebase-encode';

// menu at the top of the primary position
// Tremr.Tag.Header = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  promises: [], // promise references so we can cancel on unmount

  getInitialState: function () {
    return {
      isLoading: true,
      loggedSeen: false,
      model: new Tremr.Models.Tag({
        id: this.props.context.tag,
      }),
    };
  },

  // load data from server when first added to DOM
  componentDidMount: function () {
    // set the tag colour
    if (this.props.context.ui != "home") {
      let hex = TremrUtils.stringToColor(this.props.context.tag);
      // document.documentElement.style.setProperty('--highlight-color', hex);
      Tremr.setHighlightColor(hex);
    }

    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // listen for sign-in/out and update user in our session
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signin:success",
      function (user) {
        this.logSeen(user, null);

        this.setState({
          userUnchecked: false,
          user: user,
        });
      }.bind(this)
    );

    var promise = Tremr.stores.tagStore.loadTag(
      this.state.model,
      this.props.context.tag,
      this.props.cache
    );
    this.promises.push(promise);
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.logSeen(null, this.state.model);

        TremrTracking.logView("feed", this.props.context.tag);

        this.setState({
          isLoading: false,
          model: this.state.model,
        });

        // trigger loading of activity for this entity
        Tremr.dispatcher.message(this, "tremr:follow:forentity", {
          entity: "tag",
          id: this.props.context.tag,
        });
      }.bind(this)
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    // return hard-coded highlight color
    if (this.props.context.ui != "home") {
      // document.documentElement.style.setProperty(
      //   "--highlight-color",
      //   Config.colors.highlight
      // );
      Tremr.setHighlightColor(Config.colors.highlight);
    }

    this.promises = _.reject(this.promises, function (promise) {
      promise.cancel();
      return true;
    });

    if (this.stopListening) {
      this.stopListening();
    }
  },

  // navigate to the new url allowing the routes to sort out context
  handleClick: function (event, data) {
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(
      this.getContext(data.scope, data.sort),
      scrollpos,
      undefined,
      false
    );
  },

  handleSubmenuClick: function (event, data) {
    let context = this.getContext(data.scope, data.sort);
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(context, scrollpos, undefined, false);
  },

  getContext: function (tab, sort) {
    if (sort == undefined) {
      sort = this.props.context.sort;
    }
    if (tab == undefined) {
      tab = this.props.context.scope;
    }
    let context = {
      target: "tag",
      scope: tab,
      tag: this.props.context.tag,
      sort: sort,
    };
    if (this.props.context.ui == "home") {
      context.ui = "home";
    }
    return context;
  },

  getUrl: function (tab, sort) {
    return Tremr.routes.urlFor({
      primary: this.getContext(tab, sort),
    });
  },

  logSeen: function (user, model) {
    if (!user) {
      user = this.state.user;
    }
    if (!model) {
      model = this.state.model;
    }

    // if we have loaded the user and the model
    if (model && user && !this.state.loggedSeen) {
      let user_id = user.get("_id") || user.get("id");
      let fbSeenRef = Tremr.firebase
        .database()
        .ref(
          "tag-seen/" +
            user_id +
            "/" +
            FirebaseEncode.encode(this.state.model.get("tag"))
        );

      fbSeenRef.once("value").then(
        function (snapshot) {
          // update only after looking for value
          fbSeenRef.set({
            count: model.get("posts"),
            seen_at: new Date().getTime(),
          });

          // fire event to let people know last time we saw this feed
          let data = snapshot.val();
          if (data) {
            data.tag = this.state.model.get("tag"); // add tag name
            Tremr.dispatcher.message(this, "tremr:tag:lastseen", data);
          }
        }.bind(this)
      );

      // ensure we only do this once
      this.setState({
        loggedSeen: true,
      });
    }
  },

  navigateNonHome: function () {
    let nonhomepagecontext = this.getContext("posts", "latest");
    nonhomepagecontext.ui = null;
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(nonhomepagecontext, scrollpos, undefined, false);
  },

  render: function () {
    var self = this;

    var classes = {
      header: true,
      tag: true,
      loading: this.state.isLoading,
      homeui: this.props.context.ui == "home",
    };
    classes["tag-" + this.props.context.tag.replace(/\s+/g, "-")] = true;
    classes = classNames(classes);

    let share = null;
    let shareUrl = this.getUrl("posts");
    share = (
      <Share
        addthisSuffix={"tag_" + this.props.context.tag.replace(/\s+/g, "-")}
        moreIcon={<Tremr.Generic.SvgIcon icon="share-reverse" />}
        icons={["more"]}
        url={shareUrl}
        title={
          this.props.context.tag
            ? this.props.context.tag.capitalize().replace(/&[^;]{2,3,4};/g, " ")
            : ""
        }
        description={
          this.props.context.tag
            ? this.props.context.tag.replace(/&[^;]{2,3,4};/g + " on Tremr", " ")
            : ""
        }
      />
    );

    let follow = (
      <Tremr.Follow.Button entity="tag" id={this.props.context.tag} />
    );

    // <Tremr.Generic.StatTab href={this.getUrl('featured')} handleClick={this.handleClick} value="featured" data={this.state.model.get('featured')} label="Featured" active={(this.props.context.scope == 'featured')} />
    let tabs = (
      <div className="tabs">
        <Tremr.Generic.StatTab
          href={this.getUrl("posts", "latest")}
          handleClick={this.handleClick}
          value={{ scope: "posts", sort: "latest" }}
          data={this.state.model.get("posts")}
          label="Posts"
          active={this.props.context.scope == "posts"}
        />
        <Tremr.Generic.StatTab
          href={this.getUrl("followers", "latest")}
          handleClick={self.handleClick}
          value={{ scope: "followers", sort: "latest" }}
          data={self.state.model.get("followers")}
          label="Followers"
          active={self.props.context.scope == "followers"}
        />
      </div>
    );

    let submenu = null;
    if (this.props.context.scope == "posts") {
      submenu = (
        <nav className="menu">
          <Tremr.Generic.Tab
            handleClick={this.handleSubmenuClick}
            href={this.getUrl("posts", "featured")}
            data={{ scope: "posts", sort: "featured" }}
            text="Featured"
            active={this.props.context.sort == "featured"}
          />
          <Tremr.Generic.Tab
            handleClick={this.handleSubmenuClick}
            href={this.getUrl("posts", "latest")}
            data={{ scope: "posts", sort: "latest" }}
            text="All Posts"
            active={this.props.context.sort == "latest"}
          />
          <Tremr.Generic.Tab
            handleClick={this.handleSubmenuClick}
            href={this.getUrl("followers", "recommended")}
            data={{ scope: "posts", sort: "recommended" }}
            text="Top Rated"
            active={this.props.context.sort == "recommended"}
          />
          
          {/*
          // BRENT - removed Trending option below until trending algorithm is updated
          <Tremr.Generic.Tab
             handleClick={this.handleSubmenuClick}
             href={this.getUrl("followers", "trending")}
             data={{ scope: "posts", sort: "trending" }}
             text="Trending"
             active={this.props.context.sort == "trending"}
           />
           */}

        </nav>
      );
    } else if (this.props.context.scope == "followers") {
      submenu = (
        <nav className="menu">
          <Tremr.Generic.Tab
            key="latest"
            href={this.getUrl("followers", "latest")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "followers", sort: "latest" }}
            text="Latest"
            active={
              this.props.context.scope == "followers" &&
              this.props.context.sort == "latest"
            }
          />
          <Tremr.Generic.Tab
            key="alphabetical"
            href={this.getUrl("followers", "alphabetical")}
            handleClick={this.handleSubmenuClick}
            data={{ scope: "followers", sort: "alphabetical" }}
            text="A-Z"
            active={
              this.props.context.scope == "followers" &&
              this.props.context.sort == "alphabetical"
            }
          />
        </nav>
      );
    }

    // very simple version for showing in the homepage UI style
    if (this.props.context.ui == "home") {
      let nonhomepagecontext = this.getContext("posts", "latest");
      nonhomepagecontext.ui = null;
      let nonhomepageurl = Tremr.routes.urlFor({
        primary: nonhomepagecontext,
      });
      let title = (
        <a
          className="tagName"
          href={nonhomepageurl}
          onClick={this.navigateNonHome}
        >
          {this.props.context.tag.capitalize()}
        </a>
      );
      return (
        <div className={classes}>
          <HorizontalScroller>
            <div className="tag-posts-subfeeds-summary">
              {title}
              {submenu}
            </div>
          </HorizontalScroller>
        </div>
      );
    }

    if (submenu) {
      submenu = <HorizontalScroller>{submenu}</HorizontalScroller>;
    }

    return (
      <div className={classes}>
        <div className="content">
          <div className="info">
            <h1>
              <Tremr.Generic.Abbreviations
                text={this.props.context.tag.capitalize()}
              />
            </h1>
          </div>
          <div className="actions-tabs-container">
            <div className="actions">
              {share}
              {follow}
            </div>
            {tabs}
          </div>
          {submenu}
        </div>
      </div>
    );
  },
});
