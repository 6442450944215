require("./scss/endorse.scss");

let classNames = require("../../utils/classnames");
let TremrUtils = require("../../utils/tremr_utils");
let IconButton = require("../generic/iconbutton");
let PropTypes = require("prop-types");
let CreateReactClass = require("create-react-class");
let SvgIcon = require("../generic/svgicon");
// let UserAwareMixin = require("../../mixins/userawaremixin");
let UserEndorse = require("../activity/userendorse");
let ContentEditable = require("../editor/contenteditable");
let alertify = require("alertify");

module.exports = CreateReactClass({
  // mixins: [UserAwareMixin],

  // promises: [], // so we can cancel on unmount

  propTypes: {
    user_id: PropTypes.string.isRequired,
  },

  getInitialState: function () {
    return {
      reportComment: "",
    };
  },

  componentDidMount: function () {
    let commentKey = "endorsements/{ofuser}/reports/{user}/comment";
    commentKey = commentKey.replace(/\{ofuser\}/, this.props.user_id);
    commentKey = commentKey.replace(/\{user\}/, this.props.user.get("_id"));

    let commentRef = Tremr.firebase.database().ref(commentKey);
    commentRef.once("value", (snapshot) => {
      this.refs["reportComment"].setState({
        value: snapshot.val(),
      });
    });

    this.setState({
      commentRef: commentRef,
    });
  },

  componentWillUnmount: function () {
    if (this.state.commentRef) {
      this.state.commentRef.off("value");
    }
  },

  submit: function (event) {
    event.preventDefault();
    event.stopPropagation();

    // write the comment and submit to the FB database
    this.state.commentRef.set(this.refs["reportComment"].state.value);

    let submitKey = "endorsements/{ofuser}/reports/{user}/submitted";
    submitKey = submitKey.replace(/\{ofuser\}/, this.props.user_id);
    submitKey = submitKey.replace(/\{user\}/, this.props.user.get("_id"));

    let submitRef = Tremr.firebase.database().ref(submitKey);
    submitRef.set(Date.now());

    alertify.success("Endorsement Submitted");

    this.props.onClose();
  },

  learnAbout: function (event) {
    event.stopPropagation();
  },

  // display
  render: function () {
    if (!this.props.user) {
      return null;
    }

    // see if we have loaded the image or not
    let classes = {
      "post-report": true,
    };
    classes = classNames(classes);

    // <div className="row post-title">
    //   "{this.props.post_title}" by {this.props.post_author}
    // </div>

    return (
      <div className="post-report-container sidebar">
        <div className="post-report-toolbar toolbar">
          <div className="row">
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
          </div>
          <div className="row title">Endorse Member</div>
          <div className="row">
            Which aspects of this member's content do you appreciate?
          </div>
          <div className="row help">
            <a
              href="https://support.tremr.com/article/36-endorsements"
              target="_blank"
              onClick={this.learnAbout}
            >
              Learn about endorsements
            </a>
          </div>
        </div>
        <div className="controls">
          <UserEndorse user_id={this.props.user_id} />
          <div className="line expandable">
            <ContentEditable
              key="report-comment"
              ref="reportComment"
              classNames={{ contenteditable: true, reportComment: true }}
              initialValue={this.state.reportComment}
              placeholder="Add a comment…"
            />
          </div>
          <p>
            <div className="button save" onClick={this.submit}>
              <span>Submit</span>
            </div>
          </p>
        </div>
      </div>
    );
  },
});
