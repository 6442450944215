var CreateReactClass = require('create-react-class');

// SVG based icon, allowing us to add styles
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultProps: function() {
		return {
			baseColor: '#2d2d2d',
            level: 0,
            onClick: _.noop
		}
	},

	render: function() {

        let classes1 = "";
        let classes2 = "";
        let classes3 = "";
        if (this.props.level < 0) {
            classes1 = "set";
        }
        if (this.props.level < -1) {
            classes2 = "set";
        }
        if (this.props.level < -2) {
            classes3 = "set";
        }

		return (
			<svg
             onClick={this.props.onClick}
             className="activity-icon-downvote"
			 viewBox="0 0 512 512">
             <g id="downvote">
               <path className={classes1} id="level-1" d="M328.393,202 C338.334,202 346.393,210.059 346.393,220 L346.393,240.377 C346.393,244.795 349.974,248.377 354.393,248.377 L400.04,248.377 C405.541,248.377 410.741,250.893 414.155,255.207 C420.324,263.003 419.005,274.323 411.209,280.492 L282.062,382.685 C266.789,394.77 245.211,394.77 229.938,382.685 L100.791,280.492 C96.476,277.078 93.96,271.879 93.96,266.377 C93.96,256.436 102.019,248.377 111.96,248.377 L160.839,248.377 C165.257,248.377 168.839,244.795 168.839,240.377 L168.839,220 C168.839,210.059 176.898,202 186.839,202 L328.393,202 z M326.393,222 L188.839,222 L188.839,240.377 C188.839,255.841 176.303,268.377 160.839,268.377 L117.711,268.377 L242.348,367.001 C250.348,373.332 261.651,373.332 269.651,367.001 L394.289,268.377 L354.393,268.377 C338.929,268.377 326.393,255.841 326.393,240.377 L326.393,222 z"/>
               <path className={classes2} id="level-2" d="M339,159.5 C344.523,159.5 349,163.977 349,169.5 C349,175.023 344.523,179.5 339,179.5 L177,179.5 C171.477,179.5 167,175.023 167,169.5 C167,163.977 171.477,159.5 177,159.5 L339,159.5 z"/>
               <path className={classes3} id="level-3" d="M339,117.5 C344.523,117.5 349,121.977 349,127.5 C349,133.023 344.523,137.5 339,137.5 L177,137.5 C171.477,137.5 167,133.023 167,127.5 C167,121.977 171.477,117.5 177,117.5 L339,117.5 z"/>
             </g>
			</svg>
		);
	}
});
