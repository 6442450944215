var UnseenCount = require('../generic/unseencount');
var CreateReactClass = require('create-react-class');
var classNames = require('../../utils/classnames');
var ChannelAvatar = require('./channelavatar');
var Config = require('config');

// Tremr.Feed.ListItem
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

  // open a card in primary, keeping the scroll position
    openPrimary: function(context) {

        // var scrollpos;
        // var domNode = ReactDOM.findDOMNode(this);
        // scrollpos = $(domNode).closest('.current, .primary').scrollTop(); // get scroll pos of container
        let scrollpos = window.scrollY;

        Tremr.navigatePrimary(context, scrollpos);
    },

    // open a card
    openCard: function(event) {

        event.stopPropagation();

        // if meta key, allow normal open - for container use
        // data-href to open in new tab
        if (event.metaKey) {
            if (event.currentTarget && !event.currentTarget.getAttribute('href')) {
                var url = Tremr.routes.urlFor({
                    primary: this.getUserContext()
                });
                window.open(url);
            }
            return;
        }

        event.preventDefault();

        if (this.props.closeSidebarOnClick) {
            Tremr.dispatcher.message(this, "tremr:layout:leftsidebarcloseonmobile");
        }

        this.openPrimary(this.getUserContext());
    },

    // get the context for this users feed
    getUserContext: function() {
        let context = {
            target: 'feed',
            scope: 'posts',
            feed: this.props.feed.get('name'),
            subfeed: 'featured',
            ui: 'home'
        };

        if (this.props.feed && this.props.feed.get('followedsubfeed')) {
            context.subfeed = this.props.feed.get('followedsubfeed');
        }

        return context;
    },

    // display
    render: function() {

        let classes = classNames({
            active: this.props.active,
            channel: this.props.feed.get('channel') == 'yes'
        });

        let avatar_image = null;
        if (this.props.feed.get('author_avatar_image')) {
            avatar_image = <Tremr.Generic.Image key={"avatar"+this.props.feed.get('author_avatar_image').public_id} height={46} width={46} image={this.props.feed.get('author_avatar_image')} />;
        } else if (this.props.feed.get('channel') == "yes") {

            let color = Config.colors.highlight;
            if (this.props.feed.get('highlight_color')) {
              color = this.props.feed.get('highlight_color');
            }
            avatar_image = <ChannelAvatar title={this.props.feed.get('author_name')} color={color} />;
        }

        let url = Tremr.routes.urlFor({
            primary: this.getUserContext()
        });

        let subfeed = '';
        let feedTotalKey = 'feed-stats/' + this.props.feed.get('name');
        let feedSeenKey = 'feed-seen/{user}/' + this.props.feed.get('name');

        if (this.props.feed && this.props.feed.get('followedsubfeed')) {
            subfeed = ' ' + this.props.feed.get('followedsubfeed');
            feedTotalKey = feedTotalKey + '/subfeeds/' + this.props.feed.get('followedsubfeed');
            feedSeenKey = feedSeenKey + '/subfeeds/' + this.props.feed.get('followedsubfeed');
        } else {
            feedTotalKey = feedTotalKey + '/posts';
            feedSeenKey = feedSeenKey + '/count';
        }

        return (
        <div className='feed-listitem'>
            <a className={classes} onClick={this.openCard} href={url} title={this.props.feed.get('author_name') + subfeed}>
                {avatar_image}
                <UnseenCount total_path={feedTotalKey} seen_path={feedSeenKey} />
            </a>
        </div>);
    }
});
