var GridLayouts = require("../../stores/generic/gridlayouts");
var Footer = require("../../stores/layout/footer");
var PostListCardWrapper = require("../post/listcardwrapper");
var WallControls = require("../post/wallcontrols");
var PostContentCard = require("../post/contentcard");
var SimpleCard = require("../post/simplecard");
var ChannelInvite = require("../../models/ChannelInvite");
var alertify = require("alertify");
var Contributors = require("../../models/Contributors");
var Channels = require("../../models/Channels");
var WallControls = require("../post/wallcontrols");
var Endorsements = require("../../models/Endorsements");

// Tremr.FeedStore
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  // cache info
  store.cacheTimeout = 30;

  // load feed from the server using a prebuilt backbone model
  store.loadFeed = function (model, feedname, cache) {
    return Tremr.Utils.loadModel(
      model,
      {
        id: feedname,
      },
      cache
    );
  };

  // load headline post for this feed
  this.loadHeadline = function (model, tagname, cache) {
    return Tremr.Utils.loadModel(
      model,
      {
        id: tagname,
      },
      cache,
      true
    );
  };

  // trigger loading of activity for these posts
  this.requestActivityForPosts = function (data) {
    // if (data.length > 0) {
    //    var ids = data.map(function(model) {
    //       return model.id;
    //    });
    //    Tremr.dispatcher.message(this, "tremr:activity:forpost", {post_ids: ids});
    // }
  }.bind(this);

  // trigger loading of follow data (i.e. the follow button) on users or tags
  this.requestFollowingForEntity = function (data) {
    if (data.length > 0) {
      var ids = data.map(function (model) {
        let attributes = model;
        if (model.attributes) {
          attributes = model.attributes;
        }

        var key = {
          entity: "feed",
          id: attributes.name,
        };
        if (model.type == "tag") {
          key = {
            entity: "tag",
            id: attributes.tag,
          };
        }
        return key;
      });

      Tremr.dispatcher.message(this, "tremr:follow:forentity", ids);
    }
  }.bind(this);

  this.requestEndorsementsForUser = function (data) {
    if (data.length > 0) {
      var ids = data.map(function (model) {
        let attributes = model;
        if (model.attributes) {
          attributes = model.attributes;
        }

        var key = {
          entity: "feed",
          id: attributes.name,
        };
        return key;
      });

      Tremr.dispatcher.message(this, "tremr:follow:forentity", ids);
    }
  }.bind(this);

  // factory for getting headline content (wall calls this)
  (this.getHeadlineContent = function (context) {}),
    // factory for footer content
    (this.getStaffPicksFooterContent = function (context) {
      var tabs = store.getTabs(context);
      var defaultTab = store.getDefaultTab(context);

      var key = "postsWall";
      if (context.scope == "followers") {
        key = "followersWall";
      } else if (context.scope == "following") {
        key = "followingWall";
      } else if (context.scope == "drafts") {
        key = "draftsWall";
      }

      var Wallref = key + "." + context.feed + "." + context.scope;
      var ref = "footer" + Wallref;

      return (
        <Footer ref={ref} key={ref}>
          <section>
            <Tremr.Generic.SvgIcon icon="star" />
            <label>{Tremr.Theme.HomepageFeedTitle}</label>
          </section>
          <Tremr.Layout.Filter
            tipPrefix="Order by "
            icons={true}
            wallIdentifier={Wallref}
            tabs={tabs}
            initialTab={defaultTab}
          />
        </Footer>
      );
    });

  // factory for footer content
  // this.getFooterContent = function(context) {
  //
  //    var tabs = store.getTabs(context);
  //    var defaultTab = store.getDefaultTab(context);
  //
  //    var key = "postsWall";
  //    if (context.subfeed) {
  //       key = key + "-" + context.subfeed;
  //    }
  //    if (context.scope == 'followers') {
  //       key = "followersWall";
  //    } else if (context.scope == 'following') {
  //       key = "followingWall";
  //    } else if (context.scope == 'drafts') {
  //       key = "draftsWall";
  //    }
  //
  //    var Wallref = key + "." + context.feed + "." + context.scope;
  //    var ref = "footer" + Wallref;
  //
  //    return <Footer>
  //       <Tremr.Feed.Footer ref={ref} key={ref} wallIdentifier={Wallref} context={context} tabs={tabs} initialTab={defaultTab}/>
  //    </Footer>;
  // };

  // factory for getting small list of recent content
  this.getMoreFromFeedList = function (feed, post_id, cache, loadedCallback) {
    let tabs = ["Latest"];
    let defaultTab = "Latest";

    let context = {
      target: "feed",
      feed: feed,
      sort: "latest",
      scope: "all",
      subfeed: "featured",
    };

    let datasources = this.getDataSources(context);

    let key = "moreFromFeedPostsWall" + context.sort + "." + context.scope;

    let cardFactory = function (model, index, options) {
      let model_post_id = model.get("slug").split("!")[1];
      if (
        index > 5 ||
        model.get("uievent") ||
        model.get("slug") == feed + "!" + post_id ||
        model.get("channel_author_feed") + "!" + model_post_id ==
          feed + "!" + post_id
      ) {
        return null;
      }
      var key = "simplecard-" + index + "-" + model.get("id");
      return (
        <SimpleCard
          key={key}
          post={model}
          loadedCallback={options.loadedCallback}
        />
      );
    };

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="moreFromFeedList"
        identifier={key}
        loadedCallback={loadedCallback}
        cardFactory={cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the content for a state
  this.getPrimaryContent = function (context, cache) {
    var tabs = store.getTabs(context);
    var defaultTab = store.getDefaultTab(context);
    var datasources = store.getDataSources(context);

    var cardFactory = this.postcardFactory;
    var collFactory = this.postsFactory;
    var key = "postsWall";
    let controls = null;
    let wallStyleProperty = null;
    let forceGridStyle = null;
    let loadedCallbackWait = true;

    if (context.subfeed) {
      key = key + "-" + context.subfeed;
    }
    let combinedLoadedCallback = this.requestActivityForPosts;

    if (context.scope == "channels") {
      cardFactory = this.usercardFactory;
      collFactory = this.channelsFactory;
      key = "channelsWall";
      if (context.sort) {
        key = key + "-" + context.sort;
      }
      forceGridStyle = "list";
      combinedLoadedCallback = this.requestFollowingForEntity;
      loadedCallbackWait = false;
    } else if (context.scope == "contributors") {
      cardFactory = this.usercardFactory;
      collFactory = this.contributorsFactory;
      key = "contributorsWall";
      if (context.sort) {
        key = key + "-" + context.sort;
      }
      forceGridStyle = "list";
      combinedLoadedCallback = this.requestFollowingForEntity;
      loadedCallbackWait = false;
    } else if (context.scope == "followers") {
      cardFactory = this.usercardFactory;
      collFactory = this.followersFactory;
      key = "followersWall";
      if (context.sort) {
        key = key + "-" + context.sort;
      }
      forceGridStyle = "list";
      combinedLoadedCallback = this.requestFollowingForEntity;
      loadedCallbackWait = false;
    } else if (context.scope == "following") {
      cardFactory = this.followingcardFactory;
      collFactory = this.followingFactory;
      key = "followingWall";
      if (context.sort) {
        key = key + "-" + context.sort;
      }
      forceGridStyle = "list";
      combinedLoadedCallback = this.requestFollowingForEntity;
      loadedCallbackWait = false;
    } else if (context.scope == "endorsements") {
      cardFactory = this.endorsementcardFactory;
      collFactory = this.endorsementsFactory;
      key = "endorsementsWall";
      if (context.sort) {
        key = key + "-" + context.sort;
      }
      forceGridStyle = "list";
      combinedLoadedCallback = this.requestFollowingForEntity;
      loadedCallbackWait = false;
    } else if (context.scope == "drafts") {
      cardFactory = this.draftcardFactory;
      collFactory = this.draftsFactory;
      combinedLoadedCallback = _.noop;
      key = "draftsWall";
      loadedCallbackWait = false;
    } else if (context.scope == "posts" || context.scope == "all") {
      // special case for the posts wall, to allow for alternate views

      controls = <WallControls wallStyleProperty="wallStylePosts" />;
      wallStyleProperty = "wallStylePosts";

      let scrollToPos = function () {
        window.scrollTo(0, context.initialScrollPos);
      };

      // call both callbacks
      combinedLoadedCallback = function (data, wallStyle) {
        let fullContext = Tremr.context.getState();
        let isCached = fullContext.cache == true;

        _.defer(() => {
          this.requestActivityForPosts(data);
          // if (wallStyle == 'content' && !isCached) {
          //    this.requestContentForPosts(data);
          // }
        });

        if (isCached) {
          Tremr.context.add({
            cache: false,
          });
          // scroll to initial position
          if (context && context.initialScrollPos) {
            scrollToPos();
          }
        }
      }.bind(this);
    }

    key += "." + context.feed + "." + context.scope;

    if (context.ui == "home") {
      return (
        <Tremr.Generic.Wall
          loadedCallbackWait={loadedCallbackWait}
          forceGridStyle={forceGridStyle}
          wallStyleProperty={wallStyleProperty}
          controls={controls}
          key={key}
          ref="primaryComponent"
          identifier={key}
          loadedCallback={combinedLoadedCallback}
          cardFactory={cardFactory}
          collectionFactory={collFactory}
          cache={cache}
          datasources={datasources}
          defaultTab={defaultTab}
          tabs={tabs}
          context={context}
        />
      );
    } else {
      return (
        <Tremr.Generic.Wall
          loadedCallbackWait={loadedCallbackWait}
          forceGridStyle={forceGridStyle}
          wallStyleProperty={wallStyleProperty}
          controls={controls}
          key={key}
          ref="primaryComponent"
          identifier={key}
          loadedCallback={combinedLoadedCallback}
          cardFactory={cardFactory}
          collectionFactory={collFactory}
          cache={cache}
          datasources={datasources}
          defaultTab={defaultTab}
          tabs={tabs}
          context={context}
        />
      );
    }
  };

  this.postsFactory = function () {
    return new Tremr.Models.Posts();
  };

  this.followingFactory = function () {
    return new Tremr.Models.Following();
  };

  this.followersFactory = function () {
    return new Tremr.Models.Followers();
  };

  this.endorsementsFactory = function () {
    return new Endorsements();
  };

  this.contributorsFactory = function () {
    return new Contributors();
  };

  this.channelsFactory = function () {
    return new Channels();
  };

  this.draftsFactory = function () {
    return new Tremr.Models.Drafts();
  };

  this.draftcardFactory = function (model, index, options) {
    var id = model.get("id") || model.get("_id");
    var key = "card-" + index + "-" + id;

    var key = "card-" + index + "-" + model.get("id");
    return (
      <Tremr.Post.DraftCard
        ref={key}
        key={key}
        post={model}
        startWidth={options.startWidth}
        startTop={options.startTop}
        cardHeightSet={options.cardHeightSet}
      />
    );
  };

  this.followingcardFactory = function (model, index, options) {
    var id = model.get("id") || model.get("_id");
    var key = "card-" + index + "-" + id;

    // check if this is a user or a tag
    if (model.get("type") && model.get("type") == "tag") {
      return (
        <Tremr.Tag.Card
          ref={key}
          key={key}
          tag={model}
          startWidth={options.startWidth}
          startTop={options.startTop}
          cardHeightSet={options.cardHeightSet}
        />
      );
    } else {
      return (
        <Tremr.Feed.Card
          ref={key}
          key={key}
          feed={model}
          startWidth={options.startWidth}
          startTop={options.startTop}
          cardHeightSet={options.cardHeightSet}
        />
      );
    }
  };

  this.endorsementcardFactory = function (model, index, options) {
    var key = "card-" + index + "-" + model.get("id");
    return (
      <Tremr.Feed.Card
        ref={key}
        key={key}
        feed={model}
        startWidth={options.startWidth}
        startTop={options.startTop}
        cardHeightSet={options.cardHeightSet}
      />
    );
  };

  this.usercardFactory = function (model, index, options) {
    var key = "card-" + index + "-" + model.get("id");
    return (
      <Tremr.Feed.Card
        ref={key}
        key={key}
        feed={model}
        startWidth={options.startWidth}
        startTop={options.startTop}
        cardHeightSet={options.cardHeightSet}
      />
    );
  };

  this.postcardFactory = function (model, index, options) {
    let identifier = "";
    if (options && options.identifier) {
      identifier = options.identifier;
    }

    var key = "card-" + index + "-" + model.get("id");

    if (options.wallStyle == "content") {
      return (
        <PostContentCard
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else if (options.wallStyle == "list") {
      return (
        <PostListCardWrapper
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else {
      return (
        <Tremr.Post.Card
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
          cardHeightSet={options.cardHeightSet}
        />
      );
    }
  };

  // get named tabs that we want to show for specific context
  this.getTabs = function (context) {
    if (context.scope == "drafts") {
      return ["Latest"];
    } else if (context.scope == "channels") {
      return ["latest", "alphabetical"];
    } else if (context.scope == "contributors") {
      return ["Oldest", "A-Z"];
    } else if (context.scope == "followers" || context.scope == "following") {
      return ["latest", "alphabetical"];
    } else if (context.scope == "endorsements") {
      return ["latest", "A-Z"];
    } else {
      return ["Trending", "Top Rated", "Latest"].reverse();
    }
  };

  // get default tab for specific context
  store.getDefaultTab = function (context) {
    if (context.scope == "contributors") {
      return "Oldest";
    } else if (
      context.scope == "following" ||
      context.scope == "followers" ||
      context.scope == "endorsements" ||
      context.scope == "channels"
    ) {
      return "latest";
    } else {
      return "Latest";
    }
  };

  this.loadContributors = function (page, collection, context, cache) {
    context.src = "feed.contributors";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  this.loadChannels = function (page, collection, context, cache) {
    context.src = "feed.channels";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  this.loadEndorsements = function (page, collection, context, cache) {
    context.src = "feed.endorsements";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  // get named data source function for a specific context
  store.getDataSources = function (context) {
    // do in a function and use dynamically in datasources
    var getDataForContext = function (c) {
      var data = {
        target: c.target,
      };
      if (c.target == "homepage-feed") {
        data.target = "feed";
        data.id = c.feed;
      }
      if (c.scope && c.scope == "posts") {
        data.scope = "all";
      } else if (c.scope) {
        data.scope = c.scope;
      }
      if (c.target == "feed") {
        data.id = c.feed;
      }
      if (!["all", "recommended", "trending"].includes(c.subfeed)) {
        if (c.subfeeds == "1") {
          data.subfeeds = "1";
        }
        if (c.subfeed) {
          data.subfeed = c.subfeed;
        }
      }
      if (["recommended", "trending"].includes(c.subfeed)) {
        data.sort = c.subfeed;
      } else if (c.target == "feed" && c.sort) {
        data.sort = c.sort;
      }

      return data;
    };

    if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "notifications"
    ) {
      var sources = [];

      sources["Latest"] = function (page, notifications, cntxt, cache) {
        return Tremr.stores.activityStore.loadNotifications(
          page,
          notifications,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "drafts"
    ) {
      var sources = [];

      sources["Latest"] = function (page, drafts, cntxt, cache) {
        return Tremr.stores.postStore.loadDrafts(
          page,
          drafts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "following"
    ) {
      var sources = [];

      sources["latest"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.followStore.loadFollowing(
          page,
          followers,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["alphabetical"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.followStore.loadFollowing(
          page,
          followers,
          _.extend(
            {
              sort: "alphabetical",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "followers"
    ) {
      var sources = [];

      sources["latest"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.followStore.loadFollowers(
          page,
          followers,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["alphabetical"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.followStore.loadFollowers(
          page,
          followers,
          _.extend(
            {
              sort: "alphabetical",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "endorsements"
    ) {
      var sources = [];

      sources["latest"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.feedStore.loadEndorsements(
          page,
          followers,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["alphabetical"] = function (page, followers, cntxt, cache) {
        return Tremr.stores.feedStore.loadEndorsements(
          page,
          followers,
          _.extend(
            {
              sort: "alphabetical",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "contributors"
    ) {
      var sources = [];

      sources["Oldest"] = function (page, contributors, cntxt, cache) {
        return Tremr.stores.feedStore.loadContributors(
          page,
          contributors,
          _.extend(
            {
              sort: "oldest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["A-Z"] = function (page, contributors, cntxt, cache) {
        return Tremr.stores.feedStore.loadContributors(
          page,
          contributors,
          _.extend(
            {
              sort: "alphabetical",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      context.target == "feed" &&
      context.scope &&
      context.scope == "channels"
    ) {
      var sources = [];

      sources["latest"] = function (page, channels, cntxt, cache) {
        return Tremr.stores.feedStore.loadChannels(
          page,
          channels,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["alphabetical"] = function (page, channels, cntxt, cache) {
        return Tremr.stores.feedStore.loadChannels(
          page,
          channels,
          _.extend(
            {
              sort: "alphabetical",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };

      return sources;
    } else if (
      context.target &&
      (context.target == "feed" || context.target == "homepage-feed")
    ) {
      var sources = [];

      sources["Trending"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "trending",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["Top Rated"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "recommended",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      sources["Latest"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      return sources;
    } else {
      console.log("DataSource not implemented for:");
      console.dir(context);
    }
  };

  // open create/edit channel
  this.listenTo(
    Tremr.dispatcher,
    "tremr:feed:edit",
    function (data) {
      var overlayData = {
        class: "Tremr.Feed.Edit",
        modal: true,
        props: {},
      };
      if (data.feedname) {
        overlayData.props.feedname = data.feedname;
      }
      if (data.parentFeedname) {
        overlayData.props.parentFeedname = data.parentFeedname;
      }
      if (data.tab) {
        overlayData.props.tab = data.tab;
      }
      overlayData.handleClose = function (overlayIndex) {
        alertify.confirm(
          "Close Blogcast editor and lose all changes, including Blogcast invitations?",
          function (e) {
            if (e) {
              Tremr.dispatcher.message(
                this,
                "tremr:close:overlay",
                overlayIndex
              );
            }
          }
        );
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    },
    this
  );

  this.listenTo(
    Tremr.dispatcher,
    "tremr:feed:channelinvite",
    function (data) {
      // ensure signed-in
      var user = Tremr.stores.userStore.getUser();
      if (!user) {
        Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
          event: "tremr:feed:channelinvite",
          data: data,
        });
        return;
      }

      // load the channel invite from the server
      Tremr.Utils.loadModel(
        new ChannelInvite(),
        { id: data.invite },
        false,
        true
      )
        .then(function (params) {
          if (params.data.success) {
            let m = new ChannelInvite(JSON.parse(params.data.invite));

            // check if the action was accept or reject
            let action = data.action;
            let message = "";

            if (action == "accept") {
              message =
                "Accept invitation and become a contributor to the <strong>" +
                m.get("title") +
                "</strong> Blogcast?";
              m.set("update", "accepted");
            } else {
              message =
                "Decline invitation to the <strong>" +
                m.get("title") +
                "</strong> Blogcast?";
              m.set("update", "declined");
            }

            // prompt the user to accept or not
            alertify.confirm(message, function (e) {
              if (e) {
                m.save(params, {
                  success: function (model, response, options) {
                    if (action == "accept") {
                      Tremr.dispatcher.message(
                        this,
                        "tremr:feed:invite:success",
                        user
                      );
                      alertify.success("Invitation Accepted");
                    } else {
                      alertify.log("Invitation Declined");
                    }
                  }.bind(this),
                  error: function (model, response, options) {
                    alertify.success("Error Updating Invitation");
                  },
                });
              }
            });
          } else {
            alertify.error("Invitation not found or invalid");
          }
        })
        .catch(function (params) {
          alertify.error("Invitation not found or invalid");
        });
    },
    this
  );

  // save channel
  this.listenTo(
    Tremr.dispatcher,
    "tremr:feed:save",
    function (data) {
      // if we are not logged-in then fail
      var signedInUser = Tremr.stores.userStore.getUser();
      if (!signedInUser) {
        console.log("You must be signed-in to create or edit a channel.");
        return;
      }

      var params = _.extend(data, { authenticity_token: Tremr.auth_token });

      // create a feed from the data
      let feedModel = new Tremr.Models.Feed(data);

      // watch for invalid event
      feedModel.on("invalid", function (model, response, options) {
        Tremr.dispatcher.message(
          self,
          "tremr:feed:edit:failed",
          model.validationError
        );
      });

      // save
      feedModel.save(params, {
        success: function (model, response, options) {
          // var data = response.user;
          // if (_.isString(data)) {
          //    data = JSON.parse(data);
          // }
          // var user = new Tremr.Models.User(data);
          //
          // if (user.get('_id') == signedInUser.get('id')) {
          //    Tremr.context.add({user: user});
          //    signedInUser = user;
          // }
          //
          // send a saved message
          Tremr.dispatcher.message(
            this,
            "tremr:feed:edit:success",
            signedInUser
          );
        }.bind(this),
        error: function (model, response, options) {
          if (response.responseText != "") {
            var data = JSON.parse(response.responseText);

            if (data["errors"] === undefined) {
              var info = response.responseJSON;
              Tremr.dispatcher.message(this, "tremr:feed:edit:failed", {
                name: "Channel could not be saved.",
              });
            } else {
              Tremr.dispatcher.message(
                this,
                "tremr:feed:edit:failed",
                data["errors"]
              );
            }
          } else {
            console.log("Unkown error saving channel");
          }
        },
      });
    },
    this
  );

  // delete channel
  this.listenTo(
    Tremr.dispatcher,
    "tremr:feed:delete",
    function (data) {
      // if we are not logged-in then fail
      var signedInUser = Tremr.stores.userStore.getUser();
      if (!signedInUser) {
        console.log("You must be signed-in to delete channel.");
        return;
      }

      // var params = _.extend(data, {authenticity_token: Tremr.auth_token});

      // create a feed from the data
      let feedModel = new Tremr.Models.Feed(data);

      // delete
      alertify.confirm("Delete Channel?", function (e) {
        if (e) {
          feedModel.destroy({
            success: function (model, response, options) {
              Tremr.dispatcher.message(
                this,
                "tremr:feed:delete:success",
                signedInUser
              );
            }.bind(this),
            error: function (model, response, options) {
              if (response.responseText != "") {
                var data = JSON.parse(response.responseText);

                if (data["error"]) {
                  alertify.error(data["error"]);
                }
              } else {
                console.log("Unkown error deleting channel");
              }
            },
          });
        }
      });
    },
    this
  );

  // navigate to the users own personalised feed
  store.listenTo(
    Tremr.dispatcher,
    "tremr:open:personalfeed",
    function (action, data) {
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        var primary = {
          target: "homepage",
          scope: "featured",
          sort: "latest",
        };

        Tremr.navigatePrimary(primary);
      } else {
        alertify.error("Must be signed-in to show your personal feed");
      }
    },
    store
  );

  // navigate to the users own bookmarks
  store.listenTo(
    Tremr.dispatcher,
    "tremr:open:bookmarks",
    function (action, data) {
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        var primary = {
          target: "bookmarks",
          scope: "all",
          sort: "latest",
        };

        Tremr.navigatePrimary(primary);
      } else {
        alertify.error("Must be signed-in to show your bookmarks");
      }
    },
    store
  );

  // navigate to the users own inbox
  store.listenTo(
    Tremr.dispatcher,
    "tremr:open:inbox",
    function (action, data) {
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        var primary = {
          target: "inbox",
          scope: "posts",
          sort: "latest",
        };

        Tremr.navigatePrimary(primary);
      } else {
        alertify.error("Must be signed-in to show your inbox");
      }
    },
    store
  );

  // navigate to the users own feed
  store.listenTo(
    Tremr.dispatcher,
    "tremr:open:userfeed",
    function (action, data) {
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        var primary = {
          target: "feed",
          scope: "posts",
          subfeed: "featured",
          feed: user.get("feedname"),
        };

        Tremr.navigatePrimary(primary);
      } else {
        alertify.error("Must be signed-in to show your own feed");
      }
    },
    store
  );

  // when drafts added/removed from posting we need to rerfresh the drafts list if we are viewing it
  store.listenTo(
    Tremr.dispatcher,
    "tremr:draft:refresh",
    function (action) {
      // check if user is on the drafts page
      var state = Tremr.context.getState();
      if (
        state.primary &&
        state.primary.target == "feed" &&
        state.primary.scope == "drafts"
      ) {
        // tell the app to refresh
        _.defer(function () {
          Tremr.context.refresh("primaryComponent");
        });
      }
    },
    store
  );

  // navigate to the users own feed and select the drafts tab
  store.listenTo(
    Tremr.dispatcher,
    "tremr:open:userdrafts",
    function (action, data) {
      var user = Tremr.stores.userStore.getUser();
      if (user) {
        var primary = {
          target: "feed",
          scope: "drafts",
          feed: user.get("feedname"),
        };

        Tremr.navigatePrimary(primary);
      } else {
        alertify.error("Must be signed-in to show your drafts");
      }
    },
    store
  );

  return store;
};
