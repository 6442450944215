var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');
var PropTypes = require('prop-types');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin, UserAwareMixin],

    propTypes: {
		subfeeds: PropTypes.array,
		feed: PropTypes.string,
        text: PropTypes.string,
        onClick: PropTypes.func
	},

    getDefaultProps: function() {
		return {
			subfeeds: [],
            text: "Manage Feeds"
		};
	},

	open: function(event) {
		event.preventDefault();
		event.stopPropagation();

        if (this.props.onClick) {
            this.props.onClick();
        }

        if (this.state.user) {

            var overlayData = {
               class: "Tremr.User.ManageSubfeeds",
               style: "sidebar",
               props: {
                  user: this.state.user,
                  feed: this.props.feed,
                  subfeeds: this.props.subfeeds
               }
            }
            Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);

        } else {
            Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
                callback: this.open,
                data: event
            });
            return;
        }
	},

	render: function() {

		return <a className="manage-subfeeds-button" onClick={this.open}>{this.props.text}</a>;
	}

});
