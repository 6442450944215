var Backbone = require('backbone');
var Config = require('config');

module.exports = Backbone.Model.extend({
    urlRoot: Config.apiurl+'/api/feed',

    url: function() {
        return this.urlRoot + '/' + this.get('id') + '/subfeed';
    }

});
