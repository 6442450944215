require("./scss/sidebar.scss");

var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var DraftsTab = require("../../stores/user/draftstab");
var ManageSubfeedsButton = require("../user/manage_subfeeds_button");
var CreateReactClass = require("create-react-class");
var Config = require("config");
var Notifications = require("../../stores/user/notifications");
var SvgIcon = require("../generic/svgicon");
var Channels = require("../../models/Channels");
var alertify = require("alertify");
var ChannelAvatar = require("../../stores/feed/channelavatar");

module.exports = CreateReactClass({
  // mixins: [PureRenderMixin, UserAwareMixin],
  mixins: [UserAwareMixin],

  getInitialState: function () {
    return {
      isLoading: true,
      promises: [],
      channels: new Channels(),
    };
  },

  loadFeed: function (user) {
    // load the feed
    var feed = new Tremr.Models.Feed({
      id: user.get("feedname"),
    });
    var promise = Tremr.stores.feedStore.loadFeed(
      feed,
      user.get("feedname"),
      false
    );
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.setState({
          isLoading: false,
          feed: model,
        });

        // set a global highlight colour - read from user!
        if (model.get("highlight_color")) {
          // document.documentElement.style.setProperty('--feed-highlight-color', model.get('highlight_color'));
          Tremr.setFeedHighlightColor(model.get("highlight_color"));
        } else {
          // document.documentElement.style.setProperty('--feed-highlight-color', Config.colors.highlight);
          Tremr.setFeedHighlightColor(Config.colors.highlight);
        }
      }.bind(this)
    );

    // load channels
    let channelsPromise = Tremr.stores.feedStore.loadChannels(
      1,
      this.state.channels,
      {
        feed: user.get("feedname"),
        scope: "channels",
        sort: "latest",
        target: "feed",
      },
      false
    );

    channelsPromise.then(
      function (params) {
        var collection = params.collection;
        var data = params.data;
        var options = params.options;

        this.setState({
          channels: collection,
        });
      }.bind(this)
    );

    this.setState({
      feed: feed,
      promises: this.state.promises.slice(0).concat([promise, channelsPromise]),
    });
  },

  handleScroll: function (event) {
    let node = ReactDOM.findDOMNode(this);
    let scrollTop = node.scrollTop;
    let scrollHeight = node.scrollHeight;
    let bottom_margin = 2 * node.clientHeight; // how close do we need to be to the bottom of the page

    if (this.lastScrollTop > scrollHeight) {
      this.lastScrollTop = 0; // reset when page is now smaller than prev scroll pos
    }
    let scrollAmount = scrollTop - this.lastScrollTop;
    this.lastScrollTop = scrollTop;

    // check if we are near the bottom and raise an event
    if (scrollAmount > 0 && scrollTop + bottom_margin > scrollHeight) {
      this.loadNextNotfications(this);
    }
  },

  loadNextNotfications: _.throttle((self) => {
    self.refs["notifications"].loadNextPage();
  }, 500),

  // load data from server when first added to DOM
  componentDidMount: function () {
    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // watch for scroll
    let node = ReactDOM.findDOMNode(this);
    if (TremrUtils.passiveSupported) {
      node.addEventListener("scroll", this.handleScroll, { passive: true });
    } else {
      node.addEventListener("scroll", this.handleScroll);
    }

    // listen for sign-in/out and update user in our session
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signin:success",
      function (user) {
        if (!this.state.feed || !this.state.feed.get("name")) {
          this.loadFeed(user);
        }
      }.bind(this)
    );

    // listen for profile updates
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:profile:success",
      function (user) {
        this.loadFeed(user);
      }.bind(this)
    );

    // until we load se the highlight as grey
    // document.documentElement.style.setProperty(
    //   "--feed-highlight-color",
    //   "#cccccc"
    // );
    Tremr.setFeedHighlightColor("#cccccc");

    // if we have a user, load there feed
    if (this.state.user) {
      this.loadFeed(this.state.user);
    }
  },

  // unregister listeners
  componentWillUnmount: function () {
    // stop listening to scroll
    let node = ReactDOM.findDOMNode(this);
    node.removeEventListener("scroll", this.handleScroll);

    this.state.promises = _.reject(this.state.promises, function (promise) {
      promise.cancel();
      return true;
    });

    if (this.stopListening) {
      this.stopListening();
    }

    // return hard-coded highlight color
    // document.documentElement.style.setProperty(
    //   "--feed-highlight-color",
    //   Config.colors.highlight
    // );
    Tremr.setFeedHighlightColor(Config.colors.highlight);
  },

  signOut: function (event) {
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:user:signout", {});
    this.props.onClose();
  },

  editProfile: function (event) {
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:open:profile", {});
    this.props.onClose();
  },

  viewReputation: function (event) {
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:user:sidebar:reputation", {});
    this.props.onClose();
  },

  openChannel: function (event) {
    event.preventDefault();
    event.stopPropagation();

    var feed = event.currentTarget.getAttribute("data-feed");

    let context = {
      target: "feed",
      feed: feed,
      scope: "all",
      subfeed: "featured",
    };
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(context, scrollpos);

    this.props.onClose();
  },

  viewProfile: function (event) {
    if (event.metaKey) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:open:userfeed", event);
    this.props.onClose();
  },

  newChannel: function (event) {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:feed:edit", {
      parentFeedname: this.state.feed.get("id"),
    });
    this.props.onClose();
  },

  openEditor: function (event) {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:editor:open", {});
    this.props.onClose();
  },

  openItem: function (event) {
    // event.preventDefault();
    event.stopPropagation();
    //
    // console.log("TODO: menu links!");
    // alertify.error("TODO: menu links!"+event.currentTarget.getAttribute('data-value'));
    let value = event.currentTarget.getAttribute("data-value");
    if (value == "about") {
      if (event.metaKey) {
        if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
          let url = Tremr.routes.urlFor({ primary: this.getPostContext() });
          window.open(url);
        }
        return;
      }

      event.preventDefault();

      Tremr.navigatePrimary({
        target: "post",
        feed: Tremr.Theme.SystemFeed,
        id: Tremr.Theme.AboutPostSlug,
      });

      this.props.onClose();
    }

    // this.props.onClose();
  },

  render: function () {
    var classes = {
      "user-sidebar": true,
      sidebar: true,
      loading: this.state.isLoading,
    };
    classes = classNames(classes);

    if (this.state.isLoading) {
      return <div className={classes}></div>;
    }

    var avatarSize = 140;
    let avatar = null;
    let title = null;
    let profileUrl = null;

    if (this.state.feed) {
      if (
        this.state.feed.get("author_avatar_image") &&
        this.state.feed.get("author_avatar_image").public_id
      ) {
        avatar = (
          <div
            onClick={this.viewProfile}
            key={
              "authorAvatar" +
              this.state.feed.get("author_avatar_image").public_id
            }
            className="avatar"
          >
            <Tremr.Generic.Image
              key={
                "authorAvatarImage" +
                this.state.feed.get("author_avatar_image").public_id
              }
              height={avatarSize}
              width={avatarSize}
              image={this.state.feed.get("author_avatar_image")}
            />
          </div>
        );
      }

      title = (
        <h1 key="feedName">
          <Tremr.Generic.Abbreviations
            text={this.state.feed.get("author_name")}
          />
        </h1>
      );

      let context = {
        target: "feed",
        scope: "all",
        feed: this.state.feed.get("id"),
        sort: "latest",
        subfeed: "featured",
      };
      profileUrl = Tremr.routes.urlFor({
        primary: context,
      });
    }

    let notesmenu = null;
    let notes = null;
    if (this.state.user) {
      notes = <Notifications ref="notifications" />;
    }

    let stats = null;
    if (this.state.feed.get("stats")) {
      stats = (
        <div className="stats">
          <div className="stat">
            <strong>{this.state.feed.get("stats").posts}</strong> posts
          </div>
          <div className="stat">
            <strong>{this.state.feed.get("stats").followers}</strong> followers
          </div>
        </div>
      );
    }

    let manageSubfeeds = (
      <ManageSubfeedsButton
        text="Add/Remove Feeds"
        onClick={this.props.onClose}
        key="managesubfeeds"
        feed={this.state.feed.get("name")}
        subfeeds={this.state.feed.get("subfeeds") || []}
      />
    );

    let channels = null;
    if (this.state.channels) {
      channels = (
        <div className="section">
          {this.state.channels.map(
            function (channel) {
              let image = (
                <Tremr.Generic.Image
                  image={channel.get("avatar_image")}
                  height={32}
                  width={32}
                />
              );
              if (!channel.get("avatar_image")) {
                let color = Config.colors.highlight;
                if (channel.get("highlight_color")) {
                  color = channel.get("highlight_color");
                }
                image = (
                  <ChannelAvatar
                    title={channel.get("author_name")}
                    color={color}
                  />
                );
              }
              return (
                <div
                  key={"feed-" + channel.get("name")}
                  className="channel line"
                  onClick={this.openChannel}
                  data-feed={channel.get("name")}
                >
                  <div className="name">{channel.get("author_name")}</div>
                  {image}
                </div>
              );
            }.bind(this)
          )}
        </div>
      );
    }

    let newChannelLink = null;
    let reputationLink = null;
    if (this.state.user && this.state.user.get("admin") == "yes") {
      newChannelLink = (
        <a className="line" onClick={this.newChannel}>
          New Blogcast<span className="beta">(Beta)</span>
        </a>
      );
    }
    if (
      Config.reputation != "admin" ||
      (this.state.user && this.state.user.get("admin") == "yes")
    ) {
      reputationLink = (
        <a className="line" onClick={this.viewReputation}>
          Reputation
        </a>
      );
    }

    // <a className="line" onClick={this.openItem} href="/welcome" target="_blank">About</a>

    return (
      <div className={classes}>
        <div className="user-sidebar-toolbar toolbar">
          <div className="row">
            {avatar}
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
          </div>
          <div className="row title" onClick={this.viewProfile}>
            {title}
            {stats}
          </div>
        </div>

        <div className="controls">
          <div className="section">
            <a className="line">
              <div className="button" onClick={this.openEditor}>
                <SvgIcon icon="write" /> New Post
              </div>
            </a>
            {newChannelLink}
            <div className="line manage-subfeeds-button">{manageSubfeeds}</div>
            {reputationLink}
            <a className="line" href={profileUrl} onClick={this.viewProfile}>
              View Profile
            </a>
            <a className="line" onClick={this.editProfile}>
              Edit Profile
            </a>
            <a className="line" onClick={this.signOut}>
              Sign Out
            </a>
          </div>

          {channels}

          <div className="section">
            <a
              className="line"
              onClick={this.openItem}
              href={Tremr.Theme.HelpLink}
              target="_blank"
            >
              Help
            </a>
            <a
              className="line"
              data-value="about"
              onClick={this.openItem}
              href={Tremr.Theme.AboutLink}
              target="_blank"
            >
              About
            </a>
            <a
              className="line"
              onClick={this.openItem}
              href={Tremr.Theme.PrivacyPolicyLink}
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    );
  },
});
