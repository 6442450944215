require("./scss/multibutton.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// button, with arrow dropdown for further options
// Tremr.Generic.Multibutton = CreateReactClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        additional: PropTypes.array.isRequired, // expected objects with label/action
        showLabel: PropTypes.bool,
        handleIcon: PropTypes.string
    },

    getDefaultProps: function() {
        return {
            label: 'Button',
            action: function(event) { },
            additional: [],
            showLabel: true,
            handleIcon: 'dropdown'
        }
    },

    getInitialState: function() {
        return {
            open: false
        };
    },

    toggleOpen: function(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            open: !this.state.open
        });
    },

    // close the dropdown and call the callback
    action: function(callback) {

        return function(event) {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                open: false
            });

            callback();
        }.bind(this);
    },

    // display
    render: function() {

        var handle = null;
        var dropdown = null;

        if (this.props.additional && this.props.additional.length > 0) {
            handle = <div onClick={this.toggleOpen} className="handle">
                <Tremr.Generic.SvgIcon icon={this.props.handleIcon} />
            </div>;

            if (this.state.open) {
                dropdown = <div className="dropdown">
                {this.props.additional.map(function(element) {
                    return <div onClick={this.action(element.action)}>{element.label}</div>;
                }.bind(this))}
                </div>;
            }
        }

        var label = null;
        if (this.props.showLabel) {
            label = <div className="label">{this.props.label}</div>;
        }

        return (<div className="multi-button">
            <div onClick={this.action(this.props.action)} className="action">
                {label}
                {handle}
            </div>
            {dropdown}
        </div>);
    }
});

