// var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var PropTypes = require("prop-types");
let Stars = require("../controls/stars");
var SvgIcon = require("../generic/svgicon");

// sideways scrolling list of related posts
module.exports = CreateReactClass({
  // mixins: [PureRenderMixin],

  mixins: [UserAwareMixin],

  propTypes: {
    onClick: PropTypes.func.isRequired,
    // liveDataPoints: PropTypes.array.isRequired,
  },

  getDefaultProps: function () {
    return {};
  },

  getInitialState: function () {
    return {};
  },

  click: function (e) {
    this.props.onClick(e);
  },

  noAccess: function (e) {},

  render: function () {
    
        
    // check for channel membership and post door policy
        
    // assume allowed to reply
    let CanRespond = true;
    let BlockReason = "";

    // channel membership
    if (
      this.state.user && 
      this.state.user.get("_id") && 
      this.props.liveDataPoints?.channelMembership?.data?.length > 0
    ) {

      // check our id is in the list
      if (!this.props.liveDataPoints.channelMembership.data.includes(this.state.user.get("_id"))) {
        CanRespond = false;
        BlockReason = "Not a member of the Blogcast."
      }
    }

    // post door policy
    if (
      this.state.user && 
      this.state.user.get("_id") && 
      this.props.liveDataPoints?.doorPolicy?.data?.option == "Restricted"
    ) {
    
      // we have a post door policy, check if user can respond or not
      if (
        this.props.liveDataPoints?.doorPolicy?.data?.restricted.includes("Reputation")
      ) {
        if (
          this.props.liveDataPoints.reputation.data >=
          this.props.liveDataPoints.doorPolicy.data.restrictedStars
        ) {
          // ok, leave alone
        } else {
          CanRespond = false;
          BlockReason = "Post door policy requires higher reputation."
        }
      }

      // always allow author to respond
      if (
        this.state.user &&
        this.props.liveDataPoints?.doorPolicy?.data?.author_id ==
          this.state.user.get("_id")
      ) {
        CanRespond = true;
      }  
    }


    // UI added here for either channel membership or door policy block    
    if (!CanRespond) {
      let doorPolicySummary = (
        <div className="door-policy-summary-container">
          <div className="door-policy-summary">
            <SvgIcon title={BlockReason} icon={CanRespond ? "unlocked" : "locked"} />
          </div>
        </div>
      );    

      return (
        <div className="reply-area">
          {doorPolicySummary}
        </div>
      );
    } else {
      return (
        <div className="reply-area">
           <div className="reply-button-container" onClick={this.click}>
             <Tremr.Generic.SvgIcon icon="reply" />
             <div>Reply</div>
           </div>
        </div>      
      );
    }
  },
});
