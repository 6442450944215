require("./scss/manage_subfeeds.scss");

var alertify = require('alertify');
var UserAwareMixin = require('../../mixins/userawaremixin');
var classNames = require('../../utils/classnames');
var PopUp = require('../generic/popup');
var ReactSortable = require('react-sortablejs');
var TremrUtils = require('../../utils/tremr_utils');
var Subfeed = require('../../models/Subfeed');
var CreateReactClass = require('create-react-class');
var PropTypes = require('prop-types');
var SvgIcon = require('../generic/svgicon');

module.exports = CreateReactClass({

	mixins: [UserAwareMixin], // cant be pure because the sortablejs re-orders the array!

    propTypes: {
		subfeeds: PropTypes.array,
		feed: PropTypes.string
	},

    getDefaultProps: function() {
		return {
			subfeeds: []
		};
	},

	getInitialState: function() {

		return {
			open: false,
			subfeeds: this.props.subfeeds.slice(),
			edits: [],
			newsubfeedname: ''
		};
	},

	handleOrderUpdate: function(order) {

		if (this.state.isSaving) { return; }

		// re-order based on the ids in the list
		let items = this.state.subfeeds;
		items.sort((a, b) => {
			return order.indexOf(a.id) > order.indexOf(b.id) ? 1 : -1;
		});

		// re-order the feeds and update the state
		this.setState({
			subfeeds: items
		});
	},

	// keep track of new subfeed, until it is entered
	newSubfeed: function(content) {

        if (this.state.isSaving) { return; }

		this.setState({
			newsubfeedname: content.value
		});
	},

	// add the new subfeed and clear the new field
	endNewSubfeed: function() {

		if (this.state.isSaving) { return; }

		let isError = false;

        if (this.state.newsubfeedname.trim() == '') {
			alertify.error("Please enter a feed name");
			isError = true;
		}

		let subfeeds = this.state.subfeeds.slice();

		// check for duplicate
		_.each(subfeeds, (subfeed) => {
				if (subfeed.name.toLowerCase() == this.state.newsubfeedname.trim().toLowerCase() ||
					subfeed.name.toLowerCase() == 'all-posts') {
				alertify.error("Duplicate feed name");
				isError = true;
			}
		});

		if (!isError) {

			// add new one, with generated guid
			subfeeds.push({
				id: TremrUtils.uniqueId(),
				name: this.state.newsubfeedname.trim()
			});

			this.setState({
				subfeeds: subfeeds,
				newsubfeedname: ''
			});
		}
	},

	edit: function(subfeed) {

		if (this.state.isSaving) { return; }

        let featuredSubfeed = false;
        _.each(this.state.subfeeds, function(s) {
			if (s.featured == 'yes') {
				featuredSubfeed = s;
			}
		});

        let communitySubfeed = false;
        _.each(this.state.subfeeds, function(s) {
			if (s.community == 'yes') {
				communitySubfeed = s;
			}
		});

        if (featuredSubfeed.id == subfeed) {
			alertify.error("Cannot rename Featured feed.");
			return;
		} else if (communitySubfeed.id == subfeed) {
			alertify.error("Cannot rename Community feed.");
			return;
		}

		let edits = this.state.edits;
		this.setState({
			edits: edits.concat(subfeed)
		});

		_.delay(function() {
			if (this.refs['Edit'+subfeed]) {
	        	this.refs['Edit'+subfeed].focus();
	        }
	  	}.bind(this), 1);
	},

	// replace old name with new name
	updateSubfeed: function(subfeedid, oldsubfeed, newsubfeed) {

		if (this.state.isSaving) { return; }

		let subfeeds = this.state.subfeeds.slice();
		_.each(subfeeds, function(subfeed) {
			if (subfeed.id == subfeedid) {
				subfeed.name = newsubfeed.trim();
			}
		});
		this.setState({
			subfeeds: subfeeds
		});
	},

    endEdit: function(subfeedid) {

		if (this.state.isSaving) { return; }

		let isError = false;

		_.each(this.state.subfeeds, function(subfeed) {
			if (subfeed.id == subfeedid && subfeed.name.trim() == '') {
				alertify.error("Please enter a feed name");
				isError = true;
			} else if (subfeed.id == subfeedid) {
				_.each(this.state.subfeeds, function(sb) {
					if ((sb.id != subfeedid && sb.name.trim().toLowerCase() == subfeed.name.trim().toLowerCase()) ||
					    subfeed.name.toLowerCase() == 'all-posts' || subfeed.name.toLowerCase() == 'featured') {
						alertify.error("Duplicate feed name");
						isError = true;
					}
				}.bind(this));
			}
		}.bind(this));

		if (!isError) {

			let edits = this.state.edits;
			edits = edits.filter(function(editid) {
	  			return editid != subfeedid;
			});
			this.setState({
				edits: edits
			});
		}
	},

	remove: function(subfeedid) {

		if (this.state.isSaving) { return; }

        alertify.confirm("Remove Feed?", function(e) {
            if (e) {
                let subfeeds = this.state.subfeeds;

                let removedIsFeatured = null;
                let removedIsCommunity = null;
        		subfeeds = subfeeds.filter(function(f) {
        			if (f.id == subfeedid) {
        				removedIsFeatured = f.featured;
                        removedIsCommunity = f.community;
        			}
          			return f.id != subfeedid;
        		});

                if (removedIsFeatured == 'yes') {
        			alertify.error("Cannot remove Featured feed.");
        			return;
        		} else if (removedIsCommunity == 'yes') {
        			alertify.error("Cannot remove Community feed.");
        			return;
        		}
        		this.setState({
        			subfeeds: subfeeds
        		});
            }
        }.bind(this));
	},

	// send the updates to the server
	save: function(identifier) {

		if (this.state.isSaving) { return; }

	    this.setState({
	      isSaving: true
	    });

		let model = new Subfeed();
		let data = {
			id: this.props.feed,
			subfeeds: this.state.subfeeds
		};

        this.props.onClose();

        // do the update sending just this data
        model.save(data, {
            success: function (userSession, response) {

                // fire event to update subfeeds for this feed
	            Tremr.dispatcher.message(this, "tremr:"+this.props.feed+":subfeeds:save:success", this.state.subfeeds);

	            alertify.success("Feeds saved.");

	      		this.setState({
					open: false,
					isSaving: false,
					edits: [],
					newsubfeedname: ''
				});

            }.bind(this),
            error: function (userSession, response) {
                console.log("error saving feeds: "+response.responseText);
                this.setState({
			    	isSaving: false
			    });
            }.bind(this)
        });

	},

    handleKeyUp: function(event) {

        if (event.keyCode === 13) {
            this.endNewSubfeed();
        }
    },

	render: function() {

        let items = [];
		if (this.state.subfeeds) {

			items = _.map(this.state.subfeeds, (feed) => {
				let f = feed.name;
				let id = feed.id;

                if (f == "featured" || feed.featured == 'yes') {
                    return null;
                }

				if (_.contains(this.state.edits, id)) {
					// editing version
					return <div key={"subfeed-edit-"+id} className="line" data-id={id}>
                        <div className="handle">
                            <Tremr.Generic.SvgIcon icon="handle" />
                        </div>
                        <Tremr.Generic.Input ref={"EditFeed "+f} onEnter={() => {
							this.endEdit(id);
						}} onChange={(newsubfeedname) => {
							this.updateSubfeed(id, f, newsubfeedname);
						}} name="newFeed" ref={"Edit"+id} label="Feed name" type="text" initialValue={f} maxLength="30" />
						<Tremr.Generic.SvgIcon classes="icon save-button" onClick={(event) => {
							this.endEdit(id);
						}} icon="tick" />
                        <Tremr.Generic.SvgIcon classes="icon remove-button" onClick={(event) => {
							this.remove(id);
						}} icon="remove" />
					</div>;
				} else {
					// standard version
					return <div key={"subfeed"+id} className="line" data-id={id}>
                        <div className="handle">
                            <Tremr.Generic.SvgIcon icon="handle" />
                        </div>
						<div className="feedName">{f}</div>
						<a className="edit-button" onClick={(event) => {
							this.edit(id);
						}}>Edit</a>
                        <Tremr.Generic.SvgIcon  classes="icon remove-button" onClick={(event) => {
							this.remove(id);
						}} icon="remove" />
					</div>;
				}
			});
		}

		// use a key on the input field so we can force a new component when the
		// new feed is saved, thus clearing the text of the field.
		let inputKey = TremrUtils.uniqueId();
		if (this.state.subfeeds.length > 0) {
			inputKey = this.state.subfeeds[this.state.subfeeds.length - 1].id;
		}

        // <Tremr.Generic.Input key={inputKey} onEnter={this.endNewSubfeed} onChange={this.newSubfeed} name="newFeed" label="New feed" type="text" initialValue={this.state.newsubfeedname} maxLength="30" />

		return <div className="user-manage-subfeeds sidebar">
            <div className="user-manage-subfeeds-toolbar toolbar">
                <SvgIcon key="close" icon="remove" classes="close icon" onClick={this.props.onClose} />
                <a className="button save" onClick={this.save}>Save</a>
			</div>
            <div className="controls">
                <div className="disabled line">
                    <Tremr.Generic.SvgIcon icon="square" />
                    <div className="feedName">Everything</div>
                </div>
                <div className="disabled line">
                    <Tremr.Generic.SvgIcon icon="square" />
                    <div className="feedName">Featured</div>
                </div>

				<ReactSortable
				    tag="div"
				    onChange={this.handleOrderUpdate}
                    options={{handle: '.handle'}}>
				    {items}
				</ReactSortable>

				<div className="line">
					<Tremr.Generic.SvgIcon icon="plus"  classes="icon plus-button" />
                    <Tremr.Editor.ContentEditable key={inputKey} classNames={{contenteditable: true, newFeed: true}} onKeyUp={this.handleKeyUp} initialValue={this.state.newsubfeedname} onChange={this.newSubfeed} maxLength={30} placeholder="New feed"/>

					<div className="button add" onClick={(event) => {
						this.endNewSubfeed();
					}}>Add</div>
				</div>
			</div>
		</div>;

	}

});
