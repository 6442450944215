let React = require("react");
let CreateReactClass = require("create-react-class");
let PureCanvas = require("./purecanvas");

module.exports = CreateReactClass({
  getDefaultProps: function() {
    return {
      percent: 0, // %
      scale: 1,
      backgroundColor: '#fbfbfb',
      foregroundColor: '#f7f7f7',
      borderRadius: 6
    };
  },

  // parent setting params will call this
  // so we can redraw
  componentDidUpdate: function() {
    this.draw();
  },

  roundRect: function(ctx, x, y, width, height, radius) {
    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
},

  // do the drawing
  draw: function() {
    let ctx = this.ctx;

    ctx.save();
    ctx.clearRect(0, 0, this.width, this.height);

    // draw rounded rectangle background
    this.roundRect(ctx, 0, 0, this.width, this.height, this.props.borderRadius);
    ctx.fillStyle = this.props.backgroundColor;
    ctx.fill();

    // draw rounded (left side only) rectangle to represent percentage
    if (this.props.percent > 0) {
      let percentageWidth = (this.width / 100) * this.props.percent;
      let borderStyle = {tl: this.props.borderRadius, tr: 0, br: 0, bl: this.props.borderRadius};
      if (percentageWidth + this.props.borderRadius >= this.width) {
        borderStyle = this.props.borderRadius;
      }
      this.roundRect(ctx, 0, 0, percentageWidth, this.height, borderStyle);
      ctx.fillStyle = this.props.foregroundColor;
      ctx.fill();
    }

    ctx.restore();
  },

  saveCtx: function(ctx) {
    this.ctx = ctx;
    this.height = this.ctx.canvas.height;
    this.width = this.ctx.canvas.width;
    this.draw(); // first draw
  },

  render: function() {
    return (
      <div className="reputation-bar">
        <PureCanvas
          height={this.props.height * this.props.scale}
          width={this.props.width * this.props.scale}
          contextRef={this.saveCtx}
        />
        <div className="reputation-bar-children">
          {this.props.children}
        </div>
      </div>
    );
  }
});
