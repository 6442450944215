let React = require("react");
let CreateReactClass = require("create-react-class");
let Stars = require("../controls/stars");
var UserAwareMixin = require("../../mixins/userawaremixin");
var Config = require("config");

// designed to be wrapped with a withDBData and then passes liveDataPoints data
// down to an animatedStars control
module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  getDefaultProps: function () {
    return {
      drawUnset: false,
      starSize: 18,
      lineWidth: 3,
    };
  },

  render: function () {
    if (
      Config.reputation == "admin" &&
      (!this.state.user || this.state.user.get("admin") != "yes")
    ) {
      return null;
    }

    // check if we want to suppress data
    if (this.props.liveDataPoints && this.props.liveDataPoints.suppress) {
      const suppressDataPoint = this.props.dataPoints.find(function (dp) {
        return dp.name && dp.name == "suppress";
      });
      if (suppressDataPoint && suppressDataPoint.check) {
        if (suppressDataPoint.check(this.props.liveDataPoints.suppress.data)) {
          return null;
        }
      }
    }

    // check how to draw stars
    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.rep_stars &&
      this.props.liveDataPoints.post_rep_stars
    ) {
      return (
        <Stars
          onRender={this.props.onRender}
          alignment={this.props.alignment}
          scale={window.devicePixelRatio}
          drawUnset={this.props.drawUnset}
          setColor={this.props.highlightColor}
          lineWidth={this.props.lineWidth}
          starSize={this.props.starSize}
          height={this.props.height}
          width={this.props.width}
          stars={parseInt(0 | this.props.liveDataPoints.rep_stars.data)}
          filledStars={parseInt(
            0 | this.props.liveDataPoints.post_rep_stars.data
          )}
        />
      );
    } else if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.rep_stars
    ) {
      return (
        <Stars
          onRender={this.props.onRender}
          alignment={this.props.alignment}
          scale={window.devicePixelRatio}
          drawUnset={this.props.drawUnset}
          setColor={this.props.highlightColor}
          lineWidth={this.props.lineWidth}
          starSize={this.props.starSize}
          height={this.props.height}
          width={this.props.width}
          stars={parseInt(0 | this.props.liveDataPoints.rep_stars.data)}
          filledStars={0}
        />
      );
    } else {
      return null;
    }
  },
});
