require("./scss/card.scss");
require("./scss/headlinecard.scss");

var moment = require("moment");
var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var CardMixin = require("../../mixins/cardmixin");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CardBreadcrumbs = require("./cardbreadcrumbs");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var ContentSummary = require("./contentsummary");
var PostOrigin = require("./postorigin");

let WithFBData = require("../generic/withFBData");
var ReplyAreaBlog = require("./replyareablog");
var ReplyAreaBlogWithFBData = WithFBData(ReplyAreaBlog);
var Stars = require("../reputation/stars");
var StarsWithFBData = WithFBData(Stars);
var ReputationOverview = require("../reputation/postoverview");
var ReputationOverviewWithFBData = WithFBData(ReputationOverview);
let ColorChangesMixin = require("../../mixins/colorchangesmixin");
let ReputationBadge = require("../reputation/badge");
let ReputationBadgeWithFBData = WithFBData(ReputationBadge);
let ReputationHelper = require("../reputation/helper");
let PostWarningBadgeOverview = require("../reputation/postwarningbadge");
let PostWarningBadgeOverviewWithFBData = WithFBData(PostWarningBadgeOverview);

// Tremr.Post.Card = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin, CardMixin, UserAwareMixin, ColorChangesMixin],

  propTypes: {
    post: PropTypes.object.isRequired,
    imageSize: PropTypes.object,
    showBreadcrumbs: PropTypes.bool,
    showResponses: PropTypes.bool,
    identifier: PropTypes.string,
    cardMixinStyle: PropTypes.object,
  },

  getDefaultProps: function () {
    return {
      imageSize: {
        width: 270,
        height: false,
        maxHeight: 464,
        minHeight: 220,
      },
      showBreadcrumbs: true,
      showResponses: true,
    };
  },

  getInitialState: function () {
    return {
      isDeleted: false,
      breadcrumbsExpanded: false,
      columns: 1,
      sizes: {
        columns: 4,
        margin: 40,
        gutter: 20,
        width: 300,
        row: 20,
        currentWidth: 0,
      },
    };
  },

  // let the component decide if it can be shown at multi-column sizes
  // based on the model
  _allowSize: function (size) {
    if (size > 1) {
      if (this.props.post) {
        if (this.props.post.get("image") || this.props.post.get("embed")) {
          // don't allow links which probably don't have an image
          if (
            this.props.post.get("embed") &&
            this.props.post.get("embed").type == "link" &&
            this.props.post.get("embed").thumbnail_url ==
              this.props.post.get("embed").url
          ) {
            return false;
          }

          // only allow if the image isn't going to be stretched
          // let width = 0;
          // if (this.props.post.get('image')) {
          //     width = this.props.post.get('image').width;
          // } else if (this.props.post.get('embed') && this.props.post.get('embed').width) {
          //     width = this.props.post.get('embed').width;
          // } else if (this.props.post.get('embed') && this.props.post.get('embed').thumbnail_width) {
          //     width = this.props.post.get('embed').thumbnail_width;
          // }

          //  allow upscale of 30%
          // if ((width * 1.3) > (size * 300 * TremrUtils.getPixelRatio())) {
          return true;
          // } else {
          //     return false;
          // }
        } else if (size == 2) {
          // don't allow 2x cards with no image
          return false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true; // always allow size 1
    }
  },

  // listen for deletes and updates
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    // see if this post is deleted
    this.listenTo(
      Tremr.dispatcher,
      "tremr:post:delete:success",
      function (post_id) {
        if (this.props.post.get("slug") == post_id) {
          this.setState({ isDeleted: true });
        }
      }.bind(this)
    );

    // highlight if the current feed was last seen before this post was created
    this.listenTo(
      Tremr.dispatcher,
      "tremr:feed:lastseen",
      function (data) {
        let lastSeen = moment(data.seen_at);

        // iterate feeds (so we can compare against the date it was added to this feed)
        _.each(
          this.props.post.get("feeds"),
          function (feed) {
            if (feed.name == data.feed) {
              let postAdded = moment(feed.added, "YYYY-MM-DDTHH:mmZ");

              if (postAdded.isAfter(lastSeen)) {
                this.setState({ isUnseen: true });
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    // highlight if the current tag was last seen before this post was created
    this.listenTo(
      Tremr.dispatcher,
      "tremr:tag:lastseen",
      function (data) {
        let lastSeen = moment(data.seen_at);

        // iterate feeds (so we can compare against the date it was added to this feed)
        _.each(
          this.props.post.get("feeds"),
          function (feed) {
            if (feed.name == data.feed) {
              let postAdded = moment(feed.added, "YYYY-MM-DDTHH:mmZ");

              if (postAdded.isAfter(lastSeen)) {
                this.setState({ isUnseen: true });
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.stopListening();
  },

  // open a card
  openCard: function (event) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // special case for drafts

    if (this.props.post.get("draft_id")) {
      if (event.metaKey) {
        let url = "/editor/draft/" + this.props.post.get("draft_id");
        window.open(url);
      } else {
        Tremr.dispatcher.message(this, "tremr:editor:open", {
          draft_id: this.props.post.get("draft_id"),
        });
        event.preventDefault();
      }
      return;
    }

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        let url = Tremr.routes.urlFor({ primary: this.getPostContext() });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    if (this.state.isDeleted) {
      return;
    }

    this.openContext(event, this.getPostContext());
  },

  // get the context for this post
  getPostContext: function () {
    var route = Tremr.stores.postStore.route(this.props.post);
    return route;
  },

  // handle moving off the card, or onto a different link
  cardUnhover: function (event) {
    event.preventDefault();
    event.stopPropagation();
    var node = ReactDOM.findDOMNode(this);
    node.classList.remove("hover");

    // this.refs["ActivityBar"].hideButtons();
  },

  // handle moving on the card, or off a different link
  cardHover: function (event) {
    event.preventDefault();
    event.stopPropagation();
    var node = ReactDOM.findDOMNode(this);
    node.classList.add("hover");

    // this.refs["ActivityBar"].showButtons();
  },

  getChosenReason: function () {
    let chosenReason = null;
    if (this.props.post.get("reasons")) {
      _.each(this.props.post.get("reasons"), function (reason) {
        if (reason["reason"] == "repost") {
          if (
            !chosenReason ||
            reason["reposted_at"] < chosenReason["reposted_at"]
          ) {
            chosenReason = reason;
          }
        }
      });
    } else if (this.props.post.get("repost")) {
      chosenReason = this.props.post.get("repost");
    }
    return chosenReason;
  },

  // navigate to the users feed
  openContext: function (event, context) {
    event.stopPropagation(); // before we check for meta key (requires click hander on sub elements)

    // if meta key, allow normal open - for container use
    // data-href to open in new tab
    if (event.metaKey) {
      if (event.currentTarget && !event.currentTarget.getAttribute("href")) {
        var url = Tremr.routes.urlFor({ primary: context });
        window.open(url);
      }
      return;
    }

    event.preventDefault();

    Tremr.navigatePrimary(context);
  },

  openAuthorFeed: function (event) {
    event.preventDefault();
    event.stopPropagation();

    let feedContext = {
      target: "feed",
      scope: "posts",
      subfeed: "featured",
      feed:
        this.props.post.get("channel_author_feed") ||
        this.props.post.get("author_feed"),
    };

    this.openContext(event, feedContext);
  },

  openTag: function (event) {
    let tags = this.props.post.get("tags");
    let tag = null;
    let tagContext = null;

    if (tags.length > 0) {
      tagContext = {
        target: "tag",
        tag: tags[0],
      };
    }

    tagContext ? this.openContext(event, tagContext) : null;
  },

  breadcrumbsToggled: function (state) {
    this.setState({ breadcrumbsExpanded: state });
  },

  reputationChange: function () {
    this.resizedCard();
  },

  // display
  render: function () {
    var divStyle = null;
    if (this.props.cardMixinStyle) {
      divStyle = this.props.cardMixinStyle;
    } else {
      divStyle = this.getCardStyle();
    }

    // var tagAndType = <Tremr.Post.TagAndType tags={this.props.post.get('tags')} postType={this.props.post.get('post_type')} allowLink={true} showType={false} showTags={false} categoryTagIndex={1} />;

    // var trending = null;
    // if (this.state.user && this.state.user.get('admin') == 'yes') {
    // var trendingData = this.props.post.get('trending');
    //     if (trendingData) {
    //         trending = <p>
    //         <b>Total: {trendingData.total}</b><br />
    //         <b>Updated: {trendingData.last}</b><br />
    //         <b>Hours: {trendingData.hourly}</b><br />
    //         </p>;
    //     }
    // }

    var url = Tremr.routes.urlFor({ primary: this.getPostContext() });
    if (this.props.post.get("draft_id")) {
      url = "/editor/draft/" + this.props.post.get("draft_id");
    }

    // 4 col layout is changed in styles so we need a different width
    let textColWidth = this.state.width ? this.state.width : 280;
    let imageColWidth = this.state.width ? this.state.width : 280;
    let imageUrlWidth =
      280 * this.state.columns +
      this.state.sizes.gutter * (this.state.columns - 1);
    let even4design = false;
    if (this.state.columns >= 3) {
      textColWidth = this.state.sizes.width;
      imageColWidth = this.state.sizes.width * 3 + this.state.sizes.gutter * 2;
      imageUrlWidth =
        280 * 3 + this.state.sizes.gutter * (this.state.columns - 1);
      divStyle.paddingRight =
        divStyle.width - (textColWidth + this.state.sizes.gutter);

      // are we an odd or even 4 span
      let patternRepeat = 5;
      if (this.state.columns == 3) {
        patternRepeat = 3;
      }
      let odd = Math.floor(this.state.rowIndex / patternRepeat);

      if (odd % 2 == 0) {
        // switch to an image left, text right version
        divStyle.paddingLeft = divStyle.paddingRight;
        divStyle.paddingRight = 0;
        even4design = true;
      }
    }

    let breadcrumbs = null;
    let ancestorTitle = null;
    // let parentAuthor = null;

    if (this.props.post.get("origin")) {
      ancestorTitle = (
        <p className="ancestor-title">
          Re: {this.props.post.get("origin")["title"]}
        </p>
      );
    }
    // if (this.props.post.get("parent")) {
    //     parentAuthor = (<span>
    //        {" "}
    //        to {this.props.post.get("parent")["author_name"]}{" "}
    //     </span>);
    // }
    if (
      this.props.post.get("ancestors") &&
      this.props.post.get("ancestors").length > 0
    ) {
      breadcrumbs = (
        <CardBreadcrumbs
          onToggle={this.breadcrumbsToggled}
          posts={this.props.post.get("ancestors")}
          width={textColWidth}
        />
      );
    }

    var width = this.props.imageSize.width;
    if (this.state.width) {
      width = this.state.width;
    }

    // if we still have default min height change for narrow
    var minHeight = this.props.imageSize.minHeight;
    if (minHeight == 220 && Tremr.Utils.isDeviceOrNarrow()) {
      minHeight = 130;
    }

    var image = null;
    var embed = null;
    var headerImageKey = "header-image-" + width;

    var title = this.props.post.get("title");
    if (title) {
      title = <Tremr.Generic.Abbreviations text={title.capitalize().trim()} />;
    }

    // this.props.imageSize.height
    let setHeight = 220;
    let maxHeight = 220;
    if (this.state.sizes.columns == 2) {
      setHeight = 180;
      maxHeight = 180;
      if (this.state.columns == 2) {
        setHeight = 300;
        maxHeight = 300;
      }
    } else if (this.state.columns == 2) {
      setHeight = 360;
      maxHeight = 360;
    } else if (this.state.columns > 2) {
      setHeight = 530;
      maxHeight = 530;
    }

    if (this.props.post.get("image")) {
      // <Tremr.Generic.Image key={headerImageKey} image={this.props.post.get("image")} width={imageColWidth} urlWidth={imageUrlWidth} minHeight={minHeight} height={setHeight} maxHeight={maxHeight}/>

      image = (
        <a onClick={this.openCard} href={url} className="header-image">
          <Tremr.Generic.Image
            key={headerImageKey}
            image={this.props.post.get("image")}
            width={imageColWidth}
            urlWidth={imageUrlWidth}
            minHeight={minHeight}
            height="auto"
          />
        </a>
      );
    } else if (this.props.post.get("embed")) {
      let showTitle = true;
      let showDescription = true;
      let showLink = true;
      if (this.props.post.get("fieldset") == "article") {
        showTitle = true;
        showDescription = false;
        showLink = false;
      }

      // var titleMatch = (this.props.post.get('embed').title && this.props.post.get('embed').title.trim() == title);
      let headerEmbedStyles = {
        width: imageColWidth,
      };

      if (
        this.props.post.get("embed").html &&
        this.props.post.get("embed").type == "video"
      ) {
        image = (
          <div
            onClick={this.openCard}
            className="header-image"
            style={headerEmbedStyles}
          >
            <Tremr.Generic.EmbedThumb
              key={headerImageKey}
              showMedia={true}
              showText={this.state.columns != 4}
              showLink={showLink}
              showDescription={showDescription}
              showTitle={showTitle}
              shortText={false}
              embed={this.props.post.get("embed")}
              width={imageColWidth}
              minHeight={minHeight}
              height={setHeight}
              maxHeight={maxHeight}
            />
          </div>
        );

        // image = (
        //     <div onClick={this.openCard} className="header-image">
        //         <Tremr.Generic.Embed
        //             key={headerImageKey}
        //             embed={this.props.post.get("embed")}
        //             maxHeight={350}
        //             height={false}
        //             width="auto"
        //         />
        //     </div>
        // );
      } else if (
        this.props.post.get("embed").type == "photo" &&
        this.props.post.get("embed").thumbnail_url ==
          this.props.post.get("embed").url
      ) {
        image = (
          <div
            onClick={this.openCard}
            className="header-image"
            style={headerEmbedStyles}
          >
            <Tremr.Generic.EmbedThumb
              key={headerImageKey}
              showMedia={false}
              showImage={true}
              showText={false}
              embed={this.props.post.get("embed")}
              width={imageColWidth}
              minHeight={minHeight}
              height={setHeight}
              maxHeight={maxHeight}
            />
          </div>
        );
      } else if (
        this.props.post.get("embed").type == "link" &&
        this.props.post.get("embed").thumbnail_url ==
          this.props.post.get("embed").url
      ) {
        image = (
          <div
            onClick={this.openCard}
            className="header-image"
            style={headerEmbedStyles}
          >
            <Tremr.Generic.EmbedThumb
              key={headerImageKey}
              showMedia={false}
              showImage={true}
              showText={true}
              showLink={showLink}
              showDescription={showDescription}
              showTitle={showTitle}
              shortText={false}
              embed={this.props.post.get("embed")}
              width={imageColWidth}
              minHeight={minHeight}
              height={setHeight}
              maxHeight={maxHeight}
            />
          </div>
        );
      } else {
        image = (
          <div
            onClick={this.openCard}
            className="header-image"
            style={headerEmbedStyles}
          >
            <Tremr.Generic.EmbedThumb
              key={headerImageKey}
              showText={true}
              showLink={showLink}
              showDescription={showDescription}
              showTitle={showTitle}
              shortText={false}
              embed={this.props.post.get("embed")}
              width={imageColWidth}
              height={setHeight}
              maxHeight={maxHeight}
            />
          </div>
        );
      }
      // }
      // if ((this.props.post.get('embed').title && !titleMatch) || this.props.post.get('embed').description) {
      //     embed = <Tremr.Generic.EmbedThumb key={headerImageKey+'-desc'} shortText={true} showTitle={!titleMatch} showImage={false} embed={this.props.post.get('embed')} width={width} minHeight={minHeight} maxHeight={this.props.imageSize.maxHeight} />
      // } else {
      //     embed = <span></span>;  has to be set to pick up embed class later
      // }
      embed = true;
    }

    // let authorUrl = Tremr.routes.urlFor({
    //     primary: {
    //         target: 'feed',
    //         scope: 'all',
    //         subfeed: 'featured',
    //         feed: this.props.post.get('author_feed')
    //     }
    // });

    var responses = null;
    if (
      this.props.showResponses &&
      this.props.post.get("responses_count") &&
      this.props.post.get("responses") &&
      this.props.post.get("responses").length > 0
    ) {
      // responses = <Tremr.Post.ResponsesSummary count={this.props.post.get('responses_count')} posts={this.props.post.get('responses')} />;
      // responses = <div className="responses-count">
      // <em>{this.props.post.get('responses_count')}</em> response{(this.props.post.get('responses_count') > 1 ? 's' : '')}
      // </div>
      responses = (
        <div className="responses-count">
          <span>{this.props.post.get("responses_count")}</span>
        </div>
      );
    }

    // var views = this.props.post.get("views");
    // if (!views) {
    //     views = "0";
    // }

    let recommended = false;
    if (
      this.props.post.get("author_tags") &&
      _.contains(
        this.props.post.get("author_tags"),
        Tremr.Theme.RecommendedWriter.Tag
      )
    ) {
      recommended = true;
    }

    // why is this in your feed
    let chosenReason = this.getChosenReason();
    let repostedBy = <PostOrigin post={this.props.post} />;

    var poststats = null;
    if (
      this.props.post.get("contentsummary") &&
      this.props.post.get("contentsummary")["total"] > 0
    ) {
      poststats = (
        <div className="post-stats">
          <ContentSummary post={this.props.post} />
        </div>
      );
    } else if (
      this.props.post.get("wordcount") &&
      this.props.post.get("wordcount") > 0
    ) {
      poststats = (
        <div className="post-stats">
          {this.props.post.get("wordcount").toLocaleString()} words
        </div>
      );
    }

    // hide summary when content in summary is already visible
    // if (this.props.post.get("contentsummary")) {
    //     if (this.props.post.get("image") &&
    //         this.props.post.get("contentsummary")["images"] == 1 &&
    //         this.props.post.get("contentsummary")["total"] == 1) {
    //         poststats = null;
    //     }
    //     if (this.props.post.get("embed") &&
    //         this.props.post.get("contentsummary")["links"] == 1 &&
    //         this.props.post.get("contentsummary")["total"] == 1) {
    //         poststats = null;
    //     }
    //     if (this.props.post.get("embed") &&
    //         this.props.post.get("contentsummary")["images"] == 1 &&
    //         this.props.post.get("contentsummary")["links"] == 1 &&
    //         this.props.post.get("contentsummary")["total"] == 2) {
    //         poststats = null;
    //     }
    // }

    // never show post stats when expanded or when we have nothing beyond the summary
    let summaryWordCount = TremrUtils.countWords(
      this.props.post.get("summary")
        ? this.props.post.get("summary")
        : this.props.post.get("generated_summary")
    );
    let contentWordCount = this.props.post.get("wordcount");
    if (contentWordCount < summaryWordCount) {
      poststats = null;
    }

    // work out our top level class
    var classes = {
      "post-card": true,
      card: true,
      image: this.props.post.get("image"),
      embed: image && embed,
      "has-breadcrumbs": breadcrumbs,
      "has-responses": responses,
      repost: chosenReason,
      deleted: this.state.isDeleted,
      unseen: this.state.isUnseen,
      "show-breadcrumbs": this.state.breadcrumbsExpanded,
      "alternate-design": even4design,
      article: this.props.post.get("fieldset") == "article",
      injected: this.props.post.get("injected"),
      "image-comment":
        this.props.post.get("image") &&
        this.props.post.get("fieldset") != "article",
    };
    if (recommended) {
      classes[Tremr.Theme.RecommendedWriter.Tag.replace(" ", "-")] = true;
    }
    classes["columns-" + this.state.columns] = true;
    if (this.props.post.get("post_type")) {
      classes[this.props.post.get("post_type")] = true;
    }
    if (
      this.props.post.get("author_tags") &&
      _.contains(
        this.props.post.get("author_tags"),
        Tremr.Theme.RecommendedWriter.Tag
      )
    ) {
      classes["author-tags"] = true;
    }

    classes = classNames(classes);

    // <ActivityBar ref="ActivityBar" post={this.props.post} post_id={this.props.post.get("id")} width={textColWidth}/>

    // {moment(this.props.post.get("created_at")).format("D MMM")}&nbsp;{
    //    moment(this.props.post.get("created_at")).format("YY") == moment().format("YY")
    //       ? ''
    //       : moment(this.props.post.get("created_at")).format("'YY")
    // }
    // {parentAuthor}

    let comment = true;
    if (
      (this.props.post.get("generated_summary") &&
        this.props.post.get("generated_summary").length > 0) ||
      (this.props.post.get("summary") &&
        this.props.post.get("summary").length > 0)
    ) {
      comment = false;
    }
    let h1 = null;
    // if (this.props.post.get("fieldset") == "article") {
    if (!comment) {
      h1 = (
        <h1>
          <a onClick={this.openCard} href={url}>
            {title}
          </a>
        </h1>
      );
    } else {
      h1 = (
        <div className="comment">
          <a onClick={this.openCard} href={url}>
            {title}
          </a>
        </div>
      );
    }

    let tags = this.props.post.get("tags");
    let tagLinks = null;
    if (tags.length > 0) {
      // tags = tags.slice(0, 3);
      tags = tags.slice(0, 1);
      tagLinks = _.flatten(
        _.map(
          tags,
          function (tag) {
            let tagLink = (
              <a
                key={"tag-" + tag}
                className="tag"
                href={"/tags/" + tag}
                onClick={this.openTag}
                data-tag={tag}
              >
                <Tremr.Generic.Abbreviations uppercaseOnly={false} text={tag} />
              </a>
            );
            return [tagLink, ", "];
          }.bind(this)
        ),
        true
      );
    }
    if (tagLinks) {
      tagLinks.pop();
      tagLinks = <div className="tags">{tagLinks}</div>;
    }

    let parentAuthor = null;
    if (
      this.props.post.get("origin") &&
      this.props.post.get("origin")["id"] != this.props.post.get("parent")["id"]
    ) {
      ancestorTitle = (
        <p className="ancestor-title">
          Re: {this.props.post.get("origin")["title"]}
        </p>
      );
    }
    if (this.props.post.get("parent")) {
      // <Tremr.Generic.SvgIcon icon="respond"/>
      parentAuthor = (
        <div className="parent-author">
          to {this.props.post.get("parent")["author_name"]}{" "}
        </div>
      );
    }

    const reputationStarsDataPoints = [
      ReputationHelper.getUserReputationStarsDataPoint(
        this.props.post.get("author_id")
      ),
      ReputationHelper.getPostReputationStarsDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
      ReputationHelper.enhanceDataPoint(
        ReputationHelper.getPostReputationBadgeDataPoint(
          this.props.post.get("author_id"),
          this.props.post.get("id")
        ),
        {
          name: "suppress",
          check: function (value) {
            return value && value.length > 0;
          },
        }
      ),
    ];

    const highlightColor = this.state.highlightColor;
    const reputationStars = (
      <StarsWithFBData
        key={"stars" + highlightColor}
        drawUnset={false}
        alignment="left"
        highlightColor={highlightColor}
        lineWidth={3}
        starSize={12}
        height={12}
        width={45}
        scale={window.devicePixelRatio}
        dataPoints={reputationStarsDataPoints}
        onChange={this.reputationChange}
      />
    );

    const reputationOverviewDataPoints = [
      ReputationHelper.renameDataPoint(
        ReputationHelper.getUserReputationStarsDataPoint(
          this.props.post.get("author_id")
        ),
        "user_rep_stars"
      ),
      ReputationHelper.getPostReputationAutoTagsDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
    ];
    const reputationOverview = (
      <ReputationOverviewWithFBData
        key={"repover" + highlightColor}
        align="left"
        highlightColor={highlightColor}
        authorName={this.props.post.get("author_name")}
        dataPoints={reputationOverviewDataPoints}
      >
        {reputationStars}
      </ReputationOverviewWithFBData>
    );
    const reputationBadgeDataPoints = [
      ReputationHelper.getUserReputationBadgeDataPoint(
        this.props.post.get("author_id")
      ),
    ];
    const userBadge = (
      <ReputationBadgeWithFBData dataPoints={reputationBadgeDataPoints} />
    );

    const reputationPostBadgeDataPoints = [
      ReputationHelper.getPostReputationBadgeDataPoint(
        this.props.post.get("author_id"),
        this.props.post.get("id")
      ),
    ];
    const postWarningBadge = (
      <PostWarningBadgeOverviewWithFBData
        align="left"
        dataPoints={reputationPostBadgeDataPoints}
      />
    );

    let created = moment(
      this.props.post.get("created_at"),
      "YYYY-MM-DDTHH:mmZ"
    );
    // let yearDiff =
    //   parseInt(moment().format("YYYY")) - parseInt(created.format("YYYY"));
    let age = TremrUtils.agoDescription(created);
    // if (yearDiff > 0) {
    //   age = created.fromNow("MMM'YY");
    // }
    let timestamp = created.format();
    // let isToday = created.format("D M YY") == moment().format("D M YY");
    // if (isToday) {
    //   age = created.fromNow("H:mma");
    // }
    let timestampClass = "";
    if (age) {
      timestampClass = "show";
    }

    let channel_feed = null;
    if (this.props.post?.get('channel_title')) {
      channel_feed = this.props.post.get('author_feed');

      // check if this is only in the community subfeed
      let memberFeeds = this.props.post.get('feeds').filter((feed) => {
        return (feed['name'] == channel_feed && feed['subfeed'] != 'community');
      });
      
      if (!memberFeeds || memberFeeds.length == 0) {
        channel_feed = null;
      }
    }

    //ReplyAreaBlogWithFBData
    let reaplyarea = (
      <ReplyAreaBlogWithFBData
        dataPoints={Tremr.stores.postStore.getDoorPolicyDataPoints(
          this.props.post.get("id"), channel_feed
        )}/>
    );

    return (
      <article
        onMouseOver={this.cardHover}
        onMouseLeave={this.cardUnhover}
        onClick={this.openCard}
        className={classes}
        style={divStyle}
      >
        <Tremr.Generic.Mouseover
          onMouseOver={this.cardUnhover}
          onMouseLeave={this.cardHover}
        >
          {breadcrumbs}
        </Tremr.Generic.Mouseover>

        {image}

        <div className="content">
          {h1}

          {repostedBy}
          {userBadge}

          <p className="byline">
            <a className="author" onClick={this.openAuthorFeed}>
              {this.props.post.get("author_name")}
              <time dateTime={timestamp} className={timestampClass}>
                {age}
              </time>
            </a>
          </p>

          {ancestorTitle}

          {parentAuthor ? parentAuthor : tagLinks}

          {reputationOverview}
          {postWarningBadge}

          <p>
            {this.props.post.get("summary")
              ? this.props.post.get("summary")
              : this.props.post.get("generated_summary")}
          </p>

          {poststats}

          {reaplyarea}

          {responses}
        </div>
      </article>
    );
  },
});
