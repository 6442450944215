var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.UserNewPassword
module.exports = Backbone.Model.extend({
    url: Config.apiurl+'/api/users/new_password.json',
    paramRoot: 'user',

    defaults: {
        "password": "",
        "confirm_password": ""
    },

    // check we have both fields and that they match
    validate: function (attributes) {

        var errors = {count: 0};

        if (attributes.password != undefined && attributes.password.replace(/\s/gi, "").length == 0) {
            errors.password = "Please enter a new password";
            errors.count = errors.count + 1;
        } else if (attributes.password != undefined && attributes.password.replace(/\s/gi, "").length < 8) {
            this.set('password', '');
            errors.password = "Must be 8 or more characters";
            errors.count = errors.count + 1;
        }

        if (attributes.confirm_password != undefined && attributes.confirm_password.replace(/\s/gi, "").length == 0) {
            errors.confirm_password = "Please confirm your new password";
            errors.count = errors.count + 1;
        } else if (attributes.confirm_password != undefined && attributes.confirm_password != attributes.password) {
            this.set('confirm_password', '');
            errors.confirm_password = "Confirmation password doesn't match";
            errors.count = errors.count + 1;
        }

        if (errors.count > 0) {
            return errors;
        }
    }
});