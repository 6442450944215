var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// simple component that wraps common abbreviations (hard-coded) with an abbr element
// Tremr.Generic.Abbreviations
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        text: PropTypes.string,
        uppercaseOnly: PropTypes.bool
    },

    getDefaultProps: function() {
        return {
            uppercaseOnly: true,
            abbreviations: {
                uk: 'United Kingdom',
                us: 'United States of America',
                usa: 'United States of America',
                nato: 'North Atlantic Treaty Organization',
                isil: 'Islamic State of Iraq and the Levant',
                isis: 'Islamic State of Iraq and al-Sham',
                cia: 'Central Intelligence Agency',
                nsa: 'National Security Agency',
                fbi: 'Federal Bureau of Investigation',
                mp: 'Member of Parliament',
                gop: 'Grand Old Party / Republican Party',
                eu: 'European Union',
                gdp: 'Gross Domestic Product',
                nra: 'National Rifle Association',
                vat: 'Value-added Tax',
                // who: 'World Health Organization',
                sec: 'Securities & Exchange Commission',
                aca: 'Affordable Care Act',
                ttip: 'The Transatlantic Trade and Investment Partnership',
                nfl: 'National Football League',
                fa: 'Football Association',
                nba: 'National Basketball Association',
                nhl: 'National Hockey League',
                fifa: 'Fédération Internationale de Football Association',
                uefa: 'Union of European Football Associations',
                rfu: 'Rugby Football Union',
                mlb: 'Major League Baseball',
                irl: 'Indy Racing League',
                nascar: 'National Association for Stock Car Auto Racing',
                afl: 'American Federation of Labor',
                bbc: 'British Broadcasting Corporation',
                itv: 'Independent Television',
                abc: 'American Broadcasting Company',
                nbc: 'National Broadcasting Company',
                cbs: 'Columbia Broadcasting System',
                cnn: 'Cable News Network'
            }
        };
    },

    // display
    render: function() {

        // split into words and replace any abbreviations
        if (this.props.text) {
            var words = this.props.text.split(' ');
            words = _.flatten(_.map(words, function(word, index) {
                var text = word;

                if (this.props.uppercaseOnly) {
                    if (Tremr.Utils.isUpperCase(word)) {
                        if (this.props.abbreviations[word.toLowerCase()]) {
                            text = <abbr key={'abbr-'+word} title={this.props.abbreviations[word.toLowerCase()]}>{word.toUpperCase()}</abbr>;
                        }
                    }
                } else {
                    if (this.props.abbreviations[word.toLowerCase()]) {
                        text = <abbr key={'abbr-'+word} title={this.props.abbreviations[word.toLowerCase()]}>{word.toUpperCase()}</abbr>;
                    }
                }
                if (index > 0) {
                    return [' ', text];
                } else {
                    return text;
                }
            }.bind(this)));

            return (<span className="abbreviations">
                {words}
            </span>);
        } else {
            return null;
        }
    }
});
