var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');

// Tremr.Generic.Embed
module.exports = CreateReactClass({

    mixins: [PureRenderMixin], // can't use this because of fade in

    // set default props (as reference to what we can send in)
    getDefaultProps: function() {
        return {
            embed: false, // embedly json
            width: 200,
            height: false, // if height or width are false then calculate from width
            resizable: false,
            showLinkDescription: true
        }
    },

    // open the url
    openLink: function(event) {

        // now handled by the link within
        // alertify.success("Open url: "+this.props.embed.url);
    },

    handleImageLoad: function() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }
    },

    // display based on the embed property
    render: function() {

        var classes = {
            'embed': true
        };
        if (this.props.embed.type) {
            classes[this.props.embed.type] = true;
        }
        classes = classNames(classes);

        if (this.props.embed.type == 'link') {
            return <div className={classes}>
                <Tremr.Generic.EmbedThumb showDescription={this.props.showLinkDescription} onLoad={this.handleImageLoad} clickHandler={this.openLink} embed={this.props.embed} maxHeight={this.props.maxHeight} height={this.props.height} width={this.props.width} resizable={this.props.resizable} />
            </div>;
        } else if (this.props.embed.type == 'photo') {
            return <div className={classes}>
                <Tremr.Generic.EmbedThumb showTitle={false} showText={false} onLoad={this.handleImageLoad} embed={this.props.embed} maxHeight={this.props.maxHeight} height={this.props.height} width={this.props.width} resizable={this.props.resizable} />
            </div>;
        } else if (this.props.embed.html) {
            var getEmbedHTML = function() {
                return {
                    __html: this.props.embed.html
                };
            }.bind(this);
            return <div className={classes} dangerouslySetInnerHTML={getEmbedHTML()} />;
        } else {
            return null;
        }

    }
});
