var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.UserPasswordRecovery
module.exports = Backbone.Model.extend({
    url: Config.apiurl+'/api/users/password.json',
    paramRoot: 'user',

    defaults: {
        "email": ""
    },

    // lets check we have everything we need
    validate: function (attributes) {

        var errors = {count: 0};

        if (attributes.email.replace(/\s/gi, "").length == 0 || Tremr.Utils.validEmail(attributes.email) == false) {
            this.set('email', '');
            errors.email = "Please enter a valid email";
            errors.count = errors.count + 1;
        }

        if (errors.count > 0) {
            return errors;
        }
    }
});