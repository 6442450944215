require("./scss/dummycard.scss");

var classNames = require("../../utils/classnames");
var CardMixin = require("../../mixins/cardmixin");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

module.exports = CreateReactClass({

   mixins: [PureRenderMixin, CardMixin],

   getInitialState: function() {
      return {
         columns: 1,
         sizes: {
            columns: 4,
            margin: 40,
            gutter: 20,
            width: 300,
            row: 20,
            currentWidth: 0
         }
      };
   },

   render: function() {

       let divStyle = this.getCardStyle();
       return <div className={"dummy-card "+this.props.wallStyle} style={divStyle}></div>;
   }

});
