var Backbone = require('backbone');
var Config = require('config');
var User = require('./User');

// Tremr.Models.Users
module.exports = Backbone.Collection.extend({
    url: Config.apiurl+'/api/users',
    model: User

});
