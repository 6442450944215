require("./scss/colorpicker.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');
var classNames = require('../../utils/classnames');
var SvgIcon = require('../generic/svgicon');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	propTypes: {
        colors: PropTypes.array.isRequired,
        selectedColor: PropTypes.string.isRequired,
        addColor: PropTypes.func.isRequired,
        selectColor: PropTypes.func.isRequired
    },

    getInitialState: function() {
        return {
            adding: false
        };
    },

    startAdding: function() {
        this.setState({
            adding: true
        });
    },

    newColorKeyDown: function(event) {

        if (event.keyCode == 188 ||
            event.key == 'Enter' ||
            event.key == 'Tab') {

            event.preventDefault();
            event.stopPropagation();

            let color = this.refs['newColor'].state.value.trim();

            // if it's a hex string add the # on if missing
            let isHex  = /(^#?[0-9A-F]{6}$)|(^#?[0-9A-F]{3}$)/i.test(color);
            let isName = this.isNamedColor(color);
            let isValid = isHex || isName;

            if (isHex) {
                if (/^[^#].*/i.test(color)) {
                    color = "#" + color;
                }
            }

            // if this already exists in the list just select it
            if (this.props.colors.includes(color)) {
                this.props.selectColor(color);
                this.setState({
                    adding: false
                });
                return;
            }

            // if valid, add it
            if (isValid) {
                if (color.length > 0) {
                    this.props.addColor(color);
                    this.setState({
                        adding: false
                    });
                }
            }
        }
    },

    componentDidUpdate: function() {
        if (this.refs['newColor']) {
            this.refs['newColor'].focus();
        }
    },

    isNamedColor: function(txt) {
      var s = new Option().style;
      s.color = txt;
      return s.color == txt;
    },

    render: function() {

        // add default colors
        let colors = ['#FF7F10', '#54A3DD', '#93CC58', '#E74100', '#DD67C2'];
        colors = colors.concat(this.props.colors);

        // add current color even if not present
        if (!colors.includes(this.props.selectedColor)) {
            colors.push(this.props.selectedColor);
        }

        // depending on state, draw controls
        let addButton = null;
        let addInput = null;
        if (this.state.adding) {
            addInput = <div className="adding">
                <Tremr.Editor.ContentEditable ref="newColor" classNames={{newColor: true}} initialValue="" maxLength={32} placeholder="#RRGGBB" onKeyDown={this.newColorKeyDown} />
            </div>;
        } else {
            addButton = <div className="add" onClick={this.startAdding}>
                <SvgIcon key="add-button" icon="plus" />
            </div>;
        }

        return <div className="generic-colorpicker">
            <div className="colors">
                {_.uniq(colors).map(function(c) {
                    let divStyle = {
                        backgroundColor: c
                    };
                    let classes = classNames({
                        color: true,
                        selected: (this.props.selectedColor == c)
                    });
                    return <div onClick={() => { this.props.selectColor(c); }} key={c} className={classes} style={divStyle}></div>;
                }.bind(this))}
                {addButton}
            </div>
            {addInput}
        </div>
	}
});
