var GridLayouts = require("../../stores/generic/gridlayouts");
var Footer = require("../../stores/layout/footer");
var PostListCardWrapper = require("../post/listcardwrapper");
var WallControls = require("../post/wallcontrols");
var PostContentCard = require("../post/contentcard");

// Tremr.SearchStore = function () {
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  // trigger loading of activity for these posts
  this.requestActivityForPosts = function (data) {
    // if (data.length > 0) {
    //     var ids = data.map(function(model) {
    //         return model.id;
    //     });
    //     Tremr.dispatcher.message(this, "tremr:activity:forpost", {post_ids: ids});
    // }
  }.bind(this);

  // trigger loading of follow data (i.e. the follow button) on users or tags
  this.requestFollowingForEntity = function (data) {
    if (data.length > 0) {
      var ids = data.map(function (model) {
        var key = {
          entity: "feed",
          id: model.id,
        };
        if (model.type !== undefined) {
          key["entity"] = model.type;
        }
        if (model.name !== undefined) {
          key["id"] = model.name;
        } else if (model.tag !== undefined) {
          key["id"] = model.tag;
        }
        return key;
      });
      Tremr.dispatcher.message(this, "tremr:follow:forentity", ids);
    }
  }.bind(this);

  // factory for footer content
  // this.getFooterContent = function(context) {
  //
  //     var tabs = store.getTabs(context);
  //     var defaultTab = store.getDefaultTab(context);
  //
  //     var key = "searchWall";
  //     var Wallref = key+"."+context.term.replace(' ', '')+"."+context.scope;
  //     var ref = "footer"+Wallref;
  //
  //     return <Footer ref={ref} key={ref}>
  //         <section>
  //             <Tremr.Generic.SvgIcon icon="search" />
  //             <label>{context.term}</label>
  //             &nbsp;|&nbsp;
  //             <label>{context.scope}</label>
  //         </section>
  //         <Tremr.Layout.Filter tipPrefix="Order by " icons={true} wallIdentifier={Wallref} tabs={tabs} initialTab={defaultTab} />
  //     </Footer>;
  // };

  // factory for getting the content for a state
  this.getPrimaryContent = function (context, cache) {
    var tabs = store.getTabs(context);
    var defaultTab = store.getDefaultTab(context);
    var datasources = store.getDataSources(context);
    let controls = null;
    let wallStyleProperty = null;
    let forceGridStyle = "list";
    let loadedCallbackWait = false;
    let loadedCallback = _.null;

    // var loadedCallback = this.requestActivityForPosts;
    if (
      context.scope == "users" ||
      context.scope == "tags" ||
      context.scope == "channels"
    ) {
      loadedCallback = this.requestFollowingForEntity;
    } else {
      controls = <WallControls wallStyleProperty="wallStylePosts" />;
      wallStyleProperty = "wallStylePosts";
      forceGridStyle = null;
      loadedCallbackWait = true;
    }

    // call both callbacks
    var callback = function (data, wallStyle) {
      let fullContext = Tremr.context.getState();
      let isCached = fullContext.cache == true;

      _.defer(() => {
        loadedCallback(data);
        // this.requestActivityForPosts(data);
        // if (wallStyle == 'content' && !isCached) {
        //    this.requestContentForPosts(data);
        // }
      });

      if (isCached) {
        Tremr.context.add({
          cache: false,
        });
        // scroll to initial position
        if (context && context.initialScrollPos) {
          scrollToPos();
        }
      }
    }.bind(this);

    var cardFactory = this.cardFactory;
    var collFactory = this.collFactory;
    var key = "searchWall";
    if (context.sort) {
      key = key + "." + context.sort;
    }

    var ref = key + "." + context.term.replace(" ", "") + "." + context.scope;
    return (
      <Tremr.Generic.Wall
        loadedCallbackWait={loadedCallbackWait}
        forceGridStyle={forceGridStyle}
        wallStyleProperty={wallStyleProperty}
        controls={controls}
        key={ref}
        ref={ref}
        identifier={ref}
        loadedCallback={callback}
        cardFactory={cardFactory}
        collectionFactory={collFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  this.collFactory = function () {
    return new Tremr.Models.Searches();
  };

  // create card depending on type of data
  this.cardFactory = function (model, index, props) {
    var key = "card-" + index + "-" + model.get("id");

    if (model.get("slug") && model.get("author_name")) {
      if (props.wallStyle == "content") {
        return (
          <PostContentCard
            loadedCallback={props.loadedCallback}
            ref={key}
            key={key}
            post={model}
          />
        );
      } else if (props.wallStyle == "list") {
        return (
          <PostListCardWrapper
            loadedCallback={props.loadedCallback}
            ref={key}
            key={key}
            post={model}
          />
        );
      } else {
        return (
          <Tremr.Post.Card
            loadedCallback={props.loadedCallback}
            ref={key}
            key={key}
            post={model}
            cardHeightSet={props.cardHeightSet}
          />
        );
      }
    } else if (model.get("author_name") && model.get("stats")) {
      return (
        <Tremr.Feed.Card
          ref={key}
          key={key}
          feed={model}
          startWidth={props.startWidth}
          startTop={props.startTop}
          cardHeightSet={props.cardHeightSet}
        />
      );
    } else if (model.get("tag") && model.get("followers") >= 0) {
      return (
        <Tremr.Tag.Card
          ref={key}
          key={key}
          tag={model}
          startWidth={props.startWidth}
          startTop={props.startTop}
          cardHeightSet={props.cardHeightSet}
        />
      );
    } else {
      console.log("Unkown data found in search:");
      console.dir(model.attributes);
      return null;
    }
  };

  // get named tabs that we want to show for specific context
  this.getTabs = function (context) {
    if (context.scope == "posts") {
      return ["relevance", "trending", "recommended", "latest"];
    } else if (context.scope == "channels") {
      return ["relevance"];
    } else if (context.scope == "users") {
      return ["relevance", "latest", "alphabetical"];
    } else if (context.scope == "tags") {
      return ["relevance", "alphabetical"];
    } else {
      return ["relevance"];
    }
  };

  // get default tab for specific context
  this.getDefaultTab = function (context) {
    return "relevance";
  };

  // load posts from the server using a prebuilt backbone posts collection
  this.searchPosts = function (page, collection, context, cache) {
    context.src = "search.posts";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  // load users from the server using a prebuilt backbone posts collection
  this.searchUsers = function (page, collection, context, cache) {
    context.src = "search.users";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  this.searchChannels = function (page, collection, context, cache) {
    context.src = "search.channels";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  // load tags from the server using a prebuilt backbone posts collection
  this.searchTags = function (page, collection, context, cache) {
    context.src = "search.tags";
    return Tremr.Utils.loadCollection(page, collection, context, cache);
  };

  // get named data source function for a specific context
  this.getDataSources = function (context) {
    // do in a function and use dynamically in datasources
    var getDataForContext = function (c) {
      return c;
    };

    var sources = [];

    if (
      context.target &&
      context.target == "search" &&
      context.scope == "posts"
    ) {
      sources["relevance"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchPosts(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };

      sources["latest"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchPosts(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };

      sources["recommended"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchPosts(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };

      sources["trending"] = function (page, collection, cntxt, cache) {
        return [];
        // return Tremr.stores.searchStore.searchPosts(page, collection, _.extend(getDataForContext(cntxt), { sort: 'trending' }), cache);
      };
    } else if (
      context.target &&
      context.target == "search" &&
      context.scope == "channels"
    ) {
      sources["relevance"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchChannels(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };
    } else if (
      context.target &&
      context.target == "search" &&
      context.scope == "users"
    ) {
      sources["relevance"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchUsers(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };

      sources["latest"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchUsers(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };

      sources["alphabetical"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchUsers(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };
    } else if (
      context.target &&
      context.target == "search" &&
      context.scope == "tags"
    ) {
      sources["relevance"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchTags(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };

      sources["alphabetical"] = function (page, collection, cntxt, cache) {
        return Tremr.stores.searchStore.searchTags(
          page,
          collection,
          getDataForContext(cntxt),
          cache
        );
      };
    } else {
      console.log("DataSource not implemented for:");
      console.dir(context);
    }

    return sources;
  };

  return store;
};
