require("./scss/footer.scss");

var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getInitialState() {
		return {
            // shown: false,
            shown: true,
			lastScrollTop: 0
		};
	},

	// handleScroll: function(event) {
    //
	// 	let scrollTop = window.scrollY;
    //
	// 	if (this.state.shown && scrollTop > this.state.lastScrollTop) {
	// 		this.setState({
	// 			shown: false,
	// 			lastScrollTop: scrollTop
	// 		});
	// 	} else if (!this.state.shown && scrollTop <= this.state.lastScrollTop) {
	// 		this.setState({
	// 			shown: true,
	// 			lastScrollTop: scrollTop
	// 		});
	// 	} else {
	// 		this.setState({
	// 			lastScrollTop: scrollTop
	// 		});
	// 	}
    //
    //
	// },
    //
	// componentDidMount: function() {
    //
	// 	// listen to alert tab for events instead of checking ourselves
	// 	if (!this.stopListening) { _.extend(this, Backbone.Events); }
    //
	// 	window.addEventListener("scroll", this.handleScroll);
    // },
    //
    // componentWillUnmount: function() {
    //
    //     if (this.stopListening) {
    //         this.stopListening();
    //     }
    //
    //     window.removeEventListener("scroll", this.handleScroll);
    // },

	render: function() {

		var classes = {
			'layout-footer': true,
			'shown': this.state.shown
		};
		classes = classNames(classes);

		return (
			<footer className={classes}>
				{this.props.children}
			</footer>
		);
	}
});
