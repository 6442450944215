require("./scss/titlebar.scss");

var ProfileMenu = require("../../stores/user/profilemenu");
var SignInUpMenu = require("../../stores/user/signinupmenu");
var SearchMenu = require("../../stores/search/menu");
var UserAwareMixin = require("../../mixins/userawaremixin");
var CreateReactClass = require("create-react-class");
var TremrStorage = require("../../utils/tremr_storage");
// var Logo = require("../generic/logo");
// var Logo = Tremr.Theme.Logo;
var LogoTr = require("../generic/logotr");
var SvgIcon = require("../generic/svgicon");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin, UserAwareMixin],

  getDefaultProps: function () {
    return {
      style: "stripes", // or light
      extraClass: "",
      showBack: false
    };
  },

  openEditor: function (event) {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:editor:open", {});
  },

  homepage: function (event) {
    // just allow a normal hyperlink for now
    // event.preventDefault();
    // event.stopPropogation();
  },

  goBack: function(event) {
    event.preventDefault();
    event.stopPropagation();
    window.history.back();
},

updated: function(newState) {
    let history = TremrStorage.getSession('tremr_history') || [];
    this.setState({
        history: history.length
    });
},

componentDidMount: function() {
    // watch for changes to the global state
    Tremr.dispatcher.on("tremr:contextchanged", this.updated);
},

componentWillUnmount: function() {
    Tremr.dispatcher.off("tremr:contextchanged", this.updated);
},

  render: function () {
    let baseColor = "#2d2d2d";
    let highlightColor = "#2d2d2d";
    if (this.props.style != "dark") {
      baseColor = "white";
      highlightColor = "white";
    }

    let logo = (
      <a href="/" onClick={this.homepage} className="logo">
        <Tremr.Theme.Logo
          baseColor={baseColor}
          highlightColor={highlightColor}
          width={"auto"}
          height={"auto"}
        />
      </a>
    );
    if (this.props.logo == "tr") {
      logo = (
        <a href="/" onClick={this.homepage} className="logotr">
          <LogoTr
            baseColor={baseColor}
            highlightColor={highlightColor}
            width={"auto"}
            height={"auto"}
          />
        </a>
      );
    }    

    if (this.props.showBack && TremrStorage.getLocal("ui-app") == true) {
      let history = TremrStorage.getSession('tremr_history') || [];
      if (history.length > 0) {
          logo = <a href={_.last(history)} className="back" onClick={this.goBack}>
              <SvgIcon icon="back" classes="icon"/>
          </a>;
      }
  }

    let search = null;
    if (!this.props.hideSearch) {
      search = <SearchMenu />;
    }
    let newPost = null;
    if (this.state.user) {
      newPost = (
        <div className="button" onClick={this.openEditor}>
          <SvgIcon icon="write" />
        </div>
      );
    }

    return (
      <div
        className={
          "layout-titlebar " + this.props.style + " " + this.props.extraClass
        }
      >
        <div className="layout-titlebar-content">
          {logo}
          {this.props.children}
          {newPost}
          {search}
          <SignInUpMenu />
          <ProfileMenu />
        </div>
      </div>
    );
  },
});
