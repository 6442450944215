require("./scss/viewchart.scss");

var moment = require('moment');
var classNames = require('../../utils/classnames');
var UserAwareMixin = require('../../mixins/userawaremixin');
var CreateReactClass = require('create-react-class');

// menu at the top of the primary position
// Tremr.Feed.ViewChart
module.exports = CreateReactClass({

  mixins: [PureRenderMixin, UserAwareMixin],

  promises: [], // promise references so we can cancel on unmount

  getInitialState: function() {
    return {
      isLoading: true,
      model: false
    }
  },

  getDefaultProps: function() {
    return {
      refreshTime: 29000
    };
  },

  // do the check
  viewDataCheck: function() {
      if (this.state.user) {
        this.load();
      }
  },

  // periodically check the remaining notification count
  startViewDataCheck: function() {
      if (!this.refreshTimer) {
          this.refreshTimer = setInterval(function() {
              this.viewDataCheck();
          }.bind(this), this.props.refreshTime);
      }
  },

  componentDidMount: function() {

    if (!this.stopListening) { _.extend(this, Backbone.Events); }

    // listen for sign-in/out
    this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function(user) {
        this.load();
        this.startViewDataCheck();
    }.bind(this));

    this.listenTo(Tremr.dispatcher, "tremr:user:signout:success", function() {
        this.setState({
            model: false
        });
        clearInterval(this.refreshTimer);
    }.bind(this));

    // do immediately if already signed-in
    if (this.state.user) {
        this.load();
        this.startViewDataCheck();
    }

    var domNode = ReactDOM.findDOMNode(this);
    var width = domNode.offsetWidth;

    this.setState({
      width: width
    });

  },

  // stop reloading first page
  componentWillUnmount: function() {

      this.promises = _.reject(this.promises, function(promise) {
        promise.cancel();
        return true;
      });

      if (this.refreshTimer) {
          clearInterval(this.refreshTimer);
      }

      if (this.stopListening) {
        this.stopListening();
      }
  },


  // load from server
  load: function() {

    if (this.state.user) {
      var key = { id: this.state.user.get('feedname') };
      var m = new Tremr.Models.FeedView(key);

      var promise = Tremr.Utils.loadModel(m, key, false, true);
      this.promises.push(promise);
      promise.then(function(params) {

        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.setState({
          isLoading: false,
          model: model
        });

      }.bind(this), function(params) {

        console.error("Error loading Feed View Data for Chart:");
        console.dir(params);
        
        // clear loading state
        this.setState({
          isLoading: false
        });
      }.bind(this));
    }

  },

  render: function() {

    var classes = {
      'feed-views': true,
      'loading': this.state.isLoading,
      'has-data': this.state.model
    };
    classes = classNames(classes);

    var total = '…';
    var today = '…';
    var time = moment().format("h.mma");

    let chart = null;
    if (this.state.width && this.state.width > 0) {

        var chartData = new Array(30+1).join('0').split('').map(parseFloat);
        if (this.state.model) {
          chartData = this.state.model.get('daily');
          total = this.state.model.get('total').toLocaleString();
          today = this.state.model.get('today').toLocaleString();
          time = moment(this.state.model.get('timestamp'), "YYYY-MM-DDTHH:mmZ").format("h.mma");
        }

        chart = <Tremr.Generic.BarChart data={chartData} height={80} width={this.state.width} />;
    }

    return (

      <div className={classes}>

        <div className="stat">
          <label><em>Views</em> - 30 days</label>
          <output>{total}</output>
        </div>

        <div className="stat">
          <label>Today - {time}</label>
          <output>{today}</output>
        </div>

        {chart}
      </div>
    );
  }
});
