require("./scss/responsesmenu.scss");

var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	propTypes: {
		switchTab: PropTypes.func.isRequired,
        currentTab: PropTypes.string.isRequired,
        counts: PropTypes.object.isRequired,
        level: PropTypes.number.isRequired,
        style: PropTypes.object.isRequired,
        feed: PropTypes.string.isRequired,
        post_id: PropTypes.string.isRequired,
        initialSubTab: PropTypes.string
    },

    getDefaultProps: function() {
    	return {
    		level: 0,
    		style: {}
    	};
    },

	render: function() {

        let classes = "post-responsesmenu level-"+this.props.level.toString();

		let topMenu = null;
		if (this.props.level == 0 || this.props.level == 1) {
			topMenu = <nav className="menu">
				<Tremr.Generic.StatTab handleClick={this.props.switchTab} value="responses" data={this.props.counts.all} label="Responses" active={(this.props.currentTab == 'responses')} />
				<Tremr.Generic.StatTab handleClick={this.props.switchTab} value="contributors" data={this.props.counts.contributors} label="People" active={(this.props.currentTab == 'contributors')} />
	        </nav>;
	    }
// <Tremr.Generic.StatTab handleClick={this.props.switchTab} value="direct responses" data={this.props.counts.direct} label="Direct Responses" active={(this.props.currentTab == 'direct responses')} />
// <Tremr.Generic.StatTab handleClick={this.props.switchTab} value="responses" data={this.props.counts.all} label="All Responses" active={(this.props.currentTab == 'responses')} />
// <Tremr.Generic.StatTab handleClick={this.props.switchTab} value="related" data={this.props.counts.related} label="Related" active={(this.props.currentTab == 'related')} />

        // submenu for sort depends on selected tab
        let subMenu = null;
        if (this.props.level == 0 || this.props.level == 2) {
	        let context = {
	        	target: this.props.currentTab,
	        	slug: this.props.feed+'!'+this.props.post_id,
	            scope: 'all'
	        };
	        let tabs = Tremr.stores.postStore.getTabs(context);
			let defaultTab = Tremr.stores.postStore.getDefaultTab(context);

            // override if init tab set
            if (this.props.initialSubTab) {
                defaultTab = this.props.initialSubTab;
            }

            let tab = this.props.currentTab.replace(' ', '-');
            if (tab == 'responses') {
                tab = 'direct-responses';
            }

	        let key = "responses."+tab+"."+context.slug+"."+context.scope;
            if (this.props.currentTab == 'related') {
	        	key = "related."+context.slug+"."+context.scope;
	        } else if (this.props.currentTab == 'contributors') {
	        	key = "responses.contributors."+context.slug;
            }

		    subMenu = <Tremr.Layout.Filter tipPrefix="Order by " icons={false} wallIdentifier={key} tabs={tabs} initialTab={defaultTab} />;
		}

	 return <div className={classes} style={this.props.style}>
	 	{this.props.children}
	 	{topMenu}
	 	{subMenu}
	 </div>;
	}
});
