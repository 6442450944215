var UserAwareMixin = require('../../mixins/userawaremixin');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// show a stattab with the users drafts counted in it, also listens for drafts being
// created/removed and keeps the local count up to date without having to reload
module.exports = CreateReactClass({

  mixins: [PureRenderMixin, UserAwareMixin],

  promises: [], // promise references so we can cancel on unmount

  propTypes: {
      href: PropTypes.string,
      active: PropTypes.bool,
      onClick: PropTypes.func
  },

  getDefaultProps: function() {
      return {
          href: '',
          active: false,
          onClick: function() {}
      }
  },

  getInitialState: function() {
    return {
      draftsCount: false
    }
  },

  // check if we need to get the users draft count
  checkDraftCount: function() {

    if (this.state.user && !this.state.draftsCount) {

      // load the users draft count
      var promise = Tremr.stores.userStore.countDrafts(this.state.user.get('_id'));
      this.promises.push(promise);
      promise.then(function(value) {

        this.setState({
          draftsCount: value.get('count')
        });

      }.bind(this));
    }
  },

  // register listeners
  componentDidMount: function() {

    _.extend(this, Backbone.Events);

    // watch for updated draft count
    this.listenTo(Tremr.dispatcher, "tremr:draft:refresh", function(data) {

      if (this.state.draftsCount && data.adjust) {
        this.setState({
          draftsCount: this.state.draftsCount + data.adjust
        });
      }
    }.bind(this));

    this.checkDraftCount();

  },

  componentDidUpdate: function(prevProps, prevState) {

    this.checkDraftCount();
  },

  // unregister listeners
  componentWillUnmount: function() {

      this.promises = _.reject(this.promises, function(promise) {
        promise.cancel();
        return true;
      });

      this.stopListening();
  },

  render: function() {

    var data = this.state.draftsCount || 0;

    return (

      <Tremr.Generic.StatTab href={this.props.href} handleClick={this.props.onClick} value="drafts" data={data} label="Drafts" active={this.props.active} />
    );
  }
});
