var Backbone = require('backbone');
var Config = require('config');

// Tremr.Models.Follow
module.exports = Backbone.Model.extend({
    urlRoot: Config.apiurl+'/api/following'

});




