var Widget = require('jquery.ui.widget');
var BlueimpFileUpload = require('blueimp-file-upload');
var CloudinaryJqueryFileUpload = require('cloudinary-jquery-file-upload');

// var LoadImage = require('blueimp-load-image');

// var IframeTransport = require('blueimp-file-upload/js/jquery.iframe-transport');
// var Fileupload = require('blueimp-file-upload/js/jquery.fileupload');
// var FileuploadImage = require('blueimp-file-upload/js/jquery.fileupload-image');
// var cloudinary = require('cloudinary-jquery-file-upload/cloudinary-jquery-file-upload');
var CreateReactClass = require('create-react-class');
var PropTypes = require("prop-types");

// Tremr.Generic.Imageupload = React.createClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin], // can't use this because of fade in

    propTypes: {
        buttonText: PropTypes.object,
        showPreview: PropTypes.bool,
        showPlaceholder: PropTypes.bool,
        showProgress: PropTypes.bool,
        previewHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        previewWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        previewMaxHeight: PropTypes.number,
        showRemove: PropTypes.bool,
        initialImage: PropTypes.object,
        startHandler: PropTypes.func,
        updatedHandler: PropTypes.func,
        progressHandler: PropTypes.func,
        failHandler: PropTypes.func
    },


    // state to allow for fading in images
    getInitialState: function() {
        return {
            buttonText: 'Upload',
            inProgress: false,
            progress: 0,
            image: this.props.initialImage
        }
    },

    // set default props
    getDefaultProps: function() {
        return {
            showPreview: true,
            showPlaceholder: false,
            widshowRemoveth: true,
            initialImage: null,
            previewHeight: 0,
            previewWidth: 0,
            previewResize: false,
            previewMaxHeight: 5000,
            showRemove: true,
            showProgress: true,
            startHandler: function(image) {},
            updatedHandler: function(image) {},
            progressHandler: function(image) {}
        }
    },

    componentDidMount: function() {

        // cloudinary options from user
        var user = Tremr.stores.userStore.getUser();
        var cloudinary_url = user.get('cloudinary_url') + 'auto/upload';

        // build upload and instantiate cloudinary control
        // (input has to be manually insterted - or we get
        // problems with react because cloudinary plugin messes with DOM)
        var domNode = ReactDOM.findDOMNode(this.refs.upload_container);
        var container = $(domNode);
        var attribs = {
            class: "cloudinary-fileupload",
            name: 'file',
            type: 'file'
        };
        attribs['data-cloudinary-field'] = "image_id";
        attribs['data-form-data'] = JSON.stringify(user.get('cloudinary_options'));
        attribs['data-url'] = cloudinary_url;
        var $upload = $("<input>", attribs);
        container.html($upload);

        var node = ReactDOM.findDOMNode(this);
        let cloudinary = $(node).find('input.cloudinary-fileupload[type=file]');
        if (cloudinary.length) {
            cloudinary.off("click");
            cloudinary.off("cloudinarystart");
            cloudinary.off("fileuploadprogress");
            cloudinary.off("cloudinarydone");
            cloudinary.cloudinary_fileupload({
                acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|tiff?)$/i,
                processalways: function(e,data){
                  if (data.files.error) alert(data.files[0].error);
                }
            });

            cloudinary.on('cloudinarystart', this.uploadstarted);
            cloudinary.on('fileuploadprogress', this.uploadprogress);
            cloudinary.on('cloudinarydone', this.uploaddone);
            if (this.fileuploadfail) {
                cloudinary.on('fileuploadfail', this.fileuploadfail);
            }
        }
    },

    // unregister event handlers
    componentWillUnmount: function() {
        var node = ReactDOM.findDOMNode(this);
        var cloudinary = $(node).find('input.cloudinary-fileupload[type=file]');
        if (cloudinary.length) {
            cloudinary.off("click");
            cloudinary.off("cloudinarystart");
            cloudinary.off("fileuploadprogress");
            cloudinary.off("cloudinarydone");
            cloudinary.off("fileuploadfail");
        }
        cloudinary.remove();
    },

    // programaticly start the upload
    startUpload: function() {

        var node = ReactDOM.findDOMNode(this);
        var cloudinary = $(node).find('input.cloudinary-fileupload[type=file]');
        $(cloudinary).trigger('click');
    },

    // fade out existing image and handle progress marker
    uploadstarted: function(e) {
        this.props.startHandler();
        this.props.progressHandler(0);
        this.setState({
            inProgress: true,
            progress: 0,
            image: false
        });
    },

    uploadprogress: function(e, data) {
        var progress = Math.round((data.loaded * 100.0) / data.total);
        this.props.progressHandler(progress);
        this.setState({
            progress: progress
        });
    },

    // upload is completed
    uploaddone: function(e, data) {
        if (data.textStatus == "success") {

            this.props.progressHandler(100);
            this.setState({
                inProgress: false,
                progress: 100,
                image: data.result
            });

            this.props.updatedHandler(data.result);

        } else {
            alertify.error("Error uploading image: "+data.textStatus);
        }
    },

    // upload failed
    fileuploadfail: function(e, data) {

        this.props.progressHandler(0);
        this.setState({
            inProgress: false,
            progress: 0,
            image: false
        });

        if (this.props.failHandler) {
            this.props.failHandler(data.result);
        }
    },

    // activate the select file
    selectFile: _.debounce(function(event) {

        event.stopPropagation();
        event.preventDefault();

        var node = ReactDOM.findDOMNode(this);
        $(node).find("input.cloudinary-fileupload[type=file]").click();

    }, 200, true),

    remove: function(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            image: null
        });
    },


    // display based on the image property
    render: function() {

        var mediaPreview = '';
        if (this.props.showPreview && this.state.image && this.state.image.public_id) {
            // mediaPreview = <Tremr.Generic.Image image={this.state.image} height={this.props.previewHeight} maxHeight={this.props.previewMaxHeight} width={this.props.previewWidth} resize={this.props.previewResize} />;
            // console.log("this.props.previewHeight:"+this.props.previewHeight);
            // content: <Tremr.Generic.Image key={'image-'+image.public_id} image={image} maxHeight={5000} height={false} width="auto" resizable={true} />,

            mediaPreview = <Tremr.Generic.Image gravity="g_auto" urlHeight={this.props.previewHeight} urlWidth={"auto"} height={this.props.previewHeight} width={this.props.previewWidth} image={this.state.image} maxHeight={5000} resizable={this.props.previewResize} />
        } else if (this.props.showPreview && this.props.showPlaceholder) {
            mediaPreview = <div className="image placeholder">
                <Tremr.Generic.SvgIcon icon="photo" />
            </div>;
        }

        var removeButton = '';
        if (this.props.showRemove && this.state.image && !this.state.inProgress) {
            removeButton = <a onClick={this.remove} className="remove-image">
                <Tremr.Generic.SvgIcon icon="remove" />
            </a>;
        }

        var inProgress = '';
        if (this.props.showProgress && this.state.inProgress) {
            let progressStyle = {
                width: this.state.progress+'%'
            };
            inProgress = <div className="progress">
                <span className="meter" style={progressStyle}></span>
            </div>;
        }

        // put the image in using specified dimensions
        return (<div className='image-upload'>
            {mediaPreview}
            {inProgress}

            <div className="upload-container" ref="upload_container"></div>

            <a onClick={this.selectFile} className="button upload">{this.props.buttonText}</a>

            {removeButton}

        </div>);
    }
});
