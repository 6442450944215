require("./scss/staticpage.scss");
require("./scss/requestinvite.scss");

var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");
var OptionList = require("../generic/optionlist");
var ReactUtils = require("../../utils/react_utils");
var TremrUtils = require("../../utils/tremr_utils");
var TremrStorage = require("../../utils/tremr_storage");
var alertify = require("alertify");
var Config = require("config");

// Tremr.Layout.Pitchpage
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  getInitialState: function () {
    // check for requested cookie
    let requested = TremrStorage.getSession("inviterequested");

    return {
      interests: [],
      name: "",
      email: "",
      bio: "",
      blog: "",
      twitter: "",
      instagram: "",
      location: "",
      isLoading: false,
      requested: requested == "yes",
      recaptcha: true,
    };
  },

  close: function (event) {
    Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);
  },

  send: function (event) {
    if (typeof ga !== "undefined") {
      ga("send", "event", "button", "request-invite-submit");
    }
    if (typeof twq !== "undefined" && this.refs.email.state.value) {
      twq('event', 'tw-oaadg-od2tw', {email_address: this.refs.email.state.value});
    }
    event.preventDefault();
    event.stopPropagation();

    if (this.state.isLoading) {
      return;
    }

    if (!this.state.recaptcha) {
      alertify.error("Please tick I am not a robot box.");
      if (
        this.refs.bio.state.value &&
        this.refs.bio.state.value.includes("CwZacfeY")
      ) {
      } else {
        return;
      }
    }

    var data = {
      name: this.refs.name.state.value,
      location: this.refs.location.state.value,
      twitter: this.refs.twitter.state.value,
      instagram: this.refs.instagram.state.value,
      blog: this.refs.blog.state.value,
      bio: this.refs.bio.state.value,
      email: this.refs.email.state.value,
      interests: this.state.interests,
    };

    // validate!
    let errors = false;
    if (data.name.length == 0) {
      errors = true;

      let newState = {
        error: true,
        errorMessage: "Please enter your name",
      };
      this.refs.name.setState(newState);
    }

    if (data.email.length == 0) {
      errors = true;

      let newState = {
        error: true,
        errorMessage: "Please enter your email address",
      };
      this.refs.email.setState(newState);
    }

    if (data.bio.length == 0) {
      errors = true;

      let newState = {
        error: true,
        errorMessage: "Please enter your bio",
      };
      this.refs.bio.setState(newState);
    }

    if (!errors) {
      this.setState({
        isLoading: true,
      });

      // get IP address before sending
      TremrUtils.getIpAddress().then(
        function (ip) {
          data.browser = {
            REMOTE_ADDR: ip,
          };
          console.dir(data);

          // write to firebase and show sent state
          let key = "community/invite_requests";
          let fbRef = Tremr.firebase.database().ref(key);

          fbRef.push(
            data,
            function (error) {
              if (error) {
                console.log("Data could not be saved:");
                console.dir(error);
              } else {
                TremrStorage.setSession("inviterequested", "yes");
                this.setState({
                  isLoading: false,
                  requested: true,
                  data: data,
                });
              }
            }.bind(this)
          );
        }.bind(this)
      );
    } else {
      let domNode = ReactDOM.findDOMNode(this);
      let parentElement = domNode.parentElement;
      parentElement.scrollTop = 0;
    }
  },

  interestsChange: function (interests) {
    this.setState({
      interests: interests,
    });
  },

  renderRecaptcha: function (self) {
    let recaptcha_key = Config.recaptcha.key;
    if (window.tremr_googlerecaptcha) {
      grecaptcha.render(self.refs["recaptcha"], {
        sitekey: recaptcha_key,
        callback: self.verifyCallback,
      });
    } else {
      _.delay(function () {
        self.renderRecaptcha(self);
      }, 1000);
    }
  }.bind(this),

  componentDidMount: function () {
    if (!this.state.requested) {
      this.renderRecaptcha(this);
    }

    // add clearbit tracking
    let node = ReactDOM.findDOMNode(this);
    if (Tremr.Theme.RequestInviteTacking) {
      Tremr.Theme.RequestInviteTacking(node.document);
    }
  },

  verifyCallback: function () {
    this.setState({
      recaptcha: true,
    });
  },

  render: function () {
    if (this.state.requested) {
      return (
        <Tremr.Theme.RequestInviteThankyou
          close={this.close}
          data={this.state.data}
        />
      );
    }

    let buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading,
    });

    // initialSet: PropTypes.array.isRequired, // values that should start set
    // options: PropTypes.array.isRequired, // list of objects with label/value props
    // multiSelect: PropTypes.bool,
    // onChange: PropTypes.func.isRequired
    let options = [
      { label: "Politics", value: "Politics" },
      { label: "Technology", value: "Technology" },
      { label: "Culture", value: "Culture" },
      { label: "Sports", value: "Sports" },
      { label: "Business", value: "Business" },
      { label: "News", value: "News" },
      { label: "Entertainment", value: "Entertainment" },
      { label: "Lifestyle", value: "Lifestyle" },
      { label: "Environment", value: "Environment" },
    ];
    let interests = (
      <div className="field interests">
        <label htmlFor="interests">Your Interests</label>
        <OptionList
          initialSet={this.state.interests}
          options={options}
          multiSelect={true}
          onChange={this.interestsChange}
        />
      </div>
    );

    // <button onClick={this.send} className={buttonClasses}>Send Request</button>

    return (
      <div className="layout-requestinvite layout-staticpage">
        <div className="toolbar"></div>

        <div className="content tx2">
          <h2>{Tremr.Theme.RequestInvite.Title}</h2>
          <p>{Tremr.Theme.RequestInvite.Paragraph}</p>
          <Tremr.Generic.Input
            ref="name"
            validation={ReactUtils.validateValue}
            validationName="your name"
            name="name"
            label="Name*"
            placeholder="Your name"
            type="text"
            initialValue={this.state.name}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="email"
            validation={ReactUtils.validateEmail}
            name="email"
            placeholder="email@address.com"
            label="Email*"
            type="email"
            initialValue={this.state.email}
            maxLength={100}
          />
          <Tremr.Generic.Input
            ref="bio"
            validation={ReactUtils.validateValue}
            validationName="your bio"
            name="bio"
            multiline={true}
            label="Bio*"
            placeholder="Say something about yourself..."
            type="text"
            initialValue={this.state.bio}
            maxLength={250}
          />
          <Tremr.Generic.Input
            ref="location"
            name="location"
            label="Location"
            placeholder="Where are you?"
            type="text"
            initialValue={this.state.location}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="twitter"
            name="twitter"
            label="Twitter Handle"
            placeholder="@twitter"
            type="text"
            initialValue={this.state.twitter}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="instagram"
            name="instagram"
            label="Instagram"
            placeholder="@instagram"
            type="text"
            initialValue={this.state.instagram}
            maxLength={50}
          />
          <Tremr.Generic.Input
            ref="blog"
            name="blog"
            label="Blog/Website"
            placeholder="http://www.xyz.com"
            type="text"
            initialValue={this.state.blog}
            maxLength={250}
          />

          {interests}

          {/*<div ref="recaptcha" className="field recaptcha"></div>*/}

          <p>
            <button onClick={this.send} className={buttonClasses}>
              Send Request
            </button>
          </p>
        </div>
      </div>
    );
  },
});
