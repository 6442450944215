require("./scss/sidebar_activity.scss");

var TremrUtils = require("../../utils/tremr_utils");
var classNames = require("../../utils/classnames");
var UserAwareMixin = require("../../mixins/userawaremixin");
var DraftsTab = require("../../stores/user/draftstab");
var ManageSubfeeds = require("../user/manage_subfeeds");
var CreateReactClass = require("create-react-class");
var Config = require("config");
var Notifications = require("../../stores/user/notifications");
var SvgIcon = require("../generic/svgicon");

module.exports = CreateReactClass({
  // mixins: [PureRenderMixin, UserAwareMixin],
  mixins: [UserAwareMixin],

  getInitialState: function () {
    return {
      isLoading: true,
      promises: [],
    };
  },

  loadFeed: function (user) {
    // load the feed
    var feed = new Tremr.Models.Feed({
      id: user.get("feedname"),
    });
    var promise = Tremr.stores.feedStore.loadFeed(
      feed,
      user.get("feedname"),
      false
    );
    this.setState({
      feed: feed,
      promises: this.state.promises.slice(0).concat(promise),
    });
    promise.then(
      function (params) {
        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.setState({
          isLoading: false,
          feed: model,
        });

        // set a global highlight colour - read from user!
        if (model.get("highlight_color")) {
          // document.documentElement.style.setProperty('--feed-highlight-color', model.get('highlight_color'));
          Tremr.setFeedHighlightColor(model.get("highlight_color"));
        } else {
          // document.documentElement.style.setProperty('--feed-highlight-color', Config.colors.highlight);
          Tremr.setFeedHighlightColor(Config.colors.highlight);
        }
      }.bind(this)
    );
  },

  loadMore: function () {
    this.refs["notifications"].loadNextPage();
  },

  handleScroll: function () {
    let node = ReactDOM.findDOMNode(this.refs["scrollContainer"]);
    if (node) {
      let scrollTop = node.scrollTop;
      let scrollHeight = node.scrollHeight;
      let clientHeight = node.clientHeight;

      if (scrollTop + clientHeight + 300 > scrollHeight) {
        this.loadMore();
      }
    }
  },

  addScrollHandler: function () {
    let node = ReactDOM.findDOMNode(this.refs["scrollContainer"]);
    if (TremrUtils.passiveSupported) {
      node.addEventListener("scroll", this.handleScroll, { passive: true });
    } else {
      node.addEventListener("scroll", this.handleScroll);
    }
  },

  componentDidUpdate: function (prevProps, prevState) {
    if (prevState.isLoading && !this.state.isLoading) {
      this.addScrollHandler();
    }
  },

  // load data from server when first added to DOM
  componentDidMount: function () {
    // watch for scroll
    if (this.state.isLoading === false) {
      this.addScrollHandler();
    }

    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    // listen for sign-in/out and update user in our session
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:signin:success",
      function (user) {
        if (!this.state.feed || !this.state.feed.get("name")) {
          this.loadFeed(user);
        }
      }.bind(this)
    );

    // listen for profile updates
    this.listenTo(
      Tremr.dispatcher,
      "tremr:user:profile:success",
      function (user) {
        this.loadFeed(user);
      }.bind(this)
    );

    // until we load se the highlight as grey
    // document.documentElement.style.setProperty('--feed-highlight-color', '#cccccc');
    Tremr.setFeedHighlightColor("#cccccc");

    // if we have a user, load there feed
    if (this.state.user) {
      this.loadFeed(this.state.user);
    }
  },

  // unregister listeners
  componentWillUnmount: function () {
    let node = ReactDOM.findDOMNode(this.refs["scrollContainer"]);
    node.removeEventListener("scroll", this.handleScroll);

    this.state.promises = _.reject(this.state.promises, function (promise) {
      promise.cancel();
      return true;
    });

    if (this.stopListening) {
      this.stopListening();
    }

    // return hard-coded highlight color
    // document.documentElement.style.setProperty(
    //   "--feed-highlight-color",
    //   Config.colors.highlight
    // );
    Tremr.setFeedHighlightColor(Config.colors.highlight);
  },

  signOut: function (event) {
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:user:signout", {});
    Tremr.dispatcher.message(this, "tremr:user:sidebar", false);
  },

  editProfile: function () {
    event.preventDefault();
    event.stopPropagation();
    Tremr.dispatcher.message(this, "tremr:open:profile", {});
    Tremr.dispatcher.message(this, "tremr:user:sidebar", false);
  },

  viewProfile: function (event) {
    if (event.metaKey) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:open:userfeed", event);
    Tremr.dispatcher.message(this, "tremr:user:sidebar", false);
  },

  newChannel: function (event) {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:feed:edit", {
      parentFeedname: this.state.feed.get("id"),
    });
    Tremr.dispatcher.message(this, "tremr:user:sidebar", false);
  },

  render: function () {
    var classes = {
      "user-sidebar-activity": true,
      sidebar: true,
      loading: this.state.isLoading,
    };
    classes = classNames(classes);

    if (this.state.isLoading) {
      return <div className={classes}></div>;
    }

    var avatarSize = 140;
    let avatar = null;
    let title = null;
    let profileUrl = null;

    if (this.state.feed) {
      if (
        this.state.feed.get("author_avatar_image") &&
        this.state.feed.get("author_avatar_image").public_id
      ) {
        avatar = (
          <div
            key={
              "authorAvatar" +
              this.state.feed.get("author_avatar_image").public_id
            }
            className="avatar"
          >
            <Tremr.Generic.Image
              key={
                "authorAvatarImage" +
                this.state.feed.get("author_avatar_image").public_id
              }
              height={avatarSize}
              width={avatarSize}
              image={this.state.feed.get("author_avatar_image")}
            />
          </div>
        );
      }

      title = (
        <h1 key="feedName">
          <Tremr.Generic.Abbreviations
            text={this.state.feed.get("author_name")}
          />
        </h1>
      );

      let context = {
        target: "feed",
        scope: "all",
        feed: this.state.feed.get("id"),
        sort: "latest",
        subfeed: "featured",
      };
      profileUrl = Tremr.routes.urlFor({
        primary: context,
      });
    }

    let notesmenu = null;
    let notes = null;
    if (this.state.user) {
      notes = <Notifications ref="notifications" />;
    }

    return (
      <div className={classes}>
        <div className="user-sidebar-activity-toolbar toolbar">
          <div className="row">
            <div className="title">Activity</div>
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
          </div>
        </div>

        <div
          ref="scrollContainer"
          className="controls"
          onClick={this.props.onClose}
        >
          <Tremr.Feed.ViewChart />
          {notes}
        </div>
      </div>
    );
  },
});
