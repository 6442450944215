var classNames = require('../../utils/classnames');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// rich text editor block
// Tremr.Editor.EmbedControl
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      identifier: PropTypes.string.isRequired,
      captionText: PropTypes.array.isRequired, // array of chars
      notifyPosition: PropTypes.func.isRequired, // notify parent of position
      removeBlock: PropTypes.func.isRequired, // notify parent of position
      embed: PropTypes.object.isRequired,
      image: PropTypes.object.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      readOnly: PropTypes.bool
  },

  lastBounds: false, // keep track of bounds so we only notify if actually changed

  getDefaultProps: function() {

    return {
      captionText: [],
      height: 200,
      width: 200,
      readOnly: false
    }
  },

  getInitialState: function() {
    return {
      captionText: this.props.captionText
    };
  },

  // set focus to this element (selects the input)
  focus: function() {
    if (this.refs['caption'] && this.refs['caption'].focus) {
      this.refs['caption'].focus();
    }
  },

  componentDidMount: function() {
    this.notifyPosition();
  },

  componentDidUpdate: function() {
    this.notifyPosition();
  },

  // notify parent of it's position
  notifyPosition: function() {

    if (!this.props.readOnly) {

      var node = ReactDOM.findDOMNode(this);
      var bounds = node.getBoundingClientRect();
      if (bounds.top != this.lastBounds.top || bounds.height != this.lastBounds.height) {
        if (!this.props.notifyPosition(this.props.identifier, bounds, (bounds.height != this.lastBounds.height), this)) {
          this.lastBounds = bounds;
        }
      }
    } else if (!this.props.readOnly) {
      _.delay(_.throttle(this.notifyPosition, 1000), 200);
    }
  },

  // tell the editor to remove this block
  handleRemove: function(event) {

    event.preventDefault();
    event.stopPropagation();
    this.props.removeBlock(this.props.identifier);
  },

  handleImageLoad: function() {
    _.delay(this.notifyPosition, 200);
  },

  render: function() {

    var classes = classNames({
        'block': true,
        'embed-block': true,
        'loading': !this.props.embed
    });

    var embed = <div className="embed loading" style={{height: this.props.height}}></div>;
    if (this.props.embed) {
      embed = <Tremr.Generic.Embed onLoad={this.handleImageLoad} embed={this.props.embed} height={this.props.height} width="auto" resizable={true} />;
    }

    var removeButton = null;
    if (!this.props.readOnly) {
      removeButton = <a onClick={this.handleRemove} className="remove">
        <Tremr.Generic.SvgIcon icon="remove" />
      </a>;
    }

    var caption = null;
    if (!this.props.readOnly || (this.props.captionText && this.props.captionText.length > 0)) {
      var getCaptionMarkup = function()  {
        return this.props.captionText.join('');
      }.bind(this);
      caption = React.createElement('p', {"data-placeholder": 'Enter a caption', className: 'caption', contentEditable: (!this.props.readOnly), dangerouslySetInnerHTML: { __html: getCaptionMarkup() } })
    }

    return (<div className={classes} contentEditable="false" data-id={this.props.identifier}>
      {embed}
      {caption}
      {removeButton}
    </div>);

  }

});
