var classNames = require('../../utils/classnames');
var CreateReactClass = require('create-react-class');

module.exports = CreateReactClass({

    render: function() {
        let value = this.props.liveDataPoints['field'].data;
        if (value && value !== 0 && value !== "") {
            return <span className="fb-field">{this.props.prefix}{value}{this.props.suffix}</span>;
        }

        return null;
    }
});
