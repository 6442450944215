require("./scss/header.scss");

var PropTypes = require('prop-types');
var TremrTracking = require('../../utils/tracking');
var TremrUtils = require('../../utils/tremr_utils');
var classNames = require('../../utils/classnames');
var UserAwareMixin = require('../../mixins/userawaremixin');
var DraftsTab = require('../../stores/user/draftstab');
var ManageSubfeedsButton = require('../user/manage_subfeeds_button');
var CreateReactClass = require('create-react-class');
var Config = require('config');
var Titlebar = require("../layout/titlebar");
var Share = require('../generic/share');
var NewPostButton = require('../../stores/post/newposttextbutton');
var HorizontalScroller = require('../generic/horizontalscroller');
var ChannelAvatar = require('./channelavatar');
var Config = require('config');
// let WithFBData = require("../generic/withFBData");
// var FBField = require("../generic/FBField");
// var FBFieldWithFBData = WithFBData(FBField);

// menu at the top of the primary position
// Tremr.Feed.Header
module.exports = CreateReactClass({

  mixins: [PureRenderMixin, UserAwareMixin],

  promises: [], // promise references so we can cancel on unmount

  propTypes: {
      model: PropTypes.object.isRequired,
      context: PropTypes.object.isRequired
  },

  getInitialState: function() {
    return {
        retryTimeout: 1000,
        haveAutoSwitched: (this.props.context.scope != 'featured'),
        subfeedUpdates: 0,
        post: new Tremr.Models.Post() // keep a copy of current post
    }
  },

  loadPostStub: function() {

      // load the post stub
      var promise = Tremr.stores.postStore.loadPost(this.props.model.get('id'), 'channel-stub', this.state.post, false, true);
      this.promises.push(promise);
      promise.then(function(params) {

        var model = params.model;
        var data = params.data;
        var options = params.options;

        this.setState({
          post: this.state.post
        });

    }.bind(this)).catch(function(params) {
          // retry
          // console.log("channel header RETRY post stub.");

          _.delay(function() {
              this.loadPostStub();
          }.bind(this), this.state.retryTimeout);
          this.setState({
              retryTimeout: this.state.retryTimeout * 2
          });
      }.bind(this));
  },

  // load data from server when first added to DOM
  componentDidMount: function() {

    if (!this.stopListening) {
      _.extend(this, Backbone.Events);
    }

    this.loadPostStub();

    // listen for updates to subfeeds - set in feed data AND update menu
    this.listenTo(Tremr.dispatcher, "tremr:"+this.props.context.feed+":subfeeds:save:success", function(subfeeds) {
      let m = this.props.model;
      m.set('subfeeds', subfeeds);
      this.setState({
        model: m,
        subfeedUpdates: this.state.subfeedUpdates + 1
      });
    }.bind(this));

    // listen for sign-in/out and update user in our session
    this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function(user) {

      this.setState({
        userUnchecked: false,
        user: user
      });
    }.bind(this));

    // listen for save profile
    this.listenTo(Tremr.dispatcher, "tremr:user:profile:success", function(draft_id) {
      this.setState({
        isLoading: false
      });
    }.bind(this));

    this.listenTo(Tremr.dispatcher, "tremr:close:overlay", function(draft_id) {
      this.setState({
        isLoading: false
      });
    }.bind(this));


  },

    // unregister listeners
  componentWillUnmount: function() {

      if (this.stopListening) {
        this.stopListening();
      }
  },


  // navigate to the new url allowing the routes to sort out context
  handleClick: function(event) {
    let tab = $(event.currentTarget).attr('data-value');
    let subfeed = null;
    let sort = null;
    if (tab == 'posts') {
      subfeed = 'featured';
    }
    if (tab == 'contributors') {
      sort = 'oldest';
    }
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(this.getContext(tab, subfeed, sort), scrollpos, undefined, false);
  },

  handleSubmenuClick: function(event, data) {
    let context = this.getContext(data.scope, data.subfeed, data.sort);
    let scrollpos = window.scrollY;
    Tremr.navigatePrimary(context, scrollpos, undefined, false);
  },

  getContext: function(tab, subfeed, sort) {

    let context = {
        target: 'feed',
        scope: tab,
        feed: this.props.context.feed,
        sort: 'latest'
    };
    if (subfeed) {
      context.subfeed = subfeed;
    }
    if (sort) {
      context.sort = sort;
    }
    if (this.props.context.ui == 'home') {
        context.ui = 'home';
    }
    return context;
  },

  getUrl: function(tab, subfeed, sort) {
      if (subfeed == undefined) {
          subfeed = null;
      }
      if (sort == undefined) {
          sort = 'latest';
      }
      return Tremr.routes.urlFor({
        primary: this.getContext(tab, subfeed, sort)
    });
  },

  signOut: function() {
    Tremr.dispatcher.message(this, "tremr:user:signout", {});
  },

  manageContributors: function() {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:feed:edit", {
        feedname: this.props.model.get('name'),
        tab: 'team'
    });
  },

  editChannel: function() {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:feed:edit", {
        feedname: this.props.model.get('name')
    });
  },

  editAdminChannel: function() {
    if (this.props.model) {

      this.setState({
        isLoading: true
      });

      Tremr.dispatcher.message(this, "tremr:feed:edit", {
          feedname: this.props.model.get('name')
      });
    }
  },

  navigateNonHome: function() {
      let nonhomepagecontext = this.getContext('posts', 'featured', 'latest');
      nonhomepagecontext.ui = null;
      let scrollpos = window.scrollY;
      Tremr.navigatePrimary(nonhomepagecontext, scrollpos, undefined, false);
  },

   render: function() {

    var classes = {
        'channelheader': true,
        'header': true,
        'feed': true,
      'loading': this.state.isLoading,
      'homeui': this.props.context.ui == 'home',
      'has-image': this.props.model.get('header_image') && this.props.model.get('header_image').public_id
    };
    classes[this.props.context.feed] = true;
    classes = classNames(classes);

    var title = this.props.context.feed.capitalize();
    if (this.props.model) {
      title = <h1 key="feedName">
        <Tremr.Generic.Abbreviations text={this.props.model.get('author_name')} />
      </h1>;
    }

    let avatar = null;
    let bio = null;
    let location = null;
    let twitter = null;
    let instagram = null;
    let blog = null;
    let newsletter = null;
    let draftTab = null;
    //let button = <Tremr.Follow.Button entity="feed" id={this.props.context.feed} />;
    let button = null;
    let share = null;
    let manageSubfeeds = null;
    let header_image = null;
    let contributors = null;

    let defaultStats = {
      posts: 0,
      followers: 0,
      following: 0,
      subfeeds: {},
      channels: 0
    };
    let defaultSubfeedStats = {
      featured: 0
    };
    let stats = _.extend(defaultStats, this.props.model.get('stats'));
    stats['subfeeds'] = _.extend(defaultSubfeedStats, stats['subfeeds']);

    // if (this.state.isLoading === false) {

      var avatarSize = 140;
      if (Tremr.Utils.isDeviceOrNarrow()) {
        avatarSize = 100;
      }

      if (this.props.model.get('author_avatar_image') && this.props.model.get('author_avatar_image').public_id) {
        avatar = <div key={"authorAvatar"+this.props.model.get('author_avatar_image').public_id} className="avatar">
          <Tremr.Generic.Image key={"authorAvatarImage"+this.props.model.get('author_avatar_image').public_id} height={avatarSize} width={avatarSize} image={this.props.model.get('author_avatar_image')} />
        </div>;
    } else {
        let color = Config.colors.highlight;
        if (this.props.model.get('highlight_color')) {
          color = this.props.model.get('highlight_color');
        }
        avatar = <div key="authorAvatar" className="avatar dummy">
            <ChannelAvatar title={this.props.model.get('author_name')} color={Config.colors.grey} textColor={color} />
        </div>;
    }

      let headerHeight = 320;
      let highlightColor = Config.colors.highlight;
      if (this.props.model.get('highlight_color')) {
        highlightColor = this.props.model.get('highlight_color');
      }

      if (this.props.model.get('header_image') && this.props.model.get('header_image').public_id) {
        header_image = <div key={"userHeader"+this.props.model.get('header_image').public_id} className="header-image">
          <Tremr.Generic.Image initialBackgroundColor={highlightColor} gravity="g_auto" urlHeight={320} urlWidth={1280} key={"userHeaderImage"+this.props.model.get('header_image').public_id} height={headerHeight} width={"auto"} image={this.props.model.get('header_image')} resizable={true} />
        </div>;
    } else {
        header_image = <div key="userHeaderBlank" className="header-image"></div>;
    }

      var user = this.state.user;

      // if user is the editor
      if (user && user.isEditorOfFeed(this.props.context.feed, this.props.model)) {

        // let the author edit this feeds sub-feeds
        manageSubfeeds = <ManageSubfeedsButton text="+ Add Feed" key={"managesubfeeds-"+this.state.subfeedUpdates} feed={this.props.context.feed} subfeeds={this.props.model.get('subfeeds') || []} />;
      }

      // always show follow - even for editor
      button = <Tremr.Follow.Button title={this.props.model.get('author_name')} subfeeds={this.props.model.get('subfeeds') || []} entity="feed" id={this.props.model.get('name')} />;

      let shareImage = "";
      // if (this.props.model.get('author_avatar_image') && this.props.model.get('author_avatar_image').public_id) {
      //   avatar = <div key={"authorAvatar"+this.props.model.get('author_avatar_image').public_id} className="avatar">
      //     <Tremr.Generic.Image key={"authorAvatarImage"+this.props.model.get('author_avatar_image').public_id} height={avatarSize} width={avatarSize} image={this.props.model.get('author_avatar_image')} />
      //   </div>;
      // }
      let shareUrl = this.getUrl('posts', 'featured');
      share = <Share moreIcon={<Tremr.Generic.SvgIcon icon="share-reverse" />} icons={['more']} imageUrl={shareImage} url={shareUrl} title={this.props.model.get('author_name')
              ? this.props.model.get('author_name').capitalize().replace(/&[^;]{2,3,4};/g, ' ')
              : ''} description={this.props.model.get('bio')
              ? this.props.model.get('bio').replace(/&[^;]{2,3,4};/g, ' ')
              : ''}/>;

      var authortags = null;
      if (this.props.model && this.props.model.get('author_tags') && _.contains(this.props.model.get('author_tags'), Tremr.Theme.RecommendedWriter.Tag)) {
        authortags = <div className="author-tags">
        {Tremr.Theme.RecommendedWriter.Description}
        </div>;
      }

      if (this.state.user && user.get('feedname') == this.props.context.feed && this.state.user.get('bio') && this.state.user.get('bio').length > 0) {
        bio = <div className="bio">
        {authortags}
          <Tremr.Generic.Abbreviations text={this.state.user.get('bio')} />
        </div>;
      } else {
        bio = <div className="bio">
        {authortags}
          <Tremr.Generic.Abbreviations text={this.props.model.get('author_bio')} />
        </div>;
      }
      if (this.state.user && user.get('feedname') == this.props.context.feed && this.state.user.get('location') && this.state.user.get('location').length > 0) {
        location = <div className="location">
          <Tremr.Generic.Abbreviations text={this.state.user.get('location')} />
        </div>;
      } else if (this.props.model.get('author_location') && this.props.model.get('author_location').length > 0) {
        location = <div className="location">
          <Tremr.Generic.Abbreviations text={this.props.model.get('author_location')} />
        </div>;
      }
      if (this.state.user && user.get('feedname') == this.props.context.feed && this.state.user.get('twitter') && this.state.user.get('twitter').length > 0) {
        twitter = <a target="_blank" href={'https://twitter.com/'+this.state.user.get('twitter').replace('@', '').replace(/(https?:\/\/)?(twitter\.com\/?)?/, "")} className="twitter">Twitter</a>;

        // let twitterDataPoints = [{
        //   name: 'field',
        //   key: `/community/reputation/users/${this.state.user.get("author_id")}/twitter_follower_count`,
        // }];
        // twitter = <span>{twitter}<FBFieldWithFBData prefix=" (" suffix=")" dataPoints={twitterDataPoints} /></span>;

      } else if (this.props.model.get('author_twitter') && this.props.model.get('author_twitter').length > 0) {
        twitter = <a target="_blank" href={'https://twitter.com/'+this.props.model.get('author_twitter').replace('@', '').replace(/(https?:\/\/)?(twitter\.com\/?)?/, "")} className="twitter">Twitter</a>;

        // CANT SHOW THIS IN CASE THE AUTHOR TWITTER ISNT ACTUALLY THE
        // THE AUTHORS TWITTER - WE CAN'T TELL THAT HERE
        // let twitterDataPoints = [{
        //   name: 'field',
        //   key: `/community/reputation/users/${this.props.model.get("author_id")}/twitter_follower_count`,
        // }];
        // twitter = <span>{twitter}<FBFieldWithFBData prefix=" (" suffix=")" dataPoints={twitterDataPoints} /></span>;
      }            

      if (this.state.user && user.get('feedname') == this.props.context.feed && this.state.user.get('instagram') && this.state.user.get('instagram').length > 0) {
        instagram = <a target="_blank" href={'https://instagram.com/'+this.state.user.get('instagram').replace('@', '').replace(/(https?:\/\/)?(instagram\.com\/?)?/, "")} className="instagram">Instagram</a>;
      } else if (this.props.model.get('instagram') && this.props.model.get('instagram').length > 0) {
        instagram = <a target="_blank" href={'https://instagram.com/'+this.props.model.get('instagram').replace('@', '').replace(/(https?:\/\/)?(instagram\.com\/?)?/, "")} className="instagram">Instagram</a>;
      }

      if (this.state.user && this.state.user.get('feedname') == this.props.context.feed && this.state.user.get('blog') && this.state.user.get('blog').length > 0) {
        blog = <a target="_blank" href={this.state.user.get('blog')} className="blog">Website</a>;
      } else if (this.props.model.get('author_blog') && this.props.model.get('author_blog').length > 0) {
        blog = <a target="_blank" href={this.props.model.get('author_blog')} className="blog">Website</a>;
      }

      if (this.props.model.get('has_newsletter') && this.props.model.get('has_newsletter') != "no" &&
          this.props.model.get('newsletter') && this.props.model.get('newsletter').length > 0 ) {
        newsletter = <a target="_blank" href={this.props.model.get('newsletter')} className="newsletter">Newsletter</a>;
      }

      var followersLink = null;
      if (this.props.model.get('id') != 'tremr' && stats) {
        followersLink = <Tremr.Generic.StatTab href={this.getUrl('followers')} handleClick={this.handleClick} value="followers" data={stats['followers']} label="Followers" active={(this.props.context.scope == 'followers')} />;
      }
//
    // }

    let editButton = null;
    let editTeamButton = null;
    let newPostButton = null;
    if (this.state.user && this.state.user.isEditorOfFeed(this.props.model.get('name'), this.props.model)) {
        editButton = <Tremr.Generic.SvgIcon title="Edit Channel" icon="config" classes="button icon" onClick={this.editChannel} />;
        editTeamButton = <Tremr.Generic.Tab key="manage_contributors" text="Manage Members" handleClick={this.manageContributors} />;
    }
    if (this.state.user && this.state.user.isContributorOfFeed(this.props.model.get('name'), this.props.model)) {
      // newPostMenu = <NewPostMenu key="newpostcontributor" channel={this.props.model} contributor={true} />;
      newPostButton = <NewPostButton key="newpostcontributor" channel={this.props.model} contributor={true} />;
    } else if (this.state.user) {
        // newPostMenu = <NewPostMenu key="newpostcommunity" channel={this.props.model} />;
        newPostButton = <NewPostButton key="newpostcommunity" channel={this.props.model} />;
    }

    var adminedit = null;
    if (this.state.user && this.state.user.get('admin') == 'yes') {
      adminedit = <Tremr.Generic.SvgIcon icon="admin-config" classes="button icon" onClick={this.editAdminChannel} />;
      editButton = null;
    }

    let submenu = null;
    if (this.props.context.scope == 'posts' || this.props.context.scope == 'all') {
      let menuitems = _.map(this.props.model.get('subfeeds'), (subfeed) => {
        if (subfeed && subfeed.featured == 'yes') {
          return null;
        } else if (stats) {
          return <Tremr.Generic.StatTab key={subfeed.name} href={this.getUrl('posts', subfeed.name)} handleClick={this.handleSubmenuClick} value={{scope: 'posts', subfeed: subfeed.name}} data={stats['subfeeds'][subfeed.name]} label={subfeed.name} active={(this.props.context.scope == 'posts' && this.props.context.subfeed == subfeed.name)} />
        }
      });

      if (stats) {
        submenu = <nav className="menu">
              <Tremr.Generic.StatTab key="featured" href={this.getUrl('posts', 'featured')} handleClick={this.handleSubmenuClick} value={{scope: 'posts', subfeed: 'featured'}} data={stats['subfeeds']['featured']} label="Featured" active={(this.props.context.scope == 'posts' && this.props.context.subfeed == 'featured')} />
              <Tremr.Generic.StatTab key="all-posts" href={this.getUrl('posts', 'all')} handleClick={this.handleSubmenuClick} value={{scope: 'posts', subfeed: 'all'}} data={stats['posts']} label="All Posts" active={(this.props.context.scope == 'posts' && (!this.props.context.subfeed || this.props.context.subfeed == 'all'))} />
              <Tremr.Generic.StatTab key="trending" href={this.getUrl('posts', 'trending')} handleClick={this.handleSubmenuClick} value={{scope: 'posts', subfeed: 'trending'}} data={stats['trending']} label="Trending" active={(this.props.context.scope == 'posts' && this.props.context.subfeed == 'trending')} />
              <Tremr.Generic.StatTab key="recommended" href={this.getUrl('posts', 'recommended')} handleClick={this.handleSubmenuClick} value={{scope: 'posts', subfeed: 'recommended'}} data={stats['recommended']} label="Top Rated" active={(this.props.context.scope == 'posts' && this.props.context.subfeed == 'recommended')} />
              {menuitems}
              {manageSubfeeds}
            </nav>;
      }
  } else if (this.props.context.scope == 'contributors') {

         submenu = <nav className="menu">
            <Tremr.Generic.Tab key="oldest" href={this.getUrl('contributors', null, 'oldest')} handleClick={this.handleSubmenuClick} data={{scope: 'contributors', sort: 'oldest'}} text="Joined" active={(this.props.context.scope == 'contributors' && this.props.context.sort == 'oldest')} />
            <Tremr.Generic.Tab key="alphabetical" href={this.getUrl('contributors', null, 'alphabetical')} handleClick={this.handleSubmenuClick} data={{scope: 'contributors', sort: 'alphabetical'}} text="A-Z" active={(this.props.context.scope == 'contributors' && this.props.context.sort == 'alphabetical')} />
            {editTeamButton}
          </nav>;
  } else if (this.props.context.scope == 'followers') {

         submenu = <nav className="menu">
            <Tremr.Generic.Tab key="latest" href={this.getUrl('followers', 'latest')} handleClick={this.handleSubmenuClick} data={{scope: 'followers', sort: 'latest'}} text="Latest" active={(this.props.context.scope == 'followers' && this.props.context.sort == 'latest')} />
            <Tremr.Generic.Tab key="alphabetical" href={this.getUrl('followers', 'alphabetical')} handleClick={this.handleSubmenuClick} data={{scope: 'followers', sort: 'alphabetical'}} text="A-Z" active={(this.props.context.scope == 'followers' && this.props.context.sort == 'alphabetical')} />
          </nav>;
     }

    contributors = <div className="contributors">
         {_.map(this.props.model.get('contributors'), (contributor) => {
             return <div className="contributor">
                 <Tremr.Generic.Image key={"contributorImage"+contributor['avatar_image'].public_id} height={36} width={36} image={contributor['avatar_image']} />
             </div>;
         })}
     </div>;     

    let channelsTab = null;
    if (stats['channels'] && stats['channels'] > 0) {
        channelsTab = <Tremr.Generic.StatTab href={this.getUrl('channels')} handleClick={this.handleClick} value="channels" data={stats['channels']} label="Blogcasts" active={(this.props.context.scope == 'channels')} />
    }

    let tabs = null;
    if (stats) {
      tabs = <div className="tabs">
        <Tremr.Generic.StatTab href={this.getUrl('posts', 'featured')} handleClick={this.handleClick} value="posts" data={stats['posts']} label="Posts" active={(this.props.context.scope == 'posts')} />
        <Tremr.Generic.StatTab href={this.getUrl('contributors')} handleClick={this.handleClick} value="contributors" data={this.props.model.get('contributors').length} label="Members" active={(this.props.context.scope == 'contributors')} />
        {followersLink}
        {draftTab}
      </div>;

    }

    // very simple version for showing in the homepage UI style
    if (this.props.context.ui == 'home') {
      let subfeed = this.props.context.subfeed ? "("+this.props.context.subfeed.capitalize()+")" : null;
      let nonhomepagecontext = this.getContext('posts', 'featured', 'latest');
      nonhomepagecontext.ui = null;
      let nonhomepageurl = Tremr.routes.urlFor({
          primary: nonhomepagecontext
      });
      title = <a className="feedName" href={nonhomepageurl} onClick={this.navigateNonHome}>
        <Tremr.Generic.Abbreviations text={this.props.model.get('author_name')} />
      </a>;
      return <div className={classes}>
        <HorizontalScroller>
            <div className="feed-posts-subfeeds-summary">
                {title}
                {submenu}
            </div>
        </HorizontalScroller>
      </div>;
    }

    if (submenu) {
        submenu = <HorizontalScroller>{submenu}</HorizontalScroller>;
    }


    return (

      <div className={classes}>
        {header_image}
        <Titlebar style="light">
            <div className="subheader">Blogcast</div>
            {adminedit}
            {editButton}
        </Titlebar>
        <div className="content">
            {avatar}
            {contributors}
            <div className="info">
                {title}
                {bio}
                <div className="links">
                    {location}
                    {twitter}
                    {instagram}
                    {blog}
                    {newsletter}
                </div>
            </div>
            <div className="actions-tabs-container">
                <div className="actions">
                    {share}
                    {button}
                    {newPostButton}
                </div>
                {tabs}
            </div>
            {submenu}
        </div>
      </div>
    );
  }
});
