var CreateReactClass = require('create-react-class');

// menu at the top of the drafts wall
// Tremr.Post.DraftsHeader = CreateReactClass({
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  render: function() {

    return <h1>DRAFTS HEADER</h1>;
  }

});
