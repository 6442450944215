module.exports = function () {

    // receive and send out event
    this.message = function(originating_store, action, data) {
    	this.trigger(action, data);
    };

    // use backbone events
    var newInstance = _.extend(this, Backbone.Events);
    return newInstance;
};
