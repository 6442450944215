var moment = require('moment');
var classNames = require('../../utils/classnames');
var TremrTracking = require('../../utils/tracking');
var Cookies = require('js-cookie/src/js.cookie');
var UserAwareMixin = require('../../mixins/userawaremixin');
var CardBreadcrumbs = require('./cardbreadcrumbs');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// Tremr.Post.Preview = CreateReactClass({
module.exports = CreateReactClass({

	// mixins: [PureRenderMixin],

	propTypes: {
        post: PropTypes.object.isRequired
    },

	// produce the page
	render: function() {

		// work out our top level class
		var classes = {
			'post': true,
			'preview': true,
			'image': (this.props.post && this.props.post.get('image')),
			'embed': (this.props.post && this.props.post.get('embed'))
		};
		classes[this.props.post.get('post_type')] = true;
		classes = classNames(classes);

		var tags = <Tremr.Tag.List tags={this.props.post.get('tags')} />;


		var imageHeader = this.props.post.get('image');
		var image = null;
		var imageCaption = null;
		if (imageHeader) {
            image = <Tremr.Generic.Image image={imageHeader} maxHeight={1076} height={false} width="auto" />;
            if (imageHeader.caption) {
        		imageCaption = <p className='caption'>{imageHeader.caption}</p>;
            }

        } else if (this.props.post.get('embed')) {
            image = <Tremr.Generic.Embed embed={this.props.post.get('embed')} maxHeight={1076} height={false} width="auto" />;
        }

        // var age = $.timeago(this.props.post.get('created_at'));
        var created = moment(this.props.post.get('created_at'), "YYYY-MM-DDTHH:mmZ");
        var age = created.fromNow(true);

        var bylinesubheader = age;
        if (this.props.post.get('post_type') && this.props.post.get('post_type') != 'general') {
            bylinesubheader = this.props.post.get('post_type').capitalize() + ' / ' + bylinesubheader;
        }

        var byline = null;
        // var byline = <Tremr.User.Byline ref="byline" subfeeds={[]} avatar_size={120} showFollow={false} post={this.props.post}>
        //     <div className="bio">{this.props.post.get('author_bio')}</div>
        //     <div className="location">{this.props.post.get('author_location')}</div>
        // </Tremr.User.Byline>;

		var breadcrumbs = null;
		if (this.props.post.get('ancestors') && this.props.post.get('ancestors').length > 0) {
			breadcrumbs = <CardBreadcrumbs posts={this.props.post.get('ancestors')} />;
		}

		var getHTML = function() {
            return {
                __html: this.props.post.get('content') || ''
            };
        }.bind(this);

        var postContent = null;
        if (this.props.post.get('content_blocks')) {
        	// new posts use content_blocks
        	postContent = <Tremr.Editor.Richtext margins={30} initialBlocks={this.props.post.get('content_blocks')} key="richtext" ref="richtext" />;
        } else {
        	postContent = <div className="post-content" dangerouslySetInnerHTML={getHTML()} />;
        }

        // render html
		return (
		<div className={classes}>
			<article>

				{breadcrumbs}

				<header>

					{byline}

	                <h1>{this.props.post.get('title') ? this.props.post.get('title').capitalize() : ''}</h1>

	                <p className="tagline">{this.props.post.get('summary')}</p>

	                <div className="header-image">
						{image}
						{imageCaption}
					</div>

				</header>
				<section>
					{postContent}

					{tags}

				</section>
				<aside>
				</aside>
			</article>
		</div>
		);
	}


});
