var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// rich text editor block
// Tremr.Editor.PlaceholderControl
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      identifier: PropTypes.string.isRequired,
      notifyPosition: PropTypes.func.isRequired
  },

  lastBounds: false, // keep track of bounds so we only notify if actually changed

  componentDidMount: function() {
    this.notifyPosition();
  },

  componentDidUpdate: function() {
    this.notifyPosition();
  },

  // notify parent of it's position
  notifyPosition: function() {

    var node = ReactDOM.findDOMNode(this);
    var bounds = node.getBoundingClientRect();
    if (bounds.top != this.lastBounds.top || bounds.height != this.lastBounds.height) {
      if (this.props.notifyPosition(this.props.identifier, bounds, (bounds.height != this.lastBounds.height), this)) {
        this.lastBounds = bounds;
      }
    }
  },

  render: function() {

    return (
      <p onClick={this.props.onClick} className="block text-block placeholder">Say some more…</p>
    );
  }
});
