
// Set of util funtions for rendering layouts of grid components

let GridLayouts = {};

GridLayouts.Standard = {

	// util to get the next column to use, in this case always just the next column
	// instead of the default, which is to pick the shortest column
	nextColumn: function(sizes, column_heights, rowIndex, lastColumn) {
		return lastColumn;
	},
	// util to force all cards to span 2 column
	columnSpan: function(index, sizes, column_heights, rowIndex, colIndex, lastSpan) {

		// depends on sizes
		if (sizes.columns == 2) {

			// 2-1 pattern repeats every 2
			let rowSequence = rowIndex % 2;
			if (rowSequence == 1) {
				if (colIndex == 0) {
					return 2;
				} else {
					return 1; // won't fit!
				}
			} else {
				return 1;
			}

		} else if (sizes.columns == 3) {

			// 2:1-1:2-3 pattern repeats every 3
			let rowSequence = rowIndex % 3;
			if (rowSequence == 1) {
				if (colIndex == 0) {
					return 2;
				} else {
					return 1;
				}
			} else if (rowSequence == 2) {
				if (colIndex == 1) {
					return 2;
				} else {
					return 1;
				}
			} else {
				// if (colIndex == 0) {
				// 	return 3;
				// } else {
					return 1; // won't fit!
				// }
			}

		} else if (sizes.columns == 4) {

			// OLD -> 2:1:1-1:1:2-4 pattern repeats every 3
			// 2:1:1-1:1:2-1:1:1:1-1:1:1:1-4 pattern repeats every 5
			let rowSequence = rowIndex % 5;
			if (rowSequence == 1) {
				if (colIndex == 0) {
					return 2;
				} else {
					return 1;
				}
			} else if (rowSequence == 2) {
				if (colIndex == 2) {
					return 2;
				} else {
					return 1;
				}
			} else if (rowSequence == 3) {
				return 1;
			} else if (rowSequence == 4) {
				return 1;
			} else {
				if (colIndex == 0) {
					return 4;
				} else {
					return 1; // won't fit!
				}
			}

		} else {
			return 1;
		}
	},
	// do we want to clear the row heights on every new row?
	clearNewRow: function(sizes, column_heights, rowIndex, lastColumn) {
		return true;
	}
};


module.exports = GridLayouts;
