require("./scss/editcontributors.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var classNames = require("../../utils/classnames");
var UsersLookup = require("../../models/UsersLookup");
var WithFBData = require("../generic/withFBData");
var Stars = require("../reputation/stars");
var StarsWithFBData = WithFBData(Stars);
var Config = require("config");
var alertify = require("alertify");

module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    contributors: PropTypes.array,
    pendingContributors: PropTypes.array,
    onChange: PropTypes.func,
    starColor: PropTypes.string,
  },

  getDefaultProps: function () {
    return {
      contributors: [],
      pendingContributors: [],
      starColor: "#5F5C6A",
    };
  },

  getInitialState: function () {
    return {
      searchText: "",
      suggestions: new UsersLookup(),
      suggestionCount: 0,
    };
  },

  changeInvite: function (value) {
    // do the search on the server

    // we need a new collection each time to stop the caching filling up with every match
    let suggestions = new UsersLookup();

    // update state
    this.setState({
      searchText: value,
      suggestions: suggestions,
      suggestionCount: 0,
    });

    if (value.length < 3) {
      return;
    }

    // query server
    Tremr.Utils.loadCollection(
      1,
      suggestions,
      { s: value.toLowerCase() },
      true
    ).then(
      function (params) {
        let collection = params.collection;
        this.setState({
          suggestions: params.collection,
          suggestionCount: params.collection.length,
        });
      }.bind(this)
    );
  },

  addInvite: function (user) {
    let contributor = _.extend({ role: "contributor" }, user.attributes);

    this.props.onChange("add-pending", contributor);

    // clear the box and update pending
    let newState = {
      searchText: "",
      suggestions: new UsersLookup(),
      suggestionCount: 0,
    };

    this.setState(newState);

    this.refs.inviteText.setState({
      value: "",
    });
  },

  clickInvite: function (event) {
    let id = event.currentTarget.getAttribute("data-id");
    this.state.suggestions.each(
      function (user) {
        if (user.get("id") == id) {
          this.addInvite(user);
        }
      }.bind(this)
    );
  },

  addSingleSuggestedInvite: function () {
    // if we have a single suggestion then add it
    if (this.state.suggestions && this.state.suggestions.length == 1) {
      this.addInvite(this.state.suggestions.at(0));
    }
  },

  removePendingContributor: function (event) {
    let id = event.currentTarget.getAttribute("data-id");
    this.props.onChange("remove-pending", id);
  },

  removeContributor: function (event) {
    let id = event.currentTarget.getAttribute("data-id");
    this.props.onChange("remove-contributor", id);
  },

  setChannelAdmin: function (event) {
    let id = event.currentTarget.getAttribute("data-id");
    this.props.onChange("set-channel-admin", id);
  },

  removeChannelAdmin: function (event) {
    let id = event.currentTarget.getAttribute("data-id");

    alertify.confirm(
      "Remove Admin Rights?",
      function (e) {
        if (e) {
          this.props.onChange("remove-channel-admin", id);
        }
      }.bind(this)
    );
  },

  getRoleName: function (contributor) {
    if (contributor.role == "editor") {
      return "owner";
    } else if (contributor.permission == "admin") {
      return "admin";
    } else if (contributor.role == "contributor") {
      return "member";
    } else {
      return contributor.role;
    }
  },

  render: function () {
    let classes = classNames({
      "feed-edit-contributors": true,
    });

    const avatarSize = 52;

    let suggestions = null;
    if (this.state.suggestions && this.state.suggestions.length > 0) {
      suggestions = (
        <div className="suggestions">
          {this.state.suggestions.map((item, index) => {
            let reputationStarsDataPoints = [
              {
                name: "rep_stars",
                key: "/community/reputation/users/" + item.id + "/rep_stars",
              },
            ];

            let bio = null;
            if (item.get("bio")) {
              bio = <div className="bio">{item.get("bio")}</div>;
            }
            return (
              <div
                className="suggestion contributor"
                onClick={this.clickInvite}
                key={"suggestion-" + index}
                data-id={item.get("id")}
              >
                <div className="avatar">
                  <Tremr.Generic.Image
                    key={item.get("avatar_image").public_id}
                    image={item.get("avatar_image")}
                    height={avatarSize}
                    width={avatarSize}
                  />
                </div>
                <div className="info">
                  <div className="name">{item.get("name")}</div>
                  {bio}
                </div>
                <div className="reputation">
                  <StarsWithFBData
                    alignment={"right"}
                    highlightColor={this.props.starColor}
                    height={18}
                    width={54}
                    scale={window.devicePixelRatio}
                    dataPoints={reputationStarsDataPoints}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="contributors">
          {this.props.contributors.map((item, index) => {
            let isChannelAdmin =
              item.role == "editor" || item.permission == "admin";
            let makeAdmin = (
              <div
                className="channel-admin"
                onClick={this.setChannelAdmin}
                data-id={item.id}
              >
                Make Admin
              </div>
            );
            if (isChannelAdmin) {
              makeAdmin = (
                <div
                  className="channel-admin set"
                  onClick={this.removeChannelAdmin}
                  data-id={item.id}
                >
                  Admin
                </div>
              );
            }

            let reputationStarsDataPoints = [
              {
                name: "rep_stars",
                key: "/community/reputation/users/" + item.id + "/rep_stars",
              },
            ];

            let avatar = null;
            if (item.avatar_image && item.avatar_image.public_id) {
              avatar = (
                <Tremr.Generic.Image
                  key={item.avatar_image.public_id}
                  image={item.avatar_image}
                  height={avatarSize}
                  width={avatarSize}
                />
              );
            }

            return (
              <div className="contributor" key={"contributor-" + index}>
                <div className="avatar">{avatar}</div>
                <div className="info">
                  <div className="name">
                    {item.name}{" "}
                    <div className="role">({this.getRoleName(item)})</div>
                  </div>
                  <div className="bio">{item.bio}</div>
                  <div className="actions">
                    <div
                      className="delete"
                      onClick={this.removeContributor}
                      data-id={item.id}
                    >
                      Remove
                    </div>
                    {makeAdmin}
                  </div>
                </div>
                <div className="reputation">
                  <StarsWithFBData
                    alignment={"right"}
                    highlightColor={this.props.starColor}
                    height={18}
                    width={54}
                    scale={window.devicePixelRatio}
                    dataPoints={reputationStarsDataPoints}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="invite">
          <Tremr.Generic.Input
            ref="inviteText"
            name="invite"
            onEnter={this.addSingleSuggestedInvite}
            onChange={this.changeInvite}
            label="Invite a member… (Enter username or email)"
            showPlaceholder="Invite a contributor… (Enter username or email)"
            type="text"
            initialValue={this.state.searchText}
            maxLength={50}
          />
          {suggestions}
        </div>

        <h2>Invited</h2>
        <p>Invitations will be sent on save.</p>
        <div className="contributors pending">
          {this.props.pendingContributors.map((item, index) => {
            let reputationStarsDataPoints = [
              {
                name: "rep_stars",
                key: "/community/reputation/users/" + item.id + "/rep_stars",
              },
            ];

            return (
              <div
                className="contributor pending"
                key={"pending-contributor-" + index}
              >
                <div className="avatar">
                  <Tremr.Generic.Image
                    key={item.avatar_image.public_id}
                    image={item.avatar_image}
                    height={avatarSize}
                    width={avatarSize}
                  />
                </div>
                <div className="info">
                  <div className="name">{item.name}</div>
                  <div className="bio">{item.bio}</div>
                  <div className="actions">
                    <div className={"status " + item.status}>
                      {item.status ? item.status.capitalize() : ""}
                    </div>
                    <div
                      className="delete"
                      onClick={this.removePendingContributor}
                      data-id={item.id}
                    >
                      Remove
                    </div>
                  </div>
                </div>
                <div className="reputation">
                  <StarsWithFBData
                    alignment={"right"}
                    highlightColor={this.props.starColor}
                    height={18}
                    width={54}
                    scale={window.devicePixelRatio}
                    dataPoints={reputationStarsDataPoints}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
});
