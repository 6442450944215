require("./scss/embedprompt.scss");

var classNames = require('../../utils/classnames');
var embedly = require('jquery-embedly');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// Tremr.Generic.EmbedPrompt
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        prompt: PropTypes.string,
        onCancel: PropTypes.func,
        onStart: PropTypes.func,
        onSuccess: PropTypes.func,
        onInvalid: PropTypes.func
    },

    getDefaultProps: function() {
        return {
            prompt: 'Paste link…'
        }
    },

    getInitialState: function() {
        return {
            started: false,
            url: ''
        };
    },

    // focus on the input field (doesn't always work!?)
    focus: function() {

      this.refs['url'].focus();
    },

    // start the embed
    embed: function(event) {
        event.preventDefault();
        event.stopPropagation();

        var url = this.refs['url'].state.value;
        if (url && url.trim) {
            url = url.trim();
        }

        // validate the url
        if (!url || url.length <= 6) {
            if (this.props.onInvalid) {
                this.props.onInvalid('Please enter a valid URL');
            }
            return;
        }

        // callback
        if (this.props.onStart) {
            this.props.onStart(url);
        }

        // use embedly to get the data (luxe: 1)
        $.embedly.oembed(Tremr.Utils.addHttp(url), {
            query: {
                maxwidth: 854,
                maxheight: 480
            }
        }).done(function(results){
            // Even though there was only one url, this will still be a list of results
            var data = results[0];
            if (data.error_message !== undefined) {
                if (this.props.onInvalid) {
                    this.props.onInvalid(data.error_message);
                }
            } else if (this.props.onSuccess) {

                this.props.onSuccess(data);
            }


        }.bind(this));
    },

    // cancel
    cancel: function(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    },

    // watch for enter press in field
    handleKeyDown: function(event) {

        if (event.key == 'Enter') {
            this.embed(event);
        }
    },

    // display based on the embed property
    render: function() {

        var classNames = require('../../utils/classnames');
        var classes = classNames({
            'embed-prompt': true,
            'started': this.state.started
        });

        return <div className={classes}>
            <Tremr.Editor.ContentEditable ref="url" maxLength={255} onKeyDown={this.handleKeyDown} placeholder={this.props.prompt} />
            <div className="start" onClick={this.embed}><Tremr.Generic.SvgIcon icon="tick" /></div>
            <div className="cancel" onClick={this.cancel}><Tremr.Generic.SvgIcon icon="remove" /></div>
        </div>;

    }
});
