var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// pop-up to show when highlighting text in a richtext block
// Tremr.Editor.Formatpopup
module.exports = CreateReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
      initialMode: PropTypes.string,
      getSelection: PropTypes.func.isRequired,
      format: PropTypes.func.isRequired,
      close: PropTypes.func.isRequired,
      containsFormat: PropTypes.func.isRequired,
      containsTag: PropTypes.func.isRequired,
      range: PropTypes.object.isRequired
  },

  getDefaultProps: function() {
    return {
      initialMode: 'format'
    }
  },

  getInitialState: function() {
    return {
      mode: this.props.initialMode
    };
  },

  handleClick: function(event) {
    event.preventDefault();
    event.stopPropagation();

    var action = event.currentTarget.getAttribute('data-action');

    // process depending on format
    if (action == 'close') {

      this.props.close();

    } else if (action == 'add-anchor') {

      var domNode = ReactDOM.findDOMNode(this.refs['url']);
      var url = Tremr.Utils.addHttp(domNode.value);
      this.props.format(this.props.getSelection(), 'A', { href: url });

    } else if (action == 'A' && event.currentTarget.getAttribute('class') == 'active') {

      this.props.format(this.props.getSelection(), 'A'); // toggle off

    } else if (action == 'A') {

      this.setState({
        mode: 'anchor'
      });

    } else {

      this.props.format(this.props.getSelection(), action);

      // check if we now have the format or not
      var newState = {};
      newState['has'+action.toLowerCase().capitalize()] = !this.state['has'+action.toLowerCase().capitalize()];
      this.setState(newState);
    }
  },

  // position above the selection
  componentDidMount: function() {
    this.setPosition();
  },

  // position above the selection
  componentDidUpdate: function(prevProps, prevState) {

    this.setPosition();

    _.delay(function() {
      var url = this.refs['url'];
      if (url) {
        var domNode = ReactDOM.findDOMNode(url);
        domNode.focus();
      }
    }.bind(this), 1);
  },

  // position above the selection
  setPosition: function() {

    var domNode = ReactDOM.findDOMNode(this);

    // highlight formatting we have in the selection
    $(domNode).find('[data-action="A"]').attr('class', (this.props.containsFormat('A') ? 'active': ''));
    $(domNode).find('[data-action="STRONG"]').attr('class', (this.props.containsFormat('STRONG') ? 'active': ''));
    $(domNode).find('[data-action="EM"]').attr('class', (this.props.containsFormat('EM') ? 'active': ''));
    $(domNode).find('[data-action="BLOCKQUOTE"]').attr('class', (this.props.containsTag('BLOCKQUOTE') ? 'active': ''));
    $(domNode).find('[data-action="H2"]').attr('class', (this.props.containsTag('H2') ? 'active': ''));

    // set the position
    // var selection = window.getSelection();
    var range = this.props.range;
    var boundary = range.getBoundingClientRect();
    var parentBounds = domNode.parentNode.getBoundingClientRect();

    // get selection position within richtext
    var top = boundary.top - parentBounds.top;
    var left = boundary.left - parentBounds.left;
    var right = boundary.right - parentBounds.left;

    // adjust to be above and central aligned to selection
    var thisWidth = $(domNode).outerWidth();
    top = top - (15 + $(domNode).outerHeight());
    left = left + ((right - left)/2) - (thisWidth/2);
    // var top = offset + boundary.top - (15 + domNode.outerHeight());
    // var left = ((boundary.left + boundary.right)/2) - (thisWidth/2);

    // make sure we don't go off the edge
    var parentWidth = $(domNode).closest('.richtext').innerWidth();
    if ((left + thisWidth + 30) > parentWidth) { left = (parentWidth - thisWidth - 30); }
    if (left < -30) { left = -30; } // allow to go 30px over the side
    // if (top < 0) { top = 0; }

    $(domNode).css({
        top: top,
        left: left,
        opacity: 1.0
    });

    // if we ever get a zero height re-call
    if (boundary.height == 0) {
      setTimeout(this.setPosition, 20);
    }

  },

  render: function() {

    var content = '';
    if (this.state.mode == 'anchor') {
      content = <div className="popup-content">
        <input ref="url" type="url" placeholder="http://example.com" />
        <a onClick={this.handleClick} data-action="add-anchor">
          <Tremr.Generic.SvgIcon icon="tick" />
        </a>
        <a onClick={this.handleClick} data-action="close">
          <Tremr.Generic.SvgIcon icon="remove" />
        </a>
      </div>;
    } else {
      content = <div className="popup-content">
        <a onClick={this.handleClick} data-action="STRONG" className={this.state.hasStrong ? 'active' : ''}>B</a>
        <a style={{fontStyle: 'italic'}} onClick={this.handleClick} data-action="EM" className={this.state.hasEm ? 'active' : ''}>I</a>
        <a onClick={this.handleClick} data-action="A" className={this.state.hasA ? 'active' : ''}>
          <Tremr.Generic.SvgIcon icon="link" />
        </a>
        <a onClick={this.handleClick} data-action="H2" className={this.state.hasH2 ? 'active' : ''}>H2</a>
        <a onClick={this.handleClick} data-action="BLOCKQUOTE" className={this.state.hasBlockquote ? 'active' : ''}>
          <Tremr.Generic.SvgIcon icon="quote" />
        </a>
      </div>;
    }

    return (

      <div className="format-popup">
        {content}
      </div>
    );
  }
});
