/*
 * Utils for keeping track of all tracking code
 */

var TremrTracking = {};

// log a view to the server
TremrTracking.logView = function (entity, id) {
  var v = new Tremr.Models.View({
    entity: entity,
    entity_id: id,
  });
  v.save();
};

// user has logged in or is already logged in
TremrTracking.identify = function (user) {
  let id = user.get("id") || user.get("_id");
  let feed = user.get("feedname");

  if (window.trackJs) {
    trackJs.configure({
      userId: id,
    });
  }

  if (window.heap) {
    heap.identify(feed + "-" + id);
  }

  // if (ga !== undefined) {
  // google analytics identify user goes here
  // }
};

// track a page change - ie context change in tremr terms
TremrTracking.trackPage = function (category, url) {
  url = url.replace("!", "_");

  // update google analytics (if it is in the page) - used by tremr site
  if (typeof ga !== "undefined") {
    ga("send", "pageview", url);
  }

  // google analytics using gtag - used by covid site
  if (typeof gtag !== "undefined") {
    gtag("event", "page_view");
  }

  // var current_user = Tremr.stores.userStore.getUser();
};

// user signed up
TremrTracking.signedUp = function (user) {
  if (typeof google_trackConversion !== "undefined") {
    google_trackConversion({
      google_conversion_id: 972776917,
      google_conversion_label: "jr5ZCMW8iWgQ1cvtzwM",
      google_conversion_language: "en",
      google_remarketing_only: false,
    });
  }

  if (typeof ga !== "undefined") {
    ga("send", "event", "user", "join");
  }

  // google analytics using gtag
  if (typeof gtag !== "undefined") {
    gtag("event", "sign_up");
  }

  if (typeof fbq !== "undefined") {
    fbq("track", "CompleteRegistration");
  }

  if (typeof twttr !== "undefined") {
    if (twttr.conversion && twttr.conversion.trackPid) {
      twttr.conversion.trackPid("nuh37", {
        tw_sale_amount: 0,
        tw_order_quantity: 0,
      });
    }
  }
};

// user has posted
TremrTracking.posted = function (user, post) {
  if (typeof ga !== "undefined") {
    ga("send", "event", "post", "posted");
  }

  // google analytics using gtag
  if (typeof gtag !== "undefined") {
    gtag("event", "post", {
      event_category: "posted",
      event_label: "posted",
    });
  }
};

// user has recommended a post
TremrTracking.react = function (user, post_id, tag, onoff) {
  if (onoff == "off" || onoff === false) {
    tag = "un" + tag;
  }
  if (typeof ga !== "undefined") {
    ga("send", "event", "button", tag, post_id);
  } else {
    console.log("TRACK: " + user + "," + post_id + "," + tag + "," + onoff);
  }

  // google analytics using gtag
  if (typeof gtag !== "undefined") {
    gtag("event", "button", {
      event_category: tag,
      event_label: post_id,
    });
  }
};

// send a GA event
TremrTracking.event = function (eventCategory, eventAction, eventLabel) {
  if (typeof ga !== "undefined") {
    ga("send", "event", eventCategory, eventAction, eventLabel);
  } else {
    console.log(
      "TRACK: " + eventCategory + "," + eventAction + "," + eventLabel
    );
  }

  // google analytics using gtag
  if (typeof gtag !== "undefined") {
    gtag("event", eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
};

module.exports = TremrTracking;
