require("./scss/postoverview.scss");

let React = require("react");
let CreateReactClass = require("create-react-class");
let UserAwareMixin = require("../../mixins/userawaremixin");
let SvgIcon = require("../generic/svgicon");
let classNames = require("../../utils/classnames");
let Stars = require("../controls/stars");
let TremrUtils = require("../../utils/tremr_utils");
let Config = require("config");

// wraps a child component - click opens a pop-up with data about a specific post
module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  getInitialState: function () {
    let state = {
      open: false,
      isMounted: false,
    };
    return state;
  },

  getUnsetColor: function () {
    // if (
    //   this.props.highlightColor &&
    //   this.props.highlightColor.startsWith("#")
    // ) {
    //   return TremrUtils.pSBC(0.9, this.props.highlightColor, false, true);
    // }
    // return "#eeeeee";
    return "#ffffff";
  },

  // update the pop-up position
  handleMove: function () {
    if (this.state.open && this.state.isMounted) {
      this.setState(this.getPosition());
    }
  },

  componentDidMount: function () {
    this.setState({
      isMounted: true,
    });
  },

  componentWillUnmount: function () {
    this.setState({
      isMounted: false,
    });
  },

  getPosition: function () {
    // find the position of this element in the viewport
    let domNode = ReactDOM.findDOMNode(this);
    let rect = domNode.getBoundingClientRect();
    return {
      left: rect.x,
      top: rect.y,
      width: rect.width,
      height: rect.height,
    };
  },

  toggleOpen: function (event) {
    event.preventDefault();
    event.stopPropagation();

    // only capture events when open!
    if (this.state.open) {
      window.removeEventListener("scroll", this.handleMove);
      window.removeEventListener("resize", this.handleMove);
    } else {
      TremrUtils.addPassiveEventListener("scroll", this.handleMove);
      TremrUtils.addPassiveEventListener("resize", this.handleMove);
    }

    if (this.state.isMounted) {
      let newState = this.getPosition();
      newState.open = !this.state.open;
      this.setState(newState);
    }
  },

  open: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.open) {
      if (this.state.isMounted) {
        TremrUtils.addPassiveEventListener("scroll", this.handleMove);
        TremrUtils.addPassiveEventListener("resize", this.handleMove);

        let newState = this.getPosition();
        newState.open = true;
        this.setState(newState);
      }
    }
  },

  close: function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.open) {
      TremrUtils.addPassiveEventListener("scroll", this.handleMove);
      TremrUtils.addPassiveEventListener("resize", this.handleMove);

      if (this.state.isMounted) {
        let newState = this.getPosition();
        newState.open = false;
        this.setState(newState);
      }
    }
  },

  info: function (event) {
    event.preventDefault();
    event.stopPropagation();

    window.open(
      "https://support.tremr.com/article/39-stars-and-reputation",
      "_blank"
    );
  },

  render: function () {
    // if user is not an admin just return null
    if (
      Config.reputation == "admin" &&
      (!this.state.user || this.state.user.get("admin") != "yes")
    ) {
      return null;
    }

    let unsetColor = this.getUnsetColor();

    // needs to be provided with firebase data
    let overlay = null;
    let isLeft = this.props.align == "left";
    let popup = null;
    let popupPointer = null;
    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.user_rep_stars &&
      this.props.liveDataPoints.auto_tags
    ) {
      if (this.state.open) {
        let lines = [];
        lines.push(
          <div className="line" key="overall">
            <label>Overall</label>
            <Stars
              alignment="right"
              scale={window.devicePixelRatio}
              drawUnset={true}
              setColor={this.props.highlightColor}
              unsetColor={unsetColor}
              lineWidth={3}
              starSize={18}
              height={18}
              width={63}
              stars={parseInt(
                0 | this.props.liveDataPoints.user_rep_stars.data
              )}
              filledStars={0}
            />
          </div>
        );

        if (this.props.liveDataPoints.auto_tags.data) {
          let keys = Object.keys(this.props.liveDataPoints.auto_tags.data);
          if (keys.length > 0) {
            keys = keys.slice(0).sort((a, b) => {
              return (
                this.props.liveDataPoints.auto_tags.data[b] -
                this.props.liveDataPoints.auto_tags.data[a]
              );
            });
            keys = keys.slice(0, 3);

            keys.forEach(
              function (item) {
                lines.push(
                  <div className="line tag" key={item}>
                    <label>{item}</label>
                    <Stars
                      alignment="right"
                      scale={window.devicePixelRatio}
                      drawUnset={true}
                      setColor={this.props.highlightColor}
                      unsetColor={unsetColor}
                      unsetFilled={true}
                      lineWidth={3}
                      starSize={18}
                      height={18}
                      width={63}
                      stars={0}
                      filledStars={parseInt(
                        0 | this.props.liveDataPoints.auto_tags.data[item]
                      )}
                    />
                  </div>
                );
              }.bind(this)
            );
          }
        }

        // left: rect.x,
        // top: rect.y,
        // width: rect.width,
        // height: rect.height,
        // TODO: check we're on the viewport
        let styles = {
          top: this.state.top,
        };

        // if the left will overlap the edge of the viewport then left align
        let leftPosition = this.state.left + this.state.width - 250;
        leftPosition = leftPosition + 13; // offset a bit

        // if the right overlaps the right edge then just set to zero
        if (leftPosition + 265 > window.innerWidth) {
          styles.right = 0;
        } else {
          styles.left = leftPosition;
        }

        if (this.props.align == "left") {
          leftPosition = this.state.left;
          leftPosition = leftPosition - 12; // offset a bit

          // if we overlap the right edge then set back
          if (leftPosition + 265 > window.innerWidth) {
            // can't do this on the wall because we can't overlap
            // an earlier card (earlier cards can already expand over
            // following cards - zIndex is used to allow this)
            // leftPosition = window.innerWidth - 265;

            // instead restrict the width
            styles.maxWidth = window.innerWidth - (leftPosition + 8);
          }

          // if the left overlaps the left edge then just set to zero
          if (leftPosition < 0) {
            styles.left = 0;
          } else {
            styles.left = leftPosition;
          }
        }

        // let farLeftOfPopup =

        // let dLeft = this.state.left;
        // let dRight = Math.abs(
        //   window.innerWidth - (this.state.left + this.state.width)
        // );
        //
        // console.log("dLeft:" + dLeft);
        // console.log("dRight:" + dRight);
        // if (dRight > dLeft && dLeft <= 200) {
        //   // styles.left = 200;
        //   isLeft = true;
        // } else if (dRight < 20) {
        //   styles.left = "auto";
        //   styles.right = 0;
        //   isLeft = false;
        // }

        // ingore vertical edges
        // if (this.state.top <= 200) {
        //   styles.top = 200;
        // } else if (this.state.top + this.state.height >= window.innerHeight) {
        //   styles.top = window.innerHeight - 250;
        // }

        popup = (
          <div className="reputation-post-overview-popup" style={styles}>
            <h4 onClick={this.info}>
              <span>Reputation</span>
              <SvgIcon icon="question" />
            </h4>
            <h5>{this.props.authorName}</h5>
            {lines}
            <SvgIcon icon="speechpointer" />
          </div>
        );

        overlay = <div className="overlay" onClick={this.toggleOpen} />;
      }
    }

    let classes = classNames({
      "reputation-post-overview": true,
      open: this.state.open,
      left: isLeft,
    });

    if (TremrUtils.hasMouse) {
      return (
        <div
          key="hasMouse"
          className={classes}
          onMouseEnter={this.open}
          onMouseLeave={this.close}
        >
          {popup}
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div key="noMouse" className={classes} onClick={this.toggleOpen}>
          {overlay}
          {popup}
          {this.props.children}
        </div>
      );
    }
  },
});
