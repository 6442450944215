var Backbone = require("backbone");
var TremrTracking = require("../../utils/tracking");
var TremrUtils = require("../../utils/tremr_utils");
var Cookies = require("js-cookie/src/js.cookie");
var Config = require("config");
var alertify = require("alertify");
var Promise = require("bluebird");
var CountDrafts = require("../../models/CountDrafts");
var TremrStorage = require("../../utils/tremr_storage");
var GridLayouts = require("../../stores/generic/gridlayouts");
var WallControls = require("../post/wallcontrols");
var PostListCardWrapper = require("../post/listcardwrapper");
var PostContentCard = require("../post/contentcard");

// Tremr.UserStore = function () {
module.exports = function () {
  // use backbone events
  var store = _.extend(this, Backbone.Events);

  this.preferences = false; // read from cookie or set as defaults
  this.notificationSettings = false;

  // read the auth token from the page, so we can use it when sending back to the server
  this.auth_token = $('meta[name="csrf-token"]').attr("content");

  // remember a list of actions to do if succesfully signed-in
  this.onSigninActions = [];

  // remember if we have sent the user signin status check
  this.pendingUserCheck = true;

  // load user from server (admin only, server will handle that)
  store.loadUser = function (id) {
    return Tremr.Utils.loadModel(
      new Tremr.Models.User(),
      {
        id: id,
      },
      false
    );
  };

  // count drafts for the user
  store.countDrafts = function (user_id) {
    return new Promise(function (resolve, reject) {
      if (user_id) {
        Tremr.Utils.loadModel(
          new CountDrafts(),
          {
            id: user_id,
          },
          false
        ).then(function (params) {
          var model = params.model;
          var data = params.data;
          var options = params.options;

          resolve(model);
        });
      }
    });
  };

  // get the complete user notifications (loaded if needed) and return in a promise
  this.requestNotificationSettings = function () {
    return new Promise(
      function (resolve, reject) {
        if (this.notificationSettings) {
          resolve(this.notificationSettings);
        } else {
          if (this.getUser()) {
            var key = {
              id: this.getUser().get("_id"),
            };
            var m = new Tremr.Models.UserNotificationSettings(key);

            // load notification settings
            Tremr.Utils.loadModel(m, key, false, true).then(
              function (params) {
                var model = params.model;
                var data = params.data;
                var options = params.options;

                this.notificationSettings = model;
                resolve(model);
              }.bind(this),
              function (params) {
                var response = params.response;

                // log the error
                Tremr.Utils.errorMessage(
                  Tremr.messages.dataError,
                  response.status,
                  response
                );
                reject();
              }.bind(this)
            );
          }
        }
      }.bind(this)
    );
  };

  // raise an event with the current setting of a specific notification setting
  this.gettNotificationSetting = function (name) {
    return new Promise(
      function (resolve, reject) {
        // load the settings and return just the one we want
        Tremr.stores.userStore.requestNotificationSettings().then(
          function (model) {
            var setting = model.get(name);
            if (!setting) {
              setting = "yes";
            }
            resolve(setting);
          }.bind(this),
          function () {
            reject();
          }.bind(this)
        );
      }.bind(this)
    );
  };

  // set the value of a specific notification setting and raise an event with the value,
  // and send to the server
  this.setNotificationSetting = function (name, value) {
    var data = {};
    data[name] = value;
    return this.saveNotificationSetting(data);
  };

  // save notification settings to the server
  this.saveNotificationSetting = function (settings) {
    // load the settings and update
    return new Promise(
      function (resolve, reject) {
        // load the settings and return just the one we want
        Tremr.stores.userStore.requestNotificationSettings().then(
          function (model) {
            // add settings to the current ones - so we can send all or just one into this func
            var data = model.attributes;
            data = _.extend(data, settings);
            var params = _.extend(data, {
              authenticity_token: Tremr.auth_token,
            });

            // do the update sending just this data
            model.save(params, {
              success: function (userSession, response) {
                this.notificationSettings = model;
                resolve(model);
              }.bind(this),
              error: function (userSession, response) {
                console.log(
                  "error saving notification settings: " + response.responseText
                );
                reject();
              },
            });
          }.bind(this),
          function () {
            reject();
          }.bind(this)
        );
      }.bind(this)
    );
  };

  // trigger loading of activity for these posts
  this.requestActivityForPosts = function (data) {
    // if (data.length > 0) {
    //    var ids = data.map(function(model) {
    //       return model.id;
    //    });
    //    Tremr.dispatcher.message(this, "tremr:activity:forpost", {post_ids: ids});
    // }
  }.bind(this);

  // factory for footer content
  this.getPersonalFeedFooterContent = function (context) {
    var tabs = store.getTabs(context);
    var defaultTab = store.getDefaultTab(context);

    var Wallref = "personalFeed";
    var ref = "footer." + context.sort + "." + Wallref;

    return (
      <Tremr.User.PersonalFeedFooterFooter
        key={ref}
        ref={ref}
        tabs={tabs}
        initialTab={defaultTab}
        wallIdentifier={Wallref}
      />
    );
  };

  // trigger loading of seen for these activity records
  this.requestSeenForActivities = function (data) {
    if (data.length > 0) {
      var ids = data.map(function (model) {
        return model.id;
      });
      Tremr.dispatcher.message(this, "tremr:activity:seen", ids);
    }
  }.bind(this);

  // factory for creating the notification list on the sidebar
  this.getSidebarNotificationContent = function (user, cache) {
    var tabs = ["latest"];
    var defaultTab = "latest";

    var collFactory = function () {
      return new Tremr.Models.Notifications();
    };

    var cardFactory = function (model, index, props) {
      var id = model.get("id");
      var key = "card-" + id;

      return (
        <Tremr.Activity.ListItem
          mykey={key}
          ref={key}
          key={key}
          activity={model}
        />
      );
    };

    var context = {
      target: "feed",
      feed: user.get("feedname"),
      scope: "notifications",
    };
    var datasources = Tremr.stores.feedStore.getDataSources(context);

    var key = "notificationsSidebarWall" + context.feed + "." + context.scope;

    // ask if the user has seen the newly loaded data and raise events
    var loadedCallback = function (data) {
      this.requestSeenForActivities(data);
    }.bind(this);

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="sidebarNotificationsWall"
        identifier={key}
        cardFactory={cardFactory}
        collectionFactory={collFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
        loadedCallback={loadedCallback}
      />
    );
  };

  // factory for creating the simple follow list on the sidebar
  this.getSidebarFollowingContent = function (user, active, cache) {
    var tabs = ["latest"];
    var defaultTab = "latest";

    var collFactory = function () {
      return new Tremr.Models.Following();
    };

    var cardFactory = function (model, index, props) {
      var id = model.get("id") || model.get("_id");
      var key = "card" + "-" + id;

      // check if this is a user or a tag
      if (model.get("type") && model.get("type") == "tag") {
        let isactive = false;
        let menukey = "tag-" + model.get("tag");
        isactive = menukey == active;

        return (
          <Tremr.Tag.ListItem
            ref={key}
            key={key}
            active={isactive}
            tag={model}
            closeSidebarOnClick={true}
          />
        );
      } else {
        let isactive = false;
        let menukey = "feed-" + model.get("name");
        if (model.get("followedsubfeed")) {
          menukey = menukey + "-" + model.get("followedsubfeed");
        } else {
          menukey = menukey + "-featured";
        }
        isactive = menukey == active;

        return (
          <Tremr.Feed.ListItem
            ref={key}
            key={key}
            active={isactive}
            feed={model}
            closeSidebarOnClick={true}
          />
        );
      }
    };

    var context = {
      target: "feed",
      feed: user.get("feedname"),
      scope: "following",
      subfeed: "featured",
      // subfeeds: '1' // this splits the data out into each subfeed
    };
    var datasources = Tremr.stores.feedStore.getDataSources(context);

    var key = "followingSidebarWall" + context.feed + "." + context.scope;

    // remove based on tag/name
    var customDelete = function (id, collection) {
      var coll = collection.reject(function (item) {
        if (item.get("tag") && item.get("tag") == id) {
          return true;
        } else if (item.get("name") && item.get("name") == id) {
          return true;
        }
        return false;
      });
      return new Tremr.Models.Following(coll);
    };

    return (
      <Tremr.Generic.Wall
        forceGridStyle="list"
        key={key}
        ref="sidebarFollowingWall"
        customDelete={customDelete}
        identifier={key}
        cardFactory={cardFactory}
        collectionFactory={collFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  // factory for getting the content for a state
  this.getPrimaryContent = function (context, cache) {
    let tabs = store.getTabs(context);
    let defaultTab = store.getDefaultTab(context);
    let datasources = store.getDataSources(context);
    let key = "user." + context.target + "." + context.scope;
    if (context.sort) {
      key = key + "." + context.sort;
    }

    let controls = <WallControls wallStyleProperty="wallStylePosts" />;

    let scrollToPos = function () {
      window.scrollTo(0, context.initialScrollPos);
    };

    // callback for wall loaded
    let wallLoaded = function (data, wallStyle) {
      let fullContext = Tremr.context.getState();
      let isCached = fullContext.cache == true;

      _.defer(() => {
        // this.requestActivityForPosts(data);
        // if (wallStyle == 'content' && !isCached) {
        //    this.requestContentForPosts(data);
        // }
      });

      if (isCached) {
        Tremr.context.add({
          cache: false,
        });
        // scroll to initial position
        if (context && context.initialScrollPos) {
          scrollToPos();
        }
      }
    }.bind(this);

    return (
      <Tremr.Generic.Wall
        wallStyleProperty="wallStylePosts"
        controls={controls}
        key={key}
        ref="primaryComponent"
        identifier={key}
        loadedCallback={wallLoaded}
        cardFactory={this.cardFactory}
        collectionFactory={this.postsFactory}
        cache={cache}
        datasources={datasources}
        defaultTab={defaultTab}
        tabs={tabs}
        context={context}
      />
    );
  };

  this.postsFactory = function () {
    return new Tremr.Models.Posts();
  };

  this.cardFactory = function (model, index, options) {
    let identifier = "";
    if (options && options.identifier) {
      identifier = options.identifier;
    }
    let key = "card-" + index + "-" + model.get("id");

    if (options.wallStyle == "content") {
      return (
        <PostContentCard
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else if (options.wallStyle == "list") {
      return (
        <PostListCardWrapper
          loadedCallback={options.loadedCallback}
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
        />
      );
    } else {
      return (
        <Tremr.Post.Card
          ref={key}
          key={key}
          post={model}
          identifier={identifier}
          cardHeightSet={options.cardHeightSet}
        />
      );
    }
  };

  // get named tabs that we want to show for specific context
  this.getTabs = function (context) {
    // return ['Trending', 'Top Rated', 'Latest'];
    return ["latest"];
  };

  // get default tab for specific context
  this.getDefaultTab = function (context) {
    return "latest";
  };

  // get named data source function for a specific context
  this.getDataSources = function (context) {
    if (context.target && context.target == "bookmarks") {
      let sources = [];

      // do in a function and use dynamically in datasources
      let getDataForContext = function (c) {
        let data = {
          target: c.target,
        };
        if (c.scope) {
          data.scope = c.scope;
        }
        if (c.sort) {
          data.sort = c.sort;
        }
        return data;
      };

      sources["latest"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      return sources;
    } else if (context.target && context.target == "inbox") {
      let sources = [];

      // do in a function and use dynamically in datasources
      let getDataForContext = function (c) {
        let data = {
          target: c.target,
        };
        if (c.scope) {
          data.scope = c.scope;
        }
        if (c.sort) {
          data.sort = c.sort;
        }
        return data;
      };

      sources["latest"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      return sources;
    } else if (context.target && context.target == "homepage") {
      let sources = [];

      // do in a function and use dynamically in datasources
      let getDataForContext = function (c) {
        let data = {
          target: "personalfeed",
        };
        if (c.scope) {
          data.scope = c.scope;
        }
        if (c.sort) {
          data.sort = c.sort;
        }
        return data;
      };

      sources["trending"] = function (page, posts, cntxt) {
        // return Tremr.stores.postStore.loadPosts(page, posts, _.extend({ sort: 'trending' }, getDataForContext(cntxt)));
        alertify.error("Not implemented yet");
      };
      sources["recommended"] = function (page, posts, cntxt) {
        // return Tremr.stores.postStore.loadPosts(page, posts, _.extend({ sort: 'recommended' }, getDataForContext(cntxt)));
        alertify.error("Not implemented yet");
      };
      sources["latest"] = function (page, posts, cntxt, cache) {
        return Tremr.stores.postStore.loadPosts(
          page,
          posts,
          _.extend(
            {
              sort: "latest",
            },
            getDataForContext(cntxt)
          ),
          cache
        );
      };
      return sources;
    } else {
      console.log("DataSource not implemented for:");
      console.dir(context);
    }
  };

  // default user preferences
  this.getDefaultPreferences = function () {
    // settings to start off with
    var defaults = {
      wallStylePosts: "grid",
      wallStyleResponses: "content",
    };

    return defaults;
  };

  // read the value of a user preference (creates defaults if there aren't any)
  this.getPreference = function (key) {
    if (this.preferences == false) {
      // create fresh
      this.preferences = _.extend(
        this.getDefaultPreferences(),
        TremrStorage.getLocal("ui-preferences")
      );
      TremrStorage.setLocal("ui-preferences", this.preferences);
    } else if (this.preferences[key] == undefined) {
      // if missing key try fresh defaults and merge
      this.preferences = _.extend(
        this.getDefaultPreferences(),
        this.preferences
      );
      TremrStorage.setLocal("ui-preferences", this.preferences);
    }

    return this.preferences[key];
  };

  // set a user preference (stored in a cookie)
  this.setPreference = function (key, value) {
    if (this.preferences == false) {
      this.preferences = this.getDefaultPreferences();
    }

    this.preferences[key] = value;
    TremrStorage.setLocal("ui-preferences", this.preferences);
  };

  // update the user preference
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:preference",
    function (data) {
      this.setPreference(data.key, data.value);

      // fire an event to notify the UI of the specific update
      Tremr.dispatcher.message(this, "tremr:user:preference:" + data.key, data);
    },
    this
  );

  // listen for new channel being created and recreate
  this.listenTo(
    Tremr.dispatcher,
    "tremr:feed:edit:success",
    function (user) {
      // console.log("rechecking user session");
      this.checkExsitingSession(this);
    },
    this
  );

  // check if we have sent the user signin status check yet
  this.isPendingUserCheck = function () {
    return this.pendingUserCheck;
  };

  // returns the currently signed-in user
  this.getUser = function () {
    if (Tremr.context.data.user) {
      return Tremr.context.data.user;
    } else {
      return false;
    }
  };

  // update the auth token used when posting data
  this.updateAuthToken = function (value) {
    this.auth_token = value;
    $('meta[name="csrf-token"]').attr("content", value);
  };

  // check if there is a current user logged in on the server
  this.checkExsitingSession = function (userStore) {
    if (typeof userStore == "undefined") {
      userStore = this;
    }

    var session = new Tremr.Models.UserSession({ id: "current" });
    session
      .fetch({
        success: function (userSession, data, response) {
          if (this) {
            this.pendingUserCheck = false;
          }

          if (_.isString(data)) {
            data = JSON.parse(data);
          }

          if (data.auth_token) {
            Tremr.stores.userStore.updateAuthToken(data.auth_token);
          }

          if (data.success) {
            var user_data = {};
            if (_.isString(data.user)) {
              user_data = JSON.parse(data.user);
            }

            var user = new Tremr.Models.User(user_data);

            Tremr.context.add({ user: user });

            // sign-in to firebase using the users JWT
            Tremr.firebase
              .auth()
              .signInWithCustomToken(user.get("jwt"))
              .catch(function (error) {
                console.log("Firebase signInWithCustomToken:");
                console.dir(error);
                if (error.code == "auth/custom-token-mismatch") {
                }
              });

            // wait and refresh user (includes cloudinary that needs
            // to be updated but also nice to check for session again sometimes)
            _.delay(Tremr.stores.userStore.checkExsitingSession, 300000);

            if (
              this &&
              this.dispatchSigninSuccess &&
              _.isFunction(this.dispatchSigninSuccess)
            ) {
              this.dispatchSigninSuccess(user);
            } else if (userStore && userStore.dispatchSigninSuccess) {
              if (_.isFunction(userStore.dispatchSigninSuccess)) {
                userStore.dispatchSigninSuccess(user);
                // } else {
                //   console.log("dispatchSigninSuccess is not a function:");
                //   console.dir(userStore.dispatchSigninSuccess);
              }
              // } else {
              // console.log("*** call to dispatchSigninSuccess on NULL ! ***");
            }
          } else {
            // dispatch an event to show we aren't signed-in
            Tremr.dispatcher.message(
              Tremr.stores.userStore,
              "tremr:user:signin:failed"
            );
          }
        }.bind(userStore),
      })
      .then(
        function () {
          if (this) {
            this.pendingUserCheck = false;
          }
        }.bind(userStore)
      );
  };

  // execute any pending actions when signed in/up
  (this.execPendingActions = function (user) {
    if (this.onSigninActions && this.onSigninActions.length > 0) {
      // also wait until we have firebase properly logged-in
      var fbuser = Tremr.firebase.auth().currentUser;
      if (user && fbuser && user.get("_id") == fbuser.uid) {
        // User is signed in.. fire pending actions
        _.each(
          this.onSigninActions,
          function (action) {
            if (action.callback) {
              action.callback(action.data);
            } else {
              Tremr.dispatcher.message(this, action.event, action.data);
            }
          }.bind(this)
        );
        this.onSigninActions = [];
      } else {
        // not signed-in, call again shortly
        _.delay(this.execPendingActions.bind(this), 200, user);
      }
    } // no actions to worry about
  }),
    // util to raise the user signin:success event but that waits
    // for the matching firebase user to also be signed-in to avoid
    // a mismatch
    (this.dispatchSigninSuccess = function (user) {
      // also wait until we have firebase properly logged-in
      let fbuser = Tremr.firebase.auth().currentUser;
      if (user && fbuser && user.get("_id") == fbuser.uid) {
        Tremr.dispatcher.message(this, "tremr:user:signin:success", user);
      } else {
        _.delay(() => {
          if (window.location.pathname == "/") {
            Backbone.history.loadUrl();
          } else if (window.location.pathname == "/welcome") {
            Tremr.navigatePrimary({
              target: "homepage",
              scope: "all",
              sort: "latest",
            });
          }
          this.dispatchSigninSuccess(user);
        }, 100);
      }
    }),
    // request user sidebar open
    store.listenTo(Tremr.dispatcher, "tremr:user:sidebar", function (state) {
      // we must only allow this if the user is signed-in, so
      // raise a signed-in only event and allow user store to
      // control it.
      let user = Tremr.stores.userStore.getUser();
      if (state && !user) {
        Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
          event: "tremr:user:sidebar",
          data: state,
        });
        return;
      }

      var overlayData = {
        class: "Tremr.User.Sidebar",
        style: "sidebar",
        props: {
          user: user,
        },
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);

      // open/close
      // if (state) {
      //    Tremr.context.add({userSidebar: true});
      // } else {
      //    Tremr.context.add({userSidebar: false});
      // }
    });

  // request user sidebar open
  store.listenTo(Tremr.dispatcher, "tremr:user:sidebar:activity", function (
    state
  ) {
    // we must only allow this if the user is signed-in, so
    // raise a signed-in only event and allow user store to
    // control it.
    let user = Tremr.stores.userStore.getUser();
    if (state && !user) {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        event: "tremr:user:sidebar:activity",
        data: state,
      });
      return;
    }

    var overlayData = {
      class: "Tremr.User.SidebarActivity",
      style: "sidebar",
      props: {
        user: user,
      },
    };
    Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);

    // open/close
    // if (state) {
    //    Tremr.context.add({userSidebar: true});
    // } else {
    //    Tremr.context.add({userSidebar: false});
    // }
  });

  // request reputation sidebar open
  store.listenTo(Tremr.dispatcher, "tremr:user:sidebar:reputation", function (
    state
  ) {
    // we must only allow this if the user is signed-in, so
    // raise a signed-in only event and allow user store to
    // control it.
    let user = Tremr.stores.userStore.getUser();
    if (state && !user) {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        event: "tremr:user:sidebar:reputation",
        data: state,
      });
      return;
    }

    var overlayData = {
      class: "Tremr.Reputation.Sidebar",
      style: "sidebar",
      props: {
        user: user,
      },
    };
    Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
  });

  // immediately check session if the page has become active again
  this.listenTo(
    Tremr.dispatcher,
    "tremr:visibilitychange",
    function (visible) {
      if (visible) {
        this.checkExsitingSession();
      }
    }.bind(this)
  );

  // tracking user id
  this.listenTo(Tremr.dispatcher, "tremr:user:signin:success", function (data) {
    TremrTracking.identify(data);
    Cookies.set("has_closed_welcomer", "1", { expires: 1 });
    Cookies.set("has_closed_newsletter", "1", { expires: 1 });
  });

  // tracking user sign-up
  this.listenTo(Tremr.dispatcher, "tremr:user:signup:success", function (data) {
    TremrTracking.signedUp(data);
  });

  // listen for required sign-in messages
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:requiresignin",
    function (data) {
      // if we are signed-in then just fire the action
      if (this.getUser() && data && data.callback) {
        data.callback(data);
        return;
      } else if (this.getUser() && data && data.event) {
        Tremr.dispatcher.message(this, data.event, data.data);
        return;
      }

      // otherwise remember the action and try to sign-in
      this.onSigninActions.push(data);
      Tremr.dispatcher.message(this, "tremr:open:pitchpage", data.data);
    },
    this
  );

  // listen for user requesting forgotten password
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:forgotpassword",
    function (data) {
      // add authenticity token
      var params = _.extend(data, { authenticity_token: Tremr.auth_token });

      var model = new Tremr.Models.UserPasswordRecovery();

      // watch for invalid event on model
      model.on("invalid", function (model, response, options) {
        Tremr.dispatcher.message(
          this,
          "tremr:user:forgotpassword:failed",
          model.validationError
        );
      });

      // save
      model.save(params, {
        success: function (userSession, response) {
          var data = response.user;
          if (_.isString(data)) {
            data = JSON.parse(data);
          }

          Tremr.dispatcher.message(
            this,
            "tremr:user:forgotpassword:success",
            data
          );
        },
        error: function (userSession, response) {
          var info = response.responseJSON;
          Tremr.dispatcher.message(
            this,
            "tremr:user:forgotpassword:failed",
            info.errors
          );
        },
      });
    },
    this
  );

  // listen for user submitting new password
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:newpassword",
    function (data) {
      // add authenticity token
      var params = _.extend(data, { authenticity_token: Tremr.auth_token });

      var model = new Tremr.Models.UserNewPassword();

      // watch for invalid event on model
      model.on("invalid", function (model, response, options) {
        Tremr.dispatcher.message(
          this,
          "tremr:user:newpassword:failed",
          model.validationError
        );
      });

      // save
      model.save(params, {
        success: function (userSession, response) {
          var data = response.user;
          if (_.isString(data)) {
            data = JSON.parse(data);
          }

          var user = new Tremr.Models.User(data);

          Tremr.context.add({ user: user });

          // send a signin AND a signup success
          this.dispatchSigninSuccess(user);
          Tremr.dispatcher.message(
            this,
            "tremr:user:newpassword:success",
            data
          );
        }.bind(this),
        error: function (userSession, response) {
          var info = response.responseJSON;
          Tremr.dispatcher.message(
            this,
            "tremr:user:newpassword:failed",
            info.errors
          );
        }.bind(this),
      });
    },
    this
  );

  // log the user out
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:signout",
    function (action, data) {
      var endsession = new Tremr.Models.EndUserSession({
        authenticity_token: Tremr.auth_token,
        id: "session",
      });
      endsession.destroy({
        success: function (model, response) {
          // sign-out from firebase
          Tremr.firebase.auth().signOut();

          // replace with anonymous firebase!
          Tremr.firebase
            .auth()
            .signInAnonymously()
            .catch(function (error) {
              console.log("Firebase signInAnonymously:");
              console.dir(error);
            });

          // update state
          Tremr.context.remove(["user"]);
          Tremr.navigatePrimary({
            target: "homepage-feed",
            scope: "all",
            feed: Tremr.Theme.HomepageFeed,
            sort: "latest",
          });

          Tremr.dispatcher.message(this, "tremr:user:signout:success");

          alertify.log("You have been signed-out, have a nice day!");

          // always go to the homepage
          // window.location.href = '//';
        }.bind(this),
      });
    },
    this
  );

  // when we are asked to open the signinup, create component and ask for an overlay
  this.listenTo(
    Tremr.dispatcher,
    "tremr:open:signin",
    function (action, data) {
      var overlayData = {
        class: "Tremr.User.Signin",
        modal: true,
        props: {},
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    },
    this
  );

  this.listenTo(
    Tremr.dispatcher,
    "tremr:open:requestinvite",
    function (action, data) {
      var overlayData = {
        class: "Tremr.Layout.Requestinvite",
        modal: true,
        props: {},
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    },
    this
  );

  // when we are asked to open the signinup, create component and ask for an overlay
  this.listenTo(
    Tremr.dispatcher,
    "tremr:open:pitchpage",
    function (action, data) {
      var overlayData = {
        class: "Tremr.Theme.Pitchpage",
        modal: true,
        props: {},
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    },
    this
  );

  // open email settings in overlay
  this.listenTo(
    Tremr.dispatcher,
    "tremr:open:notificationsettings",
    function (action, data) {
      // we ust only allow this if the user is signed-in, so
      // raise a signed-in only event and allow user store to
      // control it.
      if (!Tremr.stores.userStore.getUser()) {
        Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
          event: "tremr:open:notificationsettings",
          data: data,
        });
        return;
      }

      var overlayData = {
        class: "Tremr.User.NotificationSettings",
        style: "sidebar",
        props: {
          user: this.getUser(),
        },
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    },
    this
  );

  // open profile edit in overlay for admin to edit user
  this.listenTo(
    Tremr.dispatcher,
    "tremr:open:adminprofile",
    function (action, data) {
      // load the user
      var promise = this.loadUser(action);
      promise.then(
        function (params) {
          var model = params.model;
          var data = params.data;
          var options = params.options;

          var overlayData = {
            class: "Tremr.User.Profile",
            modal: true,
            props: {
              user: model,
              showTags: true,
            },
          };
          Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
        }.bind(this)
      );
    },
    this
  );

  // open profile edit in overlay
  this.listenTo(
    Tremr.dispatcher,
    "tremr:open:profile",
    function (action, data) {
      var overlayData = {
        class: "Tremr.User.Profile",
        modal: true,
        props: {
          user: this.getUser(),
        },
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
    },
    this
  );

  // listen for profile edits
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:profile:save",
    function (data) {
      // if we are not logged-in then fail
      var signedInUser = this.getUser();
      if (!signedInUser) {
        console.log("You must be signed-in to edit your profile.");
        return;
      }

      var params = _.extend(data, { authenticity_token: Tremr.auth_token });

      // use the user that we're given
      var user = params.user;
      delete params["user"];

      // watch for invalid event
      user.on(
        "invalid",
        function (model, response, options) {
          Tremr.dispatcher.message(
            this,
            "tremr:user:profile:failed",
            model.validationError
          );
        }.bind(this)
      );

      // make sure we have an ID
      if (!user.get("id") && user.get("_id")) {
        user.set("id", user.get("_id"));
      }

      // save
      user.save(params, {
        success: function (userSession, response) {
          var data = response.user;
          if (_.isString(data)) {
            data = JSON.parse(data);
          }
          var user = new Tremr.Models.User(data);

          if (user.get("_id") == signedInUser.get("id")) {
            Tremr.context.add({ user: user });
            signedInUser = user;
          }

          // send a saved message
          Tremr.dispatcher.message(
            this,
            "tremr:user:profile:success",
            signedInUser
          );
        }.bind(this),
        error: function (userSession, response) {
          if (response.responseText != "") {
            var data = JSON.parse(response.responseText);
            console.dir(JSON.parse(response.responseText));
            if (data["errors"] === undefined) {
              var info = response.responseJSON;
              Tremr.dispatcher.message(this, "tremr:user:profile:failed", {
                email: "Profile could not be saved.",
              });
            } else {
              Tremr.dispatcher.message(
                this,
                "tremr:user:profile:failed",
                data["errors"]
              );
            }
          } else {
            console.log("Unkown error saving profile");
          }
        }.bind(this),
      });
    },
    this
  );

  // listen for signup requests
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:signup",
    function (data) {
      // if we are already logged-in then ignore this
      if (this.getUser()) {
        console.log("Ignoring sign-up request because already signed-in.");
        return;
      }

      var params = _.extend(data, {
        authenticity_token: Tremr.auth_token,
        password_confirmation: data.password,
      });

      var signupmodel = new Tremr.Models.UserRegistration();

      // watch for invalid event
      signupmodel.on("invalid", function (model, response, options) {
        console.log("invalid:");
        console.dir(arguments);
        Tremr.dispatcher.message(
          this,
          "tremr:user:signup:failed",
          model.validationError
        );
      });

      // save
      signupmodel.save(params, {
        success: function (userSession, response) {
          var data = response.user;
          if (_.isString(data)) {
            data = JSON.parse(data);
          }
          var user = new Tremr.Models.User(data);

          Tremr.context.add({ user: user });

          // sign-in to firebase using the users JWT
          Tremr.firebase
            .auth()
            .signInWithCustomToken(user.get("jwt"))
            .catch(function (error) {
              console.log("Firebase signInWithCustomToken:");
              console.dir(error);
            });

          // send a signin AND a signup success
          this.dispatchSigninSuccess(user);
          Tremr.dispatcher.message(this, "tremr:user:signup:success", user);

          // fire pending actions
          this.execPendingActions(user);
        }.bind(this),
        error: function (userSession, response) {
          if (response.responseText != "") {
            var data = JSON.parse(response.responseText);
            if (data["errors"] === undefined) {
              var info = response.responseJSON;
              Tremr.dispatcher.message(this, "tremr:user:signup:failed", {
                email: "User could not be created, have you already signed-up?",
              });
            } else {
              Tremr.dispatcher.message(
                this,
                "tremr:user:signup:failed",
                data["errors"]
              );
            }
          } else {
            console.log("Unkown error signing-up");
          }
        }.bind(this),
      });
    },
    this
  );

  // listen for signin requests
  this.listenTo(
    Tremr.dispatcher,
    "tremr:user:signin",
    function (data) {
      // if we are already logged-in then ignore this
      if (this.getUser()) {
        console.log("Ignoring sign-in request because already signed-in.");
        return;
      }

      var params = _.extend(data, { authenticity_token: Tremr.auth_token });

      var signinmodel = new Tremr.Models.UserSession();

      // watch for invalid event on model
      signinmodel.on("invalid", function (model, response, options) {
        Tremr.dispatcher.message(
          this,
          "tremr:user:signin:failed",
          model.validationError
        );
      });

      let originalData = data;

      // save
      signinmodel.save(params, {
        success: function (userSession, response) {
          if (
            _.isArray(response) &&
            response[0] &&
            response[0].uievent == "retry"
          ) {
            Tremr.dispatcher.message(this, "tremr:user:signin", originalData);
          } else {
            var d = response.user;
            if (_.isString(d)) {
              d = JSON.parse(d);
            }
            var user = new Tremr.Models.User(d);

            Tremr.context.add({ user: user });

            // sign-in to firebase using the users JWT
            Tremr.firebase
              .auth()
              .signInWithCustomToken(user.get("jwt"))
              .catch(function (error) {
                console.log("Firebase signInWithCustomToken:");
                console.dir(error);
              });

            this.dispatchSigninSuccess(user);

            // fire pending actions
            this.execPendingActions(user);
          }
        }.bind(this),
        error: function (userSession, response) {
          var info = response.responseJSON;
          Tremr.dispatcher.message(
            this,
            "tremr:user:signin:failed",
            info.errors
          );
        }.bind(this),
      });
    },
    this
  );

  // listen for social sign-in
  this.listenTo(
    Tremr.dispatcher,
    "tremr:social_login",
    function (arg) {
      // send to server to create user or sign-in
      var sc = new Tremr.Models.SocialCallback({
        connection_token: arg.connection.connection_token,
      });

      // if they are a new user, show the profile editor
      sc.save(
        {},
        {
          success: function (m, response, options) {
            var data = response.user;
            if (_.isString(data)) {
              data = JSON.parse(data);
            }
            var user = new Tremr.Models.User(data);

            Tremr.context.add({ user: user });

            // sign-in to firebase using the users JWT
            Tremr.firebase
              .auth()
              .signInWithCustomToken(user.get("jwt"))
              .catch(function (error) {
                console.log("Firebase signInWithCustomToken:");
                console.dir(error);
              });

            this.dispatchSigninSuccess(user);

            // show the user profile overlay and pass along the
            if (response.wasnew == true) {
              Tremr.dispatcher.message(this, "tremr:user:signup:success", user);

              // open profile editor
              Tremr.dispatcher.message(this, "tremr:open:profile", {});
            }

            // fire pending actions
            this.execPendingActions(user);
          }.bind(this),
          error: function (model, xhr, options) {
            Tremr.dispatcher.message(this, "tremr:user:signin:failed", []);

            var r = JSON.parse(xhr.responseText);
            alertify.error("Error signing in: " + r.error);
          }.bind(this),
        }
      );
    },
    this
  );

  // listen for social link
  this.listenTo(
    Tremr.dispatcher,
    "tremr:social_link",
    function (arg) {
      // send to server
      let data = {
        connection_token: arg.connection.connection_token,
        user_token: arg.connection.user_token,
      };
      TremrUtils.fetch(Config.apiurl + "/api/social_link", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
      }).then(
        function (response) {
          if (response.ok) {
            response.json().then(
              function (data) {
                var user_data = {};
                if (_.isString(data.user)) {
                  user_data = JSON.parse(data.user);
                }

                var user = new Tremr.Models.User(user_data);

                Tremr.context.add({ user: user });
                this.dispatchSigninSuccess(user);
              }.bind(this)
            );
          } else {
            response.json().then(
              function (data) {
                let message = "Error linking social media account";
                if (data.error) {
                  message = data.error;
                }

                console.log(
                  "Error:" + response.status + ":" + response.statusText
                );
                alertify.error(message);
              }.bind(this)
            );
          }
        }.bind(this)
      );
    },
    this
  );

  // listen for social sign-in
  this.listenTo(
    Tremr.dispatcher,
    "tremr:social_login:signup",
    function (arg) {
      // send to server to create user or sign-in
      var sc = new Tremr.Models.SocialSignupCallback({
        connection_token: arg.connection.connection_token,
      });

      sc.save(
        {},
        {
          success: function (m, response, options) {
            var data = response;
            if (_.isString(data)) {
              data = JSON.parse(data);
              console.dir(data);
            }
            data = data.social;
            console.dir(data);

            // notify sign-up process of the social data
            Tremr.dispatcher.message(
              this,
              "tremr:social_login:signup:success",
              data
            );
          }.bind(this),
          error: function (model, xhr, options) {
            Tremr.dispatcher.message(this, "tremr:user:signin:failed", []);

            var r = JSON.parse(xhr.responseText);
            alertify.error("Error signing in: " + r.error);
          }.bind(this),
        }
      );
    },
    this
  );

  return store;
};
