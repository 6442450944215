var classNames = require("../../utils/classnames");
var ReactUtils = require("../../utils/react_utils");
var CreateReactClass = require("create-react-class");

// ui for signing the user up
// Tremr.User.Signup = CreateReactClass({
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  // register listeners
  componentDidMount: function () {
    _.extend(this, Backbone.Events);

    this.renderSocialSignin();

    var self = this;

    this.listenTo(Tremr.dispatcher, "tremr:user:signup:failed", function (
      errors
    ) {
      // clear old errors
      _.each(
        this.refs,
        function (ref) {
          if (ref.state.error) {
            ref.setState({
              error: false,
            });
          }
        }.bind(this)
      );

      _.each(_.pairs(errors), function (error) {
        if (self.refs[error[0]]) {
          self.refs[error[0]].setState({
            error: true,
            errorMessage: error[1],
          });
        }
      });

      this.setState({
        isLoading: false,
      });
    });

    this.renderSocialSignin();

    if (!this.state.requested) {
      this.renderRecaptcha(this);
    }

    // add clearbit tracking
    let node = ReactDOM.findDOMNode(this);
    if (Tremr.Theme.SignupTacking) {
      Tremr.Theme.SignupTacking(node.document);
    }
  },

  // unregister listeners
  componentWillUnmount: function () {
    this.stopListening();
  },

  getInitialState: function () {
    return {
      name: "",
      email: "",
      password: "",
      username: "",
      isLoading: false,
    };
  },

  renderSocialSignin: function () {
    // if (!Tremr.Theme.RenderSocialSignin) {
    //   return;
    // }
    //
    // // add the social signup
    // var url = Tremr.current_url.protocol + "//" + Tremr.current_url.hostname;
    // if (
    //   Tremr.current_url.port &&
    //   Tremr.current_url.port != "80" &&
    //   Tremr.current_url.port != "0"
    // ) {
    //   url = url + ":" + Tremr.current_url.port;
    // }
    // var social_callback_script = url + "/api/social_callback";
    // // var css = url + '/css/oneall.css';
    // var css = url + Tremr.Theme.OneAllCss;
    //
    // _oneall.push([
    //   "social_login",
    //   "set_providers",
    //   ["facebook", "twitter", "google"],
    // ]);
    // _oneall.push(["social_login", "set_grid_sizes", [1, 3]]);
    // _oneall.push(["social_login", "set_custom_css_uri", css]);
    // _oneall.push(["social_login", "set_callback_uri", social_callback_script]);
    // _oneall.push(["social_login", "do_render_ui", "social_signup"]);
    // _oneall.push([
    //   "social_login",
    //   "set_event",
    //   "on_login_end_success",
    //   function (arg) {},
    // ]);
    // _oneall.push([
    //   "social_login",
    //   "set_event",
    //   "on_login_redirect",
    //   function (arg) {
    //     Tremr.dispatcher.message(this, "tremr:social_login", arg);
    //     this.setState({
    //       isLoading: true,
    //     });
    //     return false;
    //   }.bind(this),
    // ]);
  },

  componentDidUpdate: function (prevProps, prevState) {
    // this.renderSocialSignin();
  },

  nameChanged: function (name) {
    var slugValidation = ReactUtils.validateSlug(name);
    var feedname = slugValidation.value;

    this.refs.feedname.setState({
      value: feedname,
      error: !slugValidation.valid,
      errorMessage: slugValidation.message,
    });
    this.setState({
      feedname: feedname,
    });
  },

  feednameChanged: function (name) {
    this.setState({
      feedname: name,
    });
  },

  signup: function (event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    var data = {
      name: this.refs.name.state.value,
      email: this.refs.email.state.value,
      password: this.refs.password.state.value,
      feedname: this.refs.feedname.state.value,
    };

    Tremr.dispatcher.message(this, "tremr:user:signup", data);
  },

  more: function (event) {
    event.preventDefault();
    event.stopPropagation();

    Tremr.dispatcher.message(this, "tremr:close:overlay", this.props.index);

    Tremr.navigateCurrent({
      target: "post",
      feed: "tremr",
      id: "what-is-tremr",
    });
  },

  render: function () {
    var self = this;

    var buttonClasses = classNames({
      submit: true,
      loading: this.state.isLoading,
    });

    var buttonLabel = "Sign Up";
    if (this.state.isLoading) {
      buttonLabel = "Creating Account";
    }

    let socialSignin = null;
    // if (Tremr.Theme.RenderSocialSignin) {
    //   socialSignin = <div id="social_signup"></div>;
    // }

    return (
      <form className="user-signup">
        <p className="intro">
          {Tremr.Theme.Title} is the new place to have your say on the news of
          the day.{" "}
          <a target="_blank" href="/tremr/what-is-tremr">
            Learn more…
          </a>
        </p>

        <h3>Sign-up</h3>
        {socialSignin}

        <Tremr.Generic.Input
          ref="name"
          onChange={this.nameChanged}
          name="name"
          label="Full name"
          type="text"
          initialValue={self.state.name}
          maxLength="30"
        />

        <Tremr.Generic.Input
          ref="email"
          name="email"
          label="Email address"
          type="email"
          initialValue={self.state.email}
          validation={ReactUtils.validateEmail}
          maxLength="50"
        />

        <Tremr.Generic.Input
          ref="password"
          name="password"
          label="Password"
          type="password"
          initialValue={self.state.password}
          validation={ReactUtils.validatePassword}
          maxLength="50"
        />

        <Tremr.Generic.Input
          onEnter={this.signup}
          ref="feedname"
          onChange={this.feednameChanged}
          name="feedname"
          label="Username"
          type="text"
          initialValue={self.state.feedname}
          validation={ReactUtils.validateSlug}
          maxLength="30"
        />

        <p className="help">
          Chose your username:{" "}
          <em>
            www.tremr.com/<span>{self.state.feedname}</span>
          </em>
        </p>

        <button onClick={this.signup} className={buttonClasses}>
          {buttonLabel}
        </button>
        <p className="help">
          By clicking 'Sign Up' you agree to
          <br />
          the {Tremr.Theme.Title}{" "}
          <a href={Tremr.Theme.TermsLink} target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={Tremr.Theme.PrivacyPolicyLink} target="_blank">
            Privacy Policy
          </a>
        </p>
      </form>
    );
  },
});
