require("./scss/dashboard.scss");

let React = require("react");
let TremrSettings = require("../../utils/tremr_settings");
let CreateReactClass = require("create-react-class");
let Dashboard = require("../controls/dashboard");

// designed to be wrapped with a withDBData and then passes liveDataPoints data
module.exports = CreateReactClass({
  render: function () {
    let imagePublicId = "avatar-default";
    if (
      this.props.user.get("avatar_image") &&
      this.props.user.get("avatar_image").public_id
    ) {
      imagePublicId = this.props.user.get("avatar_image").public_id;
    }
    const avatarUrl =
      TremrSettings.cloudinary_url +
      "image/upload/dpr_" +
      Tremr.Utils.getPixelRatio() +
      ",f_auto,q_90,w_600,h_600,c_fill,g_face/" +
      imagePublicId +
      ".jpg";

    if (
      this.props.liveDataPoints &&
      this.props.liveDataPoints.reputation &&
      this.props.liveDataPoints.reputation.data &&
      this.props.liveDataPoints.bars &&
      this.props.liveDataPoints.bars.data
    ) {
      let rep = this.props.liveDataPoints.reputation.data;

      const trust = (parseFloat(rep.trust_grade) * 100) / 10;
      const respect = (parseFloat(rep.respect_grade) * 100) / 10;

      let bars = this.props.liveDataPoints.bars.data;
      let stars = [bars.one_star_bar, bars.two_star_bar, bars.three_star_bar];
      // let stars = [10, 20, 30];

      let tags = {};
      if (rep.tags) {
        Object.keys(rep.tags).forEach(function (key) {
          tags[rep.tags[key].tag] = rep.tags[key];
        });
      }

      let reputationDataPoints = rep.rep_score_30_days_array;
      let activityDataPoints = rep.rep_activity_30_days_array;

      return (
        <Dashboard
          title={this.props.user.get("name")}
          time={600}
          delay={600}
          reputation={parseFloat(rep.rep_score)}
          trust={trust}
          respect={respect}
          stars={stars}
          reputationValues={reputationDataPoints}
          activityValues={activityDataPoints}
          avatarUrl={avatarUrl}
          tags={tags}
          highlightColor={this.props.highlightColor}
        />
      );
    } else {
      return (
        <Dashboard
          title={this.props.user.get("name")}
          time={0}
          delay={0}
          reputation={0}
          trust={0}
          respect={0}
          stars={[10, 20, 30]}
          reputationValues={[]}
          activityValues={[]}
          avatarUrl={avatarUrl}
          tags={{}}
          highlightColor={this.props.highlightColor}
        />
      );
    }
  },
});
