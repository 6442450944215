var CreateReactClass = require('create-react-class');
var PropTypes = require('prop-types');

// Tremr.Generic.Share = CreateReactClass({
module.exports = CreateReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        icons: PropTypes.array,
        imageUrl: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        moreIcon: PropTypes.element,
        showTitles: PropTypes.bool,
        addthisSuffix: PropTypes.string
    },

    getDefaultProps: function() {
      return {
          icons: ['twitter', 'facebook', 'more'],
          showTitles: false,
          addthisSuffix: ''
      }
    },

    componentDidMount: function() {
        this.componentDidUpdate();

        // if ((typeof addthis !== 'undefined') && addthis.addEventListener) {
        //     addthis.addEventListener('addthis.menu.share', this.shareEventHandler);
        // }

        // if ((typeof addthis !== 'undefined') && addthis.addEventListener) {
        //     addthis.addEventListener('addthis.menu.share', this.shareEventHandler);
        // }
    },

    // componentWillUnmount: function() {
    //     if ((typeof addthis !== 'undefined') && addthis.removeEventListener) {
    //         addthis.removeEventListener('addthis.menu.share');
    //     }
    // },

    // track when a user shares
    // shareEventHandler: function(event) {
    //     if (event.type == 'addthis.menu.share') {
    //         console.log("SHARED!");
    //         console.dir(event.data);
    //     }
    // },

    componentDidUpdate: function() {

      // can't set these in REACT !
      var domNode = ReactDOM.findDOMNode(this);
      var url =  window.location.protocol + '//' + window.location.host + this.props.url;
      domNode.setAttribute('data:media', this.props.imageUrl);
      domNode.setAttribute('data:url', url);
      domNode.setAttribute('data:title', this.props.title);
      domNode.setAttribute('data:description', this.props.description);
      if (typeof addthis !== 'undefined') {
        addthis.toolbox(".addthis_toolbox"+this.props.addthisSuffix);        
      }        
    },

    nativeShare: function(event) {

        var url =  window.location.protocol + '//' + window.location.host + this.props.url;

        if (navigator.share && _.isFunction(navigator.share)) {
            navigator.share({
            title: this.props.title,
            text: this.props.description,
            url: url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
    },

    // display
    render: function() {

        // <a className="addthis_button_link"><img src="/images/share_button_twitter_72.jpg" width="32" height="32" alt="Share to Twitter" /></a>
        //<img src="/images/share_button_facebook_72.jpg" width="32" height="32" alt="Share to Facebook" />

        var url =  window.location.protocol + '//' + window.location.host + this.props.url;

        let moreIcon = this.props.moreIcon;
        if (!moreIcon) {
            moreIcon = <Tremr.Generic.SvgIcon icon="share" classes="share icon" />;
        }

        let moreButton = null;
        let moreTitle = null;
        if (this.props.showTitles) {
            moreTitle = <label className="share-title">Share</label>;
        }
        if (this.props.icons.includes('more')) {
            if (navigator.share && _.isFunction(navigator.share)) {
                moreButton = <div className="native-share" onClick={this.nativeShare}>
                    {moreIcon}{moreTitle}
                </div>;
            } else {
                // moreButton = <a className="addthis_button_more">
                //     {moreIcon}{moreTitle}
                // </a>;
            }
        }

        let twitterButton = null;
        let twitterTitle = null;
        let facebookButton = null;
        let facebookTitle = null;

        // no idea why but facebook link in sidebar only doesn't open
        // using a.href so doing with js instead which works
        let openLink = function(event) {
            if (event.currentTarget && event.currentTarget.getAttribute("href")) {
                event.preventDefault();
                event.stopPropagation();
                window.open(event.currentTarget.getAttribute("href"));
            }
        }
        
        // only show these where we dont have native share
        if (navigator.share) {
            if (this.props.showTitles) {
                twitterTitle = <label className="share-title">Twitter</label>;
            }
            if (this.props.icons.includes('twitter')) {
                twitterButton = <a className="hide-mobile addthis_button_twitter" target="_blank" href={"https://twitter.com/intent/tweet?text="+encodeURIComponent(this.props.title)+"&url="+encodeURIComponent(url)}>
                    <Tremr.Generic.SvgIcon icon="twitter" classes="twitter icon" />{twitterTitle}
                </a>;
            }

            if (this.props.showTitles) {
                facebookTitle = <label className="share-title">Facebook</label>;
            }
            if (this.props.icons.includes('facebook')) {
                facebookButton = <a onClick={openLink} className="hide-mobile addthis_button_facebook" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(url)}>
                    <Tremr.Generic.SvgIcon icon="facebook" classes="facebook icon" />{facebookTitle}
                </a>;
            }
        } else {
            if (this.props.showTitles) {
                twitterTitle = <label className="share-title">Twitter</label>;
            }
            if (this.props.icons.includes('twitter')) {
                twitterButton = <a className="addthis_button_twitter" target="_blank" href={"https://twitter.com/intent/tweet?text="+encodeURIComponent(this.props.title)+"&url="+encodeURIComponent(url)}>
                    <Tremr.Generic.SvgIcon icon="twitter" classes="twitter icon" />{twitterTitle}
                </a>;
            }

            if (this.props.showTitles) {
                facebookTitle = <label className="share-title">Facebook</label>;
            }
            if (this.props.icons.includes('facebook')) {
                facebookButton = <a onClick={openLink} className="addthis_button_facebook" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(url)}>
                    <Tremr.Generic.SvgIcon icon="facebook" classes="facebook icon" />{facebookTitle}
                </a>;
            }
        } 


        // <a className="addthis_button_twitter">
        //     <Tremr.Generic.SvgIcon icon="twitter" classes="twitter icon" />
        // </a>
        // {moreButton}
        // return <div className="addthis_custom_sharing" data-url={url} data-title={this.props.title} data-description={this.props.description}></div>;
        // return <div className="addthis_sharing_toolbox" data-media={this.props.imageUrl} data-url={url} data-title={this.props.title} data-description={this.props.description}></div>;

        return <div className="addthis_toolbox addthis_toolbox">
            <div className="custom_images">
                {twitterButton}
                {facebookButton}
                {moreButton}
            </div>
        </div>;
    }
});
