require("./scss/contentsummary.scss");

var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");

// iterates post content to figure out word count, image count etc.
module.exports = CreateReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    post: PropTypes.object.isRequired,
  },

  render: function () {
    // add to an object counting each item
    let counts = {
      words: this.props.post.get("wordcount")
        ? this.props.post.get("wordcount")
        : 0,
      images: this.props.post.get("contentsummary")
        ? this.props.post.get("contentsummary").images
        : 0,
      videos: this.props.post.get("contentsummary")
        ? this.props.post.get("contentsummary").videos
        : 0,
      links: this.props.post.get("contentsummary")
        ? this.props.post.get("contentsummary").links
        : 0,
      media: this.props.post.get("contentsummary")
        ? this.props.post.get("contentsummary").media
        : 0,
      tweets: this.props.post.get("contentsummary")
        ? this.props.post.get("contentsummary").tweets
        : 0,
    };

    // convert counts into array of output text where counts is not zero
    var content = [];
    if (counts.words > 0)
      content.push(
        <output key="words">
          {counts.words.toLocaleString() +
            " word" +
            (counts.words > 1 ? "s" : "")}
        </output>
      );
    if (counts.images > 0)
      content.push(
        <output key="images">
          {counts.images.toLocaleString() +
            " image" +
            (counts.images > 1 ? "s" : "")}
        </output>
      );
    if (counts.videos > 0)
      content.push(
        <output key="videos">
          {counts.videos.toLocaleString() +
            " video" +
            (counts.videos > 1 ? "s" : "")}
        </output>
      );
    if (counts.links > 0)
      content.push(
        <output key="links">
          {counts.links.toLocaleString() +
            " link" +
            (counts.links > 1 ? "s" : "")}
        </output>
      );
    if (counts.tweets > 0)
      content.push(
        <output key="tweets">
          {counts.tweets.toLocaleString() +
            " tweet" +
            (counts.tweets > 1 ? "s" : "")}
        </output>
      );
    if (counts.media > 0)
      content.push(
        <output key="others">
          {counts.media.toLocaleString() +
            " other" +
            (counts.media > 1 ? "s" : "")}
        </output>
      );

    // if (counts.images > 1) content.push(<output key="images">{counts.images.toLocaleString() + " image" + (counts.images > 1 ? 's' : '')}</output>);
    // if (counts.videos > 1) content.push(<output key="videos">{counts.videos.toLocaleString() + " video" + (counts.videos > 1 ? 's' : '')}</output>);
    // if (counts.links > 1) content.push(<output key="links">{counts.links.toLocaleString() + " link" + (counts.links > 1 ? 's' : '')}</output>);
    // if (counts.tweets > 1) content.push(<output key="tweets">{counts.tweets.toLocaleString() + " tweet" + (counts.tweets > 1 ? 's' : '')}</output>);
    // if (counts.media > 1) content.push(<output key="others">{counts.media.toLocaleString() + " other" + (counts.media > 1 ? 's' : '')}</output>);

    return (
      <div className="post-contentsummary">
        {content}
        {content ? "…" : ""}
      </div>
    );
  },
});
