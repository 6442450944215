// Util for managing, comparing, combining ranges - defined as an object with a start/end property
// TESTS: https://jsfiddle.net/mkxaq0dn/21/
var Ranges = {

	// add a to b, assume they DO meet or intersect
	union: function(a, b) {

		return _.extend(_.clone(b), {
			start: Math.min(a.start, b.start),
			end: Math.max(a.end, b.end)
		});
	},

	// intersection (how a fits in to b)
	intersection: function(a, b) {

		if (a.start >= b.end) {

			return 'after';

		} else if (a.end <= b.start) {

			return 'before';

		} else if (a.start >= b.start && a.end <= b.end ) {

			return 'contained';

		} else if (a.end < b.end && a.end > b.start) {

			// overlaps before
			return 'start';

		} else if (a.start > b.start && a.start < b.end) {

			// overlaps after
			return 'end';

		} else if (a.start <= b.start && a.end >= b.end ) {

			// a isn't contained by b, a is container of b
			return 'container';

		} else {
			// shouldn't happen
			throw "Error checking intersection of two ranges";
		}
	},


	// subtract a from b (can return 0, 1 or 2 ranges - clones of originals)
	subtract: function(a, b) {

		// check intersection and use that to determine what to do
		var inter = this.intersection(a, b);

		if (inter == 'before' || inter == 'after') {
			// no intersection, just return clone
			return [_.clone(b)];
		} else if (inter == 'start') {
			// start boundry crossed - push b's start up to a's end.
			return [_.extend(_.clone(b), {
				start: a.end
			})];
		} else if (inter == 'end') {
			// end boundry crossed - push b's end down to a's start.
			return [_.extend(_.clone(b), {
				end: a.start
			})];
		} else if (inter == 'container') {
			// a totally covers b, return no range
			return [];
		} else {
			// fully contained, return two clones, either side
			var parts = [];

			// and start
			if (a.end < b.end) {
				parts.push(_.extend(_.clone(b), { start: a.end }));
			}

            // check if we need to keep format at end
			if (a.start > b.start) {
				parts.push(_.extend(_.clone(b), {end: a.start }));
			}

			return parts;
		}
	},

};

module.exports = Ranges;