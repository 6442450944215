let React = require("react");
let CreateReactClass = require("create-react-class");
let PureCanvas = require("./purecanvas");
const DEFAULT_STAR_SIZE = 28;

module.exports = CreateReactClass({
  getDefaultProps: function () {
    return {
      stars: 0, // 0, 1, 2 or 3
      filledStars: 0, // 0, 1, 2 or 3
      scale: 1,
      starSize: DEFAULT_STAR_SIZE,
      lineWidth: 3,
      setColor: "#FBAC01",
      unsetColor: "#E1E1E1",
      unsetFilled: false,
      drawUnset: true,
      alignment: "center",
      onRender: null,
      onClick: null,
      icons: {
        star:
          // "M20.2595236,22.6154952 L19.1072676,15.6594518 L24.128122,10.7091737 L17.1564649,9.65549871 L14,3.35066231 L10.8435351,9.65549871 L3.87187799,10.7091737 L8.89273243,15.6594518 L7.74047636,22.6154952 L14,19.3700989 L20.2595236,22.6154952 Z",
          "M 14 20.34 L 7.74 22.615 L 7.97 15.959 L 3.872 10.709 L 10.273 8.871 L 14 3.351 L 17.727 8.871 L 24.128 10.709 L 20.03 15.959 L 20.26 22.615 L 14 20.34 Z",
      },
    };
  },

  // parent setting params will call this
  // so we can redraw
  componentDidUpdate: function () {
    this.draw();
    if (this.props.onRender) {
      this.props.onRender();
    }
  },

  drawIcon: function (name, isSet, isFilled, ctx, x, y) {
    let starWidth = this.props.starSize * this.props.scale;
    let halfStarWidth = starWidth / 2;

    ctx.save();
    ctx.beginPath();
    ctx.translate(x - halfStarWidth, y - halfStarWidth);

    // adjust for non-default star size
    ctx.scale(
      this.props.starSize / DEFAULT_STAR_SIZE,
      this.props.starSize / DEFAULT_STAR_SIZE
    );

    ctx.scale(this.props.scale, this.props.scale);

    let p = new Path2D(this.props.icons[name]);

    if (isFilled) {
      ctx.fillStyle = this.props.setColor;
      ctx.fill(p);
    } else if (this.props.unsetFilled) {
      ctx.fillStyle = this.props.unsetColor;
      ctx.fill(p);
    }

    ctx.lineWidth = this.props.lineWidth;
    if (isSet) {
      ctx.strokeStyle = this.props.setColor;
    } else {
      ctx.strokeStyle = this.props.unsetColor;
    }
    ctx.stroke(p);

    ctx.closePath();
    ctx.restore();
  },

  numberOfStars: function () {
    let stars = this.props.stars;
    if (this.props.drawUnset) {
      stars = 3;
    }
    if (this.props.filledStars > stars) {
      stars = this.props.filledStars;
    }
    return stars;
  },

  // do the drawing
  draw: function () {
    let numberOfStars = this.numberOfStars();
    if (numberOfStars == 0) {
      return;
    }

    let ctx = this.ctx;

    // sort out some variables for sizes
    let starWidth = this.props.starSize * this.props.scale;
    let halfStarWidth = starWidth / 2;
    let centreHeight = this.height / 2;

    ctx.save();
    ctx.clearRect(0, 0, this.width, this.height);

    // draw spaced to fill width
    let space = this.width - numberOfStars * starWidth;
    let startPosition = space / 2 + halfStarWidth;

    // set the start position depending on alignment and number of stars
    if (this.props.alignment == "left") {
      startPosition = halfStarWidth;
    } else if (this.props.alignment == "right") {
      startPosition = this.width - (numberOfStars * starWidth - halfStarWidth);
    }

    for (let i = 0; i < numberOfStars; i++) {
      // stars sometimes smaller (when we have more filled than unfilled)
      // if (this.props.stars > i || this.props.drawUnset) {

      let isSet = this.props.filledStars > i || this.props.stars > i;
      let isFilled = this.props.filledStars > i;

      // isSet and isFilled should be from right to left for right aligned
      if (this.props.alignment == "right") {
        isSet =
          numberOfStars - this.props.filledStars <= i ||
          numberOfStars - this.props.stars <= i;
        isFilled = numberOfStars - this.props.filledStars <= i;
      }

      if (numberOfStars > i || this.props.drawUnset) {
        this.drawIcon(
          "star",
          isSet,
          isFilled,
          ctx,
          startPosition + starWidth * i,
          centreHeight
        );
      }
    }

    ctx.restore();
  },

  saveCtx: function (ctx) {
    this.ctx = ctx;
    this.height = this.ctx.canvas.height;
    this.width = this.ctx.canvas.width;
    this.draw(); // first draw
  },

  onClick: function (e) {
    if (this.props.onClick) {
      var domNode = ReactDOM.findDOMNode(this);
      let bounds = domNode.getBoundingClientRect();
      let x = e.clientX - bounds.left;
      let star = Math.ceil(x / this.props.starSize);
      if (star > 0 && star <= 3) {
        this.props.onClick(star);
      }
    }
  },

  render: function () {
    let numberOfStars = this.numberOfStars();
    if (numberOfStars == 0) {
      return null;
    }
    return (
      <div className="reputation-stars" onClick={this.onClick}>
        <PureCanvas
          height={this.props.height * this.props.scale}
          width={this.props.width * this.props.scale}
          contextRef={this.saveCtx}
        />
      </div>
    );
  },
});
