var scrollTo = require('jquery.scrollto');
var classNames = require('../../utils/classnames');
var UserAwareMixin = require('../../mixins/userawaremixin');
var alertify = require('alertify');
var PropTypes = require('prop-types');
var CreateReactClass = require('create-react-class');

// container for response editor that displays initially as a "button"
// Tremr.Editor.ResponseEditor
module.exports = CreateReactClass({

 	mixins: [PureRenderMixin, UserAwareMixin],

  	propTypes: {
		parent: PropTypes.object.isRequired, // parent that you are responding to
		onOpen: PropTypes.func
	},

	getInitialState: function() {
		return {
			open: false
		};
	},

	componentDidMount: function() {

		// scroll to this component and activate the open function
		var scrollTo = function(node) {
			var container = $(node).closest('.current');
			$(container).scrollTo(node, { axis:'y', offset:-35, duration: 500 })
		};

		// if the editor is open it will deal with this, if closed then just discard
	    this.listenTo(Tremr.dispatcher, "tremr:editor:askdiscard", function(callbacks) {
	      if (!this.state.open) {
	        if (callbacks.discardCallback) {
	          callbacks.discardCallback();
	        }
	      }
	    }.bind(this));

		// listen for open request
        this.listenTo(Tremr.dispatcher, "tremr:responseeditor:open", function(post_id) {
	      	// is it this post
	        if (this.props.parent && this.props.parent.get('id') == post_id) {
	        	var domNode = ReactDOM.findDOMNode(this);
	        	scrollTo(domNode, { duration: 500 });
	            this.open();
	        }
        }.bind(this));

        // listen for open request
        this.listenTo(Tremr.dispatcher, "tremr:responseeditor:scrollto", function(post_id) {

          	if (this.props.parent && this.props.parent.get('id') == post_id) {
          		var domNode = ReactDOM.findDOMNode(this);
                scrollTo(domNode);
            }
        }.bind(this));

	},

	open: function(event) {

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (Tremr.Utils.checkForIE()) {
          alertify.alert("We're sorry but your browser (Internet Explorer) does not support editing at this time.");
          return;
        } else if(Tremr.Utils.checkForEdge()) {
          alertify.alert("We're sorry but your browser (Edge) does not support editing at this time.");
          return;
        }

		// require sign-in and allow normal useraware to deal with opening editor
		if (!this.state.user) {
			Tremr.dispatcher.message(this, "tremr:user:requiresignin", false);
        }

		// open but not signed-in is same as closed, so do and wait for signin event
		this.setState({
			open: true
		});

		if (this.props.onOpen) {
			this.props.onOpen();
		}

	},

	postCallback: function(m) {
		alertify.success("Response Posted.");

		this.setState({
			open: false
		});
	},

	render: function() {

		var classNames = require('../../utils/classnames');
var classes = classNames({
			responseEditor: true,
			signedIn: this.state.user,
			open: (this.state.open && this.state.user),
			closed: !(this.state.open && this.state.user)
		});

		var content = null;
		if (!this.state.user) {

			var default_avatar = {
		        public_id: "avatar-default",
		        width: 160,
		        height: 160
		    }
			var avatar = <div className="avatar">
	            <Tremr.Generic.Image height="32" width="32" image={default_avatar} />
	         </div>;

			content = <div className="editor"><div className="panel" onClick={this.open}>
				{avatar}
				<p>Sign-in and post a response…</p>
				<Tremr.Generic.SvgIcon icon="photo" />
				<Tremr.Generic.SvgIcon icon="link" />
				<Tremr.Generic.SvgIcon icon="play" />
			</div></div>;

		} else if (this.state.user && !this.state.open) {

			if (this.state.user.get('avatar_image') && this.state.user.get('avatar_image').public_id) {
	          avatar = <div className="avatar">
	            <Tremr.Generic.Image height="32" width="32" image={this.state.user.get('avatar_image')} />
	          </div>;
	        }
			content = <div className="editor parent"><div className="panel" onClick={this.open}>
				{avatar}
				<p>Post a response…</p>
				<Tremr.Generic.SvgIcon icon="photo" />
				<Tremr.Generic.SvgIcon icon="link" />
				<Tremr.Generic.SvgIcon icon="play" />
			</div></div>;

		} else {

			content = <Tremr.Editor.Editor key="editor" ref="editor" defaultFeatured={false} showBreadcrumbs={false} onPost={this.postCallback} parent={this.props.parent} author={this.state.user} />;

		}

		return <div className={classes}>
			{content}
		</div>;
	}

});
