const TremrStorage = require("./utils/tremr_storage");
const alertify = require("alertify");
const Firebase = require("firebase/app");
const FirebaseAuth = require("firebase/auth");
const FirebaseDb = require("firebase/database");
const Config = require("config");
const Theme = require("theme");

// tremr namespace
module.exports = {
  // required stuff
  Utils: require("./utils/tremr_utils"),
  Dispatcher: require("./dispatcher"),
  Routes: require("./routes"),
  App: require("./app"),
  Theme: Theme,

  // require stores
  LayoutStore: require("./stores/layout/layout_store"),
  PostStore: require("./stores/post/post_store"),
  TagStore: require("./stores/tag/tag_store"),
  UserStore: require("./stores/user/user_store"),
  FeedStore: require("./stores/feed/feed_store"),
  ActivityStore: require("./stores/activity/activity_store"),
  FollowStore: require("./stores/follow/follow_store"),
  EditorStore: require("./stores/editor/editor_store"),
  SearchStore: require("./stores/search/search_store"),

  // require all components
  Generic: {
    CardMixin: require("./mixins/cardmixin"), // mixin
    OnClickOutside: require("./mixins/onclickoutside"), // mixin
    EventsMixin: require("./stores/generic/eventsmixin"), // mixin
    Abbreviations: require("./stores/generic/abbreviations"),
    BarChart: require("./stores/generic/barchart"),
    Contentcard: require("./stores/generic/contentcard"),
    // Dropdown: require('./stores/generic/dropdown'),
    // DropdownOption: require('./stores/generic/dropdownoption'),
    Embed: require("./stores/generic/embed"),
    EmbedCodePrompt: require("./stores/generic/embedcodeprompt"),
    EmbedPrompt: require("./stores/generic/embedprompt"),
    EmbedThumb: require("./stores/generic/embedthumb"),
    Errorfield: require("./stores/generic/errorfield"),
    Iconbutton: require("./stores/generic/iconbutton"),
    Image: require("./stores/generic/image"),
    Imageupload: require("./stores/generic/imageupload"),
    Input: require("./stores/generic/input"),
    LabelLink: require("./stores/generic/labellink"),
    List: require("./stores/generic/list"),
    // Logo: require('./stores/generic/logo'),
    Mouseover: require("./stores/generic/mouseover"),
    Multibutton: require("./stores/generic/multibutton"),
    OptionItem: require("./stores/generic/optionitem"),
    Optionlist: require("./stores/generic/optionlist"),
    // Share: require('./stores/generic/share'),
    StatTab: require("./stores/generic/stattab"),
    SvgIcon: require("./stores/generic/svgicon"),
    Tab: require("./stores/generic/tab"),
    TagEditor: require("./stores/generic/tageditor"),
    Wall: require("./stores/generic/wall"),
  },
  Layout: {
    Filter: require("./stores/layout/filter"),
    Homemenu: require("./stores/layout/homemenu"),
    // HomepageHeader: require('./stores/layout/homepage_header'),
    Overlay: require("./stores/layout/overlay"),
    Overlays: require("./stores/layout/overlays"),
    Page: require("./stores/layout/page"),
    Rightsidebar: require("./stores/layout/rightsidebar"),
    Requestinvite: Theme.RequestInvite.Component,
  },
  Reputation: {
    Sidebar: require("./stores/reputation/sidebar"),
  },
  User: {
    Endorse: require("./stores/user/endorse"),
    UserAwareMixin: require("./mixins/userawaremixin"), // mixin
    // Byline: require('./stores/user/byline'),
    // Card: require("./stores/user/card"),
    Followingmenu: require("./stores/user/followingmenu"),
    Menu: require("./stores/user/menu"),
    NewPassword: require("./stores/user/newpassword"),
    NotificationSettings: require("./stores/user/notification_settings"),
    // Notificationsmenu: require('./stores/user/Notificationsmenu'),
    // PersonalFeedFooterFooter: require('./stores/user/personal_feed_footer'),
    // PersonalFeedHeader: require('./stores/user/personal_feed_header'),
    Profile: require("./stores/user/profile"),
    Signin: require("./stores/user/signin"),
    // Signinup: require('./stores/user/signinup'),
    Signup: require("./stores/user/signup"),
    ManageSubfeeds: require("./stores/user/manage_subfeeds"),
    Sidebar: require("./stores/user/sidebar"),
    SidebarActivity: require("./stores/user/sidebar_activity"),
    AcceptInvite: require("./stores/user/acceptinvite"),
  },
  Post: {
    // AllPostsFooter: require('./stores/post/all_posts_footer'),
    // Breadcrumb: require('./stores/post/breadcrumb'),
    // Breadcrumbs: require('./stores/post/breadcrumbs'),
    Report: require("./stores/post/report"),
    Menu: require("./stores/post/menu"),
    Card: require("./stores/post/card"),
    DraftCard: require("./stores/post/draftcard"),
    DraftsHeader: require("./stores/post/drafts_header"),
    Headline: require("./stores/post/headline"),
    Preview: require("./stores/post/preview"),
    // ResponsesSummary: require('./stores/post/responsessummary'),
    // ResponseSummary: require('./stores/post/responsesummary'),
    Show: require("./stores/post/show"),
    // TagAndType: require('./stores/post/tagandtype')
  },
  Tag: {
    Card: require("./stores/tag/card"),
    Header: require("./stores/tag/header"),
    List: require("./stores/tag/list"),
    ListItem: require("./stores/tag/listitem"),
    Tagsmenu: require("./stores/tag/tagsmenu"),
  },
  Feed: {
    Card: require("./stores/feed/card"),
    // Footer: require('./stores/feed/footer'),
    Header: require("./stores/feed/header"),
    ListItem: require("./stores/feed/listitem"),
    ViewChart: require("./stores/feed/viewchart"),
    Edit: require("./stores/feed/edit"),
  },
  Activity: {
    // ActivityCountButton: require('./stores/activity/activity_count_button'),
    // Button: require('./stores/activity/button'),
    // ListItem: require('./stores/activity/listitem'),
    RepostMenu: require("./stores/activity/repost_menu"), // has to be global for overlay/popup
  },
  Follow: {
    Button: require("./stores/follow/button"),
    Menu: require("./stores/follow/menu"),
  },
  Editor: {
    Addblockcontrol: require("./stores/editor/addblockcontrol"),
    ContentEditable: require("./stores/editor/contenteditable"),
    Count: require("./stores/editor/count"),
    Editor: require("./stores/editor/editor"),
    EmbedBlock: require("./stores/editor/embedblock"),
    EmbedControl: require("./stores/editor/embedcontrol"),
    Formatpopup: require("./stores/editor/formatpopup"),
    Header: require("./stores/editor/header"),
    PlaceholderControl: require("./stores/editor/placeholdercontrol"),
    // PostButton: require("./stores/editor/postbutton"),
    ResponseEditor: require("./stores/editor/responseeditor"),
    Richtext: require("./stores/editor/richtext"),
    TextBlock: require("./stores/editor/textblock"),
    TextControl: require("./stores/editor/textcontrol"),
    TwitterBlock: require("./stores/editor/twitterblock"),
    TwitterControl: require("./stores/editor/twittercontrol"),
    UploadBlock: require("./stores/editor/uploadblock"),
    UploadControl: require("./stores/editor/uploadcontrol"),
  },
  Search: {
    Header: require("./stores/search/header"),
    // Menu: require('./stores/search/menu')
  },

  // require models
  Models: {
    Activity: require("./models/Activity.js"),
    Activities: require("./models/Activities.js"),
    Draft: require("./models/Draft.js"),
    Drafts: require("./models/Drafts.js"),
    Feed: require("./models/Feed.js"),
    Feeds: require("./models/Feeds.js"),
    FeedHeadline: require("./models/FeedHeadline.js"),
    FeedView: require("./models/FeedView.js"),
    Follow: require("./models/Follow.js"),
    Followers: require("./models/Followers.js"),
    Following: require("./models/Following.js"),
    Post: require("./models/Post.js"),
    Posts: require("./models/Posts.js"),
    Search: require("./models/Search.js"),
    Searches: require("./models/Searches.js"),
    SeenActivity: require("./models/SeenActivity.js"),
    SeenActivities: require("./models/SeenActivities.js"),
    SeenAllActivities: require("./models/SeenAllActivities.js"),
    UnseenActivities: require("./models/UnseenActivities.js"),
    SocialCallback: require("./models/SocialCallback.js"),
    SocialSignupCallback: require("./models/SocialSignupCallback.js"),
    State: require("./models/State.js"),
    Tag: require("./models/Tag.js"),
    Tags: require("./models/Tags.js"),
    User: require("./models/User.js"),
    Users: require("./models/Users.js"),
    UserNewPassword: require("./models/UserNewPassword.js"),
    UserNotificationSettings: require("./models/UserNotificationSettings.js"),
    UserPasswordRecovery: require("./models/UserPasswordRecovery.js"),
    UserRegistration: require("./models/UserRegistration.js"),
    UserSession: require("./models/UserSession.js"),
    EndUserSession: require("./models/EndUserSession.js"),
    View: require("./models/View.js"),
  },

  // instances
  stores: {},
  dispatcher: {},

  // keep a global set of state
  context: {},

  // useful messages we use
  messages: {
    dataError: "Failed to load data from the server.",
  },

  setHighlightColor: function (newColor) {
    document.documentElement.style.setProperty("--highlight-color", newColor);
    Tremr.dispatcher.message(Tremr, "tremr:color:highlight", newColor);
  },

  setFeedHighlightColor: function (newColor) {
    document.documentElement.style.setProperty(
      "--feed-highlight-color",
      newColor
    );
    Tremr.dispatcher.message(Tremr, "tremr:color:feedhighlight", newColor);
  },

  // open something in the primary area of the page
  navigatePrimary: function (primary, scrollpos, checkEditor, resetScroll) {
    if (resetScroll === undefined) {
      resetScroll = true;
    }

    // do as a callback
    var navigate = function () {
      let currentUrl = Tremr.routes.urlFor(Tremr.context.getState());

      // remember the scroll position and url of the current component, if there is one
      if (scrollpos) {
        let history = TremrStorage.getSession("scrollpos") || {};
        history[currentUrl] = scrollpos;
        TremrStorage.setSession("scrollpos", history);
      }

      // remember the users path through the site (for back button)
      let history = TremrStorage.getSession("tremr_history") || [];
      history.push(currentUrl);
      TremrStorage.setSession("tremr_history", history);

      // update state
      var newState = {
        primary: primary,
        editor: { open: false },
        cache: false,
      };

      // scroll to the top whenever we open something new
      if (resetScroll) {
        _.defer(function () {
          window.scrollTo(0, 0);
        });
      }

      Tremr.context.batch(newState, ["current", "editor"]);

      var newUrl = Tremr.routes.urlFor(Tremr.context.getState());
      Tremr.routes.updateUrl(newUrl);
    };

    // do we need to check editor?
    if (checkEditor === undefined) {
      checkEditor = true;
    }

    // ask editor store if we can navigate
    if (checkEditor) {
      Tremr.stores.editorStore.canNavigate(navigate);
    } else {
      navigate();
    }
  },

  // open something in the current area of the page, which is now also the primary area
  // so simply defer to that function
  navigateCurrent: function (current, scrollpos, checkEditor) {
    this.navigatePrimary(current, scrollpos, checkEditor);
  },

  // set left/right sidebar state if we haven't already got it
  sidebarDefaultState: function (state) {
    var newState = {};
    var screenWidth = false;

    if (!state.leftSidebar) {
      // depends on screen width
      screenWidth = $(window).width();
      if (screenWidth < 1024) {
        newState.leftSidebar = "closed";
      } else {
        newState.leftSidebar = "open";
      }
    }

    if (!state.rightSidebar) {
      // depends on screen width
      if (!screenWidth) {
        screenWidth = $(window).width();
      }
      if (screenWidth < 1208) {
        newState.rightSidebar = "closed";
      } else {
        newState.rightSidebar = "open";
      }
    }

    return newState;
  },

  // kick-off everything
  start: function () {
    // set server provided config
    if (window.reputation !== undefined) {
      Config.reputation = window.reputation;
    }

    // ready the routes
    Tremr.routes = new Tremr.Routes();

    // create initial state before we do anything
    Tremr.context = new Tremr.Models.State();

    // parse the querystring for use later
    Tremr.current_url = Tremr.Utils.parseUrl();

    // check for app indicator and set in localStorage
    console.log("Tremr.current_url", Tremr.current_url);
    if (Tremr.current_url && Tremr.current_url.params && Tremr.current_url.params.app == "1") {
      TremrStorage.setLocal("ui-app", true);
    }

    // context MUST be set by server rendered code to do this!, allows us to
    // render meaningful html before we parse the route (or we could bootstrap cached data)
    if (typeof TremrContext !== "undefined") {
      // always set sidebars here!
      TremrContext = _.extend(
        TremrContext,
        this.sidebarDefaultState(TremrContext)
      );
      Tremr.context.add(TremrContext, true);
    }

    // get a reference to the dispatcher
    Tremr.dispatcher = new Tremr.Dispatcher();

    // create the stores we want to use
    Tremr.stores.layoutStore = new Tremr.LayoutStore();
    Tremr.stores.postStore = new Tremr.PostStore();
    Tremr.stores.tagStore = new Tremr.TagStore();
    Tremr.stores.userStore = new Tremr.UserStore();
    Tremr.stores.feedStore = new Tremr.FeedStore();
    Tremr.stores.activityStore = new Tremr.ActivityStore();
    Tremr.stores.followStore = new Tremr.FollowStore();
    Tremr.stores.editorStore = new Tremr.EditorStore();
    Tremr.stores.searchStore = new Tremr.SearchStore();

    // init the firebase app
    Tremr.firebase = Firebase.default.initializeApp(Config.firebase);

    // listen for auth change
    Tremr.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // we have a user - no need to do anything
        Tremr.dispatcher.message(Tremr, "tremr:firebase:auth", false);
      } else {
        // default to anonymous firebase user
        Tremr.firebase
          .auth()
          .signInAnonymously()
          .catch(function (error) {
            console.log("Firebase signInAnonymously:");
            console.dir(error);
          });
      }
    });

    // create the app in the page
    let rootElement = document.querySelectorAll(".tremr")[0];
    if (!rootElement) {
      rootElement = document.createElement("div");
      rootElement.className = "tremr";
      document.body.appendChild(rootElement);
    }

    ReactDOM.render(<Tremr.App />, rootElement);

    // fire initialise action
    Tremr.dispatcher.message(Tremr, "tremr:init", false);

    // watch for changes to the global state
    Tremr.context.on("state:changed", function (state, newstate, removedstate) {
      // update stores
      Tremr.dispatcher.message(Tremr, "tremr:contextchanged", state);
    });

    Tremr.context.on("state:refresh", function (state, item) {
      // update stores
      Tremr.dispatcher.message(Tremr, "tremr:refresh", item);
    });

    // watch for page resize, and fire global event
    $(window).resize(
      _.throttle(function () {
        Tremr.dispatcher.message(Tremr, "tremr:windowresize", window);
      }, 200)
    );

    // start looking for page changes
    Backbone.history.start({ pushState: true });

    // see if the user is logged-in
    Tremr.stores.userStore.checkExsitingSession(Tremr.stores.userStore);

    // watch for page shown
    document.addEventListener("visibilitychange", function () {
      var visible =
        document.visibilityState && document.visibilityState == "visible";
      Tremr.dispatcher.message(Tremr, "tremr:visibilitychange", visible);
    });

    // show any flash messages
    if (window.tremr_flash !== undefined) {
      _.each(window.tremr_flash, function (flash) {
        if (flash.name == "error") {
          alertify.error(flash.message);
        } else {
          alertify.log(flash.message);
        }
      });
    }
  },
};
