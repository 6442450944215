var CreateReactClass = require('create-react-class');

// SVG based icon, allowing us to add styles
module.exports = CreateReactClass({

	mixins: [PureRenderMixin],

	getDefaultProps: function() {
		return {
			baseColor: '#2d2d2d',
			level: 0,
            onClick: _.noop
		}
	},

	render: function() {

        let classes1 = "";
        let classes2 = "";
        let classes3 = "";
        if (this.props.level > 0) {
            classes1 = "set";
        }
        if (this.props.level > 1) {
            classes2 = "set";
        }
        if (this.props.level > 2) {
            classes3 = "set";
        }

		return (
			<svg
             onClick={this.props.onClick}
			 className="activity-icon-upvote"
			 viewBox="0 0 512 512">
			  <g id="upvote">
                <path className={classes1} id="level-1" d="M255.997,120.322 L259.451,120.393 C267.892,121.051 275.292,124.415 282.062,129.315 L411.209,231.508 C419.005,237.677 420.324,248.997 414.155,256.793 C410.741,261.107 405.541,263.623 400.04,263.623 L354.393,263.623 C349.974,263.623 346.393,267.205 346.393,271.623 L346.393,292 C346.393,301.941 338.334,310 328.393,310 L186.839,310 C176.898,310 168.839,301.941 168.839,292 L168.839,271.623 C168.839,267.205 165.257,263.623 160.839,263.623 L111.96,263.623 C102.019,263.623 93.96,255.564 93.96,245.623 C93.96,240.121 96.476,234.922 100.791,231.508 L229.938,129.315 C236.468,124.24 244.281,121.075 252.548,120.393 L255.997,120.322 z M255.69,140.267 C250.721,140.367 246.401,142.28 242.348,144.999 L117.711,243.623 L160.839,243.623 C176.303,243.623 188.839,256.159 188.839,271.623 L188.839,290 L326.393,290 L326.393,271.623 C326.393,256.159 338.929,243.623 354.393,243.623 L394.289,243.623 L269.651,144.999 C265.744,141.91 262.576,141.116 257.808,140.325 L255.69,140.267 z"/>
                <path className={classes2} id="level-2" d="M339,332.5 C344.523,332.5 349,336.977 349,342.5 C349,348.023 344.523,352.5 339,352.5 L177,352.5 C171.477,352.5 167,348.023 167,342.5 C167,336.977 171.477,332.5 177,332.5 L339,332.5 z"/>
                <path className={classes3} id="level-3" d="M339,374.5 C344.523,374.5 349,378.977 349,384.5 C349,390.023 344.523,394.5 339,394.5 L177,394.5 C171.477,394.5 167,390.023 167,384.5 C167,378.977 171.477,374.5 177,374.5 L339,374.5 z"/>
              </g>
			</svg>
		);
	}
});
