require("./scss/button.scss");

var classNames = require("../../utils/classnames");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var UserAwareMixin = require("../../mixins/userawaremixin");
var TremrTracking = require("../../utils/tracking");

module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  propTypes: {
    tag: PropTypes.string.isRequired,
  },

  getEncodedTag: function () {
    return this.props.tag.replace(/\s/g, ":");
  },

  // immediately toggle value in firebase
  handleClick: function (event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.state.user) {
      // write true/false to the ref
      let value = this.props.liveDataPoints[this.getEncodedTag() + "-set"].data;
      if (!value) {
        value = false;
      }
      value = !value; // toggle
      this.props.liveDataPoints[this.getEncodedTag() + "-set"].fbRef.set(
        value,
        function (error) {
          if (error) {
            console.log("Data could not be saved:");
            console.dir(error);
          } else {
            TremrTracking.react(
              this.state.user,
              this.props.post_id,
              this.props.tag,
              value != false
            );
          }
        }.bind(this)
      );

      if (value) {
        // if this is a reaction then modify this users record in reactors
        if (Tremr.stores.activityStore.reactions.includes(this.props.tag)) {
          this.props.liveDataPoints["reactors"].fbRef.transaction(function (
            current
          ) {
            return (current || 0) + 1;
          });
        }
      } else {
        // if this is a reaction then modify this users record in reactors
        if (Tremr.stores.activityStore.reactions.includes(this.props.tag)) {
          this.props.liveDataPoints["reactors"].fbRef.transaction(
            function (current) {
              return (current || 0) - 1;
            },
            function (error, committed, snapshot) {
              if (snapshot.val() <= 0) {
                this.props.liveDataPoints["reactors"].fbRef.remove();
              }
            }.bind(this)
          );
        }
      }
    } else {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        callback: this.handleClick,
        data: event,
      });
      return;
    }
  },

  // display based on the image property
  render: function () {
    let value = this.props.liveDataPoints[this.getEncodedTag() + "-set"].data;

    // see if we have loaded the image or not
    let classes = {};
    classes["activity-button"] = true;
    classes["set"] = value;
    classes[this.props.tag.replace(/\s/g, "-")] = true;
    classes = classNames(classes);

    let percent = null;
    let percentage = 0;

    if (Tremr.stores.activityStore.reactions.includes(this.props.tag)) {
      if (
        this.props.liveDataPoints["reactors-count"] &&
        this.props.liveDataPoints["reactors-count"].data
      ) {
        percentage = Math.round(
          ((this.props.liveDataPoints[this.getEncodedTag() + "-sum"].data ||
            0) /
            this.props.liveDataPoints["reactors-count"].data) *
            100
        );
      }
    }

    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage > 0) {
      percentage = percentage.toString() + "%";
      var style = {
        width: percentage,
      };
      percent = <span key="percent" className="percent" style={style} />;
    }

    return (
      <button
        title={this.props.tag.replace(/:/g, " ").capitalize()}
        className={classes}
        onClick={this.handleClick}
      >
        <div key="label" className="label">
          {this.props.tag.replace(/:/g, " ")}
        </div>
        {percent}
      </button>
    );
  },
});
