require("./scss/menu.scss");

var classNames = require("../../utils/classnames");
var TremrUtils = require("../../utils/tremr_utils");
var IconButton = require("../generic/iconbutton");
var PropTypes = require("prop-types");
var CreateReactClass = require("create-react-class");
var SvgIcon = require("../generic/svgicon");
var UserAwareMixin = require("../../mixins/userawaremixin");
var Share = require("../generic/share");
var FactCheckButton = require("../factcheck/button");

module.exports = CreateReactClass({
  mixins: [UserAwareMixin],

  // promises: [], // so we can cancel on unmount

  propTypes: {
    post_id: PropTypes.string.isRequired,
    post_slug: PropTypes.string.isRequired,
    author_id: PropTypes.string.isRequired,
    post_author: PropTypes.string.isRequired,
    post_title: PropTypes.string.isRequired,
    post_url: PropTypes.string.isRequired,
    post_imageUrl: PropTypes.string.isRequired,
    post_summary: PropTypes.string.isRequired,
    isFactChecked: PropTypes.bool.isRequired,
  },

  getDefaultProps: function () {
    return {
      isFactChecked: false,
    };
  },

  editPost: function (event) {
    event.stopPropagation();
    event.preventDefault();

    Tremr.dispatcher.message(this, "tremr:editor:open", {
      post_id: this.props.post_slug,
    });

    this.props.onClose();
  },

  reportPost: function (event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.state.user) {
      var overlayData = {
        class: "Tremr.Post.Report",
        style: "sidebar",
        props: {
          user: this.state.user,
          post_id: this.props.post_id,
          author_id: this.props.author_id,
          post_slug: this.props.post_slug,
          post_author: this.props.post_author,
          post_title: this.props.post_title,
          post_url: this.props.post_url,
          post_imageUrl: this.props.post_imageUrl,
          post_summary: this.props.post_summary,
        },
      };
      Tremr.dispatcher.message(this, "tremr:open:overlay", overlayData);
      this.props.onClose();
    } else {
      Tremr.dispatcher.message(this, "tremr:user:requiresignin", {
        callback: this.reportPost,
        data: event,
      });
      return;
    }
  },

  // display
  render: function () {
    // see if we have loaded the image or not
    let classes = {
      "post-menu": true,
    };
    classes = classNames(classes);

    // can edit?
    let editButton = null;
    if (
      this.state.user &&
      (this.state.user.get("admin") == "yes" ||
        this.state.user.get("_id") == this.props.author_id)
    ) {
      editButton = (
        <div className="section">
          <div className="line" onClick={this.editPost}>
            <SvgIcon icon="write" />
            <label>Edit Post</label>
          </div>
        </div>
      );
    }

    let reportButton = null;
    if (this.state.user) {
      reportButton = (
        <div className="section">
          <div className="line" onClick={this.reportPost}>
            <SvgIcon icon="report" />
            <label>Report Post</label>
          </div>
        </div>
      );
    }

    return (
      <div className="post-menu-container sidebar">
        <div className="post-menu-toolbar toolbar">
          <div className="row">
            <SvgIcon
              key="close"
              icon="remove"
              classes="close icon"
              onClick={this.props.onClose}
            />
          </div>
          <div className="row title">{this.props.post_title}</div>
        </div>
        <div className="controls">
          <Share
            addthisSuffix={"post_" + this.props.post_id}
            showTitles={true}
            imageUrl={this.props.post_imageUrl}
            url={this.props.post_url}
            title={this.props.post_title}
            description={this.props.post_summary}
          />
          {editButton}
          {reportButton}
          <FactCheckButton
            onClick={this.props.onClose}
            isSet={this.props.isFactChecked}
            post_id={this.props.post_id}
          />
        </div>
      </div>
    );
  },
});
